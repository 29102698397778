import { useUrlQueryParam } from "../../utils/url";
import { useMemo } from "react";

export const usePointsGoodsSearchParams = () => {
    const [param, setParam] = useUrlQueryParam(["pointsGoodsId", "pointsGoodsTitle", "pointsGoodsState", "pageNum"]);
    return [
        useMemo(
          () => ({ ...param, pointsGoodsId: Number(param.pointsGoodsId) || undefined, pageNum: Number(param.pageNum) || undefined }),
          [param]
        ),
        setParam,
    ] as const;
};
