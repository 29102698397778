import { Button, Form, Input, message, Radio, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { useGoodsSetting, useEditGoodsSetting } from "../../services/goods-setting-service";
import { useDocumentTitle } from "../../utils"

export const GoodsSettingIndexPage = () => {
    //标题
    useDocumentTitle('商品设置', true)
    //布局
    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 10 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    //表单
    const [form] = useForm();
    const [goodsSettingMiniProgramShareTitleType, setGoodsSettingMiniProgramShareTitleType] = useState(1);
    //准备回显数据
    const { data: editingGoodsSetting, isLoading } = useGoodsSetting();  
    useEffect(() => {
        form.setFieldsValue(editingGoodsSetting?.data)
        setGoodsSettingMiniProgramShareTitleType(editingGoodsSetting?.data?.goodsSettingMiniProgramShareTitleType)
    }, [editingGoodsSetting, form]);
    //前缀
    function changeGoodsSettingMiniProgramShareTitleType(e: any){
        setGoodsSettingMiniProgramShareTitleType(e.target.value)
    }
    //编辑
    const useMutateEditGoodsSetting = useEditGoodsSetting;
    const {mutateAsync, error } =  useMutateEditGoodsSetting();
    //提交表单
    const [submitLoading, setSubmitLoading] = useState(false);
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...editingGoodsSetting?.data, ...values }).then(() => {
            message.info('编辑成功');
            setSubmitLoading(false);
        });
    };
    return (
        <div>
            <Spin spinning={isLoading}>
                <Form
                    form={form}
                    {...layout}
                    onFinish={onFinish}>
                    <div style={{ padding: 10, backgroundColor: '#f8f8f8', fontSize: 14, fontWeight: 700}}>
                        商品设置
                    </div>

                    <Form.Item
                        label={"为你推荐"}
                        name={"goodsSettingRecommendShow"}
                        rules={[{ required: true, message: "为你推荐必选" }]}
                        style={{ marginTop: 24, textAlign:'left' }}
                    >
                        <Radio.Group>
                            <Radio value={true}>开启</Radio>
                            <Radio value={false}>关闭</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label={"商品评价"}
                        name={"goodsSettingEvaluateShow"}
                        rules={[{ required: true, message: "商品评价必选" }]}
                        style={{ textAlign:'left' }}
                    >
                        <Radio.Group>
                            <Radio value={true}>展示</Radio>
                            <Radio value={false}>隐藏</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label={"商详页销量"}
                        name={"goodsSettingSalesVolumeShow"}
                        rules={[{ required: true, message: "商详页销量必选" }]}
                        style={{ textAlign:'left' }}
                    >
                        <Radio.Group>
                            <Radio value={true}>展示</Radio>
                            <Radio value={false}>隐藏</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <div style={{ padding: 10, backgroundColor: '#f8f8f8', fontSize: 14, fontWeight: 700}}>
                        商品分享设置
                    </div>

                    <Form.Item
                        label={"小程序分享标题"}
                        name={"goodsSettingMiniProgramShareTitleType"}
                        rules={[{ required: true, message: "小程序分享标题必选" }]}
                        style={{ marginTop: 24, textAlign:'left' }}
                    >
                        <Radio.Group onChange={changeGoodsSettingMiniProgramShareTitleType}>
                            <Radio value={1}>仅展示商品名称</Radio>
                            <Radio value={2}>展示自定义前缀和商品名称</Radio>
                        </Radio.Group>
                    </Form.Item>

                    {goodsSettingMiniProgramShareTitleType == 2 ? <Form.Item
                        label={"标题前缀"}
                        name={"goodsSettingMiniProgramShareTitlePrefix"}
                        rules={[{ required: true, message: "标题前缀必填" }]}
                    >
                        <Input style={{width: 300}} placeholder={"请输入标题前缀"} />
                    </Form.Item> : ""}
                    
                    <Form.Item {...tailLayout} style={{ textAlign:'left' }}>
                        <Button loading={ submitLoading } type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>  
                </Form>
            </Spin>
        </div>
    )
}

