import { useMutation, useQuery, useQueryClient } from "react-query";
import { SchoolAuth } from "../interfaces/school-auth";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useSchoolAuthList = (param?: Partial<SchoolAuth>) => {
    const client = useHttp();
    const result = useQuery<any>(['schoolAuthPage', param], () => client('schoolAuth', { data: cleanObject(param) }))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return { ...result, totalElements, data };
}

export const useAgreeSchoolAuth = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ schoolAuthId }: { schoolAuthId: number }) => client(`schoolAuth/agree?schoolAuthId=${schoolAuthId}`, {
            method: "POST",
        }), {
        onSuccess: () => queryClient.invalidateQueries('schoolAuthPage')
    }
    );
}

export const useRejectSchoolAuth = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<SchoolAuth>) => client(`schoolAuth/reject`, {
            data: params,
            method: 'POST'
        }), {
        onSuccess: () => queryClient.invalidateQueries('schoolAuthPage')
    }
    );
}