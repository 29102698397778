import { useMutation, useQuery, useQueryClient } from "react-query";
import { OrdersSetting } from "../interfaces/orders-setting";
import { useHttp } from "../utils/http";

export const useEditOrdersSetting = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<OrdersSetting>) => client(`ordersSetting`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('ordersSetting')
        }
    )
}

export const useOrdersSetting = () => {
    const client = useHttp()
    return useQuery<any>(
        ['ordersSetting', {}],
        () => client(`ordersSetting`)
    )
}