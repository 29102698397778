import { Button, Col, Divider, Form, Input, message, Radio, Row, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useSearchHot, useEditSearchHot } from "../../services/search-hot-service";
import { useDocumentTitle } from "../../utils"

export const SearchHotUpdatePage = () => {
    //标题
    useDocumentTitle('编辑关键词', true)
    const { pathname } = useLocation();
    const searchHotId = pathname.match(/dress\/search-manage\/search\/hot\/update\/(\d+)/)?.[1]
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    //表单
    const [form] = useForm();
    //准备回显数据
    const { data: editingSearchHot, isLoading } = useSearchHot(
        Number(searchHotId)
    );
    useEffect(() => {
        form.setFieldsValue(editingSearchHot?.data)
    }, [editingSearchHot, form]);
    //编辑
    const useMutateEditSearchHot = useEditSearchHot;
    const { mutateAsync } = useMutateEditSearchHot();
    //提交表单
    const [submitLoading, setSubmitLoading] = useState(false);
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...editingSearchHot?.data, ...values }).then(() => {
            message.info('编辑成功');
            window.location.href = "/#/dress/search-manage/search/hot/list";
        });
    };
    return (
        <div>
            <Spin spinning={isLoading}>
                <Row>
                    <Col span={12}>
                        <Link to={"/dress/search-manage/search/hot/list"}>
                            <Button type="primary">列表</Button>
                        </Link>
                    </Col>
                </Row>
                <Divider style={{ marginBottom: 24 }} />
                <Form
                    form={form}
                    {...layout}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label={"关键词"}
                        name={"searchHotValue"}
                        rules={[{ required: true, message: "关键词必填" }]}
                    >
                        <Input style={{ width: 380 }} placeholder={"请输入关键词"} />
                    </Form.Item>

                    <Form.Item
                        label={"排序"}
                        name={"searchHotSort"}
                        rules={[{ required: true, message: "排序必填" }]}
                    >
                        <Input style={{ width: 380 }} placeholder={"请输入排序"} />
                    </Form.Item>

                    <Form.Item
                        label={"状态"}
                        name={"searchHotShow"}
                        rules={[{ required: true, message: "状态必选" }]}
                        style={{ textAlign: 'left' }}
                    >
                        <Radio.Group>
                            <Radio defaultChecked value={true}>显示</Radio>
                            <Radio value={false}>不显示</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                        <Button loading={submitLoading} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    )
}

