import { Button, Col, message, Modal, Row, Space, Table } from "antd"
import Search from "antd/lib/input/Search"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useCompanyTypeList, useDeleteCompanyType } from "../../services/company-type-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useCompanyTypeSearchParams } from "./util"

export const CompanyTypeListPage = () => {
    useDocumentTitle('企业行业列表', true)
    const [param, setParam] = useCompanyTypeSearchParams()
    const {isLoading, error, totalElements, data: list} = useCompanyTypeList(useDebounce(param, 200))

    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) => 
            setParam({
            ...param,
            pageNum: (current -1),
        })
    }

    //删除事件
    const { mutateAsync:deleteMutateAsync } = useDeleteCompanyType();
    const confirmDeleteCompanyType = (companyTypeId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    deleteMutateAsync({ companyTypeId }).then((data) => {
                        if (data.status === 0) {
                            message.info('删除成功');
                            Modal.destroyAll();
                          } else {
                            message.error(data.msg)
                          }
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    //分页
    return (
        <div>
            <Row style={{marginBottom: 24}}>
                <Col span={12}>
                    <Link to={"/qiye/company-manage/company-type/add"}>
                        <Button type="primary">新建</Button>
                    </Link>
                </Col>
                <Col span={12} style={{textAlign:'right'}}>
                    <Search 
                        placeholder="可搜索企业行业名称" 
                        style={{ width: 200 }}
                        value={param.companyTypeName}
                        onChange={(evt) =>
                            setParam({
                            ...param,
                            companyTypeName: evt.target.value,
                            pageNum: null
                        })} 
                        onSearch={(value) =>
                            setParam({
                            ...param,
                            companyTypeName: value,
                            pageNum: null
                        })} 
                    />
                </Col>
            </Row>
            <Table 
                loading = { isLoading }
                rowKey={ columns => columns.companyTypeId } 
                pagination = { paginationProps } 
                columns = {[
                {
                    title: 'ID',
                    dataIndex: 'companyTypeId',
                    key: 'companyTypeId',
                    align: 'center',
                    width: 100,
                },
                {
                    title: '名称',
                    dataIndex: 'companyTypeName',
                    key: 'companyTypeName',
                    align: 'center',
                },
                {
                    title: '排序',
                    dataIndex: 'companyTypeSort',
                    key: 'companyTypeSort',
                    align: 'center',
                },
                {
                    title: '状态',
                    dataIndex: 'companyTypeShow',
                    key: 'companyTypeShow',
                    align: 'center',
                    render: (value) => (
                        <span>{value ? '显示' : '不显示'}</span>
                    ),
                },
                {
                    title: '操作',
                    key: 'action',
                    align: 'center',
                    render: (value, companyType) => (
                        <Space size="middle">
                            <Link to={"/qiye/company-manage/company-type/update/" + companyType.companyTypeId}>
                                编辑
                            </Link>
                            <a onClick={() => confirmDeleteCompanyType(companyType.companyTypeId)}>
                                删除
                            </a>
                        </Space>
                    ),
                    width: 150,
                }
            ]}
            dataSource={ list || [] }  />
            <ErrorBox error={ error } />
        </div>
    )
}