import { useMemo } from "react";
import { useUrlQueryParam } from "../../utils/url";

export const useSchoolSearchParams = () => {
    const [param, setParam] = useUrlQueryParam(["schoolId", "schoolAddTimeStart", "schoolAddTimeEnd", "schoolName", "schoolDelete", "schoolAttribute", "schoolLevelId", "trendsTagId", "schoolTypeId", "memberNickname", "pageNum"]);
    return [
        useMemo(() => ({
            ...param, schoolId: Number(param.schoolId) || undefined, 
            schoolDelete: Boolean(param.schoolDelete) || undefined, schoolAttribute: Number(param.schoolAttribute) || undefined,
            schoolLevelId: Number(param.schoolLevelId) || undefined, schoolTypeId: Number(param.schoolTypeId) || undefined,
            trendsTagId: Number(param.trendsTagId) || undefined,
            pageNum: Number(param.pageNum) || undefined
        }), [param]),
        setParam
    ] as const
}