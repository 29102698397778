import { Button, Col, message, Modal, Row, Space, Table, Image } from "antd"
import Search from "antd/lib/input/Search"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useBombWindowList, useDeleteBombWindow } from "../../services/bomb-window-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useBombWindowSearchParams } from "./util"

export const BombWindowListPage = () => {
    useDocumentTitle('开屏广告列表', true)
    const imgUrl = process.env.REACT_APP_IMG_URL;
    const [param, setParam] = useBombWindowSearchParams()
    const {isLoading, error, totalElements, data: list} = useBombWindowList(useDebounce(param, 200))
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) => 
            setParam({
            ...param,
            pageNum: (current -1),
        })
    }
    //删除事件
    const useMutateDeleteBombWindow = useDeleteBombWindow;
    const { mutateAsync } = useMutateDeleteBombWindow();
    const confirmDeleteBombWindow = (bombWindowId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ bombWindowId }).then(() => {
                        message.info('删除成功');
                        Modal.destroyAll();
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    //分页
    return (
        <div>
            <Row style={{marginBottom: 24}}>
                <Col span={12}>
                    <Link to={"/dress/decorate-manage/bomb-window/add"}>
                        <Button type="primary">新建</Button>
                    </Link>
                </Col>
                <Col span={12} style={{textAlign:'right'}}>
                    <Search 
                        placeholder="可搜索开屏广告标题" 
                        style={{ width: 200 }}
                        value={param.bombWindowTitle}
                        onChange={(evt) =>
                            setParam({
                            ...param,
                            bombWindowTitle: evt.target.value,
                            pageNum: null
                        })} 
                        onSearch={(value) =>
                            setParam({
                            ...param,
                            bombWindowTitle: value,
                            pageNum: null
                        })} 
                    />
                </Col>
            </Row>
            <Table 
                loading = { isLoading }
                pagination = { paginationProps } 
                rowKey={ columns => columns.bombWindowId } 
                columns = {[
                {
                    title: 'ID',
                    dataIndex: 'bombWindowId',
                    key: 'bombWindowId',
                    width: 100,
                },
                {
                    title: '标题',
                    dataIndex: 'bombWindowTitle',
                    key: 'bombWindowTitle',
                },
                {
                    title: '图片',
                    dataIndex: 'bombWindowPic',
                    key: 'bombWindowPic',
                    render: (value) => (
                        value ? <Image width={100} src={imgUrl + value}/> : ''
                    ),
                },
                {
                    title: '排序',
                    dataIndex: 'bombWindowSort',
                    key: 'bombWindowSort',
                },
                {
                    title: '状态',
                    dataIndex: 'bombWindowShow',
                    key: 'bombWindowShow',
                    render: (value) => (
                        value ? '显示' : '不显示'
                    ),
                },
                {
                    title: '操作',
                    key: 'action',
                    render: (value, bombWindow) => (
                        <Space size="middle">
                            <Link to={"/dress/decorate-manage/bomb-window/update/" + bombWindow.bombWindowId}>
                                编辑
                            </Link>
                            <a onClick={() => confirmDeleteBombWindow(bombWindow.bombWindowId)}>
                                删除
                            </a>
                        </Space>
                    ),
                    width: 150,
                },
            ]}
            dataSource={ list || [] }  />
            <ErrorBox error={ error } />
        </div>
    )
}