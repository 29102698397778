import { Button, Col, Menu, message, Modal, Row, Space, Table } from "antd"
import Search from "antd/lib/input/Search"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { usePointsGoodsClassList, useDeletePointsGoodsClass } from "../../services/points-goods-class-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { usePointsGoodsClassSearchParams } from "./util"

export const PointsGoodsClassListPage = () => {
    useDocumentTitle('分类列表', true)
    const [param, setParam] = usePointsGoodsClassSearchParams()
    const {isLoading, error, totalElements, data: list} = usePointsGoodsClassList(useDebounce(param, 200))
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) => 
            setParam({
            ...param,
            pageNum: (current -1),
        })
    }
    //删除事件
    const useMutateDeletePointsGoodsClass = useDeletePointsGoodsClass;
    const { mutateAsync } = useMutateDeletePointsGoodsClass();
    const confirmDeletePointsGoodsClass = (pointsGoodsClassId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ pointsGoodsClassId }).then(() => {
                        message.info('删除成功');
                        Modal.destroyAll();
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    //分页
    return (
        <div>
            <Menu style={{ marginBottom: 24 }} mode="horizontal" selectedKeys={['pointsGoodsClass']}>
                <Menu.Item key="pointsGoods">
                    <Link to={"/shop/market-manage/points/goods/list"}>
                        商品管理
                    </Link>
                </Menu.Item>
                <Menu.Item key="pointsGoodsClass">
                    <Link to={"/shop/market-manage/points/goodsClass/list"}>
                        分类管理
                    </Link>
                </Menu.Item>
                <Menu.Item key="pointsAdv">
                    <Link to={"/shop/market-manage/points/adv/list"}>
                        广告管理
                    </Link>
                </Menu.Item>
            </Menu>
            <Row style={{marginBottom: 24}}>
                <Col span={12}>
                    <Link to={"/shop/market-manage/points/goodsClass/add"}>
                        <Button type="primary">新建分类</Button>
                    </Link>
                </Col>
                <Col span={12} style={{textAlign:'right'}}>
                    <Search 
                        placeholder="可搜索分类名称" 
                        style={{ width: 200 }}
                        value={param.pointsGoodsClassName}
                        onChange={(evt) =>
                            setParam({
                            ...param,
                            pointsGoodsClassName: evt.target.value,
                            pageNum: null
                        })} 
                        onSearch={(value) =>
                            setParam({
                            ...param,
                            pointsGoodsClassName: value,
                            pageNum: null
                        })} 
                    />
                </Col>
            </Row>
            <Table 
                loading = { isLoading }
                rowKey={ columns => columns.pointsGoodsClassId } 
                pagination = { paginationProps } 
                columns = {[
                {
                    title: '分类ID',
                    dataIndex: 'pointsGoodsClassId',
                    key: 'pointsGoodsClassId',
                    width: 150,
                },
                {
                    title: '分类名称',
                    dataIndex: 'pointsGoodsClassName',
                    key: 'pointsGoodsClassName',
                },
                {
                    title: '操作',
                    key: 'action',
                    render: (value, pointsGoodsClass) => (
                        <Space size="middle">
                            <Link to={"/shop/market-manage/points/goodsClass/update/" + pointsGoodsClass.pointsGoodsClassId}>
                                编辑
                            </Link>
                            <a onClick={() => confirmDeletePointsGoodsClass(pointsGoodsClass.pointsGoodsClassId)}>
                                删除
                            </a>
                        </Space>
                    ),
                    width: 150,
                },
            ]}
            dataSource={ list || [] }  />
            <ErrorBox error={ error } />
        </div>
    )
}