import { useMutation, useQuery, useQueryClient } from "react-query";
import { Goods } from "../interfaces/goods";
import { GoodsGoodsServeTag } from "../interfaces/goods-goods-serve-tag";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useGoodsList = (param?: Partial<Goods>) => {
    const client = useHttp();
    const result = useQuery<any>(['goodsList', param], () => client('goods', { data: cleanObject(param) }))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return { ...result, totalElements, data };
}

export const useEditGoods = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Goods>) => client(`goods`, {
            method: 'PUT',
            data: params
        }), {
        onSuccess: () => queryClient.invalidateQueries('goodsList')
    }
    )
}

export const useAddGoods = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Goods>) => client(`goods`, {
            data: params,
            method: 'POST'
        }), {
        onSuccess: () => queryClient.invalidateQueries('goodsList')
    }
    )
}

export const useDeleteGoods = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ goodsId }: { goodsId: number }) =>
            client(`goods/${goodsId}`, {
                method: "DELETE",
            }), {
        onSuccess: () => queryClient.invalidateQueries('goodsList')
    }
    );
};

export const useRecommedGoods = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ goodsId }: { goodsId: number }) =>
            client(`goods/${goodsId}/recommend`, {
                method: "PUT",
            }), {
        onSuccess: () => queryClient.invalidateQueries('goodsList')
    }
    );
};

export const useUnRecommedGoods = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ goodsId }: { goodsId: number }) =>
            client(`goods/${goodsId}/unRecommend`, {
                method: "PUT",
            }), {
        onSuccess: () => queryClient.invalidateQueries('goodsList')
    }
    );
};

export const useUnStoreRecommedGoods = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ goodsId }: { goodsId: number }) =>
            client(`goods/${goodsId}/unStoreRecommend`, {
                method: "PUT",
            }), {
        onSuccess: () => queryClient.invalidateQueries('goodsList')
    }
    );
};
export const useStoreRecommedGoods = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ goodsId }: { goodsId: number }) =>
            client(`goods/${goodsId}/storeRecommend`, {
                method: "PUT",
            }), {
        onSuccess: () => queryClient.invalidateQueries('goodsList')
    }
    );
};
export const useGoods = (goodsId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['goods', { goodsId }],
        () => client(`goods/${goodsId}`),
        {
            enabled: Boolean(goodsId)
        }
    )
}

export const useGoodsMutation = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ goodsId }: { goodsId: number }) =>
            client(`goods/${goodsId}`, {
                method: "GET",
            }), {
        onSuccess: () => queryClient.invalidateQueries('goods')
    }
    );
};

//单规格商品
export const useSingleSpecificationGoodsList = (param?: Partial<Goods>) => {
    const client = useHttp();
    const result = useQuery<any>(['singleSpecificationGoodsList', param], () => client('goods/singleSpecification', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    const last = result.data?.data?.last;
    return {...result, totalElements, last, data };
}

//推广商品
export const useShareGoods = () => {
    const client = useHttp()
    return useMutation(
        ({ goodsId }: { goodsId: number }) =>
            client(`goods/shareGoods/${goodsId}`, {
                method: "GET",
            })
    );
};


//查询标签
export const useGoodsGoodsServeTagList = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ goodsId }: { goodsId: number }) => client(`goodsGoodsServeTag?goodsId=${goodsId}`, {
            method: "GET",
        }), {
            onSuccess: () => queryClient.invalidateQueries('goodsGoodsServeTagList')
        }
    );
};

//设置标签
export const useAddGoodsGoodsServeTag = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<GoodsGoodsServeTag>) => client(`goodsGoodsServeTag`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('goods')
        }
    )
}
