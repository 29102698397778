import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Radio,
  Row,
  Select,
  Spin,
  Upload,
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useFloor, useEditFloor } from "../../services/mall-floor-service";
import { usePageUrl, usePageUrlList } from "../../services/page-url-service";
import { useDocumentTitle } from "../../utils";
import { getToken } from "../../utils/auth-provider";
import { useAreaListCity } from "../../services/area-service";
const { Option } = Select;

export const MallFloorUpdatePage = () => {
  //标题
  useDocumentTitle("编辑楼层", true);
  //API地址
  const apiUrl = process.env.REACT_APP_API_URL;
  const imgUrl = process.env.REACT_APP_IMG_URL;
  const token = getToken();
  const { pathname } = useLocation();
  const floorId = pathname.match(/shop\/dress-manage\/mall-floor\/update\/(\d+)/)?.[1];
  //布局
  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 7 },
  };
  const tailLayout = {
    wrapperCol: { offset: 3 },
  };
  //是否跳转外链
  const [externalLinksShow, setExternalLinksShow] = useState(false);
  function radioHandleChange(e: any) {
    setExternalLinksShow(e.target.value);
  }
  //准备跳转链接
  const [propertyShow, setPropertyShow] = useState(false);
  const [propertyName, setPropertyName] = useState("参数");
  const { data: pageUrlList } = usePageUrlList();
  const options = pageUrlList?.map((item: any, value: any) => (
    <Option value={item.pageUrlId} key={item.pageUrlId}>
      {item.pageUrlName}
    </Option>
  ));
  //option选中与非选中
  const { mutateAsync: mutateAsyncPageUrl } = usePageUrl();
  function optionHandleChange(pageUrlId: number) {
    mutateAsyncPageUrl({ pageUrlId }).then((data) => {
      const pageUrlFlag = data.data.pageUrlFlag;
      const pageUrlPropertyName = data.data.pageUrlPropertyName;
      setPropertyShow(pageUrlFlag);
      setPropertyName(pageUrlPropertyName);
    });
  }
  //表单
  const [form] = useForm();
  //准备回显数据
  const { data: editingFloor, isLoading } = useFloor(Number(floorId));
  const [imageUrl, setImageUrl] = useState("");
  //专题
  useEffect(() => {
    form.setFieldsValue(editingFloor?.data);
    const picUrl =
      imgUrl + editingFloor?.data?.floorPic;
    setImageUrl(picUrl);
    //跳转外链
    const floorExternalLinks = editingFloor?.data?.floorExternalLinks;
    setExternalLinksShow(floorExternalLinks);
    //跳转链接
    const pageUrlPropertyValue = editingFloor?.data?.pageUrlPropertyValue;
    if (floorExternalLinks == true && pageUrlPropertyValue != null) {
      setPropertyShow(true);
    } else {
      setPropertyShow(false);
    }
  }, [editingFloor, form]);
  //图片上传
  const [uploadLoading, setUploadLoading] = useState(false);
  const uploadButton = (
    <div>
      {uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>上传图片</div>
    </div>
  );
  const beforeUpload = (file: any) => {
    setImageUrl("");
    setUploadLoading(true);
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      setUploadLoading(false);
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      setUploadLoading(false);
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  const handleChange = (info: any) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      setUploadLoading(false);
      setImageUrl(info?.file?.response?.data?.realPath);
      const relativePath = info?.file?.response?.data?.relativePath;
      form.setFieldsValue({
        floorPic: relativePath,
      });
    }
  };
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  //编辑
  const useMutateEditFloor = useEditFloor;
  const { mutateAsync, error } = useMutateEditFloor();
  //提交表单
  const [submitLoading, setSubmitLoading] = useState(false);
  const onFinish = (values: any) => {
    setSubmitLoading(true);
    mutateAsync({ ...editingFloor?.data, ...values }).then(() => {
      message.info("编辑成功");
      window.location.href = "/#/shop/dress-manage/mall-floor/list";
    });
  };
  //准备区域数据
  const { data: areaList } = useAreaListCity();
  const areaListOptions = areaList?.data?.map((item: any, value: any) => (
    <Select.Option value={item.areaId} key={item.areaId}>
      {item.areaName}
    </Select.Option>
  ));
  return (
    <div>
      <Spin spinning={isLoading}>
        <Row>
          <Col span={12}>
            <Link to={"/shop/dress-manage/mall-floor/list"}>
              <Button type="primary">楼层列表</Button>
            </Link>
          </Col>
        </Row>
        <Divider style={{ marginBottom: 24 }} />
        <Form form={form} {...layout} onFinish={onFinish}>
          <Form.Item
            label={"楼层标题"}
            name={"floorTitle"}
            rules={[{ required: true, message: "楼层标题必填" }]}
          >
            <Input placeholder={"请输入楼层标题"} />
          </Form.Item>

          <Form.Item
            name={"uploadPic"}
            label={"楼层图片"}
            valuePropName="fileList"
            getValueFromEvent={normFile}
            rules={[{ required: true, message: "楼层图片必传" }]}
            style={{ textAlign: "left" }}
            initialValue={[
              {
                uid: "",
                lastModified: "",
                lastModifiedDate: "",
                name: "",
                size: "",
                type: "",
                percent: "",
                originFileObj: {
                  uid: "",
                },
                status: "done",
                response: {
                  status: 0,
                  data: {
                    relativePath: "",
                    realPath: "",
                  },
                },
                xhr: {},
              },
            ]}
          >
            <Upload
              name="imageFile"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action={apiUrl + "/upload/img"}
              beforeUpload={beforeUpload}
              onChange={handleChange}
              headers={{ Authorization: "Bearer " + token }}
            >
              {imageUrl ? (
                <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
              ) : (
                uploadButton
              )}
            </Upload>
          </Form.Item>

          <Form.Item
            hidden
            label={"楼层图片"}
            name={"floorPic"}
            rules={[{ required: true, message: "楼层图片必传" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={"楼层样式"}
            name={"floorStyle"}
            rules={[{ required: true, message: "楼层样式必选" }]}
            style={{ textAlign: "left" }}
          >
            <Radio.Group>
              <Radio value={1}>一行一个大图</Radio>
              <Radio value={2}>一行一个小图</Radio>
              <Radio value={3}>一行两个</Radio>
              <Radio value={4}>一行多个</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label={"是否跳转外链"}
            name={"floorExternalLinks"}
            rules={[{ required: true, message: "跳转外链必选" }]}
            style={{ textAlign: "left" }}
          >
            <Radio.Group onChange={radioHandleChange}>
              <Radio value={true}>是</Radio>
              <Radio value={false}>否</Radio>
            </Radio.Group>
          </Form.Item>

          {externalLinksShow ? (
            <Form.Item
              label={"跳转链接"}
              name={"pageUrlId"}
              rules={[{ required: true, message: "跳转链接必选" }]}
            >
              <Select
                showSearch
                placeholder="请选择跳转链接"
                optionFilterProp="children"
                style={{ textAlign: "left" }}
                onChange={optionHandleChange}
              >
                {options}
              </Select>
            </Form.Item>
          ) : (
            ""
          )}

          {propertyShow && externalLinksShow ? (
            <Form.Item
              label={propertyName}
              name={"pageUrlPropertyValue"}
              rules={[{ required: true, message: "链接参数必传" }]}
            >
              <Input placeholder={"请输入链接参数"} />
            </Form.Item>
          ) : (
            ""
          )}

          <Form.Item
            label={"楼层排序"}
            name={"floorSort"}
            rules={[{ required: true, message: "楼层排序必填" }]}
          >
            <Input placeholder={"请输入楼层排序"} />
          </Form.Item>

          <Form.Item
            label={"是否显示"}
            name={"floorShow"}
            rules={[{ required: true, message: "楼层显示必选" }]}
            style={{ textAlign: "left" }}
          >
            <Radio.Group>
              <Radio value={true}>显示</Radio>
              <Radio value={false}>不显示</Radio>
            </Radio.Group>
          </Form.Item>
          <Form.Item
            label={"显示区域"}
            name={"areaId"}
            rules={[{ required: true, message: "显示区域必选" }]}
          >
            <Select
              showSearch
              placeholder="请选择显示区域"
              optionFilterProp="children"
              style={{ textAlign: "left", width: 380 }}
            >
              {areaListOptions}
            </Select>
          </Form.Item>
          <Form.Item {...tailLayout} style={{ textAlign: "left" }}>
            <Button loading={submitLoading} type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
