import { Button, Card, Col, Form, message, Modal, Row, Space, Table, Select } from "antd"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useAreaListAll } from "../../services/area-service"
import { useBenefitList, useDeleteBenefit } from "../../services/benefit-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useBenefitSearchParams } from "./util"
const { Option } = Select;

export const BenefitListPage = () => {
    useDocumentTitle('单项列表', true)
    const [param, setParam] = useBenefitSearchParams()
    const { isLoading, error, totalElements, data: list } = useBenefitList(useDebounce(param, 200))

    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) =>
            setParam({
                ...param,
                pageNum: (current - 1),
            })
    }

    const { data: cityAreaList } = useAreaListAll(4);
    const cityAreaOptions = cityAreaList?.data?.map((item: any) =>
        <Option value={item.value}>{item.label}</Option>
    )

    //提交
    const onFinish = (values: any) => {
        setParam({
            ...param,
            benefitType: values.benefitType,
            cityAreaId: values.cityAreaId,
            pageNum: null
        })
    }

    //删除事件
    const { mutateAsync: deleteMutateAsync } = useDeleteBenefit();
    const confirmDeleteBenefit = (benefitId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    deleteMutateAsync({ benefitId }).then((data) => {
                        if (data.status === 0) {
                            message.info('删除成功');
                            Modal.destroyAll();
                        } else {
                            message.error(data.msg)
                        }
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };

    //分页
    return (
        <div>
            <Row style={{ marginBottom: 24 }}>
                <Col span={12}>
                    <Link to={"/taocan/combo-manage/benefit/add"}>
                        <Button type="primary">新建</Button>
                    </Link>
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                </Col>
            </Row>
            <Card style={{ backgroundColor: "#f2f3f5", margin: '24px 0 24px 0' }}>
                <Form onFinish={onFinish}>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item name="benefitType" label="单项类型">
                                <Select
                                    placeholder='请选择单项类型'
                                    showSearch
                                    allowClear
                                    optionFilterProp='children'
                                >
                                    <Option value={2}>学校</Option>
                                    <Option value={3}>机构</Option>
                                    <Option value={5}>企业</Option>
                                    <Option value={6}>商家</Option>
                                    <Option value={9}>简章</Option>
                                    <Option value={10}>动态</Option>
                                    <Option value={11}>招聘</Option>
                                    <Option value={12}>课程</Option>
                                    <Option value={14}>商品</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="cityAreaId" label="选择城市">
                                <Select
                                    placeholder='请选择城市'
                                    showSearch
                                    allowClear
                                    optionFilterProp='children'
                                >
                                    {cityAreaOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item style={{ marginBottom: 0 }}>
                        <Button style={{ marginLeft: 70 }} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
            <Table
                loading={isLoading}
                rowKey={columns => columns.benefitId}
                pagination={paginationProps}
                columns={[
                    {
                        title: 'ID',
                        dataIndex: 'benefitId',
                        key: 'benefitId',
                        align: 'center',
                        width: 100,
                        fixed: 'left',
                    },
                    {
                        title: '城市',
                        dataIndex: 'cityAreaName',
                        key: 'cityAreaName',
                        align: 'center',
                        width: 200,
                        fixed: 'left',
                    },
                    {
                        title: '类型',
                        dataIndex: 'benefitType',
                        key: 'benefitType',
                        align: 'center',
                        width: 150,
                        render: (value) => (
                            value === 2 ? '学校' : value === 3 ? '机构' : value === 5 ? '企业' : value === 6 ? '商家' : value === 9 ? '简章'
                                : value === 10 ? '动态' : value === 11 ? '招聘' : value === 12 ? '课程' : value === 14 ? '商品' : '-'
                        )
                    },
                    {
                        title: '置顶',
                        dataIndex: 'benefitTopPrice',
                        key: 'benefitTopPrice',
                        align: 'center',
                        width: 150,
                        render: (value) => (
                            value ? value + '元/天' : '-'
                        )
                    },
                    {
                        title: '刷新',
                        dataIndex: 'benefitRefreshPrice',
                        key: 'benefitRefreshPrice',
                        align: 'center',
                        width: 150,
                        render: (value) => (
                            value ? value + '元/次' : '-'
                        )
                    },
                    {
                        title: '招聘',
                        dataIndex: 'benefitRecruitPrice',
                        key: 'benefitRecruitPrice',
                        align: 'center',
                        width: 150,
                        render: (value) => (
                            value ? value + '元/次' : '-'
                        )
                    },
                    {
                        title: '操作',
                        key: 'action',
                        align: 'center',
                        render: (value, benefit) => (
                            <Space size="middle">
                                <Link to={"/taocan/combo-manage/benefit/update/" + benefit.benefitId}>
                                    编辑
                                </Link>
                                <a onClick={() => confirmDeleteBenefit(benefit.benefitId)}>
                                    删除
                                </a>
                            </Space>
                        ),
                        width: 150,
                        fixed: 'right',
                    }
                ]}
                dataSource={list || []}
                scroll={{ x: 1600 }}
            />
            <ErrorBox error={error} />
        </div>
    )
}