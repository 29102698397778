import { useMutation, useQuery, useQueryClient } from "react-query";
import { GoodsSpecValue } from "../interfaces/goods-spec-value";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useGoodsSpecValueList = (param?: Partial<GoodsSpecValue>) => {
    const client = useHttp();
    const result = useQuery<any>(['goodsSpecValueList', param], () => client('goodsSpecValue', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditGoodsSpecValue = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<GoodsSpecValue>) => client(`goodsSpecValue`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('goodsSpecValueList')
        }
    )
}

export const useAddGoodsSpecValue = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<GoodsSpecValue>) => client(`goodsSpecValue`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('goodsSpecValueList')
        }
    )
}

export const useDeleteGoodsSpecValue = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ goodsSpecValueId }: { goodsSpecValueId: number}) =>
            client(`goodsSpecValue/${goodsSpecValueId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('goodsSpecValueList')
        }
    );
};

export const useGoodsSpecValue = (goodsSpecValueId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['goodsSpecValue', {goodsSpecValueId}],
        () => client(`goodsSpecValue/${goodsSpecValueId}`),
        {
            enabled: Boolean(goodsSpecValueId)
        }
    )
}

/**查询全部 */
export const useGoodsSpecValueListAll = () => {
    const client = useHttp();
    return useMutation(
        (params: Partial<GoodsSpecValue>) =>
            client(`goodsSpecValue/listAll?goodsSpecId=` + params.goodsSpecId, {
                method: "GET"
            }),
    );
};