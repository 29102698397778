import { useMutation, useQuery, useQueryClient } from "react-query";
import { CompanyScale } from "../interfaces/company-scale";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useCompanyScaleList = (param?: Partial<CompanyScale>) => {
    const client = useHttp();
    const result = useQuery<any>(['companyScalePage', param], () => client('companyScale', { data: cleanObject(param) }))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return { ...result, totalElements, data };
}

export const useEditCompanyScale = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<CompanyScale>) => client(`companyScale`, {
            method: 'PUT',
            data: params
        }), {
        onSuccess: () => queryClient.invalidateQueries('companyScalePage')
    }
    )
}

export const useAddCompanyScale = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<CompanyScale>) => client(`companyScale`, {
            data: params,
            method: 'POST'
        }), {
        onSuccess: () => queryClient.invalidateQueries('companyScalePage')
    }
    )
}

export const useDeleteCompanyScale = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ companyScaleId }: { companyScaleId: number }) =>
            client(`companyScale/${companyScaleId}`, {
                method: "DELETE",
            }), {
        onSuccess: () => queryClient.invalidateQueries('companyScalePage')
    }
    );
};

export const useCompanyScale = (companyScaleId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['companyScale', { companyScaleId }],
        () => client(`companyScale/${companyScaleId}`),
        {
            enabled: Boolean(companyScaleId)
        }
    )
}

export const useCompanyScaleListAll = () => {
    const client = useHttp();
    return useQuery<any>(["companyScaleList", { }], () =>
        client(`companyScale/list`)
    );
};