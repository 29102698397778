import { Button, Col, Divider, Form, Input, InputNumber, message, Radio, Row, Cascader } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useMajorClassListAll } from "../../services/major-class-service";
import { useAddMajor } from "../../services/major-service";
import { useDocumentTitle } from "../../utils"

export const MajorAddPage = () => {
    //标题
    useDocumentTitle('新建专业', true)
    const navigate = useNavigate();

    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };

    //分类
    const { data: majorClassList } = useMajorClassListAll(2);
    const [majorClassId, setMajorClassId] = useState();
    const handleClass = (e: any) => {
        if (e && e.length > 1) {
            setMajorClassId(e[1])
        }
    }

    //表单
    const [form] = useForm();

    //新建
    const [submitLoading, setSubmitLoading] = useState(false);
    const { mutateAsync } = useAddMajor();
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...values, majorClassId }).then((data) => {
            if (data.status === 0) {
                message.success(data.msg);
                navigate('/xuexiao/major-manage/major/list')
            } else {
                setSubmitLoading(false);
                message.error(data.msg);
            }
        }).catch(err => {
            message.error('提交失败，请重新提交');
            setSubmitLoading(false);
        });
    };

    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/xuexiao/major-manage/major/list"}>
                        <Button type="primary">列表</Button>
                    </Link>
                </Col>
            </Row>

            <Divider style={{ marginBottom: 24 }} />

            <Form
                form={form}
                {...layout}
                onFinish={onFinish}
                initialValues={{
                    majorSort: 0,
                    majorShow: true,
                }}
            >
                <Form.Item
                    label={"名称"}
                    name={"majorName"}
                    rules={[{ required: true, message: "名称必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入名称"} />
                </Form.Item>

                <Form.Item
                    label={"薪资"}
                    name={"majorSalary"}
                >
                    <InputNumber min={0} step={0.01} style={{ width: 380 }} placeholder={"请输入薪资"} addonAfter={'元/月'} />
                </Form.Item>

                <Form.Item
                    label={"分类"}
                    name={"classId"}
                    rules={[{ required: true, message: "分类必选" }]}
                >
                    <Cascader
                        style={{ width: 380 }}
                        options={majorClassList?.data}
                        onChange={handleClass}
                        placeholder="请选择分类"
                    />
                </Form.Item>

                <Form.Item
                    label={"排序"}
                    name={"majorSort"}
                    rules={[{ required: true, message: "排序必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入排序"} />
                </Form.Item>

                <Form.Item
                    label={"状态"}
                    name={"majorShow"}
                    rules={[{ required: true, message: "状态必选" }]}
                    style={{ textAlign: 'left' }}
                >
                    <Radio.Group>
                        <Radio defaultChecked value={true}>显示</Radio>
                        <Radio value={false}>不显示</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                    <Button loading={submitLoading} type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

