import { Button, Form, Input, Menu, message, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import { useCourseSearchDefault, useEditCourseSearchDefault } from '../../services/course-search-default-service';
import { useDocumentTitle } from "../../utils"

export const CourseSearchDefaultIndexPage = () => {
    //标题
    useDocumentTitle('默认关键词', true)
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    //表单
    const [form] = useForm();
    //准备回显数据
    const { data: editingSearchDefault, isLoading } = useCourseSearchDefault();
    useEffect(() => {
        form.setFieldsValue(editingSearchDefault?.data)
    }, [editingSearchDefault, form]);
    //编辑
    const { mutateAsync } = useEditCourseSearchDefault();
    //提交表单
    const [submitLoading, setSubmitLoading] = useState(false);
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...editingSearchDefault?.data, ...values }).then(() => {
            message.info('编辑成功');
            setSubmitLoading(false);
        });
    };
    return (
        <div>
            <Menu style={{ marginBottom: 24 }} mode="horizontal" selectedKeys={['course-search-default']}>
                <Menu.Item key="course-search-hot">
                    <Link to={"/jigou/course-manage/course-search/hot/list"}>
                        关键词列表
                    </Link>
                </Menu.Item>
                <Menu.Item key="course-search-default">
                    <Link to={"/jigou/course-manage/course-search/default/index"}>
                        默认关键词
                    </Link>
                </Menu.Item>
            </Menu>
            <Spin spinning={isLoading}>
                <Form
                    form={form}
                    {...layout}
                    onFinish={onFinish}>
                    <Form.Item
                        label={"关键词"}
                        name={"courseSearchDefaultValue"}
                    >
                        <Input style={{ width: 380 }} placeholder={"请输入关键词"} />
                    </Form.Item>

                    <Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                        <Button loading={submitLoading} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    )
}

