import { useMutation, useQuery, useQueryClient } from "react-query";
import { Planner } from "../interfaces/planner";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const usePlannerList = (param?: Partial<Planner>) => {
    const client = useHttp();
    const result = useQuery<any>(['plannerPage', param], () => client('planner', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditPlanner = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Planner>) => client(`planner`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('plannerPage')
        }
    )
}

export const useUpPlanner = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Planner>) => client(`planner/up`, {
            data: params,
            method: 'PUT'
        }), {
            onSuccess: () => queryClient.invalidateQueries('plannerPage')
        }
    )
}

export const useOffPlanner = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Planner>) =>
            client(`planner/off`, {
                data: params,
                method: 'PUT'
            }), {
            onSuccess: () => queryClient.invalidateQueries('plannerPage')
        }
    );
};

export const usePlanner = (plannerId?: number) => {
    const client = useHttp()
    const result = useQuery<any>(
        ['planner', {plannerId}],
        () => client(`planner/${plannerId}`),
        {
            enabled: Boolean(plannerId)
        }
    )
    return {data:result.data?.data}
}

export const usePlannerListAll = () => {
    const client = useHttp();
    return useQuery<any>(["plannerList", {}], () =>
        client(`planner/list`)
    );
};