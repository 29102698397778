import { Button, Col, message, Modal, Row, Space, Table, Image, Card, Form, Input } from "antd"
import Search from "antd/lib/input/Search"
import { Link } from "react-router-dom"
import dayjs from "dayjs"
import { ErrorBox } from "../../components/lib"
import { usePointsTaskList, useDeletePointsTask } from "../../services/points-task-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { usePointsTaskSearchParams } from "./util"

export const PointsTaskListPage = () => {
    useDocumentTitle('积分任务列表', true)
    const imgUrl = process.env.REACT_APP_IMG_URL;
    const [param, setParam] = usePointsTaskSearchParams()
    const {isLoading, error, totalElements, data: list} = usePointsTaskList(useDebounce(param, 200))

	//分页
	const paginationProps = {
	  showSizeChanger: true,
	  onShowSizeChange: (current: number, pageSize: number) => {
		setParam({
		  ...param,
		  pageNum: current - 1,
		  pageSize,
		});
	  },
	  defaultCurrent: 1,
	  pageSize: param.pageSize,
	  total: totalElements,
	  onChange: (current: number, pageSize: number) =>
		setParam({
		  ...param,
		  pageNum: current - 1,
		  pageSize,
		}),
	};
    //删除事件
    const { mutateAsync } = useDeletePointsTask();
    const confirmDeletePointsTask = (pointsTaskId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ pointsTaskId }).then((data) => {
                        if (data.status === 0) {
                            message.info('删除成功');
                            Modal.destroyAll();
                          } else {
                            message.error(data.msg)
                          }
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    //分页
    return (
        <div>
            <Row style={{marginBottom: 24}}>
                <Col span={12}>
                    <Link to={"/shop/market-manage/points-task/add"}>
                        <Button type="primary">新建</Button>
                    </Link>
                </Col>
            </Row>
            <Table 
                loading = { isLoading }
                rowKey={ columns => columns.pointsTaskId } 
                pagination = { paginationProps }
                scroll={{x:1500}}
            	dataSource={ list || [] }
                columns = {[
                {
                    title: 'ID',
                    dataIndex: 'pointsTaskId',
                    key: 'pointsTaskId',
                    align: 'center',
                    width: 100,
                },

                {
                    title: '任务类型',
                    dataIndex: 'taskType',
                    key: 'taskType',
                    width: 150,
                    align: 'center',
                    render(value, record, index) {
                        return (<>{value==1?"完善头像":
                        value==2?"绑定手机号":value==3?"完成下单":""}</>)
                    },
                },

                {
                    title: '完成任务奖励积分',
                    dataIndex: 'taskPoints',
                    key: 'taskPoints',
                    width: 150,
                    align: 'center',
                },

                {
                    title: '限制次数',
                    dataIndex: 'taskLimitTimes',
                    key: 'taskLimitTimes',
                    width: 150,
                    align: 'center',
                    render(value, record, index) {
                        return (<>{record?.taskLimt?record?.taskLimitTimes:"不限制"}</>)
                    },
                },

                {
                    title: '状态',
                    dataIndex: 'taskState',
                    key: 'taskState',
                    width: 150,
                    align: 'center',
                    render(value, record, index) {
                        return (<>{value?"开启":"关闭"}</>)
                    },
                },

				{
                    title: '图片',
                    dataIndex: 'taskPic',
                    key: 'taskPic',
                    align: 'center',
                    width: 100,
                    render: (value) => (
                        <Image width={80} src={imgUrl + value}/>
                    ),
                },

                {
                    title: '操作',
                    key: 'action',
                    align: 'center',
                    width: 200,
                    fixed:"right",
                    render: (value, pointsTask) => (
                        <Space size="middle">
                            <Link to={"/shop/market-manage/points-task/update/" + pointsTask.pointsTaskId}>
                                编辑
                            </Link>
                            <a onClick={() => confirmDeletePointsTask(pointsTask.pointsTaskId)}>
                                删除
                            </a>
                        </Space>
                    )
                }
            ]} />
            <ErrorBox error={ error } />
        </div>
    )
}