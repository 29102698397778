import { Button, Card, Col, Image, Divider, Row, Avatar } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useDocumentTitle } from "../../utils";
import dayjs from "dayjs";
import { useAgentSearchParams } from "./util";
import { useAgent } from "../../services/agent-service";

export const AgentDetailPage = () => {
  //标题
  useDocumentTitle("代理商详情", true);
  const [param] = useAgentSearchParams();
  const imgUrl = process.env.REACT_APP_IMG_URL;
  let agentId = param.agentId;

  //表单

  //准备回显
  const { data: agent, result: result } = useAgent(agentId);
  console.log("agent", agent);

  //内容图
  const [visibleMore, setVisibleMore] = useState(false);
  const agentMorePicsImage = agent?.agentMorePicsList?.map((item: any) => {
    return (
      <>
        <Image src={imgUrl + item}></Image>
      </>
    );
  }); //内容图
  const [visibleEnvironment, setVisibleEnvironment] = useState(false);
  const agentEnvironmentPicsImage = agent?.agentEnvironmentPicsList?.map(
    (item: any) => {
      return (
        <>
          <Image src={imgUrl + item}></Image>
        </>
      );
    }
  );

  return (
    <div>
      <Row>
        <Col span={12}>
          <Link to={"/agent/agent-manage/agent/list"}>
            <Button type="primary">列表</Button>
          </Link>
        </Col>
      </Row>

      <Divider style={{ marginBottom: 24 }} />
      <Card title={"基本信息"}>
        <Row gutter={16}>
          <Col className="gutter-row" span={4}>
            <div style={{ padding: "8px 0" }}>
              代理商名称: {agent?.agentName ? agent?.agentName : "-"}
            </div>
          </Col>
          <Col className="gutter-row" span={6}>
            <div style={{ padding: "8px 0" }}>
              申请时间:{" "}
              {agent?.agentAddTime
                ? dayjs(agent?.agentAddTime).format("YYYY-MM-DD")
                : ""}
            </div>
          </Col>
          <Col className="gutter-row" span={6}>
            <div style={{ padding: "8px 0" }}>
              更新时间:{" "}
              {agent?.agentUpdateTime
                ? dayjs(agent?.agentUpdateTime).format("YYYY-MM-DD hh:mm:ss")
                : ""}
            </div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className="gutter-row" span={4}>
            <div style={{ padding: "8px 0" }}>
              代理位置: {agent?.areaName ? agent?.areaName : ""}
            </div>
          </Col>
          <Col className="gutter-row" span={4}>
            <div style={{ padding: "8px 0" }}>
              代理类型: {agent?.agentType == 1 ? "省代" : "市代"}
            </div>
          </Col>
          <Col className="gutter-row" span={4}>
            <div style={{ padding: "8px 0" }}>
              上级代理:{" "}
              {agent?.agentParentId ? agent?.parentAgent?.agentName : "无"}
            </div>
          </Col>
        </Row>
        <Row gutter={16}>
          <Col className="gutter-row" span={4}>
            <div style={{ padding: "8px 0" }}>
              申请状态: {agent?.agentStateValue}
            </div>
          </Col>
          <Col className="gutter-row" span={4}>
            <div style={{ padding: "8px 0" }}>
              审核人: {agent?.lastOptionName}
            </div>
          </Col>
          {agent?.agentState != 30 && agent?.mark ? (
            <>
              <Col className="gutter-row" span={4}>
                <div style={{ padding: "8px 0" }}>拒绝理由: {agent?.mark}</div>
              </Col>
            </>
          ) : (
            ""
          )}
        </Row>
        {agent?.member ? (
          <>
            <Row gutter={16}>
              <Col className="gutter-row" span={12}>
                <div style={{ padding: "8px 0" }}>
                  关联用户: <Avatar src={agent?.member?.memberHeadImg} />
                  <Link
                    to={`/user/user-manage/member/detail?memberId=${agent?.member?.memberId}`}
                  >
                    {" "}
                    {agent?.member?.memberNickname}
                  </Link>
                </div>
              </Col>
            </Row>
          </>
        ) : (
          ""
        )}
      </Card>

      {/* <Card title={"图片展示"} style={{marginTop:"20px"}}>
                <Row gutter={16}>
                    <Col className="gutter-row" span={5} style={{minWidth:250}}>
                        <Card title={"背景图"} style={{textAlign:"center",minWidth:250}}>
                                {agent?.agentPic?<>
                                <Image preview={{ visible: false }} width={200} src={imgUrl +agent?.agentPic }></Image>
                                </>:""}
                        </Card>
                    </Col>
                    <Col className="gutter-row" span={5} style={{minWidth:250}}>
                    <Card title={"内容展示图"} style={{textAlign:"center",minWidth:250}}>
                        {agent?.agentMorePics?<>
                            <Image
                                preview={{ visible: false }}
                                width={200}
                                src={imgUrl +agent?.agentMorePicsList[0] }
                                onClick={() => setVisibleMore(true)}
                            />
                            <div style={{ display: 'none' }}>
                                <Image.PreviewGroup preview={{visible:visibleMore ,
                                     onVisibleChange: (vis) => setVisibleMore(vis) }}>
                                {agentMorePicsImage}
                                </Image.PreviewGroup>
                            </div></>:""
                        }
                        </Card>
                        
                    </Col>
                    <Col className="gutter-row" span={5} style={{minWidth:250}}>
                    <Card title={"环境展示"} style={{textAlign:"center",minWidth:250}}>
                        {agent?.agentEnvironmentPics?<>
                            <Image
                                preview={{ visible: false }}
                                width={200}
                                src={imgUrl +agent?.agentEnvironmentPicsList[0] }
                                onClick={() => setVisibleEnvironment(true)}
                            />
                            <div style={{ display: 'none' }}>
                                <Image.PreviewGroup preview={{visible:visibleEnvironment ,
                                     onVisibleChange: (vis) => setVisibleEnvironment(vis) }}>
                                {agentEnvironmentPicsImage}
                                </Image.PreviewGroup>
                            </div></>:""
                        }
                        </Card>
                        </Col>
                </Row>
            </Card> */}
      <Card title={"收益"} style={{ marginTop: "20px" }}>
        
        <Row gutter={16}>
            <Col className="gutter-row" span={4}>
                <div style={{ padding: "8px 0" }}>
                商品分润: {agent?.agentGoodsProportion ? agent?.agentGoodsProportion : "0"}%
                </div>
            </Col>
            <Col className="gutter-row" span={4}>
                <div style={{ padding: "8px 0" }}>
                课程分润: {agent?.agentCourseProportion ? agent?.agentCourseProportion : "0"}%
                </div>
            </Col>
            <Col className="gutter-row" span={4}>
                <div style={{ padding: "8px 0" }}>
                服务分润: {agent?.agnetServerProportion ? agent?.agnetServerProportion : "0"}%
                </div>
            </Col>
            </Row>
        <Row gutter={16}>
          <Col className="gutter-row" span={4}>
            <div style={{ padding: "8px 0" }}>
                现有收益:  {agent?.agentMoney}
            </div>
          </Col>
          <Col className="gutter-row" span={4}>
            <div style={{ padding: "8px 0" }}>
                累计收益:  {agent?.agentMoneyTotal}
            </div>
          </Col>
          <Col className="gutter-row" span={4}>
            <div style={{ padding: "8px 0" }}>
                提现收益:  {agent?.agentMoneyUse}
            </div>
          </Col>
        </Row>
      </Card>
    </div>
  );
};
