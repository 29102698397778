import './App.css';
import { useAuth } from './context/auth-context';
import { ErrorBoundary } from './components/error-boundary';
import { FullPageErrorFallback } from './components/lib';
import { Authenticated } from './pages/authenticated-app';
import { Unauthenticated } from './pages/unauthenticated';

function App() {
  const {adminAccount}  = useAuth()
  return (
    <ErrorBoundary fallbackRender={FullPageErrorFallback}>
      <div className="App">
        {adminAccount? <Authenticated/> : <Unauthenticated/>}
      </div>
    </ErrorBoundary>

  );
}

export default App;
