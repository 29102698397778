import { useMutation, useQuery, useQueryClient } from "react-query";
import { Major } from "../interfaces/major";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useMajorList = (param?: Partial<Major>) => {
    const client = useHttp();
    const result = useQuery<any>(['majorPage', param], () => client('major', { data: cleanObject(param) }))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return { ...result, totalElements, data };
}

export const useEditMajor = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Major>) => client(`major`, {
            method: 'PUT',
            data: params
        }), {
        onSuccess: () => queryClient.invalidateQueries('majorPage')
    }
    )
}

export const useAddMajor = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Major>) => client(`major`, {
            data: params,
            method: 'POST'
        }), {
        onSuccess: () => queryClient.invalidateQueries('majorPage')
    }
    )
}

export const useDeleteMajor = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ majorId }: { majorId: number }) =>
            client(`major/${majorId}`, {
                method: "DELETE",
            }), {
        onSuccess: () => queryClient.invalidateQueries('majorPage')
    }
    );
};

export const useMutationMajor = () => {
    const client = useHttp()
    return useMutation(
        ({ majorId }: { majorId: number }) =>
            client(`major/${majorId}`, {
                method: "GET",
            })
    );
};

export const useMajor = (majorId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['major', { majorId }],
        () => client(`major/${majorId}`),
        {
            enabled: Boolean(majorId)
        }
    )
}
