import { useMemo } from "react";
import { useUrlQueryParam } from "../../utils/url";

export const useCompanySearchParams = () => {
    const [param, setParam] = useUrlQueryParam(["companyId", "companyAddTimeStart", "companyAddTimeEnd", "companyName", "companyDelete", "companyAttributeId", "companyTagId", "companyTypeParentId", "memberNickname", "pageNum"]);
    return [
        useMemo(() => ({
            ...param, companyId: Number(param.companyId) || undefined, 
            companyDelete: Boolean(param.companyDelete) || undefined, companyAttributeId: Number(param.companyAttributeId) || undefined,
            companyTypeParentId: Number(param.companyTypeParentId) || undefined, companyTagId: Number(param.companyTagId) || undefined,
            pageNum: Number(param.pageNum) || undefined
        }), [param]),
        setParam
    ] as const
}