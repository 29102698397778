import { Button, Col, message, Modal, Row, Space, Table } from "antd"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useCompanyScaleList, useDeleteCompanyScale } from "../../services/company-scale-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useCompanyScaleSearchParams } from "./util"

export const CompanyScaleListPage = () => {
    useDocumentTitle('企业规模列表', true)
    const [param, setParam] = useCompanyScaleSearchParams()
    const {isLoading, error, totalElements, data: list} = useCompanyScaleList(useDebounce(param, 200))

    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) => 
            setParam({
            ...param,
            pageNum: (current -1),
        })
    }

    //删除事件
    const { mutateAsync:deleteMutateAsync } = useDeleteCompanyScale();
    const confirmDeleteCompanyScale = (companyScaleId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    deleteMutateAsync({ companyScaleId }).then((data) => {
                        if (data.status === 0) {
                            message.info('删除成功');
                            Modal.destroyAll();
                          } else {
                            message.error(data.msg)
                          }
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    //分页
    return (
        <div>
            <Row style={{marginBottom: 24}}>
                <Col span={12}>
                    <Link to={"/qiye/company-manage/company-scale/add"}>
                        <Button type="primary">新建</Button>
                    </Link>
                </Col>
                <Col span={12} style={{textAlign:'right'}}>
                </Col>
            </Row>
            <Table 
                loading = { isLoading }
                rowKey={ columns => columns.companyScaleId } 
                pagination = { paginationProps } 
                columns = {[
                {
                    title: 'ID',
                    dataIndex: 'companyScaleId',
                    key: 'companyScaleId',
                    align: 'center',
                    width: 100,
                },
                {
                    title: '规模',
                    dataIndex: 'companyScaleMin',
                    key: 'companyScaleMin',
                    align: 'center',
                    render: (value, companyScale) => (
                        value && companyScale?.companyScaleMax ? value + "~" + companyScale?.companyScaleMax + "人" 
                        : !value && companyScale?.companyScaleMax ? companyScale?.companyScaleMax + "人以下" 
                        : value && !companyScale?.companyScaleMax ? value + "人以上" 
                        : '-'
                    )
                },
                {
                    title: '排序',
                    dataIndex: 'companyScaleSort',
                    key: 'companyScaleSort',
                    align: 'center',
                },
                {
                    title: '状态',
                    dataIndex: 'companyScaleShow',
                    key: 'companyScaleShow',
                    align: 'center',
                    render: (value) => (
                        <span>{value ? '显示' : '不显示'}</span>
                    ),
                },
                {
                    title: '操作',
                    key: 'action',
                    align: 'center',
                    render: (value, companyScale) => (
                        <Space size="middle">
                            <Link to={"/qiye/company-manage/company-scale/update/" + companyScale.companyScaleId}>
                                编辑
                            </Link>
                            <a onClick={() => confirmDeleteCompanyScale(companyScale.companyScaleId)}>
                                删除
                            </a>
                        </Space>
                    ),
                    width: 150,
                }
            ]}
            dataSource={ list || [] }  />
            <ErrorBox error={ error } />
        </div>
    )
}