import { Button, Col, Divider, Form, Input, message, Radio, Row, Select, Spin, Cascader, Tooltip, Typography, Tabs, InputNumber, Space, Image, Empty } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useStore, useEditStore } from "../../services/store-service";
import { useStoreDetail } from "../../services/store-detail-service";
import { useAreaListAll } from "../../services/area-service";
import { useDocumentTitle } from "../../utils";
import BraftEditor from "braft-editor";
import "braft-editor/dist/index.css";
import UploadPic from "../../components/upload-pic";
import RichText from "../../components/rich-text";
import { useStoreAttributeListAll } from "../../services/store-attribute-service";
import { useStoreTypeListAll } from "../../services/store-type-service";
import { hour } from '../../utils/hour';
import { minute } from '../../utils/minute';
import { useMemberListAll } from "../../services/member-service";
const { TabPane } = Tabs;
const { Search } = Input
const { Option } = Select;

export const StoreUpdatePage = () => {
  //标题
  useDocumentTitle("编辑商家", true);
  const { pathname } = useLocation();
  const storeId = pathname.match(/shop\/store-manage\/store\/update\/(\d+)/)?.[1];
  //布局
  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 18 },
  };
  const tailLayout = {
    wrapperCol: { offset: 3 },
  };
  //准备区域列表
  const { data: areaListAll } = useAreaListAll(3);
  // //准备商家分类列表
  // const { data: storeClassList } = useStoreClassListTree();
  // const storeClassOptions = storeClassList?.data?.map(
  //   (item: any, value: any) => (
  //     <Option value={item.storeClassId} key={item.storeClassId}>
  //       {item.storeClassName}
  //     </Option>
  //   )
  // );
  //商家性质
  const { data: storeAttributeList } = useStoreAttributeListAll();
  const storeAttributeOptions = storeAttributeList?.data?.map(
    (item: any, value: any) => (
      <Option value={item.storeAttributeId} key={item.storeAttributeId}>
        {item.storeAttributeName}
      </Option>
    )
  );
  //商家类型
  const { data: storeTypeList } = useStoreTypeListAll();
  const storeTypeOptions = storeTypeList?.data?.map(
    (item: any, value: any) => (
      <Option value={item.storeTypeId} key={item.storeTypeId}>
        {item.storeTypeName}
      </Option>
    )
  );
  //表单
  const [form] = useForm();
  const [storeLogo, setStoreLogo] = useState("");
  const [storeBanner, setStoreBanner] = useState("");
  const [storeProvinceId, setStoreProvinceId] = useState();
  const [storeCityId, setStoreCityId] = useState();
  const [storeDistrictId, setStoreDistrictId] = useState();
  const [storeContent, setStoreContent] = useState("");
  //准备回显数据
  const { data: editingStore, isLoading } = useStore(Number(storeId));
  useEffect(() => {
    form.setFieldsValue({ ...editingStore?.data });
    //省市区
    let storeCascader: any = [];
    if (editingStore?.data?.storeProvinceId) {
      storeCascader.push(editingStore?.data?.storeProvinceId);
      setStoreProvinceId(editingStore?.data?.storeProvinceId)
    }
    if (editingStore?.data?.storeCityId) {
      storeCascader.push(editingStore?.data?.storeCityId);
      setStoreCityId(editingStore?.data?.storeCityId)
    }
    if (editingStore?.data?.storeDistrictId) {
      storeCascader.push(editingStore?.data?.storeDistrictId);
      setStoreDistrictId(editingStore?.data?.storeDistrictId)
    }
    if (storeCascader) {
      form.setFieldsValue({
        "storeCascader": storeCascader
      })
    }
    if (editingStore?.data?.storeLogo) {
      setStoreLogo(editingStore?.data?.storeLogo)
    }
    if (editingStore?.data?.storeBanner) {
      setStoreBanner(editingStore?.data?.storeBanner)
    }
    if (editingStore?.data?.memberTel) {
      setMemberTel(editingStore?.data?.memberTel)
    }
  }, [editingStore, form]);
  //获取商品详情
  const { data: editingStoreDetail } = useStoreDetail(Number(storeId));
  useEffect(() => {
    setStoreContent(editingStoreDetail?.data?.storeContent);
    form.setFieldsValue({
      storeContent: BraftEditor.createEditorState(editingStoreDetail?.data?.storeContent),
    });
  }, [editingStoreDetail, form]);

  //编辑
  const useMutateEditStore = useEditStore;
  const { mutateAsync, error } = useMutateEditStore();
  //提交表单
  const [submitLoading, setSubmitLoading] = useState(false);
  const onFinish = (values: any) => {
    setSubmitLoading(true);
    mutateAsync({
      ...editingStore.data,
      ...values,
      storeLogo,
      storeBanner,
      storeContent,
      storeProvinceId,
      storeCityId,
      storeDistrictId
    }).then((data) => {
      if (data?.status === 0) {
        message.info("编辑成功");
        window.location.href = "/#/shop/store-manage/store/list";
      } else {
        setSubmitLoading(false);
        message.info(data?.msg);
      }
    });
  };

  //选择用户
  const { mutateAsync: getMemberList } = useMemberListAll();
  const [memberList, setMemberList] = useState([]);
  const [memberTel, setMemberTel] = useState("");
  const handleMemberTel = (e: any) => {
    setMemberTel(e.target.value);
  }
  useEffect(() => {
    getMemberList({
      memberTel,
    }).then((data) => {
      if (data.status === 0) {
        setMemberList(data?.data?.content);
      }
    })
  }, [memberTel])

  return (
    <div>
      <Spin spinning={isLoading}>
        <Row>
          <Col span={12}>
            <Link to={"/shop/store-manage/store/list"}>
              <Button type="primary">列表</Button>
            </Link>
          </Col>
        </Row>
        <Divider style={{ marginBottom: 24 }} />
        <Form form={form} {...layout} onFinish={onFinish}>
          <Tabs>
            <TabPane tab="基本信息" key="1">
              <Form.Item
                label={"商家名称"}
                name={"storeName"}
                style={{ marginTop: 24 }}
                rules={[{ required: true, message: "商家名称必填" }]}
              >
                <Input placeholder={"请输入商家名称"} style={{ width: 380 }} />
              </Form.Item>

              <Form.Item
                label={"商家电话"}
                name={"storeTel"}
                rules={[{ required: true, message: "商家电话必填" }]}
              >
                <Input placeholder={"请输入商家电话"} style={{ width: 380 }} />
              </Form.Item>

              <UploadPic
                name='uploadLogo'
                label='LOGO'
                pic={storeLogo}
                setPic={setStoreLogo}
                action={'update'}
                style={{ textAlign: 'left' }}
                required={false}
              >
              </UploadPic>

              <UploadPic
                name='uploadBanner'
                label='Banner图'
                pic={storeBanner}
                setPic={setStoreBanner}
                action={'update'}
                style={{ textAlign: 'left' }}
                required={false}
              >
              </UploadPic>

              {/* <Form.Item
                label={"商家分类"}
                name={"storeClassId"}
                rules={[{ required: true, message: "商家分类必选" }]}
              >
                <Select
                  showSearch
                  placeholder="请选择商家分类"
                  optionFilterProp="children"
                  style={{ textAlign: "left", width: 380 }}
                >
                  <Option value={0} key={0}>无</Option>
                  {storeClassOptions}
                </Select>
              </Form.Item> */}

              <Form.Item
                label={"商家性质"}
                name={"storeAttributeId"}
                rules={[{ required: true, message: "商家性质必选" }]}
              >
                <Select
                  showSearch
                  placeholder="请选择商家性质"
                  optionFilterProp="children"
                  style={{ textAlign: "left", width: 380 }}
                >
                  {storeAttributeOptions}
                </Select>
              </Form.Item>

              <Form.Item
                label={"商家类型"}
                name={"storeTypeId"}
                rules={[{ required: true, message: "商家类型必选" }]}
              >
                <Select
                  showSearch
                  placeholder="请选择商家类型"
                  optionFilterProp="children"
                  style={{ textAlign: "left", width: 380 }}
                >
                  {storeTypeOptions}
                </Select>
              </Form.Item>

              <Form.Item
                label={"省市区"}
                name={"storeCascader"}
                rules={[{ required: true, message: "省市区必选" }]}
              >
                <Cascader
                  options={areaListAll?.data}
                  placeholder="请选择省市区"
                  style={{ width: 380 }}
                />
              </Form.Item>

              <Form.Item
                label={"详细地址"}
                name={"storeAddress"}
              >
                <Input placeholder={"请输入详细地址"} style={{ width: 380 }} />
              </Form.Item>

              <Form.Item
                label={'经纬度'}
                name={'storeLatitude'}
              >
                <Form.Item
                  name={'storeLatitude'}
                  style={{ float: 'left', marginBottom: 0 }}
                >
                  <Input type={'number'} style={{ width: 150 }} placeholder={'请输入纬度'} />
                </Form.Item>
                <Form.Item
                  name={'storeLongitude'}
                  style={{ float: 'left', marginBottom: 0 }}
                >
                  <Input type={'number'} style={{ width: 150, marginLeft: 10 }} placeholder={'请输入经度'} />
                </Form.Item>
                <Form.Item style={{ float: 'left', marginBottom: 0, marginLeft: 10 }}>
                  <Tooltip title='坐标拾起器'>
                    <Typography.Link target='_blank' href='https://lbs.qq.com/getPoint/'>
                      坐标
                    </Typography.Link>
                  </Tooltip>
                </Form.Item>
              </Form.Item>

              <Form.Item
                label={"是否营业"}
                name={"storeBusiness"}
                rules={[{ required: true, message: "是否营业必选" }]}
                style={{ textAlign: "left" }}
              >
                <Radio.Group>
                  <Radio value={true}>营业</Radio>
                  <Radio value={false}>不营业</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label={"上班时间"}
                name={"storeBusinessStartHour"}
                style={{ marginBottom: 0 }}
              >
                <Input.Group compact>
                  <Form.Item
                    name={"storeBusinessStartHour"}
                  >
                    <Select
                      showSearch
                      placeholder="请选择时"
                      optionFilterProp="children"
                      style={{ textAlign: "left", width: 150 }}
                      options={hour}
                    />
                  </Form.Item>
                  <Form.Item>
                    <span style={{ margin: "0 5px 0 5px" }}>:</span>
                  </Form.Item>
                  <Form.Item
                    name={"storeBusinessStartMinute"}
                  >
                    <Select
                      showSearch
                      placeholder="请选择分"
                      optionFilterProp="children"
                      style={{ textAlign: "left", width: 150 }}
                      options={minute}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>

              <Form.Item
                label={"下班时间"}
                name={"storeBusinessEndHour"}
                style={{ marginBottom: 0 }}
              >
                <Input.Group compact>
                  <Form.Item
                    name={"storeBusinessEndHour"}
                  >
                    <Select
                      showSearch
                      placeholder="请选择时"
                      optionFilterProp="children"
                      style={{ textAlign: "left", width: 150 }}
                      options={hour}
                    />
                  </Form.Item>
                  <Form.Item>
                    <span style={{ margin: "0 5px 0 5px" }}>:</span>
                  </Form.Item>
                  <Form.Item
                    name={"storeBusinessEndMinute"}
                  >
                    <Select
                      showSearch
                      placeholder="请选择分"
                      optionFilterProp="children"
                      style={{ textAlign: "left", width: 150 }}
                      options={minute}
                    />
                  </Form.Item>
                </Input.Group>
              </Form.Item>

              <Form.Item
                label={"商家排序"}
                name={"storeSort"}
                rules={[{ required: true, message: "商家排序必填" }]}
              >
                <Input placeholder={"请输入商家排序"} style={{ width: 380 }} />
              </Form.Item>

              <Form.Item
                label={"状态"}
                name={"storeShow"}
                rules={[{ required: true, message: "状态必选" }]}
                style={{ textAlign: "left" }}
              >
                <Radio.Group>
                  <Radio value={true}>显示</Radio>
                  <Radio value={false}>不显示</Radio>
                </Radio.Group>
              </Form.Item>

              <Form.Item
                label={"平台分佣"}
                name={"storeCommissionRatio"}
                rules={[{ required: true, message: "平台分佣必填" }]}
              >
                <InputNumber min={0} step={0.01} addonAfter={"%"} placeholder={"请输入平台分佣"} style={{ width: 380 }} />
              </Form.Item>

              <Form.Item label={'搜索用户'} name={'memberTel'}>
                <Search
                  placeholder={'请输入手机号检索用户'}
                  maxLength={11}
                  onChange={handleMemberTel}
                  style={{ width: 380 }}></Search>
              </Form.Item>

              {memberList?.length ? (
                <Form.Item label={'选择用户'} name={'memberId'}>
                  <Radio.Group>
                    {memberList?.map((item: any) => (
                      <Space align='center' direction='vertical' size={5} style={{ marginBottom: '10px', marginRight: '5px' }} key={item.memberId}>
                        <Radio value={item.memberId}><span className='text1' style={{ width: '60px', display: 'block' }} >{item.memberNickname}</span></Radio>
                        <Image width='60px' height='60px' src={item.memberHeadImg} />
                      </Space>
                    ))}
                  </Radio.Group>
                </Form.Item>
              ) : (
                <Form.Item label={'选择用户'}>
                  <Empty description={'暂无用户'} style={{ maxWidth: '180px', height: '50px' }} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                </Form.Item>
              )}
            </TabPane>

            <TabPane tab="商家介绍" key="2">
              <RichText
                label={"商家介绍"}
                name={"storeContent"}
                setContent={setStoreContent}
              >
              </RichText>
            </TabPane>
          </Tabs>

          <Form.Item {...tailLayout} style={{ textAlign: "left" }}>
            <Button loading={submitLoading} type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
