import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Radio,
  Row,
  Spin,
  Select,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  useSchoolArticle,
  useEditSchoolArticle,
  useSchoolArticleDetail,
} from "../../services/school-article-service";
import { useDocumentTitle } from "../../utils";
import { useTrendsTagListAll } from "../../services/trends-tag-service";
import { useSchoolListAll } from "../../services/school-service";
import UploadMorePic from "../../components/upload-more-pic";
import BraftEditor from "braft-editor";
import RichText from "../../components/rich-text";
const { Option } = Select;

export const SchoolArticleUpdatePage = () => {
  //标题
  useDocumentTitle("编辑学校资讯", true);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const schoolArticleId = Number(
    pathname.match(/xuexiao\/school-manage\/school-article\/update\/(\d+)/)?.[1]
  );
  const [fileList, setFileList] = useState<any[]>([[]]);
  const [schoolArticleContent, setSchoolArticleContent] = useState("");

  //布局
  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 18 },
  };
  const tailLayout = {
    wrapperCol: { offset: 3 },
  };

  //动态标签
  const { data: trendsTagList } = useTrendsTagListAll(1);
  const trendsTagOptions = trendsTagList?.data?.map((item: any) => (
    <Option value={item.trendsTagId}>{item.trendsTagName}</Option>
  ));

  //学校
  const { data: schoolList } = useSchoolListAll();
  const schoolOptions = schoolList?.data?.map((item: any) => (
    <Option value={item.schoolId}>{item.schoolName}</Option>
  ));

  //表单
  const [form] = useForm();

  //准备回显
  const { data: editingSchoolArticle, isLoading } =
    useSchoolArticle(schoolArticleId);
  useEffect(() => {
    form.setFieldsValue({ ...editingSchoolArticle?.data });
    if (editingSchoolArticle?.data?.morePicList) {
      setFileList([editingSchoolArticle?.data?.morePicList]);
      form.setFieldsValue({
        uploadMore: editingSchoolArticle?.data?.morePicList,
      });
    }
  }, [editingSchoolArticle, form]);
  const { data: editingSchoolArticleDetail } =
    useSchoolArticleDetail(schoolArticleId);
  useEffect(() => {
    setSchoolArticleContent(
      editingSchoolArticleDetail?.data?.schoolArticleContent
    );
    form.setFieldsValue({
      schoolArticleContent: BraftEditor.createEditorState(
        editingSchoolArticleDetail?.data?.schoolArticleContent
      ),
    });
  }, [editingSchoolArticleDetail, form]);

  //新建
  const [submitLoading, setSubmitLoading] = useState(false);
  const { mutateAsync } = useEditSchoolArticle();
  const onFinish = (values: any) => {
    setSubmitLoading(true);
    var schoolArticlePicArray = values?.uploadMore?.map((item: any) => {
      return item?.response;
    });
    var schoolArticlePics = schoolArticlePicArray?.join(",");
    mutateAsync({
      ...editingSchoolArticle?.data,
      ...values,
      schoolArticlePics,
      schoolArticleContent,
    })
      .then((data) => {
        if (data.status === 0) {
          message.success(data.msg);
          navigate("/xuexiao/school-manage/school-article/list");
        } else {
          setSubmitLoading(false);
          message.error(data.msg);
        }
      })
      .catch((err) => {
        message.error("提交失败，请重新提交");
        setSubmitLoading(false);
      });
  };

  return (
    <div>
      <Row>
        <Col span={12}>
          <Link to={"/xuexiao/school-manage/school-article/list"}>
            <Button type="primary">列表</Button>
          </Link>
        </Col>
      </Row>

      <Divider style={{ marginBottom: 24 }} />

      <Spin spinning={isLoading}>
        <Form form={form} {...layout} onFinish={onFinish}>
          <Form.Item
            label={"类型"}
            name={"schoolArticleType"}
            rules={[{ required: true, message: "类型必选" }]}
          >
            <Radio.Group>
              <Radio value={1}>简章</Radio>
              <Radio value={2}>政策</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label={"标题"}
            name={"schoolArticleTitle"}
            rules={[{ required: true, message: "标题必填" }]}
          >
            <Input style={{ width: 380 }} placeholder={"请输入标题"} />
          </Form.Item>

          <Form.Item
            name="trendsTagIdList"
            label="标签"
            rules={[{ required: true, message: "标签必选" }]}
          >
            <Select
              placeholder="请选择标签"
              showSearch
              allowClear
              optionFilterProp="children"
              mode="multiple"
              style={{ width: 380 }}
            >
              {trendsTagOptions}
            </Select>
          </Form.Item>

          <UploadMorePic
            label={"图片"}
            name={"uploadMore"}
            index={0}
            fileList={fileList}
            setFileList={setFileList}
            style={{ textAlign: "left" }}
          ></UploadMorePic>

          <Form.Item
            name="schoolId"
            label="学校"
            rules={[{ required: true, message: "学校必选" }]}
          >
            <Select
              placeholder="请选择学校"
              showSearch
              allowClear
              optionFilterProp="children"
              style={{ width: 380 }}
            >
              {schoolOptions}
            </Select>
          </Form.Item>

          <Form.Item
            label={"排序"}
            name={"schoolArticleSort"}
            rules={[{ required: true, message: "排序必填" }]}
          >
            <Input style={{ width: 380 }} placeholder={"请输入排序"} />
          </Form.Item>

          <Form.Item
            label={"状态"}
            name={"schoolArticleShow"}
            rules={[{ required: true, message: "状态必选" }]}
            style={{ textAlign: "left" }}
          >
            <Radio.Group>
              <Radio defaultChecked value={true}>
                显示
              </Radio>
              <Radio value={false}>不显示</Radio>
            </Radio.Group>
          </Form.Item>

          <RichText
            label={"详情"}
            name={"schoolArticleContent"}
            setContent={setSchoolArticleContent}
          ></RichText>

          <Form.Item {...tailLayout} style={{ textAlign: "left" }}>
            <Button loading={submitLoading} type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
