import {
  Button,
  Col,
  message,
  Modal,
  Row,
  Space,
  Table,
  Image,
  Menu,
} from "antd";
import Search from "antd/lib/input/Search";
import { Link } from "react-router-dom";
import { ErrorBox } from "../../components/lib";
import {
  useGrouponAdvList,
  useDeleteGrouponAdv,
} from "../../services/groupon-adv-service";
import { useDebounce, useDocumentTitle } from "../../utils";
import { useGrouponAdvSearchParams } from "./util";

export const GrouponAdvListPage = () => {
  useDocumentTitle("广告列表", true);
  const imgUrl = process.env.REACT_APP_IMG_URL;
  const [param, setParam] = useGrouponAdvSearchParams();
  const {
    isLoading,
    error,
    totalElements,
    data: list,
  } = useGrouponAdvList(useDebounce(param, 200));
  //分页
  const paginationProps = {
    defaultCurrent: 1,
    pageSize: 10,
    total: totalElements,
    onChange: (current: number) =>
      setParam({
        ...param,
        pageNum: current - 1,
      }),
  };
  //删除事件
  const useMutateDeleteGrouponAdv = useDeleteGrouponAdv;
  const { mutateAsync } = useMutateDeleteGrouponAdv();
  const confirmDeleteGrouponAdv = (grouponAdvId: number) => {
    Modal.confirm({
      title: "确定删除这条数据吗?",
      content: "点击确定删除",
      okText: "确定",
      onOk(): any {
        return new Promise((resolve, reject) => {
          mutateAsync({ grouponAdvId }).then(() => {
            message.info("删除成功");
            Modal.destroyAll();
          });
        }).catch(() => console.log("Oops errors!"));
      },
      cancelText: "取消",
    });
  };
  //分页
  return (
    <div>
      <Menu
        style={{ marginBottom: 24 }}
        mode="horizontal"
        selectedKeys={["grouponAdv"]}
      >
        <Menu.Item key="groupon">
          <Link to={"/shop/market-manage/groupon/list"}>拼团管理</Link>
        </Menu.Item>
        <Menu.Item key="grouponAdv">
          <Link to={"/shop/market-manage/groupon/adv/list"}>拼团广告</Link>
        </Menu.Item>
      </Menu>
      <Row style={{ marginBottom: 24 }}>
        {/* <Col span={12}>
          <Link to={"/shop/market-manage/groupon/adv/add"}>
            <Button type="primary">新建广告</Button>
          </Link>
        </Col> */}
        <Col span={12} style={{ textAlign: "left" }}>
          <Search
            placeholder="可搜索广告标题"
            style={{ width: 200 }}
            value={param.grouponAdvTitle}
            onChange={(evt) =>
              setParam({
                ...param,
                grouponAdvTitle: evt.target.value,
                pageNum: null,
              })
            }
            onSearch={(value) =>
              setParam({
                ...param,
                grouponAdvTitle: value,
                pageNum: null,
              })
            }
          />
        </Col>
      </Row>
      <Table
        loading={isLoading}
        rowKey={(columns) => columns.grouponAdvId}
        pagination={paginationProps}
        columns={[
          {
            title: "广告ID",
            dataIndex: "grouponAdvId",
            key: "grouponAdvId",
            width: 100,
          },
          {
            title: "广告标题",
            dataIndex: "grouponAdvTitle",
            key: "grouponAdvTitle",
          },
          {
            title: "广告图片",
            dataIndex: "grouponAdvPic",
            key: "grouponAdvPic",
            render: (value, grouponAdv) => (
              <Image
                width={150}
                src={
                  imgUrl  + grouponAdv.grouponAdvPic
                }
              />
            ),
          },
          {
            title: "广告排序",
            dataIndex: "grouponAdvSort",
            key: "grouponAdvSort",
          },
          {
            title: "是否显示",
            dataIndex: "grouponAdvShow",
            key: "grouponAdvShow",
            render: (value, grouponAdv) => (
              <span>{grouponAdv.grouponAdvShow ? "显示" : "不显示"}</span>
            ),
          },
          {
            title: "操作",
            key: "action",
            render: (value, grouponAdv) => (
              <Space size="middle">
                <Link
                  to={
                    "/shop/market-manage/groupon/adv/update/" + grouponAdv.grouponAdvId
                  }
                >
                  编辑
                </Link>
                <a
                  onClick={() =>
                    confirmDeleteGrouponAdv(grouponAdv.grouponAdvId)
                  }
                >
                  删除
                </a>
              </Space>
            ),
            width: 150,
          },
        ]}
        dataSource={list || []}
      />
      <ErrorBox error={error} />
    </div>
  );
};
