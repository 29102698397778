import { Button, Card, Col, Form, message, Modal, Row, Space, Table, DatePicker, Select, Input, Image } from "antd"
import dayjs from "dayjs"
import { useState } from "react"
import { ErrorBox } from "../../components/lib"
import { useSchoolAuthList, useAgreeSchoolAuth, useRejectSchoolAuth } from "../../services/school-auth-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useSchoolAuthSearchParams } from "../school-auth/util"
const { RangePicker } = DatePicker
const { Option } = Select;

export const SchoolAuthListPage = () => {
    useDocumentTitle('学校认证列表', true)
    const [param, setParam] = useSchoolAuthSearchParams()
    const { isLoading, error, totalElements, data: list } = useSchoolAuthList(useDebounce(param, 200))

    const imgUrl = process.env.REACT_APP_IMG_URL;

    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) =>
            setParam({
                ...param,
                pageNum: (current - 1),
            })
    }

    //时间
    const [schoolAuthTimeStart, setSchoolAuthTimeStart] = useState('');
    const [schoolAuthTimeEnd, setSchoolAuthTimeEnd] = useState('');
    const handleSelectTime = (value: any, dateString: any) => {
        setSchoolAuthTimeStart(dateString[0]);
        setSchoolAuthTimeEnd(dateString[1]);
    }

    //提交
    const onFinish = (values: any) => {
        setParam({
            ...param,
            schoolName: values.schoolName,
            memberNickname: values.memberNickname,
            schoolAuthState: values.schoolAuthState,
            schoolAuthTimeStart: schoolAuthTimeStart,
            schoolAuthTimeEnd: schoolAuthTimeEnd,
            pageNum: null
        })
    }

    //通过
    const { mutateAsync: agreeMutateAsync } = useAgreeSchoolAuth();
    const confirmAgreeSchoolAuth = (schoolAuthId: number) => {
        Modal.confirm({
            title: "确定操作这条数据吗?",
            content: "点击确定操作",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    agreeMutateAsync({ schoolAuthId }).then((data) => {
                        if (data.status === 0) {
                            message.info('操作成功');
                            Modal.destroyAll();
                        } else {
                            message.error(data.msg)
                        }
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };

    //驳回
    const [schoolAuthId, setSchoolAuthId] = useState<number>();
    const [submitRejectLoading, setSubmitRejectLoading] = useState(false);
    const [rejectModalVisible, setRejectModalVisible] = useState(false);
    const { mutateAsync: mutateAsyncRejectSchoolAuth } = useRejectSchoolAuth();
    const [schoolAuthFailReason, setSchoolAuthFailReason] = useState('');
    const handleFailReason = (e: any) => {
        setSchoolAuthFailReason(e.target.value);
    }
    const handleRejectCancel = () => {
        setRejectModalVisible(false);
    };
    const handleRejectOk = () => {
        if (!schoolAuthFailReason) {
            message.error('驳回原因必填');
        } else {
            setSubmitRejectLoading(true)
            mutateAsyncRejectSchoolAuth({ schoolAuthId, schoolAuthFailReason }).then(() => {
                setRejectModalVisible(false);
                setSubmitRejectLoading(false);
            });
        }
    }
    const showRejectModal = (schoolAuthId: number) => {
        setSchoolAuthId(schoolAuthId);
        setRejectModalVisible(true);
    }

    //分页
    return (
        <div>
            <Card style={{ backgroundColor: "#f2f3f5", margin: '24px 0 24px 0' }}>
                <Form onFinish={onFinish}>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item name="schoolAuthTimeRange" label="认证时间">
                                <RangePicker onChange={handleSelectTime} showTime format="YYYY-MM-DD HH:mm:ss" style={{ width: '100%' }} allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="schoolAuthState" label="认证状态">
                                <Select
                                    placeholder='请选择认证状态'
                                    showSearch
                                    allowClear
                                    optionFilterProp='children'
                                >
                                    <Option value={10}>认证中</Option>
                                    <Option value={20}>认证失败</Option>
                                    <Option value={30}>认证成功</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item name="schoolName" label="学校名称">
                                <Input placeholder='请输入学校名称' allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="memberNickname" label="用户昵称">
                                <Input placeholder='请输入用户昵称' allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item style={{ marginBottom: 0 }}>
                        <Button style={{ marginLeft: 70 }} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
            <Table
                loading={isLoading}
                rowKey={columns => columns.schoolAuthId}
                pagination={paginationProps}
                columns={[
                    {
                        title: 'ID',
                        dataIndex: 'schoolAuthId',
                        key: 'schoolAuthId',
                        align: 'center',
                        width: 100,
                        fixed: 'left',
                    },
                    {
                        title: '名称',
                        dataIndex: 'schoolName',
                        key: 'schoolName',
                        align: 'center',
                        width: 200,
                        fixed: 'left',
                    },
                    {
                        title: '地区',
                        dataIndex: 'provinceAreaName',
                        key: 'provinceAreaName',
                        align: 'center',
                        width: 200,
                        render: (value, schoolAuth) => (
                            value || schoolAuth.cityAreaName || schoolAuth.districtAreaName ? (
                                <div>
                                    <span>{value}{schoolAuth.cityAreaName}{schoolAuth.districtAreaName}</span>
                                </div>
                            ) : ('-')
                        )
                    },
                    {
                        title: '性质',
                        dataIndex: 'schoolAttribute',
                        key: 'schoolAttribute',
                        align: 'center',
                        width: 100,
                        render: (value) => (
                            value === 1 ? '公办' : value === 2 ? '民办' : '-'
                        ),
                    },
                    {
                        title: '层次',
                        dataIndex: 'schoolLevelName',
                        key: 'schoolLevelName',
                        align: 'center',
                        width: 150,
                    },
                    {
                        title: '类型',
                        dataIndex: 'schoolTypeName',
                        key: 'schoolTypeName',
                        align: 'center',
                        width: 150,
                    },
                    // {
                    //     title: '创办时间',
                    //     dataIndex: 'schoolBuildTime',
                    //     key: 'schoolBuildTime',
                    //     align: 'center',
                    //     width: 200,
                    //     render: (value) => (
                    //         value ? dayjs(value).format('YYYY-MM-DD') : '-'
                    //     )
                    // },
                    {
                        title: '许可证',
                        dataIndex: 'schoolCert',
                        key: 'schoolCert',
                        align: 'center',
                        width: 100,
                        render: (value) => (
                            value ? <Image src={imgUrl + value} width={60} height={80} /> : '-'
                        )
                    },
                    {
                        title: '申请人',
                        dataIndex: 'schoolContactName',
                        key: 'schoolContactName',
                        align: 'center',
                        width: 150,
                        render: (value, schoolAuth) => (
                            <div>
                                {value}{schoolAuth.schoolAuthType === 1 ? '(法人)' : schoolAuth.schoolAuthType === 2 ? '(代办人)' : ''}<br />
                                {schoolAuth?.schoolContactTel}
                            </div>
                        )
                    },
                    {
                        title: '授权书',
                        dataIndex: 'schoolContactCert',
                        key: 'schoolContactCert',
                        align: 'center',
                        width: 100,
                        render: (value) => (
                            value ? <Image src={imgUrl + value} width={60} height={80} /> : '-'
                        )
                    },
                    {
                        title: '身份证',
                        dataIndex: 'schoolContactCardFront',
                        key: 'schoolContactCardFront',
                        align: 'center',
                        width: 300,
                        render: (value, schoolAuth) => (
                            <div>
                                {value ? <Image src={imgUrl + value} height={80} /> : ''}
                                {schoolAuth.schoolContactCardBack ? <Image src={imgUrl + schoolAuth.schoolContactCardBack} height={80} style={{ marginLeft: 5 }} /> : ''}
                            </div>
                        )
                    },
                    {
                        title: '用户昵称',
                        dataIndex: 'memberNickname',
                        key: 'memberNickname',
                        align: 'center',
                        width: 150,
                    },
                    {
                        title: '认证时间',
                        dataIndex: 'schoolAuthTime',
                        key: 'schoolAuthTime',
                        align: 'center',
                        width: 200,
                        render: (value) => (
                            value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : '-'
                        )
                    },
                    {
                        title: '认证状态',
                        dataIndex: 'schoolAuthState',
                        key: 'schoolAuthState',
                        align: 'center',
                        width: 100,
                        render: (value) => (
                            value === 10 ? '认证中' : value === 20 ? '认证失败' : value === 30 ? '认证成功' : '-'
                        )
                    },
                    {
                        title: '操作',
                        key: 'action',
                        align: 'center',
                        render: (value, schoolAuth) => (
                            schoolAuth.schoolAuthState === 10 ? (
                                <Space size="middle">
                                    <a onClick={() => confirmAgreeSchoolAuth(schoolAuth.schoolAuthId)}>
                                        通过
                                    </a>
                                    <a onClick={() => showRejectModal(schoolAuth.schoolAuthId)}>
                                        驳回
                                    </a>
                                </Space>
                            ) : ('')
                        ),
                        width: 150,
                        fixed: 'right',
                    }
                ]}
                dataSource={list || []}
                scroll={{ x: 2250 }}
            />
            <ErrorBox error={error} />
            <Modal
                confirmLoading={submitRejectLoading}
                centered
                bodyStyle={{ height: '100', overflowY: 'auto' }}
                destroyOnClose={true}
                width={500}
                title="认证驳回"
                visible={rejectModalVisible}
                onOk={handleRejectOk}
                onCancel={handleRejectCancel}>
                <Col>
                    <Form>
                        <Form.Item
                            label='驳回原因'
                        >
                            <Input onChange={handleFailReason} placeholder={"输入驳回原因"} />
                        </Form.Item>
                    </Form>
                </Col>
            </Modal>
        </div>
    )
}