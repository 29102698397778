import { useMutation, useQuery, useQueryClient } from "react-query";
import { MallFloor } from "../interfaces/mall-floor";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useFloorList = (param?: Partial<MallFloor>) => {
    const client = useHttp();
    const result = useQuery<any>(['floorList', param], () => client('mallFloor', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditFloor = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<MallFloor>) => client(`mallFloor`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('floorList')
        }
    )
}

export const useAddFloor = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<MallFloor>) => client(`mallFloor`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('floorList')
        }
    )
}

export const useDeleteFloor = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ floorId }: { floorId: number}) =>
            client(`mallFloor/${floorId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('floorList')
        }
    );
};

export const useFloor = (floorId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['mallFloor', {floorId}],
        () => client(`mallFloor/${floorId}`),
        {
            enabled: Boolean(floorId)
        }
    )
}