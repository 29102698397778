import { Button, Col, Divider, Form, Input, InputNumber, message, Radio, Row, Spin, DatePicker, Select, Tooltip, Typography, Cascader, Tabs, Space, Empty, Image } from "antd";
import { useForm } from "antd/lib/form/Form";
import BraftEditor from "braft-editor";
import moment from "moment";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import RichText from "../../components/rich-text";
import UploadMorePic from "../../components/upload-more-pic";
import UploadPic from "../../components/upload-pic";
import { useAreaListAll } from "../../services/area-service";
import { useCompanyDetail } from "../../services/company-detail-service";
import { useCompanyTagListAll } from "../../services/company-tag-service";
import { useCompanyAttributeListAll } from "../../services/company-attribute-service";
import { useEditCompany, useCompany } from "../../services/company-service";
import { useCompanyTypeListAll } from "../../services/company-type-service";
import { useDocumentTitle } from "../../utils"
import { useCompanyScaleListAll } from "../../services/company-scale-service";
import { useMemberListAll } from "../../services/member-service";
const { Option } = Select
const { Search } = Input
const { TabPane } = Tabs;

export const CompanyUpdatePage = () => {
    //标题
    useDocumentTitle('编辑企业', true)
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const companyId = Number(pathname.match(/qiye\/company-manage\/company\/update\/(\d+)/)?.[1])

    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 18 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };

    //性质
    const { data: companyAttributeList } = useCompanyAttributeListAll();
    const companyAttributeOptions = companyAttributeList?.data?.map((item: any) =>
        <Option value={item.companyAttributeId}>{item.companyAttributeName}</Option>
    )

    //类型
    const { data: companyTypeList } = useCompanyTypeListAll(2);
    const [companyTypeId, setCompanyTypeId] = useState();
    const handleCompanyType = (e: any) => {
        if (e && e.length > 1) {
            setCompanyTypeId(e[1])
        }
    }

    //标签
    const { data: companyTagList } = useCompanyTagListAll();
    const companyTagOptions = companyTagList?.data?.map((item: any) =>
        <Option value={item.companyTagId}>{item.companyTagName}</Option>
    )

    //规模
    const { data: companyScaleList } = useCompanyScaleListAll();
    const companyScaleOptions = companyScaleList?.data?.map((item: any) =>
        <Option value={
            item.companyScaleId}>{item?.companyScaleMin && item?.companyScaleMax ? item?.companyScaleMin + "~" + item?.companyScaleMax + "人"
                : !item?.companyScaleMin && item?.companyScaleMax ? item?.companyScaleMax + "人以下"
                    : item?.companyScaleMin && !item?.companyScaleMax ? item?.companyScaleMin + "人以上"
                        : '-'}</Option>
    )

    //省市区
    const { data: areaList } = useAreaListAll(3)

    //准备回显
    const { data: editingCompany, isLoading } = useCompany(companyId);
    const [form] = useForm();
    const [companyLogo, setCompanyLogo] = useState("");
    const [companyPic, setCompanyPic] = useState("");
    const [fileList, setFileList] = useState<any[]>([[]]);
    const [companyBuildTimeStr, setCompanyBuildTimeStr] = useState("");
    const [provinceAreaId, setProvinceAreaId] = useState();
    const [cityAreaId, setCityAreaId] = useState();
    const [districtAreaId, setDistrictAreaId] = useState();
    const [companyContent, setCompanyContent] = useState("");
    useEffect(() => {
        form.setFieldsValue({ ...editingCompany?.data });
        if (editingCompany?.data?.companyLogo) {
            setCompanyLogo(editingCompany?.data?.companyLogo)
        }
        if (editingCompany?.data?.companyPic) {
            setCompanyPic(editingCompany?.data?.companyPic)
        }
        //多图
        if (editingCompany?.data?.morePicList) {
            setFileList([editingCompany?.data?.morePicList]);
            form.setFieldsValue({
                "uploadMore": editingCompany?.data?.morePicList
            })
        }
        //成立时间
        if (editingCompany?.data?.companyBuildTimeStr) {
            setCompanyBuildTimeStr(editingCompany?.data?.companyBuildTimeStr)
            form.setFieldsValue({
                "companyBuildTimeStr": moment(editingCompany?.data?.companyBuildTimeStr, "YYYY-MM-DD")
            })
        }
        //省市区
        let companyCascader: any = [];
        if (editingCompany?.data?.provinceAreaId) {
            companyCascader.push(editingCompany?.data?.provinceAreaId);
            setProvinceAreaId(editingCompany?.data?.provinceAreaId)
        }
        if (editingCompany?.data?.cityAreaId) {
            companyCascader.push(editingCompany?.data?.cityAreaId);
            setCityAreaId(editingCompany?.data?.cityAreaId)
        }
        if (editingCompany?.data?.districtAreaId) {
            companyCascader.push(editingCompany?.data?.districtAreaId);
            setDistrictAreaId(editingCompany?.data?.districtAreaId)
        }
        if (companyCascader) {
            form.setFieldsValue({
                "companyCascader": companyCascader
            })
        }
        if (editingCompany?.data?.companyTypeParentId && editingCompany?.data?.companyTypeId) {
            const typeId = [editingCompany?.data?.companyTypeParentId, editingCompany?.data?.companyTypeId]
            setCompanyTypeId(editingCompany?.data?.companyTypeId)
            form.setFieldsValue({
                "typeId": typeId
            })
        }
        if (editingCompany?.data?.memberTel) {
            setMemberTel(editingCompany?.data?.memberTel)
        }
    }, [editingCompany, form]);

    const { data: editingCompanyDetail } = useCompanyDetail(companyId);
    useEffect(() => {
        setCompanyContent(editingCompanyDetail?.data?.companyContent);
        form.setFieldsValue({
            "companyContent": BraftEditor.createEditorState(editingCompanyDetail?.data?.companyContent)
        });
    }, [editingCompanyDetail, form]);

    const handleSelectTime = (value: any, dateString: any) => {
        setCompanyBuildTimeStr(dateString);
    }

    const handleCascader = (e: any) => {
        if (e.length > 0) {
            setProvinceAreaId(e[0]);
            if (e.length > 1) {
                setCityAreaId(e[1]);
                if (e.length > 2) {
                    setDistrictAreaId(e[2]);
                }
            }
        }
    }

    //编辑
    const [submitLoading, setSubmitLoading] = useState(false);
    const { mutateAsync } = useEditCompany();
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        var companyPicArray = values?.uploadMore?.map((item: any) => {
            return item?.response;
        });
        var companyPics = companyPicArray?.join(',');
        mutateAsync({ ...editingCompany?.data, ...values, companyLogo, companyPic, companyPics, companyBuildTimeStr, provinceAreaId, cityAreaId, districtAreaId, companyTypeId, companyContent }).then((data) => {
            if (data.status === 0) {
                message.success(data.msg);
                navigate('/qiye/company-manage/company/list')
            } else {
                setSubmitLoading(false);
                message.error(data.msg);
            }
        }).catch(err => {
            message.error('提交失败，请重新提交');
            setSubmitLoading(false);
        });
    };

    //选择用户
    const { mutateAsync: getMemberList } = useMemberListAll();
    const [memberList, setMemberList] = useState([]);
    const [memberTel, setMemberTel] = useState("");
    const handleMemberTel = (e: any) => {
        setMemberTel(e.target.value);
    }
    useEffect(() => {
        getMemberList({
            memberTel,
        }).then((data) => {
            if (data.status === 0) {
                setMemberList(data?.data?.content);
            }
        })
    }, [memberTel])

    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/qiye/company-manage/company/list"}>
                        <Button type="primary">列表</Button>
                    </Link>
                </Col>
            </Row>

            <Divider style={{ marginBottom: 24 }} />

            <Spin spinning={isLoading}>
                <Form
                    form={form}
                    {...layout}
                    onFinish={onFinish}
                >
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="基本信息" key="1">
                            <Form.Item
                                label={"企业名称"}
                                name={"companyName"}
                                rules={[{ required: true, message: "企业名称必填" }]}
                            >
                                <Input style={{ width: 380 }} placeholder={"请输入企业名称"} />
                            </Form.Item>

                            <Form.Item
                                label={"企业简称"}
                                name={"companyShortName"}
                            >
                                <Input style={{ width: 380 }} placeholder={"请输入企业简称"} />
                            </Form.Item>

                            <Form.Item
                                label={"联系电话"}
                                name={"companyTel"}
                            >
                                <Input style={{ width: 380 }} placeholder={"请输入联系电话"} />
                            </Form.Item>

                            <UploadPic
                                name='uploadLogo'
                                label='LOGO'
                                pic={companyLogo}
                                setPic={setCompanyLogo}
                                action={'update'}
                                style={{ textAlign: 'left' }}
                                required={false}
                            >
                            </UploadPic>

                            <UploadPic
                                name='uploadPic'
                                label='背景图'
                                pic={companyPic}
                                setPic={setCompanyPic}
                                action={'update'}
                                style={{ textAlign: 'left' }}
                                required={false}
                            >
                            </UploadPic>

                            <UploadMorePic
                                label={"环境图"}
                                name={"uploadMore"}
                                index={0}
                                fileList={fileList}
                                setFileList={setFileList}
                                style={{ textAlign: "left" }}
                            >
                            </UploadMorePic>

                            <Form.Item
                                label={"企业性质"}
                                name={"companyAttributeId"}
                                rules={[{ required: true, message: "企业性质必选" }]}
                            >
                                <Select
                                    placeholder='请选择企业性质'
                                    showSearch
                                    allowClear
                                    optionFilterProp='children'
                                    style={{ width: 380 }}
                                >
                                    {companyAttributeOptions}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label={"企业行业"}
                                name={"typeId"}
                                rules={[{ required: true, message: "企业行业必选" }]}
                            >
                                <Cascader
                                    style={{ width: 380 }}
                                    options={companyTypeList?.data}
                                    onChange={handleCompanyType}
                                    placeholder="请选择企业行业"
                                />
                            </Form.Item>

                            <Form.Item
                                label={"企业规模"}
                                name={"companyScaleId"}
                                rules={[{ required: true, message: "企业规模必选" }]}
                            >
                                <Select
                                    placeholder='请选择企业规模'
                                    showSearch
                                    allowClear
                                    optionFilterProp='children'
                                    style={{ width: 380 }}
                                >
                                    {companyScaleOptions}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label={"企业福利"}
                                name={"companyTagIdList"}
                            >
                                <Select
                                    placeholder='请选择企业福利'
                                    showSearch
                                    allowClear
                                    optionFilterProp='children'
                                    style={{ width: 380 }}
                                    mode="multiple"
                                >
                                    {companyTagOptions}
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label={"法人代表"}
                                name={"companyLeader"}
                            >
                                <Input style={{ width: 380 }} placeholder={"请输入法人代表"} />
                            </Form.Item>

                            <Form.Item
                                label={"注册资本"}
                                name={"companyCapital"}
                            >
                                <InputNumber min={0} style={{ width: 380 }} placeholder={"请输入注册资本"} addonAfter={'万元'} />
                            </Form.Item>

                            <Form.Item
                                label={"成立时间"}
                                name={"companyBuildTimeStr"}
                            >
                                <DatePicker style={{ width: 380 }} onChange={handleSelectTime} />
                            </Form.Item>

                            <Form.Item
                                label={"省市区"}
                                name={"companyCascader"}
                            >
                                <Cascader style={{ width: 380 }} options={areaList?.data} onChange={handleCascader} placeholder={"请选择省市区"} />
                            </Form.Item>

                            <Form.Item
                                label={"详细地址"}
                                name={"companyAddress"}
                            >
                                <Input style={{ width: 380 }} placeholder={"请输入详细地址"} />
                            </Form.Item>

                            <Form.Item
                                label={'经纬度'}
                                name={'companyLatitude'}
                            >
                                <Form.Item
                                    name={'companyLatitude'}
                                    style={{ float: 'left', marginBottom: 0 }}
                                >
                                    <Input type={'number'} style={{ width: 150 }} placeholder={'请输入纬度'} />
                                </Form.Item>
                                <Form.Item
                                    name={'companyLongitude'}
                                    style={{ float: 'left', marginBottom: 0 }}
                                >
                                    <Input type={'number'} style={{ width: 150, marginLeft: 10 }} placeholder={'请输入经度'} />
                                </Form.Item>
                                <Form.Item style={{ float: 'left', marginBottom: 0, marginLeft: 10 }}>
                                    <Tooltip title='坐标拾起器'>
                                        <Typography.Link target='_blank' href='https://lbs.qq.com/getPoint/'>
                                            坐标
                                        </Typography.Link>
                                    </Tooltip>
                                </Form.Item>
                            </Form.Item>

                            <Form.Item
                                label={"排序"}
                                name={"companySort"}
                                rules={[{ required: true, message: "排序必填" }]}
                            >
                                <Input style={{ width: 380 }} placeholder={"请输入排序"} />
                            </Form.Item>

                            <Form.Item
                                label={"状态"}
                                name={"companyShow"}
                                rules={[{ required: true, message: "状态必选" }]}
                                style={{ textAlign: 'left' }}
                            >
                                <Radio.Group>
                                    <Radio value={true}>显示</Radio>
                                    <Radio value={false}>不显示</Radio>
                                </Radio.Group>
                            </Form.Item>

                            <Form.Item label={'搜索用户'} name={'memberTel'}>
                                <Search
                                    placeholder={'请输入手机号检索用户'}
                                    maxLength={11}
                                    onChange={handleMemberTel}
                                    style={{ width: 380 }}></Search>
                            </Form.Item>

                            {memberList?.length ? (
                                <Form.Item label={'选择用户'} name={'memberId'}>
                                    <Radio.Group>
                                        {memberList?.map((item: any) => (
                                            <Space align='center' direction='vertical' size={5} style={{ marginBottom: '10px', marginRight: '5px' }} key={item.memberId}>
                                                <Radio value={item.memberId}><span className='text1' style={{ width: '60px', display: 'block' }} >{item.memberNickname}</span></Radio>
                                                <Image width='60px' height='60px' src={item.memberHeadImg} />
                                            </Space>
                                        ))}
                                    </Radio.Group>
                                </Form.Item>
                            ) : (
                                <Form.Item label={'选择用户'}>
                                    <Empty description={'暂无用户'} style={{ maxWidth: '180px', height: '50px' }} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                </Form.Item>
                            )}

                        </TabPane>

                        <TabPane tab="企业介绍" key="2">

                            <RichText
                                label={"企业介绍"}
                                name={"companyContent"}
                                setContent={setCompanyContent}
                            >
                            </RichText>

                        </TabPane>
                    </Tabs>

                    <Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                        <Button loading={submitLoading} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>

        </div>
    )
}

