import { useMutation, useQuery, useQueryClient } from "react-query";
import { GoodsServeTag } from "../interfaces/goods-serve-tag";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useGoodsServeTagList = (param?: Partial<GoodsServeTag>) => {
    const client = useHttp();
    const result = useQuery<any>(['goodsServeTagList', param], () => client('goodsServeTag', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditGoodsServeTag = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<GoodsServeTag>) => client(`goodsServeTag`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('goodsServeTagList')
        }
    )
}

export const useAddGoodsServeTag = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<GoodsServeTag>) => client(`goodsServeTag`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('goodsServeTagList')
        }
    )
}

export const useDeleteGoodsServeTag = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ goodsServeTagId }: { goodsServeTagId: number}) =>
            client(`goodsServeTag/${goodsServeTagId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('goodsServeTagList')
        }
    );
};

export const useGoodsServeTag = (goodsServeTagId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['goodsServeTag', {goodsServeTagId}],
        () => client(`goodsServeTag/${goodsServeTagId}`),
        {
            enabled: Boolean(goodsServeTagId)
        }
    )
}

/**查询全部 */
export const useGoodsServeTagListAll = (param?: Partial<GoodsServeTag>) => {
    const client = useHttp();
    const result = useQuery<any>(["goodsServeTagListAll", param], () =>
        client("goodsServeTag/listAll", { data: param })
    );
    const data = result.data?.data;
    return {...result, data };
};