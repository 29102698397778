import { useMutation, useQuery, useQueryClient } from "react-query";
import { Withdraw } from "../interfaces/withdraw";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useWithdrawList = (param?: Partial<Withdraw>) => {
    const client = useHttp();
    const result = useQuery<any>(['withdrawPage', param], () => client('withdraw', { data: cleanObject(param) }))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return { ...result, totalElements, data };
}

export const useAgreeWithdraw = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ withdrawId }: { withdrawId: number }) =>
            client(`withdraw/agree?withdrawId=` + withdrawId, {
                method: "POST",
            }), {
        onSuccess: () => queryClient.invalidateQueries('withdrawPage')
    }
    );
};

export const useRejectWithdraw = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Withdraw>) => client(`withdraw/reject`, {
            method: 'POST',
            data: params
        }), {
        onSuccess: () => queryClient.invalidateQueries('withdrawPage')
    }
    );
}

export const useWithdraw = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ withdrawId }: { withdrawId: number }) =>
            client(`withdraw/${withdrawId}`, {
                method: "GET",
            }), {
        onSuccess: () => queryClient.invalidateQueries('withdraw')
    }
    );
};