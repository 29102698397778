import { useMutation, useQuery, useQueryClient } from "react-query";
import { CompanyTag } from "../interfaces/company-tag";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useCompanyTagList = (param?: Partial<CompanyTag>) => {
    const client = useHttp();
    const result = useQuery<any>(['companyTagPage', param], () => client('companyTag', { data: cleanObject(param) }))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return { ...result, totalElements, data };
}

export const useEditCompanyTag = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<CompanyTag>) => client(`companyTag`, {
            method: 'PUT',
            data: params
        }), {
        onSuccess: () => queryClient.invalidateQueries('companyTagPage')
    }
    )
}

export const useAddCompanyTag = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<CompanyTag>) => client(`companyTag`, {
            data: params,
            method: 'POST'
        }), {
        onSuccess: () => queryClient.invalidateQueries('companyTagPage')
    }
    )
}

export const useDeleteCompanyTag = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ companyTagId }: { companyTagId: number }) =>
            client(`companyTag/${companyTagId}`, {
                method: "DELETE",
            }), {
        onSuccess: () => queryClient.invalidateQueries('companyTagPage')
    }
    );
};

export const useCompanyTag = (companyTagId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['companyTag', { companyTagId }],
        () => client(`companyTag/${companyTagId}`),
        {
            enabled: Boolean(companyTagId)
        }
    )
}

export const useCompanyTagListAll = () => {
    const client = useHttp();
    return useQuery<any>(["companyTagList", { }], () =>
        client(`companyTag/list`)
    );
};