import { useMutation, useQuery, useQueryClient } from "react-query";
import { SchoolOrders } from "../interfaces/school-orders";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useSchoolOrdersList = (param?: Partial<SchoolOrders>) => {
    const client = useHttp();
    const result = useQuery<any>(['schoolOrdersPage', param], () => client('schoolOrders', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useComfirmPaySchoolOrders = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ schoolOrdersSn }: { schoolOrdersSn: string }) =>
            client(`schoolOrders?schoolOrdersSn=${schoolOrdersSn}`, {
                method: "POST",
            }), {
        onSuccess: () => queryClient.invalidateQueries('schoolOrdersPage')
    }
    );
};