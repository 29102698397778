import { Button, Col, message, Modal, Row, Space, Table } from "antd"
import Search from "antd/lib/input/Search"
import { useEffect } from "react"
import { Link, useLocation } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useBusinessCircleStoreList, useDeleteBusinessCircleStore } from "../../services/store-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useStoreSearchParams } from "../store/util"

export const BusinessCircleStoreListPage = () => {
    useDocumentTitle('门店列表', true)
    const { pathname } = useLocation()
    const businessCircleId = pathname.match(/shop\/store-manage\/businessCircle\/store\/list\/(\d+)/)?.[1]
    const [param, setParam] = useStoreSearchParams()
    useEffect(() => {
        setParam({
            businessCircleId: businessCircleId,
        })
    }, [businessCircleId]);
    const {isLoading, error, totalElements, data: list} = useBusinessCircleStoreList(useDebounce(param, 200))
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) => 
            setParam({
            ...param,
            pageNum: (current -1),
        })
    }
    //删除事件
    const useMutateDeleteBusinessCircleStore = useDeleteBusinessCircleStore;
    const { mutateAsync } = useMutateDeleteBusinessCircleStore();
    const confirmDeleteDeleteBusinessCircleStore = (storeId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ storeId }).then(() => {
                        message.info('删除成功');
                        Modal.destroyAll();
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    return (
        <div>
            <Row style={{marginBottom: 24}}>
                <Col span={12}>
                    <Link style={{marginRight: 10}} to={"/shop/store-manage/businessCircle/list"}>
                        <Button type="primary">商圈列表</Button>
                    </Link>
                    <Link to={"/shop/store-manage/businessCircle/store/add/" + businessCircleId}>
                        <Button type="primary">添加门店</Button>
                    </Link>
                </Col>
                <Col span={12} style={{textAlign:'right'}}>
                    
                </Col>
            </Row>
            <Table 
                loading = { isLoading }
                rowKey={ columns => columns.storeId } 
                pagination = { paginationProps } 
                columns = {[
                {
                    title: "门店ID",
                    dataIndex: "storeId",
                    key: "storeId",
                    width: 100,
                },
                {
                    title: "门店名称",
                    dataIndex: "storeName",
                    key: "storeName",
                },
                {
                    title: '操作',
                    key: 'action',
                    render: (value, store) => (
                        <Space size="middle">
                            <a onClick={() => confirmDeleteDeleteBusinessCircleStore(store.storeId)}>
                                删除
                            </a>
                        </Space>
                    ),
                    width: 150,
                },
            ]}
            dataSource={ list || [] }  />
            <ErrorBox error={ error } />
        </div>
    )
}