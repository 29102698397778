import { useMutation, useQuery, useQueryClient } from "react-query";
import { AgencyTag } from "../interfaces/agency-tag";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useAgencyTagList = (param?: Partial<AgencyTag>) => {
    const client = useHttp();
    const result = useQuery<any>(['agencyTagPage', param], () => client('agencyTag', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useAgencyTagListAll = () => {
    const client = useHttp();
    const result = useQuery<any>(['agencyTagAllList'], () => client('agencyTag/list'))
    const data = result.data?.data;
    return {...result, data };
}

export const useEditAgencyTag = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<AgencyTag>) => client(`agencyTag`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('agencyTagPage')
        }
    )
}

export const useAddAgencyTag = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<AgencyTag>) => client(`agencyTag`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('agencyTagPage')
        }
    )
}

export const useDeleteAgencyTag = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ agencyTagId }: { agencyTagId: number}) =>
            client(`agencyTag/${agencyTagId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('agencyTagPage')
        }
    );
};

export const useAgencyTag = (agencyTagId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['agencyTag', {agencyTagId}],
        () => client(`agencyTag/${agencyTagId}`),
        {
            enabled: Boolean(agencyTagId)
        }
    )
}