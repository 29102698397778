import { useMemo } from "react";
import { useUrlQueryParam } from "../../utils/url";

export const useSchoolOrdersRefundSearchParams = () => {
    const [param, setParam] = useUrlQueryParam(["schoolOrdersRefundSn", "schoolOrdersSn", "schoolId", "agencyId", "schoolOrdersRefundState", "schoolOrdersRefundAddTimeStart", "schoolOrdersRefundAddTimeEnd", "schoolOrdersRefundContactName", "schoolOrdersRefundContactTel", "memberNickname", "schoolOrdersRefundId", "pageNum"]);
    return [
        useMemo(() => ({...param, schoolOrdersRefundId: Number(param.schoolOrdersRefundId) || undefined, schoolId: Number(param.schoolId) || undefined,
            agencyId: Number(param.agencyId) || undefined, pageNum: Number(param.pageNum) || undefined}), [param]),
        setParam
    ] as const
}