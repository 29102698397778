import { Button, Col, message, Modal, Row, Space, Table, Select, Card, Form, Input } from "antd"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useMajorClassListAll } from "../../services/major-class-service"
import { useMajorList, useDeleteMajor } from "../../services/major-service"
import { useTrendsTagListAll } from "../../services/trends-tag-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useMajorSearchParams } from "./util"
const { Option } = Select;

export const MajorListPage = () => {
    useDocumentTitle('专业列表', true)
    const [param, setParam] = useMajorSearchParams()
    const { isLoading, error, totalElements, data: list } = useMajorList(useDebounce(param, 200))

    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) =>
            setParam({
                ...param,
                pageNum: (current - 1),
            })
    }

    //分类
    const { data: majorClassList } = useMajorClassListAll(1);
    const majorClassOptions = majorClassList?.data?.map((item: any) =>
        <Option value={item?.value}>{item?.label}</Option>
    )

    //动态标签
    const { data: trendsTagList } = useTrendsTagListAll(1);
    const trendsTagOptions = trendsTagList?.data?.map((item: any) =>
        <Option value={item?.trendsTagId}>{item?.trendsTagName}</Option>
    )

    //删除事件
    const { mutateAsync: deleteMutateAsync } = useDeleteMajor();
    const confirmDeleteMajor = (majorId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    deleteMutateAsync({ majorId }).then((data) => {
                        if (data.status === 0) {
                            message.info('删除成功');
                            Modal.destroyAll();
                        } else {
                            message.error(data.msg)
                        }
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };

    //提交
    const onFinish = (values: any) => {
        setParam({
            ...param,
            majorName: values.majorName,
            majorClassParentId: values.majorClassParentId,
            trendsTagId: values.trendsTagId,
            pageNum: null
        })
    }

    //分页
    return (
        <div>
            <Row style={{ marginBottom: 24 }}>
                <Col span={12}>
                    <Link to={"/xuexiao/major-manage/major/add"}>
                        <Button type="primary">新建</Button>
                    </Link>
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                </Col>
            </Row>
            <Card style={{ backgroundColor: "#f2f3f5", margin: '24px 0 24px 0' }}>
                <Form onFinish={onFinish}>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item name="majorName" label="专业名称">
                                <Input placeholder='请输入专业名称' allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="majorClassParentId" label="专业分类">
                                <Select
                                    placeholder='请选择专业分类'
                                    showSearch
                                    allowClear
                                    optionFilterProp='children'
                                >
                                    {majorClassOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="trendsTagId" label="动态标签">
                                <Select
                                    placeholder='请选择动态标签'
                                    showSearch
                                    allowClear
                                    optionFilterProp='children'
                                >
                                    {trendsTagOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item style={{ marginBottom: 0 }}>
                        <Button style={{ marginLeft: 70 }} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
            <Table
                loading={isLoading}
                rowKey={columns => columns.majorId}
                pagination={paginationProps}
                columns={[
                    {
                        title: 'ID',
                        dataIndex: 'majorId',
                        key: 'majorId',
                        align: 'center',
                        width: 100,
                    },
                    {
                        title: '名称',
                        dataIndex: 'majorName',
                        key: 'majorName',
                        align: 'center',
                    },
                    {
                        title: '分类',
                        dataIndex: 'majorClassName',
                        key: 'majorClassName',
                        align: 'center',
                        render: (value, major) => (
                            value ? major?.majorClassParentName + '-' + value : ''
                        )
                    },
                    {
                        title: '动态标签',
                        dataIndex: 'trendsTagName',
                        key: 'trendsTagName',
                        align: 'center',
                    },
                    {
                        title: '排序',
                        dataIndex: 'majorSort',
                        key: 'majorSort',
                        align: 'center',
                    },
                    {
                        title: '状态',
                        dataIndex: 'majorShow',
                        key: 'majorShow',
                        align: 'center',
                        render: (value) => (
                            value ? '显示' : '不显示'
                        ),
                    },
                    {
                        title: '操作',
                        key: 'action',
                        align: 'center',
                        render: (value, major) => (
                            <Space size="middle">
                                <Link to={"/xuexiao/major-manage/major/update/" + major.majorId}>
                                    编辑
                                </Link>
                                <a onClick={() => confirmDeleteMajor(major.majorId)}>
                                    删除
                                </a>
                            </Space>
                        ),
                        width: 150,
                    }
                ]}
                dataSource={list || []} />
            <ErrorBox error={error} />
        </div>
    )
}