import { Button, Col, message, Modal, Row, Space, Table, Image, Card, Form, Input } from "antd";
import { Link } from "react-router-dom";
import { ErrorBox } from "../../components/lib";
import { usePlannerList, useOffPlanner, useUpPlanner } from "../../services/planner-service";
import { useDebounce, useDocumentTitle } from "../../utils";
import { usePlannerSearchParams } from "./util";
import { useState } from "react";
import { useResetIdentityAccount } from "../../services/identity-account-service";

export const PlannerListPage = () => {
  useDocumentTitle("列表", true);
  const imgUrl = process.env.REACT_APP_IMG_URL;
  const [param, setParam] = usePlannerSearchParams();
  const { isLoading, error, totalElements, data: list, } = usePlannerList(useDebounce(param, 200));

  //分页
  const paginationProps = {
    showSizeChanger: true,
    onShowSizeChange: (current: number, pageSize: number) => {
      setParam({
        ...param,
        pageNum: current - 1,
        pageSize,
      });
    },
    defaultCurrent: 1,
    pageSize: param.pageSize,
    total: totalElements,
    onChange: (current: number, pageSize: number) =>
      setParam({
        ...param,
        pageNum: current - 1,
        pageSize,
      }),
  };
  //搜索
  const onFinish = (values: any) => {
    setParam({
      ...param,
      plannerName: values.plannerName,
      pageNum: null,
    });
  };
  //上架事件
  const { mutateAsync: upPlannerFuntion } = useUpPlanner();
  const confirmUpPlanner = (plannerId: number) => {
    Modal.confirm({
      title: "确定通过该规划师吗?",
      content: "点击确定通过",
      okText: "确定",
      onOk(): any {
        return new Promise((resolve, reject) => {
          upPlannerFuntion({ plannerId }).then((data) => {
            if (data.status === 0) {
              message.info("审核成功");
              Modal.destroyAll();
            } else {
              message.error(data.msg);
            }
          });
        }).catch(() => console.log("Oops errors!"));
      },
      cancelText: "取消",
    });
  };
  //下架事件
  const { mutateAsync: offPlannerFuntion } = useOffPlanner();
  const [plannerId, setPlannerId] = useState<number>();
  const [offModalOpen, setOffModalOpen] = useState(false);
  const [offSubmitLoading, setOffSubmitLoading] = useState(false);
  const handleCancel = () => {
    setOffModalOpen(false);
    setPlannerId(undefined);
  }
  const confirmOffPlanner = (plannerId: number) => {
    setPlannerId(plannerId);
    setOffModalOpen(true);
  }
  const onRejectFinish = (values: any) => {
    console.log(values);
    setOffSubmitLoading(true)
    let param = { plannerId, systemRemark: values.systemRemark };
    return new Promise((resolve, reject) => {
      offPlannerFuntion(param).then((data) => {
        if (data.status === 0) {
          message.info("拒绝成功");
          Modal.destroyAll();
        } else {
          message.error(data.msg);
        }
      }).finally(() => setOffSubmitLoading(false));
    }).catch(() => console.log("Oops errors!"));
  }
  //多图查看
  const [visible, setVisible] = useState(false);
  const [imageList, setImageList] = useState([]);
  const handleImage = (value: []) => {
    setImageList(value);
    setVisible(true);
  };
  //重置密码
  const { mutateAsync: resetIdentityAccount } = useResetIdentityAccount();
  const confirmResetIdentityAccount = (identityType: number, identityId: number) => {
    Modal.confirm({
      title: "确定操作这条数据吗?",
      content: "点击确定取消",
      okText: "确定",
      onOk(): any {
        return new Promise((resolve, reject) => {
          resetIdentityAccount({ identityType, identityId }).then((data) => {
            if (data.status === 0) {
              message.info("操作成功");
              Modal.destroyAll();
            } else {
              message.error(data.msg);
            }
          });
        }).catch(() => console.log("Oops errors!"));
      },
      cancelText: "取消",
    });
  };
  //分页
  return (
    <div>
      <div style={{ display: "none" }}>
        <Image.PreviewGroup
          preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}
        >
          {imageList
            ? imageList?.map(function (item: any) {
              return (
                <Image
                  src={imgUrl + item}
                  style={{
                    width: "80px",
                    height: "80px",
                    marginRight: "5px",
                  }}
                ></Image>
              );
            })
            : ""}
        </Image.PreviewGroup>
      </div>
      <Row style={{ marginBottom: 24 }}>
        <Col span={12}>
          <Link to={"/jigou/planner-manage/planner/add"}>
            <Button type="primary">新建</Button>
          </Link>
        </Col>
      </Row>
      <Card style={{ backgroundColor: "#f2f3f5", marginBottom: 24 }}>
        <Form onFinish={onFinish}>
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item name="plannerName" label="名称">
                <Input placeholder="请输入名称" allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item style={{ marginBottom: 0 }}>
            <Button style={{ marginLeft: 70 }} type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <Table
        loading={isLoading}
        rowKey={(columns) => columns.plannerId}
        pagination={paginationProps}
        scroll={{ x: 1600 }}
        columns={[
          {
            title: "ID",
            dataIndex: "plannerId",
            key: "plannerId",
            align: "center",
            width: 100,
          },
          {
            title: "规划师归属",
            dataIndex: "plannerType",
            key: "plannerName",
            align: "center",
            render(value, record, index) {
              return (<>
                {value === 1 ? "机构" : "学校"}
              </>)
            },
          },

          {
            title: "规划师名",
            dataIndex: "plannerName",
            key: "plannerName",
            align: "center",
          },

          {
            title: "规划师头像",
            dataIndex: "plannerHeadImg",
            key: "plannerHeadImg",
            align: "center",
            width: 120,
            render: (value) => (value ? <Image width={80} src={imgUrl + value} /> : ""),
          },

          {
            title: "背景图片",
            dataIndex: "plannerPic",
            key: "plannerPic",
            align: "center",
            width: 100,
            render: (value) => (value ? <Image width={80} src={imgUrl + value} /> : ''),
          },

          {
            title: "多图图片",
            dataIndex: "plannerMorePicList",
            key: "plannerMorePics",
            align: "center",
            width: 100,
            render: (value) => (
              <>
                {value ?
                  <>
                    <Image
                      preview={{ visible: false }}
                      width={80}
                      src={imgUrl + value[0]}
                      onClick={() => handleImage(value)}
                    />
                  </>
                  : ""}</>
            ),
          },

          {
            title: "规划师介绍",
            dataIndex: "plannerIntroduce",
            key: "plannerIntroduce",
            align: "center",
            render(value, record, index) {
              return (<>
                <div style={{
                  overflow: "hidden", textOverflow: "ellipsis",
                  display: "-webkit-box", WebkitLineClamp: 3, WebkitBoxOrient: "vertical"
                }}>{value}</div>
              </>)
            },
          },

          {
            title: "机构/学校名称",
            dataIndex: "",
            key: "agencyName",
            align: "center",
            render(value, record, index) {
              return (<>
                {record.plannerType === 1 ? record.agencyName : record.schoolName}
              </>)
            },
          },


          {
            title: "评分",
            dataIndex: "plannerEvaScore",
            key: "plannerEvaScore",
            align: "center",
            width: 70
          },
          {
            title: "状态",
            dataIndex: "plannerStateValue",
            key: "plannerStateValue",
            align: "center",
            fixed: "right",
            render(value, record) {
              if (record.plannerState !== 30) {
                return (<>
                  {value}<br />
                  {record.systemRemark ? "拒绝原因:" + record.systemRemark : ""}
                </>);
              } else {
                return (<>{value}</>)
              }
            },
          },

          {
            title: "操作",
            key: "action",
            align: "center",
            fixed: "right",
            render: (value, planner) => (
              <Space size="middle">
                <Link to={"/jigou/planner-manage/planner/detail?plannerId=" + planner.plannerId}>
                  详情
                </Link>
                {planner.plannerState === 10 ? <>
                  <a onClick={() => confirmUpPlanner(planner.plannerId)}>
                    通过
                  </a>
                  <a onClick={() => confirmOffPlanner(planner.plannerId)}>
                    拒绝
                  </a></>
                  : planner.plannerState === 30 ?
                    <a onClick={() => confirmResetIdentityAccount(4, planner.plannerId)}>
                      重置密码
                    </a>
                    : ""}

              </Space>
            ),
            width: 150,
          },
        ]}
        dataSource={list || []}
      />
      <ErrorBox error={error} />

      <Modal title="拒绝通过" open={offModalOpen} okButtonProps={{ hidden: true }} onCancel={handleCancel} cancelButtonProps={{ hidden: true }}>
        <Form onFinish={onRejectFinish}>
          <Form.Item
            label="拒绝理由"
            name={"systemRemark"}
            rules={[{ required: true, message: '拒绝理由必填' }]}
          >
            <Input />
          </Form.Item>
          <Form.Item style={{ paddingLeft: "80px" }}>
            <Button loading={offSubmitLoading} type="primary" htmlType="submit">
              提交
            </Button>
            <Button style={{ marginLeft: "50px" }} type="default" onClick={handleCancel}>
              取消
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
