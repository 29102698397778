import { Button, Col, message, Modal, Row, Space, Table, Image, Select, Card, Form, Input } from "antd"
import Search from "antd/lib/input/Search"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useAreaListCity } from "../../services/area-service"
import { useAdvList, useDeleteAdv } from "../../services/mall-adv-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useAdvSearchParams } from "./util"

export const MallAdvListPage = () => {
    useDocumentTitle('广告列表', true)
    const imgUrl = process.env.REACT_APP_IMG_URL
    const [param, setParam] = useAdvSearchParams()
    const { isLoading, error, totalElements, data: list } = useAdvList(useDebounce(param, 200))
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) =>
            setParam({
                ...param,
                pageNum: (current - 1),
            })
    }
    //删除事件
    const useMutateDeleteAdv = useDeleteAdv;
    const { mutateAsync } = useMutateDeleteAdv();
    const confirmDeleteAdv = (advId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ advId }).then(() => {
                        message.info('删除成功');
                        Modal.destroyAll();
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    //准备区域数据
    const { data: areaList } = useAreaListCity();
    const areaListOptions = areaList?.data?.map((item: any, value: any) => (
        <Select.Option value={item.areaId} key={item.areaId}>
            {item.areaName}
        </Select.Option>
    ));
    const onFinish = (values: any) => {
        setParam({
            ...param,
            ...values,
            pageNum: null
        })
    }
    //分页
    return (
        <div>
            <Row style={{ marginBottom: 24 }}>
                <Col span={12}>
                    <Link to={"/shop/dress-manage/mall-adv/add"}>
                        <Button type="primary">新建广告</Button>
                    </Link>
                </Col>
            </Row>
            <Card style={{ backgroundColor: "#f2f3f5", margin: '24px 0 24px 0' }}>
                <Form onFinish={onFinish}>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item name="advTitle" label="名称">
                                <Input placeholder='请输入名称' allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="areaId" label="区域">
                                <Select
                                    placeholder='请选择区域'
                                    showSearch
                                    allowClear
                                    optionFilterProp='children'
                                >
                                    {areaListOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item style={{ marginBottom: 0 }}>
                        <Button
                            style={{ marginLeft: 70 }}
                            type="primary"
                            htmlType="submit"
                        >
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
            <Table
                loading={isLoading}
                rowKey={columns => columns.advId}
                pagination={paginationProps}
                columns={[
                    {
                        title: '广告ID',
                        dataIndex: 'advId',
                        key: 'advId',
                        width: 100,
                    },
                    {
                        title: '地区',
                        dataIndex: 'areaName',
                        key: 'areaName',
                        width: 100,
                    },
                    {
                        title: '广告标题',
                        dataIndex: 'advTitle',
                        key: 'advTitle',
                    },
                    {
                        title: '广告图片',
                        dataIndex: 'advPic',
                        key: 'advPic',
                        render: (value, adv) => (
                            <Image width={150} src={imgUrl + adv.advPic} />
                        ),
                    },
                    {
                        title: '广告排序',
                        dataIndex: 'advSort',
                        key: 'advSort',
                    },
                    {
                        title: '是否显示',
                        dataIndex: 'advShow',
                        key: 'advShow',
                        render: (value, adv) => (
                            <span>{adv.advShow ? '显示' : '不显示'}</span>
                        ),
                    },
                    {
                        title: '操作',
                        key: 'action',
                        render: (value, adv) => (
                            <Space size="middle">
                                <Link to={"/shop/dress-manage/mall-adv/update/" + adv.advId}>
                                    编辑
                                </Link>
                                <a onClick={() => confirmDeleteAdv(adv.advId)}>
                                    删除
                                </a>
                            </Space>
                        ),
                        width: 150,
                    }
                ]}
                dataSource={list || []} />
            <ErrorBox error={error} />
        </div>
    )
}