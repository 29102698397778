import { Layout } from "antd";
import { SiderLayout } from "../../layouts/sider";
import { HeaderLayout } from "../../layouts/header";
import { FooterLayout } from "../../layouts/footer";
//概况
import { DashboardPage } from "../dashboard/index";
//引导页
import { GuideListPage } from "../guide/list";
import { GuideAddPage } from "../guide/add";
import { GuideUpdatePage } from "../guide/update";
//开屏广告
import { BombWindowListPage } from "../bomb-window/list";
import { BombWindowAddPage } from "../bomb-window/add";
import { BombWindowUpdatePage } from "../bomb-window/update";
//标签栏
import { TabBarListPage } from "../tab-bar/list";
import { TabBarAddPage } from "../tab-bar/add";
import { TabBarUpdatePage } from "../tab-bar/update";
//标签栏广告
import { TabBarAdvListPage } from "../tab-bar-adv/list";
import { TabBarAdvAddPage } from "../tab-bar-adv/add";
import { TabBarAdvUpdatePage } from "../tab-bar-adv/update";
//广告
import { AdvListPage } from "../adv/list";
import { AdvAddPage } from "../adv/add";
import { AdvUpdatePage } from "../adv/update";
//导航
import { NavigationListPage } from "../navigation/list";
import { NavigationAddPage } from "../navigation/add";
import { NavigationUpdatePage } from "../navigation/update";
//菜单
import { MenuListPage } from "../menu/list";
import { MenuAddPage } from "../menu/add";
import { MenuUpdatePage } from "../menu/update";
//工具
import { PersonMenuListPage } from "../person-menu/list";
import { PersonMenuAddPage } from "../person-menu/add";
import { PersonMenuUpdatePage } from "../person-menu/update";
//公告
import { NoticeListPage } from "../notice/list";
import { NoticeAddPage } from "../notice/add";
import { NoticeUpdatePage } from "../notice/update";
//热门搜索
import { SearchHotListPage } from "../search-hot/list";
import { SearchHotAddPage } from "../search-hot/add";
import { SearchHotUpdatePage } from "../search-hot/update";
//默认搜索
import { SearchDefaultIndexPage } from "../search-default/index";
//区域
import { AreaListPage } from "../area/list";
import { AreaAddPage } from "../area/add";
import { AreaUpdatePage } from "../area/update";
//动态
import { TrendsListPage } from "../trends/list";
import { TrendsCommentListPage } from "../trends-comment/list";
//动态标签
import { TrendsTagListPage } from "../trends-tag/list";
import { TrendsTagAddPage } from "../trends-tag/add";
import { TrendsTagUpdatePage } from "../trends-tag/update";
//学校广告
import { SchoolAdvListPage } from "../school-adv/list";
import { SchoolAdvAddPage } from "../school-adv/add";
import { SchoolAdvUpdatePage } from "../school-adv/update";
//学校导航
import { SchoolNavigationListPage } from "../school-navigation/list";
import { SchoolNavigationAddPage } from "../school-navigation/add";
import { SchoolNavigationUpdatePage } from "../school-navigation/update";
//学校头条
import { SchoolNoticeListPage } from "../school-notice/list";
import { SchoolNoticeAddPage } from "../school-notice/add";
import { SchoolNoticeUpdatePage } from "../school-notice/update";
//学校
import { SchoolListPage } from "../school/list";
import { SchoolAddPage } from "../school/add";
import { SchoolUpdatePage } from "../school/update";
//学校认证
import { SchoolAuthListPage } from "../school-auth/list";
//预约探校
import { SchoolAppointListPage } from "../school-appoint/list";
//学校层次
import { SchoolLevelListPage } from "../school-level/list";
import { SchoolLevelAddPage } from "../school-level/add";
import { SchoolLevelUpdatePage } from "../school-level/update";
//学校类型
import { SchoolTypeListPage } from "../school-type/list";
import { SchoolTypeAddPage } from "../school-type/add";
import { SchoolTypeUpdatePage } from "../school-type/update";
//学校专题
import { SchoolSubjectListPage } from "../school-subject/list";
import { SchoolSubjectAddPage } from "../school-subject/add";
import { SchoolSubjectUpdatePage } from "../school-subject/update";
//学校资讯
import { SchoolArticleListPage } from "../school-article/list";
import { SchoolArticleAddPage } from "../school-article/add";
import { SchoolArticleUpdatePage } from "../school-article/update";
//专业
import { MajorListPage } from "../major/list";
import { MajorAddPage } from "../major/add";
import { MajorUpdatePage } from "../major/update";
//专业分类
import { MajorClassListPage } from "../major-class/list";
import { MajorClassAddPage } from "../major-class/add";
import { MajorClassUpdatePage } from "../major-class/update";
//学费订单
import { SchoolOrdersListPage } from "../school-orders/list";
//学费售后
import { SchoolOrdersRefundListPage } from "../school-orders-refund/list";
//学费设置
import { SchoolOrdersSettingIndexPage } from "../school-orders-setting/index";
//企业
import { CompanyListPage } from "../company/list";
import { CompanyUpdatePage } from "../company/update";
import { CompanyAuthListPage } from "../company-auth/list";
//HR
import { HrListPage } from "../hr/list";
//企业性质
import { CompanyAttributeListPage } from "../company-attribute/list";
import { CompanyAttributeAddPage } from "../company-attribute/add";
import { CompanyAttributeUpdatePage } from "../company-attribute/update";
//企业类型
import { CompanyTypeListPage } from "../company-type/list";
import { CompanyTypeAddPage } from "../company-type/add";
import { CompanyTypeUpdatePage } from "../company-type/update";
//企业福利
import { CompanyTagListPage } from "../company-tag/list";
import { CompanyTagAddPage } from "../company-tag/add";
import { CompanyTagUpdatePage } from "../company-tag/update";
//企业规模
import { CompanyScaleListPage } from "../company-scale/list";
import { CompanyScaleAddPage } from "../company-scale/add";
import { CompanyScaleUpdatePage } from "../company-scale/update";
//职位
import { CompanyPositionListPage } from "../company-position/list";
import { CompanyPositionAddPage } from "../company-position/add";
import { CompanyPositionUpdatePage } from "../company-position/update";
//职位分类
import { CompanyPositionClassListPage } from "../company-position-class/list";
import { CompanyPositionClassAddPage } from "../company-position-class/add";
import { CompanyPositionClassUpdatePage } from "../company-position-class/update";
//招聘
import { RecruitListPage } from "../recruit/list";
import { RecruitResumeListPage } from "../recruit-resume/list";
import { RecruitResumeDetailPage } from "../recruit-resume/detail";
//招聘广告
import { RecruitAdvListPage } from "../recruit-adv/list";
import { RecruitAdvAddPage } from "../recruit-adv/add";
import { RecruitAdvUpdatePage } from "../recruit-adv/update";
//招聘导航
import { RecruitNavigationListPage } from "../recruit-navigation/list";
import { RecruitNavigationAddPage } from "../recruit-navigation/add";
import { RecruitNavigationUpdatePage } from "../recruit-navigation/update";
//经验要求
import { RecruitExperienceListPage } from "../recruit-experience/list";
import { RecruitExperienceAddPage } from "../recruit-experience/add";
import { RecruitExperienceUpdatePage } from "../recruit-experience/update";
//学历要求
import { RecruitEducationListPage } from "../recruit-education/list";
import { RecruitEducationAddPage } from "../recruit-education/add";
import { RecruitEducationUpdatePage } from "../recruit-education/update";
//简历学历
import { ResumeEducationListPage } from "../resume-education/list";
import { ResumeEducationAddPage } from "../resume-education/add";
import { ResumeEducationUpdatePage } from "../resume-education/update";
//机构
import { AgencyListPage } from "../agency/list";
import { AgencyDetailPage } from "../agency/detail";
//热门机构
import { AgencyHotListPage } from "../agency-hot/list";
//课程
import { CourseListPage } from "../course/list";
import { CourseDetailPage } from "../course/detail";
//课程分类
import { CourseClassListPage } from "../course-class/list";
import { CourseClassAddPage } from "../course-class/add";
import { CourseClassUpdatePage } from "../course-class/update";
//课程广告
import { CourseAdvListPage } from "../course-adv/list";
import { CourseAdvAddPage } from "../course-adv/add";
import { CourseAdvUpdatePage } from "../course-adv/update";
//课程导航
import { CourseNavigationListPage } from "../course-navigation/list";
import { CourseNavigationAddPage } from "../course-navigation/add";
import { CourseNavigationUpdatePage } from "../course-navigation/update";
//课程公告
import { CourseNoticeListPage } from "../course-notice/list";
import { CourseNoticeAddPage } from "../course-notice/add";
import { CourseNoticeUpdatePage } from "../course-notice/update";
//课程热门搜索
import { CourseSearchHotListPage } from "../course-search-hot/list";
import { CourseSearchHotAddPage } from "../course-search-hot/add";
import { CourseSearchHotUpdatePage } from "../course-search-hot/update";
//课程默认搜索
import { CourseSearchDefaultIndexPage } from "../course-search-default/index";
//课程推荐
import { CourseRecommendListPage } from "../course-recommend/list";
import { AddCourseRecommendListPage } from "../course-recommend/add-list";
//精品课程
import { CourseBoutiqueListPage } from "../course-boutique/list";
import { AddCourseBoutiqueListPage } from "../course-boutique/add-list";
//课程橱窗
import { CourseShowcaseListPage } from "../course-showcase/list";
import { CourseShowcaseAddPage } from "../course-showcase/add";
import { CourseShowcaseUpdatePage } from "../course-showcase/update";
import { AddCourseShowcaseCourseListPage } from "../course-showcase/add-list";
import { CourseShowcaseCourseListPage } from "../course-showcase/course-list";
//机构标签
import { AgencyTagListPage } from "../agency-tag/list";
import { AgencyTagAddPage } from "../agency-tag/add";
import { AgencyTagUpdatePage } from "../agency-tag/update";
//达人
import { SalesmanListPage } from "../salesman/list";
import { SalesmanAuthListPage } from "../salesman-auth/list";
//规划师
import { PlannerListPage } from "../planner/list";
import { PlannerDetailPage } from "../planner/detail";
//规划师评价
import { PlannerEvaListPage } from "../planner-eva/list";
//规划师评价快捷标签
import { PlannerEvaEasilyTagListPage } from "../planner-eva-easily-tag/list";
import { PlannerEvaEasilyTagAddPage } from "../planner-eva-easily-tag/add";
import { PlannerEvaEasilyTagUpdatePage } from "../planner-eva-easily-tag/update";
//商家
import { StoreListPage } from "../store/list";
// import { StoreAddPage } from "../store/add";
import { StoreUpdatePage } from "../store/update";
import { StoreAuthListPage } from "../store-auth/list";
//商家性质
import { StoreAttributeListPage } from "../store-attribute/list";
import { StoreAttributeAddPage } from "../store-attribute/add";
import { StoreAttributeUpdatePage } from "../store-attribute/update";
//商家类型
import { StoreTypeListPage } from "../store-type/list";
import { StoreTypeAddPage } from "../store-type/add";
import { StoreTypeUpdatePage } from "../store-type/update";
//代理商
import { AgentListPage } from "../agent/list";
import { AgentDetailPage } from "../agent/detail";
import { AgentMoneyLogListPage } from "../agent-money-log/list";
//代理商提现
import { AgentWithdrawCashListPage } from "../agent-withdraw-cash/list";
import { AgentWithdrawCashAddPage } from "../agent-withdraw-cash/add";
//套餐
import { ComboListPage } from "../combo/list";
import { ComboAddPage } from "../combo/add";
import { ComboUpdatePage } from "../combo/update";
//单项
import { BenefitListPage } from "../benefit/list";
import { BenefitAddPage } from "../benefit/add";
import { BenefitUpdatePage } from "../benefit/update";
//用户
import { MemberListPage } from "../member/list";
import { MemberPointsLogListPage } from "../member-points-log/list";
import { MoneyLogListPage } from "../money-log/list";
import { MemberBrowseListPage } from "../member-browse/list";
import { MemberCollectListPage } from "../member-collect/list";
//用户标签
import { MemberTagListPage } from "../member-tag/list";
import { MemberTagAddPage } from "../member-tag/add";
import { MemberTagUpdatePage } from "../member-tag/update";
//投诉建议
import { FeedbackListPage } from "../feedback/list";
//建议类型
import { FeedbackClassListPage } from "../feedback-class/list";
import { FeedbackClassAddPage } from "../feedback-class/add";
import { FeedbackClassUpdatePage } from "../feedback-class/update";
//举报
import { ReportListPage } from "../report/list";
//举报类型
import { ReportClassListPage } from "../report-class/list";
import { ReportClassAddPage } from "../report-class/add";
import { ReportClassUpdatePage } from "../report-class/update";
//对账单
import { BillListPage } from "../bill/list";
//对账单明细
import { BillDetailListPage } from "../bill-detail/list";
//提现
import { WithdrawListPage } from "../withdraw/list";
//银行
import { BankListPage } from "../bank/list";
import { BankAddPage } from "../bank/add";
import { BankUpdatePage } from "../bank/update";
//银行账号
import { BankAccountListPage } from "../bank-account/list";
import { BankAccountAddPage } from "../bank-account/add";
import { BankAccountUpdatePage } from "../bank-account/update";

//提现设置
import { WithdrawSettingIndexPage } from "../withdraw-setting/index";
//通用设置
import { SystemSettingIndexPage } from "../system-setting/index";
//认证协议
import { AuthTreatyIndexPage } from "../auth-treaty";
//账号
import { AdminAccountListPage } from "../admin-account/list";
import { AdminAccountAddPage } from "../admin-account/add";
import { AdminAccountUpdatePage } from "../admin-account/update";
//模板消息
import { TemplateMessageListPage } from "../template-message/list";
import { TemplateMessageUpdatePage } from "../template-message/update";
//公众号菜单
import { WechatMenuListPage } from "../wechat-menu/list";
import { WechatMenuAddPage } from "../wechat-menu/add";
import { WechatMenuUpdatePage } from "../wechat-menu/update";
//修改密码
import { AdminAccountUpdatePwdPage } from "../admin-account/update-pwd";
//角色
import { AdminRoleListPage } from "../admin-role/list";
import { AdminRoleAddPage } from "../admin-role/add";
import { AdminRoleUpdatePage } from "../admin-role/update";

//商城
import { GoodsListPage } from "../goods/list";
import { GoodsAddPage } from "../goods/add";
import { GoodsUpdatePage } from "../goods/update";
import { GoodsClassListPage } from "../goods-class/list";
import { GoodsClassAddPage } from "../goods-class/add";
import { GoodsClassUpdatePage } from "../goods-class/update";
import { GoodsTagListPage } from "../goods-tag/list";
import { GoodsTagAddPage } from "../goods-tag/add";
import { GoodsTagUpdatePage } from "../goods-tag/update";
import { GoodsServeTagListPage } from "../goods-serve-tag/list";
import { GoodsServeTagAddPage } from "../goods-serve-tag/add";
import { GoodsServeTagUpdatePage } from "../goods-serve-tag/update";
import { GoodsSpecListPage } from "../goods-spec/list";
import { GoodsSpecAddPage } from "../goods-spec/add";
import { GoodsSpecUpdatePage } from "../goods-spec/update";
import { GoodsSpecValueListPage } from "../goods-spec-value/list";
import { GoodsSpecValueAddPage } from "../goods-spec-value/add";
import { GoodsSpecValueUpdatePage } from "../goods-spec-value/update";
import { GoodsRecommedListPage } from "../goods/listRecommed";
import { GoodsEvaluateListPage } from "../goods-evaluate/list";
import { OrdersListPage } from "../orders/list";
import { OrdersDetailPage } from "../orders/detail";
import { OrdersVerificationPage } from "../orders/verification";
import { OrdersBatchDeliveryListPage } from "../orders-batch-delivery/list";
import { OrdersBatchDeliveryItemListPage } from "../orders-batch-delivery-item/list";
import { OrdersRefundListPage } from "../orders-refund/list";
import { OrdersRefundDetailPage } from "../orders-refund/detail";
import { OrdersSettingIndexPage } from "../orders-setting/index";

import { StoreAccountListPage } from "../store-account/list";
import { StoreAccountAddPage } from "../store-account/add";
import { StoreClassListPage } from "../store-class/list";
import { StoreClassAddPage } from "../store-class/add";
import { StoreClassUpdatePage } from "../store-class/update";
import { StoreBrandListPage } from "../store-brand/list";
import { StoreBrandAddPage } from "../store-brand/add";
import { StoreBrandUpdatePage } from "../store-brand/update";

import { BusinessCircleListPage } from "../business-circle/list";
import { BusinessCircleAddPage } from "../business-circle/add";
import { BusinessCircleUpdatePage } from "../business-circle/update";
import { BusinessCircleStoreListPage } from "../business-circle/list-store";
import { BusinessCircleStoreAddPage } from "../business-circle/add-store";
import { BusinessCircleTagListPage } from "../business-circle-tag/list";
import { BusinessCircleTagAddPage } from "../business-circle-tag/add";
import { BusinessCircleTagUpdatePage } from "../business-circle-tag/update";
import { CouponListPage } from "../coupon/list";
import { CouponUpdatePage } from "../coupon/update";
import { CouponGoodsListPage } from "../coupon-goods/list";
import { MemberCouponListPage } from "../member-coupon/list";
import { MemberCouponListUsePage } from "../member-coupon/list-use";
import { MemberCouponListNoUsePage } from "../member-coupon/list-no-use";
import { GoodsSettingIndexPage } from "../goods-setting/index";
import { GrouponListPage } from "../groupon/list";
import { GrouponUpdatePage } from "../groupon/update";

import { GrouponAdvListPage } from "../groupon-adv/list";
import { GrouponAdvUpdatePage } from "../groupon-adv/update";

import { SeckillListPage } from "../seckill/list";
import { SeckillUpdatePage } from "../seckill/update";

import { PointsAdvListPage } from "../points-adv/list";
import { PointsAdvAddPage } from "../points-adv/add";
import { PointsAdvUpdatePage } from "../points-adv/update";
import { PointsGoodsClassListPage } from "../points-goods-class/list";
import { PointsGoodsClassAddPage } from "../points-goods-class/add";
import { PointsGoodsClassUpdatePage } from "../points-goods-class/update";
import { PointsGoodsListPage } from "../points-goods/list";
import { PointsGoodsAddPage } from "../points-goods/add";
import { PointsGoodsUpdatePage } from "../points-goods/update";
//积分任务
import { PointsTaskListPage } from "../points-task/list";
import { PointsTaskAddPage } from "../points-task/add";
import { PointsTaskUpdatePage } from "../points-task/update";

import { MallAdvListPage } from "../mall-adv/list";
import { MallAdvAddPage } from "../mall-adv/add";
import { MallAdvUpdatePage } from "../mall-adv/update";
import { MallNavigationListPage } from "../mall-navigation/list";
import { MallNavigationAddPage } from "../mall-navigation/add";
import { MallNavigationUpdatePage } from "../mall-navigation/update";
import { MallMenuListPage } from "../mall-menu/list";
import { MallMenuAddPage } from "../mall-menu/add";
import { MallMenuUpdatePage } from "../mall-menu/update";
import { MallSubjectListPage } from "../mall-subject/list";
import { MallSubjectAddPage } from "../mall-subject/add";
import { MallSubjectUpdatePage } from "../mall-subject/update";
import { MallFloorListPage } from "../mall-floor/list";
import { MallFloorAddPage } from "../mall-floor/add";
import { MallFloorUpdatePage } from "../mall-floor/update";
import { MallFloorGoodsListPage } from "../mall-floor-goods/list";
import { MallFloorGoodsListNoPage } from "../mall-floor-goods/list-no";
import { MallShowcaseListPage } from "../mall-showcase/list";
import { MallShowcaseAddPage } from "../mall-showcase/add";
import { MallShowcaseUpdatePage } from "../mall-showcase/update";
import { MallShowcaseGoodsListPage } from "../mall-showcase-goods/list";
import { MallShowcaseGoodsListNoPage } from "../mall-showcase-goods/list-no";
import { Route, Routes, Navigate } from "react-router";
import { HashRouter as Router } from "react-router-dom";
import { AgencyListNoVerPage } from "../agency/list-no-ver";
import { AgencyUpdatePage } from "../agency/update";
const { Content } = Layout;

export const Authenticated = () => {
  return (
    <Layout>
      <Router>
        <SiderLayout />
        <Layout className="site-layout">
          <HeaderLayout />
          <Content
            style={{
              margin: "24px 16px 0",
              padding: 24,
              minHeight: "calc(100vh - 157px)",
              overflow: "initial",
              background: "#fff",
            }}
          >
            <Routes>
              {/* 概况 */}
              <Route path={"/dashboard"} element={<DashboardPage />} />
              {/* 引导页 */}
              <Route
                path={"/dress/decorate-manage/guide/list"}
                element={<GuideListPage />}
              />
              <Route
                path={"/dress/decorate-manage/guide/add"}
                element={<GuideAddPage />}
              />
              <Route
                path={"/dress/decorate-manage/guide/update/:guideId"}
                element={<GuideUpdatePage />}
              />
              {/* 开屏广告 */}
              <Route
                path={"/dress/decorate-manage/bomb-window/list"}
                element={<BombWindowListPage />}
              />
              <Route
                path={"/dress/decorate-manage/bomb-window/add"}
                element={<BombWindowAddPage />}
              />
              <Route
                path={"/dress/decorate-manage/bomb-window/update/:bombWindowId"}
                element={<BombWindowUpdatePage />}
              />
              {/* 标签栏 */}
              <Route
                path={"/dress/decorate-manage/tab-bar/list"}
                element={<TabBarListPage />}
              />
              <Route
                path={"/dress/decorate-manage/tab-bar/add"}
                element={<TabBarAddPage />}
              />
              <Route
                path={"/dress/decorate-manage/tab-bar/update/:tabBarId"}
                element={<TabBarUpdatePage />}
              />
              {/* 标签栏广告 */}
              <Route
                path={"/dress/decorate-manage/tab-bar/adv/list"}
                element={<TabBarAdvListPage />}
              />
              <Route
                path={"/dress/decorate-manage/tab-bar/adv/add"}
                element={<TabBarAdvAddPage />}
              />
              <Route
                path={"/dress/decorate-manage/tab-bar/adv/update/:tabBarAdvId"}
                element={<TabBarAdvUpdatePage />}
              />
              {/* 广告 */}
              <Route
                path={"/dress/decorate-manage/adv/list"}
                element={<AdvListPage />}
              />
              <Route
                path={"/dress/decorate-manage/adv/add"}
                element={<AdvAddPage />}
              />
              <Route
                path={"/dress/decorate-manage/adv/update/:advId"}
                element={<AdvUpdatePage />}
              />
              {/* 导航 */}
              <Route
                path={"/dress/decorate-manage/navigation/list"}
                element={<NavigationListPage />}
              />
              <Route
                path={"/dress/decorate-manage/navigation/add"}
                element={<NavigationAddPage />}
              />
              <Route
                path={"/dress/decorate-manage/navigation/update/:navigationId"}
                element={<NavigationUpdatePage />}
              />
              {/* 菜单 */}
              <Route
                path={"/dress/decorate-manage/menu/list"}
                element={<MenuListPage />}
              />
              <Route
                path={"/dress/decorate-manage/menu/add"}
                element={<MenuAddPage />}
              />
              <Route
                path={"/dress/decorate-manage/menu/update/:menuId"}
                element={<MenuUpdatePage />}
              />
              {/* 工具 */}
              <Route
                path={"/dress/decorate-manage/person-menu/list"}
                element={<PersonMenuListPage />}
              />
              <Route
                path={"/dress/decorate-manage/person-menu/add"}
                element={<PersonMenuAddPage />}
              />
              <Route
                path={"/dress/decorate-manage/person-menu/update/:personMenuId"}
                element={<PersonMenuUpdatePage />}
              />
              {/* 公告 */}
              <Route
                path={"/dress/notice-manage/notice/list"}
                element={<NoticeListPage />}
              />
              <Route
                path={"/dress/notice-manage/notice/add"}
                element={<NoticeAddPage />}
              />
              <Route
                path={"/dress/notice-manage/notice/update/:noticeId"}
                element={<NoticeUpdatePage />}
              />
              {/* 热门搜索 */}
              <Route
                path={"/dress/search-manage/search/hot/list"}
                element={<SearchHotListPage />}
              />
              <Route
                path={"/dress/search-manage/search/hot/add"}
                element={<SearchHotAddPage />}
              />
              <Route
                path={"/dress/search-manage/search/hot/update/:searchHotId"}
                element={<SearchHotUpdatePage />}
              />
              {/* 默认搜索 */}
              <Route
                path={"/dress/search-manage/search/default"}
                element={<SearchDefaultIndexPage />}
              />
              {/* 城市 */}
              <Route
                path={"/dress/area-manage/area/list"}
                element={<AreaListPage />}
              />
              <Route
                path={"/dress/area-manage/area/add"}
                element={<AreaAddPage />}
              />
              <Route
                path={"/dress/area-manage/area/update/:areaId"}
                element={<AreaUpdatePage />}
              />
              {/* 动态 */}
              <Route
                path={"/dress/trends-manage/trends/list"}
                element={<TrendsListPage />}
              />
              <Route
                path={"/dress/trends-manage/trends/trends-comment/list"}
                element={<TrendsCommentListPage />}
              />
              <Route
                path={"/dress/trends-manage/trends/member-browse/list"}
                element={<MemberBrowseListPage />}
              />
              <Route
                path={"/dress/trends-manage/trends/member-collect/list"}
                element={<MemberCollectListPage />}
              />
              {/* 动态标签 */}
              <Route
                path={"/dress/trends-manage/trends-tag/list"}
                element={<TrendsTagListPage />}
              />
              <Route
                path={"/dress/trends-manage/trends-tag/add"}
                element={<TrendsTagAddPage />}
              />
              <Route
                path={"/dress/trends-manage/trends-tag/update/:trendsTagId"}
                element={<TrendsTagUpdatePage />}
              />
              {/* 学校广告 */}
              <Route
                path={"/dress/trends-manage/trends-tag/school-adv/list"}
                element={<SchoolAdvListPage />}
              />
              <Route
                path={"/dress/trends-manage/trends-tag/school-adv/add"}
                element={<SchoolAdvAddPage />}
              />
              <Route
                path={
                  "/dress/trends-manage/trends-tag/school-adv/update/:schoolAdvId"
                }
                element={<SchoolAdvUpdatePage />}
              />
              {/* 学校导航 */}
              <Route
                path={"/dress/trends-manage/trends-tag/school-navigation/list"}
                element={<SchoolNavigationListPage />}
              />
              <Route
                path={"/dress/trends-manage/trends-tag/school-navigation/add"}
                element={<SchoolNavigationAddPage />}
              />
              <Route
                path={
                  "/dress/trends-manage/trends-tag/school-navigation/update/:schoolNavigationId"
                }
                element={<SchoolNavigationUpdatePage />}
              />
              {/* 学校头条 */}
              <Route
                path={"/dress/trends-manage/trends-tag/school-notice/list"}
                element={<SchoolNoticeListPage />}
              />
              <Route
                path={"/dress/trends-manage/trends-tag/school-notice/add"}
                element={<SchoolNoticeAddPage />}
              />
              <Route
                path={
                  "/dress/trends-manage/trends-tag/school-notice/update/:schoolNoticeId"
                }
                element={<SchoolNoticeUpdatePage />}
              />
              {/* 学校 */}
              <Route
                path={"/xuexiao/school-manage/school/list"}
                element={<SchoolListPage />}
              />
              <Route
                path={"/xuexiao/school-manage/school-auth/list"}
                element={<SchoolAuthListPage />}
              />
              <Route
                path={"/xuexiao/school-manage/school/add"}
                element={<SchoolAddPage />}
              />
              <Route
                path={"/xuexiao/school-manage/school/update/:schoolId"}
                element={<SchoolUpdatePage />}
              />
              {/* 学校层次 */}
              <Route
                path={"/xuexiao/school-manage/school-level/list"}
                element={<SchoolLevelListPage />}
              />
              <Route
                path={"/xuexiao/school-manage/school-level/add"}
                element={<SchoolLevelAddPage />}
              />
              <Route
                path={
                  "/xuexiao/school-manage/school-level/update/:schoolLevelId"
                }
                element={<SchoolLevelUpdatePage />}
              />
              {/* 预约探校 */}
              <Route
                path={"/xuexiao/school-manage/school-appoint/list"}
                element={<SchoolAppointListPage />}
              />
              {/* 学校类型 */}
              <Route
                path={"/xuexiao/school-manage/school-type/list"}
                element={<SchoolTypeListPage />}
              />
              <Route
                path={"/xuexiao/school-manage/school-type/add"}
                element={<SchoolTypeAddPage />}
              />
              <Route
                path={"/xuexiao/school-manage/school-type/update/:schoolTypeId"}
                element={<SchoolTypeUpdatePage />}
              />
              {/* 学校专题 */}
              <Route
                path={"/xuexiao/school-manage/school-subject/list"}
                element={<SchoolSubjectListPage />}
              />
              <Route
                path={"/xuexiao/school-manage/school-subject/add"}
                element={<SchoolSubjectAddPage />}
              />
              <Route
                path={
                  "/xuexiao/school-manage/school-subject/update/:schoolSubjectId"
                }
                element={<SchoolSubjectUpdatePage />}
              />
              {/* 学校资讯 */}
              <Route
                path={"/xuexiao/school-manage/school-article/list"}
                element={<SchoolArticleListPage />}
              />
              <Route
                path={"/xuexiao/school-manage/school-article/add"}
                element={<SchoolArticleAddPage />}
              />
              <Route
                path={
                  "/xuexiao/school-manage/school-article/update/:schoolArticleId"
                }
                element={<SchoolArticleUpdatePage />}
              />
              {/* 专业 */}
              <Route
                path={"/xuexiao/major-manage/major/list"}
                element={<MajorListPage />}
              />
              <Route
                path={"/xuexiao/major-manage/major/add"}
                element={<MajorAddPage />}
              />
              <Route
                path={"/xuexiao/major-manage/major/update/:majorId"}
                element={<MajorUpdatePage />}
              />
              {/* 专业分类 */}
              <Route
                path={"/xuexiao/major-manage/major-class/list"}
                element={<MajorClassListPage />}
              />
              <Route
                path={"/xuexiao/major-manage/major-class/add"}
                element={<MajorClassAddPage />}
              />
              <Route
                path={"/xuexiao/major-manage/major-class/update/:majorClassId"}
                element={<MajorClassUpdatePage />}
              />
              {/* 学费订单 */}
              <Route
                path={"/xuexiao/school-orders-manage/school-orders/list"}
                element={<SchoolOrdersListPage />}
              />
              {/* 学费售后 */}
              <Route
                path={"/xuexiao/school-orders-manage/school-orders-refund/list"}
                element={<SchoolOrdersRefundListPage />}
              />
              {/* 学费设置 */}
              <Route
                path={"/xuexiao/school-orders-manage/school-orders-setting"}
                element={<SchoolOrdersSettingIndexPage />}
              />
              {/* 企业 */}
              <Route
                path={"/qiye/company-manage/company/list"}
                element={<CompanyListPage />}
              />
              <Route
                path={"/qiye/company-manage/company/update/:companyId"}
                element={<CompanyUpdatePage />}
              />
              <Route
                path={"/qiye/company-manage/company-auth/list"}
                element={<CompanyAuthListPage />}
              />
              {/* HR */}
              <Route
                path={"/qiye/company-manage/hr/list"}
                element={<HrListPage />}
              />
              {/* 企业性质 */}
              <Route
                path={"/qiye/company-manage/company-attribute/list"}
                element={<CompanyAttributeListPage />}
              />
              <Route
                path={"/qiye/company-manage/company-attribute/add"}
                element={<CompanyAttributeAddPage />}
              />
              <Route
                path={
                  "/qiye/company-manage/company-attribute/update/:companyAttributeId"
                }
                element={<CompanyAttributeUpdatePage />}
              />
              {/* 企业类型 */}
              <Route
                path={"/qiye/company-manage/company-type/list"}
                element={<CompanyTypeListPage />}
              />
              <Route
                path={"/qiye/company-manage/company-type/add"}
                element={<CompanyTypeAddPage />}
              />
              <Route
                path={"/qiye/company-manage/company-type/update/:companyTypeId"}
                element={<CompanyTypeUpdatePage />}
              />
              {/* 企业福利 */}
              <Route
                path={"/qiye/company-manage/company-tag/list"}
                element={<CompanyTagListPage />}
              />
              <Route
                path={"/qiye/company-manage/company-tag/add"}
                element={<CompanyTagAddPage />}
              />
              <Route
                path={"/qiye/company-manage/company-tag/update/:companyTagId"}
                element={<CompanyTagUpdatePage />}
              />
              {/* 企业规模 */}
              <Route
                path={"/qiye/company-manage/company-scale/list"}
                element={<CompanyScaleListPage />}
              />
              <Route
                path={"/qiye/company-manage/company-scale/add"}
                element={<CompanyScaleAddPage />}
              />
              <Route
                path={
                  "/qiye/company-manage/company-scale/update/:companyScaleId"
                }
                element={<CompanyScaleUpdatePage />}
              />
              {/* 招聘 */}
              <Route
                path={"/qiye/recruit-manage/recruit/list"}
                element={<RecruitListPage />}
              />
              <Route
                path={"/qiye/recruit-manage/recruit/member-browse/list"}
                element={<MemberBrowseListPage />}
              />
              <Route
                path={"/qiye/recruit-manage/recruit/member-collect/list"}
                element={<MemberCollectListPage />}
              />
              <Route
                path={"/qiye/recruit-manage/recruit/recruit-resume/list"}
                element={<RecruitResumeListPage />}
              />
              <Route
                path={
                  "/qiye/recruit-manage/recruit/recruit-resume/detail/:recruitResumeId"
                }
                element={<RecruitResumeDetailPage />}
              />
              {/* 招聘广告 */}
              <Route
                path={"/qiye/recruit-manage/recruit-adv/list"}
                element={<RecruitAdvListPage />}
              />
              <Route
                path={"/qiye/recruit-manage/recruit-adv/add"}
                element={<RecruitAdvAddPage />}
              />
              <Route
                path={"/qiye/recruit-manage/recruit-adv/update/:recruitAdvId"}
                element={<RecruitAdvUpdatePage />}
              />
              {/* 招聘导航 */}
              <Route
                path={"/qiye/recruit-manage/recruit-navigation/list"}
                element={<RecruitNavigationListPage />}
              />
              <Route
                path={"/qiye/recruit-manage/recruit-navigation/add"}
                element={<RecruitNavigationAddPage />}
              />
              <Route
                path={
                  "/qiye/recruit-manage/recruit-navigation/update/:recruitNavigationId"
                }
                element={<RecruitNavigationUpdatePage />}
              />
              {/* 职位 */}
              <Route
                path={"/qiye/recruit-manage/company-position/list"}
                element={<CompanyPositionListPage />}
              />
              <Route
                path={"/qiye/recruit-manage/company-position/add"}
                element={<CompanyPositionAddPage />}
              />
              <Route
                path={
                  "/qiye/recruit-manage/company-position/update/:companyPositionId"
                }
                element={<CompanyPositionUpdatePage />}
              />
              {/* 职位分类 */}
              <Route
                path={"/qiye/recruit-manage/company-position-class/list"}
                element={<CompanyPositionClassListPage />}
              />
              <Route
                path={"/qiye/recruit-manage/company-position-class/add"}
                element={<CompanyPositionClassAddPage />}
              />
              <Route
                path={
                  "/qiye/recruit-manage/company-position-class/update/:companyPositionClassId"
                }
                element={<CompanyPositionClassUpdatePage />}
              />
              {/* 经验要求 */}
              <Route
                path={"/qiye/recruit-manage/recruit-experience/list"}
                element={<RecruitExperienceListPage />}
              />
              <Route
                path={"/qiye/recruit-manage/recruit-experience/add"}
                element={<RecruitExperienceAddPage />}
              />
              <Route
                path={
                  "/qiye/recruit-manage/recruit-experience/update/:recruitExperienceId"
                }
                element={<RecruitExperienceUpdatePage />}
              />
              {/* 学历要求 */}
              <Route
                path={"/qiye/recruit-manage/recruit-education/list"}
                element={<RecruitEducationListPage />}
              />
              <Route
                path={"/qiye/recruit-manage/recruit-education/add"}
                element={<RecruitEducationAddPage />}
              />
              <Route
                path={
                  "/qiye/recruit-manage/recruit-education/update/:recruitEducationId"
                }
                element={<RecruitEducationUpdatePage />}
              />
              {/* 简历学历 */}
              <Route
                path={"/qiye/recruit-manage/resume-education/list"}
                element={<ResumeEducationListPage />}
              />
              <Route
                path={"/qiye/recruit-manage/resume-education/add"}
                element={<ResumeEducationAddPage />}
              />
              <Route
                path={
                  "/qiye/recruit-manage/resume-education/update/:resumeEducationId"
                }
                element={<ResumeEducationUpdatePage />}
              />
              {/* 机构 */}
              <Route
                path={"/jigou/agency-manage/agency/list"}
                element={<AgencyListPage />}
              />
              <Route
                path={"/jigou/agency-manage/agency-no-ver/list"}
                element={<AgencyListNoVerPage />}
              />
              <Route
                path={"/jigou/agency-manage/agency/update/:agencyId"}
                element={<AgencyUpdatePage />}
              />
              <Route
                path={"/jigou/agency-manage/agency/detail"}
                element={<AgencyDetailPage />}
              />
              {/* 热门机构 */}
              <Route
                path={"/jigou/agency-manage/agency-hot/list"}
                element={<AgencyHotListPage />}
              />
              {/* 课程 */}
              <Route
                path={"/jigou/course-manage/course/list"}
                element={<CourseListPage />}
              />
              <Route
                path={"/jigou/course-manage/course/detail/:courseId"}
                element={<CourseDetailPage />}
              />
              {/* 课程分类 */}
              <Route
                path={"/jigou/course-manage/course-class/list"}
                element={<CourseClassListPage />}
              />
              <Route
                path={"/jigou/course-manage/course-class/add"}
                element={<CourseClassAddPage />}
              />
              <Route
                path={"/jigou/course-manage/course-class/update/:courseClassId"}
                element={<CourseClassUpdatePage />}
              />
              {/* 课程广告 */}
              <Route
                path={"/jigou/course-manage/course-adv/list"}
                element={<CourseAdvListPage />}
              />
              <Route
                path={"/jigou/course-manage/course-adv/add"}
                element={<CourseAdvAddPage />}
              />
              <Route
                path={"/jigou/course-manage/course-adv/update/:courseAdvId"}
                element={<CourseAdvUpdatePage />}
              />
              {/* 课程导航 */}
              <Route
                path={"/jigou/course-manage/course-navigation/list"}
                element={<CourseNavigationListPage />}
              />
              <Route
                path={"/jigou/course-manage/course-navigation/add"}
                element={<CourseNavigationAddPage />}
              />
              <Route
                path={
                  "/jigou/course-manage/course-navigation/update/:courseNavigationId"
                }
                element={<CourseNavigationUpdatePage />}
              />
              {/* 课程公告 */}
              <Route
                path={"/jigou/course-manage/course-notice/list"}
                element={<CourseNoticeListPage />}
              />
              <Route
                path={"/jigou/course-manage/course-notice/add"}
                element={<CourseNoticeAddPage />}
              />
              <Route
                path={
                  "/jigou/course-manage/course-notice/update/:courseNoticeId"
                }
                element={<CourseNoticeUpdatePage />}
              />
              {/* 课程热门搜索 */}
              <Route
                path={"/jigou/course-manage/course-search/hot/list"}
                element={<CourseSearchHotListPage />}
              />
              <Route
                path={"/jigou/course-manage/course-search/hot/add"}
                element={<CourseSearchHotAddPage />}
              />
              <Route
                path={
                  "/jigou/course-manage/course-search/hot/update/:courseSearchHotId"
                }
                element={<CourseSearchHotUpdatePage />}
              />
              {/* 课程默认搜索 */}
              <Route
                path={"/jigou/course-manage/course-search/default/index"}
                element={<CourseSearchDefaultIndexPage />}
              />
              {/* 课程推荐 */}
              <Route
                path={"/jigou/course-manage/course-recommend/list"}
                element={<CourseRecommendListPage />}
              />
              <Route
                path={"/jigou/course-manage/course-recommend/add-list"}
                element={<AddCourseRecommendListPage />}
              />
              {/* 精品课程 */}
              <Route
                path={"/jigou/course-manage/course-boutique/list"}
                element={<CourseBoutiqueListPage />}
              />
              <Route
                path={"/jigou/course-manage/course-boutique/add-list"}
                element={<AddCourseBoutiqueListPage />}
              />
              {/* 课程橱窗 */}
              <Route
                path={"/jigou/course-manage/course-showcase/list"}
                element={<CourseShowcaseListPage />}
              />
              <Route
                path={"/jigou/course-manage/course-showcase/add"}
                element={<CourseShowcaseAddPage />}
              />
              <Route
                path={
                  "/jigou/course-manage/course-showcase/update/:courseShowcaseId"
                }
                element={<CourseShowcaseUpdatePage />}
              />
              <Route
                path={"/jigou/course-manage/course-showcase/add-list"}
                element={<AddCourseShowcaseCourseListPage />}
              />
              <Route
                path={"/jigou/course-manage/course-showcase/course-list"}
                element={<CourseShowcaseCourseListPage />}
              />
              {/* 机构标签 */}
              <Route
                path={"/jigou/agency-manage/agency-tag/list"}
                element={<AgencyTagListPage />}
              />
              <Route
                path={"/jigou/agency-manage/agency-tag/add"}
                element={<AgencyTagAddPage />}
              />
              <Route
                path={"/jigou/agency-manage/agency-tag/update/:agencyTagId"}
                element={<AgencyTagUpdatePage />}
              />
              {/* 达人 */}
              <Route
                path={"/daren/salesman-manage/salesman/list"}
                element={<SalesmanListPage />}
              />
              <Route
                path={"/daren/salesman-manage/salesman-auth/list"}
                element={<SalesmanAuthListPage />}
              />
              {/* 规划师评价快捷标签 */}
              <Route
                path={"/jigou/planner-manage/planner-eva-easily-tag/list"}
                element={<PlannerEvaEasilyTagListPage />}
              />
              <Route
                path={"/jigou/planner-manage/planner-eva-easily-tag/add"}
                element={<PlannerEvaEasilyTagAddPage />}
              />
              <Route
                path={
                  "/jigou/planner-manage/planner-eva-easily-tag/update/:plannerEvaEasilyTagId"
                }
                element={<PlannerEvaEasilyTagUpdatePage />}
              />
              {/* 规划师评价 */}
              <Route
                path={"/jigou/planner-manage/planner-eva/list"}
                element={<PlannerEvaListPage />}
              />
              {/* 规划师 */}
              <Route
                path={"/jigou/planner-manage/planner/list"}
                element={<PlannerListPage />}
              />
              <Route
                path={"/jigou/planner-manage/planner/detail"}
                element={<PlannerDetailPage />}
              />
              {/* 商家 */}
              <Route
                path={"/shop/store-manage/store/list"}
                element={<StoreListPage />}
              />
              <Route
                path={"/shop/store-manage/store/update/:storeId"}
                element={<StoreUpdatePage />}
              />
              <Route
                path={"/shop/store-manage/store-auth/list"}
                element={<StoreAuthListPage />}
              />

              <Route
                path={"/shop/store-manage/storeAccount/list/:storeId"}
                element={<StoreAccountListPage />}
              />
              <Route
                path={"/shop/store-manage/storeAccount/add/:storeId"}
                element={<StoreAccountAddPage />}
              />
              {/* 商家性质 */}
              <Route
                path={"/shop/store-manage/store-attribute/list"}
                element={<StoreAttributeListPage />}
              />
              <Route
                path={"/shop/store-manage/store-attribute/add"}
                element={<StoreAttributeAddPage />}
              />
              <Route
                path={
                  "/shop/store-manage/store-attribute/update/:storeAttributeId"
                }
                element={<StoreAttributeUpdatePage />}
              />
              {/* 商家类型 */}
              <Route
                path={"/shop/store-manage/store-type/list"}
                element={<StoreTypeListPage />}
              />
              <Route
                path={"/shop/store-manage/store-type/add"}
                element={<StoreTypeAddPage />}
              />
              <Route
                path={"/shop/store-manage/store-type/update/:storeTypeId"}
                element={<StoreTypeUpdatePage />}
              />
              {/* 代理商 */}
              <Route
                path={"/agent/agent-manage/agent/list"}
                element={<AgentListPage />}
              />
              <Route
                path={"/agent/agent-manage/agent/detail"}
                element={<AgentDetailPage />}
              />
              {/* 代理商收支 */}
              <Route
                path={"/wallet/wallet-manage/agent-money/list"}
                element={<AgentMoneyLogListPage />}
              />
              {/* 银行账号,代理商用 */}
              <Route
                path={"/wallet/wallet-manage/agent-bank-account/list"}
                element={<BankAccountListPage />}
              />
              <Route
                path={"/wallet/wallet-manage/agent-bank-account/add"}
                element={<BankAccountAddPage />}
              />
              <Route
                path={
                  "/wallet/wallet-manage/agent-bank-account/update/:bankAccountId"
                }
                element={<BankAccountUpdatePage />}
              />
              {/* 代理商提现 */}
              <Route
                path={"/wallet/wallet-manage/agent-withdraw-cash/list"}
                element={<AgentWithdrawCashListPage />}
              />
              <Route
                path={"/wallet/wallet-manage/agent-withdraw-cash/add"}
                element={<AgentWithdrawCashAddPage />}
              />

              {/* 套餐 */}
              <Route
                path={"/taocan/combo-manage/combo/list"}
                element={<ComboListPage />}
              />
              <Route
                path={"/taocan/combo-manage/combo/add"}
                element={<ComboAddPage />}
              />
              <Route
                path={"/taocan/combo-manage/combo/update/:comboId"}
                element={<ComboUpdatePage />}
              />
              {/* 单项 */}
              <Route
                path={"/taocan/combo-manage/benefit/list"}
                element={<BenefitListPage />}
              />
              <Route
                path={"/taocan/combo-manage/benefit/add"}
                element={<BenefitAddPage />}
              />
              <Route
                path={"/taocan/combo-manage/benefit/update/:benefitId"}
                element={<BenefitUpdatePage />}
              />
              {/* 用户 */}
              <Route
                path={"/user/user-manage/member/list"}
                element={<MemberListPage />}
              />
              <Route
                path={"/user/user-manage/member/points/list"}
                element={<MemberPointsLogListPage />}
              />
              <Route
                path={"/user/user-manage/member/money/list"}
                element={<MoneyLogListPage />}
              />
              {/* 用户标签 */}
              <Route
                path={"/user/user-manage/member-tag/list"}
                element={<MemberTagListPage />}
              />
              <Route
                path={"/user/user-manage/member-tag/add"}
                element={<MemberTagAddPage />}
              />
              <Route
                path={"/user/user-manage/member-tag/update/:memberTagId"}
                element={<MemberTagUpdatePage />}
              />
              {/* 投诉建议 */}
              <Route
                path={"/user/feedback-manage/feedback/list"}
                element={<FeedbackListPage />}
              />
              {/* 建议类型 */}
              <Route
                path={"/user/feedback-manage/feedback-class/list"}
                element={<FeedbackClassListPage />}
              />
              <Route
                path={"/user/feedback-manage/feedback-class/add"}
                element={<FeedbackClassAddPage />}
              />
              <Route
                path={
                  "/user/feedback-manage/feedback-class/update/:feedbackClassId"
                }
                element={<FeedbackClassUpdatePage />}
              />
              {/* 举报 */}
              <Route
                path={"/user/report-manage/report/list"}
                element={<ReportListPage />}
              />
              {/* 举报类型 */}
              <Route
                path={"/user/report-manage/report-class/list"}
                element={<ReportClassListPage />}
              />
              <Route
                path={"/user/report-manage/report-class/add"}
                element={<ReportClassAddPage />}
              />
              <Route
                path={"/user/report-manage/report-class/update/:reportClassId"}
                element={<ReportClassUpdatePage />}
              />
              {/* 对账单 */}
              <Route
                path={"/money/bill-manage/bill/list"}
                element={<BillListPage />}
              />
              {/* 对账单明细 */}
              <Route
                path={"/money/bill-manage/bill-detail/list"}
                element={<BillDetailListPage />}
              />
              {/* 提现 */}
              <Route
                path={"/money/withdraw-manage/withdraw/list"}
                element={<WithdrawListPage />}
              />
              {/* 银行 */}
              <Route
                path={"/money/withdraw-manage/bank/list"}
                element={<BankListPage />}
              />
              <Route
                path={"/money/withdraw-manage/bank/add"}
                element={<BankAddPage />}
              />
              <Route
                path={"/money/withdraw-manage/bank/update/:bankId"}
                element={<BankUpdatePage />}
              />

              {/* 提现设置 */}
              <Route
                path={"/money/withdraw-manage/withdraw-setting"}
                element={<WithdrawSettingIndexPage />}
              />
              {/* 通用设置 */}
              <Route
                path={"/setting/all-manage/system-setting"}
                element={<SystemSettingIndexPage />}
              />
              {/* 订单设置 */}
              <Route
                path={"/setting/all-manage/orders-setting"}
                element={<OrdersSettingIndexPage />}
              />
              {/* 商品设置 */}
              <Route
                path={"/setting/all-manage/goods-setting"}
                element={<GoodsSettingIndexPage />}
              />
              {/* 认证协议 */}
              <Route
                path={"/setting/treaty-manage/auth-treaty"}
                element={<AuthTreatyIndexPage />}
              />
              {/* 账号 */}
              <Route
                path={"/setting/account-manage/admin-account/list"}
                element={<AdminAccountListPage />}
              />
              <Route
                path={"/setting/account-manage/admin-account/add"}
                element={<AdminAccountAddPage />}
              />
              <Route
                path={
                  "/setting/account-manage/admin-account/update/:adminAccountId"
                }
                element={<AdminAccountUpdatePage />}
              />
              {/* 模板消息 */}
              <Route
                path={"/setting/wechat-manage/template-message/list"}
                element={<TemplateMessageListPage />}
              />
              <Route
                path={
                  "/setting/wechat-manage/template-message/update/:templateMessageId"
                }
                element={<TemplateMessageUpdatePage />}
              />
              {/* 公众号菜单 */}
              <Route
                path={"/setting/wechat-manage/wechat-menu/list"}
                element={<WechatMenuListPage />}
              />
              <Route
                path={"/setting/wechat-manage/wechat-menu/add"}
                element={<WechatMenuAddPage />}
              />
              <Route
                path={"/setting/wechat-manage/wechat-menu/update/:wechatMenuId"}
                element={<WechatMenuUpdatePage />}
              />
              {/* 修改密码 */}
              <Route
                path={"/admin-account/update-pwd"}
                element={<AdminAccountUpdatePwdPage />}
              />
              {/* 角色 */}
              <Route
                path={"/setting/account-manage/admin-role/list"}
                element={<AdminRoleListPage />}
              />
              <Route
                path={"/setting/account-manage/admin-role/add"}
                element={<AdminRoleAddPage />}
              />
              <Route
                path={"/setting/account-manage/admin-role/update/:adminRoleId"}
                element={<AdminRoleUpdatePage />}
              />
              {/* 默认 */}
              <Route index element={<Navigate to={"/dashboard"} />} />

              {/* 商城 */}

              <Route
                path={"/shop/dress-manage/mall-adv/list"}
                element={<MallAdvListPage />}
              />
              <Route
                path={"/shop/dress-manage/mall-adv/add"}
                element={<MallAdvAddPage />}
              />

              <Route
                path={"/shop/dress-manage/mall-adv/update/:advId"}
                element={<MallAdvUpdatePage />}
              />
              <Route
                path={"/shop/market-manage/coupon/list"}
                element={<CouponListPage />}
              />
              <Route
                path={"/shop/market-manage/coupon/update/:couponId"}
                element={<CouponUpdatePage />}
              />
              <Route
                path={"/shop/market-manage/coupon/goods/list/:couponId"}
                element={<CouponGoodsListPage />}
              />
              <Route
                path={"/shop/market-manage/coupon/member/list/:couponId"}
                element={<MemberCouponListPage />}
              />
              <Route
                path={"/shop/market-manage/coupon/member/listUse/:couponId"}
                element={<MemberCouponListUsePage />}
              />
              <Route
                path={"/shop/market-manage/coupon/member/listNoUse/:couponId"}
                element={<MemberCouponListNoUsePage />}
              />

              <Route
                path={"/shop/market-manage/points/adv/list"}
                element={<PointsAdvListPage />}
              />
              <Route
                path={"/shop/market-manage/points/adv/add"}
                element={<PointsAdvAddPage />}
              />
              <Route
                path={"/shop/market-manage/points/adv/update/:pointsAdvId"}
                element={<PointsAdvUpdatePage />}
              />
              <Route
                path={"/shop/market-manage/points/goodsClass/list"}
                element={<PointsGoodsClassListPage />}
              />
              <Route
                path={"/shop/market-manage/points/goodsClass/add"}
                element={<PointsGoodsClassAddPage />}
              />
              <Route
                path={
                  "/shop/market-manage/points/goodsClass/update/:pointsGoodsClassId"
                }
                element={<PointsGoodsClassUpdatePage />}
              />
              <Route
                path={"/shop/market-manage/points/goods/list"}
                element={<PointsGoodsListPage />}
              />
              <Route
                path={"/shop/market-manage/points/goods/add"}
                element={<PointsGoodsAddPage />}
              />
              <Route
                path={"/shop/market-manage/points/goods/update/:pointsGoodsId"}
                element={<PointsGoodsUpdatePage />}
              />
              <Route
                path={"/shop/market-manage/seckill/list"}
                element={<SeckillListPage />}
              />
              <Route
                path={"/shop/market-manage/seckill/update/:seckillId"}
                element={<SeckillUpdatePage />}
              />

              <Route
                path={"/shop/market-manage/groupon/list"}
                element={<GrouponListPage />}
              />
              <Route
                path={"/shop/market-manage/groupon/update/:grouponId"}
                element={<GrouponUpdatePage />}
              />
              <Route
                path={"/shop/market-manage/groupon/adv/list"}
                element={<GrouponAdvListPage />}
              />
              <Route
                path={"/shop/market-manage/groupon/adv/update/:grouponAdvId"}
                element={<GrouponAdvUpdatePage />}
              />

              <Route
                path={"/application/points-manage/adv/list"}
                element={<PointsAdvListPage />}
              />
              <Route
                path={"/application/points-manage/adv/add"}
                element={<PointsAdvAddPage />}
              />
              <Route
                path={"/application/points-manage/adv/update/:pointsAdvId"}
                element={<PointsAdvUpdatePage />}
              />

              {/* 积分任务 */}
              <Route
                path={"/shop/market-manage/points-task/list"}
                element={<PointsTaskListPage />}
              />
              <Route
                path={"/shop/market-manage/points-task/add"}
                element={<PointsTaskAddPage />}
              />
              <Route
                path={"/shop/market-manage/points-task/update/:pointsTaskId"}
                element={<PointsTaskUpdatePage />}
              />

              <Route
                path={"/shop/dress-manage/mall-navigation/list"}
                element={<MallNavigationListPage />}
              />
              <Route
                path={"/shop/dress-manage/mall-navigation/add"}
                element={<MallNavigationAddPage />}
              />
              <Route
                path={"/shop/dress-manage/mall-navigation/update/:navigationId"}
                element={<MallNavigationUpdatePage />}
              />
              <Route
                path={"/shop/dress-manage/mall-subject/list"}
                element={<MallSubjectListPage />}
              />
              <Route
                path={"/shop/dress-manage/mall-subject/add"}
                element={<MallSubjectAddPage />}
              />
              <Route
                path={"/shop/dress-manage/mall-subject/update/:subjectId"}
                element={<MallSubjectUpdatePage />}
              />
              <Route
                path={"/shop/dress-manage/mall-floor/list"}
                element={<MallFloorListPage />}
              />
              <Route
                path={"/shop/dress-manage/mall-floor/add"}
                element={<MallFloorAddPage />}
              />
              <Route
                path={"/shop/dress-manage/mall-floor/update/:floorId"}
                element={<MallFloorUpdatePage />}
              />
              <Route
                path={"/shop/dress-manage/mall-floor/goods/list/:floorId"}
                element={<MallFloorGoodsListPage />}
              />
              <Route
                path={"/shop/dress-manage/mall-floor/goods/listNo/:floorId"}
                element={<MallFloorGoodsListNoPage />}
              />
              <Route
                path={"/shop/dress-manage/mall-showcase/list"}
                element={<MallShowcaseListPage />}
              />
              <Route
                path={"/shop/dress-manage/mall-showcase/add"}
                element={<MallShowcaseAddPage />}
              />
              <Route
                path={"/shop/dress-manage/mall-showcase/update/:showcaseId"}
                element={<MallShowcaseUpdatePage />}
              />
              <Route
                path={"/shop/dress-manage/mall-showcase/goods/list/:showcaseId"}
                element={<MallShowcaseGoodsListPage />}
              />
              <Route
                path={
                  "/shop/dress-manage/mall-showcase/goods/listNo/:showcaseId"
                }
                element={<MallShowcaseGoodsListNoPage />}
              />
              <Route
                path={"/shop/dress-manage/mall-menu/list"}
                element={<MallMenuListPage />}
              />
              <Route
                path={"/shop/dress-manage/mall-menu/add"}
                element={<MallMenuAddPage />}
              />
              <Route
                path={"/shop/dress-manage/mall-menu/update/:menuId"}
                element={<MallMenuUpdatePage />}
              />
              <Route
                path={"/shop/goods-manage/goods/list"}
                element={<GoodsListPage />}
              />
              <Route
                path={"/shop/goods-manage/goods/add"}
                element={<GoodsAddPage />}
              />
              <Route
                path={"/shop/goods-manage/goods/update/:goodsId"}
                element={<GoodsUpdatePage />}
              />
              <Route
                path={"/shop/goods-manage/goods-class/list"}
                element={<GoodsClassListPage />}
              />
              <Route
                path={"/shop/goods-manage/goods-class/add"}
                element={<GoodsClassAddPage />}
              />
              <Route
                path={"/shop/goods-manage/goods-class/update/:goodsClassId"}
                element={<GoodsClassUpdatePage />}
              />
              <Route
                path={"/shop/goods-manage/goods-tag/list"}
                element={<GoodsTagListPage />}
              />
              <Route
                path={"/shop/goods-manage/goods-tag/add"}
                element={<GoodsTagAddPage />}
              />
              <Route
                path={"/shop/goods-manage/goods-tag/update/:goodsTagId"}
                element={<GoodsTagUpdatePage />}
              />
              <Route
                path={"/goods/serveTag/list"}
                element={<GoodsServeTagListPage />}
              />
              <Route
                path={"/goods/serveTag/add"}
                element={<GoodsServeTagAddPage />}
              />
              <Route
                path={"/goods/serveTag/update/:goodsServeTagId"}
                element={<GoodsServeTagUpdatePage />}
              />
              <Route
                path={"/shop/goods-manage/goods-spec/list"}
                element={<GoodsSpecListPage />}
              />
              <Route
                path={"/shop/goods-manage/goods-spec/add"}
                element={<GoodsSpecAddPage />}
              />
              <Route
                path={"/shop/goods-manage/goods-spec/update/:goodsSpecId"}
                element={<GoodsSpecUpdatePage />}
              />
              <Route
                path={"/shop/goods-manage/goods-spec/value/list/:goodsSpecId"}
                element={<GoodsSpecValueListPage />}
              />
              <Route
                path={"/shop/goods-manage/goods-spec/value/add/:goodsSpecId"}
                element={<GoodsSpecValueAddPage />}
              />
              <Route
                path={
                  "/shop/goods-manage/goods-spec/value/update/:goodsSpecValueId"
                }
                element={<GoodsSpecValueUpdatePage />}
              />
              <Route
                path={"/goods/recommed/list"}
                element={<GoodsRecommedListPage />}
              />
              <Route
                path={"/shop/goods-manage/goods-evaluate/list"}
                element={<GoodsEvaluateListPage />}
              />
              <Route
                path={"/member/manage/list"}
                element={<MemberListPage />}
              />
              <Route
                path={"/shop/orders-manage/orders/list"}
                element={<OrdersListPage />}
              />
              <Route
                path={"/shop/orders-manage/orders/detail/:ordersId"}
                element={<OrdersDetailPage />}
              />
              <Route
                path={"/orders/verification"}
                element={<OrdersVerificationPage />}
              />

              <Route
                path={"/orders/batch/delivery"}
                element={<OrdersBatchDeliveryListPage />}
              />

              <Route
                path={"/orders/batch/delivery/item/:ordersBatchDeliveryId"}
                element={<OrdersBatchDeliveryItemListPage />}
              />

              <Route
                path={"/shop/orders-manage/orders-refund/list"}
                element={<OrdersRefundListPage />}
              />
              <Route
                path={
                  "/shop/orders-manage/orders-refund/detail/:ordersRefundId"
                }
                element={<OrdersRefundDetailPage />}
              />

              <Route
                path={"/shop/store-manage/manage/storeAccount/list/:storeId"}
                element={<StoreAccountListPage />}
              />
              <Route
                path={"/shop/store-manage/manage/storeAccount/add/:storeId"}
                element={<StoreAccountAddPage />}
              />
              <Route
                path={"/shop/store-manage/class/list"}
                element={<StoreClassListPage />}
              />
              <Route
                path={"/shop/store-manage/class/add"}
                element={<StoreClassAddPage />}
              />
              <Route
                path={"/shop/store-manage/class/update/:storeClassId"}
                element={<StoreClassUpdatePage />}
              />
              <Route
                path={"/shop/store-manage/brand/list"}
                element={<StoreBrandListPage />}
              />
              <Route
                path={"/shop/store-manage/brand/add"}
                element={<StoreBrandAddPage />}
              />
              <Route
                path={"/shop/store-manage/brand/update/:storeBrandId"}
                element={<StoreBrandUpdatePage />}
              />
              <Route
                path={"/shop/store-manage/area/list"}
                element={<AreaListPage />}
              />
              <Route
                path={"/shop/store-manage/area/add"}
                element={<AreaAddPage />}
              />
              <Route
                path={"/shop/store-manage/area/update/:areaId"}
                element={<AreaUpdatePage />}
              />
              <Route
                path={"/shop/store-manage/businessCircle/list"}
                element={<BusinessCircleListPage />}
              />
              <Route
                path={"/shop/store-manage/businessCircle/add"}
                element={<BusinessCircleAddPage />}
              />
              <Route
                path={
                  "/shop/store-manage/businessCircle/update/:businessCircleId"
                }
                element={<BusinessCircleUpdatePage />}
              />
              <Route
                path={
                  "/shop/store-manage/businessCircle/store/list/:businessCircleId"
                }
                element={<BusinessCircleStoreListPage />}
              />
              <Route
                path={
                  "/shop/store-manage/businessCircle/store/add/:businessCircleId"
                }
                element={<BusinessCircleStoreAddPage />}
              />

              <Route
                path={"/shop/store-manage/businessCircleTag/list"}
                element={<BusinessCircleTagListPage />}
              />
              <Route
                path={"/shop/store-manage/businessCircleTag/add"}
                element={<BusinessCircleTagAddPage />}
              />
              <Route
                path={
                  "/shop/store-manage/businessCircleTag/update/:businessCircleTagId"
                }
                element={<BusinessCircleTagUpdatePage />}
              />
            </Routes>
          </Content>
          <FooterLayout />
        </Layout>
      </Router>
    </Layout>
  );
};
