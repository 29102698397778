import { Button, Card, Col, Image,Divider, Row } from "antd";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAgency } from "../../services/agency-service";
import { useDocumentTitle } from "../../utils"
import { useAgencySearchParams } from "./util";
import dayjs from "dayjs";
import "./detail.css";

export const AgencyDetailPage = () => {
    //标题
    useDocumentTitle('机构详情', true)
    const [param] = useAgencySearchParams();
    const imgUrl = process.env.REACT_APP_IMG_URL;
    let agencyId = param.agencyId;

    //表单

    //准备回显
    const { data: agency } = useAgency(agencyId);

    //内容图
    const [visibleMore,setVisibleMore] = useState(false);
    const agencyMorePicsImage = agency?.data?.agencyMorePicsList?.map((item:any)=>{
        return (
            <><Image src={imgUrl + item }></Image></>
        )
    })    //内容图
    const [visibleEnvironment,setVisibleEnvironment] = useState(false);
    const agencyEnvironmentPicsImage = agency?.data?.agencyEnvironmentPicsList?.map((item:any)=>{
        return (
            <><Image src={imgUrl + item }></Image></>
        )
    })

    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/jigou/agency-manage/agency/list"}>
                        <Button type="primary">列表</Button>
                    </Link>
                </Col>
            </Row>

            <Divider style={{ marginBottom: 24 }} />
            <Card title={"基本信息"}>
                <Row gutter={16}>
                    
                    <Col className="gutter-row" span={4}>
                        <div style={{  padding: '8px 0' }}>机构名称:{agency?.data?.agencyName
                      ? agency?.data?.agencyName
                      : "-"}</div>
                    </Col>
                    <Col className="gutter-row" span={4}>
                        <div style={{ padding: '8px 0' }}>机构电话:{agency?.data?.agencyTel?agency?.data?.agencyTel:""}</div>
                    </Col>
                    <Col className="gutter-row" span={6}>
                        <div style={{  padding: '8px 0' }}>机构创建时间:{agency?.data?.agencyCreateTime?dayjs(agency?.data?.agencyCreateTime).format("YYYY-MM-DD"):""}</div>
                    </Col>
                    <Col className="gutter-row" span={6}>
                        <div style={{  padding: '8px 0' }}>机构申请时间:{agency?.data?.agencyCreateTime?dayjs(agency?.data?.agencyCreateTime).format("YYYY-MM-DD hh:mm:ss"):""}</div>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col className="gutter-row" span={4}>
                        <div style={{  padding: '8px 0' }}>
                            师资数量:{agency?.data?.agencyTeacherNum? agency?.data?.agencyTeacherNum:"0"}
                        </div>
                    </Col>
                    <Col className="gutter-row" span={4}>
                        <div style={{  padding: '8px 0' }}>
                            点赞:{agency?.data?.agencyLikeNum? agency?.data?.agencyLikeNum:"0"}
                        </div>
                    </Col>
                    <Col className="gutter-row" span={4}>
                        <div style={{  padding: '8px 0' }}>
                            收藏:{agency?.data?.agencyCollectNum? agency?.data?.agencyCollectNum:"0"}
                        </div>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col className="gutter-row" span={12}>
                        <div style={{  padding: '8px 0' }}>
                        位置:{agency?.data?.areaProvince?agency?.data?.areaProvince+
                        agency?.data?.areaCity+agency?.data?.areaDistrict+
                        (agency?.data?.areaDetail?agency?.data?.areaDetail:"") :""}
                        </div>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col className="gutter-row" span={12}>
                        <div style={{  padding: '8px 0' }}>
                        联系方式:{agency?.data?.agencyConcatName?agency?.data?.agencyConcatName+" "+
                        agency?.data?.agencyConcatTel:""}
                        </div>
                    </Col>
                </Row>
                <Row gutter={16}>
                    <Col className="gutter-row" span={12}>
                        <div style={{  padding: '8px 0' }}>
                        机构介绍:{agency?.data?.agencyIntroduce?agency?.data?.agencyIntroduce:""}
                        </div>
                    </Col>
                </Row>
            </Card>

            <Card title={"图片展示"} style={{marginTop:"20px"}}>
                <Row gutter={16}>
                    <Col className="gutter-row" span={5} style={{minWidth:250}}>
                        <Card title={"背景图"} style={{textAlign:"center",minWidth:250}}>
                                {agency?.data?.agencyPic?<>
                                <Image preview={{ visible: false }} width={200} src={imgUrl +agency?.data?.agencyPic }></Image>
                                </>:""}
                        </Card>
                    </Col>
                    <Col className="gutter-row" span={5} style={{minWidth:250}}>
                    <Card title={"内容展示图"} style={{textAlign:"center",minWidth:250}}>
                        {agency?.data?.agencyMorePics?<>
                            <Image
                                preview={{ visible: false }}
                                width={200}
                                src={imgUrl +agency?.data?.agencyMorePicsList[0] }
                                onClick={() => setVisibleMore(true)}
                            />
                            <div style={{ display: 'none' }}>
                                <Image.PreviewGroup preview={{visible:visibleMore ,
                                     onVisibleChange: (vis) => setVisibleMore(vis) }}>
                                {agencyMorePicsImage}
                                </Image.PreviewGroup>
                            </div></>:""
                        }
                        </Card>
                        
                    </Col>
                    <Col className="gutter-row" span={5} style={{minWidth:250}}>
                    <Card title={"环境展示"} style={{textAlign:"center",minWidth:250}}>
                        {agency?.data?.agencyEnvironmentPics?<>
                            <Image
                                preview={{ visible: false }}
                                width={200}
                                src={imgUrl +agency?.data?.agencyEnvironmentPicsList[0] }
                                onClick={() => setVisibleEnvironment(true)}
                            />
                            <div style={{ display: 'none' }}>
                                <Image.PreviewGroup preview={{visible:visibleEnvironment ,
                                     onVisibleChange: (vis) => setVisibleEnvironment(vis) }}>
                                {agencyEnvironmentPicsImage}
                                </Image.PreviewGroup>
                            </div></>:""
                        }
                        </Card>
                        </Col>
                </Row>
            </Card>
            <Card title={"营业执照"} style={{marginTop:"20px"}}>
                <Row gutter={16}>
                    <Col className="gutter-row" span={5}>
                        <Card title={"营业执照"} style={{textAlign:"center",minWidth:230}}>
                            <div style={{  padding: '8px 0' }}>
                                {agency?.data?.businessLicense?<>
                                <Image preview={{ visible: false }} width={200} src={imgUrl +agency?.data?.businessLicense }></Image>
                                </>:""}
                            </div>
                        </Card>
                    </Col>
                    <Col className="gutter-row" span={5}>
                        <Card title={"法人身份证正面"} style={{textAlign:"center",minWidth:230}}>
                            <div style={{  padding: '8px 0' }}>
                                {agency?.data?.legalPersonIdCardz?<>
                                <Image preview={{ visible: false }} width={200} src={imgUrl +agency?.data?.legalPersonIdCardz }></Image>
                                </>:""}
                            </div>
                        </Card>
                    </Col>
                    <Col className="gutter-row" span={5}>
                        <Card title={"法人身份证反面"} style={{textAlign:"center",minWidth:230}}>
                            <div style={{  padding: '8px 0' }}>
                                {agency?.data?.legalPersionIdCardf?<>
                                <Image preview={{ visible: false }} width={200} src={imgUrl +agency?.data?.legalPersionIdCardf }></Image>
                                </>:""}
                            </div>
                        </Card>
                    </Col>

                </Row>
            </Card>
            <Card title={"机构详情"} style={{marginTop:"20px",width:"100%"}} className="agency-detail">
                <div style={{width:"100%"}} dangerouslySetInnerHTML={{__html: agency?.data?.agencyContent}}></div>
            </Card>
        </div>
    )
}