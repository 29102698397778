import { useMutation, useQuery, useQueryClient } from "react-query"
import { SystemSetting } from "../interfaces/system-setting"
import { useHttp } from "../utils/http"

export const useSystemSetting = () => {
    const client = useHttp()
    return useQuery<any>(
        ['systemSetting', {}],
        () => client(`systemSetting`),
    )
}

export const useEditSystemSetting = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<SystemSetting>) => client(`systemSetting`, {
            method: 'POST',
            data: params
        }), {
        onSuccess: () => queryClient.invalidateQueries('systemSetting')
    }
    )
}