import { Table, Space, Row, Col, Button, Input, Modal, message, Image, Card, Form, Select, Menu } from 'antd';
import { usePointsGoodsList, useDeletePointsGoods } from '../../services/points-goods-service';
import { useDebounce, useDocumentTitle } from '../../utils';
import { usePointsGoodsSearchParams } from './util';
import { ErrorBox } from "../../components/lib";
import { Link } from "react-router-dom";
import dayjs from 'dayjs';
const { Option } = Select;

export const PointsGoodsListPage = () => {
    useDocumentTitle('商品列表', true)
    const imgUrl = process.env.REACT_APP_IMG_URL;
    //参数
    const [param, setParam] = usePointsGoodsSearchParams();
    const { isLoading, error, totalElements, data: list } = usePointsGoodsList(useDebounce(param, 200));
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) =>
            setParam({
                ...param,
                pageNum: (current - 1),
            })
    }
    //删除事件
    const useMutateDeletePointsGoods = useDeletePointsGoods;
    const { mutateAsync } = useMutateDeletePointsGoods();
    const confirmDeletePointsGoods = (pointsGoodsId: number) => {
        Modal.confirm({
            title: "确定失效这条数据吗?",
            content: "点击确定失效",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ pointsGoodsId }).then(() => {
                        message.info('操作成功');
                        Modal.destroyAll();
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    //完成
    const onFinish = (values: any) => {
        setParam({
            ...param,
            pointsGoodsTitle: values.pointsGoodsTitle,
            pointsGoodsState: values.pointsGoodsState,
            pageNum: null
        })
    }
    return (
        <div>
            <Menu style={{ marginBottom: 24 }} mode="horizontal" selectedKeys={['pointsGoods']}>
                <Menu.Item key="pointsGoods">
                    <Link to={"/shop/market-manage/points/goods/list"}>
                        商品管理
                    </Link>
                </Menu.Item>
                <Menu.Item key="pointsGoodsClass">
                    <Link to={"/shop/market-manage/points/goodsClass/list"}>
                        分类管理
                    </Link>
                </Menu.Item>
                <Menu.Item key="pointsAdv">
                    <Link to={"/shop/market-manage/points/adv/list"}>
                        广告管理
                    </Link>
                </Menu.Item>
            </Menu>
            <Row style={{ marginBottom: 24 }}>
                <Col span={12}>
                    <Link to={"/shop/market-manage/points/goods/add"}>
                        <Button type="primary">新建商品</Button>
                    </Link>
                </Col>
            </Row>
            <Card style={{ backgroundColor: "#f2f3f5", marginBottom: 24 }}>
                <Form onFinish={onFinish}>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item
                                name="pointsGoodsTitle"
                                label="活动名称"
                            >
                                <Input placeholder="请输入活动名称" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item
                                name="pointsGoodsState"
                                label="活动状态"
                            >
                                <Select
                                    showSearch
                                    placeholder="请选择活动状态"
                                    optionFilterProp="children"
                                >
                                    <Option value={1}>未开始</Option>
                                    <Option value={2}>进行中</Option>
                                    <Option value={3}>已结束</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Button style={{ marginLeft: 70 }} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
            <div>
                <Table
                    loading={isLoading}
                    pagination={paginationProps}
                    rowKey={columns => columns.pointsGoodsId}
                    columns={[
                        {
                            title: '活动商品',
                            width: 300,
                            fixed: 'left',
                            dataIndex: 'goodsName',
                            key: 'goodsName',
                            render: (value, pointsGoods) => (
                                <Space size="middle">
                                    <Row>
                                        <Col span={6}>
                                            <Image
                                                width="100%"
                                                src={imgUrl  + pointsGoods.goodsPic}
                                            />
                                        </Col>
                                        <Col span={18} style={{ paddingLeft: 10 }}>
                                            {pointsGoods.goodsName}
                                        </Col>
                                    </Row>
                                </Space>
                            ),
                        },
                        {
                            title: '活动名称',
                            dataIndex: 'pointsGoodsTitle',
                            key: 'pointsGoodsTitle'
                        },
                        {
                            title: '活动时间',
                            dataIndex: 'pointsGoodsStartTime',
                            key: 'pointsGoodsStartTime',
                            width: 200,
                            render(value, pointsGoods) {
                                return <span>
                                    {pointsGoods.pointsGoodsStartTime ? dayjs(pointsGoods.pointsGoodsStartTime).format('YYYY-MM-DD HH:mm:ss') : '无'}<br />
                                    ～<br />
                                    {pointsGoods.pointsGoodsEndTime ? dayjs(pointsGoods.pointsGoodsEndTime).format('YYYY-MM-DD HH:mm:ss') : '无'}
                                </span>
                            },
                        },
                        {
                            title: '订单实付积分',
                            dataIndex: 'pointsGoodsOrdersTotalPrice',
                            key: 'pointsGoodsOrdersTotalPrice'
                        },
                        {
                            title: '订单实付金额(元)',
                            dataIndex: 'pointsGoodsOrdersTotalMoney',
                            key: 'pointsGoodsOrdersTotalMoney'
                        },
                        {
                            title: '付款订单数',
                            dataIndex: 'pointsGoodsOrdersNum',
                            key: 'pointsGoodsOrdersNum'
                        },
                        {
                            title: '排序',
                            dataIndex: 'pointsGoodsSort',
                            key: 'pointsGoodsSort',
                        },
                        {
                            title: "门店",
                            dataIndex: "storeName",
                            key: "storeName",
                            width: 250,
                        },
                        {
                            title: "显示区域",
                            dataIndex: "areaName",
                            key: "areaName"
                        },
                        {
                            title: '操作',
                            key: 'operation',
                            fixed: 'right',
                            width: 150,
                            render: (value, pointsGoods) => (
                                <Space size="middle">
                                    {pointsGoods.pointsGoodsStatus === true ? <a onClick={() => confirmDeletePointsGoods(pointsGoods.pointsGoodsId)}>
                                        失效
                                    </a> : ''}<br />
                                    <Link to={'/shop/market-manage/points/goods/update/' + pointsGoods.pointsGoodsId}>
                                        编辑
                                    </Link>
                                </Space>
                            ),
                        },
                    ]}
                    dataSource={list || []}
                    scroll={{ x: 1800 }} />
            </div>
            <ErrorBox error={error} />
        </div>
    )
}