import { Button, Col, Dropdown, Menu, message, Modal, Row, Space, Table } from "antd"
import Search from "antd/lib/input/Search"
import DownOutlined from "@ant-design/icons/lib/icons/DownOutlined";
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useAdminAccountList, useDeleteAdminAccount, useForbiddenAdminAccount, useResetAdminAccount } from "../../services/admin-account-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useAdminAccountSearchParams } from "./util"

export const AdminAccountListPage = () => {
    useDocumentTitle('账号列表', true)
    const [param, setParam] = useAdminAccountSearchParams()
    const { isLoading, error, totalElements, data: list } = useAdminAccountList(useDebounce(param, 200))
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) =>
            setParam({
                ...param,
                pageNum: (current - 1),
            })
    }
    //删除事件
    const useMutateDeleteAdminAccount = useDeleteAdminAccount;
    const { mutateAsync } = useMutateDeleteAdminAccount();
    const confirmDeleteAdminAccount = (adminAccountId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ adminAccountId }).then((data) => {
                        if (data.status === 0) {
                            message.info('删除成功');
                            Modal.destroyAll();
                        } else {
                            message.error(data.msg)
                        }
                    });
                }).catch(() => console.log('Oops errors!'));
            },
            cancelText: "取消"
        });
    };
    // 重置密码
    const { mutateAsync: setResetAdminAccount } = useResetAdminAccount();
    const confirmResetAdminAccount = (adminAccountId: number) => {
        Modal.confirm({
            title: '确定重置密码吗?',
            content: '点击确定重置',
            okText: '确定',
            onOk(): any {
                return new Promise((resolve, reject) => {
                    setResetAdminAccount({ adminAccountId }).then((data) => {
                        if (data.status === 0) {
                            message.info('重置成功');
                            Modal.destroyAll();
                        } else {
                            message.info(data.msg);
                        }
                    });
                }).catch(() => console.log('Oops errors!'));
            },
            cancelText: '取消',
        });
    };
    //禁用
    const { mutateAsync: mutateForbiddenAdminAccount } = useForbiddenAdminAccount();
    const forbiddenAdminAccount = (adminAccountId: number, adminAccountForbidden: boolean) => {
        Modal.confirm({
            title: "确定操作这条数据吗?",
            content: "点击确定操作",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateForbiddenAdminAccount({ adminAccountId, adminAccountForbidden }).then((data) => {
                        message.info(data?.msg);
                        Modal.destroyAll();
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    }

    const editMenu: any = (record: any) => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to={'/setting/account-manage/admin-account/update/' + record.adminAccountId}>编辑</Link>
                </Menu.Item>
                <Menu.Item>
                    <a onClick={() => confirmDeleteAdminAccount(record.adminAccountId)}>删除</a>
                </Menu.Item>
            </Menu>
        );
    };

    const opMenu: any = (record: any) => {
        return (
            <Menu>
                <Menu.Item>
                    <a onClick={() => confirmResetAdminAccount(record.adminAccountId)}>重置</a>
                </Menu.Item>
                <Menu.Item>
                    {record.adminAccountForbidden ? (
                        <a onClick={() => forbiddenAdminAccount(record.adminAccountId, false)}>启用</a>
                    ) : (
                        <a onClick={() => forbiddenAdminAccount(record.adminAccountId, true)}>禁用</a>
                    )}
                </Menu.Item>
            </Menu>
        );
    };

    //分页
    return (
        <div>
            <Row style={{ marginBottom: 24 }}>
                <Col span={12}>
                    <Link to={"/setting/account-manage/admin-account/add"}>
                        <Button type="primary">新建</Button>
                    </Link>
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                    <Search
                        placeholder="请输入账号昵称"
                        style={{ width: 200 }}
                        value={param.adminAccountName}
                        onChange={(evt) =>
                            setParam({
                                ...param,
                                adminAccountNickname: evt.target.value,
                                pageNum: null
                            })}
                        onSearch={(value) =>
                            setParam({
                                ...param,
                                adminAccountNickname: value,
                                pageNum: null
                            })}
                    />
                </Col>
            </Row>
            <Table
                loading={isLoading}
                rowKey={columns => columns.adminAccountId}
                pagination={paginationProps}
                columns={[
                    {
                        title: 'ID',
                        dataIndex: 'adminAccountId',
                        key: 'adminAccountId',
                        width: 80,
                        align: 'center',
                    },
                    {
                        title: '昵称',
                        dataIndex: 'adminAccountNickname',
                        key: 'adminAccountNickname',
                        align: 'center',
                    },
                    {
                        title: '账号',
                        dataIndex: 'adminAccountName',
                        key: 'adminAccountName',
                        align: 'center',
                    },
                    {
                        title: '角色',
                        dataIndex: 'adminRoleName',
                        key: 'adminRoleName',
                        align: 'center',
                        render: (value, adminAccount) => (
                            <Space size='middle'>
                                {!adminAccount.adminAccountSuper ? (adminAccount.adminRoleName) : ('管理员')}
                            </Space>
                        ),
                    },
                    {
                        title: '状态',
                        dataIndex: 'adminAccountForbidden',
                        key: 'adminAccountForbidden',
                        align: 'center',
                        render: (value) => (
                            value ? "禁用" : "正常" 
                        )
                    },
                    {
                        title: '操作',
                        key: 'action',
                        width: 150,
                        align: 'center',
                        render: (value, adminAccount) => (
                            <Space size='middle'>
                                <Dropdown overlay={opMenu.bind(this, adminAccount)}>
                                    <a
                                        className="ant-dropdown-link"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        操作 <DownOutlined />
                                    </a>
                                </Dropdown>
                                <Dropdown overlay={editMenu.bind(this, adminAccount)}>
                                    <a
                                        className="ant-dropdown-link"
                                        onClick={(e) => e.preventDefault()}
                                    >
                                        编辑 <DownOutlined />
                                    </a>
                                </Dropdown>

                            </Space>
                        ),
                    },
                ]}
                dataSource={list || []} />
            <ErrorBox error={error} />
        </div>
    )
}