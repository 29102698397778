import { Button, Card, Cascader, Col, Divider, Form, Input, InputNumber, message, Radio, Row, Select, Space, Spin, Tabs, Tooltip, Typography, Image, Empty } from "antd";
import { CloseOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAgency, useEditAgency } from "../../services/agency-service";
import { useDocumentTitle } from "../../utils"
import UploadPic from '../../components/upload-pic';
import { useAgencyTagListAll } from "../../services/agency-tag-service";
import { useAreaListAll } from "../../services/area-service";
import moment from "moment";
import UploadMorePic from "../../components/upload-more-pic";
import TabPane from "antd/lib/tabs/TabPane";
import RichText from "../../components/rich-text";
import BraftEditor from "braft-editor";
import UploadPicNoSet from "../../components/upload-pic-noset";
import { useMemberListAll } from "../../services/member-service";
const { Search } = Input
export const AgencyUpdatePage = () => {
    //标题
    useDocumentTitle('编辑机构', true)
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const agencyId = Number(pathname.match(/jigou\/agency-manage\/agency\/update\/(\d+)/)?.[1])

    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 12 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };

    //表单
    const [form] = useForm();
    const [agencyPic, setAgencyPic] = useState("");
    const [uploading, setUploading] = useState<boolean>(false);

    const [agencyContent, setAgencyContent] = useState("");
    const [agencyHonorList, setAgencyHonorList] = useState();
    const [agencyCreateTimeFormat, setAgencyCreateTimeFormat] = useState("");
    const [morePicList, setMorePicList] = useState<any[]>([[]]);
    const [environmentPicList, setEnvironmentPicList] = useState<any[]>([[]]);
    const [areaProvinceId, setAreaProvinceId] = useState();
    const [areaCityId, setAreaCityId] = useState();
    const [areaDistrictId, setAreaDistrictId] = useState();

    //准备回显
    const { data: editingAgency, isLoading } = useAgency(agencyId);
    console.log("回显", editingAgency, isLoading);
    useEffect(() => {
        form.setFieldsValue({ ...editingAgency?.data });
        //封面
        if (editingAgency?.data?.agencyPic) {
            setAgencyPic(editingAgency?.data?.agencyPic)
        }
        if (editingAgency?.data?.agencyContent) {
            setAgencyContent(editingAgency?.data?.agencyContent);
            form.setFieldsValue({
                "agencyContent": BraftEditor.createEditorState(editingAgency?.data?.agencyContent)
            });
        }
        if (editingAgency?.data?.agencyHonorList) {
            setAgencyHonorList(editingAgency?.data?.agencyHonorList);
        }
        //多图
        if (editingAgency?.data?.morePicList) {
            setMorePicList([editingAgency?.data?.morePicList]);
            form.setFieldsValue({
                "uploadMorePics": editingAgency?.data?.morePicList
            })
        }
        //多图
        if (editingAgency?.data?.moreEnvironmentPicList) {
            setEnvironmentPicList([editingAgency?.data?.moreEnvironmentPicList]);
            form.setFieldsValue({
                "uploadEnvironmentPics": editingAgency?.data?.moreEnvironmentPicList
            })
        }
        //成立时间
        if (editingAgency?.data?.agencyCreateTimeFormat) {
            setAgencyCreateTimeFormat(editingAgency?.data?.agencyCreateTimeFormat)
            form.setFieldsValue({
                "agencyCreateTimeFormat": moment(editingAgency?.data?.agencyCreateTimeFormat, "YYYY-MM-DD")
            })
        }
        //省市区
        let companyCascader: any = [];
        if (editingAgency?.data?.areaProvinceId) {
            companyCascader.push(editingAgency?.data?.areaProvinceId);
            setAreaProvinceId(editingAgency?.data?.areaProvinceId)
        }
        if (editingAgency?.data?.areaCityId) {
            companyCascader.push(editingAgency?.data?.areaCityId);
            setAreaCityId(editingAgency?.data?.areaCityId)
        }
        if (editingAgency?.data?.areaDistrictId) {
            companyCascader.push(editingAgency?.data?.areaDistrictId);
            setAreaDistrictId(editingAgency?.data?.areaDistrictId)
        }
        if (companyCascader) {
            form.setFieldsValue({
                "agencyCascader": companyCascader
            })
        }
        if (editingAgency?.data?.memberTel) {
            setMemberTel(editingAgency?.data?.memberTel)
        }
    }, [editingAgency, form]);

    //tag
    const { data: agencyTagList } = useAgencyTagListAll();
    const agencyTagListOptions = agencyTagList?.map((item: any) =>
        <Select.Option value={item.agencyTagId}>{item.agencyTagName}</Select.Option>
    )

    const handleSelectTime = (value: any, dateString: any) => {
        setAgencyCreateTimeFormat(dateString);
    }

    //省市区
    const { data: areaList } = useAreaListAll(3)


    const handleCascader = (e: any) => {
        if (e.length > 0) {
            setAreaProvinceId(e[0]);
            if (e.length > 1) {
                setAreaCityId(e[1]);
                if (e.length > 2) {
                    setAreaDistrictId(e[2]);
                }
            }
        }
    }
    const [honorVal, setHonorVal] = useState([
        {
            "agencyHonorId": null,
            "agencyHonorContent": null,
            "agencyHonorPic": null,
        }
    ]);
    //新建
    const [submitLoading, setSubmitLoading] = useState(false);
    const { mutateAsync } = useEditAgency();
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        var picArray = values?.uploadMorePics?.map((item: any) => {
            return item?.response;
        });
        var agencyMorePics = picArray?.join(',');
        var environmentPicArray = values?.uploadEnvironmentPics?.map((item: any) => {
            return item?.response;
        });
        var agencyEnvironmentPics = environmentPicArray?.join(',');
        values.agencyTagIds = values.agencyTagIdList;
        console.log("values", values);
        const honorList = values.agencyHonorList;
        let agencyHonorList: any = [];
        if (honorList && honorList.length > 0) {
            agencyHonorList = honorList?.map((item: any) => {
                return {
                    "agencyHonorId": item.agencyHonorId || null,
                    "agencyHonorContent": item.agencyHonorContent,
                    "agencyHonorPic": item.agencyHonorPic,
                }
            })
        }
        values.agencyHonorList = agencyHonorList;
        mutateAsync({
            ...editingAgency?.data, ...values,
            agencyPic,
            agencyMorePics,
            agencyEnvironmentPics,
            agencyCreateTimeFormat,
            agencyContent,
            areaProvinceId,
            areaCityId,
            areaDistrictId,
        }).then((data) => {
            if (data.status === 0) {
                message.success(data.msg);
                navigate('/jigou/agency-manage/agency/list')
            } else {
                setSubmitLoading(false);
                message.error(data.msg);
            }
        }).catch(err => {
            message.error('提交失败，请重新提交');
            setSubmitLoading(false);
        });
    };

    //选择用户
    const { mutateAsync: getMemberList } = useMemberListAll();
    const [memberList, setMemberList] = useState([]);
    const [memberTel, setMemberTel] = useState("");
    const handleMemberTel = (e: any) => {
        setMemberTel(e.target.value);
    }
    useEffect(() => {
        getMemberList({
            memberTel,
        }).then((data) => {
            if (data.status === 0) {
                setMemberList(data?.data?.content);
            }
        })
    }, [memberTel])

    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/jigou/agency-manage/agency/list"}>
                        <Button type="primary">列表</Button>
                    </Link>
                </Col>
            </Row>

            <Divider style={{ marginBottom: 24 }} />

            <Spin spinning={isLoading}>
                <Form
                    form={form}
                    {...layout}
                    onFinish={onFinish}
                >
                    <Tabs defaultActiveKey="1">
                        <TabPane tab="基本信息" key="1">
                            <Form.Item
                                label={"机构类型"}
                                name={"agencyType"}
                                rules={[{ required: true, message: "机构类型必填" }]}
                            >
                                <Select style={{ width: 380 }} >
                                    <Select.Option value={1}>企业</Select.Option>
                                    <Select.Option value={2}>个人</Select.Option>
                                </Select>
                            </Form.Item>

                            <Form.Item
                                label={"机构名称"}
                                name={"agencyName"}
                                rules={[{ required: true, message: "机构名称必填" }]}
                            >
                                <Input style={{ width: 380 }} placeholder={"请输入机构名称"} />
                            </Form.Item>

                            <UploadPic
                                label={'图片'}
                                name={'uploadAgencyPic'}
                                pic={agencyPic}
                                setPic={setAgencyPic}
                                action={'update'}
                                style={{ textAlign: 'left' }}
                            >
                            </UploadPic>

                            <Form.Item
                                label={"机构标签"}
                                name={"agencyTagIdList"}
                                rules={[{ required: false, message: "机构标签必填" }]}
                            >
                                <Select mode="multiple"
                                    style={{ width: 380 }} placeholder={"请选择机构标签"}>
                                    {agencyTagListOptions}
                                </Select>
                            </Form.Item>

                            <UploadMorePic
                                label={"机构内容图片"}
                                name={"uploadMorePics"}
                                index={0}
                                fileList={morePicList}
                                setFileList={setMorePicList}
                                style={{ textAlign: "left" }}
                            >
                            </UploadMorePic>

                            <UploadMorePic
                                label={"机构环境图片"}
                                name={"uploadEnvironmentPics"}
                                index={0}
                                fileList={environmentPicList}
                                setFileList={setEnvironmentPicList}
                                style={{ textAlign: "left" }}
                            >
                            </UploadMorePic>

                            <Form.Item
                                label={"机构介绍"}
                                name={"agencyIntroduce"}
                                rules={[{ required: false, message: "机构介绍必填" }]}
                            >
                                <Input.TextArea rows={7} style={{ width: 380 }} placeholder={"请输入机构介绍"} />
                            </Form.Item>

                            <Form.Item
                                label={"老师数量"}
                                name={"agencyTeacherNum"}
                                rules={[{ required: false, message: "老师数量必填" }]}
                            >
                                <InputNumber min={0} step={1} style={{ width: 380 }} placeholder={"请输入老师数量"} />
                            </Form.Item>

                            <Form.Item
                                label={"机构电话"}
                                name={"agencyTel"}
                                rules={[{ required: true, message: "机构电话必填" }]}
                            >
                                <Input style={{ width: 380 }} placeholder={"请输入机构电话"} />
                            </Form.Item>

                            <Form.Item
                                label={"主攻专业"}
                                name={"agencyMajor"}
                                rules={[{ required: false, message: "主攻专业必填" }]}
                            >
                                <Input style={{ width: 380 }} placeholder={"请输入主攻专业"} />
                            </Form.Item>

                            <Form.Item
                                label={"省市区"}
                                name={"agencyCascader"}
                            >
                                <Cascader style={{ width: 380 }} options={areaList?.data} onChange={handleCascader} placeholder={"请选择省市区"} />
                            </Form.Item>
                            <Form.Item
                                label={"详细地址"}
                                name={"areaDetail"}
                                rules={[{ required: false, message: "详细地址必填" }]}
                            >
                                <Input style={{ width: 380 }} placeholder={"请输入详细地址"} />
                            </Form.Item>
                            <Form.Item
                                label={'经纬度'}
                                name={'agencyLatitude'}
                            >
                                <Form.Item
                                    name={'agencyLatitude'}
                                    style={{ float: 'left', marginBottom: 0 }}
                                >
                                    <Input type={'number'} style={{ width: 150 }} placeholder={'请输入纬度'} />
                                </Form.Item>
                                <Form.Item
                                    name={'agencyLongitude'}
                                    style={{ float: 'left', marginBottom: 0 }}
                                >
                                    <Input type={'number'} style={{ width: 150, marginLeft: 10 }} placeholder={'请输入经度'} />
                                </Form.Item>
                                <Form.Item style={{ float: 'left', marginBottom: 0, marginLeft: 10 }}>
                                    <Tooltip title='坐标拾起器'>
                                        <Typography.Link target='_blank' href='https://lbs.qq.com/getPoint/'>
                                            坐标
                                        </Typography.Link>
                                    </Tooltip>
                                </Form.Item>
                            </Form.Item>
                            <Form.Item
                                label={"联系人"}
                                name={"agencyConcatName"}
                                rules={[{ required: false, message: "联系人必填" }]}
                            >
                                <Input style={{ width: 380 }} placeholder={"请输入联系人"} />
                            </Form.Item>

                            <Form.Item
                                label={"联系人电话"}
                                name={"agencyConcatTel"}
                                rules={[{ required: false, message: "联系人电话必填" }]}
                            >
                                <Input style={{ width: 380 }} placeholder={"请输入联系人电话"} />
                            </Form.Item>

                            <Form.Item label={'搜索用户'} name={'memberTel'}>
                                <Search
                                    placeholder={'请输入手机号检索用户'}
                                    maxLength={11}
                                    onChange={handleMemberTel}
                                    style={{ width: 380 }}></Search>
                            </Form.Item>

                            {memberList?.length ? (
                                <Form.Item label={'选择用户'} name={'memberId'}>
                                    <Radio.Group>
                                        {memberList?.map((item:any) => (
                                            <Space align='center' direction='vertical' size={5} style={{ marginBottom: '10px', marginRight: '5px' }} key={item.memberId}>
                                                <Radio value={item.memberId}><span className='text1' style={{ width: '60px', display: 'block' }} >{item.memberNickname}</span></Radio>
                                                <Image width='60px' height='60px' src={item.memberHeadImg} />
                                            </Space>
                                        ))}
                                    </Radio.Group>
                                </Form.Item>
                            ) : (
                                <Form.Item label={'选择用户'}>
                                    <Empty description={'暂无用户'} style={{ maxWidth: '180px', height: '50px' }} image={Empty.PRESENTED_IMAGE_SIMPLE} />
                                </Form.Item>
                            )}

                        </TabPane>

                        <TabPane tab="机构介绍" key="2">

                            <RichText
                                label={"企业介绍"}
                                name={"agencyContent"}
                                setContent={setAgencyContent}
                            >
                            </RichText>

                        </TabPane>
                        <TabPane tab="机构荣誉" key="3">
                            <Form.Item
                                label={'机构荣誉'}
                            >
                                <Form.List
                                    initialValue={honorVal}
                                    name="agencyHonorList"
                                >
                                    {(fields, { add, remove }) => (
                                        <>
                                            {fields.map((field, index) => (
                                                <Card
                                                    key={field.key}
                                                    style={{ width: 380, marginBottom: 10 }}
                                                >
                                                    {index !== 0 ? (
                                                        <div style={{ width: '100%', display: 'flex', justifyContent: 'flex-end', paddingBottom: 10, boxSizing: 'border-box' }}>
                                                            <a onClick={() => remove(index)}><CloseOutlined /></a>
                                                        </div>
                                                    ) : ('')}

                                                    <Form.Item
                                                        label={'标题'}
                                                        name={[field.name, "agencyHonorContent"]}
                                                        rules={[{ required: true, message: "标题必填" }]}
                                                        style={{ marginBottom: 10 }}
                                                    >
                                                        <Input style={{ width: '100%' }} placeholder={"请输入标题"} />
                                                    </Form.Item>

                                                    <UploadPicNoSet
                                                        name={[field.name, "agencyHonorPic"]}
                                                        action={'update'}
                                                        style={{ textAlign: 'left' }}
                                                        label={'图片'}
                                                        form={form}
                                                        namePath={['agencyHonorList', field.name, "agencyHonorPic"]}
                                                    ></UploadPicNoSet>

                                                </Card>
                                            ))}
                                            <a onClick={add} style={{ marginLeft: "8px" }}>
                                                添加
                                            </a>
                                        </>
                                    )}
                                </Form.List>
                            </Form.Item>
                        </TabPane>
                    </Tabs>

                    <Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                        <Button loading={submitLoading} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    )
}