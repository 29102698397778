import { Button, Divider, Form, Input, message } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { useAuth } from "../../context/auth-context";
import { useEditAdminAccountPwd } from "../../services/admin-account-service";
import { useDocumentTitle } from "../../utils"

export const AdminAccountUpdatePwdPage = () => {
    //标题
    useDocumentTitle('修改密码', true)

    const { logout } = useAuth();

    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };

    //表单
    const [form] = useForm();

    //提交表单
    const [submitLoading, setSubmitLoading] = useState(false);
    const { mutateAsync } = useEditAdminAccountPwd();
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...values }).then((data) => {
            if (data.status === 0) {
                message.success(data.msg);
                setTimeout(() => {
                    logout();
                }, 1000)
            } else {
                setSubmitLoading(false);
                message.info(data.msg);
            }
        }).catch(err => {
            message.error('提交失败，请重新提交');
            setSubmitLoading(false);
        });
    };
    return (
        <div>
            <Divider style={{ marginBottom: 24 }} />
            <Form
                form={form}
                {...layout}
                onFinish={onFinish}
            >
                <Form.Item
                    label={"原密码"}
                    name={"oldPassword"}
                    rules={[{ required: true, message: "原密码必填" }]}
                >
                    <Input.Password style={{ width: 380 }} placeholder={"请输入原密码"} />
                </Form.Item>

                <Form.Item
                    label={"新密码"}
                    name={"newPassword"}
                    rules={[{ required: true, message: "新密码必填" }]}
                >
                    <Input.Password style={{ width: 380 }} placeholder={"请输入新密码"} />
                </Form.Item>

                <Form.Item
                    label={"确认密码"}
                    name={"againPassword"}
                    rules={[{ required: true, message: "确认密码必填" }]}
                >
                    <Input.Password style={{ width: 380 }} placeholder={"请输入确认密码"} />
                </Form.Item>

                <Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                    <Button loading={submitLoading} type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}