import { useMutation, useQuery, useQueryClient } from "react-query";
import { SchoolType } from "../interfaces/school-type";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useSchoolTypeList = (param?: Partial<SchoolType>) => {
    const client = useHttp();
    const result = useQuery<any>(['schoolTypePage', param], () => client('schoolType', { data: cleanObject(param) }))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return { ...result, totalElements, data };
}

export const useEditSchoolType = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<SchoolType>) => client(`schoolType`, {
            method: 'PUT',
            data: params
        }), {
        onSuccess: () => queryClient.invalidateQueries('schoolTypePage')
    }
    )
}

export const useAddSchoolType = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<SchoolType>) => client(`schoolType`, {
            data: params,
            method: 'POST'
        }), {
        onSuccess: () => queryClient.invalidateQueries('schoolTypePage')
    }
    )
}

export const useDeleteSchoolType = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ schoolTypeId }: { schoolTypeId: number }) =>
            client(`schoolType/${schoolTypeId}`, {
                method: "DELETE",
            }), {
        onSuccess: () => queryClient.invalidateQueries('schoolTypePage')
    }
    );
};

export const useSchoolType = (schoolTypeId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['schoolType', { schoolTypeId }],
        () => client(`schoolType/${schoolTypeId}`),
        {
            enabled: Boolean(schoolTypeId)
        }
    )
}

export const useSchoolTypeListAll = () => {
    const client = useHttp();
    return useQuery<any>(["schoolTypeList", {}], () =>
        client(`schoolType/list`)
    );
};