import { Button, Col, Divider, Form, Input, message, Radio, Row, Spin, DatePicker } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useBombWindow, useEditBombWindow } from "../../services/bomb-window-service";
import { useDocumentTitle } from "../../utils"
import moment from "moment";
import UploadPic from "../../components/upload-pic";
import PageUrlSelect from "../../components/page-url-select";
const { RangePicker } = DatePicker;

export const BombWindowUpdatePage = () => {
    //标题
    useDocumentTitle('编辑开屏广告', true)
    const { pathname } = useLocation();
    const bombWindowId = pathname.match(/dress\/decorate-manage\/bomb-window\/update\/(\d+)/)?.[1]
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    //时间
    const [bombWindowStartTimeStr, setBombWindowStartTimeStr] = useState();
    const [bombWindowEndTimeStr, setBombWindowEndTimeStr] = useState();
    const handleSelectTime = (value:any ,dateString:any) => {
        setBombWindowStartTimeStr(dateString[0]);
        setBombWindowEndTimeStr(dateString[1]);
    }
    //表单
    const [form] = useForm();
    const [bombWindowPic, setBombWindowPic] = useState("");
    const [pageUrlId, setPageUrlId] = useState();
    //准备回显数据
    const { data: editingBombWindow, isLoading } = useBombWindow(Number(bombWindowId));
  
    useEffect(() => {
        form.setFieldsValue(editingBombWindow?.data);
        //封面
        if (editingBombWindow?.data?.bombWindowPic) {
            setBombWindowPic(editingBombWindow?.data?.bombWindowPic)
        }
        let pageUrlId = editingBombWindow?.data?.pageUrlId;
        setPageUrlId(pageUrlId);
        //时间
        setBombWindowStartTimeStr(editingBombWindow?.data?.bombWindowStartTimeStr)
        setBombWindowEndTimeStr(editingBombWindow?.data?.bombWindowEndTimeStr)
        form.setFieldsValue({
            "bombWindowTimes": [moment(editingBombWindow?.data?.bombWindowStartTimeStr, "YYYY-MM-DD HH:mm:ss"), moment(editingBombWindow?.data?.bombWindowEndTimeStr, "YYYY-MM-DD HH:mm:ss")]
        })
    }, [editingBombWindow, form]);
    //编辑
    const useMutateEditBombWindow = useEditBombWindow;
    const {mutateAsync } =  useMutateEditBombWindow();
    //提交表单
    const [submitLoading, setSubmitLoading] = useState(false);
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...editingBombWindow?.data, ...values, bombWindowStartTimeStr, bombWindowEndTimeStr, bombWindowPic }).then(() => {
            message.info('编辑成功');
            window.location.href = "/#/dress/decorate-manage/bomb-window/list";
        });
    };
    return (
        <div>
            <Spin spinning={isLoading}>
                <Row>
                    <Col span={12}>
                        <Link to={"/dress/decorate-manage/bomb-window/list"}>
                            <Button type="primary">列表</Button>
                        </Link>
                    </Col>
                </Row>
                <Divider style={{ marginBottom: 24 }} />
                <Form
                    form={form}
                    {...layout}
                    onFinish={onFinish}
                    >
                    <Form.Item
                        label={"标题"}
                        name={"bombWindowTitle"}
                        rules={[{ required: true, message: "标题必填" }]}
                    >
                        <Input style={{ width: 380 }} placeholder={"请输入标题"} />
                    </Form.Item>

                    <Form.Item
                        label={"时间"}
                        name={"bombWindowTimes"}
                        rules={[{ required: true, message: "时间必选择" }]}
                        initialValue={[moment(editingBombWindow?.data?.bombWindowStartTimeStr, "YYYY-MM-DD HH:mm:ss"), moment(editingBombWindow?.data?.bombWindowEndTimeStr, "YYYY-MM-DD HH:mm:ss")]}
                    >
                        <RangePicker onChange={handleSelectTime} showTime style={{ width: 380 }} format="YYYY-MM-DD HH:mm:ss" />
                    </Form.Item>

                    <UploadPic
                        name={'uploadPic'}
                        label={'图片'}
                        pic={bombWindowPic}
                        setPic={setBombWindowPic}
                        action={'update'}
                        style={{ textAlign: 'left' }}
                    >
                    </UploadPic>

                    <PageUrlSelect
                        pageUrlId={pageUrlId}
                    >
                    </PageUrlSelect>

                    <Form.Item
                        label={"排序"}
                        name={"bombWindowSort"}
                        rules={[{ required: true, message: "排序必填" }]}
                    >
                        <Input style={{ width: 380 }} placeholder={"请输入排序"} />
                    </Form.Item>

                    <Form.Item
                        label={"状态"}
                        name={"bombWindowShow"}
                        rules={[{ required: true, message: "状态必选" }]}
                        style={{ textAlign:'left' }}
                    >
                        <Radio.Group>
                            <Radio value={true}>显示</Radio>
                            <Radio value={false}>不显示</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item {...tailLayout} style={{ textAlign:'left' }}>
                        <Button loading={ submitLoading } type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    )
}
