import { Button, Col, message, Modal, Row, Space, Table } from "antd"
import Search from "antd/lib/input/Search"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useFeedbackClassList, useDeleteFeedbackClass } from "../../services/feedback-class-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useFeedbackClassSearchParams } from "./util"

export const FeedbackClassListPage = () => {
    useDocumentTitle('建议类型列表', true)
    const [param, setParam] = useFeedbackClassSearchParams()
    const {isLoading, error, totalElements, data: list} = useFeedbackClassList(useDebounce(param, 200))

    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) => 
            setParam({
            ...param,
            pageNum: (current -1),
        })
    }

    //删除事件
    const { mutateAsync:deleteMutateAsync } = useDeleteFeedbackClass();
    const confirmDeleteFeedbackClass = (feedbackClassId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    deleteMutateAsync({ feedbackClassId }).then((data) => {
                        if (data.status === 0) {
                            message.info('删除成功');
                            Modal.destroyAll();
                          } else {
                            message.error(data.msg)
                          }
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    //分页
    return (
        <div>
            <Row style={{marginBottom: 24}}>
                <Col span={12}>
                    <Link to={"/user/feedback-manage/feedback-class/add"}>
                        <Button type="primary">新建</Button>
                    </Link>
                </Col>
                <Col span={12} style={{textAlign:'right'}}>
                    <Search 
                        placeholder="可搜索建议类型名称" 
                        style={{ width: 200 }}
                        value={param.feedbackClassName}
                        onChange={(evt) =>
                            setParam({
                            ...param,
                            feedbackClassName: evt.target.value,
                            pageNum: null
                        })} 
                        onSearch={(value) =>
                            setParam({
                            ...param,
                            feedbackClassName: value,
                            pageNum: null
                        })} 
                    />
                </Col>
            </Row>
            <Table 
                loading = { isLoading }
                rowKey={ columns => columns.feedbackClassId } 
                pagination = { paginationProps } 
                columns = {[
                {
                    title: 'ID',
                    dataIndex: 'feedbackClassId',
                    key: 'feedbackClassId',
                    align: 'center',
                    width: 100,
                },
                {
                    title: '名称',
                    dataIndex: 'feedbackClassName',
                    key: 'feedbackClassName',
                    align: 'center',
                },
                {
                    title: '排序',
                    dataIndex: 'feedbackClassSort',
                    key: 'feedbackClassSort',
                    align: 'center',
                },
                {
                    title: '状态',
                    dataIndex: 'feedbackClassShow',
                    key: 'feedbackClassShow',
                    align: 'center',
                    render: (value) => (
                        <span>{value ? '显示' : '不显示'}</span>
                    ),
                },
                {
                    title: '操作',
                    key: 'action',
                    align: 'center',
                    render: (value, feedbackClass) => (
                        <Space size="middle">
                            <Link to={"/user/feedback-manage/feedback-class/update/" + feedbackClass.feedbackClassId}>
                                编辑
                            </Link>
                            <a onClick={() => confirmDeleteFeedbackClass(feedbackClass.feedbackClassId)}>
                                删除
                            </a>
                        </Space>
                    ),
                    width: 150,
                }
            ]}
            dataSource={ list || [] }  />
            <ErrorBox error={ error } />
        </div>
    )
}