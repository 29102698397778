import { Button, Modal, Image } from "antd";

const imgUrl = process.env.REACT_APP_IMG_URL;

const PicPlayer = (props: any) => {
    let { picList, modalVisible, setModalVisible } = props;

    const handleCancel = () => {
        setModalVisible(false);
    };

    return (
        <Modal
            title="图片查看"
            forceRender={true}
            visible={modalVisible}
            onCancel={handleCancel}
            width={500}
            destroyOnClose={true}
            footer={
                <Button type={'primary'} onClick={handleCancel}>关闭</Button>
            }
        >
            <Image.PreviewGroup>
                <div style={{ width:'100%',display: 'grid', gridTemplateColumns: 'repeat(4, 1fr)', gridGap: 10 }}>
                    {picList?.map(function (item: string) {
                        return <Image style={{ height: 100 }} src={imgUrl + item} />
                    })}
                </div>
            </Image.PreviewGroup>
        </Modal>
    )
}

export default PicPlayer;