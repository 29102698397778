import { Space, Table, Image, Button, Form, Row, Col, Select, Card, Input, DatePicker, Modal, message } from "antd"
import dayjs from "dayjs"
import { useState } from "react"
import { ErrorBox } from "../../components/lib"
import { useAgencyListAll } from "../../services/agency-service"
import { useSchoolOrdersRefundList, useAgreeSchoolOrdersRefund, useRejectSchoolOrdersRefund, useSchoolOrdersRefund } from "../../services/school-orders-refund-service"
import { useSchoolListAll } from "../../services/school-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useSchoolOrdersRefundSearchParams } from "./util"
import { useAuth } from '../../context/auth-context';
import { useForm } from "antd/lib/form/Form"
const { RangePicker } = DatePicker;
const { Option } = Select;

export const SchoolOrdersRefundListPage = () => {
    useDocumentTitle('学费订单售后列表', true)
    const data = useAuth();
    const apiUrl = process.env.REACT_APP_API_URL;
    const imgUrl = process.env.REACT_APP_IMG_URL;
    const [param, setParam] = useSchoolOrdersRefundSearchParams()
    const { isLoading, error, totalElements, data: list } = useSchoolOrdersRefundList(useDebounce(param, 200))

    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) =>
            setParam({
                ...param,
                pageNum: (current - 1),
            })
    }

    //准备学校
    const { data: schoolList } = useSchoolListAll();
    const schoolOptions = schoolList?.data?.map((item: any, value: any) =>
        <Option value={item.schoolId} key={item.schoolId}>{item.schoolName}</Option>
    );

    //准备机构
    const { data: agencyList } = useAgencyListAll();
    const agencyOptions = agencyList?.data?.map((item: any, value: any) =>
        <Option value={item.agencyId} key={item.agencyId}>{item.agencyName}</Option>
    );

    //开单时间
    const rangeConfig = {
        rules: [{ type: "array" as const, message: "请选择时间!" }],
    };
    const [schoolOrdersRefundAddTimeStart, setSchoolOrdersRefundAddTimeStart] = useState("");
    const [schoolOrdersRefundAddTimeEnd, setSchoolOrdersRefundAddTimeEnd] = useState("");
    const handleSelectTime = (value: any, dateString: any) => {
        setSchoolOrdersRefundAddTimeStart(dateString[0]);
        setSchoolOrdersRefundAddTimeEnd(dateString[1]);
    };

    const [op, setOp] = useState(1);
    function changeOpSubmit(e: number) {
        setOp(e);
    }

    //完成
    const onFinish = (values: any) => {
        if (op === 1) {
            setParam({
                ...param,
                schoolOrdersRefundSn: values.schoolOrdersRefundSn,
                schoolOrdersSn: values.schoolOrdersSn,
                schoolOrdersRefundAddTimeStart: schoolOrdersRefundAddTimeStart,
                schoolOrdersRefundAddTimeEnd: schoolOrdersRefundAddTimeEnd,
                schoolId: values.schoolId,
                agencyId: values.agencyId,
                schoolOrdersRefundState: values.schoolOrdersRefundState,
                schoolOrdersRefundContactName: values.schoolOrdersRefundContactName,
                schoolOrdersRefundContactTel: values.schoolOrdersRefundContactTel,
                memberNickname: values.memberNickname,
                pageNum: null,
            });
        } else if (op === 2) {
            let schoolOrdersRefundSn = values.schoolOrdersRefundSn;
            let schoolOrdersSn = values.schoolOrdersSn;
            let schoolOrdersRefundContactName = values.schoolOrdersRefundContactName;
            let schoolOrdersRefundContactTel = values.schoolOrdersRefundContactTel;
            let memberNickname = values.memberNickname;
            let schoolOrdersRefundState = values.schoolOrdersRefundState;
            let schoolId = values.schoolId;
            let agencyId = values.agencyId;
            let token = data?.adminAccount?.token;
            if (schoolOrdersRefundSn === undefined) {
                schoolOrdersRefundSn = "";
            }
            if (schoolOrdersSn === undefined) {
                schoolOrdersSn = "";
            }
            if (schoolOrdersRefundContactName === undefined) {
                schoolOrdersRefundContactName = "";
            }
            if (schoolOrdersRefundContactTel === undefined) {
                schoolOrdersRefundContactTel = "";
            }
            if (memberNickname === undefined) {
                memberNickname = "";
            }
            if (schoolOrdersRefundState === undefined) {
                schoolOrdersRefundState = "";
            }
            if (schoolId === undefined) {
                schoolId = "";
            }
            if (agencyId === undefined) {
                agencyId = "";
            }
            if (token == undefined) {
                token = "";
            }
            Modal.confirm({
                title: "确定导出当前列表数据吗?",
                content: "点击确定导出",
                okText: "确定",
                onOk(): any {
                    return new Promise((resolve, reject) => {
                        window.location.href = apiUrl + `/schoolOrdersRefund/export?schoolOrdersRefundSn=${schoolOrdersRefundSn}&schoolOrdersSn=${schoolOrdersSn}&schoolOrdersRefundContactName=${schoolOrdersRefundContactName}&schoolOrdersRefundContactTel=${schoolOrdersRefundContactTel}&schoolOrdersRefundAddTimeStart=${schoolOrdersRefundAddTimeStart}&schoolOrdersRefundAddTimeEnd=${schoolOrdersRefundAddTimeEnd}&schoolOrdersRefundState=${schoolOrdersRefundState}&memberNickname=${memberNickname}&schoolId=${schoolId}&agencyId=${agencyId}&token=${token}`;
                        message.success("导出成功");
                        Modal.destroyAll();
                    }).catch(() => console.log("Oops errors!"));
                },
                cancelText: "取消",
            });
        }
    };

    //通过
    const { mutateAsync: mutateAgreeSchoolOrdersRefund } = useAgreeSchoolOrdersRefund();
    const agreeSchoolOrdersRefund = (schoolOrdersRefundId: number) => {
        Modal.confirm({
            title: "确定同意这条数据吗?",
            content: "点击确定操作",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAgreeSchoolOrdersRefund({ schoolOrdersRefundId }).then((data) => {
                        if (data.status === 0) {
                            message.info('同意成功');
                            Modal.destroyAll();
                        } else {
                            message.error(data.msg)
                        }
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };

    //驳回
    const [schoolOrdersRefundId, setSchoolOrdersRefundId] = useState<number>();
    const [submitRejectLoading, setSubmitRejectLoading] = useState(false);
    const [rejectModalVisible, setRejectModalVisible] = useState(false);
    const { mutateAsync: mutateRejectSchoolOrdersRefund } = useRejectSchoolOrdersRefund();
    const { mutateAsync: mutateSchoolOrdersRefund } = useSchoolOrdersRefund();
    const [form] = useForm();
    const [schoolOrdersRefundRejectReason, setSchoolOrdersRefundRejectReason] = useState('');
    const handleRejectReason = (e: any) => {
        setSchoolOrdersRefundRejectReason(e.target.value);
    }
    const handleRejectCancel = () => {
        setRejectModalVisible(false);
    };
    const handleRejectOk = () => {
        if (!schoolOrdersRefundRejectReason) {
            message.error('驳回原因必填');
        } else {
            setSubmitRejectLoading(true)
            mutateRejectSchoolOrdersRefund({ schoolOrdersRefundId, schoolOrdersRefundRejectReason }).then(() => {
                setRejectModalVisible(false);
                setSubmitRejectLoading(false);
            });
        }
    }
    const showRejectModal = (schoolOrdersRefundId: number) => {
        setSchoolOrdersRefundId(schoolOrdersRefundId);
        mutateSchoolOrdersRefund({ schoolOrdersRefundId }).then((data) => {
            form.setFieldsValue({
                "schoolOrdersRefundSn": data?.data?.withdrawSn
            })
        });
        setRejectModalVisible(true);
    }

    //分页
    return (
        <div>
            <Card style={{ backgroundColor: "#f2f3f5", marginBottom: 24 }}>
                <Form onFinish={onFinish}>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item name="schoolOrdersRefundContactName" label="联系人姓名">
                                <Input placeholder="请输入联系人姓名" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="schoolOrdersRefundContactTel" label="联系人手机">
                                <Input placeholder="请输入联系人手机" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="memberNickname" label="用户昵称">
                                <Input placeholder="请输入用户昵称" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item name="schoolOrdersRefundAddTimeRange" label="申请时间" {...rangeConfig}>
                                <RangePicker
                                    onChange={handleSelectTime}
                                    showTime
                                    format="YYYY-MM-DD HH:mm:ss"
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="schoolOrdersRefundSn" label="售后编号">
                                <Input placeholder="请输入售后编号" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="schoolOrdersSn" label="订单编号">
                                <Input placeholder="请输入订单编号" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item name="schoolOrdersRefundState" label="售后状态">
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder="请选择售后状态"
                                    optionFilterProp="children"
                                >
                                    <Option value={100}>全部</Option>
                                    <Option value={10}>退款申请中</Option>
                                    <Option value={20}>退款驳回</Option>
                                    <Option value={30}>退款同意</Option>
                                    <Option value={60}>退款关闭</Option>
                                    <Option value={70}>退款成功</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="schoolId" label="所属学校">
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder="请选择所属学校"
                                    optionFilterProp="children"
                                >
                                    {schoolOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="agencyId" label="所属机构">
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder="请选择所属机构"
                                    optionFilterProp="children"
                                >
                                    {agencyOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Button
                            onClick={() => changeOpSubmit(1)}
                            style={{ marginLeft: 70 }}
                            type="primary"
                            htmlType="submit"
                        >
                            提交
                        </Button>
                        <Button
                            onClick={() => changeOpSubmit(2)}
                            style={{ marginLeft: 20 }}
                            type="primary"
                            htmlType="submit"
                        >
                            导出
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
            <Table
                loading={isLoading}
                rowKey={columns => columns.schoolOrdersRefundId}
                pagination={paginationProps}
                columns={[
                    {
                        title: '售后编号',
                        dataIndex: 'schoolOrdersRefundSn',
                        key: 'schoolOrdersRefundSn',
                        align: 'center',
                        width: 200,
                        fixed: 'left',
                    },
                    {
                        title: '订单编号',
                        dataIndex: 'schoolOrdersSn',
                        key: 'schoolOrdersSn',
                        align: 'center',
                        width: 200,
                    },
                    {
                        title: '专业',
                        dataIndex: 'majorName',
                        key: 'majorName',
                        align: 'center',
                        width: 200,
                    },
                    {
                        title: '退款金额',
                        dataIndex: 'schoolOrdersRefundMoney',
                        key: 'schoolOrdersRefundMoney',
                        align: 'center',
                        width: 150,
                    },
                    {
                        title: '退款原因',
                        dataIndex: 'schoolOrdersRefundReason',
                        key: 'schoolOrdersRefundReason',
                        align: 'center',
                        width: 200,
                    },
                    {
                        title: '退款说明',
                        dataIndex: 'schoolOrdersRefundMessage',
                        key: 'schoolOrdersRefundMessage',
                        align: 'center',
                        width: 200,
                    },
                    {
                        title: '退款凭证',
                        dataIndex: 'schoolOrdersRefundPic',
                        key: 'schoolOrdersRefundPic',
                        align: 'center',
                        width: 200,
                        render(value) {
                            return (
                                <Image.PreviewGroup>
                                    {value ? value?.split(",")?.map((item: any) =>
                                        <Image src={imgUrl + item} style={{ width: "80px", height: "80px", marginRight: "5px" }}></Image>
                                    ) : ''}
                                </Image.PreviewGroup>
                            );
                        }
                    },
                    {
                        title: '联系人',
                        dataIndex: 'schoolOrdersRefundContactName',
                        key: 'schoolOrdersRefundContactName',
                        align: 'center',
                        width: 200,
                        render: (values, schoolOrdersRefund) => (
                            <div>
                                {values ? <div>{values}</div> : ''}
                                {schoolOrdersRefund.schoolOrdersRefundContactTel ? <div>{schoolOrdersRefund.schoolOrdersRefundContactTel}</div> : ''}
                            </div>
                        )
                    },
                    {
                        title: '用户',
                        dataIndex: 'memberNickname',
                        key: 'memberNickname',
                        align: 'center',
                        width: 150,
                    },
                    {
                        title: '学校',
                        dataIndex: 'schoolName',
                        key: 'schoolName',
                        align: 'center',
                        width: 200,
                    },
                    {
                        title: '机构',
                        dataIndex: 'agencyName',
                        key: 'agencyName',
                        align: 'center',
                        width: 200,
                    },
                    {
                        title: '咨询师',
                        dataIndex: 'plannerName',
                        key: 'plannerName',
                        align: 'center',
                        width: 200,
                    },
                    {
                        title: '地区',
                        dataIndex: 'provinceAreaName',
                        key: 'provinceAreaName',
                        align: 'center',
                        width: 200,
                        render: (values, schoolOrdersRefund) => (
                            values || schoolOrdersRefund.cityAreaName ? values + schoolOrdersRefund.cityAreaName : '-'
                        )
                    },
                    {
                        title: '售后状态',
                        dataIndex: 'schoolOrdersRefundStateValue',
                        key: 'schoolOrdersRefundStateValue',
                        align: 'center',
                        width: 150,
                    },
                    {
                        title: '申请时间',
                        dataIndex: 'schoolOrdersRefundAddTime',
                        key: 'schoolOrdersRefundAddTime',
                        align: 'center',
                        width: 250,
                        render: (values) => (
                            values ? dayjs(values).format('YYYY-MM-DD hh:mm:ss') : '-'
                        )
                    },
                    {
                        title: '操作',
                        key: 'action',
                        align: 'center',
                        render: (value, schoolOrdersRefund) => (
                            schoolOrdersRefund.schoolOrdersRefundState === 10 ? (
                                <Space size="middle">
                                    <a onClick={() => agreeSchoolOrdersRefund(schoolOrdersRefund.schoolOrdersRefundId)}>
                                        同意
                                    </a>
                                    <a onClick={() => showRejectModal(schoolOrdersRefund.schoolOrdersRefundId)}>
                                        驳回
                                    </a>
                                </Space>
                            ) : ('')
                        ),
                        width: 150,
                        fixed: 'right',
                    }
                ]}
                dataSource={list || []}
                scroll={{ x: 3050 }}
            />
            <ErrorBox error={error} />
            <Modal
                confirmLoading={submitRejectLoading}
                centered
                bodyStyle={{ height: '100', overflowY: 'auto' }}
                destroyOnClose={true}
                width={500}
                title="售后驳回"
                visible={rejectModalVisible}
                onOk={handleRejectOk}
                onCancel={handleRejectCancel}>
                <Col>
                    <Form form={form}>
                        <Form.Item
                            label='售后编号'
                            name='schoolOrdersRefundSn'
                        >
                            <Input readOnly />
                        </Form.Item>
                        <Form.Item
                            label='驳回原因'
                        >
                            <Input onChange={handleRejectReason} placeholder={"输入驳回原因"} />
                        </Form.Item>
                    </Form>
                </Col>
            </Modal>
        </div>
    )
}


