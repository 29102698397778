/* eslint-disable jsx-a11y/anchor-is-valid */
import {
  Button,
  Col,
  message,
  Modal,
  Row,
  Space,
  Table,
  Tag,
  Select,
  Card,
  Form,
  Input,
  DatePicker,
} from "antd";
import { Link } from "react-router-dom";
import { ErrorBox } from "../../components/lib";
import {
  useSchoolArticleList,
  useDeleteSchoolArticle,
} from "../../services/school-article-service";
import { useDebounce, useDocumentTitle } from "../../utils";
import { useSchoolArticleSearchParams } from "./util";
import { useSchoolListAll } from "../../services/school-service";
import { useTrendsTagListAll } from "../../services/trends-tag-service";
import { useState } from "react";
import dayjs from "dayjs";
const { Option } = Select;
const { RangePicker } = DatePicker;

export const SchoolArticleListPage = () => {
  useDocumentTitle("学校资讯列表", true);
  const [param, setParam] = useSchoolArticleSearchParams();
  const {
    isLoading,
    error,
    totalElements,
    data: list,
  } = useSchoolArticleList(useDebounce(param, 200));

  //分页
  const paginationProps = {
    defaultCurrent: 1,
    pageSize: 10,
    total: totalElements,
    onChange: (current: number) =>
      setParam({
        ...param,
        pageNum: current - 1,
      }),
  };

  //时间
  const [schoolArticleAddTimeStart, setSchoolArticleAddTimeStart] =
    useState("");
  const [schoolArticleAddTimeEnd, setSchoolArticleAddTimeEnd] = useState("");
  const handleSelectTime = (value: any, dateString: any) => {
    setSchoolArticleAddTimeStart(dateString[0]);
    setSchoolArticleAddTimeEnd(dateString[1]);
  };

  //学校
  const { data: schoolList } = useSchoolListAll();
  const schoolOptions = schoolList?.data?.map((item: any) => (
    <Option value={item.schoolId}>{item.schoolName}</Option>
  ));

  //动态标签
  const { data: trendsTagList } = useTrendsTagListAll(1);
  const trendsTagOptions = trendsTagList?.data?.map((item: any) => (
    <Option value={item.trendsTagId}>{item.trendsTagName}</Option>
  ));

  const onFinish = (values: any) => {
    setParam({
      ...param,
      schoolArticleTitle: values.schoolArticleTitle,
      schoolArticleType: values.schoolArticleType,
      schoolId: values.schoolId,
      trendsTagId: values.trendsTagId,
      schoolArticleAddTimeStart,
      schoolArticleAddTimeEnd,
      pageNum: null,
    });
  };

  //删除事件
  const { mutateAsync: deleteMutateAsync } = useDeleteSchoolArticle();
  const confirmDeleteSchoolArticle = (schoolArticleId: number) => {
    Modal.confirm({
      title: "确定删除这条数据吗?",
      content: "点击确定删除",
      okText: "确定",
      onOk(): any {
        return new Promise((resolve, reject) => {
          deleteMutateAsync({ schoolArticleId }).then((data) => {
            if (data.status === 0) {
              message.info("删除成功");
              Modal.destroyAll();
            } else {
              message.error(data.msg);
            }
          });
        }).catch(() => console.log("Oops errors!"));
      },
      cancelText: "取消",
    });
  };
  //分页
  return (
    <div>
      <Row style={{ marginBottom: 24 }}>
        <Col span={12}>
          <Link to={"/xuexiao/school-manage/school-article/add"}>
            <Button type="primary">新建</Button>
          </Link>
        </Col>
        <Col span={12} style={{ textAlign: "right" }}></Col>
      </Row>
      <Card style={{ backgroundColor: "#f2f3f5", margin: "24px 0 24px 0" }}>
        <Form onFinish={onFinish}>
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item name="schoolArticleTitle" label="资讯标题">
                <Input placeholder="请输入资讯标题" allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="schoolId" label="所属学校">
                <Select
                  placeholder="请选择所属学校"
                  showSearch
                  allowClear
                  optionFilterProp="children"
                >
                  {schoolOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="schoolArticleType" label="资讯类型">
                <Select
                  placeholder="请选择资讯类型"
                  showSearch
                  allowClear
                  optionFilterProp="children"
                >
                  <Option value={1}>简章</Option>
                  <Option value={2}>政策</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item name="trendsTagId" label="动态标签">
                <Select
                  placeholder="请选择动态标签"
                  showSearch
                  allowClear
                  optionFilterProp="children"
                >
                  {trendsTagOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="schoolArticleAddTimeRange" label="创建时间">
                <RangePicker
                  onChange={handleSelectTime}
                  showTime
                  format="YYYY-MM-DD HH:mm:ss"
                  style={{ width: "100%" }}
                  allowClear
                />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item style={{ marginBottom: 0 }}>
            <Button
              onClick={onFinish}
              style={{ marginLeft: 70 }}
              type="primary"
              htmlType="submit"
            >
              提交
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <Table
        loading={isLoading}
        rowKey={(columns) => columns.schoolArticleId}
        pagination={paginationProps}
        columns={[
          {
            title: "ID",
            dataIndex: "schoolArticleId",
            key: "schoolArticleId",
            align: "center",
            fixed: "left",
            width: 100,
          },
          {
            title: "标题",
            dataIndex: "schoolArticleTitle",
            key: "schoolArticleTitle",
            align: "center",
            ellipsis: true,
            width: 300,
          },
          {
            title: "类型",
            dataIndex: "schoolArticleType",
            key: "schoolArticleType",
            align: "center",
            width: 100,
            render: (value) =>
              value === 1 ? "简章" : value === 2 ? "政策" : "-",
          },
          {
            title: "学校",
            dataIndex: "schoolName",
            key: "schoolName",
            align: "center",
            width: 200,
          },
          {
            title: "标签",
            dataIndex: "trendsTagNameList",
            key: "trendsTagNameList",
            align: "center",
            width: 250,
            render: (value) =>
              value?.map((item: string, index: number) => {
                return (
                  <Tag key={"tag" + index} style={{ marginTop: 8 }}>
                    {item}
                  </Tag>
                );
              }),
          },
          {
            title: "排序",
            dataIndex: "schoolArticleSort",
            key: "schoolArticleSort",
            align: "center",
            width: 100,
          },
          {
            title: "状态",
            dataIndex: "schoolArticleShow",
            key: "schoolArticleShow",
            align: "center",
            width: 100,
            render: (value) => <span>{value ? "显示" : "不显示"}</span>,
          },
          {
            title: "创建时间",
            dataIndex: "schoolArticleAddTime",
            key: "schoolArticleAddTime",
            align: "center",
            width: 200,
            render: (value) =>
              value ? dayjs(value).format("YYYY-MM-DD HH:mm:ss") : "-",
          },
          {
            title: "操作",
            key: "action",
            align: "center",
            fixed: "right",
            width: 150,
            render: (value, schoolArticle) => (
              <Space size="middle">
                <Link
                  to={
                    "/xuexiao/school-manage/school-article/update/" +
                    schoolArticle.schoolArticleId
                  }
                >
                  编辑
                </Link>
                <a
                  onClick={() =>
                    confirmDeleteSchoolArticle(schoolArticle.schoolArticleId)
                  }
                >
                  删除
                </a>
              </Space>
            ),
          },
        ]}
        dataSource={list || []}
        scroll={{ x: 1500 }}
      />
      <ErrorBox error={error} />
    </div>
  );
};
