import { Breadcrumb, Button, Col, Divider, Form, Input, message, Radio, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useAddStoreAccount } from "../../services/store-account-service";
import { useDocumentTitle } from "../../utils";

export const StoreAccountAddPage = () => {
  //标题
  useDocumentTitle("新建账号", true);
  //API地址
  const { pathname } = useLocation();
  const storeId = pathname.match(/shop\/store-manage\/storeAccount\/add\/(\d+)/)?.[1];
  //布局
  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 7 },
  };
  const tailLayout = {
    wrapperCol: { offset: 3 },
  };
  //表单
  const [form] = useForm();
  //新建
  const useMutateAddStoreAccount = useAddStoreAccount;
  const { mutateAsync } = useMutateAddStoreAccount();
  //提交表单
  const [submitLoading, setSubmitLoading] = useState(false);
  const onFinish = (values: any) => {
    setSubmitLoading(true);
    mutateAsync({ ...values, storeId }).then((res) => {
      if(res.status === 0) {
        message.info("新建成功");
        window.location.href = "/#/shop/store-manage/storeAccount/list/" + storeId;
      } else {
        message.info(res.msg);
        setSubmitLoading(false);
      }
    });
  };
  return (
    <div>
      <Breadcrumb style={{ marginBottom: 24 }}>
        <Breadcrumb.Item>
          <Link to={'/shop/store-manage/list'}>门店列表</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>
          <Link to={'/shop/store-manage/storeAccount/list/' + storeId}>账号列表</Link>
        </Breadcrumb.Item>
        <Breadcrumb.Item>新建账号</Breadcrumb.Item>
      </Breadcrumb>
      <Row>
        <Col span={12}>
          <Link to={"/shop/store-manage/storeAccount/list/" + storeId}>
            <Button type="primary">账号列表</Button>
          </Link>
        </Col>
      </Row>
      <Divider style={{ marginBottom: 24 }} />
      <Form
        form={form}
        {...layout}
        onFinish={onFinish}
        initialValues={{
          storeAccountIsSuper: true,
        }}
      >
        <Form.Item
          label={"账号名称"}
          name={"storeAccountName"}
          rules={[{ required: true, message: "账号名称必填" }]}
        >
          <Input placeholder={"请输入账号名称"} />
        </Form.Item>

        <Form.Item
          label={"账号密码"}
          name={"storeAccountPassword"}
          rules={[{ required: true, message: "账号密码必填" }]}
        >
          <Input.Password placeholder={"请输入账号密码"} />
        </Form.Item>

        <Form.Item
          label={"是否超级管理员"}
          name={"storeAccountIsSuper"}
          rules={[{ required: true, message: "超级管理员必选" }]}
          style={{ textAlign: "left" }}
        >
          <Radio.Group>
            <Radio defaultChecked value={true}>
              是
            </Radio>
            <Radio value={false}>否</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item {...tailLayout} style={{ textAlign: "left" }}>
          <Button loading={submitLoading} type="primary" htmlType="submit">
            提交
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
