import { Button, Modal } from "antd";
import ReactPlayer from 'react-player'

const videoUrl = process.env.REACT_APP_VIDEO_URL;

const VideoPlayer = (props: any) => {
    let { video, modalVisible, setModalVisible } = props;

    const handleCancel = () => {
        setModalVisible(false);
    };

    return (
        <Modal
            title="视频播放"
            forceRender={true}
            visible={modalVisible}
            onCancel={handleCancel}
            width={500}
            destroyOnClose={true}
            footer={
                <Button type={'primary'} onClick={handleCancel}>关闭</Button>
            }
        >
            <ReactPlayer
                muted
                playing={true}
                controls={true}
                url={videoUrl + video}
                width={450}
                loop={true}
            />
        </Modal>
    )
}

export default VideoPlayer;