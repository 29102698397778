import { Button, Col, Divider, Form, Input, message, Radio, Row, Select, Upload } from "antd";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAddSubject } from "../../services/mall-subject-service";
import { usePageUrl, usePageUrlList } from "../../services/page-url-service";
import { useDocumentTitle } from "../../utils"
import { getToken } from "../../utils/auth-provider";
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css';
import { useAreaListCity } from "../../services/area-service";
const { Option } = Select;

export const MallSubjectAddPage = () => {
    //标题
    useDocumentTitle('新建专题', true)
    //API地址
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    //富文本
    const [controls, setControls] = useState<any>([
        'undo', 'redo', 'separator',
        'font-size', 'line-height', 'letter-spacing', 'separator',
        'text-color', 'bold', 'italic', 'underline', 'strike-through', 'separator',
        'superscript', 'subscript', 'remove-styles', 'emoji', 'separator', 'text-indent', 'text-align', 'separator',
        'headings', 'list-ul', 'list-ol', 'blockquote', 'code', 'separator',
        'link', 'separator', 'hr', 'separator',
        'media', 'separator',
        'clear'
    ]);
    //是否跳转外链
    const [externalLinksShow, setExternalLinksShow] = useState(false);
    function radioHandleChange(e: any) {
        setExternalLinksShow(e.target.value);
    }
    //准备跳转链接
    const [propertyShow, setPropertyShow] = useState(false);
    const [propertyName, setPropertyName] = useState("参数");
    const { data: pageUrlList } = usePageUrlList();
    const options = pageUrlList?.map((item: any, value: any) =>
        <Option value={item.pageUrlId} key={item.pageUrlId}>
            {item.pageUrlName}
        </Option>
    );
    //option选中与非选中
    const { mutateAsync: mutateAsyncPageUrl } = usePageUrl();
    function optionHandleChange(pageUrlId: number) {
        mutateAsyncPageUrl({ pageUrlId }).then((data) => {
            const pageUrlFlag = data.data.pageUrlFlag
            const pageUrlPropertyName = data.data.pageUrlPropertyName
            setPropertyShow(pageUrlFlag)
            setPropertyName(pageUrlPropertyName)
        });
    }
    //表单
    const [form] = useForm();
    //图片上传
    const [uploadLoading, setUploadLoading] = useState(false);
    const [imageUrl, setImageUrl] = useState("");
    const uploadButton = (
        <div>
            {uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>上传图片</div>
        </div>
    );
    const beforeUpload = (file: any) => {
        setImageUrl("");
        setUploadLoading(true);
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            setUploadLoading(false);
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            setUploadLoading(false);
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }
    const handleChange = (info: any) => {
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            setUploadLoading(false);
            setImageUrl(info?.file?.response?.data?.realPath);
            const relativePath = info?.file?.response?.data?.relativePath
            form.setFieldsValue({
                subjectPic: relativePath
            })
        }
    };
    const normFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };
    //富文本
    const [subjectContent, setSubjectContent] = useState("");
    const handleEditorChange = (editorState: any) => {
        setSubjectContent(editorState.toHTML())
    }
    //富文本上传
    const myUploadFn = (param: any) => {
        const serverURL = apiUrl + '/upload/braftEditorImg'
        const xhr = new XMLHttpRequest
        const fd = new FormData()
        const successFn = (response: any) => {
            // 假设服务端直接返回文件上传后的地址
            // 上传成功后调用param.success并传入上传后的文件地址
            param.success({
                url: xhr.responseText,
                meta: {
                    loop: true, // 指定音视频是否循环播放
                    autoPlay: true, // 指定音视频是否自动播放
                    controls: true, // 指定音视频是否显示控制栏
                }
            })
        }
        const progressFn = (event: any) => {
            // 上传进度发生变化时调用param.progress
            param.progress(event.loaded / event.total * 100)
        }
        const errorFn = (response: any) => {
            // 上传发生错误时调用param.error
            param.error({
                msg: 'unable to upload.'
            })
        }
        xhr.upload.addEventListener("progress", progressFn, false)
        xhr.addEventListener("load", successFn, false)
        xhr.addEventListener("error", errorFn, false)
        xhr.addEventListener("abort", errorFn, false)
        fd.append('file', param.file)
        xhr.open('POST', serverURL, true)
        xhr.setRequestHeader("name", "imageFile");
        xhr.setRequestHeader("Authorization", "Bearer " + token);
        xhr.send(fd)
    }
    //新建
    const useMutateAddSubject = useAddSubject;
    const { mutateAsync, error } = useMutateAddSubject();
    //提交表单
    const [submitLoading, setSubmitLoading] = useState(false);
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...values, subjectContent }).then(() => {
            message.info('新建成功');
            window.location.href = "/#/shop/dress-manage/mall-subject/list";
        });
    };
    //准备区域数据
    const { data: areaList } = useAreaListCity();
    const areaListOptions = areaList?.data?.map((item: any, value: any) => (
        <Select.Option value={item.areaId} key={item.areaId}>
            {item.areaName}
        </Select.Option>
    ));
    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/shop/dress-manage/mall-subject/list"}>
                        <Button type="primary">专题列表</Button>
                    </Link>
                </Col>
            </Row>
            <Divider style={{ marginBottom: 24 }} />
            <Form
                form={form}
                {...layout}
                onFinish={onFinish}
                initialValues={{
                    subjectSort: 0,
                    subjectShow: true,
                    subjectExternalLinks: false
                }}>
                <Form.Item
                    label={"专题标题"}
                    name={"subjectTitle"}
                    rules={[{ required: true, message: "专题标题必填" }]}
                >
                    <Input placeholder={"请输入专题标题"} />
                </Form.Item>

                <Form.Item
                    name={"uploadPic"}
                    label={"专题图片"}
                    valuePropName="fileList"
                    getValueFromEvent={normFile}
                    rules={[{ required: true, message: "专题图片必传" }]}
                    style={{ textAlign: 'left' }}
                >
                    <Upload
                        name="imageFile"
                        listType="picture-card"
                        className="avatar-uploader"
                        showUploadList={false}
                        action={apiUrl + "/upload/img"}
                        beforeUpload={beforeUpload}
                        onChange={handleChange}
                        headers={{ "Authorization": "Bearer " + token }}
                    >
                        {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                    </Upload>
                </Form.Item>

                <Form.Item hidden
                    label={"专题图片"}
                    name={"subjectPic"}
                    rules={[{ required: true, message: "专题图片必传" }]}
                >
                    <Input />
                </Form.Item>

                <Form.Item
                    label={"是否跳转外链"}
                    name={"subjectExternalLinks"}
                    rules={[{ required: true, message: "跳转外链必选" }]}
                    style={{ textAlign: 'left' }}
                >
                    <Radio.Group onChange={radioHandleChange}>
                        <Radio value={true}>是</Radio>
                        <Radio defaultChecked value={false}>否</Radio>
                    </Radio.Group>
                </Form.Item>

                {externalLinksShow ? <Form.Item
                    label={"跳转链接"}
                    name={"pageUrlId"}
                    rules={[{ required: true, message: "跳转链接必选" }]}
                >
                    <Select
                        showSearch
                        placeholder="请选择跳转链接"
                        optionFilterProp="children"
                        style={{ textAlign: 'left' }}
                        onChange={optionHandleChange}
                    >
                        {options}
                    </Select>
                </Form.Item> : ''}

                {(propertyShow && externalLinksShow) ? <Form.Item
                    label={propertyName}
                    name={"pageUrlPropertyValue"}
                    rules={[{ required: true, message: "链接参数必传" }]}
                >
                    <Input placeholder={"请输入链接参数"} />
                </Form.Item> : ''}


                <Form.Item
                    label={"专题排序"}
                    name={"subjectSort"}
                    rules={[{ required: true, message: "专题排序必填" }]}
                >
                    <Input placeholder={"请输入专题排序"} />
                </Form.Item>

                <Form.Item
                    label={"是否显示"}
                    name={"subjectShow"}
                    rules={[{ required: true, message: "专题显示必选" }]}
                    style={{ textAlign: 'left' }}
                >
                    <Radio.Group>
                        <Radio defaultChecked value={true}>显示</Radio>
                        <Radio value={false}>不显示</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item
                    label={"显示区域"}
                    name={"areaId"}
                    rules={[{ required: true, message: "显示区域必选" }]}
                >
                    <Select
                        showSearch
                        placeholder="请选择显示区域"
                        optionFilterProp="children"
                        style={{ textAlign: "left", width: 380 }}
                    >
                        {areaListOptions}
                    </Select>
                </Form.Item>
                <Form.Item
                    label={"专题详情"}
                    name={"subjectContent"}
                    style={{ marginTop: 24 }}
                >
                    <BraftEditor
                        style={{ border: "1px solid #f0f0f0", width: "800px" }}
                        onChange={handleEditorChange}
                        media={{ uploadFn: myUploadFn }}
                    />
                </Form.Item>
                <Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                    <Button loading={submitLoading} type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

