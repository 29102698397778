import { useMutation, useQuery, useQueryClient } from "react-query";
import { TrendsTag } from "../interfaces/trends-tag";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useTrendsTagList = (param?: Partial<TrendsTag>) => {
    const client = useHttp();
    const result = useQuery<any>(['trendsTagPage', param], () => client('trendsTag', { data: cleanObject(param) }))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return { ...result, totalElements, data };
}

export const useEditTrendsTag = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<TrendsTag>) => client(`trendsTag`, {
            method: 'PUT',
            data: params
        }), {
        onSuccess: () => queryClient.invalidateQueries('trendsTagPage')
    }
    )
}

export const useAddTrendsTag = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<TrendsTag>) => client(`trendsTag`, {
            data: params,
            method: 'POST'
        }), {
        onSuccess: () => queryClient.invalidateQueries('trendsTagPage')
    }
    )
}

export const useDeleteTrendsTag = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ trendsTagId }: { trendsTagId: number }) =>
            client(`trendsTag/${trendsTagId}`, {
                method: "DELETE",
            }), {
        onSuccess: () => queryClient.invalidateQueries('trendsTagPage')
    }
    );
};

export const useTrendsTag = (trendsTagId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['trendsTag', { trendsTagId }],
        () => client(`trendsTag/${trendsTagId}`),
        {
            enabled: Boolean(trendsTagId)
        }
    )
}

export const useTrendsTagListAll = (trendsTagType: number) => {
    const client = useHttp();
    return useQuery<any>(["trendsTagList", { trendsTagType }], () =>
        client(`trendsTag/list?trendsTagType=${trendsTagType}`)
    );
};