import { useMutation, useQuery, useQueryClient } from "react-query";
import { CourseShowcaseCourse } from "../interfaces/course-showcase-course";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";


export const useNoCourseShowcaseCourseList = (param?: Partial<any>) => {
    const client = useHttp();
    const result = useQuery<any>(['noCourseShowcaseCoursePage',param], () => client('courseShowcaseCourse/course', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useCourseShowcaseCourseList = (param?: Partial<any>) => {
    const client = useHttp();
    const result = useQuery<any>(['courseShowcaseCoursePage', param], () => client('courseShowcaseCourse', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditCourseShowcaseCourse = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<CourseShowcaseCourse>) => client(`courseShowcaseCourse`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('courseShowcaseCoursePage')
        }
    )
}

export const useAddCourseShowcaseCourse = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<CourseShowcaseCourse>) => client(`courseShowcaseCourse`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('noCourseShowcaseCoursePage')
        }
    )
}

export const useDeleteCourseShowcaseCourse = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ courseShowcaseCourseIds }: { courseShowcaseCourseIds: number[]}) =>
            client(`courseShowcaseCourse`, {
            method: "DELETE",
            data:{courseShowcaseCourseIds}
        }), {
            onSuccess: () => queryClient.invalidateQueries('courseShowcaseCoursePage')
        }
    );
};

export const useCourseShowcaseCourse = (courseShowcaseCourseId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['courseShowcaseCourse', {courseShowcaseCourseId}],
        () => client(`courseShowcaseCourse/${courseShowcaseCourseId}`),
        {
            enabled: Boolean(courseShowcaseCourseId)
        }
    )
}