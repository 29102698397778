import { useMutation, useQuery, useQueryClient } from "react-query";
import { Goods } from "../interfaces/goods";
import { MallFloorGoods } from "../interfaces/mall-floor-goods";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useFloorGoodsList = (param?: Partial<Goods>) => {
    const client = useHttp();
    const result = useQuery<any>(['floorGoodsList', param], () => client('mallFloorGoods/listFloorGoods', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useFloorGoodsListNo = (param?: Partial<any>) => {
    const client = useHttp();
    const result = useQuery<any>(['floorGoodsListNo', param], () => client('mallFloorGoods/listNoInFloorGoods', {data: cleanObject(param)}),{enabled:Boolean(param?.floorId)})
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useAddFloorGoods = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<MallFloorGoods>) => client(`mallFloorGoods/add`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('floorGoodsListNo')
        }
    )
}

export const useDeleteFloorGoods = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ goodsId, floorId }: { goodsId: number, floorId: number}) =>
            client(`mallFloorGoods/delete?goodsId=${goodsId}&floorId=${floorId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('floorGoodsList')
        }
    );
};