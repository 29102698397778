import { useMemo } from "react";
import { useUrlQueryParam } from "../../utils/url";

export const usePlannerEvaSearchParams = () => {
    const [param, setParam] = useUrlQueryParam(["pageNum", "pageSize", "plannerId", "plannerEvaStatType", "plannerEvaTimeStart", "plannerEvaTimeEnd", "plannerEvaEasilyTagId", "memberNickname"]);
    return [
        useMemo(() => ({ ...param, pageNum: Number(param.pageNum) || undefined, pageSize: Number(param.pageSize) || undefined, plannerId: Number(param.plannerId) || undefined,
            plannerEvaStatType: Number(param.plannerEvaStatType) || undefined, plannerEvaEasilyTagId: Number(param.plannerEvaEasilyTagId) || undefined }), [param]),
        setParam
    ] as const
}