import { Button, Col, Menu, message, Modal, Row, Table } from "antd"
import { Link, useLocation } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useMemberCouponList } from "../../services/member-coupon-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useMemberCouponSearchParams } from "./util"
import { useEffect } from "react"
import dayjs from "dayjs"
import { useAuth } from "../../context/auth-context"

export const MemberCouponListUsePage = () => {
    useDocumentTitle('优惠券领取列表-已使用', true)
    const apiUrl = process.env.REACT_APP_API_URL;
    const data = useAuth();
    // const shopId = data?.shopAccount?.shopId
    const { pathname } = useLocation();
    const couponId = pathname.match(/shop\/market-manage\/coupon\/member\/listUse\/(\d+)/)?.[1]
    const [param, setParam] = useMemberCouponSearchParams()
    useEffect(() => {
        setParam({
            couponId: couponId,
            couponUse: true
        })
    }, [couponId]);
    const {isLoading, error, totalElements, data: list} = useMemberCouponList(useDebounce(param, 200))
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) => 
            setParam({
            ...param,
            pageNum: (current -1),
        })
    }
    const exportMemberCoupon = () => {
        Modal.confirm({
            title: '确定导出当前列表数据吗?',
            content: '点击确定导出',
            okText: '确定',
            onOk(): any {
                return new Promise((resolve, reject) => {
                    window.location.href =
                        apiUrl +
                        `/exportMemberCoupon?couponId=${couponId}&couponUse=true`
                    message.success('导出成功')
                    Modal.destroyAll()
                }).catch(() => console.log('Oops errors!'))
            },
            cancelText: '取消',
        })
    }
    return (
        <div>
            <Row style={{marginBottom: 24}}>
                <Col span={12}>
                    <Link to={"/shop/market-manage/coupon/list"}>
                        <Button type="primary">优惠券列表</Button>
                    </Link>
                    <Button onClick={exportMemberCoupon} style={{ marginLeft: 10 }} type='primary'>
                        导出
                    </Button>
                </Col>
                <Col span={12} style={{textAlign:'right'}}>
                    
                </Col>
            </Row>
            <Menu style={{ marginBottom: 24 }} mode="horizontal" selectedKeys={['memberCouponListUse']}>
                <Menu.Item key="memberCouponListAll">
                    <Link to={"/shop/market-manage/coupon/member/list/" + couponId}>
                        全部
                    </Link>
                </Menu.Item>
                <Menu.Item key="memberCouponListUse">
                    <Link to={"/shop/market-manage/coupon/member/listUse/" + couponId}>
                        已使用
                    </Link>
                </Menu.Item>
                <Menu.Item key="memberCouponListNoUse">
                    <Link to={"/shop/market-manage/coupon/member/listNoUse/" + couponId}>
                        未使用
                    </Link>
                </Menu.Item>
            </Menu>
            <Table 
                loading = { isLoading }
                rowKey={ columns => columns.memberCouponId } 
                pagination = { paginationProps } 
                columns = {[
                {
                    title: '优惠券标题',
                    dataIndex: 'couponTitle',
                    key: 'couponTitle',
                },
                { 
                    title: '用户ID', 
                    dataIndex: 'memberId', 
                    key: 'memberId' 
                },
                { 
                    title: '用户昵称', 
                    dataIndex: 'memberNickname', 
                    key: 'memberNickname' 
                },
                { 
                    title: '领取时间', 
                    dataIndex: 'couponDrawTime', 
                    key: 'couponDrawTime',
                    render(value, memberCoupon) {
                        return <span>
                            {memberCoupon.couponDrawTime ? dayjs(memberCoupon.couponDrawTime).format('YYYY-MM-DD'):'无'}
                        </span>
                    }
                },
                { 
                    title: '是否使用', 
                    dataIndex: 'couponUse', 
                    key: 'couponUse',
                    render(value, memberCoupon) {
                        return <span>
                            {memberCoupon.couponUse === true ? '已使用':'未使用'}<br/>
                        </span>
                    },
                },
                { 
                    title: '使用时间', 
                    dataIndex: 'couponUseTime', 
                    key: 'couponUseTime',
                    render(value, memberCoupon) {
                        return <span>
                            {memberCoupon.couponUseTime ? dayjs(memberCoupon.couponUseTime).format('YYYY-MM-DD'):'无'}
                        </span>
                    }
                }
            ]}
            dataSource={ list || [] }  />
            <ErrorBox error={ error } />
        </div>
    )
}