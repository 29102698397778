import { Button, Col, message, Modal, Row, Space, Table, Image, Card, Form, Input } from "antd"
import Search from "antd/lib/input/Search"
import { Link } from "react-router-dom"
import dayjs from "dayjs"
import { ErrorBox } from "../../components/lib"
import { useCourseList, useDeleteCourse, useOffCourse, useUpCourse } from "../../services/course-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useCourseSearchParams } from "./util"

export const CourseListPage = () => {
    useDocumentTitle('课程列表', true)
    const imgUrl = process.env.REACT_APP_IMG_URL;
    const [param, setParam] = useCourseSearchParams()
    const {isLoading, error, totalElements, data: list} = useCourseList(useDebounce(param, 200))

	//分页
	const paginationProps = {
	  showSizeChanger: true,
	  onShowSizeChange: (current: number, pageSize: number) => {
		setParam({
		  ...param,
		  pageNum: current - 1,
		  pageSize,
		});
	  },
	  defaultCurrent: 1,
	  pageSize: param.pageSize,
	  total: totalElements,
	  onChange: (current: number, pageSize: number) =>
		setParam({
		  ...param,
		  pageNum: current - 1,
		  pageSize,
		}),
	};
     //搜索
  const onFinish = (values: any) => {
    setParam({
      ...param,
      courseName: values.courseName,
      pageNum: null,
    });
  };
  //删除事件
  const { mutateAsync:deleteAsync } = useDeleteCourse();
  const confirmDeleteCourse = (courseId: number) => {
      Modal.confirm({
          title: "确定删除这条数据吗?",
          content: "点击确定删除",
          okText: "确定",
          onOk(): any {
              return new Promise((resolve, reject) => {
                deleteAsync({ courseId }).then((data) => {
                      if (data.status === 0) {
                          message.info('删除成功');
                          Modal.destroyAll();
                        } else {
                          message.error(data.msg)
                        }
                  });
              }).catch(
                  () => console.log('Oops errors!')
              );
          },
          cancelText: "取消"
      });
  };
  //下架事件
  const { mutateAsync:offAsync } = useOffCourse();
  const confirmOffCourse = (courseId: number) => {
      Modal.confirm({
          title: "确定下架这条数据吗?",
          content: "点击确定下架",
          okText: "确定",
          onOk(): any {
              return new Promise((resolve, reject) => {
                offAsync({ courseId }).then((data) => {
                      if (data.status === 0) {
                          message.info('下架成功');
                          Modal.destroyAll();
                        } else {
                          message.error(data.msg)
                        }
                  });
              }).catch(
                  () => console.log('Oops errors!')
              );
          },
          cancelText: "取消"
      });
  };
  //上事件
  const { mutateAsync:upAsync } = useUpCourse();
  const confirmUpCourse = (courseId: number) => {
      Modal.confirm({
          title: "确定上架这条数据吗?",
          content: "点击确定上架",
          okText: "确定",
          onOk(): any {
              return new Promise((resolve, reject) => {
                upAsync({ courseId }).then((data) => {
                      if (data.status === 0) {
                          message.info('上架成功');
                          Modal.destroyAll();
                        } else {
                          message.error(data.msg)
                        }
                  });
              }).catch(
                  () => console.log('Oops errors!')
              );
          },
          cancelText: "取消"
      });
  };
    //分页
    return (
        <div>
            <Row style={{marginBottom: 24}}>
                <Col span={12}>
                    {/* <Link to={"/jigou/course-manage/course/add"}>
                        <Button type="primary">新建</Button>
                    </Link> */}
                </Col>
				<Col span={12} style={{ textAlign: "right" }}>
				  <Search
					placeholder="可搜索课程名称"
					style={{ width: 200 }}
					value={param.courseName}
					onChange={(evt) =>
					  setParam({
						...param,
						courseName: evt.target.value,
						pageNum: null,
					  })
					}
					onSearch={(value) =>
					  setParam({
						...param,
						courseName: value,
						pageNum: null,
					  })
					}
				  />
				</Col>
            </Row>
            <Table 
                loading = { isLoading }
                rowKey={ columns => columns.courseId } 
                pagination = { paginationProps } 
                columns = {[
                {
                    title: 'ID',
                    dataIndex: 'courseId',
                    key: 'courseId',
                    align: 'center',
                    width: 100,
                },

                {
                    title: '课程名称',
                    dataIndex: 'courseName',
                    key: 'courseName',
                    align: 'center',
                },
                {
                    title: '课程名称',
                    dataIndex: 'coursePic',
                    key: 'coursePic',
                    align: 'center',
                    render(value, record, index) {
                        return (
                            <><Image width={120} src={imgUrl + value}></Image></>
                        )
                    },
                },

                {
                    title: '课程评分',
                    dataIndex: 'courseEvaSource',
                    key: 'courseEvaSource',
                    align: 'center',
                },

                {
                    title: '课程类型',
                    dataIndex: 'courseType',
                    key: 'courseType',
                    align: 'center',
                    render: (value) => (
                        <span>{value==1 ? '线上' : '线下'}</span>
                    ),
                },

                {
                    title: '课程分类名称',
                    dataIndex: 'courseClassName',
                    key: 'courseClassName',
                    align: 'center',
                },

                {
                    title: '课程价格',
                    dataIndex: 'coursePrice',
                    key: 'coursePrice',
                    align: 'center',
                },

                {
                    title: '课程点赞人数',
                    dataIndex: 'courseLikeNum',
                    key: 'courseLikeNum',
                    align: 'center',
                },

                {
                    title: '机构名称',
                    dataIndex: 'agencyName',
                    key: 'agencyName',
                    align: 'center',
                },

                {
                    title: '购买人数',
                    dataIndex: 'courseBuyNum',
                    key: 'courseBuyNum',
                    align: 'center',
                },

                {
                    title: '上架状态',
                    dataIndex: 'courseShow',
                    key: 'courseShow',
                    align: 'center',
                    render: (value) => (
                        <span>{value ? '上架' : '下架'}</span>
                    ),
                },

                {
                    title: '备注',
                    dataIndex: 'mark',
                    key: 'mark',
                    align: 'center',
                },


                {
                    title: '操作',
                    key: 'action',
                    align: 'center',
                    render: (value, course) => (
                        <Space size="middle">
                            <Link to={"/jigou/course-manage/course/detail/" + course.courseId}>
                                详情
                            </Link>
                            {course.courseShow?<><a onClick={() => confirmOffCourse(course.courseId)}>
                                下架
                            </a></>:<a onClick={() => confirmUpCourse(course.courseId)}>
                                上架
                            </a>}
                            
                            <a onClick={() => confirmDeleteCourse(course.courseId)}>
                                删除
                            </a>
                        </Space>
                    ),
                    width: 150,
                }
            ]}
            dataSource={ list || [] }  />
            <ErrorBox error={ error } />
        </div>
    )
}