import { useMutation, useQuery, useQueryClient } from "react-query";
import { CourseNavigation } from "../interfaces/course-navigation";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useCourseNavigationList = (param?: Partial<CourseNavigation>) => {
    const client = useHttp();
    const result = useQuery<any>(['courseNavigationPage', param], () => client('courseNavigation', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditCourseNavigation = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<CourseNavigation>) => client(`courseNavigation`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('courseNavigationPage')
        }
    )
}

export const useAddCourseNavigation = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<CourseNavigation>) => client(`courseNavigation`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('courseNavigationPage')
        }
    )
}

export const useDeleteCourseNavigation = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ courseNavigationId }: { courseNavigationId: number}) =>
            client(`courseNavigation/${courseNavigationId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('courseNavigationPage')
        }
    );
};

export const useCourseNavigation = (courseNavigationId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['courseNavigation', {courseNavigationId}],
        () => client(`courseNavigation/${courseNavigationId}`),
        {
            enabled: Boolean(courseNavigationId)
        }
    )
}