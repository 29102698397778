import { useMutation } from "react-query"
import { IdentityAccount } from "../interfaces/identity-account"
import { useHttp } from "../utils/http"

export const useResetIdentityAccount = () => {
    const client = useHttp()
    return useMutation(
        (params: Partial<IdentityAccount>) => client(`identityAccount/reset`, {
            method: 'POST',
            data: params
        })
    )
}