import { useMemo } from "react";
import { useUrlQueryParam } from "../../utils/url";

export const useTabBarSearchParams = () => {
    const [param, setParam] = useUrlQueryParam(["tabBarTitle", "tabBarId", "pageNum"]);
    return [
        useMemo(() => ({ ...param, tabBarId: Number(param.tabBarId) || undefined, pageNum: Number(param.pageNum) || undefined }), [param]),
        setParam
    ] as const
}

export const getTabBarTypeName = (value: number) => {
    let tabBarTypeName = '';
    if (value) {
        if (value === 1) {
            tabBarTypeName = '学校';
        }
        // else if (value === 2) {
        //     tabBarTypeName = '招生计划';
        // } 
        else if (value === 3) {
            tabBarTypeName = '机构';
        } else if (value === 4) {
            tabBarTypeName = '课程';
        } else if (value === 5) {
            tabBarTypeName = '规划师';
        } else if (value === 6) {
            tabBarTypeName = '企业';
        } else if (value === 7) {
            tabBarTypeName = '招聘';
        } else if (value === 8) {
            tabBarTypeName = '商家';
        } else if (value === 9) {
            tabBarTypeName = '商品';
        } else if (value === 10) {
            tabBarTypeName = '动态';
        } 
        // else if (value === 11) {
        //     tabBarTypeName = '公告';
        // }
    }
    return tabBarTypeName;
}

export const tabBarTypeList = [
    {
        type: 1,
        name: '学校',
        url: ''
    },
    // {
    //     type: 2,
    //     name: '招生计划',
    //     url: ''
    // },
    {
        type: 3,
        name: '机构',
        url: ''
    },
    {
        type: 4,
        name: '课程',
        url: ''
    },
    {
        type: 5,
        name: '规划师',
        url: ''
    },
    {
        type: 6,
        name: '企业',
        url: ''
    },
    {
        type: 7,
        name: '招聘',
        url: ''
    },
    {
        type: 8,
        name: '商家',
        url: ''
    },
    {
        type: 9,
        name: '商品',
        url: ''
    },
    {
        type: 10,
        name: '动态',
        url: ''
    },
    // {
    //     type: 11,
    //     name: '公告',
    //     url: ''
    // },
]