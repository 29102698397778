import { useMutation, useQuery, useQueryClient } from "react-query";
import { Coupon } from "../interfaces/coupon";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";


export const useCouponList = (param?: Partial<Coupon>) => {
    const client = useHttp();
    const result = useQuery<any>(['couponList', param], () => client('coupon', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useCouponListAll = (param?: Partial<Coupon>) => {
    const client = useHttp();
    const result = useQuery<any>(['couponListAll', param], () => client('coupon/listAll', {data: cleanObject(param)}))
    const data = result.data?.data;
    return {...result, data };
}

export const useEditCoupon = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Coupon>) => client(`coupon`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('couponList')
        }
    )
}

export const useAddCoupon = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Coupon>) => client(`coupon`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('couponList')
        }
    )
}

export const useDeleteCoupon = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ couponId }: { couponId: number}) =>
            client(`coupon/${couponId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('couponList')
        }
    );
};

export const useCoupon = (couponId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['coupon', {couponId}],
        () => client(`coupon/${couponId}`),
        {
            enabled: Boolean(couponId)
        }
    )
}