import { Button, Col, message, Modal, Row, Space, Table } from "antd"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useWechatMenuList, useDeleteWechatMenu, useSyncWechatMenu } from "../../services/wechat-menu-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useWechatMenuSearchParams } from "./util"

export const WechatMenuListPage = () => {
    useDocumentTitle('公众号菜单', true)
    const [param, setParam] = useWechatMenuSearchParams()
    const { isLoading, error, totalElements, data: list } = useWechatMenuList(useDebounce(param, 200))
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) =>
            setParam({
                ...param,
                pageNum: (current - 1),
            })
    }
    //同步事件
    const useMutateSyncWechatMenu = useSyncWechatMenu;
    const { mutateAsync: syncMutateAsync } = useMutateSyncWechatMenu();
    const confirmSyncWechatMenu = () => {
        Modal.confirm({
            title: "确定同步公众号吗?",
            content: "点击确定同步",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    syncMutateAsync().then(() => {
                        message.info('同步成功');
                        Modal.destroyAll();
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    //删除事件
    const useMutateDeleteWechatMenu = useDeleteWechatMenu;
    const { mutateAsync } = useMutateDeleteWechatMenu();
    const confirmDeleteWechatMenu = (wechatMenuId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ wechatMenuId }).then(() => {
                        message.info('删除成功');
                        Modal.destroyAll();
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    //分页
    return (
        <div>
            <Row style={{ marginBottom: 24 }}>
                <Col span={12}>
                    <Link to={"/setting/wechat-manage/wechat-menu/add"}>
                        <Button type="primary">新建</Button>
                    </Link>
                    <Button type="primary" style={{ marginLeft: 10 }} onClick={() => confirmSyncWechatMenu()}>
                        同步
                    </Button>
                </Col>
            </Row>
            <Table
                loading={isLoading}
                rowKey={columns => columns.wechatMenuId}
                pagination={paginationProps}
                columns={[
                    {
                        title: 'ID',
                        dataIndex: 'wechatMenuId',
                        key: 'wechatMenuId',
                        width: 150,
                    },
                    {
                        title: '名称',
                        dataIndex: 'wechatMenuName',
                        key: 'wechatMenuName',
                    },
                    {
                        title: '响应类型',
                        dataIndex: 'wechatMenuType',
                        key: 'wechatMenuType',
                        render(value, wechatMenu) {
                            return <span>
                                {wechatMenu.wechatMenuType === 'click' ? '点击' : wechatMenu.wechatMenuType === 'view' ? '网页'
                                    : wechatMenu.wechatMenuType === 'miniprogram' ? '小程序' : '无'}<br />
                            </span>
                        },
                    },
                    {
                        title: '排序',
                        dataIndex: 'wechatMenuSort',
                        key: 'wechatMenuSort',
                    },
                    {
                        title: '操作',
                        key: 'action',
                        render: (value, wechatMenu) => (
                            <Space size="middle">
                                <Link to={"/setting/wechat-manage/wechat-menu/update/" + wechatMenu.wechatMenuId}>
                                    编辑
                                </Link>
                                <a onClick={() => confirmDeleteWechatMenu(wechatMenu.wechatMenuId)}>
                                    删除
                                </a>
                            </Space>
                        ),
                        width: 150,
                    },
                ]}
                dataSource={list || []} />
            <ErrorBox error={error} />
        </div>
    )
}