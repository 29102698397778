import { useQuery } from "react-query";
import { Bill } from "../interfaces/bill";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useBillList = (param?: Partial<Bill>) => {
    const client = useHttp();
    const result = useQuery<any>(['billList', param], () => client('bill', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}