import { useMutation, useQuery, useQueryClient } from "react-query";
import { GoodsEvaluate } from "../interfaces/goods-evaluate";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useGoodsEvaluateList = (param?: Partial<GoodsEvaluate>) => {
    const client = useHttp();
    const result = useQuery<any>(['goodsEvaluateList', param], () => client('goodsEvaluate', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useDeleteGoodsEvaluate = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ goodsEvaluateId }: { goodsEvaluateId: number}) =>
            client(`goodsEvaluate/${goodsEvaluateId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('goodsEvaluateList')
        }
    );
};