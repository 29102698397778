import { useMutation, useQuery, useQueryClient } from "react-query";
import { CourseShowcase } from "../interfaces/course-showcase";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useCourseShowcaseList = (param?: Partial<CourseShowcase>) => {
    const client = useHttp();
    const result = useQuery<any>(['courseShowcasePage', param], () => client('courseShowcase', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditCourseShowcase = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<CourseShowcase>) => client(`courseShowcase`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('courseShowcasePage')
        }
    )
}

export const useAddCourseShowcase = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<CourseShowcase>) => client(`courseShowcase`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('courseShowcasePage')
        }
    )
}

export const useDeleteCourseShowcase = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ courseShowcaseId }: { courseShowcaseId: number}) =>
            client(`courseShowcase/${courseShowcaseId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('courseShowcasePage')
        }
    );
};

export const useCourseShowcase = (courseShowcaseId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['courseShowcase', {courseShowcaseId}],
        () => client(`courseShowcase/${courseShowcaseId}`),
        {
            enabled: Boolean(courseShowcaseId)
        }
    )
}

