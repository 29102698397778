import { Button, Card, Col, Form, message, Modal, Row, Space, Table, Select, Input } from "antd"
import dayjs from "dayjs"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useComboList, useDeleteCombo } from "../../services/combo-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useComboSearchParams } from "./util"
const { Option } = Select;

export const ComboListPage = () => {
    useDocumentTitle('套餐列表', true)
    const [param, setParam] = useComboSearchParams()
    const { isLoading, error, totalElements, data: list } = useComboList(useDebounce(param, 200))

    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) =>
            setParam({
                ...param,
                pageNum: (current - 1),
            })
    }

    //提交
    const onFinish = (values: any) => {
        setParam({
            ...param,
            comboName: values.comboName,
            comboType: values.comboType,
            pageNum: null
        })
    }

    //删除事件
    const { mutateAsync: deleteMutateAsync } = useDeleteCombo();
    const confirmDeleteCombo = (comboId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    deleteMutateAsync({ comboId }).then((data) => {
                        if (data.status === 0) {
                            message.info('删除成功');
                            Modal.destroyAll();
                        } else {
                            message.error(data.msg)
                        }
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };

    //分页
    return (
        <div>
            <Row style={{ marginBottom: 24 }}>
                <Col span={12}>
                    <Link to={"/taocan/combo-manage/combo/add"}>
                        <Button type="primary">新建</Button>
                    </Link>
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                </Col>
            </Row>
            <Card style={{ backgroundColor: "#f2f3f5", margin: '24px 0 24px 0' }}>
                <Form onFinish={onFinish}>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item name="comboName" label="套餐名称">
                                <Input placeholder='请输入套餐名称' allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="comboType" label="套餐类型">
                                <Select
                                    placeholder='请选择套餐类型'
                                    showSearch
                                    allowClear
                                    optionFilterProp='children'
                                >
                                    <Option value={2}>学校</Option>
                                    <Option value={3}>机构</Option>
                                    <Option value={5}>企业</Option>
                                    <Option value={6}>商家</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item style={{ marginBottom: 0 }}>
                        <Button style={{ marginLeft: 70 }} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
            <Table
                loading={isLoading}
                rowKey={columns => columns.comboId}
                pagination={paginationProps}
                columns={[
                    {
                        title: 'ID',
                        dataIndex: 'comboId',
                        key: 'comboId',
                        align: 'center',
                        width: 100,
                        fixed: 'left',
                    },
                    {
                        title: '名称',
                        dataIndex: 'comboName',
                        key: 'comboName',
                        align: 'center',
                        width: 200,
                        fixed: 'left',
                    },
                    {
                        title: '类型',
                        dataIndex: 'comboType',
                        key: 'comboType',
                        align: 'center',
                        width: 150,
                        render: (value) => (
                            value === 2 ? '学校' : value === 3 ? '机构' : value === 5 ? '企业' : value === 6 ? '商家' : '-'
                        )
                    },
                    {
                        title: '天数',
                        dataIndex: 'comboDay',
                        key: 'comboDay',
                        align: 'center',
                        width: 150,
                        render: (value) => (
                            value ? value + '天' : '-'
                        )
                    },
                    {
                        title: '价格',
                        dataIndex: 'comboPrice',
                        key: 'comboPrice',
                        align: 'center',
                        width: 150,
                        render: (value) => (
                            value ? '￥' + value : '-'
                        )
                    },
                    {
                        title: '权益',
                        dataIndex: 'comboBenefit',
                        key: 'comboBenefit',
                        align: 'center',
                        width: 250,
                        render: (value, combo) => (
                            <div>
                                {combo.comboTop ? (<div>赠送置顶金额{combo.comboTopAmount}元</div>) : ('')}
                                {combo.comboRefresh ? (<div>赠送刷新次数{combo.comboRefreshNum}次</div>) : ('')}
                                {combo.comboRecruit ? (<div>赠送招聘次数{combo.comboRecruitNum}次</div>) : ('')}
                            </div>
                        )
                    },
                    {
                        title: '销量',
                        dataIndex: 'comboSaleNum',
                        key: 'comboSaleNum',
                        align: 'center',
                        width: 150,
                    },
                    {
                        title: '创建时间',
                        dataIndex: 'comboAddTime',
                        key: 'comboAddTime',
                        align: 'center',
                        width: 200,
                        render: (value) => (
                            value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : '-'
                        )
                    },
                    {
                        title: '排序',
                        dataIndex: 'comboSort',
                        key: 'comboSort',
                        align: 'center',
                        width: 100,
                    },
                    {
                        title: '状态',
                        dataIndex: 'comboShow',
                        key: 'comboShow',
                        align: 'center',
                        width: 100,
                        render: (value) => (
                            value ? '上架' : '下架'
                        ),
                    },
                    {
                        title: '操作',
                        key: 'action',
                        align: 'center',
                        render: (value, combo) => (
                            <Space size="middle">
                                <Link to={"/taocan/combo-manage/combo/update/" + combo.comboId}>
                                    编辑
                                </Link>
                                <a onClick={() => confirmDeleteCombo(combo.comboId)}>
                                    删除
                                </a>
                            </Space>
                        ),
                        width: 150,
                        fixed: 'right',
                    }
                ]}
                dataSource={list || []}
                scroll={{ x: 1600 }}
            />
            <ErrorBox error={error} />
        </div>
    )
}