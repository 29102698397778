import { useMutation, useQuery, useQueryClient } from "react-query";
import { GoodsSetting } from "../interfaces/goods-setting";
import { useHttp } from "../utils/http";

export const useEditGoodsSetting = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<GoodsSetting>) => client(`goodsSetting`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('goodsSetting')
        }
    )
}

export const useGoodsSetting = () => {
    const client = useHttp()
    return useQuery<any>(
        ['goodsSetting', {}],
        () => client(`goodsSetting`)
    )
}