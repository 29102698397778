import { useMutation, useQuery, useQueryClient } from "react-query";
import { SalesmanAuth } from "../interfaces/salesman-auth";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useSalesmanAuthList = (param?: Partial<SalesmanAuth>) => {
    const client = useHttp();
    const result = useQuery<any>(['salesmanAuthPage', param], () => client('salesmanAuth', { data: cleanObject(param) }))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return { ...result, totalElements, data };
}

export const useAgreeSalesmanAuth = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ salesmanAuthId }: { salesmanAuthId: number }) => client(`salesmanAuth/agree?salesmanAuthId=${salesmanAuthId}`, {
            method: "POST",
        }), {
        onSuccess: () => queryClient.invalidateQueries('salesmanAuthPage')
    }
    );
}

export const useRejectSalesmanAuth = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<SalesmanAuth>) => client(`salesmanAuth/reject`, {
            data: params,
            method: 'POST'
        }), {
        onSuccess: () => queryClient.invalidateQueries('salesmanAuthPage')
    }
    );
}