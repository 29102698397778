import { Card, Form, Table, DatePicker, Row, Col, Select, Button } from "antd"
import { useEffect, useState } from "react"
import { useLocation } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useOrdersBatchDeliveryItemList } from "../../services/orders-batch-delivery-item-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useOrdersBatchDeliveryItemSearchParams } from "./util"
const { Option } = Select;

export const OrdersBatchDeliveryItemListPage = () => {
    useDocumentTitle('批量发货详情', true)
    const { pathname } = useLocation();
    const ordersBatchDeliveryId = pathname.match(/orders\/batch\/delivery\/item\/(\d+)/)?.[1]
    const [param, setParam] = useOrdersBatchDeliveryItemSearchParams()
    useEffect(() => {
        setParam({
            ordersBatchDeliveryId: ordersBatchDeliveryId,
        })
    }, [ordersBatchDeliveryId]);
    const {isLoading, error, totalElements, data: list} = useOrdersBatchDeliveryItemList(useDebounce(param, 200))
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) => 
            setParam({
            ...param,
            pageNum: (current -1),
        })
    }
    //完成
    const onFinish = (values: any) => {
        setParam({
            ...param,
            ordersBatchDeliveryItemState: values.ordersBatchDeliveryItemState,
            pageNum: null
        })
    }
    return (
        <div>
            <Card style={{ backgroundColor: "#f2f3f5", marginBottom: 24 }}>
                <Form onFinish={onFinish}>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item
                                name="ordersBatchDeliveryItemState"
                                label="状态"
                            >
                                <Select
                                    showSearch
                                    placeholder="请选择状态"
                                    optionFilterProp="children"
                                >
                                    <Option value={0}>全部</Option>
                                    <Option value={1}>成功</Option>
                                    <Option value={2}>失败</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Button style={{ marginLeft:70 }} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
            <Table 
                loading = { isLoading }
                rowKey={ columns => columns.ordersBatchDeliveryItemId } 
                pagination = { paginationProps } 
                columns = {[
                {
                    title: '订单号',
                    dataIndex: 'ordersSn',
                    key: 'ordersSn',
                },
                {
                    title: '快递公司',
                    dataIndex: 'expressName',
                    key: 'expressName',
                },
                {
                    title: '快递单号',
                    dataIndex: 'trackingNumber',
                    key: 'trackingNumber',
                },
                {
                    title: '发货状态',
                    dataIndex: 'ordersBatchDeliveryItemStateValue',
                    key: 'ordersBatchDeliveryItemStateValue',
                }
            ]}
            dataSource={ list || [] }  />
            <ErrorBox error={ error } />
        </div>
    )
}