import { useQuery } from "react-query";
import { BillDetail } from "../interfaces/bill-detail";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useBillDetailList = (param?: Partial<BillDetail>) => {
    const client = useHttp();
    const result = useQuery<any>(['billDetailList', param], () => client('billDetail', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}