import { Button, Col, Divider, Form, Input, message, Radio, Row, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEditBank, useBank } from "../../services/bank-service";
import { useDocumentTitle } from "../../utils"
import UploadPic from '../../components/upload-pic';
import ColorSelect from "../../components/color-select";

export const BankUpdatePage = () => {
    //标题
    useDocumentTitle('编辑银行', true)
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const bankId = Number(pathname.match(/money\/withdraw-manage\/bank\/update\/(\d+)/)?.[1])

    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };

    //表单
    const [form] = useForm();
    const [bankLogo, setBankLogo] = useState("");
    const [bankColor, setBankColor] = useState("")
    useEffect(() => {
        form.setFieldsValue({
            "bankColor": bankColor
        })
    }, [bankColor])

    //准备回显
    const { data: editingBank, isLoading } = useBank(bankId);
    useEffect(() => {
        form.setFieldsValue({ ...editingBank?.data });
        //logo
        if (editingBank?.data?.bankLogo) {
            setBankLogo(editingBank?.data?.bankLogo)
        }
        //背景色
        if (editingBank?.data?.bankColor) {
            setBankColor(editingBank?.data?.bankColor)
        }
    }, [editingBank, form]);

    //新建
    const [submitLoading, setSubmitLoading] = useState(false);
    const { mutateAsync } = useEditBank();
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...editingBank?.data, ...values, bankLogo, bankColor }).then((data) => {
            if (data.status === 0) {
                message.success(data.msg);
                navigate('/money/withdraw-manage/bank/list')
            } else {
                setSubmitLoading(false);
                message.error(data.msg);
            }
        }).catch(err => {
            message.error('提交失败，请重新提交');
            setSubmitLoading(false);
        });
    };

    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/money/withdraw-manage/bank/list"}>
                        <Button type="primary">列表</Button>
                    </Link>
                </Col>
            </Row>

            <Divider style={{ marginBottom: 24 }} />

            <Spin spinning={isLoading}>
                <Form
                    form={form}
                    {...layout}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label={"名称"}
                        name={"bankName"}
                        rules={[{ required: true, message: "名称必填" }]}
                    >
                        <Input style={{ width: 380 }} placeholder={"请输入名称"} />
                    </Form.Item>

                    <UploadPic
                        name={'uploadLogo'}
                        label={'图片'}
                        pic={bankLogo}
                        setPic={setBankLogo}
                        action={'update'}
                        style={{ textAlign: 'left' }}
                    >
                    </UploadPic>

                    <ColorSelect
                        label={'背景色'}
                        name={'bankColor'}
                        color={bankColor}
                        setColor={setBankColor}
                    >
                    </ColorSelect>

                    <Form.Item
                        label={"排序"}
                        name={"bankSort"}
                        rules={[{ required: true, message: "排序必填" }]}
                    >
                        <Input style={{ width: 380 }} placeholder={"请输入排序"} />
                    </Form.Item>

                    <Form.Item
                        label={"状态"}
                        name={"bankShow"}
                        rules={[{ required: true, message: "状态必选" }]}
                        style={{ textAlign: 'left' }}
                    >
                        <Radio.Group>
                            <Radio defaultChecked value={true}>显示</Radio>
                            <Radio value={false}>不显示</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                        <Button loading={submitLoading} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    )
}

