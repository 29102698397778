import { Button, Col, Dropdown, Menu, message, Modal, Row, Space, Table } from "antd"
import { DownOutlined } from "@ant-design/icons";
import Search from "antd/lib/input/Search"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useTrendsTagList, useDeleteTrendsTag } from "../../services/trends-tag-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useTrendsTagSearchParams } from "./util"

export const TrendsTagListPage = () => {
    useDocumentTitle('动态标签列表', true)
    const [param, setParam] = useTrendsTagSearchParams()
    const { isLoading, error, totalElements, data: list } = useTrendsTagList(useDebounce(param, 200))

    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) =>
            setParam({
                ...param,
                pageNum: (current - 1),
            })
    }

    //删除事件
    const { mutateAsync: deleteMutateAsync } = useDeleteTrendsTag();
    const confirmDeleteTrendsTag = (trendsTagId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    deleteMutateAsync({ trendsTagId }).then((data) => {
                        if (data.status === 0) {
                            message.info('删除成功');
                            Modal.destroyAll();
                        } else {
                            message.error(data.msg)
                        }
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };

    const contentMenu: any = (record: any) => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to={'/dress/trends-manage/trends-tag/school-adv/list?trendsTagId=' + record.trendsTagId}>广告</Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to={'/dress/trends-manage/trends-tag/school-navigation/list?trendsTagId=' + record.trendsTagId}>导航</Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to={'/dress/trends-manage/trends-tag/school-notice/list?trendsTagId=' + record.trendsTagId}>头条</Link>
                </Menu.Item>
            </Menu>
        )
    }
    const operationMenu: any = (record: any) => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to={"/dress/trends-manage/trends-tag/update/" + record.trendsTagId}>
                        编辑
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <a onClick={() => confirmDeleteTrendsTag(record.trendsTagId)}>
                        删除
                    </a>
                </Menu.Item>
            </Menu>
        )
    }

    //分页
    return (
        <div>
            <Row style={{ marginBottom: 24 }}>
                <Col span={12}>
                    <Link to={"/dress/trends-manage/trends-tag/add"}>
                        <Button type="primary">新建</Button>
                    </Link>
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                    <Search
                        placeholder="可搜索动态标签名称"
                        style={{ width: 200 }}
                        value={param.trendsTagName}
                        onChange={(evt) =>
                            setParam({
                                ...param,
                                trendsTagName: evt.target.value,
                                pageNum: null
                            })}
                        onSearch={(value) =>
                            setParam({
                                ...param,
                                trendsTagName: value,
                                pageNum: null
                            })}
                    />
                </Col>
            </Row>
            <Table
                loading={isLoading}
                rowKey={columns => columns.trendsTagId}
                pagination={paginationProps}
                columns={[
                    {
                        title: 'ID',
                        dataIndex: 'trendsTagId',
                        key: 'trendsTagId',
                        align: 'center',
                        width: 100,
                    },
                    {
                        title: '名称',
                        dataIndex: 'trendsTagName',
                        key: 'trendsTagName',
                        align: 'center',
                    },
                    {
                        title: '类型',
                        dataIndex: 'trendsTagType',
                        key: 'trendsTagType',
                        align: 'center',
                        render: (value) => (
                            value === 1 ? '教育' : value === 2 ? '招聘' : value === 3 ? '生活' : '-'
                        ),
                    },
                    {
                        title: '排序',
                        dataIndex: 'trendsTagSort',
                        key: 'trendsTagSort',
                        align: 'center',
                    },
                    {
                        title: '状态',
                        dataIndex: 'trendsTagShow',
                        key: 'trendsTagShow',
                        align: 'center',
                        render: (value) => (
                            value ? '显示' : '不显示'
                        ),
                    },
                    {
                        title: '推荐',
                        dataIndex: 'trendsTagRecommend',
                        key: 'trendsTagRecommend',
                        align: 'center',
                        render: (value) => (
                            <span>{value ? '是' : '否'}</span>
                        ),
                    },
                    {
                        title: '操作',
                        key: 'action',
                        align: 'center',
                        render: (value, trendsTag) => (
                            trendsTag.trendsTagType === 1 ? (
                                <Space size="middle">
                                    <Dropdown overlay={contentMenu.bind(this, trendsTag)}>
                                        <a className='ant-dropdown-link' onClick={e => e.preventDefault()}>
                                            装饰 <DownOutlined />
                                        </a>
                                    </Dropdown>
                                    <Dropdown overlay={operationMenu.bind(this, trendsTag)}>
                                        <a className='ant-dropdown-link' onClick={e => e.preventDefault()}>
                                            操作 <DownOutlined />
                                        </a>
                                    </Dropdown>
                                </Space>
                            ) : (
                                <Space size="middle">
                                    <Link to={"/dress/trends-manage/trends-tag/update/" + trendsTag.trendsTagId}>
                                        编辑
                                    </Link>
                                    <a onClick={() => confirmDeleteTrendsTag(trendsTag.trendsTagId)}>
                                        删除
                                    </a>
                                </Space>
                            )

                        ),
                        width: 150,
                    }
                ]}
                dataSource={list || []} />
            <ErrorBox error={error} />
        </div>
    )
}