import { Button, Col, Divider, Form, Input, message, Radio, Row, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAddStoreClass, useStoreClassListTree } from "../../services/store-class-service";
import { useDocumentTitle } from "../../utils"
const { Option } = Select;

export const StoreClassAddPage = () => {
    //标题
    useDocumentTitle('新建门店分类', true)
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    //准备树状结构数据
    const { data: storeClassList } = useStoreClassListTree();
    const storeClassListOptions = storeClassList?.data?.map((item: any, value: any) => 
        <Option value={item.storeClassId} key={item.storeClassId}>{item.storeClassName}</Option>
    );
    //表单
    const [form] = useForm();
    //新建
    const useMutateAddStoreClass = useAddStoreClass;
    const {mutateAsync, error } =  useMutateAddStoreClass();
    //提交表单
    const [submitLoading, setSubmitLoading] = useState(false);
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...values }).then(() => {
            message.info('新建成功');
            window.location.href = "/#/store/class/list"; 
        });
    };
    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/store/class/list"}>
                        <Button type="primary">分类列表</Button>
                    </Link>
                </Col>
            </Row>
            <Divider style={{ marginBottom: 24 }} />
            <Form
                form={form}
                {...layout}
                onFinish={onFinish}
                initialValues={{
                    storeClassSort: 0,
                    storeClassShow: true
                }}>
                <Form.Item
                    label={"上级分类"}
                    name={"storeClassParentId"}
                    rules={[{ required: true, message: "上级分类必选" }]}
                >
                    <Select
                        showSearch
                        placeholder="请选择上级分类"
                        optionFilterProp="children"
                        style={{textAlign:'left'}}
                    >
                        <option value={0}>无</option>
                        {storeClassListOptions}
                    </Select>
                </Form.Item>

                <Form.Item
                    label={"分类名称"}
                    name={"storeClassName"}
                    rules={[{ required: true, message: "分类名称必填" }]}
                >
                    <Input placeholder={"请输入分类名称"} />
                </Form.Item>

                <Form.Item
                    label={"分类排序"}
                    name={"storeClassSort"}
                    rules={[{ required: true, message: "分类排序必填" }]}
                >
                    <Input placeholder={"请输入分类排序"} />
                </Form.Item>

                <Form.Item
                    label={"是否显示"}
                    name={"storeClassShow"}
                    rules={[{ required: true, message: "分类显示必选" }]}
                    style={{ textAlign:'left' }}
                >
                    <Radio.Group>
                        <Radio defaultChecked value={true}>显示</Radio>
                        <Radio value={false}>不显示</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item {...tailLayout} style={{ textAlign:'left' }}>
                    <Button loading={ submitLoading } type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>  
            </Form>
        </div>
    )
}

