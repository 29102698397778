import { Breadcrumb, Button, Col, message, Modal, Row, Space, Table } from "antd"
import Search from "antd/lib/input/Search"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useArea, useAreaList, useDeleteArea } from "../../services/area-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useAreaSearchParams } from "./util"

export const AreaListPage = () => {
    useDocumentTitle('区域列表', true)
    const [param, setParam] = useAreaSearchParams()
    const { isLoading, error, totalElements, data: list } = useAreaList(useDebounce(param, 200))

    const { data: parentArea } = useArea(param?.areaParentId || 0)

    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) =>
            setParam({
                ...param,
                pageNum: (current - 1),
            })
    }

    //删除事件
    const useMutateDeleteArea = useDeleteArea;
    const { mutateAsync } = useMutateDeleteArea();
    const confirmDeleteArea = (areaId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ areaId }).then((data) => {
                        if (data.status === 0) {
                            message.info('删除成功');
                            Modal.destroyAll();
                        } else {
                            message.error(data.msg)
                        }
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    //分页
    return (
        <div>
            {param?.areaType && param?.areaType > 1 ? (
                <Breadcrumb style={{ marginBottom: 24 }}>
                    <Breadcrumb.Item>
                        <Link to={"/dress/area-manage/area/list?areaType=1&areaParentId=0"}>
                            省份列表
                        </Link>
                    </Breadcrumb.Item>
                    {param?.areaType === 2 ? (
                        <Breadcrumb.Item>
                            城市列表
                        </Breadcrumb.Item>
                    ) : (
                        <Breadcrumb.Item>
                            <Link to={"/dress/area-manage/area/list?areaType=2&areaParentId=" + parentArea?.data?.areaParentId}>
                                城市列表
                            </Link>
                        </Breadcrumb.Item>
                    )}
                    {param?.areaType === 3 ? (
                        <Breadcrumb.Item>
                            区县列表
                        </Breadcrumb.Item>
                    ) : ('')}
                </Breadcrumb>
            ) : ('')}

            <Row style={{ marginBottom: 24 }}>
                <Col span={12}>
                    <Link to={"/dress/area-manage/area/add?areaType=" + (param?.areaType || 1) + "&areaParentId=" + (param?.areaParentId || 0)}>
                        <Button type="primary">新建</Button>
                    </Link>
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                    <Search
                        placeholder="可搜索区域名称"
                        style={{ width: 200 }}
                        value={param.areaName}
                        onChange={(evt) =>
                            setParam({
                                ...param,
                                areaName: evt.target.value,
                                pageNum: null
                            })}
                        onSearch={(value) =>
                            setParam({
                                ...param,
                                areaName: value,
                                pageNum: null
                            })}
                    />
                </Col>
            </Row>
            <Table
                loading={isLoading}
                rowKey={columns => columns.areaId}
                pagination={paginationProps}
                columns={[
                    {
                        title: 'ID',
                        dataIndex: 'areaId',
                        key: 'areaId',
                        align: 'center',
                        width: 100,
                    },
                    {
                        title: '名称',
                        dataIndex: 'areaName',
                        key: 'areaName',
                        align: 'center',
                    },
                    {
                        title: '首字母',
                        dataIndex: 'areaLetter',
                        key: 'areaLetter',
                        align: 'center',
                    },
                    {
                        title: '排序',
                        dataIndex: 'areaSort',
                        key: 'areaSort',
                        align: 'center',
                    },
                    {
                        title: '状态',
                        dataIndex: 'areaShow',
                        key: 'areaShow',
                        align: 'center',
                        render: (value) => (
                            <span>{value ? '显示' : '不显示'}</span>
                        ),
                    },
                    {
                        title: '操作',
                        key: 'action',
                        align: 'center',
                        render: (value, area) => (
                            <Space size="middle">
                                {!param?.areaType || param?.areaType === 1 ? (
                                    <Link to={"/dress/area-manage/area/list?areaType=2&areaParentId=" + area.areaId}>
                                        城市
                                    </Link>
                                ) : param?.areaType === 2 ? (
                                    <Link to={"/dress/area-manage/area/list?areaType=3&areaParentId=" + area.areaId}>
                                        区县
                                    </Link>
                                ) : ''}
                                <Link to={"/dress/area-manage/area/update/" + area.areaId}>
                                    编辑
                                </Link>
                                <a onClick={() => confirmDeleteArea(area.areaId)}>
                                    删除
                                </a>
                            </Space>
                        ),
                        width: 200,
                    }
                ]}
                dataSource={list || []} />
            <ErrorBox error={error} />
        </div>
    )
}