import { useQuery } from 'react-query';
import { AdminAuthority } from '../interfaces/admin-authority';
import { cleanObject } from '../utils';
import { useHttp } from '../utils/http';

// 权限列表 list
export const useAdminAuthorityList = () => {
    const client = useHttp();
    return useQuery<any>(["adminAuthorityList", {}], () =>
        client(`adminAuthority/list`)
    );
};

export const useAdminAuthorityListAll = (param?: Partial<AdminAuthority>) => {
    const client = useHttp();
    return useQuery<any>(['adminAuthorityListAll', param], () => client('adminAuthority/getList', {data: cleanObject(param)}))
}
