import { useMutation, useQuery, useQueryClient } from "react-query";
import { CourseRecommend } from "../interfaces/course-recommend";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useCourseRecommendList = (param?: Partial<CourseRecommend>) => {
    const client = useHttp();
    const result = useQuery<any>(['courseRecommendPage', param], () => client('courseRecommend', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useNoCourseRecommendList = (param?: Partial<CourseRecommend>) => {
    const client = useHttp();
    const result = useQuery<any>(['noCourseRecommendPage',param], () => client('courseRecommend/course', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditCourseRecommend = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<CourseRecommend>) => client(`courseRecommend`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('courseRecommendPage')
        }
    )
}

export const useAddCourseRecommend = () => {
    const client = useHttp();
    const queryClient = useQueryClient();
    return useMutation(
      (params: Partial<CourseRecommend>) =>
        client(`courseRecommend`, {
          data: params,
          method: "POST",
        }),
      {
        onSuccess: () => queryClient.invalidateQueries("noCourseRecommendPage"),
      }
    );
}

export const useDeleteCourseRecommend = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ courseRecommendIds }: { courseRecommendIds: number[]}) =>
            client(`courseRecommend`, {
            method: "DELETE",
            data: { courseRecommendIds }
        }), {
            onSuccess: () => queryClient.invalidateQueries('courseRecommendPage')
        }
    );
};


