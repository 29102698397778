import { useMemo } from "react";
import { useUrlQueryParam } from "../../utils/url";

export const useSalesmanSearchParams = () => {
    const [param, setParam] = useUrlQueryParam(["salesmanId", "salesmanAddTimeStart", "salesmanAddTimeEnd", "salesmanName", "salesmanDelete", "memberNickname", "pageNum"]);
    return [
        useMemo(() => ({
            ...param, salesmanId: Number(param.salesmanId) || undefined, 
            salesmanDelete: Boolean(param.salesmanDelete) || undefined,
            pageNum: Number(param.pageNum) || undefined
        }), [param]),
        setParam
    ] as const
}