import { Button, Checkbox, Col, Divider, Form, Input, InputNumber, message, Radio, Row, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEditCombo, useCombo } from "../../services/combo-service";
import { useDocumentTitle } from "../../utils"

export const ComboUpdatePage = () => {
    //标题
    useDocumentTitle('编辑套餐', true)
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const comboId = Number(pathname.match(/taocan\/combo-manage\/combo\/update\/(\d+)/)?.[1])

    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };

    //表单
    const [form] = useForm();
    const { data: editingCombo, isLoading } = useCombo(comboId);
    useEffect(() => {
        form.setFieldsValue({ ...editingCombo?.data });
        if (editingCombo?.data?.comboType) {
            setComboType(editingCombo?.data?.comboType)
        }
        setComboTop(editingCombo?.data?.comboTop)
        setComboRefresh(editingCombo?.data?.comboRefresh)
        setComboRecruit(editingCombo?.data?.comboRecruit)
        form.setFieldsValue({
            comboTopStatus: [editingCombo?.data?.comboTop],
            comboRefreshStatus: [editingCombo?.data?.comboRefresh],
            comboRecruitStatus: [editingCombo?.data?.comboRecruit],
        });
    }, [editingCombo, form]);

    //类型
    const [comboType, setComboType] = useState(2);
    const handleType = (e: any) => {
        setComboType(e.target.value)
    }
    //置顶金额
    const [comboTop, setComboTop] = useState(false);
    const handleTop = (e: any) => {
        setComboTop(e[0]);
    }
    //刷新次数
    const [comboRefresh, setComboRefresh] = useState(false);
    const handleRefresh = (e: any) => {
        setComboRefresh(e[0]);
    }
    //招聘次数
    const [comboRecruit, setComboRecruit] = useState(false);
    const handleRecruit = (e: any) => {
        setComboRecruit(e[0]);
    }

    //编辑
    const [submitLoading, setSubmitLoading] = useState(false);
    const { mutateAsync } = useEditCombo();
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...editingCombo?.data, ...values, comboType, comboTop, comboRefresh, comboRecruit }).then((data) => {
            if (data.status === 0) {
                message.success(data.msg);
                navigate('/taocan/combo-manage/combo/list')
            } else {
                setSubmitLoading(false);
                message.error(data.msg);
            }
        }).catch(err => {
            message.error('提交失败，请重新提交');
            setSubmitLoading(false);
        });
    };

    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/taocan/combo-manage/combo/list"}>
                        <Button type="primary">列表</Button>
                    </Link>
                </Col>
            </Row>

            <Divider style={{ marginBottom: 24 }} />

            <Spin spinning={isLoading}>
                <Form
                    form={form}
                    {...layout}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label={"套餐名称"}
                        name={"comboName"}
                        rules={[{ required: true, message: "套餐名称必填" }]}
                    >
                        <Input style={{ width: 380 }} placeholder={"请输入套餐名称"} />
                    </Form.Item>

                    <Form.Item
                        label={"套餐类型"}
                        name={"comboType"}
                        rules={[{ required: true, message: "套餐类型必选" }]}
                        style={{ textAlign: 'left' }}
                    >
                        <Radio.Group onChange={handleType}>
                            <Radio value={2}>学校</Radio>
                            <Radio value={3}>机构</Radio>
                            <Radio value={5}>企业</Radio>
                            <Radio value={6}>商家</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label={"有效天数"}
                        name={"comboDay"}
                        rules={[{ required: true, message: "有效天数必填" }]}
                    >
                        <InputNumber min={1} style={{ width: 380 }} placeholder={"请输入有效天数"} addonAfter={'天'} />
                    </Form.Item>

                    <Form.Item
                        label={"套餐价格"}
                        name={"comboPrice"}
                        rules={[{ required: true, message: "套餐价格必填" }]}
                    >
                        <InputNumber step={0.01} min={0} style={{ width: 380 }} placeholder={"请输入套餐价格"} addonAfter={'元'} />
                    </Form.Item>

                    <Form.Item
                        label={"套餐原价"}
                        name={"comboOriginalPrice"}
                        rules={[{ required: true, message: "套餐原价必填" }]}
                    >
                        <InputNumber step={0.01} min={0} style={{ width: 380 }} placeholder={"请输入套餐原价"} addonAfter={'元'} />
                    </Form.Item>

                    <Form.Item
                        label={"套餐权益"}
                        name={"comboTopStatus"}
                    >
                        <Checkbox.Group onChange={handleTop} options={[{ label: '置顶金额', value: true }]} />
                    </Form.Item>

                    {comboTop ? (
                        <Form.Item
                            colon={false}
                            label={' '}
                            name={'comboTopAmount'}
                            rules={[{ required: true, message: '置顶金额必填' }]}
                        >
                            <InputNumber step={0.01} min={0} placeholder="请输入置顶金额" style={{ width: 380 }} addonAfter={'元'} />
                        </Form.Item>
                    ) : ('')}

                    <Form.Item
                        colon={false}
                        label={' '}
                        name={"comboRefreshStatus"}
                    >
                        <Checkbox.Group onChange={handleRefresh} options={[{ label: '刷新次数', value: true }]} />
                    </Form.Item>

                    {comboRefresh ? (
                        <Form.Item
                            colon={false}
                            label={' '}
                            name={'comboRefreshNum'}
                            rules={[{ required: true, message: '刷新次数必填' }]}
                        >
                            <InputNumber min={0} placeholder="请输入刷新次数" style={{ width: 380 }} addonAfter={'次'} />
                        </Form.Item>
                    ) : ('')}

                    {comboType === 5 ? (
                        <>
                            <Form.Item
                                colon={false}
                                label={' '}
                                name={"comboRecruitStatus"}
                            >
                                <Checkbox.Group onChange={handleRecruit} options={[{ label: '招聘次数', value: true }]} />
                            </Form.Item>

                            {comboRecruit ? (
                                <Form.Item
                                    colon={false}
                                    label={' '}
                                    name={'comboRecruitNum'}
                                    rules={[{ required: true, message: '招聘次数必填' }]}
                                >
                                    <InputNumber min={0} placeholder="请输入招聘次数" style={{ width: 380 }} addonAfter={'次'} />
                                </Form.Item>
                            ) : ('')}
                        </>
                    ) : ('')}

                    <Form.Item
                        label={"套餐排序"}
                        name={"comboSort"}
                        rules={[{ required: true, message: "套餐排序必填" }]}
                    >
                        <Input style={{ width: 380 }} placeholder={"请输入套餐排序"} />
                    </Form.Item>

                    <Form.Item
                        label={"套餐状态"}
                        name={"comboShow"}
                        rules={[{ required: true, message: "套餐状态必选" }]}
                        style={{ textAlign: 'left' }}
                    >
                        <Radio.Group>
                            <Radio value={true}>上架</Radio>
                            <Radio value={false}>下架</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                        <Button loading={submitLoading} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    )
}

