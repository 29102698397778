import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHttp } from "../utils/http";
import { Groupon } from "../interfaces/groupon";

//列表 
export const useGrouponList = (param?: Partial<Groupon>) => {
    const client = useHttp();
    const result = useQuery<any>(["grouponList", param], () =>
        client("groupon", { data: param })
    );
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
};

//新增
export const useAddGroupon = () => {
    const client = useHttp();
    const queryClient = useQueryClient();
    return useMutation(
        (params: Partial<Groupon>) =>
            client(`groupon`, {
                data: params,
                method: "POST"
            }), {
                onSuccess: () => queryClient.invalidateQueries('grouponList')
            }
    );
};

//编辑
export const useEditGroupon = () => {
    const client = useHttp();
    const queryClient = useQueryClient();
    return useMutation(
        (params: Partial<Groupon>) =>
            client(`groupon`, {
                data: params,
                method: "PUT"
            }), {
                onSuccess: () => queryClient.invalidateQueries('grouponList')
            }
    );
};

//失效
export const useDeleteGroupon = () => {
    const client = useHttp();
    const queryClient = useQueryClient();
    return useMutation(
        ({ grouponId }: { grouponId: number}) =>
            client(`groupon/${grouponId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('grouponList')
        }
    );
};

export const useGroupon = (grouponId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['groupon', {grouponId}],
        () => client(`groupon/${grouponId}`),
        {
            enabled: Boolean(grouponId)
        }
    )
}


