import { Button, Col, message, Modal, Row, Space, Table, Image, Card, Form, Input, Breadcrumb } from "antd"
import Search from "antd/lib/input/Search"
import { Link } from "react-router-dom"
import dayjs from "dayjs"
import { ErrorBox } from "../../components/lib"
import { useDebounce, useDocumentTitle } from "../../utils"

import { useCourseShowcaseSearchParams } from "./util"
import { useState } from 'react'
import { useAddCourseShowcaseCourse, useNoCourseShowcaseCourseList } from '../../services/course-showcase-course-service'
export const AddCourseShowcaseCourseListPage = () => {
    useDocumentTitle('课程列表', true)
    const imgUrl = process.env.REACT_APP_IMG_URL;
    const [param, setParam] = useCourseShowcaseSearchParams()
    const {isLoading, error, totalElements, data: list} = useNoCourseShowcaseCourseList(useDebounce(param, 200))

	//分页
	const paginationProps = {
	  showSizeChanger: true,
	  onShowSizeChange: (current: number, pageSize: number) => {
		setParam({
		  ...param,
		  pageNum: current - 1,
		  pageSize,
		});
	  },
	  defaultCurrent: 1,
	  pageSize: param.pageSize,
	  total: totalElements,
	  onChange: (current: number, pageSize: number) =>
		setParam({
		  ...param,
		  pageNum: current - 1,
		  pageSize,
		}),
	};
     //搜索
  const onFinish = (values: any) => {
    setParam({
      ...param,
      courseName: values.courseName,
      pageNum: null,
    });
  };

    // 多选框
    const [selectedIds, setSelectedIds] = useState<number[]>([]);
    const rowSelection = {
      onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
        setSelectedIds(
          selectedRows.map((x) => {
            return x.courseId;
          })
        );
      },
    };
    
  const { mutateAsync } = useAddCourseShowcaseCourse();
  const batchAdd = () => {
    if (selectedIds && selectedIds.length > 0) {
      Modal.confirm({
        title: `确定增加这${selectedIds.length}条数据吗?`,
        content: "点击确定增加",
        okText: "确定",
        onOk(): any {
          return new Promise((resolve, reject) => {
            const courseIds = selectedIds;
            const data = {
                courseIds: courseIds,
                courseShowcaseId:param.courseShowcaseId
            };
            mutateAsync(data).then((data) => {
              console.log("resAdd", data);
              if (data.status === 0) {
                message.info("新增成功");
                Modal.destroyAll();
              } else {
                message.error(data.msg);
              }
            });
          }).catch(() => console.log("Oops errors!"));
        },
        cancelText: "取消",
      });
    }
  };

    //分页
    return (
        <div>
            <Row style={{ marginBottom: 24 }}>
                <Breadcrumb>
                <Breadcrumb.Item>
                    <Link to={`/jigou/course-manage/course-showcase/course-list?courseShowcaseId=${param.courseShowcaseId}`}>
                    橱窗课程
                    </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    <span>添加课程</span>
                </Breadcrumb.Item>
                </Breadcrumb>
            </Row>
      <Row style={{marginBottom: 24}}>
                
				<Col span={24} style={{ textAlign: "right" }}>
				  <Search
					placeholder="可搜索课程名称"
					style={{ width: 200 }}
					value={param.courseName}
					onChange={(evt) =>
					  setParam({
						...param,
						courseName: evt.target.value,
						pageNum: null,
					  })
					}
					onSearch={(value) =>
					  setParam({
						...param,
						courseName: value,
						pageNum: null,
					  })
					}
				  />
				</Col>
            </Row>
            <Row style={{ marginBottom: 24 }}>
                <Col span={12}>
                <Button type="primary" onClick={batchAdd}>
                    添加
                </Button>
                </Col>
            </Row>
            <Table 
                loading = { isLoading }
                rowKey={ columns => columns.courseId } 
                pagination = { paginationProps } 
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
                columns = {[
                {
                    title: 'ID',
                    dataIndex: 'courseId',
                    key: 'courseId',
                    align: 'center',
                    width: 100,
                },

                {
                    title: '课程名称',
                    dataIndex: 'courseName',
                    key: 'courseName',
                    align: 'center',
                },
                {
                    title: '课程图片',
                    dataIndex: 'coursePic',
                    key: 'coursePic',
                    align: 'center',
                    render(value, record, index) {
                        return (
                            <><Image width={120} src={imgUrl + value}></Image></>
                        )
                    },
                },

                {
                    title: '课程类型',
                    dataIndex: 'courseType',
                    key: 'courseType',
                    align: 'center',
                    render: (value) => (
                        <span>{value==1 ? '线上' : '线下'}</span>
                    ),
                },

                {
                    title: '课程价格',
                    dataIndex: 'coursePrice',
                    key: 'coursePrice',
                    align: 'center',
                },

             
                {
                    title: '机构名称',
                    dataIndex: 'agencyName',
                    key: 'agencyName',
                    align: 'center',
                },

                {
                    title: '上架状态',
                    dataIndex: 'courseShow',
                    key: 'courseShow',
                    align: 'center',
                    render: (value) => (
                        <span>{value ? '上架' : '下架'}</span>
                    ),
                },
            ]}
            dataSource={ list || [] }  />
            <ErrorBox error={ error } />
        </div>
    )
}