import { Button, Col, message, Modal, Row, Space, Table, Image, Card, Form, Input } from "antd"
import Search from "antd/lib/input/Search"
import { Link } from "react-router-dom"
import dayjs from "dayjs"
import { ErrorBox } from "../../components/lib"
import { useCourseAdvList, useDeleteCourseAdv } from "../../services/course-adv-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useCourseAdvSearchParams } from "./util"

export const CourseAdvListPage = () => {
    useDocumentTitle('课程广告列表', true)
    const imgUrl = process.env.REACT_APP_IMG_URL;
    const [param, setParam] = useCourseAdvSearchParams()
    const {isLoading, error, totalElements, data: list} = useCourseAdvList(useDebounce(param, 200))

	//分页
	const paginationProps = {
	  showSizeChanger: true,
	  onShowSizeChange: (current: number, pageSize: number) => {
		setParam({
		  ...param,
		  pageNum: current - 1,
		  pageSize,
		});
	  },
	  defaultCurrent: 1,
	  pageSize: param.pageSize,
	  total: totalElements,
	  onChange: (current: number, pageSize: number) =>
		setParam({
		  ...param,
		  pageNum: current - 1,
		  pageSize,
		}),
	};
     //搜索
  const onFinish = (values: any) => {
    setParam({
      ...param,
      courseAdvName: values.courseAdvName,
      pageNum: null,
    });
  };
    //删除事件
    const { mutateAsync } = useDeleteCourseAdv();
    const confirmDeleteCourseAdv = (courseAdvId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ courseAdvId }).then((data) => {
                        if (data.status === 0) {
                            message.info('删除成功');
                            Modal.destroyAll();
                          } else {
                            message.error(data.msg)
                          }
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    //分页
    return (
        <div>
            <Row style={{marginBottom: 24}}>
                <Col span={12}>
                    <Link to={"/jigou/course-manage/course-adv/add"}>
                        <Button type="primary">新建</Button>
                    </Link>
                </Col>
				<Col span={12} style={{ textAlign: "right" }}>
				  <Search
					placeholder="可搜索课程广告名称"
					style={{ width: 200 }}
					value={param.courseAdvName}
					onChange={(evt) =>
					  setParam({
						...param,
						courseAdvName: evt.target.value,
						pageNum: null,
					  })
					}
					onSearch={(value) =>
					  setParam({
						...param,
						courseAdvName: value,
						pageNum: null,
					  })
					}
				  />
				</Col>
            </Row>
            <Table 
                loading = { isLoading }
                rowKey={ columns => columns.courseAdvId } 
                pagination = { paginationProps } 
                columns = {[
                {
                    title: 'ID',
                    dataIndex: 'courseAdvId',
                    key: 'courseAdvId',
                    align: 'center',
                    width: 100,
                },

                {
                    title: '轮播图名称',
                    dataIndex: 'courseAdvName',
                    key: 'courseAdvName',
                    align: 'center',
                },

				{
                    title: '图片',
                    dataIndex: 'courseAdvPic',
                    key: 'courseAdvPic',
                    align: 'center',
                    render: (value) => (
                        <Image width={150} src={imgUrl + value}/>
                    ),
                },
                {
                    title: '轮播图排序',
                    dataIndex: 'courseAdvSort',
                    key: 'courseAdvSort',
                    align: 'center',
                },

                {
                    title: '轮播图是否显示',
                    dataIndex: 'courseAdvShow',
                    key: 'courseAdvShow',
                    align: 'center',
                    render: (value) => (
                        <span>{value ? '显示' : '不显示'}</span>
                    ),
                },

                {
                    title: '操作',
                    key: 'action',
                    align: 'center',
                    render: (value, courseAdv) => (
                        <Space size="middle">
                            <Link to={"/jigou/course-manage/course-adv/update/" + courseAdv.courseAdvId}>
                                编辑
                            </Link>
                            <a onClick={() => confirmDeleteCourseAdv(courseAdv.courseAdvId)}>
                                删除
                            </a>
                        </Space>
                    ),
                    width: 150,
                }
            ]}
            dataSource={ list || [] }  />
            <ErrorBox error={ error } />
        </div>
    )
}