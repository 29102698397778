import { Button, Col, message, Modal, Row, Space, Table, Tabs, Image, Menu } from "antd"
import Search from "antd/lib/input/Search"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useAuth } from "../../context/auth-context"
import { usePointsAdvList, useDeletePointsAdv } from "../../services/points-adv-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { usePointsAdvSearchParams } from "./util"
const { TabPane } = Tabs;

export const PointsAdvListPage = () => {
    useDocumentTitle('广告列表', true)
    const imgUrl = process.env.REACT_APP_IMG_URL;
    const [param, setParam] = usePointsAdvSearchParams()
    const { isLoading, error, totalElements, data: list } = usePointsAdvList(useDebounce(param, 200))
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) =>
            setParam({
                ...param,
                pageNum: (current - 1),
            })
    }
    //删除事件
    const useMutateDeletePointsAdv = useDeletePointsAdv;
    const { mutateAsync } = useMutateDeletePointsAdv();
    const confirmDeletePointsAdv = (pointsAdvId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ pointsAdvId }).then(() => {
                        message.info('删除成功');
                        Modal.destroyAll();
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    //tab切换
    const onChange = (e: any) => {
        setParam({
            ...param,
            pageNum: null
        })
    }
    //分页
    return (
        <div>
            <Menu style={{ marginBottom: 24 }} mode="horizontal" selectedKeys={['pointsAdv']}>
                <Menu.Item key="pointsGoods">
                    <Link to={"/shop/market-manage/points/goods/list"}>
                        商品管理
                    </Link>
                </Menu.Item>
                <Menu.Item key="pointsGoodsClass">
                    <Link to={"/shop/market-manage/points/goodsClass/list"}>
                        分类管理
                    </Link>
                </Menu.Item>
                <Menu.Item key="pointsAdv">
                    <Link to={"/shop/market-manage/points/adv/list"}>
                        广告管理
                    </Link>
                </Menu.Item>
            </Menu>
            <Row style={{ marginBottom: 24 }}>
                <Col span={12}>
                    <Link to={"/shop/market-manage/points/adv/add"}>
                        <Button type="primary">新建广告</Button>
                    </Link> 
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                    <Search
                        placeholder="可搜索广告标题"
                        style={{ width: 200 }}
                        value={param.pointsAdvTitle}
                        onChange={(evt) =>
                            setParam({
                                ...param,
                                pointsAdvTitle: evt.target.value,
                                pageNum: null
                            })}
                        onSearch={(value) =>
                            setParam({
                                ...param,
                                pointsAdvTitle: value,
                                pageNum: null
                            })}
                    />
                </Col>
            </Row>
            <Table
                loading={isLoading}
                rowKey={columns => columns.pointsAdvId}
                pagination={paginationProps}
                columns={[
                    {
                        title: '广告ID',
                        dataIndex: 'pointsAdvId',
                        key: 'pointsAdvId',
                        width: 100,
                    },
                    {
                        title: '广告标题',
                        dataIndex: 'pointsAdvTitle',
                        key: 'pointsAdvTitle',
                    },
                    {
                        title: '广告图片',
                        dataIndex: 'pointsAdvPic',
                        key: 'pointsAdvPic',
                        render: (value, pointsAdv) => (
                            <Image src={imgUrl  + pointsAdv.pointsAdvPic} width={150} />
                        )
                    },
                    {
                        title: '广告排序',
                        dataIndex: 'pointsAdvSort',
                        key: 'pointsAdvSort',
                    },
                    {
                        title: '是否显示',
                        dataIndex: 'pointsAdvShow',
                        key: 'pointsAdvShow',
                        render: (value, pointsAdv) => (
                            <span>{pointsAdv.pointsAdvShow ? '显示' : '不显示'}</span>
                        )
                    },
                    {
                        title: '操作',
                        key: 'action',
                        render: (value, pointsAdv) => ( 
                            <Space size="middle">
                                <Link to={"/shop/market-manage/points/adv/update/" + pointsAdv.pointsAdvId}>
                                    编辑
                                </Link>
                                <a onClick={() => confirmDeletePointsAdv(pointsAdv.pointsAdvId)}>
                                    删除
                                </a>
                            </Space>
                        ),
                        width: 150,
                    },
                ]}
                dataSource={list || []} />
            <ErrorBox error={error} />
        </div>
    )
}