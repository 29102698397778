import { Button, Col, message, Modal, Row, Space, Table } from "antd"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useMajorClassList, useDeleteMajorClass } from "../../services/major-class-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useMajorClassSearchParams } from "./util"

export const MajorClassListPage = () => {
    useDocumentTitle('专业分类列表', true)
    const [param, setParam] = useMajorClassSearchParams()
    const { isLoading, error, totalElements, data: list } = useMajorClassList(useDebounce(param, 200))

    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) =>
            setParam({
                ...param,
                pageNum: (current - 1),
            })
    }

    //删除事件
    const { mutateAsync: deleteMutateAsync } = useDeleteMajorClass();
    const confirmDeleteMajorClass = (majorClassId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    deleteMutateAsync({ majorClassId }).then((data) => {
                        if (data.status === 0) {
                            message.info('删除成功');
                            Modal.destroyAll();
                        } else {
                            message.error(data.msg)
                        }
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };

    //分页
    return (
        <div>
            <Row style={{ marginBottom: 24 }}>
                <Col span={12}>
                    <Link to={"/xuexiao/major-manage/major-class/add"}>
                        <Button type="primary">新建</Button>
                    </Link>
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                </Col>
            </Row>
            <Table
                loading={isLoading}
                rowKey={columns => columns.majorClassId}
                pagination={paginationProps}
                columns={[
                    {
                        title: 'ID',
                        dataIndex: 'majorClassId',
                        key: 'majorClassId',
                        align: 'center',
                        width: 100,
                    },
                    {
                        title: '名称',
                        dataIndex: 'majorClassName',
                        key: 'majorClassName',
                        align: 'center',
                    },
                    {
                        title: '标签',
                        dataIndex: 'trendsTagName',
                        key: 'trendsTagName',
                        align: 'center',
                    },
                    {
                        title: '排序',
                        dataIndex: 'majorClassSort',
                        key: 'majorClassSort',
                        align: 'center',
                    },
                    {
                        title: '状态',
                        dataIndex: 'majorClassShow',
                        key: 'majorClassShow',
                        align: 'center',
                        render: (value) => (
                            value ? '显示' : '不显示'
                        ),
                    },
                    {
                        title: '操作',
                        key: 'action',
                        align: 'center',
                        render: (value, majorClass) => (
                            <Space size="middle">
                                <Link to={"/xuexiao/major-manage/major-class/update/" + majorClass.majorClassId}>
                                    编辑
                                </Link>
                                <a onClick={() => confirmDeleteMajorClass(majorClass.majorClassId)}>
                                    删除
                                </a>
                            </Space>
                        ),
                        width: 150,
                    }
                ]}
                dataSource={list || []} />
            <ErrorBox error={error} />
        </div>
    )
}