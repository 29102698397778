import {
  Button,
  Col,
  Form,
  Input,
  InputNumber,
  message,
  Radio,
  Row,
  DatePicker,
  Select,
  Tooltip,
  Typography,
  Cascader,
  Tabs,
  Space,
  Empty,
  Image,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import RichText from "../../components/rich-text";
import UploadMorePic from "../../components/upload-more-pic";
import UploadPic from "../../components/upload-pic";
import { useAreaListAll } from "../../services/area-service";
import { useTrendsTagListAll } from "../../services/trends-tag-service";
import { useSchoolLevelListAll } from "../../services/school-level-service";
import { useAddSchool } from "../../services/school-service";
import { useSchoolTypeListAll } from "../../services/school-type-service";
import { useDocumentTitle } from "../../utils";
import { useMemberListAll } from "../../services/member-service";
const { Option } = Select;
const { Search } = Input;
const { TabPane } = Tabs;

export const SchoolAddPage = () => {
  //标题
  useDocumentTitle("新建学校", true);
  const navigate = useNavigate();

  //布局
  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 18 },
  };
  const tailLayout = {
    wrapperCol: { offset: 3 },
  };

  //学校层次
  const { data: schoolLevelList } = useSchoolLevelListAll();
  const schoolLevelOptions = schoolLevelList?.data?.map((item: any) => (
    <Option value={item.schoolLevelId}>{item.schoolLevelName}</Option>
  ));

  //学校类型
  const { data: schoolTypeList } = useSchoolTypeListAll();
  const schoolTypeOptions = schoolTypeList?.data?.map((item: any) => (
    <Option value={item.schoolTypeId}>{item.schoolTypeName}</Option>
  ));

  //动态标签
  const { data: trendsTagList } = useTrendsTagListAll(1);
  const trendsTagOptions = trendsTagList?.data?.map((item: any) => (
    <Option value={item.trendsTagId}>{item.trendsTagName}</Option>
  ));

  //省市区
  const { data: areaList } = useAreaListAll(3);

  const [form] = useForm();
  const [schoolLogo, setSchoolLogo] = useState("");
  const [schoolPic, setSchoolPic] = useState("");
  const [fileList, setFileList] = useState<any[]>([[]]);
  const [schoolBuildTimeStr, setSchoolBuildTimeStr] = useState("");
  const [provinceAreaId, setProvinceAreaId] = useState();
  const [cityAreaId, setCityAreaId] = useState();
  const [districtAreaId, setDistrictAreaId] = useState();
  const [schoolContent, setSchoolContent] = useState("");

  const handleSelectTime = (value: any, dateString: any) => {
    setSchoolBuildTimeStr(dateString);
  };

  const handleCascader = (e: any) => {
    if (e.length > 0) {
      setProvinceAreaId(e[0]);
      if (e.length > 1) {
        setCityAreaId(e[1]);
        if (e.length > 2) {
          setDistrictAreaId(e[2]);
        }
      }
    }
  };

  //编辑
  const [submitLoading, setSubmitLoading] = useState(false);
  const { mutateAsync } = useAddSchool();
  const onFinish = (values: any) => {
    setSubmitLoading(true);
    var schoolPicArray = values?.uploadMore?.map((item: any) => {
      return item?.response;
    });
    var schoolPics = schoolPicArray?.join(",");
    mutateAsync({
      ...values,
      schoolLogo,
      schoolPic,
      schoolPics,
      schoolBuildTimeStr,
      provinceAreaId,
      cityAreaId,
      districtAreaId,
      schoolContent,
    })
      .then((data) => {
        if (data.status === 0) {
          message.success(data.msg);
          navigate("/xuexiao/school-manage/school/list");
        } else {
          setSubmitLoading(false);
          message.error(data.msg);
        }
      })
      .catch((err) => {
        message.error("提交失败，请重新提交");
        setSubmitLoading(false);
      });
  };

  //选择用户
  const { mutateAsync: getMemberList } = useMemberListAll();
  const [memberList, setMemberList] = useState([]);
  const [memberTel, setMemberTel] = useState("");
  const handleMemberTel = (e: any) => {
    setMemberTel(e.target.value);
  };
  useEffect(() => {
    getMemberList({
      memberTel,
    }).then((data) => {
      if (data.status === 0) {
        setMemberList(data?.data?.content);
      }
    });
  }, [memberTel]);

  return (
    <div>
      <Row style={{ marginBottom: 24 }}>
        <Col span={12}>
          <Link to={"/xuexiao/school-manage/school/list"}>
            <Button type="primary">列表</Button>
          </Link>
        </Col>
      </Row>

      <Form
        form={form}
        {...layout}
        onFinish={onFinish}
        initialValues={{ schoolAttribute: 1, schoolSort: 0, schoolShow: true, schoolHot: false }}
      >
        <Tabs defaultActiveKey="1">
          <TabPane tab="基本信息" key="1">
            <Form.Item
              label={"学校名称"}
              name={"schoolName"}
              rules={[{ required: true, message: "学校名称必填" }]}
            >
              <Input style={{ width: 380 }} placeholder={"请输入学校名称"} />
            </Form.Item>

            <Form.Item label={"英文名"} name={"schoolEnglishName"}>
              <Input style={{ width: 380 }} placeholder={"请输入英文名"} />
            </Form.Item>

            <UploadPic
              name="uploadLogo"
              label="校徽"
              pic={schoolLogo}
              setPic={setSchoolLogo}
              action={"add"}
              style={{ textAlign: "left" }}
              required={false}
            ></UploadPic>

            <UploadPic
              name="uploadPic"
              label="背景图"
              pic={schoolPic}
              setPic={setSchoolPic}
              action={"add"}
              style={{ textAlign: "left" }}
              required={false}
            ></UploadPic>

            <UploadMorePic
              label={"详情图"}
              name={"uploadMore"}
              index={0}
              fileList={fileList}
              setFileList={setFileList}
              style={{ textAlign: "left" }}
            ></UploadMorePic>

            <Form.Item
              label={"学校性质"}
              name={"schoolAttribute"}
              rules={[{ required: true, message: "学校性质必选" }]}
            >
              <Select
                placeholder="请选择学校性质"
                showSearch
                allowClear
                optionFilterProp="children"
                style={{ width: 380 }}
              >
                <Option value={1}>公办</Option>
                <Option value={2}>民办</Option>
              </Select>
            </Form.Item>

            <Form.Item
              label={"学校层次"}
              name={"schoolLevelId"}
              rules={[{ required: true, message: "学校层次必选" }]}
            >
              <Select
                placeholder="请选择学校层次"
                showSearch
                allowClear
                optionFilterProp="children"
                style={{ width: 380 }}
              >
                {schoolLevelOptions}
              </Select>
            </Form.Item>

            <Form.Item
              label={"学校类型"}
              name={"schoolTypeId"}
              rules={[{ required: true, message: "学校类型必选" }]}
            >
              <Select
                placeholder="请选择学校类型"
                showSearch
                allowClear
                optionFilterProp="children"
                style={{ width: 380 }}
              >
                {schoolTypeOptions}
              </Select>
            </Form.Item>

            <Form.Item label={"动态标签"} name={"trendsTagIdList"}>
              <Select
                placeholder="请选择动态标签"
                showSearch
                allowClear
                optionFilterProp="children"
                style={{ width: 380 }}
                mode="multiple"
              >
                {trendsTagOptions}
              </Select>
            </Form.Item>

            <Form.Item label={"学校电话"} name={"schoolTel"}>
              <Input style={{ width: 380 }} placeholder={"请输入学校电话"} />
            </Form.Item>

            <Form.Item label={"学校官网"} name={"schoolWebsite"}>
              <Input style={{ width: 380 }} placeholder={"请输入学校官网"} />
            </Form.Item>

            <Form.Item label={"创办时间"} name={"schoolBuildTimeStr"}>
              <DatePicker style={{ width: 380 }} onChange={handleSelectTime} />
            </Form.Item>

            <Form.Item label={"占地面积"} name={"schoolArea"}>
              <InputNumber
                step={0.01}
                min={0}
                style={{ width: 380 }}
                placeholder={"请输入占地面积"}
                addonAfter="亩"
              />
            </Form.Item>

            <Form.Item
              label={"省市区"}
              name={"schoolCascader"}
              rules={[{ required: true, message: "省市区必选" }]}
            >
              <Cascader
                style={{ width: 380 }}
                options={areaList?.data}
                onChange={handleCascader}
                placeholder={"请选择省市区"}
              />
            </Form.Item>

            <Form.Item label={"详细地址"} name={"schoolAddress"}>
              <Input style={{ width: 380 }} placeholder={"请输入详细地址"} />
            </Form.Item>

            <Form.Item label={"经纬度"} name={"schoolLatitude"}>
              <Form.Item
                name={"schoolLatitude"}
                style={{ float: "left", marginBottom: 0 }}
              >
                <Input
                  type={"number"}
                  style={{ width: 150 }}
                  placeholder={"请输入纬度"}
                />
              </Form.Item>
              <Form.Item
                name={"schoolLongitude"}
                style={{ float: "left", marginBottom: 0 }}
              >
                <Input
                  type={"number"}
                  style={{ width: 150, marginLeft: 10 }}
                  placeholder={"请输入经度"}
                />
              </Form.Item>
              <Form.Item
                style={{ float: "left", marginBottom: 0, marginLeft: 10 }}
              >
                <Tooltip title="坐标拾起器">
                  <Typography.Link
                    target="_blank"
                    href="https://lbs.qq.com/getPoint/"
                  >
                    坐标
                  </Typography.Link>
                </Tooltip>
              </Form.Item>
            </Form.Item>

            <Form.Item
              label={"排序"}
              name={"schoolSort"}
              rules={[{ required: true, message: "排序必填" }]}
            >
              <Input style={{ width: 380 }} placeholder={"请输入排序"} />
            </Form.Item>

            <Form.Item
              label={"状态"}
              name={"schoolShow"}
              rules={[{ required: true, message: "状态必选" }]}
              style={{ textAlign: "left" }}
            >
              <Radio.Group>
                <Radio value={true}>显示</Radio>
                <Radio value={false}>不显示</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item
              label={"热门"}
              name={"schoolHot"}
              rules={[{ required: true, message: "是否热门必选" }]}
              style={{ textAlign: "left" }}
            >
              <Radio.Group>
                <Radio value={true}>是</Radio>
                <Radio value={false}>否</Radio>
              </Radio.Group>
            </Form.Item>

            <Form.Item label={"搜索用户"} name={"memberTel"}>
              <Search
                placeholder={"请输入手机号检索用户"}
                maxLength={11}
                onChange={handleMemberTel}
                style={{ width: 380 }}
              ></Search>
            </Form.Item>

            {memberList?.length ? (
              <Form.Item label={"选择用户"} name={"memberId"}>
                <Radio.Group>
                  {memberList?.map((item: any) => (
                    <Space
                      align="center"
                      direction="vertical"
                      size={5}
                      style={{ marginBottom: "10px", marginRight: "5px" }}
                      key={item.memberId}
                    >
                      <Radio value={item.memberId}>
                        <span
                          className="text1"
                          style={{ width: "60px", display: "block" }}
                        >
                          {item.memberNickname}
                        </span>
                      </Radio>
                      <Image
                        width="60px"
                        height="60px"
                        src={item.memberHeadImg}
                      />
                    </Space>
                  ))}
                </Radio.Group>
              </Form.Item>
            ) : (
              <Form.Item label={"选择用户"}>
                <Empty
                  description={"暂无用户"}
                  style={{ maxWidth: "180px", height: "50px" }}
                  image={Empty.PRESENTED_IMAGE_SIMPLE}
                />
              </Form.Item>
            )}
          </TabPane>

          <TabPane tab="学校简介" key="2">
            <RichText
              label={"学校简介"}
              name={"schoolContent"}
              setContent={setSchoolContent}
            ></RichText>
          </TabPane>
        </Tabs>

        <Form.Item {...tailLayout} style={{ textAlign: "left" }}>
          <Button loading={submitLoading} type="primary" htmlType="submit">
            提交
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
