import { Button, Col, message, Modal, Row, Space, Table } from "antd"
import Search from "antd/lib/input/Search"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useRecruitEducationList, useDeleteRecruitEducation } from "../../services/recruit-education-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useRecruitEducationSearchParams } from "./util"

export const RecruitEducationListPage = () => {
    useDocumentTitle('学历要求列表', true)
    const [param, setParam] = useRecruitEducationSearchParams()
    const {isLoading, error, totalElements, data: list} = useRecruitEducationList(useDebounce(param, 200))

    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) => 
            setParam({
            ...param,
            pageNum: (current -1),
        })
    }

    //删除事件
    const { mutateAsync:deleteMutateAsync } = useDeleteRecruitEducation();
    const confirmDeleteRecruitEducation = (recruitEducationId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    deleteMutateAsync({ recruitEducationId }).then((data) => {
                        if (data.status === 0) {
                            message.info('删除成功');
                            Modal.destroyAll();
                          } else {
                            message.error(data.msg)
                          }
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    //分页
    return (
        <div>
            <Row style={{marginBottom: 24}}>
                <Col span={12}>
                    <Link to={"/qiye/recruit-manage/recruit-education/add"}>
                        <Button type="primary">新建</Button>
                    </Link>
                </Col>
                <Col span={12} style={{textAlign:'right'}}>
                    <Search 
                        placeholder="可搜索学历要求名称" 
                        style={{ width: 200 }}
                        value={param.recruitEducationName}
                        onChange={(evt) =>
                            setParam({
                            ...param,
                            recruitEducationName: evt.target.value,
                            pageNum: null
                        })} 
                        onSearch={(value) =>
                            setParam({
                            ...param,
                            recruitEducationName: value,
                            pageNum: null
                        })} 
                    />
                </Col>
            </Row>
            <Table 
                loading = { isLoading }
                rowKey={ columns => columns.recruitEducationId } 
                pagination = { paginationProps } 
                columns = {[
                {
                    title: 'ID',
                    dataIndex: 'recruitEducationId',
                    key: 'recruitEducationId',
                    align: 'center',
                    width: 100,
                },
                {
                    title: '名称',
                    dataIndex: 'recruitEducationName',
                    key: 'recruitEducationName',
                    align: 'center',
                },
                {
                    title: '排序',
                    dataIndex: 'recruitEducationSort',
                    key: 'recruitEducationSort',
                    align: 'center',
                },
                {
                    title: '状态',
                    dataIndex: 'recruitEducationShow',
                    key: 'recruitEducationShow',
                    align: 'center',
                    render: (value) => (
                        <span>{value ? '显示' : '不显示'}</span>
                    ),
                },
                {
                    title: '操作',
                    key: 'action',
                    align: 'center',
                    render: (value, recruitEducation) => (
                        <Space size="middle">
                            <Link to={"/qiye/recruit-manage/recruit-education/update/" + recruitEducation.recruitEducationId}>
                                编辑
                            </Link>
                            <a onClick={() => confirmDeleteRecruitEducation(recruitEducation.recruitEducationId)}>
                                删除
                            </a>
                        </Space>
                    ),
                    width: 150,
                }
            ]}
            dataSource={ list || [] }  />
            <ErrorBox error={ error } />
        </div>
    )
}