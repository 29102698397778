import { Button, Col, message, Modal, Row, Space, Table, Image, Card, Form, Input } from "antd"
import Search from "antd/lib/input/Search"
import { Link } from "react-router-dom"
import dayjs from "dayjs"
import { ErrorBox } from "../../components/lib"
import { useCourseRecommendList, useDeleteCourseRecommend } from "../../services/course-recommend-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useCourseShowcaseSearchParams } from "./util"
import { useState } from 'react'
import { useCourseShowcaseCourseList, useDeleteCourseShowcaseCourse } from '../../services/course-showcase-course-service'

export const CourseShowcaseCourseListPage = () => {
    useDocumentTitle('课程推荐列表', true)
    const imgUrl = process.env.REACT_APP_IMG_URL;
    const [param, setParam] = useCourseShowcaseSearchParams()
    const {isLoading, error, totalElements, data: list} = useCourseShowcaseCourseList(useDebounce(param, 200))

	//分页
	const paginationProps = {
	  showSizeChanger: true,
	  onShowSizeChange: (current: number, pageSize: number) => {
		setParam({
		  ...param,
		  pageNum: current - 1,
		  pageSize,
		});
	  },
	  defaultCurrent: 1,
	  pageSize: param.pageSize,
	  total: totalElements,
	  onChange: (current: number, pageSize: number) =>
		setParam({
		  ...param,
		  pageNum: current - 1,
		  pageSize,
		}),
	};
    //删除事件
  const { mutateAsync } = useDeleteCourseShowcaseCourse();
  // 多选框
  const [selectedIds, setSelectedIds] = useState<number[]>([]);
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: any[]) => {
      setSelectedIds(
        selectedRows.map((x) => {
          return x.courseShowcaseCourseId;
        })
      );
    },
  };
  const batchDelete = () => {
    if (selectedIds && selectedIds.length > 0) {
      console.log(selectedIds);
      
      Modal.confirm({
        title: `确定删除这${selectedIds.length}条数据吗?`,
        content: "点击确定删除",
        okText: "确定",
        onOk(): any {
          return new Promise((resolve, reject) => {
            const courseShowcaseCourseIds = selectedIds;
            mutateAsync({ courseShowcaseCourseIds }).then((data) => {
              console.log("resdelete", data);
              if (data.status === 0) {
                message.info("删除成功");
                Modal.destroyAll();
              } else {
                message.error(data.msg);
              }
            });
          }).catch(() => console.log("Oops errors!"));
        },
        cancelText: "取消",
      });
    }
  };

    //分页
    return (
        <div>
            <Row style={{marginBottom: 24}}>
                <Col span={12}>
                    <Button type="primary" style={{ marginRight: 10 }} danger onClick={batchDelete}>
                        删除
                    </Button>
                    <Link to={`/jigou/course-manage/course-showcase/add-list?courseShowcaseId=${param.courseShowcaseId}`}>
                        <Button type="primary">新增</Button>
                    </Link>
                </Col>
            </Row>
            <Table 
                loading = { isLoading }
                rowKey={ columns => columns.courseShowcaseCourseId } 
                pagination = { paginationProps } 
                rowSelection={{
                  type: "checkbox",
                  ...rowSelection,
                }}
                columns = {[
                {
                    title: 'ID',
                    dataIndex: 'courseShowcaseCourseId',
                    key: 'courseShowcaseCourseId',
                    align: 'center',
                    width: 100,
                },

                {
                    title: '课程名称',
                    dataIndex: ['course','courseName'],
                    key: 'courseName',
                    align: 'center',
                },
                {
                    title: '课程图片',
                    dataIndex: ['course','coursePic'],
                    key: 'coursePic',
                    align: 'center',
                    render(value, record, index) {
                        return (
                            <><Image width={120} src={imgUrl + value}></Image></>
                        )
                    },
                },

                {
                    title: '课程类型',
                    dataIndex: ['course','courseType'],
                    key: 'courseType',
                    align: 'center',
                    render: (value) => (
                        <span>{value==1 ? '线上' : '线下'}</span>
                    ),
                },

                {
                    title: '课程价格',
                    dataIndex: ['course','coursePrice'],
                    key: 'coursePrice',
                    align: 'center',
                },

                {
                    title: '机构名称',
                    dataIndex: ['course','agencyName'],
                    key: 'agencyName',
                    align: 'center',
                },

                {
                    title: '上架状态',
                    dataIndex: ['course','courseShow'],
                    key: 'courseShow',
                    align: 'center',
                    render: (value) => (
                        <span>{value ? '上架' : '下架'}</span>
                    ),
                },
            ]}
            dataSource={ list || [] }  />
            <ErrorBox error={ error } />
        </div>
    )
}