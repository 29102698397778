import { Button, Col, Divider, Form, Input, InputNumber, message, Radio, Row, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAddPointsTask } from "../../services/points-task-service";
import { useDocumentTitle } from "../../utils"
import  UploadPic  from '../../components/upload-pic';
export const PointsTaskAddPage = () => {
    //标题
    useDocumentTitle('新建积分任务', true)
    const navigate = useNavigate();

    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };

    //表单
    const [form] = useForm();
    const [taskPic, setTaskPic] = useState("");
    const [uploading, setUploading] = useState<boolean>(false);
    //限制
    const [taskLimt,setTaskLimit] = useState(false);
    const onTaskLimitChange = (e:any) =>{
        setTaskLimit(e.target.value);
    }
    //新建
    const [submitLoading, setSubmitLoading] = useState(false);
    const { mutateAsync } = useAddPointsTask();
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...values, taskPic }).then((data) => {
            if (data.status === 0) {
                message.success(data.msg);
                navigate('/shop/market-manage/points-task/list')
            } else {
                setSubmitLoading(false);
                message.error(data.msg);
            }
        }).catch(err => {
            message.error('提交失败，请重新提交');
            setSubmitLoading(false);
        });
    };
    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/shop/market-manage/points-task/list"}>
                        <Button type="primary">列表</Button>
                    </Link>
                </Col>
            </Row>

            <Divider style={{ marginBottom: 24 }} />

            <Form
                form={form}
                {...layout}
                onFinish={onFinish}
                initialValues={{
                    pointsTaskSort: 0,
                    taskState: true,
                    taskLimt:false,
                }}>
               	<Form.Item
                    label={"任务类型"}
                    name={"taskType"}
                    rules={[{ required: true, message: "任务类型必填" }]}
                >
                    <Select placeholder="请选择任务类型" style={{ width: 380 }} >
                        <Select.Option key={1} value={1}>完善头像</Select.Option>
                        <Select.Option key={2} value={2}>绑定手机号</Select.Option>
                        <Select.Option key={3} value={3}>完成下单</Select.Option>
                    </Select>
                </Form.Item>

               	<Form.Item
                    label={"完成任务奖励积分"}
                    name={"taskPoints"}
                    rules={[{ required: true, message: "完成任务奖励积分必填" }]}
                >
                    <InputNumber min={0} step={1} style={{ width: 380 }} placeholder={"请输入完成任务奖励积分"} />
                </Form.Item>

                <Form.Item
                    label={"是否限制"}
                    name={"taskLimt"}
                    rules={[{ required: true, message: "是否限制必选" }]}
                    style={{ textAlign: 'left' }}
                >
                    <Radio.Group onChange={onTaskLimitChange}>
                        <Radio value={true}>是</Radio>
                        <Radio defaultChecked value={false}>否</Radio>
                    </Radio.Group>
                </Form.Item>
                {taskLimt?<>
                    <Form.Item
                        label={"限制次数"}
                        name={"taskLimitTimes"}
                        rules={[{ required: true, message: "限制次数必填" }]}
                    >
                        <InputNumber min={0} style={{ width: 380 }} placeholder={"请输入限制次数"} />
                    </Form.Item>
                </>:""}

                <Form.Item
                    label={"状态"}
                    name={"taskState"}
                    rules={[{ required: true, message: "状态必选" }]}
                    style={{ textAlign: 'left' }}
                >
                    <Radio.Group>
                        <Radio defaultChecked value={true}>开启</Radio>
                        <Radio value={false}>关闭</Radio>
                    </Radio.Group>
                </Form.Item>
               	<UploadPic
                    label={'图片'}
                    name={'uploadTaskPic'}
                    pic={taskPic}
                    setPic={setTaskPic}
                    action={'add'}
                    style={{ textAlign: 'left' }}
                >
                </UploadPic>

               <Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                    <Button loading={submitLoading} type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}