import { useMutation, useQuery, useQueryClient } from "react-query";
import { Member } from "../interfaces/member";
import { MemberMemberTag } from "../interfaces/member-member-tag";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useMemberList = (param?: Partial<Member>) => {
    const client = useHttp();
    const result = useQuery<any>(['memberPage', param], () => client('member', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useForbiddenMember = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ memberId, memberForbidden }: { memberId: number, memberForbidden: boolean }) =>
            client(`member/forbidden?memberId=${memberId}&memberForbidden=${memberForbidden}`, {
                method: "POST",
            }), {
        onSuccess: () => queryClient.invalidateQueries('memberPage')
    }
    );
};

//设置标签
export const useAddMemberTag = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<MemberMemberTag>) => client(`memberMemberTag`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('memberPage')
        }
    )
}

//查询标签
export const useMemberMemberTagList = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ memberId }: { memberId: number }) => client(`memberMemberTag?memberId=${memberId}`, {
            method: "GET",
        }), {
            onSuccess: () => queryClient.invalidateQueries('memberMemberTagList')
        }
    );
};

export const useMemberListAll = () => {
    const client = useHttp()
    return useMutation(
        ({ memberTel }: { memberTel: string }) => client(`member/list?&memberTel=${memberTel}`, {
            method: "GET",
        })
    );
};
