import { useMutation, useQuery, useQueryClient } from "react-query";
import { CourseSearchHot } from "../interfaces/course-search-hot";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useCourseSearchHotList = (param?: Partial<CourseSearchHot>) => {
    const client = useHttp();
    const result = useQuery<any>(['courseSearchHotPage', param], () => client('courseSearchHot', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditCourseSearchHot = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<CourseSearchHot>) => client(`courseSearchHot`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('courseSearchHotPage')
        }
    )
}

export const useAddCourseSearchHot = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<CourseSearchHot>) => client(`courseSearchHot`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('courseSearchHotPage')
        }
    )
}

export const useDeleteCourseSearchHot = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ courseSearchHotId }: { courseSearchHotId: number}) =>
            client(`courseSearchHot/${courseSearchHotId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('courseSearchHotPage')
        }
    );
};

export const useCourseSearchHot = (courseSearchHotId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['courseSearchHot', {courseSearchHotId}],
        () => client(`courseSearchHot/${courseSearchHotId}`),
        {
            enabled: Boolean(courseSearchHotId)
        }
    )
}