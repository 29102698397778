import { useUrlQueryParam } from "../../utils/url";
import { useMemo } from "react";

export const useWithdrawSearchParams = () => {
  const [param, setParam] = useUrlQueryParam(["withdrawState", "withdrawAddTimeStart", "withdrawAddTimeEnd", "pageNum", "withdrawId"]);
  return [
    useMemo(
      () => ({ ...param, withdrawId: Number(param.withdrawId) || undefined, withdrawState: Number(param.withdrawState) || undefined, pageNum: Number(param.pageNum) || undefined }),
      [param]
    ),
    setParam,
  ] as const;
};
