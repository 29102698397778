import { useMutation, useQuery, useQueryClient } from "react-query";
import { Agent } from "../interfaces/agent";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useAgentList = (param?: Partial<Agent>) => {
    const client = useHttp();
    const result = useQuery<any>(['agentPage', param], () => client('agent', { data: cleanObject(param) }))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return { ...result, totalElements, data };
}

export const useEditAgent = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Agent>) => client(`agent`, {
            method: 'PUT',
            data: params
        }), {
        onSuccess: () => queryClient.invalidateQueries('agentPage')
    }
    )
}

export const useAgreeAgent = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: any) => client(`agent`, {
            data: params,
            method: 'PUT'
        }), {
        onSuccess: () => queryClient.invalidateQueries('agentPage')
    }
    )
}

export const useRejectAgent = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ agentId, remark }: { agentId: number, remark: string | undefined }) =>
            client(`agent`, {
                method: "DELETE",
                data: { agentId, remark }
            }), {
        onSuccess: () => queryClient.invalidateQueries('agentPage')
    }
    );
};

export const useAgent = (agentId?: number) => {
    const client = useHttp()
    const result = useQuery<any>(
        ['agent', { agentId }],
        () => client(`agent/${agentId}`),
        {
            enabled: Boolean(agentId)
        }
    )
    return { result, data: result?.data?.data };
}

export const useSelfAgent = () => {
    const client = useHttp()
    const result = useQuery<any>(
        ['agentSelf'],
        () => client(`agent/self`)
    )
    return { result, data: result?.data?.data }
}

//列表 list
export const useAgentListAll = () => {
    const client = useHttp();
    return useQuery<any>(["agentList", {}], () =>
        client(`agent/list`)
    );
};