import { Button, Col, Divider, Form, Input, message, Radio, Row, DatePicker } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAddBombWindow } from "../../services/bomb-window-service";
import { useDocumentTitle } from "../../utils"
import UploadPic from "../../components/upload-pic";
import PageUrlSelect from "../../components/page-url-select";
const { RangePicker } = DatePicker;

export const BombWindowAddPage = () => {
    //标题
    useDocumentTitle('新建开屏广告', true)
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    //表单
    const [form] = useForm();
    const [bombWindowPic, setBombWindowPic] = useState("");
    //时间
    const [bombWindowStartTimeStr, setBombWindowStartTimeStr] = useState();
    const [bombWindowEndTimeStr, setBombWindowEndTimeStr] = useState();
    const handleSelectTime = (value: any, dateString: any) => {
        setBombWindowStartTimeStr(dateString[0]);
        setBombWindowEndTimeStr(dateString[1]);
    }
    //新建
    const useMutateAddBombWindow = useAddBombWindow;
    const { mutateAsync } = useMutateAddBombWindow();
    //提交表单
    const [submitLoading, setSubmitLoading] = useState(false);
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...values, bombWindowStartTimeStr, bombWindowEndTimeStr, bombWindowPic }).then(() => {
            message.info('新建成功');
            window.location.href = "/#/dress/decorate-manage/bomb-window/list";
        });
    };
    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/dress/decorate-manage/bomb-window/list"}>
                        <Button type="primary">列表</Button>
                    </Link>
                </Col>
            </Row>
            <Divider style={{ marginBottom: 24 }} />
            <Form
                form={form}
                {...layout}
                onFinish={onFinish}
                initialValues={{
                    bombWindowSort: 0,
                    bombWindowShow: true
                }}
            >
                <Form.Item
                    label={"标题"}
                    name={"bombWindowTitle"}
                    rules={[{ required: true, message: "标题必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入标题"} />
                </Form.Item>

                <Form.Item
                    label={"时间"}
                    name={"bombWindowTimes"}
                    rules={[{ required: true, message: "时间必选择" }]}
                >
                    <RangePicker onChange={handleSelectTime} showTime style={{ width: 380 }} format="YYYY-MM-DD HH:mm:ss" />
                </Form.Item>

                <UploadPic
                    name={'uploadPic'}
                    label={'图片'}
                    pic={bombWindowPic}
                    setPic={setBombWindowPic}
                    action={'add'}
                    style={{ textAlign: 'left' }}
                >
                </UploadPic>

                <PageUrlSelect></PageUrlSelect>

                <Form.Item
                    label={"排序"}
                    name={"bombWindowSort"}
                    rules={[{ required: true, message: "排序必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入排序"} />
                </Form.Item>

                <Form.Item
                    label={"状态"}
                    name={"bombWindowShow"}
                    rules={[{ required: true, message: "状态必选" }]}
                    style={{ textAlign: 'left' }}
                >
                    <Radio.Group>
                        <Radio defaultChecked value={true}>显示</Radio>
                        <Radio value={false}>不显示</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                    <Button loading={submitLoading} type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}
