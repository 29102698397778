import { useMutation, useQuery, useQueryClient } from "react-query"
import { AuthTreaty } from "../interfaces/auth-treaty"
import { useHttp } from "../utils/http"

export const useEditAuthTreaty = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<AuthTreaty>) => client(`authTreaty`, {
            method: 'POST',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('authTreaty')
        }
    )
}

export const useAuthTreaty = (authTreatyType: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['authTreaty', {authTreatyType}],
        () => client(`authTreaty?authTreatyType=${authTreatyType}`),
        {
            enabled: Boolean(authTreatyType)
        }
    )
}