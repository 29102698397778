import { useMutation, useQuery, useQueryClient } from "react-query";
import { Combo } from "../interfaces/combo";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useComboList = (param?: Partial<Combo>) => {
    const client = useHttp();
    const result = useQuery<any>(['comboPage', param], () => client('combo', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditCombo = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Combo>) => client(`combo`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('comboPage')
        }
    )
}

export const useAddCombo = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Combo>) => client(`combo`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('comboPage')
        }
    )
}

export const useDeleteCombo = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ comboId }: { comboId: number}) =>
            client(`combo/${comboId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('comboPage')
        }
    );
};

export const useCombo = (comboId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['combo', {comboId}],
        () => client(`combo/${comboId}`),
        {
            enabled: Boolean(comboId)
        }
    )
}