import { Button, Col, Divider, Form, message, Row, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useEditBusinessCircleStore } from "../../services/store-service";
import { useBusinessCircleStoreListNoIn } from "../../services/store-service";
import { useDebounce, useDocumentTitle } from "../../utils"
import { useStoreSearchParams } from "../store/util";
const { Option } = Select;

export const BusinessCircleStoreAddPage = () => {
    //标题
    useDocumentTitle('添加门店', true)
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    const { pathname } = useLocation()
    const businessCircleId = pathname.match(/shop\/store-manage\/businessCircle\/store\/add\/(\d+)/)?.[1]
    const [param, setParam] = useStoreSearchParams()
    useEffect(() => {
        setParam({
            businessCircleId: businessCircleId,
        })
    }, [businessCircleId]);
    //准备跳转链接
    const { data: storeList } = useBusinessCircleStoreListNoIn(useDebounce(param, 200));
    const options = storeList?.data?.map((item: any, value: any) =>
        <Option value={item.storeId} key={item.storeId}>
            {item.storeName}
        </Option>
    );
    //表单
    const [form] = useForm();
    //新建
    const useMutateAddBusinessCircleStore = useEditBusinessCircleStore;
    const {mutateAsync, error } =  useMutateAddBusinessCircleStore();
    //提交表单
    const [submitLoading, setSubmitLoading] = useState(false);
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...values, businessCircleId }).then(() => {
            message.info('添加成功');
            window.location.href = "/#/shop/store-manage/businessCircle/store/list/" + businessCircleId; 
        });
    };
    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/shop/store-manage/businessCircle/list"}>
                        <Button type="primary">商圈列表</Button>
                    </Link>
                </Col>
            </Row>
            <Divider style={{ marginBottom: 24 }} />
            <Form
                form={form}
                {...layout}
                onFinish={onFinish}>

                <Form.Item
                    label={"门店"}
                    name={"storeId"}
                    rules={[{ required: true, message: "门店必选" }]}
                >
                    <Select
                        showSearch
                        placeholder="请选择门店"
                        optionFilterProp="children"
                        style={{textAlign:'left'}}
                    >
                        {options}
                    </Select>
                </Form.Item>

                <Form.Item {...tailLayout} style={{ textAlign:'left' }}>
                    <Button loading={ submitLoading } type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>  
            </Form>
        </div>
    )
}

