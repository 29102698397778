import { Button, Col, message, Modal, Row, Space, Table, Image } from "antd";
import Search from "antd/lib/input/Search";
import { Link } from "react-router-dom";
import { ErrorBox } from "../../components/lib";
import { useSchoolSubjectList, useDeleteSchoolSubject, } from "../../services/school-subject-service";
import { useDebounce, useDocumentTitle } from "../../utils";
import { useSchoolSubjectSearchParams } from "./util";

export const SchoolSubjectListPage = () => {
  useDocumentTitle("学校专题列表", true);
  const imgUrl = process.env.REACT_APP_IMG_URL;
  const [param, setParam] = useSchoolSubjectSearchParams();
  const { isLoading, error, totalElements, data: list } = useSchoolSubjectList(useDebounce(param, 200));

  //分页
  const paginationProps = {
    defaultCurrent: 1,
    pageSize: 10,
    total: totalElements,
    onChange: (current: number) =>
      setParam({
        ...param,
        pageNum: current - 1,
      }),
  };

  //删除事件
  const useMutateDeleteSchoolSubject = useDeleteSchoolSubject;
  const { mutateAsync } = useMutateDeleteSchoolSubject();
  const confirmDeleteSchoolSubject = (schoolSubjectId: number) => {
    Modal.confirm({
      title: "确定删除这条数据吗?",
      content: "点击确定删除",
      okText: "确定",
      onOk(): any {
        return new Promise((resolve, reject) => {
          mutateAsync({ schoolSubjectId }).then((data) => {
            if (data.status === 0) {
              message.info("删除成功");
              Modal.destroyAll();
            } else {
              message.error(data.msg);
            }
          });
        }).catch(() => console.log("Oops errors!"));
      },
      cancelText: "取消",
    });
  };
  //分页
  return (
    <div>
      <Row style={{ marginBottom: 24 }}>
        <Col span={12}>
          <Link to={"/xuexiao/school-manage/school-subject/add"}>
            <Button type="primary">新建</Button>
          </Link>
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <Search
            placeholder="可搜索专题名称"
            style={{ width: 200 }}
            value={param.schoolSubjectName}
            onChange={(evt) =>
              setParam({
                ...param,
                schoolSubjectName: evt.target.value,
                pageNum: null,
              })
            }
            onSearch={(value) =>
              setParam({
                ...param,
                schoolSubjectName: value,
                pageNum: null,
              })
            }
          />
        </Col>
      </Row>
      <Table
        loading={isLoading}
        rowKey={(columns) => columns.schoolSubjectId}
        pagination={paginationProps}
        columns={[
          {
            title: "ID",
            dataIndex: "schoolSubjectId",
            key: "schoolSubjectId",
            align: "center",
            width: 100,
          },
          {
            title: "名称",
            dataIndex: "schoolSubjectName",
            key: "schoolSubjectName",
            align: "center",
          },

          {
            title: "图片",
            dataIndex: "schoolSubjectPic",
            key: "schoolSubjectPic",
            align: "center",
            render: (value) => (
              <Image width={150} src={imgUrl + value} />
            ),
          },
          {
            title: "排序",
            dataIndex: "schoolSubjectSort",
            key: "schoolSubjectSort",
            align: "center",
          },
          {
            title: "状态",
            dataIndex: "schoolSubjectShow",
            key: "schoolSubjectShow",
            align: "center",
            render: (value, schoolSubject) => (
              schoolSubject.schoolSubjectShow ? "显示" : "不显示"
            ),
          },
          {
            title: "操作",
            key: "action",
            align: "center",
            render: (value, schoolSubject) => (
              <Space size="middle">
                <Link to={"/xuexiao/school-manage/school-subject/update/" + schoolSubject.schoolSubjectId}>
                  编辑
                </Link>
                <a onClick={() => confirmDeleteSchoolSubject(schoolSubject.schoolSubjectId)}>
                  删除
                </a>
              </Space>
            ),
            width: 150,
          },
        ]}
        dataSource={list || []}
      />
      <ErrorBox error={error} />
    </div>
  );
};
