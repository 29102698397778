import { useQuery } from "react-query";
import { TrendsComment } from "../interfaces/trends-comment";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useTrendsCommentList = (param?: Partial<TrendsComment>) => {
    const client = useHttp();
    const result = useQuery<any>(['trendsCommentPage', param], () => client('trendsComment', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}