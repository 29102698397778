import { Breadcrumb, Button, Card, Col, Form, Row, Select, Table, DatePicker } from "antd"
import dayjs from "dayjs"
import { useState } from "react"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useMemberPointsLogList } from "../../services/member-points-log-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useMemberPointsLogSearchParams } from "./util"
const { RangePicker } = DatePicker
const { Option } = Select

export const MemberPointsLogListPage = () => {
    useDocumentTitle('积分明细', true)
    const [param, setParam] = useMemberPointsLogSearchParams()
    const { isLoading, error, totalElements, data: list } = useMemberPointsLogList(useDebounce(param, 200))
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) =>
            setParam({
                ...param,
                pageNum: (current - 1),
            })
    }
    const rangeConfig = {
        rules: [{ type: 'array' as const, message: '请选择时间!' }],
    }
    //注册时间
    const [memberPointsLogTimeStart, setMemberPointsLogTimeStart] = useState('')
    const [memberPointsLogTimeEnd, setMemberPointsLogTimeEnd] = useState('')
    const handleSelectTime = (value: any, dateString: any) => {
        setMemberPointsLogTimeStart(dateString[0])
        setMemberPointsLogTimeEnd(dateString[1])
    }
    const onFinish = (values: any) => {
        setParam({
            ...param,
            memberPointsLogTimeStart: memberPointsLogTimeStart,
            memberPointsLogTimeEnd: memberPointsLogTimeEnd,
            memberPointsLogOperation: values.memberPointsLogOperation,
            pageNum: null,
        })
    }
    //分页
    return (
        <div>
            <Breadcrumb style={{ marginBottom: 20 }}>
                <Breadcrumb.Item>
                    <Link to={'/user/user-manage/member/list'}>客户列表</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>积分明细</Breadcrumb.Item>
            </Breadcrumb>
            <Card style={{ backgroundColor: '#f2f3f5', marginBottom: 24 }}>
                <Form onFinish={onFinish}>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item name='memberPointsLogTimeRange' label='选择时间' {...rangeConfig}>
                                <RangePicker onChange={handleSelectTime} showTime format='YYYY-MM-DD HH:mm:ss' />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name='memberPointsLogOperation' label='操作方式'>
                                <Select showSearch allowClear placeholder='请选择操作方式' optionFilterProp='children'>
                                    <Option value={''}>全部</Option>
                                    <Option value={'add'}>增加</Option>
                                    <Option value={'sub'}>减少</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Button style={{ marginLeft: 70 }} type='primary' htmlType='submit'>
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
            <Table
                loading={isLoading}
                rowKey={columns => columns.memberPointsLogId}
                pagination={paginationProps}
                columns={[
                    {
                        title: '明细编号',
                        dataIndex: 'memberPointsLogSn',
                        key: 'memberPointsLogSn',
                    },
                    {
                        title: '变化前积分',
                        dataIndex: 'memberPointsLogStartPoints',
                        key: 'memberPointsLogStartPoints',
                        render(value, memberPointsLog) {
                            return <span>￥{memberPointsLog.memberPointsLogStartPoints}</span>
                        },
                    },
                    {
                        title: '变化积分',
                        dataIndex: 'memberPointsLogChangePoints',
                        key: 'memberPointsLogChangePoints',
                        render(value, memberPointsLog) {
                            return <span>￥{memberPointsLog.memberPointsLogChangePoints}</span>
                        },
                    },
                    {
                        title: '变化后积分',
                        dataIndex: 'memberPointsLogEndPoints',
                        key: 'memberPointsLogEndPoints',
                        render(value, memberPointsLog) {
                            return <span>￥{memberPointsLog.memberPointsLogEndPoints}</span>
                        },
                    },
                    {
                        title: '操作方式',
                        dataIndex: 'memberPointsLogOperation',
                        key: 'memberPointsLogOperation',
                        render(value, memberPointsLog) {
                            return <span>{memberPointsLog.memberPointsLogOperation === 'add' ? "增加" : memberPointsLog.memberPointsLogOperation === 'sub' ? "减少" : '无'}</span>
                        },
                    },
                    {
                        title: '变化原因',
                        dataIndex: 'memberPointsLogChangeReason',
                        key: 'memberPointsLogChangeReason',
                    },
                    {
                        title: '变化时间',
                        dataIndex: 'memberPointsLogTime',
                        key: 'memberPointsLogTime',
                        render(value, memberPointsLog) {
                            return <span>{memberPointsLog.memberPointsLogTime ? dayjs(memberPointsLog.memberPointsLogTime).format('YYYY-MM-DD HH:mm:ss') : '无'}</span>
                        },
                    },
                ]}
                dataSource={list || []} />
            <ErrorBox error={error} />
        </div>
    )
}