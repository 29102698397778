import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import { AppProviders } from './context';
import zhCN from 'antd/es/locale/zh_CN';
import 'moment/locale/zh-cn';
import { ConfigProvider } from 'antd';

ReactDOM.render(
  <React.StrictMode>
    <ConfigProvider locale={zhCN}>
      <AppProviders>
        <App />
      </AppProviders>
    </ConfigProvider>
  </React.StrictMode>,
  document.getElementById('root')
);

reportWebVitals();
