import { useMutation, useQuery, useQueryClient } from "react-query";
import { SchoolNavigation } from "../interfaces/school-navigation";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useSchoolNavigationList = (param?: Partial<SchoolNavigation>) => {
    const client = useHttp();
    const result = useQuery<any>(['schoolNavigationPage', param], () => client('schoolNavigation', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditSchoolNavigation = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<SchoolNavigation>) => client(`schoolNavigation`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('schoolNavigationPage')
        }
    )
}

export const useAddSchoolNavigation = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<SchoolNavigation>) => client(`schoolNavigation`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('schoolNavigationPage')
        }
    )
}

export const useDeleteSchoolNavigation = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ schoolNavigationId }: { schoolNavigationId: number}) =>
            client(`schoolNavigation/${schoolNavigationId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('schoolNavigationPage')
        }
    );
};

export const useSchoolNavigation = (schoolNavigationId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['schoolNavigation', {schoolNavigationId}],
        () => client(`schoolNavigation/${schoolNavigationId}`),
        {
            enabled: Boolean(schoolNavigationId)
        }
    )
}