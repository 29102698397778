import { Button, Col, Divider, Form, Input, message, Row, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useGoodsSpec, useEditGoodsSpec } from "../../services/goods-spec-service";
import { useDocumentTitle } from "../../utils"

export const GoodsSpecUpdatePage = () => {
    //标题
    useDocumentTitle('编辑商品规格', true)
    const { pathname } = useLocation();
    const goodsSpecId = pathname.match(/shop\/goods-manage\/goods-spec\/update\/(\d+)/)?.[1]
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    //表单
    const [form] = useForm();
    //准备回显数据
    const { data: editingGoodsSpec, isLoading } = useGoodsSpec(
        Number(goodsSpecId)
    );  
    useEffect(() => {
        form.setFieldsValue(editingGoodsSpec?.data)
    }, [editingGoodsSpec, form]);
    //编辑
    const useMutateEditGoodsSpec = useEditGoodsSpec;
    const {mutateAsync, error } =  useMutateEditGoodsSpec();
    //提交表单
    const [submitLoading, setSubmitLoading] = useState(false);
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...editingGoodsSpec?.data, ...values }).then(() => {
            message.info('编辑成功');
            window.location.href = "/#/shop/goods-manage/goods-spec/list"; 
        });
    };
    return (
        <div>
            <Spin spinning={isLoading}>
                <Row>
                    <Col span={12}>
                        <Link to={"/shop/goods-manage/goods-spec/list"}>
                            <Button type="primary">规格列表</Button>
                        </Link>
                    </Col>
                </Row>
                <Divider style={{ marginBottom: 24 }} />
                <Form
                    form={form}
                    {...layout}
                    onFinish={onFinish}>
                    <Form.Item
                        label={"规格名称"}
                        name={"goodsSpecName"}
                        rules={[{ required: true, message: "规格名称必填" }]}
                    >
                        <Input placeholder={"请输入规格名称"} />
                    </Form.Item>

                    <Form.Item
                        label={"规格排序"}
                        name={"goodsSpecSort"}
                        rules={[{ required: true, message: "规格排序必填" }]}
                    >
                        <Input placeholder={"请输入规格排序"} />
                    </Form.Item>


                    <Form.Item {...tailLayout} style={{ textAlign:'left' }}>
                        <Button loading={ submitLoading } type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>  
                </Form>
            </Spin>
        </div>
    )
}

