import { Button, Col, Divider, Form, Input, message, Radio, Row, Spin, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import UploadPic from "../../components/upload-pic";
import { useEditMajorClass, useMajorClass, useMajorClassListAll } from "../../services/major-class-service";
import { useTrendsTagListAll } from "../../services/trends-tag-service";
import { useDocumentTitle } from "../../utils"
const { Option } = Select;

export const MajorClassUpdatePage = () => {
    //标题
    useDocumentTitle('编辑专业分类', true)
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const majorClassId = Number(pathname.match(/xuexiao\/major-manage\/major-class\/update\/(\d+)/)?.[1])

    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };

    //动态标签
    const { data: trendsTagList } = useTrendsTagListAll(1);
    const trendsTagOptions = trendsTagList?.data?.map((item: any) =>
        <Option value={item?.trendsTagId}>{item?.trendsTagName}</Option>
    )

    //上级
    const { data: majorClassList } = useMajorClassListAll(1);
    const majorClassOptions = majorClassList?.data?.map((item: any) =>
        <Option value={item?.value}>{item?.label}</Option>
    )

    const [majorClassParentId, setMajorClassParentId] = useState(0);
    const handleParent = (e: any) => {
        setMajorClassParentId(e)
    }

    //准备回显
    const [majorClassPic, setMajorClassPic] = useState('');
    const { data: editingMajorClass, isLoading } = useMajorClass(majorClassId);
    const [form] = useForm();
    useEffect(() => {
        form.setFieldsValue({ ...editingMajorClass?.data });
        setMajorClassParentId(editingMajorClass?.data?.majorClassParentId)
        if (editingMajorClass?.data?.majorClassPic) {
            setMajorClassPic(editingMajorClass?.data?.majorClassPic)
        }
    }, [editingMajorClass, form]);


    //编辑
    const [submitLoading, setSubmitLoading] = useState(false);
    const { mutateAsync } = useEditMajorClass();
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...editingMajorClass?.data, ...values, majorClassPic }).then((data) => {
            if (data.status === 0) {
                message.success(data.msg);
                navigate('/xuexiao/major-manage/major-class/list')
            } else {
                setSubmitLoading(false);
                message.error(data.msg);
            }
        }).catch(err => {
            message.error('提交失败，请重新提交');
            setSubmitLoading(false);
        });
    };

    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/xuexiao/major-manage/major-class/list"}>
                        <Button type="primary">列表</Button>
                    </Link>
                </Col>
            </Row>

            <Divider style={{ marginBottom: 24 }} />

            <Spin spinning={isLoading}>
                <Form
                    form={form}
                    {...layout}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label={"名称"}
                        name={"majorClassName"}
                        rules={[{ required: true, message: "名称必填" }]}
                    >
                        <Input style={{ width: 380 }} placeholder={"请输入名称"} />
                    </Form.Item>

                    <Form.Item
                        label={"上级"}
                        name={"majorClassParentId"}
                        rules={[{ required: true, message: "上级必选" }]}
                    >
                        <Select
                            style={{ width: 380 }}
                            placeholder={"请选择上级"}
                            showSearch
                            allowClear
                            optionFilterProp='children'
                            onChange={handleParent}
                        >
                            <Option value={0}>无</Option>
                            {majorClassOptions}
                        </Select>
                    </Form.Item>

                    {majorClassParentId === 0 ? (
                        <Form.Item
                            label={"标签"}
                            name={"trendsTagId"}
                            rules={[{ required: true, message: "标签必选" }]}
                        >
                            <Select
                                style={{ width: 380 }}
                                placeholder={"请选择标签"}
                                showSearch
                                allowClear
                                optionFilterProp='children'
                            >
                                {trendsTagOptions}
                            </Select>
                        </Form.Item>
                    ) : ('')}

                    <UploadPic
                        name={'uploadPic'}
                        label={'图片'}
                        pic={majorClassPic}
                        setPic={setMajorClassPic}
                        action={'update'}
                        style={{ textAlign: 'left' }}
                        required={false}
                    >
                    </UploadPic>

                    <Form.Item
                        label={"排序"}
                        name={"majorClassSort"}
                        rules={[{ required: true, message: "排序必填" }]}
                    >
                        <Input style={{ width: 380 }} placeholder={"请输入排序"} />
                    </Form.Item>

                    <Form.Item
                        label={"状态"}
                        name={"majorClassShow"}
                        rules={[{ required: true, message: "状态必选" }]}
                        style={{ textAlign: 'left' }}
                    >
                        <Radio.Group>
                            <Radio defaultChecked value={true}>显示</Radio>
                            <Radio value={false}>不显示</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                        <Button loading={submitLoading} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>

        </div>
    )
}

