import { Button, Card, Col, Form, Input, message, Modal, Row, Select, Space, Table, DatePicker, Image, Tag } from "antd"
import dayjs from "dayjs"
import { useState } from "react"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import PicPlayer from "../../components/pic-player"
import VideoPlayer from "../../components/video-player"
import { useTrendsList, useShowTrends, useHideTrends } from "../../services/trends-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useTrendsSearchParams } from "./util"
const { Option } = Select;
const { RangePicker } = DatePicker;

export const TrendsListPage = () => {
    useDocumentTitle('动态列表', true)
    const [param, setParam] = useTrendsSearchParams()
    const { isLoading, error, totalElements, data: list } = useTrendsList(useDebounce(param, 200))

    const imgUrl = process.env.REACT_APP_IMG_URL;

    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) =>
            setParam({
                ...param,
                pageNum: (current - 1),
            })
    }

    //时间
    const [trendsAddTimeStart, setTrendsAddTimeStart] = useState('');
    const [trendsAddTimeEnd, setTrendsAddTimeEnd] = useState('');
    const handleSelectTime = (value: any, dateString: any) => {
        setTrendsAddTimeStart(dateString[0]);
        setTrendsAddTimeEnd(dateString[1]);
    }

    //提交
    const onFinish = (values: any) => {
        setParam({
            ...param,
            trendsTitle: values.trendsTitle,
            trendsState: values.trendsState,
            trendsAddTimeStart: trendsAddTimeStart,
            trendsAddTimeEnd: trendsAddTimeEnd,
            pageNum: null
        })
    }

    //视频播放
    const [video, setVideo] = useState("");
    const [videoModalVisible, setVideoModalVisible] = useState(false);
    const showVideo = (value: string) => {
        setVideo(value);
        setVideoModalVisible(true);
    }

    //图片查看
    const [picList, setPicList] = useState([]);
    const [picModalVisible, setPicModalVisible] = useState(false);
    const showPic = (value: string) => {
        let picArray: any = [];
        if (value) {
            value.split(',').map((item: string) =>
                picArray.push(item)
            )
        }
        setPicList(picArray);
        setPicModalVisible(true);
    }

    //上架
    const { mutateAsync: showMutateAsync } = useShowTrends();
    const confirmShowTrends = (trendsId: number) => {
        Modal.confirm({
            title: "确定上架这条数据吗?",
            content: "点击确定上架",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    showMutateAsync({ trendsId }).then((data) => {
                        if (data.status === 0) {
                            message.info('上架成功');
                            Modal.destroyAll();
                        } else {
                            message.error(data.msg)
                        }
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };

    //下架
    const { mutateAsync: hideMutateAsync } = useHideTrends();
    const confirmHideTrends = (trendsId: number) => {
        Modal.confirm({
            title: "确定下架这条数据吗?",
            content: "点击确定下架",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    hideMutateAsync({ trendsId }).then((data) => {
                        if (data.status === 0) {
                            message.info('下架成功');
                            Modal.destroyAll();
                        } else {
                            message.error(data.msg)
                        }
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };

    //分页
    return (
        <div>
            <Card style={{ backgroundColor: "#f2f3f5", margin: '24px 0 24px 0' }}>
                <Form onFinish={onFinish}>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item name="trendsAddTimeRange" label="发布时间">
                                <RangePicker onChange={handleSelectTime} showTime format="YYYY-MM-DD HH:mm:ss" style={{ width: '100%' }} allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="trendsTitle" label="动态标题">
                                <Input placeholder='请输入动态标题' allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="trendsState" label="动态状态">
                                <Select
                                    placeholder='请选择动态状态'
                                    showSearch
                                    allowClear
                                    optionFilterProp='children'
                                >
                                    <Option value={0}>全部</Option>
                                    <Option value={1}>上架</Option>
                                    <Option value={2}>下架</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item style={{ marginBottom: 0 }}>
                        <Button style={{ marginLeft: 70 }} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Card>

            <Table
                loading={isLoading}
                rowKey={columns => columns.trendsId}
                pagination={paginationProps}
                columns={[
                    {
                        title: '标题',
                        dataIndex: 'trendsTitle',
                        key: 'trendsTitle',
                        align: 'center',
                        fixed: 'left',
                        width: 200,
                    },
                    {
                        title: '封面',
                        dataIndex: 'trendsPic',
                        key: 'trendsPic',
                        align: 'center',
                        width: 100,
                        render: (value) => (
                            value ? <Image src={imgUrl + value} width={80} ></Image> : ''
                        )
                    },
                    {
                        title: '类型',
                        dataIndex: 'trendsType',
                        key: 'trendsType',
                        align: 'center',
                        width: 100,
                        render: (value) => (
                            value === 1 ? '视频' : value === 2 ? '图片' : '-'
                        )
                    },
                    {
                        title: '浏览',
                        dataIndex: 'trendsBrowseNum',
                        key: 'trendsBrowseNum',
                        align: 'center',
                        width: 100,
                        render: (value, trends) => (
                            <Link
                                to={"/dress/trends-manage/trends/member-browse/list?identityType=10&identityId=" + trends.trendsId}
                            >
                                {value}
                            </Link>
                        ),
                    },
                    {
                        title: '收藏',
                        dataIndex: 'trendsCollectNum',
                        key: 'trendsCollectNum',
                        align: 'center',
                        width: 100,
                        render: (value, trends) => (
                            <Link
                                to={"/dress/trends-manage/trends/member-collect/list?identityType=10&identityId=" + trends.trendsId}
                            >
                                {value}
                            </Link>
                        ),
                    },
                    {
                        title: '评论',
                        dataIndex: 'trendsCommentNum',
                        key: 'trendsCommentNum',
                        align: 'center',
                        width: 100,
                        render: (value, trends) => (
                            <Link
                                to={"/dress/trends-manage/trends/trends-comment/list?trendsId=" + trends.trendsId}
                            >
                                {value}
                            </Link>
                        ),
                    },
                    {
                        title: '标签',
                        dataIndex: 'trendsTagNameList',
                        key: 'trendsTagNameList',
                        align: 'center',
                        width: 200,
                        render: (value) => (
                            value ? value.map(function (item: string) {
                                return <Tag color="cyan">{item}</Tag>
                            }) : ('-')
                        )
                    },
                    {
                        title: '发布者',
                        dataIndex: 'identityType',
                        key: 'identityType',
                        align: 'center',
                        width: 200,
                        render: (value, trends) => (
                            <div>
                                <div>
                                    {value === 2 ? (<Tag color="cyan">学校</Tag>)
                                        : value === 3 ? (<Tag color="cyan">机构</Tag>)
                                            : value === 4 ? (<Tag color="cyan">规划师</Tag>)
                                                : value === 5 ? (<Tag color="cyan">企业</Tag>)
                                                    : value === 6 ? (<Tag color="cyan">商家</Tag>)
                                                        : value === 7 ? (<Tag color="cyan">代理</Tag>)
                                                            : value === 8 ? (<Tag color="cyan">达人</Tag>)
                                                                : ''}
                                </div>
                                <div>{trends.identityName}</div>
                            </div>
                        )
                    },
                    {
                        title: '状态',
                        dataIndex: 'trendsShow',
                        key: 'trendsShow',
                        align: 'center',
                        width: 100,
                        render: (value) => (
                            value ? '上架' : '下架'
                        ),
                    },
                    {
                        title: '刷新时间',
                        dataIndex: 'trendsRefreshTime',
                        key: 'trendsRefreshTime',
                        align: 'center',
                        width: 200,
                        render: (value) => (
                            value ? dayjs(value).format('YYYY-MM-DD hh:mm:ss') : '-'
                        )
                    },
                    {
                        title: '发布时间',
                        dataIndex: 'trendsAddTime',
                        key: 'trendsAddTime',
                        align: 'center',
                        width: 200,
                        render: (value) => (
                            value ? dayjs(value).format('YYYY-MM-DD hh:mm:ss') : '-'
                        )
                    },
                    {
                        title: '操作',
                        key: 'action',
                        align: 'center',
                        render: (value, trends) => (
                            <Space size="middle">
                                {trends.trendsType === 1 ? (
                                    <a onClick={() => showVideo(trends.trendsVideo)}>
                                        播放
                                    </a>
                                ) : trends.trendsType === 2 ? (
                                    <a onClick={() => showPic(trends.trendsMorePic)}>
                                        查看
                                    </a>
                                ) : ('')}
                                {trends.trendsShow ? (
                                    <a onClick={() => confirmHideTrends(trends.trendsId)}>
                                        下架
                                    </a>
                                ) : (
                                    <a onClick={() => confirmShowTrends(trends.trendsId)}>
                                        上架
                                    </a>
                                )}
                            </Space>
                        ),
                        fixed: 'right',
                        width: 150,
                    }
                ]}
                dataSource={list || []}
                scroll={{ x: 1850 }}
            />
            <ErrorBox error={error} />
            <VideoPlayer
                video={video}
                modalVisible={videoModalVisible}
                setModalVisible={setVideoModalVisible}
            ></VideoPlayer>
            <PicPlayer
                picList={picList}
                modalVisible={picModalVisible}
                setModalVisible={setPicModalVisible}
            ></PicPlayer>
        </div>
    )
}