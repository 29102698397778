import { Button, Col, message, Modal, Row, Space, Table, Image, Card, Form, Input, Menu, Dropdown } from "antd";
import { DownOutlined } from "@ant-design/icons";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
import { ErrorBox } from "../../components/lib";
import { useAgencyList } from "../../services/agency-service";
import { useDebounce, useDocumentTitle } from "../../utils";
import { useAgencySearchParams } from "./util";
import { useAddAgencyHot, useDeleteAgencyHot } from "../../services/agency-hot-service";
import { useResetIdentityAccount } from "../../services/identity-account-service";
import "./list.css"

export const AgencyListPage = () => {
  useDocumentTitle("机构列表", true);
  const imgUrl = process.env.REACT_APP_IMG_URL;
  const [param, setParam] = useAgencySearchParams();
  const {
    isLoading,
    error,
    totalElements,
    data: list,
  } = useAgencyList(useDebounce(param, 200));

  //分页
  const paginationProps = {
    showSizeChanger: true,
    onShowSizeChange: (current: number, pageSize: number) => {
      setParam({
        ...param,
        pageNum: current - 1,
        pageSize,
      });
    },
    defaultCurrent: 1,
    pageSize: param.pageSize,
    total: totalElements,
    onChange: (current: number, pageSize: number) =>
      setParam({
        ...param,
        pageNum: current - 1,
        pageSize,
      }),
  };
  //搜索
  const onFinish = (values: any) => {
    setParam({
      ...param,
      agencyName: values.agencyName,
      pageNum: null,
    });
  };

  //   编辑下拉菜单
  const optionMenu: any = (record: any) => {
    let hotAdd = record.hasHot ? false : true;
    return (
      <Menu>
        <Menu.Item>
          <Link
            to={
              "/jigou/agency-manage/agency/update/" + record.agencyId
            }
          >
            编辑
          </Link>
        </Menu.Item>
        {record.hasHot === false ?
          <Menu.Item>
            <a onClick={() => confirmAddAgencyHot(record.agencyId)}>热门推荐</a>
          </Menu.Item>
          :
          <Menu.Item>
            <a onClick={() => confirmDeleteAgencyHot(record.agencyId)}>取消推荐</a>
          </Menu.Item>
        }
        <Menu.Item>
          <a onClick={() => confirmResetIdentityAccount(3, record.agencyId)}>重置密码</a>
        </Menu.Item>
      </Menu>
    );
  };

  //热门推荐事件
  const { mutateAsync: addAgencyHotAsync } = useAddAgencyHot();
  const confirmAddAgencyHot = (agencyId: number) => {
    Modal.confirm({
      title: "确定推荐这条数据吗?",
      content: "点击确定推荐",
      okText: "确定",
      onOk(): any {
        return new Promise((resolve, reject) => {
          addAgencyHotAsync({ agencyId }).then((data) => {
            if (data.status === 0) {
              message.info("增加成功");
              Modal.destroyAll();
            } else {
              message.error(data.msg);
            }
          });
        }).catch(() => console.log("Oops errors!"));
      },
      cancelText: "取消",
    });
  };
  //热门推荐事件
  const { mutateAsync: deleteAgencyHotAsync } = useDeleteAgencyHot();
  const confirmDeleteAgencyHot = (agencyId: number) => {
    Modal.confirm({
      title: "确定取消推荐这条数据吗?",
      content: "点击确定取消",
      okText: "确定",
      onOk(): any {
        return new Promise((resolve, reject) => {
          deleteAgencyHotAsync({ agencyId }).then((data) => {
            if (data.status === 0) {
              message.info("取消成功");
              Modal.destroyAll();
            } else {
              message.error(data.msg);
            }
          });
        }).catch(() => console.log("Oops errors!"));
      },
      cancelText: "取消",
    });
  };

  //重置密码
  const { mutateAsync: resetIdentityAccount } = useResetIdentityAccount();
  const confirmResetIdentityAccount = (identityType: number, identityId: number) => {
    Modal.confirm({
      title: "确定操作这条数据吗?",
      content: "点击确定取消",
      okText: "确定",
      onOk(): any {
        return new Promise((resolve, reject) => {
          resetIdentityAccount({ identityType, identityId }).then((data) => {
            if (data.status === 0) {
              message.info("操作成功");
              Modal.destroyAll();
            } else {
              message.error(data.msg);
            }
          });
        }).catch(() => console.log("Oops errors!"));
      },
      cancelText: "取消",
    });
  };

  return (
    <div>
      <Card style={{ backgroundColor: "#f2f3f5", marginBottom: 24 }}>
        <Form onFinish={onFinish}>
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item name="agencyName" label="机构名称">
                <Input placeholder="请输入机构名称" allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item style={{ marginBottom: 0 }}>
            <Button style={{ marginLeft: 70 }} type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <Table
        loading={isLoading}
        rowKey={(columns) => columns.agencyId}
        pagination={paginationProps}
        columns={[
          {
            title: "ID",
            dataIndex: "agencyId",
            key: "agencyId",
            align: "center",
            width: 100,
          },

          {
            title: "机构名称",
            dataIndex: "agencyName",
            key: "agencyName",
            align: "center",
          },

          {
            title: "图片",
            dataIndex: "agencyPic",
            key: "agencyPic",
            align: "center",
            width: 150,
            render: (value) => (value ? <Image width={60} src={imgUrl + value} /> : ""),
          },

          {
            title: "机构介绍",
            dataIndex: "agencyIntroduce",
            key: "agencyIntroduce",
            align: "center",
            width: 150,
            render: (value) => (<><div className="more-line-ellipsis">{value ? value : ""}</div></>)
          },

          {
            title: "老师数量",
            dataIndex: "agencyTeacherNum",
            key: "agencyTeacherNum",
            align: "center",
          },

          {
            title: "添加机构时间",
            dataIndex: "agencyAddTime",
            key: "agencyAddTime",
            align: "center",
            render: (value) => (
              <span>
                {value ? dayjs(value).format("YYYY-MM-DD HH:mm:ss") : "无"}
              </span>
            ),
          },

          {
            title: "机构电话",
            dataIndex: "agencyTel",
            key: "agencyTel",
            align: "center",
          },

          {
            title: "主攻专业",
            dataIndex: "agencyMajor",
            key: "agencyMajor",
            align: "center",
          },

          {
            title: "位置",
            dataIndex: "areaDetail",
            key: "areaDetail",
            align: "center",
            render(value, record, index) {
              return (
                <>
                  {record?.areaProvince
                    ? record?.areaProvince +
                      record?.areaCity +
                      record?.areaDistrict +
                      record?.areaDetail ? record?.areaDetail : ""
                    : ""}
                </>
              );
            },
          },

          {
            title: "联系人",
            dataIndex: "agencyConcatName",
            key: "agencyConcatName",
            align: "center",
          },

          {
            title: "联系人电话",
            dataIndex: "agencyConcatTel",
            key: "agencyConcatTel",
            align: "center",
          },

          {
            title: "机构排序",
            dataIndex: "agencySort",
            key: "agencySort",
            align: "center",
          },

          {
            title: "备注",
            dataIndex: "mark",
            key: "mark",
            align: "center",
          },

          {
            title: "操作",
            key: "action",
            align: "center",
            fixed: "right",
            render: (value, agency) => (
              <Space size="middle">
                <Dropdown dropdownRender={optionMenu.bind(this, agency)}>
                  <a onClick={(e) => e.preventDefault()}>
                    <Space>
                      操作
                      <DownOutlined />
                    </Space>
                  </a>
                </Dropdown>
                <Link to={"/jigou/agency-manage/agency/detail?agencyId=" + agency.agencyId}>
                  详情
                </Link>
              </Space>
            ),
            width: 200,
          },
        ]}
        dataSource={list || []}
        scroll={{ x: 1800 }}
      />
      <ErrorBox error={error} />
    </div>
  );
};
