import { useMutation, useQuery, useQueryClient } from "react-query";
import { WithdrawSetting } from "../interfaces/withdraw-setting";
import { useHttp } from "../utils/http";

export const useEditWithdrawSetting = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<WithdrawSetting>) => client(`withdrawSetting`, {
            method: 'POST',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('withdrawSetting')
        }
    )
}

export const useWithdrawSetting = () => {
    const client = useHttp()
    return useQuery<any>(
        ['withdrawSetting', {}],
        () => client(`withdrawSetting`),
    )
}