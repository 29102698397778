import { Button, Col, Divider, Form, Input, message, Radio, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAddCompanyPositionClass } from "../../services/company-position-class-service";
import { useDocumentTitle } from "../../utils"

export const CompanyPositionClassAddPage = () => {
    //标题
    useDocumentTitle('新建职位分类', true)
    const navigate = useNavigate();

    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };

    //表单
    const [form] = useForm();

    //新建
    const [submitLoading, setSubmitLoading] = useState(false);
    const { mutateAsync } = useAddCompanyPositionClass();
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...values }).then((data) => {
            if (data.status === 0) {
                message.success(data.msg);
                navigate('/qiye/recruit-manage/company-position-class/list')
            } else {
                setSubmitLoading(false);
                message.error(data.msg);
            }
        }).catch(err => {
            message.error('提交失败，请重新提交');
            setSubmitLoading(false);
        });
    };

    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/qiye/recruit-manage/company-position-class/list"}>
                        <Button type="primary">列表</Button>
                    </Link>
                </Col>
            </Row>

            <Divider style={{ marginBottom: 24 }} />

            <Form
                form={form}
                {...layout}
                onFinish={onFinish}
                initialValues={{
                    companyPositionClassSort: 0,
                    companyPositionClassShow: true
                }}>
                <Form.Item
                    label={"名称"}
                    name={"companyPositionClassName"}
                    rules={[{ required: true, message: "名称必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入名称"} />
                </Form.Item>

                <Form.Item
                    label={"排序"}
                    name={"companyPositionClassSort"}
                    rules={[{ required: true, message: "排序必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入排序"} />
                </Form.Item>

                <Form.Item
                    label={"状态"}
                    name={"companyPositionClassShow"}
                    rules={[{ required: true, message: "状态必选" }]}
                    style={{ textAlign: 'left' }}
                >
                    <Radio.Group>
                        <Radio defaultChecked value={true}>显示</Radio>
                        <Radio value={false}>不显示</Radio>
                    </Radio.Group>
                </Form.Item>

                <Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                    <Button loading={submitLoading} type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

