// 在真实环境中，如果使用firebase这种第三方auth服务的话，本文件不需要开发者开发

import { AdminAccount } from "../interfaces/admin-account";

const apiUrl = process.env.REACT_APP_API_URL;

const localStorageKey = "__auth_provider_xiaoqihui_admin_token__";

export const getToken = () => window.localStorage.getItem(localStorageKey);

export const handleAdminResponse = ({adminAccount}: {adminAccount: AdminAccount}) => {
    if(adminAccount.token != null) {
        window.localStorage.setItem(localStorageKey, adminAccount.token || '');
        return adminAccount;
    } else {
        return null;
    }
}

export const login = (data: { adminAccountName: string; adminAccountPasswordStr: string}) => {
    return fetch(`${apiUrl}/logins`, {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
    }).then(async (response: any) => {
        if(response.ok) {
            return response.json();
        } else {
            return Promise.reject(data)
        }
    });
}

export const logout = async () => {
    window.localStorage.removeItem(localStorageKey);
    window.location.href = "/";
}



