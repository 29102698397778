import { Button, Col, message, Modal, Row, Space, Table, Image } from "antd"
import Search from "antd/lib/input/Search"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useTabBarList, useDeleteTabBar } from "../../services/tab-bar-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useTabBarSearchParams, getTabBarTypeName } from "./util"

export const TabBarListPage = () => {
    useDocumentTitle('标签栏列表', true)
    const imgUrl = process.env.REACT_APP_IMG_URL;
    const [param, setParam] = useTabBarSearchParams()
    const { isLoading, error, totalElements, data: list } = useTabBarList(useDebounce(param, 200))

    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) =>
            setParam({
                ...param,
                pageNum: (current - 1),
            })
    }

    //删除事件
    const { mutateAsync: deleteMutateAsync } = useDeleteTabBar();
    const confirmDeleteTabBar = (tabBarId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    deleteMutateAsync({ tabBarId }).then((data) => {
                        if (data.status === 0) {
                            message.info('删除成功');
                            Modal.destroyAll();
                        } else {
                            message.error(data.msg)
                        }
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    //分页
    return (
        <div>
            <Row style={{ marginBottom: 24 }}>
                <Col span={12}>
                    <Link to={"/dress/decorate-manage/tab-bar/add"}>
                        <Button type="primary">新建</Button>
                    </Link>
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                    <Search
                        placeholder="可搜索标签栏标题"
                        style={{ width: 200 }}
                        value={param.tabBarTitle}
                        onChange={(evt) =>
                            setParam({
                                ...param,
                                tabBarTitle: evt.target.value,
                                pageNum: null
                            })}
                        onSearch={(value) =>
                            setParam({
                                ...param,
                                tabBarTitle: value,
                                pageNum: null
                            })}
                    />
                </Col>
            </Row>
            <Table
                loading={isLoading}
                rowKey={columns => columns.tabBarId}
                pagination={paginationProps}
                columns={[
                    {
                        title: 'ID',
                        dataIndex: 'tabBarId',
                        key: 'tabBarId',
                        align: 'center',
                        width: 100,
                    },
                    {
                        title: '标题',
                        dataIndex: 'tabBarTitle',
                        key: 'tabBarTitle',
                        align: 'center',
                    },
                    {
                        title: '图标',
                        dataIndex: 'tabBarPic',
                        key: 'tabBarPic',
                        align: 'center',
                        render: (value) => (
                            value ? <Image width={60} src={imgUrl + value} /> : ''
                        ),
                    },
                    {
                        title: '类型',
                        dataIndex: 'tabBarType',
                        key: 'tabBarType',
                        align: 'center',
                        render: (value) => (
                            getTabBarTypeName(value)
                        ),
                    },
                    {
                        title: '排序',
                        dataIndex: 'tabBarSort',
                        key: 'tabBarSort',
                        align: 'center',
                    },
                    {
                        title: '状态',
                        dataIndex: 'tabBarShow',
                        key: 'tabBarShow',
                        align: 'center',
                        render: (value) => (
                            value ? '显示' : '不显示'
                        ),
                    },
                    {
                        title: '默认',
                        dataIndex: 'tabBarDefault',
                        key: 'tabBarDefault',
                        align: 'center',
                        render: (value) => (
                            value ? '是' : '否'
                        ),
                    },
                    {
                        title: '操作',
                        key: 'action',
                        align: 'center',
                        render: (value, tabBar) => (
                            <Space size="middle">
                                <Link to={"/dress/decorate-manage/tab-bar/adv/list?tabBarId=" + tabBar.tabBarId}>
                                    广告
                                </Link>
                                <Link to={"/dress/decorate-manage/tab-bar/update/" + tabBar.tabBarId}>
                                    编辑
                                </Link>
                                <a onClick={() => confirmDeleteTabBar(tabBar.tabBarId)}>
                                    删除
                                </a>
                            </Space>
                        ),
                        width: 200,
                    }
                ]}
                dataSource={list || []} />
            <ErrorBox error={error} />
        </div>
    )
}