import { Col, Row, Space, Table, Tag } from "antd"
import Search from "antd/lib/input/Search"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useTemplateMessageList } from "../../services/template-message-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useTemplateMessageSearchParams } from "./util"

export const TemplateMessageListPage = () => {
    useDocumentTitle('模板消息', true)
    const [param, setParam] = useTemplateMessageSearchParams()
    const { isLoading, error, totalElements, data: list } = useTemplateMessageList(useDebounce(param, 200))
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) =>
            setParam({
                ...param,
                pageNum: (current - 1),
            })
    }
    //分页
    return (
        <div>
            <Row style={{ marginBottom: 24 }}>
                <Col span={12}>
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                    <Search
                        placeholder="可搜索模板标题"
                        style={{ width: 200 }}
                        value={param.templateMessageTitle}
                        onChange={(evt) =>
                            setParam({
                                ...param,
                                templateMessageTitle: evt.target.value,
                                pageNum: null
                            })}
                        onSearch={(value) =>
                            setParam({
                                ...param,
                                templateMessageTitle: value,
                                pageNum: null
                            })}
                    />
                </Col>
            </Row>
            <Table
                loading={isLoading}
                pagination={paginationProps}
                rowKey={columns => columns.templateMessageId}
                columns={[
                    {
                        title: '标题',
                        dataIndex: 'templateMessageTitle',
                        key: 'templateMessageTitle',
                        width: 200,
                        fixed: 'left',
                    },
                    {
                        title: '编号',
                        dataIndex: 'templateMessageSn',
                        key: 'templateMessageSn',
                    },
                    {
                        title: '状态',
                        dataIndex: 'templateMessageStatus',
                        key: 'templateMessageStatus',
                        width: 150,
                        render: (value, templateMessage) => (
                            <span>{templateMessage.templateMessageStatus ? '启用' : '停用'}</span>
                        ),
                    },
                    {
                        title: '颜色',
                        dataIndex: 'templateMessageColor',
                        key: 'templateMessageColor',
                        width: 150,
                        render: (value, templateMessage) => (
                            <Tag color={templateMessage.templateMessageColor} style={{ width: "10px", height: "15px" }}></Tag>
                        ),
                    },
                    {
                        title: '操作',
                        key: 'action',
                        render: (value, templateMessage) => (
                            <Space size="middle">
                                <Link to={"/setting/wechat-manage/template-message/update/" + templateMessage.templateMessageId}>
                                    编辑
                                </Link>
                            </Space>
                        ),
                        width: 100,
                        fixed: 'right',
                    },
                ]}
                dataSource={list || []}
                scroll={{ x: 1500 }}
            />
            <ErrorBox error={error} />
        </div>
    )
}