import { useMutation, useQuery, useQueryClient } from "react-query";
import { BusinessCircle } from "../interfaces/business-circle";
import { BusinessCircleBusinessCircleTag } from "../interfaces/business-circle-business-circle-tag";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useBusinessCircleList = (param?: Partial<BusinessCircle>) => {
    const client = useHttp();
    const result = useQuery<any>(['businessCircleList', param], () => client('businessCircle', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditBusinessCircle = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<BusinessCircle>) => client(`businessCircle`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('businessCircleList')
        }
    )
}

export const useAddBusinessCircle = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<BusinessCircle>) => client(`businessCircle`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('businessCircleList')
        }
    )
}

export const useDeleteBusinessCircle = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ businessCircleId }: { businessCircleId: number}) =>
            client(`businessCircle/${businessCircleId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('businessCircleList')
        }
    );
};

export const useBusinessCircle = (businessCircleId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['businessCircle', {businessCircleId}],
        () => client(`businessCircle/${businessCircleId}`),
        {
            enabled: Boolean(businessCircleId)
        }
    )
}

//查询标签
export const useBusinessCircleBusinessCircleTagList = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ businessCircleId }: { businessCircleId: number }) => client(`businessCircleBusinessCircleTag?businessCircleId=${businessCircleId}`, {
            method: "GET",
        }), {
            onSuccess: () => queryClient.invalidateQueries('businessCircleBusinessCircleTagList')
        }
    );
};

//设置标签
export const useAddBusinessCircleBusinessCircleTag = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<BusinessCircleBusinessCircleTag>) => client(`businessCircleBusinessCircleTag`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('businessCircle')
        }
    )
}
