import { useMutation, useQuery, useQueryClient } from "react-query";
import { TemplateMessage } from "../interfaces/template-message";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useTemplateMessageList = (param?: Partial<TemplateMessage>) => {
    const client = useHttp();
    const result = useQuery<any>(['templateMessagePage', param], () => client('templateMessage', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditTemplateMessage = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<TemplateMessage>) => client(`templateMessage`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('templateMessagePage')
        }
    )
}

export const useTemplateMessage = (templateMessageId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['templateMessage', {templateMessageId}],
        () => client(`templateMessage/${templateMessageId}`),
        {
            enabled: Boolean(templateMessageId)
        }
    )
}