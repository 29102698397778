import { Button, Form, Input, Menu, message, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import MenuItem from "antd/lib/menu/MenuItem";
import BraftEditor from "braft-editor";
import { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import RichText from "../../components/rich-text";
import {
  useAuthTreaty,
  useEditAuthTreaty,
} from "../../services/auth-treaty-service";
import { useDocumentTitle } from "../../utils";
import { useAuthTreatySearchParams } from "./util";

export const AuthTreatyIndexPage = () => {
  //标题
  useDocumentTitle("认证协议", true);
  const [param] = useAuthTreatySearchParams();

  //布局
  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 18 },
  };
  const tailLayout = {
    wrapperCol: { offset: 3 },
  };
  //表单
  const [form] = useForm();
  const { data: editingAuthTreaty, isLoading } = useAuthTreaty(
    param?.authTreatyType ? Number(param.authTreatyType) : 1
  );
  const [authTreatyContent, setAuthTreatyContent] = useState("");
  useEffect(() => {
    form.setFieldsValue(editingAuthTreaty?.data);
    setAuthTreatyContent(editingAuthTreaty?.data?.authTreatyContent);
    form.setFieldsValue({
      authTreatyTitle: editingAuthTreaty?.data?.authTreatyTitle,
      authTreatyContent: BraftEditor.createEditorState(
        editingAuthTreaty?.data?.authTreatyContent
      ),
    });
  }, [editingAuthTreaty, form, param]);

  //提交
  const { mutateAsync } = useEditAuthTreaty();
  const [submitLoading, setSubmitLoading] = useState(false);
  const onFinish = (values: any) => {
    setSubmitLoading(true);
    mutateAsync({
      ...editingAuthTreaty?.data,
      ...values,
      authTreatyType: param.authTreatyType ? Number(param.authTreatyType) : 1,
      authTreatyContent,
    }).then(() => {
      message.info("提交成功");
      setSubmitLoading(false);
    });
  };
  return (
    <div>
      <Menu
        selectedKeys={[
          param?.authTreatyType ? String(param.authTreatyType) : "1",
        ]}
        mode="horizontal"
      >
        <MenuItem key={"1"}>
          <Link to={"/setting/treaty-manage/auth-treaty?authTreatyType=1"}>
            用户
          </Link>
        </MenuItem>
        <MenuItem key={"2"}>
          <Link to={"/setting/treaty-manage/auth-treaty?authTreatyType=2"}>
            学校
          </Link>
        </MenuItem>
        <MenuItem key={"3"}>
          <Link to={"/setting/treaty-manage/auth-treaty?authTreatyType=3"}>
            机构
          </Link>
        </MenuItem>
        <MenuItem key={"4"}>
          <Link to={"/setting/treaty-manage/auth-treaty?authTreatyType=4"}>
            规划师
          </Link>
        </MenuItem>
        <MenuItem key={"5"}>
          <Link to={"/setting/treaty-manage/auth-treaty?authTreatyType=5"}>
            企业
          </Link>
        </MenuItem>
        <MenuItem key={"13"}>
          <Link to={"/setting/treaty-manage/auth-treaty?authTreatyType=13"}>
            HR
          </Link>
        </MenuItem>
        <MenuItem key={"6"}>
          <Link to={"/setting/treaty-manage/auth-treaty?authTreatyType=6"}>
            商家
          </Link>
        </MenuItem>
        <MenuItem key={"7"}>
          <Link to={"/setting/treaty-manage/auth-treaty?authTreatyType=7"}>
            代理
          </Link>
        </MenuItem>
        <MenuItem key={"8"}>
          <Link to={"/setting/treaty-manage/auth-treaty?authTreatyType=8"}>
            达人
          </Link>
        </MenuItem>
      </Menu>
      <Spin spinning={isLoading}>
        <Form
          form={form}
          {...layout}
          onFinish={onFinish}
          style={{ marginTop: 24 }}
        >
          <Form.Item
            label={"标题"}
            name={"authTreatyTitle"}
            rules={[{ required: true, message: "标题必填" }]}
          >
            <Input style={{ width: 380 }} placeholder={"请输入标题"} />
          </Form.Item>

          <RichText
            label={"内容"}
            name={"authTreatyContent"}
            setContent={setAuthTreatyContent}
          ></RichText>

          <Form.Item {...tailLayout} style={{ textAlign: "left" }}>
            <Button loading={submitLoading} type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
