import { useMutation, useQuery, useQueryClient } from "react-query"
import { SchoolOrdersSetting } from "../interfaces/school-orders-setting"
import { useHttp } from "../utils/http"

export const useSchoolOrdersSetting = () => {
    const client = useHttp()
    return useQuery<any>(
        ['schoolOrdersSetting', {}],
        () => client(`schoolOrdersSetting`),
    )
}

export const useEditSchoolOrdersSetting = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<SchoolOrdersSetting>) => client(`schoolOrdersSetting`, {
            method: 'POST',
            data: params
        }), {
        onSuccess: () => queryClient.invalidateQueries('schoolOrdersSetting')
    }
    )
}