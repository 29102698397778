import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHttp } from "../utils/http";
import { Seckill } from "../interfaces/seckill";

//列表 
export const useSeckillList = (param?: Partial<Seckill>) => {
    const client = useHttp();
    const result = useQuery<any>(["seckillList", param], () =>
        client("seckill", { data: param })
    );
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
};

//新增
export const useAddSeckill = () => {
    const client = useHttp();
    const queryClient = useQueryClient();
    return useMutation(
        (params: Partial<Seckill>) =>
            client(`seckill`, {
                data: params,
                method: "POST"
            }), {
                onSuccess: () => queryClient.invalidateQueries('seckillList')
            }
    );
};

//编辑
export const useEditSeckill = () => {
    const client = useHttp();
    const queryClient = useQueryClient();
    return useMutation(
        (params: Partial<Seckill>) =>
            client(`seckill`, {
                data: params,
                method: "PUT"
            }), {
                onSuccess: () => queryClient.invalidateQueries('seckillList')
            }
    );
};

//失效
export const useDeleteSeckill = () => {
    const client = useHttp();
    const queryClient = useQueryClient();
    return useMutation(
        ({ seckillId }: { seckillId: number}) =>
            client(`seckill/${seckillId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('seckillList')
        }
    );
};

export const useSeckill = (seckillId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['seckill', {seckillId}],
        () => client(`seckill/${seckillId}`),
        {
            enabled: Boolean(seckillId)
        }
    )
}


