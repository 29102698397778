import { useMutation, useQuery, useQueryClient } from "react-query";
import { PlannerEva } from "../interfaces/planner-eva";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const usePlannerEvaList = (param?: Partial<PlannerEva>) => {
    const client = useHttp();
    const result = useQuery<any>(['plannerEvaPage', param], () => client('plannerEva', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditPlannerEva = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<PlannerEva>) => client(`plannerEva`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('plannerEvaPage')
        }
    )
}

export const useAddPlannerEva = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<PlannerEva>) => client(`plannerEva`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('plannerEvaPage')
        }
    )
}

export const useDeletePlannerEva = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ plannerEvaId }: { plannerEvaId: number}) =>
            client(`plannerEva/${plannerEvaId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('plannerEvaPage')
        }
    );
};

export const usePlannerEva = (plannerEvaId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['plannerEva', {plannerEvaId}],
        () => client(`plannerEva/${plannerEvaId}`),
        {
            enabled: Boolean(plannerEvaId)
        }
    )
}