import { useMutation, useQuery, useQueryClient } from "react-query";
import { StoreAccount } from "../interfaces/store-account";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useStoreAccountList = (param?: Partial<StoreAccount>) => {
    const client = useHttp();
    const result = useQuery<any>(['storeAccountList', param], () => client('storeAccount/list', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditStoreAccount = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<StoreAccount>) => client(`storeAccount`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('storeAccountList')
        }
    )
}

export const useAddStoreAccount = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<StoreAccount>) => client(`storeAccount`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('storeAccountList')
        }
    )
}

export const useDeleteStoreAccount = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ storeAccountId }: { storeAccountId: number}) =>
            client(`storeAccount/${storeAccountId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('storeAccountList')
        }
    );
};

export const useStoreAccount = (storeAccountId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['storeAccount', {storeAccountId}],
        () => client(`storeAccount/${storeAccountId}`),
        {
            enabled: Boolean(storeAccountId)
        }
    )
}

// 重置密码
export const useResetPassword = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ storeAccountId }: { storeAccountId: number}) =>
            client(`storeAccount/${storeAccountId}/reset`, {
            method: "Put",
        }), {
            onSuccess: () => (queryClient.invalidateQueries('storeAccountList'))
            
        }
    );
};

export const useResetStoreAccount = () => {
    const client = useHttp()
    return useMutation(
        ({ storeId }: { storeId: number}) =>
            client(`storeAccount/reset?storeId=${storeId}`, {
            method: "Put",
        })
    );
};

export const useEditStoreAccountPwd = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<StoreAccount>) => client(`storeAccount/updatePwd`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('storeAccount')
        }
    )
}