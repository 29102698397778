import { Button, Col, Divider, Form, Input, message, Radio, Row, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAddAdminAccount } from "../../services/admin-account-service";
import { useAdminRoleListAll } from "../../services/admin-role-service";
import { useAgentListAll } from "../../services/agent-service";
import { useDocumentTitle } from "../../utils";
const { Option } = Select;

export const AdminAccountAddPage = () => {
    //标题
    useDocumentTitle('新建账号', true)
    const navigate = useNavigate();
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    //角色列表
    const { data: roleList } = useAdminRoleListAll()
    const options = roleList?.data?.map((item: any, value: any) => (
        <Option value={item.adminRoleId} key={item.adminRoleId}>
            {item.adminRoleName}
        </Option>
    ))
    //代理列表
    const { data: agentList } = useAgentListAll()
    const agentOptions = agentList?.data?.map((item: any, value: any) => (
        <Option value={item.agentId} key={item.agentId}>
            {item.agentName}
        </Option>
    ))
    const [adminRoleId, setAdminRoleId] = useState();
    const handleRole = (e: any) => {
        setAdminRoleId(e);
    }
    //是否超级管理员
    const [isSuper, setIsSuper] = useState(false);
    const selectSuper = (values: any) => {
        setIsSuper(values.target.value);
    }
    //表单
    const [form] = useForm();
    //提交表单
    const [submitLoading, setSubmitLoading] = useState(false);
    const { mutateAsync } = useAddAdminAccount();
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...values, adminRoleId }).then((data) => {
            if (data.status === 0) {
                message.success(data.msg);
                navigate('/setting/account-manage/admin-account/list')
            } else {
                setSubmitLoading(false);
                message.error(data.msg);
            }
        }).catch(err => {
            message.error('提交失败，请重新提交');
            setSubmitLoading(false);
        });
    };

    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/setting/account-manage/admin-account/list"}>
                        <Button type="primary">列表</Button>
                    </Link>
                </Col>
            </Row>

            <Divider style={{ marginBottom: 24 }} />

            <Form
                form={form}
                {...layout}
                onFinish={onFinish}
                initialValues={{
                    adminAccountSuper: false
                }}
            >

                <Form.Item
                    label={"昵称"}
                    name={"adminAccountNickname"}
                    rules={[{ required: true, message: "昵称必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入昵称"} />
                </Form.Item>

                <Form.Item
                    label={"账号"}
                    name={"adminAccountName"}
                    rules={[{ required: true, message: "账号必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入账号"} />
                </Form.Item>

                <Form.Item
                    label={"密码"}
                    name={"adminAccountPasswordStr"}
                    rules={[{ required: true, message: "密码必填" }]}
                >
                    <Input.Password style={{ width: 380 }} placeholder={"请输入密码"} />
                </Form.Item>

                <Form.Item
                    label={"身份"}
                    name={"adminAccountSuper"}
                    rules={[{ required: true, message: "身份必选" }]}
                    style={{ textAlign: 'left' }}
                >
                    <Radio.Group onChange={selectSuper}>
                        <Radio defaultChecked value={false}>普通账户</Radio>
                        <Radio value={true}>管理员</Radio>
                    </Radio.Group>
                </Form.Item>

                {!isSuper ? (
                    <Form.Item
                        label={"角色"}
                        name={"adminRoleId"}
                        rules={[{ required: true, message: "角色必选" }]}
                        style={{ textAlign: 'left' }}
                    >
                        <Select
                            showSearch
                            allowClear
                            placeholder='请选择角色'
                            optionFilterProp='children'
                            style={{ textAlign: 'left', width: 380 }}
                            onChange={handleRole}
                        >
                            {options}
                        </Select>
                    </Form.Item>
                ) : ('')}

                {adminRoleId === 1 ? (
                    <Form.Item
                        label={"代理"}
                        name={"agentId"}
                        rules={[{ required: true, message: "代理必选" }]}
                        style={{ textAlign: 'left' }}
                    >
                        <Select
                            showSearch
                            allowClear
                            placeholder='请选择代理'
                            optionFilterProp='children'
                            style={{ textAlign: 'left', width: 380 }}
                        >
                            {agentOptions}
                        </Select>
                    </Form.Item>
                ) : ('')}

                <Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                    <Button loading={submitLoading} type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}