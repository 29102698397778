import { useMemo } from "react";
import { useUrlQueryParam } from "../../utils/url";

export const useStoreSearchParams = () => {
    const [param, setParam] = useUrlQueryParam(["storeId", "storeAddTimeStart", "storeAddTimeEnd", "storeName", "storeAttributeId", "storeTypeId", "memberNickname", "businessCircleId", "pageNum"]);
    return [
        useMemo(() => ({
            ...param, storeId: Number(param.storeId) || undefined, storeAttributeId: Number(param.storeAttributeId) || undefined,
            storeTypeId: Number(param.storeTypeId) || undefined, businessCircleId: Number(param.businessCircleId) || undefined, pageNum: Number(param.pageNum) || undefined
        }), [param]),
        setParam
    ] as const
}