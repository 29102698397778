import { useMutation, useQuery, useQueryClient } from "react-query";
import { SearchDefault } from "../interfaces/search-default";
import { useHttp } from "../utils/http";

export const useEditSearchDefault = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<SearchDefault>) => client(`searchDefault`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('searchDefault')
        }
    )
}

export const useSearchDefault = () => {
    const client = useHttp()
    return useQuery<any>(
        ['searchDefault', {}],
        () => client(`searchDefault`)
    )
}