import { Button, Col, message, Modal, Row, Space, Table, Image, Breadcrumb } from "antd"
import Search from "antd/lib/input/Search"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useTabBarAdvList, useDeleteTabBarAdv } from "../../services/tab-bar-adv-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useTabBarAdvSearchParams } from "./util"

export const TabBarAdvListPage = () => {
    useDocumentTitle('标签栏广告列表', true)
    const imgUrl = process.env.REACT_APP_IMG_URL;
    const [param, setParam] = useTabBarAdvSearchParams()
    const { isLoading, error, totalElements, data: list } = useTabBarAdvList(useDebounce(param, 200))

    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) =>
            setParam({
                ...param,
                pageNum: (current - 1),
            })
    }

    //删除事件
    const { mutateAsync: deleteMutateAsync } = useDeleteTabBarAdv();
    const confirmDeleteTabBarAdv = (tabBarAdvId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    deleteMutateAsync({ tabBarAdvId }).then((data) => {
                        if (data.status === 0) {
                            message.info('删除成功');
                            Modal.destroyAll();
                        } else {
                            message.error(data.msg)
                        }
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    //分页
    return (
        <div>
            <Breadcrumb style={{ marginBottom: 24 }}>
                <Breadcrumb.Item>
                    <Link to={"/dress/decorate-manage/tab-bar/list"}>
                        标签栏列表
                    </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    广告列表
                </Breadcrumb.Item>
            </Breadcrumb>
            <Row style={{ marginBottom: 24 }}>
                <Col span={12}>
                    <Link to={"/dress/decorate-manage/tab-bar/adv/add?tabBarId=" + param?.tabBarId}>
                        <Button type="primary">新建</Button>
                    </Link>
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                    <Search
                        placeholder="可搜索广告名称"
                        style={{ width: 200 }}
                        value={param.tabBarAdvName}
                        onChange={(evt) =>
                            setParam({
                                ...param,
                                tabBarAdvName: evt.target.value,
                                pageNum: null
                            })}
                        onSearch={(value) =>
                            setParam({
                                ...param,
                                tabBarAdvName: value,
                                pageNum: null
                            })}
                    />
                </Col>
            </Row>
            <Table
                loading={isLoading}
                rowKey={columns => columns.tabBarAdvId}
                pagination={paginationProps}
                columns={[
                    {
                        title: 'ID',
                        dataIndex: 'tabBarAdvId',
                        key: 'tabBarAdvId',
                        align: 'center',
                        width: 100,
                    },
                    {
                        title: '名称',
                        dataIndex: 'tabBarAdvName',
                        key: 'tabBarAdvName',
                        align: 'center',
                    },
                    {
                        title: '图片',
                        dataIndex: 'tabBarAdvPic',
                        key: 'tabBarAdvPic',
                        align: 'center',
                        render: (value) => (
                            <Image width={150} src={imgUrl + value} />
                        ),
                    },
                    {
                        title: '排序',
                        dataIndex: 'tabBarAdvSort',
                        key: 'tabBarAdvSort',
                        align: 'center',
                    },
                    {
                        title: '状态',
                        dataIndex: 'tabBarAdvShow',
                        key: 'tabBarAdvShow',
                        align: 'center',
                        render: (value) => (
                            <span>{value ? '显示' : '不显示'}</span>
                        ),
                    },
                    {
                        title: '操作',
                        key: 'action',
                        align: 'center',
                        render: (value, tabBarAdv) => (
                            <Space size="middle">
                                <Link to={"/dress/decorate-manage/tab-bar/adv/update/" + tabBarAdv.tabBarAdvId}>
                                    编辑
                                </Link>
                                <a onClick={() => confirmDeleteTabBarAdv(tabBarAdv.tabBarAdvId)}>
                                    删除
                                </a>
                            </Space>
                        ),
                        width: 150,
                    }
                ]}
                dataSource={list || []} />
            <ErrorBox error={error} />
        </div>
    )
}