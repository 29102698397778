import { Button, Card, Col, Form, message, Modal, Row, Space, Table, DatePicker, Select, Input, Image } from "antd"
import dayjs from "dayjs"
import { useState } from "react"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useStoreAttributeListAll } from "../../services/store-attribute-service"
import { useStoreList, useDeleteStore } from "../../services/store-service"
import { useStoreTypeListAll } from "../../services/store-type-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useStoreSearchParams } from "./util"
import picNone from "../../assets/pic-none.png"
import { useResetStoreAccount } from "../../services/store-account-service"
const { RangePicker } = DatePicker
const { Option } = Select;

export const StoreListPage = () => {
    useDocumentTitle('商家列表', true)
    const [param, setParam] = useStoreSearchParams()
    const { isLoading, error, totalElements, data: list } = useStoreList(useDebounce(param, 200))

    const imgUrl = process.env.REACT_APP_IMG_URL;

    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) =>
            setParam({
                ...param,
                pageNum: (current - 1),
            })
    }

    //商家性质
    const { data: storeAttributeList } = useStoreAttributeListAll();
    const storeAttributeOptions = storeAttributeList?.data?.map((item: any) =>
        <Option value={item.storeAttributeId}>{item.storeAttributeName}</Option>
    )

    //商家类型
    const { data: storeTypeList } = useStoreTypeListAll();
    const storeTypeOptions = storeTypeList?.data?.map((item: any) =>
        <Option value={item.storeTypeId}>{item.storeTypeName}</Option>
    )

    //时间
    const [storeAddTimeStart, setStoreAddTimeStart] = useState('');
    const [storeAddTimeEnd, setStoreAddTimeEnd] = useState('');
    const handleSelectTime = (value: any, dateString: any) => {
        setStoreAddTimeStart(dateString[0]);
        setStoreAddTimeEnd(dateString[1]);
    }

    //提交
    const onFinish = (values: any) => {
        setParam({
            ...param,
            storeName: values.storeName,
            storeAttributeId: values.storeAttributeId,
            storeTypeId: values.storeTypeId,
            storeAddTimeStart: storeAddTimeStart,
            storeAddTimeEnd: storeAddTimeEnd,
            pageNum: null
        })
    }

    //删除事件
    const { mutateAsync: deleteMutateAsync } = useDeleteStore();
    const confirmDeleteStore = (storeId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    deleteMutateAsync({ storeId }).then((data) => {
                        if (data.status === 0) {
                            message.info('删除成功');
                            Modal.destroyAll();
                        } else {
                            message.error(data.msg)
                        }
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };

    //重置密码
    const { mutateAsync: resetStoreAccount } = useResetStoreAccount();
    const confirmResetStoreAccount = (storeId: number) => {
        Modal.confirm({
            title: "确定操作这条数据吗?",
            content: "点击确定取消",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    resetStoreAccount({ storeId }).then((data) => {
                        if (data.status === 0) {
                            message.info("操作成功");
                            Modal.destroyAll();
                        } else {
                            message.error(data.msg);
                        }
                    });
                }).catch(() => console.log("Oops errors!"));
            },
            cancelText: "取消",
        });
    };

    //分页
    return (
        <div>
            <Card style={{ backgroundColor: "#f2f3f5", margin: '24px 0 24px 0' }}>
                <Form onFinish={onFinish}>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item name="storeAddTimeRange" label="创建时间">
                                <RangePicker onChange={handleSelectTime} showTime format="YYYY-MM-DD HH:mm:ss" style={{ width: '100%' }} allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="storeName" label="商家名称">
                                <Input placeholder='请输入商家名称' allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item name="storeAttributeId" label="商家性质">
                                <Select
                                    placeholder='请选择商家性质'
                                    showSearch
                                    allowClear
                                    optionFilterProp='children'
                                >
                                    {storeAttributeOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="storeTypeId" label="商家类型">
                                <Select
                                    placeholder='请选择商家类型'
                                    showSearch
                                    allowClear
                                    optionFilterProp='children'
                                >
                                    {storeTypeOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item style={{ marginBottom: 0 }}>
                        <Button style={{ marginLeft: 70 }} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
            <Table
                loading={isLoading}
                rowKey={columns => columns.storeId}
                pagination={paginationProps}
                columns={[
                    {
                        title: 'ID',
                        dataIndex: 'storeId',
                        key: 'storeId',
                        align: 'center',
                        width: 100,
                        fixed: 'left',
                    },
                    {
                        title: 'LOGO',
                        dataIndex: 'storeLogo',
                        key: 'storeLogo',
                        align: 'center',
                        width: 100,
                        fixed: 'left',
                        render: (value) => (
                            value ? <Image src={imgUrl + value} width={60} /> : <Image src={picNone} width={60} />
                        )
                    },
                    {
                        title: '名称',
                        dataIndex: 'storeName',
                        key: 'storeName',
                        align: 'center',
                        width: 200,
                        fixed: 'left',
                    },
                    {
                        title: '电话',
                        dataIndex: 'storeTel',
                        key: 'storeTel',
                        align: 'center',
                        width: 150,
                    },
                    {
                        title: '地址',
                        dataIndex: 'storeAddress',
                        key: 'storeAddress',
                        align: 'center',
                        width: 300,
                        render: (value, store) => (
                            store.storeProvinceName || store.storeCityName || store.storeDistrictName ? (
                                <div>
                                    <span>{store.storeProvinceName}{store.storeCityName}{store.storeDistrictName}</span><br />
                                    <span>{value}</span>
                                </div>
                            ) : ('-')
                        )
                    },
                    {
                        title: '性质',
                        dataIndex: 'storeAttributeName',
                        key: 'storeAttributeName',
                        align: 'center',
                        width: 150,
                    },
                    {
                        title: '类型',
                        dataIndex: 'storeTypeName',
                        key: 'storeTypeName',
                        align: 'center',
                        width: 150,
                    },
                    {
                        title: '数据',
                        dataIndex: 'storePageView',
                        key: 'storePageView',
                        align: 'center',
                        width: 200,
                        render: (value, store) => (
                            <div>
                                <div>浏览量：{value}</div>
                                <div>关注量：{store.storeFavoriteNum}</div>
                            </div>
                        )
                    },
                    {
                        title: '创建时间',
                        dataIndex: 'storeAddTime',
                        key: 'storeAddTime',
                        align: 'center',
                        width: 200,
                        render: (value) => (
                            value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : '-'
                        )
                    },
                    {
                        title: '排序',
                        dataIndex: 'storeSort',
                        key: 'storeSort',
                        align: 'center',
                        width: 100,
                    },
                    {
                        title: '状态',
                        dataIndex: 'storeShow',
                        key: 'storeShow',
                        align: 'center',
                        width: 100,
                        render: (value) => (
                            value ? '显示' : '不显示'
                        ),
                    },
                    {
                        title: '操作',
                        key: 'action',
                        align: 'center',
                        render: (value, store) => (
                            <Space size="middle">
                                <Link to={"/shop/store-manage/store/update/" + store.storeId}>
                                    编辑
                                </Link>
                                <a onClick={() => confirmDeleteStore(store.storeId)}>
                                    删除
                                </a>
                                <a onClick={() => confirmResetStoreAccount(store.storeId)}>
                                    重置密码
                                </a>
                            </Space>
                        ),
                        width: 200,
                        fixed: 'right',
                    }
                ]}
                dataSource={list || []}
                scroll={{ x: 1250 }}
            />
            <ErrorBox error={error} />
        </div>
    )
}