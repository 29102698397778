import { useUrlQueryParam } from "../../utils/url";
import { useMemo } from "react";

export const useGrouponSearchParams = () => {
    const [param, setParam] = useUrlQueryParam(["grouponId", "grouponTitle", "grouponState", "pageNum"]);
    return [
        useMemo(
          () => ({ ...param, grouponId: Number(param.grouponId) || undefined, pageNum: Number(param.pageNum) || undefined }),
          [param]
        ),
        setParam,
    ] as const;
};
