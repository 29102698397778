import { useMutation, useQuery, useQueryClient } from "react-query";
import { Goods } from "../interfaces/goods";
import { MallShowcaseGoods } from "../interfaces/mall-showcase-goods";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useShowcaseGoodsList = (param?: Partial<Goods>) => {
    const client = useHttp();
    const result = useQuery<any>(['showcaseGoodsList', param], () => client('mallShowcaseGoods/listShowcaseGoods', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useShowcaseGoodsListNo = (param?: Partial<any>) => {
    const client = useHttp();
    const result = useQuery<any>(['showcaseGoodsListNo', param], () => client('mallShowcaseGoods/listNoInShowcaseGoods', {data: cleanObject(param)}),{enabled:Boolean(param?.showcaseId)})
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useAddShowcaseGoods = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<MallShowcaseGoods>) => client(`mallShowcaseGoods/add`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('showcaseGoodsListNo')
        }
    )
}

export const useDeleteShowcaseGoods = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ goodsId, showcaseId }: { goodsId: number, showcaseId: number}) =>
            client(`mallShowcaseGoods/delete?goodsId=${goodsId}&showcaseId=${showcaseId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('showcaseGoodsList')
        }
    );
};