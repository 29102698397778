import { useQuery } from "react-query"
import { useHttp } from "../utils/http"

export const useCompanyDetail = (companyId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['companyDetail', { companyId }],
        () => client(`companyDetail/${companyId}`),
        {
            enabled: Boolean(companyId)
        }
    )
}