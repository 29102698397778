import { useMutation, useQuery, useQueryClient } from "react-query";
import { Store } from "../interfaces/store";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useStoreList = (param?: Partial<Store>) => {
    const client = useHttp();
    const result = useQuery<any>(['storeList', param], () => client('store', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditStore = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Store>) => client(`store`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('storeList')
        }
    )
}

export const useAddStore = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Store>) => client(`store`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('storeList')
        }
    )
}

export const useDeleteStore = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ storeId }: { storeId: number}) =>
            client(`store/${storeId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('storeList')
        }
    );
};

export const useStore = (storeId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['store', {storeId}],
        () => client(`store/${storeId}`),
        {
            enabled: Boolean(storeId)
        }
    )
}

export const useStoreListAll = () => {
    const client = useHttp();
    return useQuery<any>(['storeListAll', {}], () => client('store/listAll'))
}

//商圈里的门店
export const useBusinessCircleStoreList = (param?: Partial<Store>) => {
    const client = useHttp();
    const result = useQuery<any>(['businessCircleStoreList', param], () => client('businessCircle/inBusinessCircle', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

//没在商圈里的门店
export const useBusinessCircleStoreListNoIn = (param?: Partial<Store>) => {
    console.log(param?.businessCircleId);
    const client = useHttp();
    return useQuery<any>(['noInBusinessCircleStoreListAll', param], () => client('businessCircle/noInBusinessCircle', {data: cleanObject(param)}))
}

//商圈新增门店
export const useEditBusinessCircleStore = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Store>) => client(`businessCircle/store`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('businessCircleStoreList')
        }
    )
}

//商圈删除门店
export const useDeleteBusinessCircleStore = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ storeId }: { storeId: number}) =>
            client(`businessCircle/store/${storeId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('businessCircleStoreList')
        }
    );
};



