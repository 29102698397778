import { Button, Col, message, Modal, Row, Space, Table, Tag, Menu, Card, Form, DatePicker, Select, Input } from "antd"
import { useForm } from "antd/lib/form/Form"
import MenuItem from "antd/lib/menu/MenuItem"
import dayjs from "dayjs"
import { useState } from "react"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useWithdrawList, useRejectWithdraw, useAgreeWithdraw, useWithdraw } from "../../services/withdraw-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useWithdrawSearchParams } from "./util"
const { RangePicker } = DatePicker;
const { Option } = Select;

export const WithdrawListPage = () => {
    useDocumentTitle('提现列表', true)
    const apiUrl = process.env.REACT_APP_API_URL;
    const [param, setParam] = useWithdrawSearchParams()
    const { isLoading, error, totalElements, data: list } = useWithdrawList(useDebounce(param, 200))

    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) =>
            setParam({
                ...param,
                pageNum: (current - 1),
            })
    }

    //时间
    const [withdrawAddTimeStart, setWithdrawAddTimeStart] = useState('');
    const [withdrawAddTimeEnd, setWithdrawAddTimeEnd] = useState('');
    const handleSelectTime = (value: any, dateString: any) => {
        setWithdrawAddTimeStart(dateString[0]);
        setWithdrawAddTimeEnd(dateString[1]);
    }

    //通过
    const { mutateAsync: agreeMutateAsync } = useAgreeWithdraw();
    const confirmAgreeWithdraw = (withdrawId: number) => {
        Modal.confirm({
            title: "确定通过这条数据吗?",
            content: "点击确定通过",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    agreeMutateAsync({ withdrawId }).then((data) => {
                        if (data.status === 0) {
                            message.info('通过成功');
                            Modal.destroyAll();
                        } else {
                            message.error(data.msg)
                        }
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };

    //驳回
    const [withdrawId, setWithdrawId] = useState<number>();
    const [submitRejectLoading, setSubmitRejectLoading] = useState(false);
    const [rejectModalVisible, setRejectModalVisible] = useState(false);
    const useMutateRejectWithdraw = useRejectWithdraw
    const { mutateAsync: mutateAsyncRejectWithdraw } = useMutateRejectWithdraw();
    const useMutateWithdraw = useWithdraw;
    const { mutateAsync: mutateAsyncWithdraw } = useMutateWithdraw();
    const [form] = useForm();
    const [withdrawFailReason, setWithdrawFailReason] = useState('');
    const handleFailReason = (e: any) => {
        setWithdrawFailReason(e.target.value);
    }
    const handleRejectCancel = () => {
        setRejectModalVisible(false);
    };
    const handleRejectOk = () => {
        if (!withdrawFailReason) {
            message.error('驳回原因必填');
        } else {
            setSubmitRejectLoading(true)
            mutateAsyncRejectWithdraw({ withdrawId, withdrawFailReason }).then(() => {
                setRejectModalVisible(false);
                setSubmitRejectLoading(false);
            });
        }
    }
    const showRejectModal = (withdrawId: number) => {
        setWithdrawId(withdrawId);
        mutateAsyncWithdraw({ withdrawId }).then((data) => {
            form.setFieldsValue({
                "withdrawSn": data?.data?.withdrawSn
            })
        });
        setRejectModalVisible(true);
    }

    //提交
    const [op, setOp] = useState(1)
    function changeOp(num: number) {
        setOp(num)
    }
    const onFinish = (values: any) => {
        if (op === 1) {
            setParam({
                ...param,
                withdrawAddTimeStart: withdrawAddTimeStart,
                withdrawAddTimeEnd: withdrawAddTimeEnd,
                withdrawState: values.withdrawState,
                pageNum: null
            })
        } else if (op === 2) {
            let startTime = withdrawAddTimeStart;
            let endTime = withdrawAddTimeEnd;
            let withdrawState = values.withdrawState;
            if (startTime === undefined) {
                startTime = "";
            }
            if (endTime === undefined) {
                endTime = "";
            }
            if (withdrawState === undefined) {
                withdrawState = "";
            }
            Modal.confirm({
                title: '确定导出当前列表数据吗?',
                content: '点击确定导出',
                okText: '确定',
                onOk(): any {
                    return new Promise((resolve, reject) => {
                        window.location.href = apiUrl +
                            `/withdraw/export?withdrawAddTimeStart=${startTime}&withdrawAddTimeEnd=${endTime}&withdrawState=${withdrawState}`
                        message.success('导出成功')
                        Modal.destroyAll()
                    }).catch(() => console.log('Oops errors!'))
                },
                cancelText: '取消',
            })
        }
    }

    //分页
    return (
        <div>
            <Card style={{ backgroundColor: "#f2f3f5", margin: '24px 0 24px 0' }}>
                <Form onFinish={onFinish}>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item name="withdrawAddTimeRange" label="提现时间">
                                <RangePicker onChange={handleSelectTime} showTime format="YYYY-MM-DD HH:mm:ss" style={{ width: '100%' }} allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="withdrawState" label="提现状态">
                                <Select placeholder='请选择提现状态' allowClear>
                                    <Option value={10}>提现中</Option>
                                    <Option value={20}>提现失败</Option>
                                    <Option value={30}>提现成功</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item style={{ marginBottom: 0 }}>
                        <Button onClick={() => changeOp(1)} style={{ marginLeft: 70 }} type='primary' htmlType='submit'>
                            提交
                        </Button>
                        <Button onClick={() => changeOp(2)} style={{ marginLeft: 20 }} type='primary' htmlType='submit'>
                            导出
                        </Button>
                    </Form.Item>
                </Form>
            </Card>

            <Table
                loading={isLoading}
                rowKey={columns => columns.withdrawId}
                pagination={paginationProps}
                columns={[
                    {
                        title: '编号',
                        dataIndex: 'withdrawSn',
                        key: 'withdrawSn',
                        align: 'center',
                        width: 150,
                        fixed: 'left',
                    },
                    {
                        title: '提现方式',
                        dataIndex: 'withdrawType',
                        key: 'withdrawType',
                        align: 'center',
                        width: 100,
                        render: (value, withdraw) => (
                            withdraw.withdrawType === 1 ? '银行卡' : '-'
                        ),
                    },
                    {
                        title: '提现金额',
                        dataIndex: 'withdrawAmount',
                        key: 'withdrawAmount',
                        align: 'center',
                        width: 100,
                        render: (value, withdraw) => (
                            '￥' + withdraw.withdrawAmount
                        ),
                    },
                    {
                        title: '手续费',
                        dataIndex: 'withdrawFee',
                        key: 'withdrawFee',
                        align: 'center',
                        width: 100,
                        render: (value, withdraw) => (
                            '￥' + withdraw.withdrawFee
                        ),
                    },
                    {
                        title: '到账金额',
                        dataIndex: 'withdrawArriveAmount',
                        key: 'withdrawArriveAmount',
                        align: 'center',
                        width: 100,
                        render: (value, withdraw) => (
                            '￥' + withdraw.withdrawArriveAmount
                        ),
                    },
                    {
                        title: '账户',
                        dataIndex: 'bankName',
                        key: 'bankName',
                        align: 'center',
                        width: 200,
                        render: (value, withdraw) => (
                            <div>
                                {withdraw.bankName}<br />
                                {withdraw.withdrawCardNo}<br />
                                {withdraw.withdrawTrueName}
                            </div>
                        ),
                    },
                    {
                        title: '提现人',
                        dataIndex: 'identityName',
                        key: 'identityName',
                        align: 'center',
                        width: 150,
                        render: (value, withdraw) => (
                            <div>
                                {withdraw.identityType === 1 ? <Tag color={'#1890ff'}>用户</Tag>
                                    : withdraw.identityType === 2 ? <Tag color={'#1890ff'}>学校</Tag>
                                        : withdraw.identityType === 3 ? <Tag color={'#1890ff'}>机构</Tag>
                                            : withdraw.identityType === 4 ? <Tag color={'#1890ff'}>规划师</Tag>
                                                : withdraw.identityType === 5 ? <Tag color={'#1890ff'}>企业</Tag>
                                                    : withdraw.identityType === 6 ? <Tag color={'#1890ff'}>商家</Tag>
                                                        : withdraw.identityType === 7 ? <Tag color={'#1890ff'}>代理</Tag>
                                                            : withdraw.identityType === 8 ? <Tag color={'#521890ffc41a'}>达人</Tag>
                                                                : ''}<br />
                                {value}<br />
                                {withdraw.identityTel}
                            </div>
                        ),
                    },
                    {
                        title: '状态',
                        dataIndex: 'withdrawState',
                        key: 'withdrawState',
                        align: 'center',
                        width: 100,
                        render: (value, withdraw) => (
                            withdraw.withdrawState === 10 ? '提现中' : withdraw.withdrawState === 20 ? '提现失败' : withdraw.withdrawState === 30 ? '提现成功' : '-'
                        ),
                    },
                    {
                        title: '时间',
                        dataIndex: 'withdrawAddTime',
                        key: 'withdrawAddTime',
                        align: 'center',
                        width: 200,
                        render: (value, withdraw) => (
                            withdraw.withdrawAddTime ? dayjs(withdraw.withdrawAddTime).format('YYYY-MM-DD HH:mm:ss') : '-'
                        ),
                    },
                    {
                        title: '操作',
                        key: 'action',
                        align: 'center',
                        render: (value, withdraw) => (
                            withdraw.withdrawState === 10 ? (
                                <Space size="middle">
                                    <a onClick={() => confirmAgreeWithdraw(withdraw.withdrawId)}>
                                        通过
                                    </a>
                                    <a onClick={() => showRejectModal(withdraw.withdrawId)}>
                                        驳回
                                    </a>
                                </Space>
                            ) : ('')
                        ),
                        width: 150,
                        fixed: 'right',
                    }
                ]}
                dataSource={list || []}
                scroll={{ x: 1650 }}
            />
            <ErrorBox error={error} />

            <Modal
                confirmLoading={submitRejectLoading}
                centered
                bodyStyle={{ height: '100', overflowY: 'auto' }}
                destroyOnClose={true}
                width={500}
                title="提现驳回"
                visible={rejectModalVisible}
                onOk={handleRejectOk}
                onCancel={handleRejectCancel}>
                <Col>
                    <Form form={form}>
                        <Form.Item
                            label='提现编号'
                            name='withdrawSn'
                        >
                            <Input readOnly />
                        </Form.Item>
                        <Form.Item
                            label='驳回原因'
                        >
                            <Input onChange={handleFailReason} placeholder={"输入驳回原因"} />
                        </Form.Item>
                    </Form>
                </Col>
            </Modal>
        </div>
    )
}