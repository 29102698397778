import { Button, Form, Input, message, Radio, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { useOrdersSetting, useEditOrdersSetting } from "../../services/orders-setting-service";
import { useDocumentTitle } from "../../utils"

export const OrdersSettingIndexPage = () => {
    //标题
    useDocumentTitle('订单设置', true)
    //布局
    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 10 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    //表单
    const [form] = useForm();
    //准备回显数据
    const { data: editingOrdersSetting, isLoading } = useOrdersSetting();  
    useEffect(() => {
        form.setFieldsValue(editingOrdersSetting?.data)
    }, [editingOrdersSetting, form]);
    //编辑
    const useMutateEditOrdersSetting = useEditOrdersSetting;
    const {mutateAsync, error } =  useMutateEditOrdersSetting();
    //提交表单
    const [submitLoading, setSubmitLoading] = useState(false);
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...editingOrdersSetting?.data, ...values }).then(() => {
            message.info('编辑成功');
            setSubmitLoading(false);
        });
    };
    return (
        <div>
            <Spin spinning={isLoading}>
                <Form
                    form={form}
                    {...layout}
                    onFinish={onFinish}>
                    <div style={{ padding: 10, backgroundColor: '#f8f8f8', fontSize: 14, fontWeight: 700}}>
                        库存设置
                    </div>

                    <Form.Item
                        label={"默认库存扣减方式"}
                        name={"ordersSettingReduceStorage"}
                        rules={[{ required: true, message: "默认库存扣减方式必选" }]}
                        style={{ textAlign:'left', marginTop: 24 }}
                    >
                        <Radio.Group>
                            <Radio value={1}>拍下减库存</Radio>
                            <Radio value={2}>付款减库存</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <div style={{ padding: 10, backgroundColor: '#f8f8f8', fontSize: 14, fontWeight: 700}}>
                        订单设置
                    </div>

                    <Form.Item
                        label={"待付款订单取消时间"}
                        name={"ordersSettingCancelOrdersHours"}
                        rules={[{ required: true, message: "待付款订单取消时间必填" }]}
                        style={{ marginTop: 24 }}
                    >
                        <Input addonBefore="拍下未付款订单" addonAfter="小时内未付款，自动取消订单" />
                    </Form.Item>

                    <Form.Item
                        label={"发货后自动确认收货时间"}
                        name={"ordersSettingConfirmOrdersDays"}
                        rules={[{ required: true, message: "发货后自动确认收货时间必填" }]}
                    >
                        <Input addonBefore="发货后" addonAfter="天，自动确认收货" />
                    </Form.Item>

                    <Form.Item
                        label={"确认收货后自动评价时间"}
                        name={"ordersSettingAutoEvaluateOrdersDays"}
                        rules={[{ required: true, message: "确认收货后自动评价时间必填" }]}
                    >
                        <Input addonBefore="确认收货后" addonAfter="天，自动评价" />
                    </Form.Item>

                    <Form.Item
                        label={"系统自动评价内容"}
                        name={"ordersSettingAutoEvaluateContent"}
                        rules={[{ required: true, message: "系统自动评价内容必填" }]}
                    >
                        <Input placeholder={"请输入系统自动评价内容"} />
                    </Form.Item>

                    <Form.Item
                        label={"关闭售后功能时间"}
                        name={"ordersSettingAftermarketDays"}
                        rules={[{ required: true, message: "关闭售后功能时间必填" }]}
                    >
                        <Input addonBefore="确认收货后" addonAfter="天后买家不支持申请售后" />
                    </Form.Item>

                    <div style={{ padding: 10, backgroundColor: '#f8f8f8', fontSize: 14, fontWeight: 700}}>
                        售后设置
                    </div>
                    
                    <Form.Item
                        label={"买家发起售后申请"}
                        name={"ordersSettingRefundMemberApplyDays"}
                        rules={[{ required: true, message: "买家发起售后申请,进入下一步的时间必填" }]}
                        style={{ marginTop: 24 }}
                    >
                        <Input addonAfter="天后商家未处理，系统将自动同意退款" />
                    </Form.Item>

                    <Form.Item
                        label={"商家拒绝售后"}
                        name={"ordersSettingRefundShopRefuseDays"}
                        rules={[{ required: true, message: "商家拒绝售后,进入下一步的时间必填" }]}
                    >
                        <Input addonAfter="天后买家未处理，系统将自动拒绝售后" />
                    </Form.Item>

                    <Form.Item
                        label={"商家同意退货"}
                        name={"ordersSettingRefundShopAgreeReturnDays"}
                        rules={[{ required: true, message: "商家同意退货,进入下一步的时间必填" }]}
                    >
                        <Input addonAfter="天后买家未处理，系统将自动拒绝售后" />
                    </Form.Item>

                    <Form.Item
                        label={"买家已退货"}
                        name={"ordersSettingRefundMemberSendDays"}
                        rules={[{ required: true, message: "买家已退货,进入下一步的时间必填" }]}
                    >
                        <Input addonAfter="天后商家未处理，系统将自动同意售后" />
                    </Form.Item>

                    <Form.Item
                        label={"商家拒绝收货"}
                        name={"ordersSettingRefundShopRefuseReceiptDays"}
                        rules={[{ required: true, message: "商家拒绝收货,进入下一步的时间必填" }]}
                    >
                        <Input addonAfter="天后买家未处理，系统将自动拒绝售后" />
                    </Form.Item>

                    <Form.Item
                        label={"申请售后扣除手续费"}
                        name={"ordersSettingRefundFeeRatio"}
                        rules={[{ required: true, message: "申请售后扣除手续费必填" }]}
                    >
                        <Input addonAfter="%" />
                    </Form.Item>

                    
                    <div style={{ padding: 10, backgroundColor: '#f8f8f8', fontSize: 14, fontWeight: 700}}>
                        平台设置
                    </div>
                    <Form.Item
                        label={"平台课程佣金"}
                        name={"ordersSettingCourseRatio"}
                        rules={[{ required: true, message: "平台课程佣金必填" }]}
                    >
                        <Input addonAfter="%" />
                    </Form.Item>
                    <Form.Item
                        label={"平台商品佣金"}
                        name={"ordersSettingGoodsRatio"}
                        rules={[{ required: true, message: "平台商品佣金必填" }]}
                    >
                        <Input addonAfter="%" />
                    </Form.Item>
                    
                    <Form.Item {...tailLayout} style={{ textAlign:'left' }}>
                        <Button loading={ submitLoading } type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>  
                </Form>
            </Spin>
        </div>
    )
}

