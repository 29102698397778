import { Form, Input, Select } from 'antd';
import { useEffect, useState } from 'react';
import { usePageUrl, usePageUrlList } from '../services/page-url-service';
const { Option } = Select;

const PageUrlSelect = (props: any) => {

    let { pageUrlId, pageUrlTabbar} = props;

    //获取所有链接
    const { data: pageUrlList } = usePageUrlList(pageUrlTabbar?{pageUrlTabbar}:{});

    //获取链接列表
    const options = pageUrlList?.map((item: any) =>
        <Option value={item.pageUrlId} key={item.pageUrlId}>
            {item.pageUrlName}
        </Option>
    );

    //查询选中链接
    const [showParam, setShowParam] = useState(false);
    const [paramName, setParamName] = useState("");
    const { mutateAsync: mutateAsyncPageUrl } = usePageUrl();
    const optionChange = (pageUrlId: number) => {
        mutateAsyncPageUrl({ pageUrlId }).then((data) => {
            const flag = data.data.pageUrlFlag
            const paramName = data.data.pageUrlParamName
            setShowParam(flag);
            setParamName(paramName);
        });
    }

    useEffect(() => {
        if (pageUrlId) {
            mutateAsyncPageUrl({ pageUrlId }).then((data) => {
                if (data.status === 0) {
                    const flag = data.data.pageUrlFlag
                    const paramName = data.data.pageUrlParamName
                    setShowParam(flag);
                    setParamName(paramName);
                } else {
                    setShowParam(false);
                    setParamName("");
                }
            });
        }
    }, [pageUrlId])

    return (
        <div>
            <Form.Item
                label={"链接"}
                name={"pageUrlId"}
                rules={[{ required: true, message: "链接必选" }]}
            >
                <Select
                    showSearch
                    allowClear
                    placeholder="请选择链接"
                    optionFilterProp="children"
                    style={{ textAlign: 'left', width: 380 }}
                    onChange={optionChange}
                >
                    {options}
                </Select>
            </Form.Item>

            {showParam ? (
                <Form.Item
                    label={paramName}
                    name={"pageUrlParamValue"}
                    rules={[{ required: true, message: paramName + "必传" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入" + paramName} />
                </Form.Item>
            ) : ('')}
        </div>
    )
}

export default PageUrlSelect;