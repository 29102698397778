import { Button, Col, message, Modal, Row, Space, Table } from "antd"
import Search from "antd/lib/input/Search"
import { useEffect } from "react"
import { Link, useLocation } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useGoodsSpecValueList, useDeleteGoodsSpecValue } from "../../services/goods-spec-value-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useGoodsSpecValueSearchParams } from "./util"

export const GoodsSpecValueListPage = () => {
    useDocumentTitle('商品规格值列表', true)
    const { pathname } = useLocation();
    const goodsSpecId = pathname.match(/shop\/goods-manage\/goods-spec\/value\/list\/(\d+)/)?.[1]
    const [param, setParam] = useGoodsSpecValueSearchParams()
    useEffect(() => {
        setParam({
            goodsSpecId: goodsSpecId,
        })
    }, [goodsSpecId]);
    const {isLoading, error, totalElements, data: list} = useGoodsSpecValueList(useDebounce(param, 200))
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) => 
            setParam({
            ...param,
            pageNum: (current -1),
        })
    }
    //删除事件
    const useMutateDeleteGoodsSpecValue = useDeleteGoodsSpecValue;
    const { mutateAsync } = useMutateDeleteGoodsSpecValue();
    const confirmDeleteGoodsSpecValue = (goodsSpecValueId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ goodsSpecValueId }).then(() => {
                        message.info('删除成功');
                        Modal.destroyAll();
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    return (
        <div>
            <Row style={{marginBottom: 24}}>
                <Col span={12}>
                    <Link to={"/shop/goods-manage/goods-spec/value/add/" + goodsSpecId}>
                        <Button type="primary">新建规格值</Button>
                    </Link>
                </Col>
                <Col span={12} style={{textAlign:'right'}}>
                    <Search 
                        placeholder="可搜索规格值名称" 
                        style={{ width: 200 }}
                        value={param.goodsSpecValueName}
                        onChange={(evt) =>
                            setParam({
                            ...param,
                            goodsSpecValueName: evt.target.value,
                            pageNum: null
                        })} 
                        onSearch={(value) =>
                            setParam({
                            ...param,
                            goodsSpecValueName: value,
                            pageNum: null
                        })} 
                    />
                </Col>
            </Row>
            <Table 
                loading = { isLoading }
                rowKey={ columns => columns.goodsSpecValueId } 
                pagination = { paginationProps } 
                columns = {[
                {
                    title: '规格值ID',
                    dataIndex: 'goodsSpecValueId',
                    key: 'goodsSpecValueId',
                    width: 100,
                },
                {
                    title: '规格值名称标题',
                    dataIndex: 'goodsSpecValueName',
                    key: 'goodsSpecValueName',
                },
                {
                    title: '操作',
                    key: 'action',
                    render: (value, goodsSpecValue) => (
                        <Space size="middle">
                            <Link to={"/shop/goods-manage/goods-spec/value/update/" + goodsSpecValue.goodsSpecValueId}>
                                编辑
                            </Link>
                            <a onClick={() => confirmDeleteGoodsSpecValue(goodsSpecValue.goodsSpecValueId)}>
                                删除
                            </a>
                        </Space>
                    ),
                    width: 150,
                },
            ]}
            dataSource={ list || [] }  />
            <ErrorBox error={ error } />
        </div>
    )
}