import { useMutation, useQuery, useQueryClient } from "react-query";
import { BombWindow } from "../interfaces/bomb-window";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useBombWindowList = (param?: Partial<BombWindow>) => {
    const client = useHttp();
    const result = useQuery<any>(['bombWindowPage', param], () => client('bombWindow', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditBombWindow = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<BombWindow>) => client(`bombWindow`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('bombWindowPage')
        }
    )
}

export const useAddBombWindow = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<BombWindow>) => client(`bombWindow`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('bombWindowPage')
        }
    )
}

export const useDeleteBombWindow = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ bombWindowId }: { bombWindowId: number}) =>
            client(`bombWindow/${bombWindowId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('bombWindowPage')
        }
    );
};

export const useBombWindow = (bombWindowId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['bombWindow', {bombWindowId}],
        () => client(`bombWindow/${bombWindowId}`),
        {
            enabled: Boolean(bombWindowId)
        }
    )
}