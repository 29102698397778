import { useMemo } from "react";
import { useUrlQueryParam } from "../../utils/url";

export const useSchoolArticleSearchParams = () => {
  const [param, setParam] = useUrlQueryParam([
    "schoolArticleTitle",
    "schoolArticleType",
    "schoolArticleId",
    "schoolId",
    "trendsTagId",
    "schoolArticleAddTimeStart",
    "schoolArticleAddTimeEnd",
    "pageNum",
  ]);
  return [
    useMemo(
      () => ({
        ...param,
        schoolArticleType: Number(param.schoolArticleType) || undefined,
        schoolArticleId: Number(param.schoolArticleId) || undefined,
        schoolId: Number(param.schoolId) || undefined,
        trendsTagId: Number(param.trendsTagId) || undefined,
        pageNum: Number(param.pageNum) || undefined,
      }),
      [param]
    ),
    setParam,
  ] as const;
};
