import {
    Button,
    Col,
    Divider,
    Form,
    Input,
    message,
    Radio,
    Row,
    Select,
    Cascader,
    Tooltip,
    Typography,
    Upload
  } from "antd";
  import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
  import { useForm } from "antd/lib/form/Form";
  import { useState } from "react";
  import { Link } from "react-router-dom";
  import { useAddBusinessCircle } from "../../services/business-circle-service";
  import { useAreaListAll } from "../../services/area-service";
  import { useDocumentTitle } from "../../utils";
  import { getToken } from "../../utils/auth-provider";
  const { Option } = Select;
  
  export const BusinessCircleAddPage = () => {
    //标题
    useDocumentTitle("新建商圈", true);
    //API地址
    const apiUrl = process.env.REACT_APP_API_URL;
    const token = getToken();
    //布局
    const layout = {
      labelCol: { span: 3 },
      wrapperCol: { span: 7 },
    };
    const tailLayout = {
      wrapperCol: { offset: 3 },
    };
    //所属区域 - 级联 
    const { data: areaListAll } = useAreaListAll(3);
    //表单
    const [form] = useForm();
    //图片上传
    const [uploadLoadings, setUploadLoadings] = useState(false);
    const [imageUrls, setImageUrls] = useState("");
    const uploadButtons = (
      <div>
        {uploadLoadings ? <LoadingOutlined /> : <PlusOutlined />}
        <div style={{ marginTop: 8 }}>上传图片</div>
      </div>
    );
    // logo
    const beforeUploads = (file: any) => {
      setImageUrls("");
      setUploadLoadings(true);
      const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
      if (!isJpgOrPng) {
        setUploadLoadings(false);
        message.error("You can only upload JPG/PNG file!");
      }
      const isLt2M = file.size / 1024 / 1024 < 2;
      if (!isLt2M) {
        setUploadLoadings(false);
        message.error("Image must smaller than 2MB!");
      }
      return isJpgOrPng && isLt2M;
    };
    const handleChanges = (info: any) => {
      if (info.file.status === "uploading") {
        return;
      }
      if (info.file.status === "done") {
        setUploadLoadings(false);
        setImageUrls(info?.file?.response?.data?.realPath);
        const relativePath = info?.file?.response?.data?.relativePath;
        form.setFieldsValue({
          businessCircleLogo: relativePath,
        });
      }
    };
    const normFiles = (e: any) => {
      if (Array.isArray(e)) {
        return e;
      }
      return e && e.fileList;
    };
    //新建
    const useMutateAddBusinessCircle = useAddBusinessCircle;
    const { mutateAsync, error } = useMutateAddBusinessCircle();
    //提交表单
    const [submitLoading, setSubmitLoading] = useState(false);
    const onFinish = (values: any) => {
      setSubmitLoading(true);
      mutateAsync({
        ...values,
        businessCircleProvinceId: values?.businessCirclePosition[0],
        businessCircleCityId: values?.businessCirclePosition[1],
        businessCircleDistrictId: values?.businessCirclePosition[2]
      }).then(() => {
        message.info("新建成功");
        window.location.href = "/#/shop/store-manage/businessCircle/list";
      });
    };
    return (
      <div>
        <Row>
          <Col span={12}>
            <Link to={"/shop/store-manage/businessCircle/list"}>
              <Button type="primary">商圈列表</Button>
            </Link>
          </Col>
        </Row>
        <Divider style={{ marginBottom: 24 }} />
        <Form
          form={form}
          {...layout}
          onFinish={onFinish}
          initialValues={{
            businessCircleSort: 0,
            businessCircleShow: true,
            businessCircleBusiness: true,
          }}
        >
          <Form.Item
            label={"商圈名称"}
            name={"businessCircleName"}
            rules={[{ required: true, message: "商圈名称必填" }]}
          >
            <Input placeholder={"请输入商圈名称"} />
          </Form.Item>
  
          <Form.Item
            label={"商圈电话"}
            name={"businessCircleTel"}
            rules={[{ required: true, message: "商圈电话必填" }]}
          >
            <Input placeholder={"请输入商圈电话"} />
          </Form.Item>
  
          <Form.Item
            name={"uploadPic"}
            label={"商圈Logo"}
            valuePropName="fileList"
            getValueFromEvent={normFiles}
            rules={[{ required: true, message: "商圈Logo必传" }]}
            style={{ textAlign: "left" }}
          >
            <Upload
              name="imageFile"
              listType="picture-card"
              className="avatar-uploader"
              showUploadList={false}
              action={apiUrl + "/upload/img"}
              beforeUpload={beforeUploads}
              onChange={handleChanges}
              headers={{ Authorization: "Bearer " + token }}
            >
              {imageUrls ? <img src={imageUrls} alt="avatar" style={{ width: "100%" }} /> : uploadButtons }
            </Upload>
          </Form.Item>
  
          <Form.Item
            hidden
            label={"商圈Logo"}
            name={"businessCircleLogo"}
            rules={[{ required: true, message: "商圈Logo必传" }]}
          >
            <Input />
          </Form.Item>

          <Form.Item
            label={"所在地区"}
            name={"businessCirclePosition"}
            rules={[{ required: true, message: "所在地区必选" }]}
          >
            <Cascader options={areaListAll?.data} placeholder="请选择所在地区" />
          </Form.Item>

          <Form.Item
            label={"详细地址"}
            name={"businessCircleAddress"}
            rules={[{ required: true, message: "详细地址必填" }]}
          >
            <Input placeholder={"请输入详细地址"} />
          </Form.Item>
  
          <Form.Item
            label={"经纬度"}
            name={"businessCircleLongitude"}
            style={{ marginBottom: 0 }}
            rules={[{ required: true, message: "经纬度必填" }]}
          >
            <Input.Group compact>
              <Form.Item
                style={{ marginRight: 5, width: 123 }}
                name={"businessCircleLongitude"}
                rules={[{ required: true, message: "经度必填" }]}
              >
                <Input placeholder={"请输入经度"} />
              </Form.Item>
              <Form.Item
                style={{ marginRight: 10, width: 123 }}
                name={"businessCircleLatitude"}
                rules={[{ required: true, message: "纬度必填" }]}
              >
                <Input placeholder={"请输入纬度"} />
              </Form.Item>
              <Tooltip title="腾讯地图坐标拾起器">
                <Typography.Link
                  target="_blank"
                  href="https://lbs.qq.com/getPoint/"
                >
                  坐标拾起器
                </Typography.Link>
              </Tooltip>
            </Input.Group>
          </Form.Item>
  
          <Form.Item
            label={"是否营业"}
            name={"businessCircleBusiness"}
            rules={[{ required: true, message: "是否营业必选" }]}
            style={{ textAlign: "left" }}
          >
            <Radio.Group>
              <Radio defaultChecked value={true}>
                营业
              </Radio>
              <Radio value={false}>不营业</Radio>
            </Radio.Group>
          </Form.Item>
  
          <Form.Item
            label={"营业开始时间"}
            name={"businessCircleBusinessStartHour"}
            style={{ marginBottom: 0 }}
            rules={[{ required: true, message: "营业开始时间必填" }]}
          >
            <Input.Group compact>
              <Form.Item
                style={{ marginRight: 5, width: 123 }}
                name={"businessCircleBusinessStartHour"}
                rules={[{ required: true, message: "时必填" }]}
              >
                <Input placeholder={"请输入时"} />
              </Form.Item>
              <Form.Item
                style={{ marginRight: 10, width: 123 }}
                name={"businessCircleBusinessStartMinute"}
                rules={[{ required: true, message: "分必填" }]}
              >
                <Input placeholder={"请输入分"} />
              </Form.Item>
            </Input.Group>
          </Form.Item>
  
          <Form.Item
            label={"营业结束时间"}
            name={"businessCircleBusinessEndHour"}
            style={{ marginBottom: 0 }}
            rules={[{ required: true, message: "营业结束时间必填" }]}
          >
            <Input.Group compact>
              <Form.Item
                style={{ marginRight: 5, width: 123 }}
                name={"businessCircleBusinessEndHour"}
                rules={[{ required: true, message: "时必填" }]}
              >
                <Input placeholder={"请输入时"} />
              </Form.Item>
              <Form.Item
                style={{ marginRight: 10, width: 123 }}
                name={"businessCircleBusinessEndMinute"}
                rules={[{ required: true, message: "分必填" }]}
              >
                <Input placeholder={"请输入分"} />
              </Form.Item>
            </Input.Group>
          </Form.Item>
  
          <Form.Item
            label={"商圈排序"}
            name={"businessCircleSort"}
            rules={[{ required: true, message: "商圈排序必填" }]}
          >
            <Input placeholder={"请输入商圈排序"} />
          </Form.Item>
  
          <Form.Item
            label={"是否显示"}
            name={"businessCircleShow"}
            rules={[{ required: true, message: "商圈显示必选" }]}
            style={{ textAlign: "left" }}
          >
            <Radio.Group>
              <Radio defaultChecked value={true}>
                显示
              </Radio>
              <Radio value={false}>不显示</Radio>
            </Radio.Group>
          </Form.Item>
  
          <Form.Item {...tailLayout} style={{ textAlign: "left" }}>
            <Button loading={submitLoading} type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </div>
    );
  };
  