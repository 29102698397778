import { Button, Col, Row, Table, Card, Form, Input, Divider, Select, DatePicker, Tag } from "antd"
import { Link } from "react-router-dom"
import dayjs from "dayjs"
import { ErrorBox } from "../../components/lib"
import { useAgentWithdrawCashList } from "../../services/agent-withdraw-cash-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useAgentWithdrawCashSearchParams } from "./util"
import { useSelfAgent } from "../../services/agent-service"
import { useState } from "react"

export const AgentWithdrawCashListPage = () => {
    useDocumentTitle('代理商提现列表', true)
    const [param, setParam] = useAgentWithdrawCashSearchParams()
    const { isLoading, error, totalElements, data: list } = useAgentWithdrawCashList(useDebounce(param, 200))

    //分页
    const paginationProps = {
        showSizeChanger: true,
        onShowSizeChange: (current: number, pageSize: number) => {
            setParam({
                ...param,
                pageNum: current - 1,
                pageSize,
            });
        },
        defaultCurrent: 1,
        pageSize: param.pageSize,
        total: totalElements,
        onChange: (current: number, pageSize: number) =>
            setParam({
                ...param,
                pageNum: current - 1,
                pageSize,
            }),
    };

    // 自己的代理商信息
    const { data: agent } = useSelfAgent();
    //创建时间选择
    const [withdrawAddTimeStart, setWithdrawAddTimeStart] = useState("");
    const [withdrawAddTimeEnd, setWithdrawAddTimeEnd] = useState("");
    const handleSelectTime = (value: any, dateString: any) => {
        setWithdrawAddTimeStart(dateString[0]);
        setWithdrawAddTimeEnd(dateString[1]);
    };
    //提交
    const onFinish = (values: any) => {
        // if (op === 1) {
        setParam({
            ...param, ...values,
            withdrawAddTimeStart,
            withdrawAddTimeEnd,
            pageNum: null,
        });
        // }
    };
    //分页
    return (
        <div>
            {agent ? <>

                <Row style={{ marginBottom: 24 }}>
                    <Col span={12}>
                        <Link to={"/wallet/wallet-manage/agent-withdraw-cash/add"}>
                            <Button type="primary">新建</Button>
                        </Link>
                    </Col>
                </Row>
                <Divider style={{ marginBottom: 24 }} />
                <Card title="代理商信息">
                    <Card.Grid style={{
                        width: '33%',
                        textAlign: 'center',
                    }}>可提现金额：{agent.agentMoney}</Card.Grid>
                    <Card.Grid style={{
                        width: '33%',
                        textAlign: 'center',
                    }}>总收入：{agent.agentMoneyTotal}</Card.Grid>
                    <Card.Grid style={{
                        width: '33%',
                        textAlign: 'center',
                    }}>已提现金额：{agent.agentMoneyUse}</Card.Grid>
                </Card>
            </> : ""}

            <Card style={{ marginBottom: 24, backgroundColor: "#f2f3f5" }}>
                <Form onFinish={onFinish}>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item
                                name="withdrawAddTime"
                                label="提现时间"
                                rules={[{ type: "array" as const, message: "请选择时间!" }]}
                            >
                                <DatePicker.RangePicker
                                    onChange={handleSelectTime}
                                    showTime
                                    format="YYYY-MM-DD HH:mm:ss"
                                    style={{ width: "100%" }}
                                />
                            </Form.Item>
                        </Col>


                        <Col span={8}>
                            <Form.Item name="withdrawSn" label="提现编号">
                                <Input placeholder="请输入提现编号" allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="withdrawState" label="提现状态">
                                <Select
                                    allowClear
                                    placeholder="请选择状态"
                                    optionFilterProp="children"
                                >
                                    <Select.Option key={10} value={10}>提现中</Select.Option>
                                    <Select.Option key={20} value={20}>提现失败</Select.Option>
                                    <Select.Option key={30} value={30}>提现成功</Select.Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Button
                            // onClick={changeOpSubmit}
                            style={{ marginLeft: 70 }}
                            type="primary"
                            htmlType="submit"
                        >
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
            <Table
                loading={isLoading}
                rowKey={columns => columns.withdrawId}
                pagination={paginationProps}
                scroll={{ x: 1500 }}
                dataSource={list || []}
                columns={[
                    {
                        title: '编号',
                        dataIndex: 'withdrawSn',
                        key: 'withdrawSn',
                        align: 'center',
                        width: 150,
                        fixed: 'left',
                    },
                    {
                        title: '提现方式',
                        dataIndex: 'withdrawType',
                        key: 'withdrawType',
                        align: 'center',
                        width: 100,
                        render: (value, withdraw) => (
                            withdraw.withdrawType === 1 ? '银行卡' : '-'
                        ),
                    },
                    {
                        title: '提现金额',
                        dataIndex: 'withdrawAmount',
                        key: 'withdrawAmount',
                        align: 'center',
                        width: 100,
                        render: (value, withdraw) => (
                            '￥' + withdraw.withdrawAmount
                        ),
                    },
                    {
                        title: '手续费',
                        dataIndex: 'withdrawFee',
                        key: 'withdrawFee',
                        align: 'center',
                        width: 100,
                        render: (value, withdraw) => (
                            '￥' + withdraw.withdrawFee
                        ),
                    },
                    {
                        title: '到账金额',
                        dataIndex: 'withdrawArriveAmount',
                        key: 'withdrawArriveAmount',
                        align: 'center',
                        width: 100,
                        render: (value, withdraw) => (
                            '￥' + withdraw.withdrawArriveAmount
                        ),
                    },
                    {
                        title: '账户',
                        dataIndex: 'bankName',
                        key: 'bankName',
                        align: 'center',
                        width: 200,
                        render: (value, withdraw) => (
                            <div>
                                {withdraw.bankName}<br />
                                {withdraw.withdrawCardNo}<br />
                                {withdraw.withdrawTrueName}
                            </div>
                        ),
                    },
                    {
                        title: '提现人',
                        dataIndex: 'identityName',
                        key: 'identityName',
                        align: 'center',
                        width: 150,
                        render: (value, withdraw) => (
                            <div>
                                {withdraw.identityType === 1 ? <Tag color={'#1890ff'}>用户</Tag>
                                    : withdraw.identityType === 2 ? <Tag color={'#1890ff'}>学校</Tag>
                                        : withdraw.identityType === 3 ? <Tag color={'#1890ff'}>机构</Tag>
                                            : withdraw.identityType === 4 ? <Tag color={'#1890ff'}>规划师</Tag>
                                                : withdraw.identityType === 5 ? <Tag color={'#1890ff'}>企业</Tag>
                                                    : withdraw.identityType === 6 ? <Tag color={'#1890ff'}>商家</Tag>
                                                        : withdraw.identityType === 7 ? <Tag color={'#1890ff'}>代理</Tag>
                                                            : withdraw.identityType === 8 ? <Tag color={'#521890ffc41a'}>达人</Tag>
                                                                : ''}<br />
                                {value}<br />
                                {withdraw.identityTel}
                            </div>
                        ),
                    },
                    {
                        title: '状态',
                        dataIndex: 'withdrawState',
                        key: 'withdrawState',
                        align: 'center',
                        width: 100,
                        render: (value, withdraw) => (
                            withdraw.withdrawState === 10 ? '提现中' : withdraw.withdrawState === 20 ? '提现失败' : withdraw.withdrawState === 30 ? '提现成功' : '-'
                        ),
                    },
                    {
                        title: '时间',
                        dataIndex: 'withdrawAddTime',
                        key: 'withdrawAddTime',
                        align: 'center',
                        width: 200,
                        render: (value, withdraw) => (
                            withdraw.withdrawAddTime ? dayjs(withdraw.withdrawAddTime).format('YYYY-MM-DD HH:mm:ss') : '-'
                        ),
                    },
                ]} />
            <ErrorBox error={error} />
        </div>
    )
}