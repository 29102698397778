import {
  Table,
  Space,
  Row,
  Col,
  Button,
  Input,
  Modal,
  message,
  Image,
  Card,
  Form,
  Select,
  Menu,
} from "antd";
import {
  useGrouponList,
  useDeleteGroupon,
} from "../../services/groupon-service";
import { useDebounce, useDocumentTitle } from "../../utils";
import { useGrouponSearchParams } from "./util";
import { ErrorBox } from "../../components/lib";
import { Link } from "react-router-dom";
import dayjs from "dayjs";
const { Option } = Select;

export const GrouponListPage = () => {
  useDocumentTitle("拼团列表", true);
  const imgUrl = process.env.REACT_APP_IMG_URL;
  //参数
  const [param, setParam] = useGrouponSearchParams();
  const {
    isLoading,
    error,
    totalElements,
    data: list,
  } = useGrouponList(useDebounce(param, 200));
  //分页
  const paginationProps = {
    defaultCurrent: 1,
    pageSize: 10,
    total: totalElements,
    onChange: (current: number) =>
      setParam({
        ...param,
        pageNum: current - 1,
      }),
  };
  //删除事件
  const useMutateDeleteGroupon = useDeleteGroupon;
  const { mutateAsync } = useMutateDeleteGroupon();
  const confirmDeleteGroupon = (grouponId: number) => {
    Modal.confirm({
      title: "确定失效这条数据吗?",
      content: "点击确定失效",
      okText: "确定",
      onOk(): any {
        return new Promise((resolve, reject) => {
          mutateAsync({ grouponId }).then(() => {
            message.info("操作成功");
            Modal.destroyAll();
          });
        }).catch(() => console.log("Oops errors!"));
      },
      cancelText: "取消",
    });
  };
  //完成
  const onFinish = (values: any) => {
    setParam({
      ...param,
      grouponTitle: values.grouponTitle,
      grouponState: values.grouponState,
      pageNum: null,
    });
  };
  return (
    <div>
      <Menu
        style={{ marginBottom: 24 }}
        mode="horizontal"
        selectedKeys={["groupon"]}
      >
        <Menu.Item key="groupon">
          <Link to={"/shop/market-manage/groupon/list"}>拼团管理</Link>
        </Menu.Item>
        <Menu.Item key="grouponAdv">
          <Link to={"/shop/market-manage/groupon/adv/list"}>拼团广告</Link>
        </Menu.Item>
      </Menu>
      {/* <Row style={{ marginBottom: 24 }}>
        <Col span={12}>
          <Link to={"/shop/market-manage/groupon/add"}>
            <Button type="primary">新建拼团</Button>
          </Link>
        </Col>
      </Row> */}
      <Card style={{ backgroundColor: "#f2f3f5", marginBottom: 24 }}>
        <Form onFinish={onFinish}>
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item name="grouponTitle" label="活动名称">
                <Input placeholder="请输入活动名称" />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="grouponState" label="活动状态">
                <Select
                  showSearch
                  placeholder="请选择活动状态"
                  optionFilterProp="children"
                >
                  <Option value={1}>未开始</Option>
                  <Option value={2}>进行中</Option>
                  <Option value={3}>已结束</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item>
            <Button style={{ marginLeft: 70 }} type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <div>
        <Table
          loading={isLoading}
          pagination={paginationProps}
          rowKey={(columns) => columns.grouponId}
          columns={[
            {
              title: "活动商品",
              width: 300,
              fixed: "left",
              dataIndex: "goodsName",
              key: "goodsName",
              render: (value, groupon) => (
                <Space size="middle">
                  <Row>
                    <Col span={6}>
                      <Image
                        width="100%"
                        src={imgUrl  + groupon.goodsPic}
                      />
                    </Col>
                    <Col span={18} style={{ paddingLeft: 10 }}>
                      {groupon.goodsName}
                    </Col>
                  </Row>
                </Space>
              ),
            },
            {
              title: "活动名称",
              dataIndex: "grouponTitle",
              key: "grouponTitle",
            },
            {
              title: "活动时间",
              dataIndex: "grouponStartTime",
              key: "grouponStartTime",
              width: 200,
              render(value, groupon) {
                return (
                  <span>
                    {groupon.grouponStartTime
                      ? dayjs(groupon.grouponStartTime).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )
                      : "无"}
                    <br />
                    ～<br />
                    {groupon.grouponEndTime
                      ? dayjs(groupon.grouponEndTime).format(
                          "YYYY-MM-DD HH:mm:ss"
                        )
                      : "无"}
                  </span>
                );
              },
            },
            {
              title: "订单实付金额(元)",
              dataIndex: "grouponOrdersAmount",
              key: "grouponOrdersAmount",
            },
            {
              title: "付款订单数",
              dataIndex: "grouponOrdersNum",
              key: "grouponOrdersNum",
            },
            {
              title: "排序",
              dataIndex: "grouponSort",
              key: "grouponSort",
            },
            {
              title: "门店",
              dataIndex: "storeName",
              key: "storeName",
              width: 250,
            },
            {
                title: "显示区域",
                dataIndex: "areaName",
                key: "areaName"
            },
            {
              title: "操作",
              key: "operation",
              fixed: "right",
              width: 150,
              render: (value, groupon) => (
                <Space size="middle">
                  {groupon.grouponStatus === true ? (
                    <a onClick={() => confirmDeleteGroupon(groupon.grouponId)}>
                      失效
                    </a>
                  ) : (
                    ""
                  )}
                  <br />
                  <Link to={"/shop/market-manage/groupon/update/" + groupon.grouponId}>
                    编辑
                  </Link>
                </Space>
              ),
            },
          ]}
          dataSource={list || []}
          scroll={{ x: 1800 }}
        />
      </div>
      <ErrorBox error={error} />
    </div>
  );
};
