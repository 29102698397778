import { useMutation, useQuery, useQueryClient } from "react-query";
import { StoreDetail } from "../interfaces/store-detail";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useStoreDetail = (storeId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['storeDetail', {storeId}],
        () => client(`storeDetail/${storeId}`),
        {
            enabled: Boolean(storeId)
        }
    )
}