import { useQuery } from "react-query";
import { RecruitResume } from "../interfaces/recruit-resume";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useRecruitResumeList = (param?: Partial<RecruitResume>) => {
    const client = useHttp();
    const result = useQuery<any>(['recruitResumePage', param], () => client('recruitResume', { data: cleanObject(param) }))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return { ...result, totalElements, data };
}

export const useRecruitResume = (recruitResumeId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['recruitResume', {recruitResumeId}],
        () => client(`recruitResume/${recruitResumeId}`),
        {
            enabled: Boolean(recruitResumeId)
        }
    )
}