import {
  Button,
  Card,
  Col,
  Form,
  message,
  Modal,
  Row,
  Space,
  Table,
  DatePicker,
  Select,
  Input,
  Image,
  Tag,
} from "antd";
import dayjs from "dayjs";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ErrorBox } from "../../components/lib";
import { useTrendsTagListAll } from "../../services/trends-tag-service";
import { useSchoolLevelListAll } from "../../services/school-level-service";
import { useSchoolList, useDeleteSchool } from "../../services/school-service";
import { useSchoolTypeListAll } from "../../services/school-type-service";
import { useDebounce, useDocumentTitle } from "../../utils";
import { useSchoolSearchParams } from "./util";
import picNone from "../../assets/pic-none.png";
import { useAuth } from "../../context/auth-context";
import { useResetIdentityAccount } from "../../services/identity-account-service";
const { RangePicker } = DatePicker;
const { Option } = Select;

export const SchoolListPage = () => {
  useDocumentTitle("学校列表", true);
  const data = useAuth();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [param, setParam] = useSchoolSearchParams();
  const {
    isLoading,
    error,
    totalElements,
    data: list,
  } = useSchoolList(useDebounce(param, 200));

  const imgUrl = process.env.REACT_APP_IMG_URL;

  //分页
  const paginationProps = {
    defaultCurrent: 1,
    pageSize: 10,
    total: totalElements,
    onChange: (current: number) =>
      setParam({
        ...param,
        pageNum: current - 1,
      }),
  };

  //学校层次
  const { data: schoolLevelList } = useSchoolLevelListAll();
  const schoolLevelOptions = schoolLevelList?.data?.map((item: any) => (
    <Option value={item.schoolLevelId}>{item.schoolLevelName}</Option>
  ));

  //学校类型
  const { data: schoolTypeList } = useSchoolTypeListAll();
  const schoolTypeOptions = schoolTypeList?.data?.map((item: any) => (
    <Option value={item.schoolTypeId}>{item.schoolTypeName}</Option>
  ));

  //动态标签
  const { data: trendsTagList } = useTrendsTagListAll(1);
  const trendsTagOptions = trendsTagList?.data?.map((item: any) => (
    <Option value={item.trendsTagId}>{item.trendsTagName}</Option>
  ));

  //时间
  const [schoolAddTimeStart, setSchoolAddTimeStart] = useState("");
  const [schoolAddTimeEnd, setSchoolAddTimeEnd] = useState("");
  const handleSelectTime = (value: any, dateString: any) => {
    setSchoolAddTimeStart(dateString[0]);
    setSchoolAddTimeEnd(dateString[1]);
  };

  const [op, setOp] = useState(1);
  function changeOpSubmit(e: number) {
    setOp(e);
  }
  //提交
  const onFinish = (values: any) => {
    if (op === 1) {
      setParam({
        ...param,
        schoolName: values.schoolName,
        schoolAttribute: values.schoolAttribute,
        schoolLevelId: values.schoolLevelId,
        schoolTypeId: values.schoolTypeId,
        trendsTagId: values.trendsTagId,
        schoolAddTimeStart: schoolAddTimeStart,
        schoolAddTimeEnd: schoolAddTimeEnd,
        pageNum: null,
      });
    } else if (op === 2) {
      let schoolName = values.schoolName;
      let schoolAttribute = values.schoolAttribute;
      let schoolLevelId = values.schoolLevelId;
      let schoolTypeId = values.schoolTypeId;
      let trendsTagId = values.trendsTagId;
      let token = data?.adminAccount?.token;
      if (schoolName === undefined) {
        schoolName = "";
      }
      if (schoolAttribute === undefined) {
        schoolAttribute = "";
      }
      if (schoolLevelId === undefined) {
        schoolLevelId = "";
      }
      if (schoolTypeId === undefined) {
        schoolTypeId = "";
      }
      if (trendsTagId === undefined) {
        trendsTagId = "";
      }
      if (token === undefined) {
        token = "";
      }
      Modal.confirm({
        title: "确定导出当前列表数据吗?",
        content: "点击确定导出",
        okText: "确定",
        onOk(): any {
          return new Promise((resolve, reject) => {
            window.location.href =
              apiUrl +
              `/school/export?schoolName=${schoolName}&schoolAttribute=${schoolAttribute}&schoolLevelId=${schoolLevelId}&schoolTypeId=${schoolTypeId}&trendsTagId=${trendsTagId}&schoolAddTimeStart=${schoolAddTimeStart}&schoolAddTimeEnd=${schoolAddTimeEnd}&token=${token}`;
            message.success("导出成功");
            Modal.destroyAll();
          }).catch(() => console.log("Oops errors!"));
        },
        cancelText: "取消",
      });
    }
  };

  //删除事件
  const { mutateAsync: deleteMutateAsync } = useDeleteSchool();
  const confirmDeleteSchool = (schoolId: number) => {
    Modal.confirm({
      title: "确定删除这条数据吗?",
      content: "点击确定删除",
      okText: "确定",
      onOk(): any {
        return new Promise((resolve, reject) => {
          deleteMutateAsync({ schoolId }).then((data) => {
            if (data.status === 0) {
              message.info("删除成功");
              Modal.destroyAll();
            } else {
              message.error(data.msg);
            }
          });
        }).catch(() => console.log("Oops errors!"));
      },
      cancelText: "取消",
    });
  };

  //重置密码
  const { mutateAsync: resetIdentityAccount } = useResetIdentityAccount();
  const confirmResetIdentityAccount = (
    identityType: number,
    identityId: number
  ) => {
    Modal.confirm({
      title: "确定操作这条数据吗?",
      content: "点击确定取消",
      okText: "确定",
      onOk(): any {
        return new Promise((resolve, reject) => {
          resetIdentityAccount({ identityType, identityId }).then((data) => {
            if (data.status === 0) {
              message.info("操作成功");
              Modal.destroyAll();
            } else {
              message.error(data.msg);
            }
          });
        }).catch(() => console.log("Oops errors!"));
      },
      cancelText: "取消",
    });
  };

  //分页
  return (
    <div>
      <Row style={{ marginBottom: 24 }}>
        <Col span={12}>
          <Link to={"/xuexiao/school-manage/school/add"}>
            <Button type="primary">新建</Button>
          </Link>
        </Col>
        <Col span={12} style={{ textAlign: "right" }}></Col>
      </Row>
      <Card style={{ backgroundColor: "#f2f3f5", margin: "24px 0 24px 0" }}>
        <Form onFinish={onFinish}>
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item name="schoolAddTimeRange" label="创建时间">
                <RangePicker
                  onChange={handleSelectTime}
                  showTime
                  format="YYYY-MM-DD HH:mm:ss"
                  style={{ width: "100%" }}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="schoolName" label="学校名称">
                <Input placeholder="请输入学校名称" allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="schoolAttribute" label="学校性质">
                <Select
                  placeholder="请选择学校性质"
                  showSearch
                  allowClear
                  optionFilterProp="children"
                >
                  <Option value={1}>公办</Option>
                  <Option value={2}>民办</Option>
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item name="schoolLevelId" label="学校层次">
                <Select
                  placeholder="请选择学校层次"
                  showSearch
                  allowClear
                  optionFilterProp="children"
                >
                  {schoolLevelOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="schoolTypeId" label="学校类型">
                <Select
                  placeholder="请选择学校类型"
                  showSearch
                  allowClear
                  optionFilterProp="children"
                >
                  {schoolTypeOptions}
                </Select>
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="trendsTagId" label="动态标签">
                <Select
                  placeholder="请选择动态标签"
                  showSearch
                  allowClear
                  optionFilterProp="children"
                >
                  {trendsTagOptions}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Form.Item style={{ marginBottom: 0 }}>
            <Button
              onClick={() => changeOpSubmit(1)}
              style={{ marginLeft: 70 }}
              type="primary"
              htmlType="submit"
            >
              提交
            </Button>
            <Button
              onClick={() => changeOpSubmit(2)}
              style={{ marginLeft: 20 }}
              type="primary"
              htmlType="submit"
            >
              导出
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <Table
        loading={isLoading}
        rowKey={(columns) => columns.schoolId}
        pagination={paginationProps}
        columns={[
          {
            title: "ID",
            dataIndex: "schoolId",
            key: "schoolId",
            align: "center",
            width: 100,
            fixed: "left",
          },
          {
            title: "校徽",
            dataIndex: "schoolLogo",
            key: "schoolLogo",
            align: "center",
            width: 100,
            fixed: "left",
            render: (value) =>
              value ? (
                <Image src={imgUrl + value} width={60} />
              ) : (
                <Image src={picNone} width={60} />
              ),
          },
          {
            title: "名称",
            dataIndex: "schoolName",
            key: "schoolName",
            align: "center",
            width: 200,
            fixed: "left",
          },
          {
            title: "电话",
            dataIndex: "schoolTel",
            key: "schoolTel",
            align: "center",
            width: 150,
          },
          {
            title: "地址",
            dataIndex: "schoolAddress",
            key: "schoolAddress",
            align: "center",
            width: 300,
            render: (value, school) =>
              school.provinceAreaName ||
              school.cityAreaName ||
              school.districtAreaName ||
              school.schoolAddress ? (
                <div>
                  <span>
                    {school.provinceAreaName}
                    {school.cityAreaName}
                    {school.districtAreaName}
                  </span>
                  <br />
                  <span>{value}</span>
                </div>
              ) : (
                "-"
              ),
          },
          {
            title: "性质",
            dataIndex: "schoolAttribute",
            key: "schoolAttribute",
            align: "center",
            width: 120,
            render: (value) =>
              value === 1 ? "公办" : value === 2 ? "民办" : "-",
          },
          {
            title: "层次",
            dataIndex: "schoolLevelName",
            key: "schoolLevelName",
            align: "center",
            width: 150,
          },
          {
            title: "类型",
            dataIndex: "schoolTypeName",
            key: "schoolTypeName",
            align: "center",
            width: 150,
          },
          {
            title: "招生类型",
            dataIndex: "trendsTagNameList",
            key: "trendsTagNameList",
            align: "center",
            width: 200,
            render: (value) =>
              value
                ? value?.map(function (item: string) {
                    return <Tag color="cyan">{item}</Tag>;
                  })
                : "-",
          },
          {
            title: "数据",
            dataIndex: "schoolBrowseNum",
            key: "schoolBrowseNum",
            align: "center",
            width: 200,
            render: (value, school) => (
              <div>
                <div>浏览量：{value}</div>
                <div>点赞量：{school.schoolClickNum}</div>
                <div>关注量：{school.schoolCollectNum}</div>
              </div>
            ),
          },
          {
            title: "创建时间",
            dataIndex: "schoolAddTime",
            key: "schoolAddTime",
            align: "center",
            width: 200,
            render: (value) =>
              value ? dayjs(value).format("YYYY-MM-DD HH:mm:ss") : "-",
          },
          {
            title: "排序",
            dataIndex: "schoolSort",
            key: "schoolSort",
            align: "center",
            width: 100,
          },
          {
            title: "状态",
            dataIndex: "schoolShow",
            key: "schoolShow",
            align: "center",
            width: 100,
            render: (value) => (value ? "显示" : "不显示"),
          },
          {
            title: "热门",
            dataIndex: "schoolHot",
            key: "schoolHot",
            align: "center",
            width: 100,
            render: (value) => (value ? "是" : "否"),
          },
          {
            title: "操作",
            key: "action",
            align: "center",
            render: (value, school) => (
              <Space size="middle">
                <Link
                  to={"/xuexiao/school-manage/school/update/" + school.schoolId}
                >
                  编辑
                </Link>
                <a onClick={() => confirmDeleteSchool(school.schoolId)}>删除</a>
                <a
                  onClick={() =>
                    confirmResetIdentityAccount(2, school.schoolId)
                  }
                >
                  重置密码
                </a>
              </Space>
            ),
            width: 200,
            fixed: "right",
          },
        ]}
        dataSource={list || []}
        scroll={{ x: 1250 }}
      />
      <ErrorBox error={error} />
    </div>
  );
};
