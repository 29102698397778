import { Button, Col, Divider, Form, Input, message, Radio, Row, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEditFeedbackClass, useFeedbackClass } from "../../services/feedback-class-service";
import { useDocumentTitle } from "../../utils"

export const FeedbackClassUpdatePage = () => {
    //标题
    useDocumentTitle('编辑建议类型', true)
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const feedbackClassId = Number(pathname.match(/user\/feedback-manage\/feedback-class\/update\/(\d+)/)?.[1])

    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };

    //准备回显
    const { data: editingFeedbackClass, isLoading } = useFeedbackClass(feedbackClassId);
    const [form] = useForm();
    useEffect(() => {
        form.setFieldsValue({ ...editingFeedbackClass?.data });
    }, [editingFeedbackClass, form]);


    //编辑
    const [submitLoading, setSubmitLoading] = useState(false);
    const { mutateAsync } = useEditFeedbackClass();
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...editingFeedbackClass?.data, ...values }).then((data) => {
            if (data.status === 0) {
                message.success(data.msg);
                navigate('/user/feedback-manage/feedback-class/list')
            } else {
                setSubmitLoading(false);
                message.error(data.msg);
            }
        }).catch(err => {
            message.error('提交失败，请重新提交');
            setSubmitLoading(false);
        });
    };

    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/user/feedback-manage/feedback-class/list"}>
                        <Button type="primary">列表</Button>
                    </Link>
                </Col>
            </Row>

            <Divider style={{ marginBottom: 24 }} />

            <Spin spinning={isLoading}>
                <Form
                    form={form}
                    {...layout}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label={"名称"}
                        name={"feedbackClassName"}
                        rules={[{ required: true, message: "名称必填" }]}
                    >
                        <Input style={{ width: 380 }} placeholder={"请输入名称"} />
                    </Form.Item>

                    <Form.Item
                        label={"排序"}
                        name={"feedbackClassSort"}
                        rules={[{ required: true, message: "排序必填" }]}
                    >
                        <Input style={{ width: 380 }} placeholder={"请输入排序"} />
                    </Form.Item>

                    <Form.Item
                        label={"状态"}
                        name={"feedbackClassShow"}
                        rules={[{ required: true, message: "状态必选" }]}
                        style={{ textAlign: 'left' }}
                    >
                        <Radio.Group>
                            <Radio defaultChecked value={true}>显示</Radio>
                            <Radio value={false}>不显示</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                        <Button loading={submitLoading} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>

        </div>
    )
}

