import { useQuery } from "react-query"
import { useHttp } from "../utils/http"

export const useSchoolDetail = (schoolId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['schoolDetail', { schoolId }],
        () => client(`schoolDetail/${schoolId}`),
        {
            enabled: Boolean(schoolId)
        }
    )
}