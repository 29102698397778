import { Upload, message, Form, Input } from 'antd';
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { getToken } from '../utils/auth-provider';
import { useState } from 'react';
import { FormProvider } from 'antd/lib/form/context';
import { upload } from '@testing-library/user-event/dist/upload';

const apiUrl = process.env.REACT_APP_API_URL;
const imgUrl = process.env.REACT_APP_IMG_URL;
const token = getToken();

const UploadPicNoSet = (props: any) => {

    const [pic, setPic] = useState("");
    let { name, label, form, namePath, action, style, required } = props;

    if (required === undefined) {
        required = true;
    }
    if(namePath === undefined){
        namePath = name;
    }

    const [uploading, setUploading] = useState(false);

    let uploadName = ["uploadImage"];
    if (name instanceof Array) {
        uploadName = name.concat();
        uploadName[uploadName.length - 1] = uploadName[uploadName.length - 1] + 'Upload'
    } else {
        uploadName = [name + "Upload"]
    }

    console.log(uploadName, name, name instanceof Array);

    let initialValue: any[] = [];
    if (action === 'update') {
        let data = form.getFieldValue(namePath);
        if (data&&!pic) {
            // console.log("getFieldValue",data);
            setPic(data)
        }
        initialValue = [
            {
                "uid": "",
                "lastModified": "",
                "lastModifiedDate": "",
                "name": "",
                "size": "",
                "type": "",
                "percent": "",
                "originFileObj": {
                    "uid": ""
                },
                "status": "done",
                "response": {
                    "status": 0,
                    "data": {
                        "relativePath": "",
                        "realPath": ""
                    }
                },
                "xhr": {}
            }
        ]
    }

    const uploadButton = (
        <div>
            {uploading ? <LoadingOutlined /> : <PlusOutlined />}
            <div style={{ marginTop: 8 }}>上传图片</div>
        </div>
    );

    const beforeUpload = (file: any) => {
        setPic("");
        setUploading(true);
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            message.error('只支持jpg或png格式的图片!');
            setUploading(false);
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            message.error('图片大小不能超过2MB!');
            setUploading(false);
        }
        return isJpgOrPng && isLt2M;
    }

    const handleChange = (info: any) => {
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            const relativePath = info?.file?.response?.data?.relativePath
            setPic(relativePath);
            console.log(form,namePath);
            
            form.setFieldValue(namePath, relativePath);
            setUploading(false);
        }
    };

    const normFile = (e: any) => {
        if (Array.isArray(e)) {
            return e;
        }
        return e && e.fileList;
    };

    return (
        <>
            <Form.Item
                name={uploadName}
                label={label}
                valuePropName="fileList"
                getValueFromEvent={normFile}
                rules={[{ required: required, message: label + "必传" }]}
                style={style}
                initialValue={initialValue}
            >
                <Upload
                    accept="image/jpeg,image/png"
                    name="imageFile"
                    listType="picture-card"
                    className="avatar-uploader"
                    showUploadList={false}
                    action={apiUrl + "/upload/img"}
                    beforeUpload={beforeUpload}
                    onChange={handleChange}
                    headers={{ "Authorization": "Bearer " + token }}
                >
                    {pic ? (
                        <img
                            src={imgUrl + pic}
                            alt="avatar"
                            style={{ maxWidth: '100%', maxHeight: '100%' }}
                        />
                    ) : (uploadButton)}
                </Upload>
            </Form.Item>
            <Form.Item
                hidden
                name={name}
                rules={[{ required: required, message: label + "必传" }]}
            >
                <Input></Input>
            </Form.Item>
        </>
    )
}

export default UploadPicNoSet;