import { Button, Card, Col, Form, message, Modal, Row, Space, Table, DatePicker, Input, Image } from "antd"
import dayjs from "dayjs"
import { useState } from "react"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useSalesmanList, useDeleteSalesman } from "../../services/salesman-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useSalesmanSearchParams } from "./util"
import picNone from "../../assets/pic-none.png"
import { useResetIdentityAccount } from "../../services/identity-account-service"
const { RangePicker } = DatePicker

export const SalesmanListPage = () => {
    useDocumentTitle('达人列表', true)
    const [param, setParam] = useSalesmanSearchParams()
    const { isLoading, error, totalElements, data: list } = useSalesmanList(useDebounce(param, 200))

    const imgUrl = process.env.REACT_APP_IMG_URL;

    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) =>
            setParam({
                ...param,
                pageNum: (current - 1),
            })
    }

    //时间
    const [salesmanAddTimeStart, setSalesmanAddTimeStart] = useState('');
    const [salesmanAddTimeEnd, setSalesmanAddTimeEnd] = useState('');
    const handleSelectTime = (value: any, dateString: any) => {
        setSalesmanAddTimeStart(dateString[0]);
        setSalesmanAddTimeEnd(dateString[1]);
    }

    //提交
    const onFinish = (values: any) => {
        setParam({
            ...param,
            salesmanName: values.salesmanName,
            salesmanAddTimeStart: salesmanAddTimeStart,
            salesmanAddTimeEnd: salesmanAddTimeEnd,
            pageNum: null
        })
    }

    //删除事件
    const { mutateAsync: deleteMutateAsync } = useDeleteSalesman();
    const confirmDeleteSalesman = (salesmanId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    deleteMutateAsync({ salesmanId }).then((data) => {
                        if (data.status === 0) {
                            message.info('删除成功');
                            Modal.destroyAll();
                        } else {
                            message.error(data.msg)
                        }
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };

    //重置密码
    const { mutateAsync: resetIdentityAccount } = useResetIdentityAccount();
    const confirmResetIdentityAccount = (identityType: number, identityId: number) => {
        Modal.confirm({
            title: "确定操作这条数据吗?",
            content: "点击确定取消",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    resetIdentityAccount({ identityType, identityId }).then((data) => {
                        if (data.status === 0) {
                            message.info("操作成功");
                            Modal.destroyAll();
                        } else {
                            message.error(data.msg);
                        }
                    });
                }).catch(() => console.log("Oops errors!"));
            },
            cancelText: "取消",
        });
    };

    //分页
    return (
        <div>
            <Card style={{ backgroundColor: "#f2f3f5", margin: '24px 0 24px 0' }}>
                <Form onFinish={onFinish}>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item name="salesmanAddTimeRange" label="创建时间">
                                <RangePicker onChange={handleSelectTime} showTime format="YYYY-MM-DD HH:mm:ss" style={{ width: '100%' }} allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="salesmanName" label="达人名称">
                                <Input placeholder='请输入达人名称' allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item style={{ marginBottom: 0 }}>
                        <Button style={{ marginLeft: 70 }} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
            <Table
                loading={isLoading}
                rowKey={columns => columns.salesmanId}
                pagination={paginationProps}
                columns={[
                    {
                        title: 'ID',
                        dataIndex: 'salesmanId',
                        key: 'salesmanId',
                        align: 'center',
                        width: 100,
                        fixed: 'left',
                    },
                    {
                        title: '头像',
                        dataIndex: 'salesmanHeadImg',
                        key: 'salesmanHeadImg',
                        align: 'center',
                        width: 100,
                        fixed: 'left',
                        render: (value) => (
                            value ? <Image src={imgUrl + value} width={60} /> : <Image src={picNone} width={60} />
                        )
                    },
                    {
                        title: '名称',
                        dataIndex: 'salesmanName',
                        key: 'salesmanName',
                        align: 'center',
                        width: 200,
                        fixed: 'left',
                    },
                    {
                        title: '手机',
                        dataIndex: 'salesmanTel',
                        key: 'salesmanTel',
                        align: 'center',
                        width: 200,
                    },
                    {
                        title: '数据',
                        dataIndex: 'salesmanBrowseNum',
                        key: 'salesmanBrowseNum',
                        align: 'center',
                        width: 200,
                        render: (value, salesman) => (
                            <div>
                                <div>浏览量：{value}</div>
                                <div>点赞量：{salesman.salesmanClickNum}</div>
                                <div>关注量：{salesman.salesmanCollectNum}</div>
                            </div>
                        )
                    },
                    {
                        title: '创建时间',
                        dataIndex: 'salesmanAddTime',
                        key: 'salesmanAddTime',
                        align: 'center',
                        width: 200,
                        render: (value) => (
                            value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : '-'
                        )
                    },
                    {
                        title: '操作',
                        key: 'action',
                        align: 'center',
                        render: (value, salesman) => (
                            <Space size="middle">
                                {/* <Link to={"/daren/salesman-manage/salesman/update/" + salesman.salesmanId}>
                                    编辑
                                </Link> */}
                                <a onClick={() => confirmDeleteSalesman(salesman.salesmanId)}>
                                    删除
                                </a>
                                <a onClick={() => confirmResetIdentityAccount(8, salesman.salesmanId)}>
                                    重置密码
                                </a>
                            </Space>
                        ),
                        width: 150,
                        fixed: 'right',
                    }
                ]}
                dataSource={list || []}
                scroll={{ x: 1200 }}
            />
            <ErrorBox error={error} />
        </div>
    )
}