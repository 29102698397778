import { useMutation, useQuery, useQueryClient } from "react-query";
import { RecruitExperience } from "../interfaces/recruit-experience";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useRecruitExperienceList = (param?: Partial<RecruitExperience>) => {
    const client = useHttp();
    const result = useQuery<any>(['recruitExperiencePage', param], () => client('recruitExperience', { data: cleanObject(param) }))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return { ...result, totalElements, data };
}

export const useEditRecruitExperience = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<RecruitExperience>) => client(`recruitExperience`, {
            method: 'PUT',
            data: params
        }), {
        onSuccess: () => queryClient.invalidateQueries('recruitExperiencePage')
    }
    )
}

export const useAddRecruitExperience = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<RecruitExperience>) => client(`recruitExperience`, {
            data: params,
            method: 'POST'
        }), {
        onSuccess: () => queryClient.invalidateQueries('recruitExperiencePage')
    }
    )
}

export const useDeleteRecruitExperience = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ recruitExperienceId }: { recruitExperienceId: number }) =>
            client(`recruitExperience/${recruitExperienceId}`, {
                method: "DELETE",
            }), {
        onSuccess: () => queryClient.invalidateQueries('recruitExperiencePage')
    }
    );
};

export const useRecruitExperience = (recruitExperienceId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['recruitExperience', { recruitExperienceId }],
        () => client(`recruitExperience/${recruitExperienceId}`),
        {
            enabled: Boolean(recruitExperienceId)
        }
    )
}

export const useRecruitExperienceListAll = () => {
    const client = useHttp();
    return useQuery<any>(["recruitExperienceList", {}], () =>
        client(`recruitExperience/list`)
    );
};