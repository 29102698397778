import { Button, Col, Divider, Form, Input, message, Radio, Row, Spin, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEditTrendsTag, useTrendsTag } from "../../services/trends-tag-service";
import { useDocumentTitle } from "../../utils"
const { Option } = Select;

export const TrendsTagUpdatePage = () => {
    //标题
    useDocumentTitle('编辑动态标签', true)
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const trendsTagId = Number(pathname.match(/dress\/trends-manage\/trends-tag\/update\/(\d+)/)?.[1])

    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };

    //准备回显
    const { data: editingTrendsTag, isLoading } = useTrendsTag(trendsTagId);
    const [form] = useForm();
    useEffect(() => {
        form.setFieldsValue({ ...editingTrendsTag?.data });
    }, [editingTrendsTag, form]);


    //编辑
    const [submitLoading, setSubmitLoading] = useState(false);
    const { mutateAsync } = useEditTrendsTag();
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...editingTrendsTag?.data, ...values }).then((data) => {
            if (data.status === 0) {
                message.success(data.msg);
                navigate('/dress/trends-manage/trends-tag/list')
            } else {
                setSubmitLoading(false);
                message.error(data.msg);
            }
        }).catch(err => {
            message.error('提交失败，请重新提交');
            setSubmitLoading(false);
        });
    };

    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/dress/trends-manage/trends-tag/list"}>
                        <Button type="primary">列表</Button>
                    </Link>
                </Col>
            </Row>

            <Divider style={{ marginBottom: 24 }} />

            <Spin spinning={isLoading}>
                <Form
                    form={form}
                    {...layout}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label={"名称"}
                        name={"trendsTagName"}
                        rules={[{ required: true, message: "名称必填" }]}
                    >
                        <Input style={{ width: 380 }} placeholder={"请输入名称"} />
                    </Form.Item>

                    <Form.Item
                        label={"类型"}
                        name={"trendsTagType"}
                        rules={[{ required: true, message: "类型必选" }]}
                    >
                        <Select
                            style={{ width: 380 }}
                            placeholder={"请选择类型"}
                            showSearch
                            allowClear
                            optionFilterProp='children'
                        >
                            <Option value={1}>教育</Option>
                            <Option value={2}>招聘</Option>
                            <Option value={3}>生活</Option>
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label={"排序"}
                        name={"trendsTagSort"}
                        rules={[{ required: true, message: "排序必填" }]}
                    >
                        <Input style={{ width: 380 }} placeholder={"请输入排序"} />
                    </Form.Item>

                    <Form.Item
                        label={"状态"}
                        name={"trendsTagShow"}
                        rules={[{ required: true, message: "状态必选" }]}
                        style={{ textAlign: 'left' }}
                    >
                        <Radio.Group>
                            <Radio defaultChecked value={true}>显示</Radio>
                            <Radio value={false}>不显示</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label={"推荐"}
                        name={"trendsTagRecommend"}
                        rules={[{ required: true, message: "是否推荐必选" }]}
                        style={{ textAlign: 'left' }}
                    >
                        <Radio.Group>
                            <Radio value={true}>是</Radio>
                            <Radio value={false}>否</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                        <Button loading={submitLoading} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>

        </div>
    )
}

