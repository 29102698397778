import { useMutation, useQuery, useQueryClient } from "react-query";
import { SchoolAdv } from "../interfaces/school-adv";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useSchoolAdvList = (param?: Partial<SchoolAdv>) => {
    const client = useHttp();
    const result = useQuery<any>(['schoolAdvPage', param], () => client('schoolAdv', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditSchoolAdv = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<SchoolAdv>) => client(`schoolAdv`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('schoolAdvPage')
        }
    )
}

export const useAddSchoolAdv = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<SchoolAdv>) => client(`schoolAdv`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('schoolAdvPage')
        }
    )
}

export const useDeleteSchoolAdv = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ schoolAdvId }: { schoolAdvId: number}) =>
            client(`schoolAdv/${schoolAdvId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('schoolAdvPage')
        }
    );
};

export const useSchoolAdv = (schoolAdvId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['schoolAdv', {schoolAdvId}],
        () => client(`schoolAdv/${schoolAdvId}`),
        {
            enabled: Boolean(schoolAdvId)
        }
    )
}
