import { Button, Col, Divider, Form, Input, message, Radio, Row, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useSchoolSubject, useEditSchoolSubject } from "../../services/school-subject-service";
import { useDocumentTitle } from "../../utils";
import UploadPic from "../../components/upload-pic";
import PageUrlSelect from "../../components/page-url-select";
import BraftEditor from "braft-editor";
import RichText from "../../components/rich-text";
import { useSchoolSubjectDetail } from "../../services/school-subject-detail-service";

export const SchoolSubjectUpdatePage = () => {
  //标题
  useDocumentTitle("编辑学校专题", true);
  const navigate = useNavigate();
  const { pathname } = useLocation();
  const schoolSubjectId = Number(pathname.match(/xuexiao\/school-manage\/school-subject\/update\/(\d+)/)?.[1]);

  //布局
  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 18 },
  };
  const tailLayout = {
    wrapperCol: { offset: 3 },
  };

  //表单
  const [form] = useForm();
  const [schoolSubjectPic, setSchoolSubjectPic] = useState("");
  const [pageUrlId, setPageUrlId] = useState();
  const [schoolSubjectContent, setSchoolSubjectContent] = useState("");
  const [schoolSubjectLink, setSchoolSubjectLink] = useState(false);
  const schoolSubjectLinkChange = (e: any) => {
    setSchoolSubjectLink(e.target.value);
  }

  //准备回显
  const { data: editingSchoolSubject, isLoading } = useSchoolSubject(schoolSubjectId);

  useEffect(() => {
    form.setFieldsValue({ ...editingSchoolSubject?.data });
    //封面
    if (editingSchoolSubject?.data?.schoolSubjectPic) {
      setSchoolSubjectPic(editingSchoolSubject?.data?.schoolSubjectPic);
    }
    setSchoolSubjectLink(editingSchoolSubject?.data?.schoolSubjectLink);
    let pageUrlId = editingSchoolSubject?.data?.pageUrlId;
    setPageUrlId(pageUrlId);
  }, [editingSchoolSubject, form]);

  const { data: editingSchoolSubjectDetail } = useSchoolSubjectDetail(schoolSubjectId);
  useEffect(() => {
    if (editingSchoolSubjectDetail?.data?.schoolSubjectContent) {
      setSchoolSubjectContent(editingSchoolSubjectDetail?.data?.schoolSubjectContent);
      form.setFieldsValue({
        schoolSubjectContent: BraftEditor.createEditorState(
          editingSchoolSubjectDetail?.data?.schoolSubjectContent
        ),
      });
    }
  }, [editingSchoolSubjectDetail, form]);

  //新建
  const [submitLoading, setSubmitLoading] = useState(false);
  const { mutateAsync } = useEditSchoolSubject();
  const onFinish = (values: any) => {
    setSubmitLoading(true);
    mutateAsync({ ...editingSchoolSubject?.data, ...values, schoolSubjectPic, schoolSubjectContent, schoolSubjectLink })
      .then((data) => {
        if (data.status === 0) {
          message.success(data.msg);
          navigate("/xuexiao/school-manage/school-subject/list");
        } else {
          setSubmitLoading(false);
          message.error(data.msg);
        }
      })
      .catch((err) => {
        message.error("提交失败，请重新提交");
        setSubmitLoading(false);
      });
  };

  return (
    <div>
      <Row>
        <Col span={12}>
          <Link to={"/xuexiao/school-manage/school-subject/list"}>
            <Button type="primary">列表</Button>
          </Link>
        </Col>
      </Row>

      <Divider style={{ marginBottom: 24 }} />

      <Spin spinning={isLoading}>
        <Form
          form={form}
          {...layout}
          onFinish={onFinish}
        >
          <Form.Item
            label={"名称"}
            name={"schoolSubjectName"}
            rules={[{ required: true, message: "名称必填" }]}
          >
            <Input style={{ width: 380 }} placeholder={"请输入名称"} />
          </Form.Item>

          <UploadPic
            name={'uploadPic'}
            label={"图片"}
            pic={schoolSubjectPic}
            setPic={setSchoolSubjectPic}
            action={"update"}
            style={{ textAlign: "left" }}
          ></UploadPic>

          <Form.Item
            label={"排序"}
            name={"schoolSubjectSort"}
            rules={[{ required: true, message: "排序必填" }]}
          >
            <Input style={{ width: 380 }} placeholder={"请输入排序"} />
          </Form.Item>

          <Form.Item
            label={"状态"}
            name={"schoolSubjectShow"}
            rules={[{ required: true, message: "状态必选" }]}
            style={{ textAlign: "left" }}
          >
            <Radio.Group>
              <Radio defaultChecked value={true}>
                显示
              </Radio>
              <Radio value={false}>不显示</Radio>
            </Radio.Group>
          </Form.Item>

          <Form.Item
            label={"跳转"}
            name={"schoolSubjectLink"}
            rules={[{ required: true, message: "跳转必选" }]}
            style={{ textAlign: "left" }}
          >
            <Radio.Group onChange={schoolSubjectLinkChange}>
              <Radio value={true}>是</Radio>
              <Radio value={false}>否</Radio>
            </Radio.Group>
          </Form.Item>

          {schoolSubjectLink ? (
            <PageUrlSelect
              pageUrlId={pageUrlId}
            ></PageUrlSelect>
          ) : (
            <RichText
              label={"详情"}
              name={"schoolSubjectContent"}
              setContent={setSchoolSubjectContent}
            ></RichText>
          )}

          <Form.Item {...tailLayout} style={{ textAlign: "left" }}>
            <Button loading={submitLoading} type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
