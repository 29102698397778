import { Col, message, Modal, Row, Space, Table, Form, Input, Menu, Dropdown, InputNumber, Avatar } from "antd"
import Search from "antd/lib/input/Search"
import { Link } from "react-router-dom"
import dayjs from "dayjs"
import { ErrorBox } from "../../components/lib"
import { useAgentList, useAgreeAgent, useRejectAgent } from "../../services/agent-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useAgentSearchParams } from "./util"
import { useState } from "react"
import { DownOutlined } from "@ant-design/icons";

export const AgentListPage = () => {
  useDocumentTitle('代理商列表', true)
  const [param, setParam] = useAgentSearchParams()
  const { isLoading, error, totalElements, data: list } = useAgentList(useDebounce(param, 200))

  //分页
  const paginationProps = {
    showSizeChanger: true,
    onShowSizeChange: (current: number, pageSize: number) => {
      setParam({
        ...param,
        pageNum: current - 1,
        pageSize,
      });
    },
    defaultCurrent: 1,
    pageSize: param.pageSize,
    total: totalElements,
    onChange: (current: number, pageSize: number) =>
      setParam({
        ...param,
        pageNum: current - 1,
        pageSize,
      }),
  };
  //搜索
  const onFinish = (values: any) => {
    setParam({
      ...param,
      agentName: values.agentName,
      pageNum: null,
    });
  };
  //   编辑下拉菜单
  const optionMenu: any = (record: any) => {
    let diabled = record.agentState == 10 ? false : true;
    return (
      <Menu>
        <Menu.Item disabled={diabled}>
          <a onClick={() => confirmAgreeAgent(record.agentId)}>同意</a>
        </Menu.Item>
        <Menu.Item disabled={diabled}>
          <a onClick={() => confirmRejectAgent(record.agentId)}>拒绝</a>
        </Menu.Item>
      </Menu>
    );
  };

  const [optionAgentId, setOptionAgentId] = useState(0);
  //拒绝事件
  const setRemarkValue = (e: any) => {
    setRemark(e.target.value)

  }
  const { mutateAsync: rejectAgentAsync } = useRejectAgent();
  const [openReject, setOpenReject] = useState(false);
  const [remark, setRemark] = useState("");
  const confirmRejectAgent = (agentId: number) => {
    setOptionAgentId(agentId);
    setOpenReject(true);
  };
  const rejectAgent = () => {
    let param = {
      agentId: optionAgentId,
      remark: remark
    }
    console.log("rejectAgentparam:", param);
    return new Promise((resolve, reject) => {
      rejectAgentAsync(param).then((data) => {
        if (data.status === 0) {
          message.info("拒绝成功");
          setOpenReject(false)
        } else {
          message.error(data.msg);
        }
      });
    }).catch(() => console.log("Oops errors!"));
  }
  //同意事件
  const [openAgree, setOpenAgree] = useState(false);
  const [agentCourseProportion, setAgentCourseProportion] = useState(0);
  const [agentGoodsProportion, setAgentGoodsProportion] = useState(0);
  const [agnetServerProportion, setAgnetServerProportion] = useState(0);
  const [agnetMajorProportion, setAgnetMajorProportion] = useState(0);
  const setAgentCourseProportionValue = (e: any) => {
    setAgentCourseProportion(e)
  }
  const setAgentGoodsProportionValue = (e: any) => {
    setAgentGoodsProportion(e)
  }
  const setAgnetServerProportionValue = (e: any) => {
    setAgnetServerProportion(e)
  }
  const setAgnetMajorProportionValue = (e: any) => {
    setAgnetMajorProportion(e)
  }
  const { mutateAsync: agreeAgentAsync } = useAgreeAgent();
  const confirmAgreeAgent = (agentId: number) => {
    setOptionAgentId(agentId);
    setOpenAgree(true);
  };
  const agreeAgent = () => {
    let param = {
      agentId: optionAgentId,
      agentCourseProportion: agentCourseProportion,
      agentGoodsProportion: agentGoodsProportion,
      agnetServerProportion: agnetServerProportion,
      agnetMajorProportion: agnetMajorProportion,
    }
    console.log("agreeAgentparam:", param);

    return new Promise((resolve, reject) => {
      agreeAgentAsync(param).then((data) => {
        if (data.status === 0) {
          message.info("成功");
          setOpenAgree(false)
        } else {
          message.error(data.msg);
        }
      });
    }).catch(() => console.log("Oops errors!"));
  }
  //分页
  return (
    <div>
      <Modal
        title="Modal"
        open={openReject}
        onOk={rejectAgent}
        onCancel={() => setOpenReject(false)}
        okText="确认"
        cancelText="取消"
      >
        <Form.Item
          label={"拒绝说明"}
          name={"remark"}
          rules={[{ required: true, message: "拒绝说明必填" }]}
          initialValue={remark}
        >
          <Input style={{ width: 380 }} onChange={setRemarkValue} placeholder={"请输入拒绝说明"} />
        </Form.Item>
      </Modal>
      <Modal
        title="Modal"
        open={openAgree}
        onOk={agreeAgent}
        onCancel={() => setOpenAgree(false)}
        okText="确认"
        cancelText="取消"
      >
        <Form.Item
          label={"课程分润"}
          name={"agentCourseProportion"}
          rules={[{ required: true, message: "课程分润必填" }]}
          initialValue={agentCourseProportion}
        >
          <InputNumber style={{ width: 380 }} onChange={setAgentCourseProportionValue} placeholder={"请输入课程分润百分比"} addonAfter="%" />
        </Form.Item>
        <Form.Item
          label={"商品分润"}
          name={"agentGoodsProportion"}
          rules={[{ required: true, message: "商品分润必填" }]}
          initialValue={agentGoodsProportion}
        >
          <InputNumber style={{ width: 380 }} onChange={setAgentGoodsProportionValue} placeholder={"请输入商品分润百分比"} addonAfter="%" />
        </Form.Item>
        <Form.Item
          label={"服务分润"}
          name={"agnetServerProportion"}
          rules={[{ required: true, message: "服务分润必填" }]}
          initialValue={agnetServerProportion}
        >
          <InputNumber style={{ width: 380 }} onChange={setAgnetServerProportionValue} placeholder={"请输入服务分润百分比"} addonAfter="%" />
        </Form.Item>
        <Form.Item
          label={"学费分润"}
          name={"agnetMajorProportion"}
          rules={[{ required: true, message: "学费分润必填" }]}
          initialValue={agnetServerProportion}
        >
          <InputNumber style={{ width: 380 }} onChange={setAgnetMajorProportionValue} placeholder={"请输入学费分润百分比"} addonAfter="%" />
        </Form.Item>
      </Modal>
      <Row style={{ marginBottom: 24 }}>
        <Col span={12}>
          {/* <Link to={"/agent/agent-manage/agent/add"}>
            <Button type="primary">新建</Button>
          </Link> */}
        </Col>
        <Col span={12} style={{ textAlign: "right" }}>
          <Search
            placeholder="可搜索代理商名称"
            style={{ width: 200 }}
            value={param.agentName}
            onChange={(evt) =>
              setParam({
                ...param,
                agentName: evt.target.value,
                pageNum: null,
              })
            }
            onSearch={(value) =>
              setParam({
                ...param,
                agentName: value,
                pageNum: null,
              })
            }
          />
        </Col>
      </Row>
      <Table
        loading={isLoading}
        rowKey={columns => columns.agentId}
        pagination={paginationProps}
        scroll={{ x: 1500 }}
        columns={[
          {
            title: 'ID',
            dataIndex: 'agentId',
            key: 'agentId',
            align: 'center',
            width: 100,
            fixed: 'left',
          },
          {
            title: '代理商名称',
            dataIndex: 'agentName',
            key: 'agentName',
            align: 'center',
            width: 150,
            fixed: 'left',
          },
          {
            title: '代理商类型',
            dataIndex: 'agentType',
            key: 'agentType',
            align: 'center',
            width: 150,
            render: (value) => <>{value == 1 ? "省代" : "市代"}</>
          },
          {
            title: '代理地区',
            dataIndex: 'areaName',
            key: 'areaName',
            width: 150,
            align: 'center',
          },
          {
            title: '代理商总金额',
            dataIndex: 'agentMoneyTotal',
            key: 'agentMoneyTotal',
            width: 150,
            align: 'center',
          },
          {
            title: '分润比例',
            dataIndex: 'agentCourseProportion',
            key: 'agentCourseProportion',
            width: 150,
            align: 'center',
            render(value, record, index) {
              return (<>
                <div>
                  <Space size={8}>
                    <label htmlFor="">课程分润</label>
                    <text>{record?.agentCourseProportion?record?.agentCourseProportion:0}%</text>
                  </Space>
                </div>
                <div>
                  <Space size={8}>
                    <label htmlFor="">商品分润</label>
                    <text>{record?.agentGoodsProportion?record?.agentGoodsProportion:0}%</text>
                  </Space>
                </div>
                <div>
                  <Space size={8}>
                    <label htmlFor="">服务分润</label>
                    <text>{record?.agentServerProportion?record?.agentServerProportion:0}%</text>
                  </Space>
                </div>
                <div>
                  <Space size={8}>
                    <label htmlFor="">学费分润</label>
                    <text>{record?.agentMajorProportion?record?.agentMajorProportion:0}%</text>
                  </Space>
                </div>
              </>)
            },
          },
          {
            title: '申请时间',
            dataIndex: 'agentAddTime',
            key: 'agentAddTime',
            width: 250,
            align: 'center',
            render: (value) => (
              <span>
                {value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : '无'}
              </span>
            ),
          },
          {
            title: '申请用户',
            dataIndex: 'memberId',
            key: 'memberId',
            width: 150,
            align: 'center',
            render(value, record, index) {
              if (value) {
                return (<>
                  <div style={{ textAlign: "center" }}>
                    <div>
                      <Avatar src={record?.member?.memberHeadImg} />
                    </div>
                    <div>
                      {record?.member?.memberNickname}
                    </div>
                  </div>
                </>)
              }
            },
          },
          {
            title: '申请状态',
            dataIndex: 'agentStateValue',
            key: 'agentStateValue',
            width: 150,
            align: 'center',
            render(value, record, index) {
              if (record.agentState != 30) {
                return (<>
                  {value}<br />
                  {record.remark ? "拒绝原因:" + record.remark : ""}
                </>);
              } else {
                return (<>{value}</>)
              }
            },
          },

          {
            title: '审核人',
            dataIndex: 'lastOptionName',
            key: 'lastOptionName',
            width: 150,
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            align: 'center',
            fixed: "right",
            render: (value, agent) => (
              <Space size="middle">
                <Link to={"/agent/agent-manage/agent/detail?agentId=" + agent.agentId}>
                  详情
                </Link>
                {
                  agent.agentState == 10 ? <>
                    <Dropdown dropdownRender={optionMenu.bind(this, agent)}>
                      <a onClick={(e) => e.preventDefault()}>
                        <Space>
                          审核
                          <DownOutlined />
                        </Space>
                      </a>
                    </Dropdown>
                  </>
                    : ""
                }
              </Space>
            ),
            width: 150,
          }
        ]}
        dataSource={list || []} />
      <ErrorBox error={error} />
    </div>
  )
}