import { Button, Col, Dropdown, Menu, message, Modal, Row, Space, Table, Image, Select, Form, Input, Card } from "antd"
import Search from "antd/lib/input/Search"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import  { useShowcaseList, useDeleteShowcase }  from "../../services/mall-showcase-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useShowcaseSearchParams } from "./util"
import DownOutlined from '@ant-design/icons/lib/icons/DownOutlined'
import { useAreaListCity } from "../../services/area-service"

export const MallShowcaseListPage = () => {
    useDocumentTitle('橱窗列表', true)
    const imgUrl = process.env.REACT_APP_IMG_URL
    const [param, setParam] = useShowcaseSearchParams()
    const {isLoading, error, totalElements, data: list} = useShowcaseList(useDebounce(param, 200))
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) => 
            setParam({
            ...param,
            pageNum: (current -1),
        })
    }
    //删除事件
    const useMutateDeleteShowcase = useDeleteShowcase;
    const { mutateAsync } = useMutateDeleteShowcase();
    const confirmDeleteShowcase = (showcaseId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ showcaseId }).then(() => {
                        message.info('删除成功');
                        Modal.destroyAll();
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    //准备区域数据
    const { data: areaList } = useAreaListCity();
    const areaListOptions = areaList?.data?.map((item: any, value: any) => (
        <Select.Option value={item.areaId} key={item.areaId}>
            {item.areaName}
        </Select.Option>
    ));
    const onFinish = (values: any) => {
        setParam({
            ...param,
            ...values,
            pageNum: null
        })
    }
    //下拉菜单
    const menu:any = (record:any) => {
        return (
            <Menu>
                <Menu.Item>
                    <Link to={"/shop/dress-manage/mall-showcase/goods/list/" + record.showcaseId}>
                        已选商品列表
                    </Link>
                </Menu.Item>
                <Menu.Item>
                    <Link to={"/shop/dress-manage/mall-showcase/goods/listNo/" + record.showcaseId}>
                        未选商品列表
                    </Link>
                </Menu.Item>
            </Menu>
        )
    }
    return (
        <div>
            <Row style={{marginBottom: 24}}>
                <Col span={12}>
                    <Link to={"/shop/dress-manage/mall-showcase/add"}>
                        <Button type="primary">新建橱窗</Button>
                    </Link>
                </Col>
            </Row>
            <Card style={{ backgroundColor: "#f2f3f5", margin: '24px 0 24px 0' }}>
                <Form onFinish={onFinish}>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item name="showcaseName" label="名称">
                                <Input placeholder='请输入名称' allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="areaId" label="区域">
                                <Select
                                    placeholder='请选择区域'
                                    showSearch
                                    allowClear
                                    optionFilterProp='children'
                                >
                                    {areaListOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item style={{ marginBottom: 0 }}>
                        <Button
                            style={{ marginLeft: 70 }}
                            type="primary"
                            htmlType="submit"
                        >
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
            <Table 
                loading = { isLoading }
                pagination = { paginationProps } 
                rowKey={ columns => columns.showcaseId } 
                columns = {[
                {
                    title: '橱窗ID',
                    dataIndex: 'showcaseId',
                    key: 'showcaseId',
                    width: 100,
                },
                {
                    title: '地区',
                    dataIndex: 'areaName',
                    key: 'areaName',
                    width: 100,
                },
                {
                    title: '橱窗名称',
                    dataIndex: 'showcaseName',
                    key: 'showcaseName',
                },
                {
                    title: '橱窗图片',
                    dataIndex: 'showcaseBanner',
                    key: 'showcaseBanner',
                    render: (value, showcase) => (
                        showcase.showcaseBanner ? <Image width={150} src={imgUrl + showcase.showcaseBanner}/> : '无'
                    ),
                },
                {
                    title: '橱窗排序',
                    dataIndex: 'showcaseSort',
                    key: 'showcaseSort',
                },
                {
                    title: '是否显示',
                    dataIndex: 'showcaseShow',
                    key: 'showcaseShow',
                    render: (value, showcase) => (
                        <span>{showcase.showcaseShow ? '显示' : '不显示'}</span>
                    ),
                },
                {
                    title: '操作',
                    key: 'action',
                    render: (value, showcase) => (
                        <Space size="middle">
                            <Dropdown overlay={menu.bind(this,showcase)}>
                                <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                                    商品 <DownOutlined />
                                </a>
                            </Dropdown>
                            <Link to={"/shop/dress-manage/mall-showcase/update/" + showcase.showcaseId}>
                                编辑
                            </Link>
                            <a onClick={() => confirmDeleteShowcase(showcase.showcaseId)}>
                                删除
                            </a>
                        </Space>
                    ),
                    width: 200,
                },
            ]}
            dataSource={ list || [] }  />
            <ErrorBox error={ error } />
        </div>
    )
}