import { Button, Card, Col, Form, DatePicker, Row, Select, Space, Table, message, Upload, Input, Modal, Spin } from "antd"
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { useForm } from "antd/lib/form/Form"
import dayjs from "dayjs"
import { useState } from "react"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useOrdersBatchDeliveryList, useAddOrdersBatchDelivery } from "../../services/orders-batch-delivery-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useOrdersBatchDeliverySearchParams } from "./util"
import { getToken } from "../../utils/auth-provider"
import DownloadOutlined from '@ant-design/icons/lib/icons/DownloadOutlined'
const { RangePicker } = DatePicker
const { Option } = Select

export const OrdersBatchDeliveryListPage = () => {
    useDocumentTitle('批量发货', true)
    const apiUrl = process.env.REACT_APP_API_URL
    const token = getToken()
    const [param, setParam] = useOrdersBatchDeliverySearchParams()
    const {isLoading, error, totalElements, data: list} = useOrdersBatchDeliveryList(useDebounce(param, 200))
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) => 
            setParam({
            ...param,
            pageNum: (current -1),
        })
    }
    //上传文件
    //发货
    const [modalLoading, setModalLoading] = useState(false);
    const [isModalVisible, setIsModalVisible] = useState(false);
    const [submitDeliveryLoading, setSubmitDeliveryLoading] = useState(false);
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    //准备跳转链接
    const [form] = useForm();
    //文件上传
    const [fileName, setFileName] = useState("");
    const [uploadLoading, setUploadLoading] = useState(false);
    const beforeUpload = (file:any) => {
        setUploadLoading(true);
        const isLt2M = file.size / 1024 / 1024 < 20;
        if (!isLt2M) {
            setUploadLoading(false);
            message.error('文件要小于 20MB!');
        }
        return isLt2M;
    }
    const handleChange = (info:any) => {
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            setUploadLoading(false);
            const relativePath = info?.file?.response?.data?.relativePath
            setFileName(relativePath)
            message.info("文件上传成功");
        }
    };
    const normFile = (e: any) => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    };
    const showModal = () => {
        setIsModalVisible(true);
    };
    const useMutateAddOrdersBatchDelivery = useAddOrdersBatchDelivery
    const { mutateAsync: mutateAsyncAddOrdersBatchDelivery } = useMutateAddOrdersBatchDelivery();
    const handleOk = () => {
        if(fileName.length == 0){
            message.error('请上传文件');
        }
        if(fileName.length > 0){
            setSubmitDeliveryLoading(true)
            mutateAsyncAddOrdersBatchDelivery({ fileName }).then(() => {
              setIsModalVisible(false);
              setSubmitDeliveryLoading(false);
            });
        }
    }
    const handleCancel = () => {
        setIsModalVisible(false);
    }; 
    //检索
    const rangeConfig = {
        rules: [{ type: 'array' as const, message: '请选择时间!' }],
    };
    const [startOrdersBatchDeliveryTime, setStartOrdersBatchDeliveryTime] = useState();
    const [endOrdersBatchDeliveryTime, setEndOrdersBatchDeliveryTime] = useState();
    const handleSelectTime = (value:any ,dateString:any) => {
        setStartOrdersBatchDeliveryTime(dateString[0]);
        setEndOrdersBatchDeliveryTime(dateString[1]);
    }
    //完成
    const onFinish = (values: any) => {
        setParam({
            ...param,
            startOrdersBatchDeliveryTime: startOrdersBatchDeliveryTime,
            endOrdersBatchDeliveryTime: endOrdersBatchDeliveryTime,
            ordersBatchDeliveryState: values.ordersBatchDeliveryState,
            pageNum: null
        })
    }
    // 下载模板
    const onDownload = () => {
        window.location.href = apiUrl + "/ordersBatchDelivery/download"
        message.success('下载成功');
    }
    //分页
    return (
        <div>
            <Modal confirmLoading={submitDeliveryLoading} centered bodyStyle={{height: '100', overflowY: 'auto'}} destroyOnClose={true} width={888} title="订单发货" visible={isModalVisible} onOk={handleOk} okText={"确认上传"} onCancel={handleCancel}>
                <Spin spinning={modalLoading}>
                    <Form
                        {...layout}
                        name="basic"
                    >
                        <Form.Item
                            name={"uploadPic"}
                            label={"文件"}
                            valuePropName="fileList"
                            getValueFromEvent={normFile}
                            rules={[{ required: true, message: "文件必传" }]}
                            style={{ textAlign:'left' }}
                        >
                            <Upload
                                name="imageFile"
                                showUploadList={false}
                                action={apiUrl + "/upload/excel"}
                                beforeUpload={beforeUpload}
                                onChange={handleChange}
                                headers={{ "Authorization": "Bearer " + token }}
                            >
                                <Button icon={uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}>选择文件</Button>   
                            </Upload>
                        </Form.Item>

                        <Form.Item hidden
                            label={"文件"}
                            name={"fileName"}
                            rules={[{ required: true, message: "文件必传" }]}
                        >
                            <Input />
                        </Form.Item>
                    </Form>
                </Spin>
            </Modal>
            <Row style={{marginBottom: 24}}>
                <Col span={12}>
                    <Button type="primary" onClick={ () => showModal() }>批量发货</Button>
                </Col>
                <Col span={12} style={{textAlign:'right'}}>
                    <Button onClick={onDownload}><DownloadOutlined />模板下载</Button>
                </Col>
            </Row>
            <Card style={{ backgroundColor: "#f2f3f5", marginBottom: 24 }}>
                <Form onFinish={onFinish}>
                    <Form.Item name="ordersBatchDeliveryTimeRange" label="时间" {...rangeConfig}>
                        <RangePicker onChange={handleSelectTime} showTime format="YYYY-MM-DD HH:mm:ss" />
                    </Form.Item>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item
                                name="ordersBatchDeliveryState"
                                label="状态"
                            >
                                <Select
                                    showSearch
                                    placeholder="请选择状态"
                                    optionFilterProp="children"
                                >
                                    <Option value={0}>全部</Option>
                                    <Option value={1}>进行中</Option>
                                    <Option value={2}>全部成功</Option>
                                    <Option value={3}>部分成功</Option>
                                    <Option value={4}>失败</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Button style={{ marginLeft:70 }} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
            <Table 
                loading = { isLoading }
                rowKey={ columns => columns.ordersBatchDeliveryId } 
                pagination = { paginationProps } 
                columns = {[
                {
                    title: '记录编号',
                    dataIndex: 'ordersBatchDeliverySn',
                    key: 'ordersBatchDeliverySn',
                    width: 200,
                },
                {
                    title: '操作时间',
                    dataIndex: 'ordersBatchDeliveryTime',
                    key: 'ordersBatchDeliveryTime',
                    render(value, ordersBatchDelivery) {
                        return <span>
                            {ordersBatchDelivery.ordersBatchDeliveryTime ? dayjs(ordersBatchDelivery.ordersBatchDeliveryTime).format('YYYY-MM-DD HH:mm:ss') : '无'}<br />
                        </span>
                    },
                },
                {
                    title: '发货单数',
                    dataIndex: 'ordersBatchDeliveryNum',
                    key: 'ordersBatchDeliveryNum',
                },
                {
                    title: '成功发货单数',
                    dataIndex: 'ordersBatchDeliverySuccessNum',
                    key: 'ordersBatchDeliverySuccessNum',
                },
                {
                    title: '状态',
                    dataIndex: 'ordersBatchDeliveryStateValue',
                    key: 'ordersBatchDeliveryStateValue',
                },
                {
                    title: '操作',
                    key: 'action',
                    render: (value, ordersBatchDelivery) => (
                        <Space size="middle">
                            <Link to={"/orders/batch/delivery/item/" + ordersBatchDelivery.ordersBatchDeliveryId}>
                                详情
                            </Link>
                        </Space>
                    ),
                    width: 100,
                }
            ]}
            dataSource={ list || [] }  />
            <ErrorBox error={ error } />
        </div>
    )
}