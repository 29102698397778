import { Layout, Menu, Row, Col, Dropdown } from 'antd';
import { DownOutlined } from '@ant-design/icons';
import { useAuth } from '../context/auth-context';
import { Link } from 'react-router-dom';
const { Header } = Layout;

export const HeaderLayout = () => {
    const { logout, adminAccount } = useAuth();
    const menu = (
        <Menu>
            <Menu.Item key="03">
                <div style={{ marginTop: 10 }}>账号：{adminAccount?.adminAccountName}</div>
                <div style={{ marginTop: 10 }}>用户：{adminAccount?.adminAccountNickname}</div>
            </Menu.Item>
            <Menu.Divider />
            <Menu.Item key="1">
                <Link style={{ color: '#3388ff' }} to="/admin-account/update-pwd">
                    修改密码
                </Link>
            </Menu.Item>
            <Menu.Item key="2">
                <a rel="noopener noreferrer" style={{ color: '#3388ff' }} onClick={logout}>
                    退出登录
                </a>
            </Menu.Item>
        </Menu>
    );
    return (
        <Header className="site-layout-background" style={{ padding: 0, background: '#fff' }}>
            <Row style={{ marginLeft: 16, marginRight: 16 }}>
                <Col span={6}>校企汇</Col>
                <Col span={10}></Col>
                <Col span={8} style={{ textAlign: 'right' }}>
                    <Dropdown overlay={menu}>
                        <a className="ant-dropdown-link" onClick={e => e.preventDefault()}>
                            {adminAccount?.adminAccountNickname} <DownOutlined />
                        </a>
                    </Dropdown>
                </Col>
            </Row>
        </Header>
    )
}