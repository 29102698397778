import { useMutation, useQuery, useQueryClient } from "react-query";
import { SchoolNotice } from "../interfaces/school-notice";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useSchoolNoticeList = (param?: Partial<SchoolNotice>) => {
    const client = useHttp();
    const result = useQuery<any>(['schoolNoticePage', param], () => client('schoolNotice', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditSchoolNotice = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<SchoolNotice>) => client(`schoolNotice`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('schoolNoticePage')
        }
    )
}

export const useAddSchoolNotice = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<SchoolNotice>) => client(`schoolNotice`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('schoolNoticePage')
        }
    )
}

export const useDeleteSchoolNotice = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ schoolNoticeId }: { schoolNoticeId: number}) =>
            client(`schoolNotice/${schoolNoticeId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('schoolNoticePage')
        }
    );
};

export const useSchoolNotice = (schoolNoticeId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['schoolNotice', {schoolNoticeId}],
        () => client(`schoolNotice/${schoolNoticeId}`),
        {
            enabled: Boolean(schoolNoticeId)
        }
    )
}