import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHttp } from "../utils/http";
export const useOrdersGoodsList = (ordersId?: number) => {
    const client = useHttp();
    const result = useQuery<any>(["ordersGoodsList"], () =>
        client("ordersGoods?ordersId=" + ordersId, {})
    );
    const data = result.data?.data;
    return { ...result, data };
};

export const useOrdersGoodsListMutation = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ ordersId }: { ordersId: number }) =>
            client(`ordersGoods?ordersId=${ordersId}`, {
                method: "GET",
            }), {
        onSuccess: () => queryClient.invalidateQueries('ordersGoodsList')
    }
    );
};

export const useSupplierOrdersGoodsList = (ordersId?: number, supplierId?: number) => {
    const client = useHttp();
    const result = useQuery<any>(["ordersGoodsList"], () =>
        client("ordersGoods/supplier?ordersId=" + ordersId + "&supplierId=" + supplierId, {})
    );
    const data = result.data?.data;
    return { ...result, data };
};