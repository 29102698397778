import { useState } from "react";
import { Login } from "./login";
export const Unauthenticated = () => {
    const [error, setError] = useState<Error | null>(null);
    console.log(error);
    return <div>
        <Login onError={setError} />
    </div>
}

