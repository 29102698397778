import { Button, Card, Form, DatePicker, Table, Row, Col, Modal, message } from "antd"
import { ErrorBox } from "../../components/lib"
import { useBillList } from "../../services/bill-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useBillSearchParams } from "./util"
import dayjs from 'dayjs';
import { useState } from "react";
const { RangePicker } = DatePicker;

export const BillListPage = () => {
    useDocumentTitle('对账单列表', true)
    const apiUrl = process.env.REACT_APP_API_URL;
    const [param, setParam] = useBillSearchParams()
    const { isLoading, error, totalElements, data: list } = useBillList(useDebounce(param, 200))
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) =>
            setParam({
                ...param,
                pageNum: (current - 1),
            })
    }
    //时间
    const rangeConfig = {
        rules: [{ type: 'array' as const, message: '请选择时间!' }],
    };
    const [startBillDate, setStartBillDate] = useState('');
    const [endBillDate, setEndBillDate] = useState('');
    const handleSelectTime = (value: any, dateString: any) => {
        setStartBillDate(dateString[0]);
        setEndBillDate(dateString[1]);
    }
    //完成
    const [op, setOp] = useState(1)
    function changeOp(num: number) {
        setOp(num)
    }
    const onFinish = (values: any) => {
        if (op === 1) {
            setParam({
                ...param,
                startBillDate: startBillDate,
                endBillDate: endBillDate,
                pageNum: null
            })
        } else if (op === 2) {
            let startTime = startBillDate;
            let endTime = endBillDate;
            if (startTime === undefined) {
                startTime = "";
            }
            if (endTime === undefined) {
                endTime = "";
            }
            Modal.confirm({
                title: '确定导出当前列表数据吗?',
                content: '点击确定导出',
                okText: '确定',
                onOk(): any {
                    return new Promise((resolve, reject) => {
                        window.location.href = apiUrl +
                            `/bill/export?startBillDate=${startTime}&endBillDate=${endTime}`
                        message.success('导出成功')
                        Modal.destroyAll()
                    }).catch(() => console.log('Oops errors!'))
                },
                cancelText: '取消',
            })
        }
    }
    //分页
    return (
        <div>
            <Card style={{ backgroundColor: "#f2f3f5", marginBottom: 24 }}>
                <Form onFinish={onFinish}>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item name="billDateRange" label="入账时间" {...rangeConfig}>
                                <RangePicker onChange={handleSelectTime} showTime format="YYYY-MM-DD HH:mm:ss" style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>

                    <Form.Item>
                        <Button onClick={() => changeOp(1)} style={{ marginLeft: 70 }} type='primary' htmlType='submit'>
                            提交
                        </Button>
                        <Button onClick={() => changeOp(2)} style={{ marginLeft: 20 }} type='primary' htmlType='submit'>
                            导出
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
            <Table
                loading={isLoading}
                rowKey={columns => columns.billId}
                pagination={paginationProps}
                columns={[
                    {
                        title: '日期',
                        dataIndex: 'billDate',
                        key: 'billDate',
                        align: 'center',
                        render(value, bill) {
                            return <span>
                                {bill.billDate ? dayjs(bill.billDate).format('YYYY-MM-DD') : '无'}
                            </span>
                        },
                    },
                    {
                        title: '收入金额',
                        dataIndex: 'billIncomeMoney',
                        key: 'billIncomeMoney',
                        align: 'center',
                        render(value, bill) {
                            return <span>
                                <text style={{ color: '#1aad19' }}>+ ￥{bill.billIncomeMoney}</text><br />
                                {/* {bill.billIncomeNum}笔 */}
                            </span>
                        },
                    },
                    {
                        title: '收入单数',
                        dataIndex: 'billIncomeNum',
                        key: 'billIncomeNum',
                        align: 'center',
                        render(value, bill) {
                            return <span>
                                {bill.billIncomeNum}单
                            </span>
                        },
                    },
                    {
                        title: '支出金额',
                        dataIndex: 'billPayMoney',
                        key: 'billPayMoney',
                        align: 'center',
                        render(value, bill) {
                            return <span>
                                <text style={{ color: '#fd5454' }}>- ￥{bill.billPayMoney}</text><br />
                            </span>
                        },
                    },
                    {
                        title: '支出单数',
                        dataIndex: 'billPayNum',
                        key: 'billPayNum',
                        align: 'center',
                        render(value, bill) {
                            return <span>
                                {bill.billPayNum}单
                            </span>
                        },
                    },
                ]}
                dataSource={list || []} />
            <ErrorBox error={error} />
        </div>
    )
}