import { useMutation, useQuery, useQueryClient } from "react-query";
import { SchoolArticle } from "../interfaces/school-article";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useSchoolArticleList = (param?: Partial<SchoolArticle>) => {
  const client = useHttp();
  const result = useQuery<any>(["schoolArticlePage", param], () =>
    client("schoolArticle", { data: cleanObject(param) })
  );
  const totalElements = result.data?.data?.totalElements;
  const data = result.data?.data?.content;
  return { ...result, totalElements, data };
};

export const useEditSchoolArticle = () => {
  const client = useHttp();
  const queryClient = useQueryClient();
  return useMutation(
    (params: Partial<SchoolArticle>) =>
      client(`schoolArticle`, {
        method: "PUT",
        data: params,
      }),
    {
      onSuccess: () => queryClient.invalidateQueries("schoolArticlePage"),
    }
  );
};

export const useAddSchoolArticle = () => {
  const client = useHttp();
  const queryClient = useQueryClient();
  return useMutation(
    (params: Partial<SchoolArticle>) =>
      client(`schoolArticle`, {
        data: params,
        method: "POST",
      }),
    {
      onSuccess: () => queryClient.invalidateQueries("schoolArticlePage"),
    }
  );
};

export const useDeleteSchoolArticle = () => {
  const client = useHttp();
  const queryClient = useQueryClient();
  return useMutation(
    ({ schoolArticleId }: { schoolArticleId: number }) =>
      client(`schoolArticle/${schoolArticleId}`, {
        method: "DELETE",
      }),
    {
      onSuccess: () => queryClient.invalidateQueries("schoolArticlePage"),
    }
  );
};

export const useSchoolArticle = (schoolArticleId?: number) => {
  const client = useHttp();
  return useQuery<any>(
    ["schoolArticle", { schoolArticleId }],
    () => client(`schoolArticle/${schoolArticleId}`),
    {
      enabled: Boolean(schoolArticleId),
    }
  );
};

export const useSchoolArticleDetail = (schoolArticleId?: number) => {
  const client = useHttp();
  return useQuery<any>(
    ["schoolArticleDetail", { schoolArticleId }],
    () => client(`schoolArticleDetail/${schoolArticleId}`),
    {
      enabled: Boolean(schoolArticleId),
    }
  );
};
