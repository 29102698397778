import { Button, Col, message, Modal, Row, Space, Table, Image, Card, Form, Input } from "antd"
import Search from "antd/lib/input/Search"
import { Link } from "react-router-dom"
import dayjs from "dayjs"
import { ErrorBox } from "../../components/lib"
import { useAgencyTagList, useDeleteAgencyTag } from "../../services/agency-tag-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useAgencyTagSearchParams } from "./util"

export const AgencyTagListPage = () => {
    useDocumentTitle('机构标签列表', true)
    const imgUrl = process.env.REACT_APP_IMG_URL;
    const [param, setParam] = useAgencyTagSearchParams()
    const {isLoading, error, totalElements, data: list} = useAgencyTagList(useDebounce(param, 200))

	//分页
	const paginationProps = {
	  showSizeChanger: true,
	  onShowSizeChange: (current: number, pageSize: number) => {
		setParam({
		  ...param,
		  pageNum: current - 1,
		  pageSize,
		});
	  },
	  defaultCurrent: 1,
	  pageSize: param.pageSize,
	  total: totalElements,
	  onChange: (current: number, pageSize: number) =>
		setParam({
		  ...param,
		  pageNum: current - 1,
		  pageSize,
		}),
	};
     //搜索
  const onFinish = (values: any) => {
    setParam({
      ...param,
      agencyTagName: values.agencyTagName,
      pageNum: null,
    });
  };
    //删除事件
    const { mutateAsync } = useDeleteAgencyTag();
    const confirmDeleteAgencyTag = (agencyTagId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ agencyTagId }).then((data) => {
                        if (data.status === 0) {
                            message.info('删除成功');
                            Modal.destroyAll();
                          } else {
                            message.error(data.msg)
                          }
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    //分页
    return (
        <div>
            <Row style={{marginBottom: 24}}>
                <Col span={12}>
                    <Link to={"/jigou/agency-manage/agency-tag/add"}>
                        <Button type="primary">新建</Button>
                    </Link>
                </Col>
				<Col span={12} style={{ textAlign: "right" }}>
				  <Search
					placeholder="可搜索机构标签名称"
					style={{ width: 200 }}
					value={param.agencyTagName}
					onChange={(evt) =>
					  setParam({
						...param,
						agencyTagName: evt.target.value,
						pageNum: null,
					  })
					}
					onSearch={(value) =>
					  setParam({
						...param,
						agencyTagName: value,
						pageNum: null,
					  })
					}
				  />
				</Col>
            </Row>
            <Table 
                loading = { isLoading }
                rowKey={ columns => columns.agencyTagId } 
                pagination = { paginationProps } 
                columns = {[
                {
                    title: 'ID',
                    dataIndex: 'agencyTagId',
                    key: 'agencyTagId',
                    align: 'center',
                    width: 100,
                },

                {
                    title: '标签名',
                    dataIndex: 'agencyTagName',
                    key: 'agencyTagName',
                    align: 'center',
                },

                {
                    title: '是否显示',
                    dataIndex: 'agencyTagShow',
                    key: 'agencyTagShow',
                    align: 'center',
                    render: (value) => (
                        <span>{value ? '显示' : '不显示'}</span>
                    ),
                },

                {
                    title: '操作',
                    key: 'action',
                    align: 'center',
                    render: (value, agencyTag) => (
                        <Space size="middle">
                            <Link to={"/jigou/agency-manage/agency-tag/update/" + agencyTag.agencyTagId}>
                                编辑
                            </Link>
                            <a onClick={() => confirmDeleteAgencyTag(agencyTag.agencyTagId)}>
                                删除
                            </a>
                        </Space>
                    ),
                    width: 150,
                }
            ]}
            dataSource={ list || [] }  />
            <ErrorBox error={ error } />
        </div>
    )
}