import { useMutation, useQuery, useQueryClient } from "react-query";
import { AdminAccount } from "../interfaces/admin-account";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

//列表
export const useAdminAccountList = (param?: Partial<AdminAccount>) => {
    const client = useHttp();
    const result = useQuery<any>(['adminAccountPage', param], () => client('adminAccount', { data: cleanObject(param) }))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return { ...result, totalElements, data };
}

//新增
export const useAddAdminAccount = () => {
    const client = useHttp();
    const queryClient = useQueryClient()
    return useMutation((params: Partial<AdminAccount>) =>
        client(`adminAccount`, {
            data: params,
            method: 'POST',
        }), {
            onSuccess: () => queryClient.invalidateQueries('adminAccountPage')
        }
    );
};

//编辑
export const useEditAdminAccount = () => {
    const client = useHttp();
    const queryClient = useQueryClient()
    return useMutation((params: Partial<AdminAccount>) =>
        client(`adminAccount`, {
            data: params,
            method: 'PUT',
        }), {
            onSuccess: () => queryClient.invalidateQueries('adminAccountPage')
        }
    );
};

//删除
export const useDeleteAdminAccount = () => {
    const client = useHttp();
    const queryClient = useQueryClient()
    return useMutation(({ adminAccountId }: { adminAccountId: number }) =>
        client(`adminAccount/${adminAccountId}`, {
            method: 'DELETE',
        }), {
        onSuccess: () => queryClient.invalidateQueries('adminAccountPage')
    }
    );
};

//详情
export const useAdminAccount = (adminAccountId?: number) => {
    const client = useHttp();
    return useQuery<any>(['adminAccountDetail', { adminAccountId }], () => client(`adminAccount/${adminAccountId}`), {
        enabled: Boolean(adminAccountId),
    });
};

//修改密码
export const useEditAdminAccountPwd = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<AdminAccount>) => client(`adminAccount/updatePwd`, {
            method: 'PUT',
            data: params
        }), {
        onSuccess: () => queryClient.invalidateQueries('adminAccountPage')
    }
    )
}

//重置密码
export const useResetAdminAccount = () => {
    const client = useHttp();
    const queryClient = useQueryClient()
    return useMutation((params: Partial<AdminAccount>) =>
        client(`adminAccount/reset`, {
            data: params,
            method: 'PUT',
        }), {
            onSuccess: () => queryClient.invalidateQueries('adminAccountPage')
        }
    );
};

//禁用
export const useForbiddenAdminAccount = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ adminAccountId, adminAccountForbidden }: { adminAccountId: number, adminAccountForbidden: boolean }) =>
            client(`adminAccount/forbidden?adminAccountId=${adminAccountId}&adminAccountForbidden=${adminAccountForbidden}`, {
                method: "POST",
            }), {
        onSuccess: () => queryClient.invalidateQueries('adminAccountPage')
    }
    );
};