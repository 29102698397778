import { Button, Col, Form, Input, Row, Tree, message, Divider } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useEffect, useState } from 'react';
import { useDebounce, useDocumentTitle } from '../../utils'
import { useAddAdminRole } from '../../services/admin-role-service';
import { useAdminAuthorityListAll } from '../../services/admin-authority-service';
import { Link, useNavigate } from 'react-router-dom';
import { useAdminAuthoritySearchParams } from './util';

export const AdminRoleAddPage = () => {
  useDocumentTitle('新建角色', true)
  const navigate = useNavigate();

  //布局
  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 7 },
  };
  const tailLayout = {
    wrapperCol: { offset: 3 },
  };

  //表单
  const [submitLoading, setSubmitLoading] = useState(false);
  const [form] = useForm();

  //获取权限列表
  const [treeData, setTreeData] = useState([]);
  const [param] = useAdminAuthoritySearchParams(0);
  const { data: list } = useAdminAuthorityListAll(useDebounce(param, 200))
  useEffect(() => {
    setTreeData(list?.data)
  }, [list])

  // 权限选择
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [adminAuthorityIdList, setAdminAuthorityIdList] = useState([]);

  // 展开节点
  const onExpand = (expandedKeysValue: any) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  // 点击节点
  const onCheck = (checkedKeysValue: any, e: any) => {
    const adminRoleIndex = e.halfCheckedKeys
    const adminAuthorityIdLists = e.checkedNodes.map((item: any) => (item.adminAuthorityId));
    if (adminRoleIndex) {
      adminRoleIndex.forEach((item: any) => {
        adminAuthorityIdLists.push(item);
      });
    }
    setAdminAuthorityIdList(adminAuthorityIdLists);
    setCheckedKeys(checkedKeysValue);
  };

  //提交
  const { mutateAsync } = useAddAdminRole();
  const onFinish = (values: any) => {
    setSubmitLoading(true);
    mutateAsync({ ...values, adminAuthorityIdList }).then((data) => {
      if (data.status === 0) {
        message.success(data.msg);
        navigate('/setting/account-manage/admin-role/list')
      } else {
        setSubmitLoading(false);
        message.error(data.msg);
      }
    }).catch(err => {
      message.error('提交失败，请重新提交');
      setSubmitLoading(false);
    });
  };
  return (
    <div>
      <Row>
        <Col span={12}>
          <Link to={"/setting/account-manage/admin-role/list"}>
            <Button type="primary">列表</Button>
          </Link>
        </Col>
      </Row>
      
      <Divider style={{ marginBottom: 24 }} />

      <Form
        form={form}
        {...layout}
        name='basic'
        onFinish={onFinish}
        initialValues={{
          adminRoleSort: 0
        }}>
        <Form.Item
          label={'名称'}
          name={'adminRoleName'}
          rules={[{ required: true, message: '名称必填' }]}
        >
          <Input style={{ width: 380 }} placeholder={'请输入名称'} />
        </Form.Item>
        
        <Form.Item
          label={'排序'}
          name={'adminRoleSort'}
          rules={[
            { required: true, message: '排序必填' },
            () => ({
              validator(_, value) {
                const pattern = /^[0-9]*$/;
                if (!value || pattern.test(value)) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('必须是纯数字'));
              },
            }),
          ]}
        >
          <Input style={{ width: 380 }} placeholder={'请输入排序'} />
        </Form.Item>

        <Form.Item
          label={'权限'}
          rules={[
            () => ({
              validator() {
                if (adminAuthorityIdList.length) {
                  return Promise.resolve();
                }
                return Promise.reject(new Error('权限必选'));
              },
            }),
          ]}
        >
          <Tree
            checkable
            onExpand={onExpand}
            expandedKeys={expandedKeys}
            autoExpandParent={autoExpandParent}
            onCheck={onCheck}
            checkedKeys={checkedKeys}
            treeData={treeData}
            fieldNames={{ title: 'adminAuthorityName', key: 'adminAuthorityId', children: 'adminAuthorityVOList' }}
          />
        </Form.Item>
        
        <Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
          <Button loading={submitLoading} type='primary' htmlType='submit'>
            提交
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
