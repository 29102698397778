import { Button, Col, message, Modal, Row, Space, Table, Image, Breadcrumb } from "antd"
import Search from "antd/lib/input/Search"
import dayjs from "dayjs"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useSchoolNoticeList, useDeleteSchoolNotice } from "../../services/school-notice-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useSchoolNoticeSearchParams } from "./util"

export const SchoolNoticeListPage = () => {
    useDocumentTitle('学校公告列表', true)
    const imgUrl = process.env.REACT_APP_IMG_URL;
    const [param, setParam] = useSchoolNoticeSearchParams()
    const { isLoading, error, totalElements, data: list } = useSchoolNoticeList(useDebounce(param, 200))

    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) =>
            setParam({
                ...param,
                pageNum: (current - 1),
            })
    }

    //删除事件
    const { mutateAsync } = useDeleteSchoolNotice();
    const confirmDeleteSchoolNotice = (schoolNoticeId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ schoolNoticeId }).then((data) => {
                        if (data.status === 0) {
                            message.info('删除成功');
                            Modal.destroyAll();
                        } else {
                            message.error(data.msg)
                        }
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    //分页
    return (
        <div>
            <Breadcrumb style={{ marginBottom: 24 }}>
                <Breadcrumb.Item>
                    <Link to={"/dress/trends-manage/trends-tag/list"}>
                        招生类型列表
                    </Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>
                    学校头条列表
                </Breadcrumb.Item>
            </Breadcrumb>
            <Row style={{ marginBottom: 24 }}>
                <Col span={12}>
                    <Link to={"/dress/trends-manage/trends-tag/school-notice/add?trendsTagId=" + param.trendsTagId}>
                        <Button type="primary">新建</Button>
                    </Link>
                </Col>
                <Col span={12} style={{ textAlign: 'right' }}>
                    <Search
                        placeholder="可搜索学校公告标题"
                        style={{ width: 200 }}
                        value={param.schoolNoticeTitle}
                        onChange={(evt) =>
                            setParam({
                                ...param,
                                schoolNoticeTitle: evt.target.value,
                                pageNum: null
                            })}
                        onSearch={(value) =>
                            setParam({
                                ...param,
                                schoolNoticeTitle: value,
                                pageNum: null
                            })}
                    />
                </Col>
            </Row>
            <Table
                loading={isLoading}
                rowKey={columns => columns.schoolNoticeId}
                pagination={paginationProps}
                columns={[
                    {
                        title: 'ID',
                        dataIndex: 'schoolNoticeId',
                        key: 'schoolNoticeId',
                        align: 'center',
                        width: 100,
                    },
                    {
                        title: '标题',
                        dataIndex: 'schoolNoticeTitle',
                        key: 'schoolNoticeTitle',
                        align: 'center',
                    },
                    {
                        title: '图片',
                        dataIndex: 'schoolNoticePic',
                        key: 'schoolNoticePic',
                        align: 'center',
                        render: (value) => (
                            <Image width={80} src={imgUrl + value} />
                        ),
                    },
                    {
                        title: '排序',
                        dataIndex: 'schoolNoticeSort',
                        key: 'schoolNoticeSort',
                        align: 'center',
                    },
                    {
                        title: '状态',
                        dataIndex: 'schoolNoticeShow',
                        key: 'schoolNoticeShow',
                        align: 'center',
                        render: (value) => (
                            <span>{value ? '显示' : '不显示'}</span>
                        ),
                    },
                    {
                        title: '创建时间',
                        dataIndex: 'schoolNoticeAddTime',
                        key: 'schoolNoticeAddTime',
                        align: 'center',
                        render: (value) => (
                            value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : '-'
                        )
                    },
                    {
                        title: '操作',
                        key: 'action',
                        align: 'center',
                        render: (value, schoolNotice) => (
                            <Space size="middle">
                                <Link to={"/dress/trends-manage/trends-tag/school-notice/update/" + schoolNotice.schoolNoticeId}>
                                    编辑
                                </Link>
                                <a onClick={() => confirmDeleteSchoolNotice(schoolNotice.schoolNoticeId)}>
                                    删除
                                </a>
                            </Space>
                        ),
                        width: 150,
                    }
                ]}
                dataSource={list || []} />
            <ErrorBox error={error} />
        </div>
    )
}