import { useMutation, useQuery, useQueryClient } from "react-query";
import { CompanyPosition } from "../interfaces/company-position";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useCompanyPositionList = (param?: Partial<CompanyPosition>) => {
    const client = useHttp();
    const result = useQuery<any>(['companyPositionPage', param], () => client('companyPosition', { data: cleanObject(param) }))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return { ...result, totalElements, data };
}

export const useEditCompanyPosition = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<CompanyPosition>) => client(`companyPosition`, {
            method: 'PUT',
            data: params
        }), {
        onSuccess: () => queryClient.invalidateQueries('companyPositionPage')
    }
    )
}

export const useAddCompanyPosition = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<CompanyPosition>) => client(`companyPosition`, {
            data: params,
            method: 'POST'
        }), {
        onSuccess: () => queryClient.invalidateQueries('companyPositionPage')
    }
    )
}

export const useDeleteCompanyPosition = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ companyPositionId }: { companyPositionId: number }) =>
            client(`companyPosition/${companyPositionId}`, {
                method: "DELETE",
            }), {
        onSuccess: () => queryClient.invalidateQueries('companyPositionPage')
    }
    );
};

export const useCompanyPosition = (companyPositionId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['companyPosition', { companyPositionId }],
        () => client(`companyPosition/${companyPositionId}`),
        {
            enabled: Boolean(companyPositionId)
        }
    )
}
