import { useMutation, useQuery, useQueryClient } from "react-query";
import { MajorClass } from "../interfaces/major-class";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useMajorClassList = (param?: Partial<MajorClass>) => {
    const client = useHttp();
    const result = useQuery<any>(['majorClassPage', param], () => client('majorClass', { data: cleanObject(param) }))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return { ...result, totalElements, data };
}

export const useEditMajorClass = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<MajorClass>) => client(`majorClass`, {
            method: 'PUT',
            data: params
        }), {
        onSuccess: () => queryClient.invalidateQueries('majorClassPage')
    }
    )
}

export const useAddMajorClass = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<MajorClass>) => client(`majorClass`, {
            data: params,
            method: 'POST'
        }), {
        onSuccess: () => queryClient.invalidateQueries('majorClassPage')
    }
    )
}

export const useDeleteMajorClass = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ majorClassId }: { majorClassId: number }) =>
            client(`majorClass/${majorClassId}`, {
                method: "DELETE",
            }), {
        onSuccess: () => queryClient.invalidateQueries('majorClassPage')
    }
    );
};

export const useMutationMajorClass = () => {
    const client = useHttp()
    return useMutation(
        ({ majorClassId }: { majorClassId: number }) =>
            client(`majorClass/${majorClassId}`, {
                method: "GET",
            })
    );
};

export const useMajorClass = (majorClassId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['majorClass', { majorClassId }],
        () => client(`majorClass/${majorClassId}`),
        {
            enabled: Boolean(majorClassId)
        }
    )
}

export const useMajorClassListAll = (cascadeType: number) => {
    const client = useHttp();
    return useQuery<any>(["majorClassList", { cascadeType }], () =>
        client(`majorClass/list?cascadeType=${cascadeType}`)
    );
};