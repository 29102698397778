import { useMutation, useQuery, useQueryClient } from "react-query";
import { BusinessCircleTag } from "../interfaces/business-circle-tag";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useBusinessCircleTagList = (param?: Partial<BusinessCircleTag>) => {
    const client = useHttp();
    const result = useQuery<any>(['businessCircleTagList', param], () => client('businessCircleTag', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditBusinessCircleTag = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<BusinessCircleTag>) => client(`businessCircleTag`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('businessCircleTagList')
        }
    )
}

export const useAddBusinessCircleTag = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<BusinessCircleTag>) => client(`businessCircleTag`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('businessCircleTagList')
        }
    )
}

export const useDeleteBusinessCircleTag = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ businessCircleTagId }: { businessCircleTagId: number}) =>
            client(`businessCircleTag/${businessCircleTagId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('businessCircleTagList')
        }
    );
};

export const useBusinessCircleTag = (businessCircleTagId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['businessCircleTag', {businessCircleTagId}],
        () => client(`businessCircleTag/${businessCircleTagId}`),
        {
            enabled: Boolean(businessCircleTagId)
        }
    )
}

/**查询全部 */
export const useBusinessCircleTagListAll = (param?: Partial<BusinessCircleTag>) => {
    const client = useHttp();
    const result = useQuery<any>(["businessCircleTagListAll", param], () =>
        client("businessCircleTag/listAll", { data: param })
    );
    const data = result.data?.data;
    return {...result, data };
};