import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Radio,
  Row,
  Select,
  Spin,
} from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useDocumentTitle } from "../../utils";
import { getToken } from "../../utils/auth-provider";
import {
  useEditWechatMenu,
  useWechatMenu,
  useWechatMenuListParent,
} from "../../services/wechat-menu-service";
import UploadPic from "../../components/upload-pic";
const { Option } = Select;

export const WechatMenuUpdatePage = () => {
  //标题
  useDocumentTitle("编辑分类", true);
  //API地址
  const apiUrl = process.env.REACT_APP_API_URL;
  const imgUrl = process.env.REACT_APP_IMG_URL;
  const token = getToken();
  const { pathname } = useLocation();
  const wechatMenuId = pathname.match(
    /setting\/wechat-manage\/wechat-menu\/update\/(\d+)/
  )?.[1];
  //准备回显数据
  const { data: editingWechatMenu, isLoading } = useWechatMenu(
    Number(wechatMenuId)
  );
  //布局
  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 7 },
  };
  const tailLayout = {
    wrapperCol: { offset: 3 },
  };
  //准备上级
  const { data: wechatMenuList } = useWechatMenuListParent();
  const wechatMenuListOptions = wechatMenuList?.data?.map(
    (item: any, value: any) => (
      <Option value={item.wechatMenuId} key={item.wechatMenuId}>
        {item.wechatMenuName}
      </Option>
    )
  );
  //响应类型
  const [wechatMenuType, setWechatMenuType] = useState("none");
  const handleChangeType = (e: any) => {
    setWechatMenuType(e.target.value);
  };
  //回复类型
  const [wechatMenuMediaType, setWechatMenuMediaType] = useState(1);
  const handleChangeMediaType = (e: any) => {
    setWechatMenuMediaType(e.target.value);
  };
  //表单
  const [form] = useForm();
  const [wechatMenuMediaPic, setWechatMenuMediaPic] = useState("");
  useEffect(() => {
    form.setFieldsValue(editingWechatMenu?.data);
    if (editingWechatMenu?.data?.wechatMenuMediaPic) {
      setWechatMenuMediaPic(editingWechatMenu?.data?.wechatMenuMediaPic);
    }
    setWechatMenuType(editingWechatMenu?.data?.wechatMenuType);
    setWechatMenuMediaType(editingWechatMenu?.data?.wechatMenuMediaType);
  }, [editingWechatMenu, form, imgUrl]);

  //编辑
  const useMutateEditWechatMenu = useEditWechatMenu;
  const { mutateAsync, error } = useMutateEditWechatMenu();
  //提交表单
  const [submitLoading, setSubmitLoading] = useState(false);
  const onFinish = (values: any) => {
    setSubmitLoading(true);
    mutateAsync({ wechatMenuId, ...values, wechatMenuMediaPic }).then(() => {
      message.info("编辑成功");
      window.location.href = "/#/setting/wechat-manage/wechat-menu/list";
    });
  };
  return (
    <div>
      <Spin spinning={isLoading}>
        <Row>
          <Col span={12}>
            <Link to={"/setting/wechat-manage/wechat-menu/list"}>
              <Button type="primary">菜单列表</Button>
            </Link>
          </Col>
        </Row>
        <Divider style={{ marginBottom: 24 }} />
        <Form form={form} {...layout} onFinish={onFinish}>
          <Form.Item
            label={"上级"}
            name={"wechatMenuParentId"}
            rules={[{ required: true, message: "上级必选" }]}
          >
            <Select
              showSearch
              placeholder="请选择上级"
              optionFilterProp="children"
              style={{ textAlign: "left", width: 380 }}
            >
              <option value={0}>无</option>
              {wechatMenuListOptions}
            </Select>
          </Form.Item>

          <Form.Item
            label={"名称"}
            name={"wechatMenuName"}
            rules={[{ required: true, message: "名称必填" }]}
          >
            <Input style={{ width: 380 }} placeholder={"请输入名称"} />
          </Form.Item>

          <Form.Item
            label={"响应类型"}
            name={"wechatMenuType"}
            rules={[{ required: true, message: "响应类型必选" }]}
            style={{ textAlign: "left" }}
          >
            <Radio.Group onChange={handleChangeType}>
              <Radio defaultChecked value={"none"}>
                无
              </Radio>
              <Radio value={"click"}>点击</Radio>
              <Radio value={"view"}>网页</Radio>
              <Radio value={"miniprogram"}>小程序</Radio>
            </Radio.Group>
          </Form.Item>

          {wechatMenuType === "click" ? (
            <div>
              <Form.Item
                label={"回复类型"}
                name={"wechatMenuMediaType"}
                rules={[{ required: true, message: "回复类型必选" }]}
                style={{ textAlign: "left" }}
              >
                <Radio.Group onChange={handleChangeMediaType}>
                  <Radio defaultChecked value={1}>
                    文字
                  </Radio>
                  <Radio value={2}>图片</Radio>
                </Radio.Group>
              </Form.Item>
              {wechatMenuMediaType === 1 ? (
                <Form.Item
                  name={"wechatMenuMediaContent"}
                  label={"文字内容"}
                  style={{ textAlign: "left" }}
                  rules={[{ required: true, message: "文字内容必填" }]}
                >
                  <Input.TextArea
                    style={{ width: 380 }}
                    rows={5}
                    placeholder={"请输入文字内容"}
                  ></Input.TextArea>
                </Form.Item>
              ) : (
                ""
              )}
              {wechatMenuMediaType === 2 ? (
                <UploadPic
                  name={"uploadPic"}
                  label={"图片"}
                  pic={wechatMenuMediaPic}
                  setPic={setWechatMenuMediaPic}
                  action={"update"}
                  style={{ textAlign: "left" }}
                ></UploadPic>
              ) : (
                ""
              )}
            </div>
          ) : (
            ""
          )}

          {wechatMenuType === "view" ? (
            <Form.Item
              label={"页面地址"}
              name={"wechatMenuUrl"}
              rules={[{ required: true, message: "页面地址必填" }]}
            >
              <Input style={{ width: 380 }} placeholder={"请输入页面地址"} />
            </Form.Item>
          ) : (
            ""
          )}

          {wechatMenuType === "miniprogram" ? (
            <div>
              <Form.Item
                label={"AppID"}
                name={"wechatMenuAppid"}
                rules={[{ required: true, message: "AppID必填" }]}
              >
                <Input style={{ width: 380 }} placeholder={"请输入AppID"} />
              </Form.Item>

              <Form.Item
                label={"页面路径"}
                name={"wechatMenuPagePath"}
                rules={[{ required: true, message: "页面路径必填" }]}
              >
                <Input style={{ width: 380 }} placeholder={"请输入页面路径"} />
              </Form.Item>

              <Form.Item
                label={"备用网页"}
                name={"wechatMenuUrl"}
                rules={[{ required: true, message: "备用网页必填" }]}
              >
                <Input style={{ width: 380 }} placeholder={"请输入备用网页"} />
              </Form.Item>
            </div>
          ) : (
            ""
          )}

          <Form.Item
            label={"排序"}
            name={"wechatMenuSort"}
            rules={[{ required: true, message: "排序必填" }]}
          >
            <Input style={{ width: 380 }} placeholder={"请输入排序"} />
          </Form.Item>

          <Form.Item {...tailLayout} style={{ textAlign: "left" }}>
            <Button loading={submitLoading} type="primary" htmlType="submit">
              提交
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
