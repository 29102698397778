import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHttp } from "../utils/http";

export const useGoodsSkuCreateList = (goodsSpecValueIds?: number[]) => {
    const client = useHttp();
    return useMutation((goodsSpecValueIds: number[]) =>
            client(`goodsSku/createSku?goodsSpecValueIds=` + goodsSpecValueIds , {
            method: "GET",
        })
    );
};

export const useGoodsSkuListByGoodsId = (goodsId?: number) => {
    const client = useHttp();
    const result = useQuery<any>(["goodsSkuList"], () =>
        client(`goodsSku/findByGoodsId?goodsId=` + goodsId, {})
    );
    const data = result.data?.data;
    return {...result, data };
};