import { useQuery } from "react-query";
import { Recruit } from "../interfaces/recruit";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useRecruitList = (param?: Partial<Recruit>) => {
    const client = useHttp();
    const result = useQuery<any>(['recruitPage', param], () => client('recruit', { data: cleanObject(param) }))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return { ...result, totalElements, data };
}