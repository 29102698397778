import { Form } from 'antd';
import BraftEditor from 'braft-editor'
import 'braft-editor/dist/index.css';
import { getToken } from '../utils/auth-provider';

const apiUrl = process.env.REACT_APP_API_URL;
const token = getToken();

const RichText = (props: any) => {

    let { label, name, setContent } = props;

    //富文本
    const handleEditorChange = (editorState: any) => {
        setContent(editorState.toHTML())
    }
    //富文本上传
    const myUploadFn = (param: any) => {
        const serverURL = apiUrl + '/upload/braftEditorImg'
        const xhr = new XMLHttpRequest()
        const fd = new FormData()
        const successFn = (response: any) => {
            // 假设服务端直接返回文件上传后的地址
            // 上传成功后调用param.success并传入上传后的文件地址
            param.success({
                url: xhr.responseText,
                meta: {
                    loop: true, // 指定音视频是否循环播放
                    autoPlay: true, // 指定音视频是否自动播放
                    controls: true, // 指定音视频是否显示控制栏
                }
            })
        }
        const progressFn = (event: any) => {
            // 上传进度发生变化时调用param.progress
            param.progress(event.loaded / event.total * 100)
        }
        const errorFn = (response: any) => {
            // 上传发生错误时调用param.error
            param.error({
                msg: 'unable to upload.'
            })
        }
        xhr.upload.addEventListener("progress", progressFn, false)
        xhr.addEventListener("load", successFn, false)
        xhr.addEventListener("error", errorFn, false)
        xhr.addEventListener("abort", errorFn, false)
        fd.append('file', param.file)
        xhr.open('POST', serverURL, true)
        xhr.setRequestHeader("name", "imageFile");
        xhr.setRequestHeader("Authorization", "Bearer " + token);
        xhr.send(fd)
    }

    return (
        <Form.Item
            label={label}
            name={name}
            style={{ marginTop: 24 }}
        >
            <BraftEditor
                style={{ border: "1px solid #f0f0f0", width: "100%" }}
                onChange={handleEditorChange}
                media={{ uploadFn: myUploadFn }}
            />
        </Form.Item>
    )

}

export default RichText;