import { useMutation,useQuery,useQueryClient } from "react-query";
import { useHttp } from "../utils/http";

export const useOrdersAddress = (ordersId?: number) => {
    const client = useHttp();
    return useQuery<any>(["ordersAddress", { ordersId }], () =>
        client(`ordersAddress?ordersId=${ordersId}`),
        {
            enabled: Boolean(ordersId)
        }
    );
};

export const useOrdersAddressMutation = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ ordersId }: { ordersId: number}) =>
            client(`ordersAddress?ordersId=${ordersId}`, {
            method: "GET",
        }), {
            onSuccess: () => queryClient.invalidateQueries('ordersAddress')
        }
    );
};