import { Button, Col, message, Modal, Row, Space, Table } from "antd"
import Search from "antd/lib/input/Search"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useStoreAttributeList, useDeleteStoreAttribute } from "../../services/store-attribute-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useStoreAttributeSearchParams } from "./util"

export const StoreAttributeListPage = () => {
    useDocumentTitle('商家性质列表', true)
    const [param, setParam] = useStoreAttributeSearchParams()
    const {isLoading, error, totalElements, data: list} = useStoreAttributeList(useDebounce(param, 200))

    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) => 
            setParam({
            ...param,
            pageNum: (current -1),
        })
    }

    //删除事件
    const { mutateAsync:deleteMutateAsync } = useDeleteStoreAttribute();
    const confirmDeleteStoreAttribute = (storeAttributeId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    deleteMutateAsync({ storeAttributeId }).then((data) => {
                        if (data.status === 0) {
                            message.info('删除成功');
                            Modal.destroyAll();
                          } else {
                            message.error(data.msg)
                          }
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    //分页
    return (
        <div>
            <Row style={{marginBottom: 24}}>
                <Col span={12}>
                    <Link to={"/shop/store-manage/store-attribute/add"}>
                        <Button type="primary">新建</Button>
                    </Link>
                </Col>
                <Col span={12} style={{textAlign:'right'}}>
                    <Search 
                        placeholder="可搜索商家性质名称" 
                        style={{ width: 200 }}
                        value={param.storeAttributeName}
                        onChange={(evt) =>
                            setParam({
                            ...param,
                            storeAttributeName: evt.target.value,
                            pageNum: null
                        })} 
                        onSearch={(value) =>
                            setParam({
                            ...param,
                            storeAttributeName: value,
                            pageNum: null
                        })} 
                    />
                </Col>
            </Row>
            <Table 
                loading = { isLoading }
                rowKey={ columns => columns.storeAttributeId } 
                pagination = { paginationProps } 
                columns = {[
                {
                    title: 'ID',
                    dataIndex: 'storeAttributeId',
                    key: 'storeAttributeId',
                    align: 'center',
                    width: 100,
                },
                {
                    title: '名称',
                    dataIndex: 'storeAttributeName',
                    key: 'storeAttributeName',
                    align: 'center',
                },
                {
                    title: '排序',
                    dataIndex: 'storeAttributeSort',
                    key: 'storeAttributeSort',
                    align: 'center',
                },
                {
                    title: '状态',
                    dataIndex: 'storeAttributeShow',
                    key: 'storeAttributeShow',
                    align: 'center',
                    render: (value) => (
                        <span>{value ? '显示' : '不显示'}</span>
                    ),
                },
                {
                    title: '操作',
                    key: 'action',
                    align: 'center',
                    render: (value, storeAttribute) => (
                        <Space size="middle">
                            <Link to={"/shop/store-manage/store-attribute/update/" + storeAttribute.storeAttributeId}>
                                编辑
                            </Link>
                            <a onClick={() => confirmDeleteStoreAttribute(storeAttribute.storeAttributeId)}>
                                删除
                            </a>
                        </Space>
                    ),
                    width: 150,
                }
            ]}
            dataSource={ list || [] }  />
            <ErrorBox error={ error } />
        </div>
    )
}