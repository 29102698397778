import { Button, Col, message, Modal, Row, Space, Table, Image, Select, Form, Input, Card } from "antd"
import Search from "antd/lib/input/Search"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useAreaListCity } from "../../services/area-service"
import  { useSubjectList, useDeleteSubject }  from "../../services/mall-subject-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useSubjectSearchParams } from "./util"

export const MallSubjectListPage = () => {
    useDocumentTitle('专题列表', true)
    const imgUrl = process.env.REACT_APP_IMG_URL
    const [param, setParam] = useSubjectSearchParams()
    const {isLoading, error, totalElements, data: list} = useSubjectList(useDebounce(param, 200))
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) => 
            setParam({
            ...param,
            pageNum: (current -1),
        })
    }
    //删除事件
    const useMutateDeleteSubject = useDeleteSubject;
    const { mutateAsync } = useMutateDeleteSubject();
    const confirmDeleteSubject = (subjectId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ subjectId }).then(() => {
                        message.info('删除成功');
                        Modal.destroyAll();
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    //准备区域数据
    const { data: areaList } = useAreaListCity();
    const areaListOptions = areaList?.data?.map((item: any, value: any) => (
        <Select.Option value={item.areaId} key={item.areaId}>
            {item.areaName}
        </Select.Option>
    ));
    const onFinish = (values: any) => {
        setParam({
            ...param,
            ...values,
            pageNum: null
        })
    }
    //分页
    return (
        <div>
            <Row style={{marginBottom: 24}}>
                <Col span={12}>
                    <Link to={"/shop/dress-manage/mall-subject/add"}>
                        <Button type="primary">新建专题</Button>
                    </Link>
                </Col>
            </Row>
            <Card style={{ backgroundColor: "#f2f3f5", margin: '24px 0 24px 0' }}>
                <Form onFinish={onFinish}>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item name="subjectTitle" label="名称">
                                <Input placeholder='请输入名称' allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="areaId" label="区域">
                                <Select
                                    placeholder='请选择区域'
                                    showSearch
                                    allowClear
                                    optionFilterProp='children'
                                >
                                    {areaListOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item style={{ marginBottom: 0 }}>
                        <Button
                            style={{ marginLeft: 70 }}
                            type="primary"
                            htmlType="submit"
                        >
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
            <Table 
                loading = { isLoading }
                pagination = { paginationProps } 
                rowKey={ columns => columns.subjectId } 
                columns = {[
                {
                    title: '专题ID',
                    dataIndex: 'subjectId',
                    key: 'subjectId',
                    width: 100,
                },
                {
                    title: '地区',
                    dataIndex: 'areaName',
                    key: 'areaName',
                    width: 100,
                },
                {
                    title: '专题标题',
                    dataIndex: 'subjectTitle',
                    key: 'subjectTitle',
                },
                {
                    title: '专题图片',
                    dataIndex: 'subjectPic',
                    key: 'subjectPic',
                    render: (value, subject) => (
                        <Image width={100} src={imgUrl + subject.subjectPic}/>
                    ),
                },
                {
                    title: '专题排序',
                    dataIndex: 'subjectSort',
                    key: 'subjectSort',
                },
                {
                    title: '是否显示',
                    dataIndex: 'subjectShow',
                    key: 'subjectShow',
                    render: (value, subject) => (
                        <span>{subject.subjectShow ? '显示' : '不显示'}</span>
                    ),
                },
                {
                    title: '操作',
                    key: 'action',
                    render: (value, subject) => (
                        <Space size="middle">
                            <Link to={"/shop/dress-manage/mall-subject/update/" + subject.subjectId}>
                                编辑
                            </Link>
                            <a onClick={() => confirmDeleteSubject(subject.subjectId)}>
                                删除
                            </a>
                        </Space>
                    ),
                    width: 150,
                },
            ]}
            dataSource={ list || [] }  />
            <ErrorBox error={ error } />
        </div>
    )
}