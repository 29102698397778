import { Typography, Col, Row, Space } from 'antd'
import './index.css';
import { useState } from 'react';
const { Text } = Typography;
export const DashboardPage = () => {
  const [nowTime, setNowTime] = useState(new Date().toLocaleDateString() + " " + new Date().toLocaleTimeString());
  return (
    <div style={{ background: '#fff' }}>
      <div style={{ width: '100%' }}>
        <Space direction='vertical' size={16} style={{ width: '100%' }}>
          <div className='bg_box pd24'>
            <Space style={{ fontWeight: 500, fontSize: 16, paddingBottom: 24 }}>
              实时概况<Text style={{ fontSize: 12 }}>更新时间：{nowTime}</Text>
            </Space>
            <Row style={{ textAlign: 'center' }}>
              <Col span={24}>
                <Row gutter={16} style={{ height: 100 }}>
                </Row>
              </Col>
            </Row>
          </div>
          <div className='bg_box pd24'>
            <div className='mb20' style={{ fontWeight: 500, fontSize: 16 }}>
              代办事项
            </div>
            <Row>
            </Row>
          </div>
        </Space>
      </div>
    </div>
  )
}
