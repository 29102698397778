import { useMutation, useQuery, useQueryClient } from "react-query";
import { MemberTag } from "../interfaces/member-tag";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useMemberTagList = (param?: Partial<MemberTag>) => {
    const client = useHttp();
    const result = useQuery<any>(['memberTagList', param], () => client('memberTag', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditMemberTag = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<MemberTag>) => client(`memberTag`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('memberTagList')
        }
    )
}

export const useAddMemberTag = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<MemberTag>) => client(`memberTag`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('memberTagList')
        }
    )
}

export const useDeleteMemberTag = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ memberTagId }: { memberTagId: number}) =>
            client(`memberTag/${memberTagId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('memberTagList')
        }
    );
};

export const useMemberTag = (memberTagId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['memberTag', {memberTagId}],
        () => client(`memberTag/${memberTagId}`),
        {
            enabled: Boolean(memberTagId)
        }
    )
}

/**查询全部 */
export const useMemberTagListAll = (param?: Partial<MemberTag>) => {
    const client = useHttp();
    const result = useQuery<any>(["memberTagListAll", param], () =>
        client("memberTag/list", { data: param })
    );
    const data = result.data?.data;
    return {...result, data };
};