import { useMutation, useQuery, useQueryClient } from "react-query";
import { OrdersRefund } from "../interfaces/orders-refund";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

//列表
export const useOrdersRefundList = (param?: Partial<OrdersRefund>) => {
    const client = useHttp();
    const result = useQuery<any>(['ordersRefundList', param], () => client('ordersRefund', { data: cleanObject(param) }))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return { ...result, totalElements, data };
}

//详情
export const useOrdersRefund = (ordersRefundId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['adv', { ordersRefundId }],
        () => client(`ordersRefund/${ordersRefundId}`),
        {
            enabled: Boolean(ordersRefundId)
        }
    )
}

export const useAgree = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<OrdersRefund>) => client(`ordersRefund/agree`, {
            method: 'PUT',
            data: params
        }), {
        onSuccess: () => queryClient.invalidateQueries('ordersRefundList')
    }
    )
}

//退货退款 - 同意 - 发送地址
export const useAgreeReturn = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<OrdersRefund>) => client(`ordersRefund/agreeReturn`, {
            method: 'PUT',
            data: params
        }), {
        onSuccess: () => queryClient.invalidateQueries('ordersRefundList')
    }
    )
}

//退货退款 - 同意 - 确认收货 - 退款
export const useAgreeReceive = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<OrdersRefund>) => client(`ordersRefund/agreeReceive`, {
            method: 'PUT',
            data: params
        }), {
        onSuccess: () => queryClient.invalidateQueries('ordersRefundList')
    }
    )
}

//退货退款 - 不同意 - 拒绝收货
export const useRejectReceive = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<OrdersRefund>) => client(`ordersRefund/rejectReceive`, {
            method: 'PUT',
            data: params
        }), {
        onSuccess: () => queryClient.invalidateQueries('ordersRefundList')
    }
    )
}

//仅退款、退货退款 - 不同意 
export const useReject = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<OrdersRefund>) => client(`ordersRefund/reject`, {
            method: 'PUT',
            data: params
        }), {
        onSuccess: () => queryClient.invalidateQueries('ordersRefundList')
    }
    )
}

