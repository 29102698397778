import { Divider, Row, Col, Button, Input, Form, DatePicker, message, Space, Image, Radio, Menu, Select } from 'antd';
import { useEffect, useState } from 'react';
import { Link, useLocation } from "react-router-dom";
import { useEditPointsGoods, usePointsGoods } from '../../services/points-goods-service';
import { usePointsGoodsClassListAll } from "../../services/points-goods-class-service";
import { useForm } from 'antd/lib/form/Form';
import { useDocumentTitle } from '../../utils';
import moment from 'moment'
const { RangePicker } = DatePicker;
const { Option } = Select;

export const PointsGoodsUpdatePage = () => {
    useDocumentTitle('编辑商品', true)
    const imgUrl = process.env.REACT_APP_IMG_URL;
    const { pathname } = useLocation();
    //准备商品
    const pointsGoodsId = pathname.match(/shop\/market-manage\/points\/goods\/update\/(\d+)/)?.[1]
    const { data: editingPointsGoods, isLoading } = usePointsGoods(
        Number(pointsGoodsId)
    );
    //准备分类
    const { data: pointsGoodsClassList } = usePointsGoodsClassListAll();
    const pointsGoodsClassOptions = pointsGoodsClassList?.data?.map((item: any, value: any) => (
        <Option value={item.pointsGoodsClassId} key={item.pointsGoodsClassId}>
            {item.pointsGoodsClassName}
        </Option>
    ));
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    const [submitLoading, setSubmitLoading] = useState(false);
    //保存
    const useMutateEditPointsGoods = useEditPointsGoods;
    const { mutateAsync, error } = useMutateEditPointsGoods();
    const [val, setVal] = useState([{}]);
    //限购
    const [purchaseLimit, setPurchaseLimit] = useState<any>(false);
    const handlePurchaseLimit = (e: any) => {
        setPurchaseLimit(e.target.value);
    };
    //规格
    const [goodsSpecOpen, setGoodsSpecOpen] = useState(false);
    //状态
    const [pointsGoodsState, setPointsGoodsState] = useState(3);
    //活动时间
    const rangeConfig = {
        rules: [{ type: 'array' as const, message: '请选择时间!' }],
    };
    const [pointsGoodsStartTimeStr, setPointsGoodsStartTimeStr] = useState();
    const [pointsGoodsEndTimeStr, setPointsGoodsEndTimeStr] = useState();
    const handleSelectTime = (value: any, dateString: any) => {
        setPointsGoodsStartTimeStr(dateString[0]);
        setPointsGoodsEndTimeStr(dateString[1]);
    }

    //表单
    const [form] = useForm();
    useEffect(() => {
        form.setFieldsValue(editingPointsGoods?.data);
        setGoodsSpecOpen(editingPointsGoods?.data?.goodsSpecOpen);
        if (editingPointsGoods?.data?.goodsSpecOpen) {
            setVal(editingPointsGoods?.data?.pointsGoodsGoodsSkuList);
        }
        setPurchaseLimit(editingPointsGoods?.data?.pointsGoodsPurchaseLimit);
        setPointsGoodsState(editingPointsGoods?.data?.pointsGoodsState);
        if (editingPointsGoods?.data?.pointsGoodsStartTimeStr) {
            setPointsGoodsStartTimeStr(editingPointsGoods?.data?.pointsGoodsStartTimeStr);
        }
        if (editingPointsGoods?.data?.pointsGoodsEndTimeStr) {
            setPointsGoodsEndTimeStr(editingPointsGoods?.data?.pointsGoodsEndTimeStr);
        }
        if (editingPointsGoods?.data?.pointsGoodsStartTimeStr && editingPointsGoods?.data?.pointsGoodsEndTimeStr) {
            form.setFieldsValue({
                "pointsGoodsTimeRange": [moment(editingPointsGoods?.data?.pointsGoodsStartTimeStr, "YYYY-MM-DD HH:mm:ss"), moment(editingPointsGoods?.data?.pointsGoodsEndTimeStr, "YYYY-MM-DD HH:mm:ss")]
            })
        }
    }, [editingPointsGoods, form]);
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        const pointsGoodsGoodsSkuList = values?.pointsGoodsGoodsSkuList
        mutateAsync({ ...editingPointsGoods?.data, ...values, pointsGoodsGoodsSkuList, pointsGoodsStartTimeStr, pointsGoodsEndTimeStr }).then((data) => {
            if (data.status === 0) {
                window.location.href = "/#/shop/market-manage/points/goods/list";
            } else {
                setSubmitLoading(false);
                message.error(data.msg);
            }
        });
    };
    return (
        <div>
            <Menu style={{ marginBottom: 24 }} mode="horizontal" selectedKeys={['pointsGoods']}>
                <Menu.Item key="pointsGoods">
                    <Link to={"/shop/market-manage/points/goods/list"}>
                        商品管理
                    </Link>
                </Menu.Item>
                <Menu.Item key="pointsGoodsClass">
                    <Link to={"/shop/market-manage/points/goodsClass/list"}>
                        分类管理
                    </Link>
                </Menu.Item>
                <Menu.Item key="pointsAdv">
                    <Link to={"/shop/market-manage/points/adv/list"}>
                        广告管理
                    </Link>
                </Menu.Item>
            </Menu>
            <Row>
                <Col span={12}>
                    <Link to={"/shop/market-manage/points/goods/list"}>
                        <Button type="primary">商品列表</Button>
                    </Link>
                </Col>
            </Row>
            <Divider style={{ marginBottom: 24 }} />
            <div>
                <Form
                    form={form}
                    {...layout}
                    name="basic"
                    onFinish={onFinish}
                >
                    <Form.Item
                        label={"活动名称"}
                        name={"pointsGoodsTitle"}
                        rules={[{ required: true, message: "活动名称必填" }]}
                    >
                        <Input placeholder={"请输入活动名称"} disabled={pointsGoodsState === 3 ? true : false} />
                    </Form.Item>

                    <Form.Item
                        name="pointsGoodsTimeRange"
                        label="活动时间"
                        {...rangeConfig}
                        rules={[{ required: true, message: "活动时间必选" }]}
                    >
                        <RangePicker onChange={handleSelectTime} showTime format="YYYY-MM-DD HH:mm:ss" style={{ width: '100%' }}
                            disabled={pointsGoodsState === 2 ? [true, false] : pointsGoodsState === 3 ? [true, true] : [false, false]} />
                    </Form.Item>

                    <Form.Item
                        label={"支付类型"}
                        name={"pointsGoodsPayType"}
                        rules={[{ required: true, message: "支付类型必选" }]}
                        style={{ textAlign:'left' }}
                    >
                        <Radio.Group disabled={true}>
                            <Radio value={1}>纯积分</Radio>
                            <Radio value={2}>积分+现金</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label={"商品分类"}
                        name={"pointsGoodsClassId"}
                        rules={[{ required: true, message: "商品分类必选" }]}
                        >
                        <Select
                            showSearch
                            placeholder="请选择商品分类"
                            optionFilterProp="children"
                            style={{ textAlign: "left" }}
                        >
                            {pointsGoodsClassOptions}
                        </Select>
                    </Form.Item>

                    {editingPointsGoods?.data?.goodsId ? 
                        <Form.Item
                            name="pointsGoodsGoods"
                            label="已选商品"
                        >
                            <Row>
                                <Col span={6}>
                                    <Image
                                        width="100%"
                                        src={imgUrl  + editingPointsGoods?.data?.goodsPic}
                                    />
                                </Col>
                                <Col span={18} style={{ paddingLeft: 10 }}>
                                    <Row>
                                        <Col span={24} style={{ marginBottom: 5 }}>
                                            {editingPointsGoods?.data?.goodsName}
                                        </Col>
                                        {goodsSpecOpen === false ?
                                            <div>
                                                <Col span={24} style={{ marginBottom: 5 }}>
                                                    规格：{editingPointsGoods?.data?.goodsSpecName}
                                                </Col>
                                            </div> : ''
                                        }

                                    </Row>
                                </Col>
                            </Row>
                        </Form.Item> : ''}

                    {goodsSpecOpen === false ?
                        <div>
                            <Form.Item
                                label="积分设置"
                            >
                                <Row style={{ height: 50, lineHeight: 50, fontWeight: 500, backgroundColor: "#f7f8fa" }}>
                                    <Col style={{ height: 50, lineHeight: "50px", width: "25%", textAlign: 'left', paddingRight: 10, paddingLeft: 10 }}>
                                        规格
                                    </Col>
                                    <Col style={{ height: 50, lineHeight: "50px", width: "25%", textAlign: 'left', paddingRight: 10 }}>
                                        积分
                                    </Col>
                                    <Col style={{ height: 50, lineHeight: "50px", width: "25%", textAlign: 'left', paddingRight: 10 }}>
                                        价格
                                    </Col>
                                    <Col style={{ height: 50, lineHeight: "50px", width: "25%", textAlign: 'left' }}>
                                        库存
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 10 }}>
                                    <Col style={{ width: "25%", textAlign: 'left', paddingRight: 10, paddingLeft: 10 }}>
                                        <Input readOnly value={editingPointsGoods?.data?.goodsSpecName} placeholder="请输入规格名称" />
                                    </Col>
                                    <Col style={{ width: "25%", textAlign: 'left', paddingRight: 10 }}>
                                        <Form.Item
                                            name={"pointsGoodsPrice"}
                                            rules={[{ required: true, message: "积分必填" }]}
                                        >
                                            <Input disabled={pointsGoodsState === 1 ? false : true} />
                                        </Form.Item>
                                    </Col>
                                    <Col style={{ width: "25%", textAlign: 'left', paddingRight: 10 }}>
                                        <Form.Item
                                            name={"pointsGoodsMoney"}
                                            rules={[{ required: true, message: "价格必填" }]}
                                        >
                                            <Input disabled={pointsGoodsState === 1 ? false : true} />
                                        </Form.Item>
                                    </Col>
                                    <Col style={{ width: "25%", textAlign: 'left' }}>
                                        <Form.Item
                                            name={"pointsGoodsStorage"}
                                            rules={[{ required: true, message: "库存必填" }]}
                                        >
                                            <Input disabled={pointsGoodsState === 1 ? false : true} />
                                        </Form.Item>
                                    </Col>
                                </Row>
                            </Form.Item>
                        </div> : "" }

                    {goodsSpecOpen === true ?
                        <div>
                            <Form.Item
                                label="积分设置"
                            >
                                <Row style={{ height: 50, lineHeight: 50, fontWeight: 500, backgroundColor: "#f7f8fa" }}>
                                    <Col style={{ height: 50, lineHeight: "50px", width: "25%", textAlign: 'left', paddingRight: 10, paddingLeft: 10 }}>
                                        规格
                                    </Col>
                                    <Col style={{ height: 50, lineHeight: "50px", width: "25%", textAlign: 'left', paddingRight: 10 }}>
                                        积分
                                    </Col>
                                    <Col style={{ height: 50, lineHeight: "50px", width: "25%", textAlign: 'left', paddingRight: 10 }}>
                                        价格
                                    </Col>
                                    <Col style={{ height: 50, lineHeight: "50px", width: "25%", textAlign: 'left' }}>
                                        库存
                                    </Col>
                                </Row>
                                <Row style={{ marginTop: 10 }}>
                                    <Form.List initialValue={val} name="pointsGoodsGoodsSkuList">
                                        {(fields, { add, remove }) => (
                                            <>
                                                {fields.map((field, index) => (
                                                    <Space
                                                        key={field.key} style={{ display: 'flex', marginBottom: 8 }} align="baseline">
                                                        <Space>
                                                            <Form.Item hidden
                                                                name={[field.name, 'pointsGoodsGoodsSkuId']}
                                                            >
                                                                <Input />
                                                            </Form.Item>
                                                            <Form.Item
                                                                name={[field.name, 'goodsSkuName']}
                                                                rules={[{ required: true, message: '规格名称必填' }]}
                                                            >
                                                                <Input readOnly />
                                                            </Form.Item>
                                                            <Form.Item
                                                                name={[field.name, 'pointsGoodsPrice']}
                                                                rules={[{ required: true, message: '积分必填' }]}
                                                            >
                                                                <Input disabled={pointsGoodsState === 1 ? false : true} />
                                                            </Form.Item>
                                                            <Form.Item
                                                                name={[field.name, 'pointsGoodsMoney']}
                                                                rules={[{ required: true, message: '价格必填' }]}
                                                            >
                                                                <Input disabled={pointsGoodsState === 1 ? false : true} />
                                                            </Form.Item>
                                                            <Form.Item
                                                                name={[field.name, 'pointsGoodsStorage']}
                                                                rules={[{ required: true, message: '库存必填' }]}
                                                            >
                                                                <Input disabled={pointsGoodsState === 1 ? false : true} />
                                                            </Form.Item>
                                                        </Space>
                                                    </Space>
                                                ))}
                                            </>
                                        )}
                                    </Form.List>
                                </Row>
                            </Form.Item>
                        </div> : "" }

                    <Form.Item
                        label={"每人限购"}
                        name={"pointsGoodsPurchaseLimit"}
                        rules={[{ required: true, message: "每人限购必选" }]}
                        style={{ textAlign: 'left' }}
                    >
                        <Radio.Group onChange={handlePurchaseLimit} >
                            <Radio defaultChecked value={true} disabled={pointsGoodsState === 1 ? false : true}>开启限购</Radio>
                            <Radio value={false} disabled={pointsGoodsState === 1 ? false : true}>关闭限购</Radio>
                        </Radio.Group>
                    </Form.Item>

                    {purchaseLimit === true ?
                        <div>
                            <Form.Item
                                label={"限购数量"}
                                name={"pointsGoodsPurchaseLimitNum"}
                                rules={[{ required: true, message: "限购数量必填" }]}
                                style={{ textAlign: 'left' }}
                            >
                                <Input placeholder={"请输入限购数量"} disabled={pointsGoodsState === 1 ? false : true} />
                            </Form.Item>
                        </div> : ""}

                    <Form.Item
                        label={"排序"}
                        name={"pointsGoodsSort"}
                        rules={[{ required: true, message: "排序必填" }]}
                        style={{ textAlign: 'left' }}
                    >
                        <Input placeholder={"请输入排序"} disabled={pointsGoodsState === 3 ? true : false} />
                    </Form.Item>

                    <Form.Item
                        label={"是否推荐"}
                        name={"pointsGoodsRecommend"}
                        rules={[{ required: true, message: "是否推荐必选" }]}
                        style={{ textAlign: 'left' }}
                    >
                        <Radio.Group>
                            <Radio value={true} disabled={pointsGoodsState === 3 ? true : false}>推荐</Radio>
                            <Radio value={false} disabled={pointsGoodsState === 3 ? true : false}>不推荐</Radio>
                        </Radio.Group>
                    </Form.Item>
                    
                    <Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                        <Button loading={submitLoading} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </div>
        </div>
    )
}