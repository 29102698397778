import { useMutation, useQuery, useQueryClient } from "react-query";
import { CourseSearchDefault } from "../interfaces/course-search-default";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useCourseSearchDefaultList = (param?: Partial<CourseSearchDefault>) => {
    const client = useHttp();
    const result = useQuery<any>(['courseSearchDefaultPage', param], () => client('courseSearchDefault', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditCourseSearchDefault = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<CourseSearchDefault>) => client(`courseSearchDefault`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('courseSearchDefaultPage')
        }
    )
}

export const useAddCourseSearchDefault = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<CourseSearchDefault>) => client(`courseSearchDefault`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('courseSearchDefaultPage')
        }
    )
}

export const useDeleteCourseSearchDefault = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ courseSearchDefaultId }: { courseSearchDefaultId: number}) =>
            client(`courseSearchDefault/${courseSearchDefaultId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('courseSearchDefaultPage')
        }
    );
};

export const useCourseSearchDefault = () => {
    const client = useHttp()
    return useQuery<any>(
        ['courseSearchDefault'],
        () => client(`courseSearchDefault`)
    )
}