import React, { ReactNode } from "react"
import * as auth from '../utils/auth-provider'
import { FullPageErrorFallback, FullPageLoading } from "../components/lib";
import { http } from "../utils/http";
import { useMount } from "../utils";
import { useAsync } from "../utils/use-async";
import { AdminAccount } from "../interfaces/admin-account";
import { message } from "antd";

const localStorageKey = "__auth_provider_xiaoqihui_admin_token__";

interface AuthForm {
    adminAccountName: string,
    adminAccountPasswordStr: string
}

//页面加载的时候执行
const bootstrapAdmin = async () => {
    let adminAccount = null;
    const token = auth.getToken();
    if(token) {
        const data = await http('adminAccount/current', { token });
        const status = data.status
        if(status === 0) {
            const result = data.data.token
            if(result !== null) {
                adminAccount = data.data
            }
        } else {
            window.localStorage.removeItem(localStorageKey) 
        }
    } 
    return adminAccount
}

const AuthContext = React.createContext<{
    adminAccount: AdminAccount | null,
    login: (form: AuthForm) => Promise<void>,
    logout: () => Promise<void>,
} | undefined>(undefined)
AuthContext.displayName = "AuthContext"

export const AuthProvider = ({children}: {children : ReactNode}) => {
    const {data: adminAccount, error, isLoading, isIdle, isError, run, setData: setAdminAccount} = useAsync<AdminAccount | null>()
    const login = (form: AuthForm) => auth.login(form).then(
        async (response) => {
            const status = response.status
            if(status === 0) {
                const token = response.data.token;
                window.localStorage.setItem(localStorageKey, token || "");
            } else {
                message.info(response.msg);
            }
            setAdminAccount(response.data)
        }
    );
    const logout = () => auth.logout().then(() => {
        setAdminAccount(null);
        window.location.href = "/";
    });
    //页面加载的时候
    useMount(() => {
        run(bootstrapAdmin())
    });
    if(isIdle || isLoading) {
        return <FullPageLoading />
    }
    if(isError) {
        return <FullPageErrorFallback error={error} />
    }
    return (
        <AuthContext.Provider 
            children={children} 
            value={{adminAccount, login, logout }} 
        />
    );   
}

export const useAuth = () => {
    const context = React.useContext(AuthContext);
    if(!context) {
        throw new Error("useAuth必须在AuthProvider中使用");  
    }
    return context;
}
    