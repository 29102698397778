import { useMemo } from "react";
import { useUrlQueryParam } from "../../utils/url";

export const useSchoolOrdersSearchParams = () => {
    const [param, setParam] = useUrlQueryParam(["schoolOrdersSn", "schoolId", "agencyId", "plannerId", "schoolOrdersState", "schoolOrdersCreateTimeStart", "schoolOrdersCreateTimeEnd", "schoolOrdersStudentName", "schoolOrdersStudentTel", "memberNickname", "schoolOrdersId", "pageNum"]);
    return [
        useMemo(() => ({...param, schoolOrdersId: Number(param.schoolOrdersId) || undefined, schoolId: Number(param.schoolId) || undefined,
            agencyId: Number(param.agencyId) || undefined, plannerId: Number(param.plannerId) || undefined, 
            pageNum: Number(param.pageNum) || undefined}), [param]),
        setParam
    ] as const
}