import { Button, Col, Divider, Form, Input, message, Radio, Row, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { usePlannerEvaEasilyTag, useEditPlannerEvaEasilyTag } from "../../services/planner-eva-easily-tag-service";
import { useDocumentTitle } from "../../utils"
export const PlannerEvaEasilyTagUpdatePage = () => {
    //标题
    useDocumentTitle('编辑规划师评价快捷标签', true)
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const plannerEvaEasilyTagId = Number(pathname.match(/jigou\/planner-manage\/planner-eva-easily-tag\/update\/(\d+)/)?.[1])

    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };

    //表单
    const [form] = useForm();
    const [plannerEvaEasilyTagPic, setPlannerEvaEasilyTagPic] = useState("");

    //准备回显
    const { data: editingPlannerEvaEasilyTag, isLoading } = usePlannerEvaEasilyTag(plannerEvaEasilyTagId);
    console.log("回显",editingPlannerEvaEasilyTag,isLoading);
    useEffect(() => {
        form.setFieldsValue({ ...editingPlannerEvaEasilyTag?.data });
        //封面
        if (editingPlannerEvaEasilyTag?.data?.plannerEvaEasilyTagPic) {
            setPlannerEvaEasilyTagPic(editingPlannerEvaEasilyTag?.data?.plannerEvaEasilyTagPic)
        }
    }, [editingPlannerEvaEasilyTag, form]);

    //新建
    const [submitLoading, setSubmitLoading] = useState(false);
    const { mutateAsync } = useEditPlannerEvaEasilyTag();
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...editingPlannerEvaEasilyTag?.data, ...values, plannerEvaEasilyTagPic }).then((data) => {
            if (data.status === 0) {
                message.success(data.msg);
                navigate('/jigou/planner-manage/planner-eva-easily-tag/list')
            } else {
                setSubmitLoading(false);
                message.error(data.msg);
            }
        }).catch(err => {
            message.error('提交失败，请重新提交');
            setSubmitLoading(false);
        });
    };

    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/jigou/planner-manage/planner-eva-easily-tag/list"}>
                        <Button type="primary">列表</Button>
                    </Link>
                </Col>
            </Row>

            <Divider style={{ marginBottom: 24 }} />

            <Spin spinning={isLoading}>
                <Form
                    form={form}
                    {...layout}
                    onFinish={onFinish}
                >
               	<Form.Item
                    label={"名称"}
                    name={"plannerEvaEasilyTagName"}
                    rules={[{ required: true, message: "名称必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入名称"} />
                </Form.Item>

               	<Form.Item
                    label={"排序"}
                    name={"plannerEvaEasilyTagSort"}
                    rules={[{ required: true, message: "排序必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入排序"} />
                </Form.Item>

                <Form.Item
                    label={"是否显示"}
                    name={"plannerEvaEasilyTagShow"}
                    rules={[{ required: true, message: "是否显示必选" }]}
                    style={{ textAlign: 'left' }}
                >
                    <Radio.Group>
                        <Radio defaultChecked value={true}>显示</Radio>
                        <Radio value={false}>不显示</Radio>
                    </Radio.Group>
                </Form.Item>
				<Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                        <Button loading={submitLoading} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    )
}