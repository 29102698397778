import { useMutation, useQuery, useQueryClient } from "react-query";
import { SchoolLevel } from "../interfaces/school-level";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useSchoolLevelList = (param?: Partial<SchoolLevel>) => {
    const client = useHttp();
    const result = useQuery<any>(['schoolLevelPage', param], () => client('schoolLevel', { data: cleanObject(param) }))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return { ...result, totalElements, data };
}

export const useEditSchoolLevel = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<SchoolLevel>) => client(`schoolLevel`, {
            method: 'PUT',
            data: params
        }), {
        onSuccess: () => queryClient.invalidateQueries('schoolLevelPage')
    }
    )
}

export const useAddSchoolLevel = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<SchoolLevel>) => client(`schoolLevel`, {
            data: params,
            method: 'POST'
        }), {
        onSuccess: () => queryClient.invalidateQueries('schoolLevelPage')
    }
    )
}

export const useDeleteSchoolLevel = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ schoolLevelId }: { schoolLevelId: number }) =>
            client(`schoolLevel/${schoolLevelId}`, {
                method: "DELETE",
            }), {
        onSuccess: () => queryClient.invalidateQueries('schoolLevelPage')
    }
    );
};

export const useSchoolLevel = (schoolLevelId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['schoolLevel', { schoolLevelId }],
        () => client(`schoolLevel/${schoolLevelId}`),
        {
            enabled: Boolean(schoolLevelId)
        }
    )
}

export const useSchoolLevelListAll = () => {
    const client = useHttp();
    return useQuery<any>(["schoolLevelList", {}], () =>
        client(`schoolLevel/list`)
    );
};