import { useMutation, useQuery, useQueryClient } from "react-query";
import { GoodsClass } from "../interfaces/goods-class";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useGoodsClassList = (param?: Partial<GoodsClass>) => {
    const client = useHttp();
    const result = useQuery<any>(['goodsClassList', param], () => client('goodsClass', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.goodsClassVOList;
    return {...result, totalElements, data };
}

export const useEditGoodsClass = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<GoodsClass>) => client(`goodsClass`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('goodsClassList')
        }
    )
}

export const useAddGoodsClass = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<GoodsClass>) => client(`goodsClass`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('goodsClassList')
        }
    )
}

export const useDeleteGoodsClass = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ goodsClassId }: { goodsClassId: number}) =>
            client(`goodsClass/${goodsClassId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('goodsClassList')
        }
    );
};

export const useGoodsClass = (goodsClassId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['goodsClass', {goodsClassId}],
        () => client(`goodsClass/${goodsClassId}`),
        {
            enabled: Boolean(goodsClassId)
        }
    )
}

export const useGoodsClassListTree = () => {
    const client = useHttp();
    return useQuery<any>(['goodsClassListTree', {}], () => client('goodsClass/listTree'))
}
