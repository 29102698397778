import { Button, Col, message, Modal, Row, Space, Table, Image, Card, Form, Input, Menu } from "antd"
import Search from "antd/lib/input/Search"
import { Link } from "react-router-dom"
import dayjs from "dayjs"
import { ErrorBox } from "../../components/lib"
import { useCourseSearchHotList, useDeleteCourseSearchHot } from "../../services/course-search-hot-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useCourseSearchHotSearchParams } from "./util"

export const CourseSearchHotListPage = () => {
    useDocumentTitle('课程热门搜索列表', true)
    const imgUrl = process.env.REACT_APP_IMG_URL;
    const [param, setParam] = useCourseSearchHotSearchParams()
    const {isLoading, error, totalElements, data: list} = useCourseSearchHotList(useDebounce(param, 200))

	//分页
	const paginationProps = {
	  showSizeChanger: true,
	  onShowSizeChange: (current: number, pageSize: number) => {
		setParam({
		  ...param,
		  pageNum: current - 1,
		  pageSize,
		});
	  },
	  defaultCurrent: 1,
	  pageSize: param.pageSize,
	  total: totalElements,
	  onChange: (current: number, pageSize: number) =>
		setParam({
		  ...param,
		  pageNum: current - 1,
		  pageSize,
		}),
	};
    //删除事件
    const { mutateAsync } = useDeleteCourseSearchHot();
    const confirmDeleteCourseSearchHot = (courseSearchHotId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ courseSearchHotId }).then((data) => {
                        if (data.status === 0) {
                            message.info('删除成功');
                            Modal.destroyAll();
                          } else {
                            message.error(data.msg)
                          }
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    //分页
    return (
        <div>
            <Menu style={{ marginBottom: 24 }} mode="horizontal" selectedKeys={['course-search-hot']}>
                <Menu.Item key="course-search-hot">
                    <Link to={"/jigou/course-manage/course-search-hot/list"}>
                        关键词列表
                    </Link>
                </Menu.Item>
                <Menu.Item key="course-search-default">
                    <Link to={"/jigou/course-manage/course-search/default/index"}>
                        默认关键词
                    </Link>
                </Menu.Item>
            </Menu>
            <Row style={{marginBottom: 24}}>
                <Col span={12}>
                    <Link to={"/jigou/course-manage/course-search/hot/add"}>
                        <Button type="primary">新建</Button>
                    </Link>
                </Col>
            </Row>
            <Table 
                loading = { isLoading }
                rowKey={ columns => columns.courseSearchHotId } 
                pagination = { paginationProps } 
                columns = {[
                {
                    title: 'ID',
                    dataIndex: 'courseSearchHotId',
                    key: 'courseSearchHotId',
                    align: 'center',
                    width: 100,
                },

                {
                    title: '热门搜索词',
                    dataIndex: 'courseSearchHotValue',
                    key: 'courseSearchHotValue',
                    align: 'center',
                },

                {
                    title: '排序',
                    dataIndex: 'courseSearchHotSort',
                    key: 'courseSearchHotSort',
                    align: 'center',
                },

                {
                    title: '操作',
                    key: 'action',
                    align: 'center',
                    render: (value, courseSearchHot) => (
                        <Space size="middle">
                            <Link to={"/jigou/course-manage/course-search/hot/update/" + courseSearchHot.courseSearchHotId}>
                                编辑
                            </Link>
                            <a onClick={() => confirmDeleteCourseSearchHot(courseSearchHot.courseSearchHotId)}>
                                删除
                            </a>
                        </Space>
                    ),
                    width: 150,
                }
            ]}
            dataSource={ list || [] }  />
            <ErrorBox error={ error } />
        </div>
    )
}