import { useMutation, useQuery, useQueryClient } from "react-query";
import { CourseClass } from "../interfaces/course-class";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useCourseClassList = (param?: Partial<CourseClass>) => {
    const client = useHttp();
    const result = useQuery<any>(['courseClassPage', param], () => client('courseClass', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data.courseClassVOList;
    return {...result, totalElements, data };
}

export const useEditCourseClass = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<CourseClass>) => client(`courseClass`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('courseClassPage')
        }
    )
}

export const useAddCourseClass = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<CourseClass>) => client(`courseClass`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('courseClassPage')
        }
    )
}

export const useDeleteCourseClass = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ courseClassId }: { courseClassId: number}) =>
            client(`courseClass/${courseClassId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('courseClassPage')
        }
    );
};

export const useCourseClass = (courseClassId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['courseClass', {courseClassId}],
        () => client(`courseClass/${courseClassId}`),
        {
            enabled: Boolean(courseClassId)
        }
    )
}

export const useGoodsClassListTree = () => {
    const client = useHttp();
    return useQuery<any>(['goodsClassListTree', {}], () => client('courseClass/listTree'))
}