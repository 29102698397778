import { useMutation, useQuery, useQueryClient } from "react-query";
import { StoreBrand } from "../interfaces/store-brand";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useStoreBrandList = (param?: Partial<StoreBrand>) => {
    const client = useHttp();
    const result = useQuery<any>(['storeBrandList', param], () => client('storeBrand', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditStoreBrand = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<StoreBrand>) => client(`storeBrand`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('storeBrandList')
        }
    )
}

export const useAddStoreBrand = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<StoreBrand>) => client(`storeBrand`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('storeBrandList')
        }
    )
}

export const useDeleteStoreBrand = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ storeBrandId }: { storeBrandId: number}) =>
            client(`storeBrand/${storeBrandId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('storeBrandList')
        }
    );
};

export const useStoreBrand = (storeBrandId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['storeBrand', {storeBrandId}],
        () => client(`storeBrand/${storeBrandId}`),
        {
            enabled: Boolean(storeBrandId)
        }
    )
}

/**查询全部 */
export const useStoreBrandListAll = (param?: Partial<StoreBrand>) => {
    const client = useHttp();
    const result = useQuery<any>(["storeBrandListAll", param], () =>
        client("storeBrand/listAll", { data: param })
    );
    const data = result.data?.data;
    return {...result, data };
};