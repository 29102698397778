import { useMutation, useQuery, useQueryClient } from "react-query";
import { StoreClass } from "../interfaces/store-class";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useStoreClassList = (param?: Partial<StoreClass>) => {
    const client = useHttp();
    const result = useQuery<any>(['storeClassList', param], () => client('storeClass', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.storeClassVOList;
    return {...result, totalElements, data };
}

export const useEditStoreClass = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<StoreClass>) => client(`storeClass`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('storeClassList')
        }
    )
}

export const useAddStoreClass = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<StoreClass>) => client(`storeClass`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('storeClassList')
        }
    )
}

export const useDeleteStoreClass = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ storeClassId }: { storeClassId: number}) =>
            client(`storeClass/${storeClassId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('storeClassList')
        }
    );
};

export const useStoreClass = (storeClassId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['storeClass', {storeClassId}],
        () => client(`storeClass/${storeClassId}`),
        {
            enabled: Boolean(storeClassId)
        }
    )
}

export const useStoreClassListTree = () => {
    const client = useHttp();
    return useQuery<any>(['storeClassListTree', {}], () => client('storeClass/listTree'))
}
