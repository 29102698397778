import { Button, Col, Divider, Form, Input, message, Radio, Row, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEditNotice, useNotice } from "../../services/notice-service";
import { useDocumentTitle } from "../../utils"
import UploadPic from '../../components/upload-pic';
import RichText from "../../components/rich-text";
import { useNoticeDetail } from "../../services/notice-detail-service";
import BraftEditor from "braft-editor";

export const NoticeUpdatePage = () => {
    //标题
    useDocumentTitle('编辑公告', true)
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const noticeId = Number(pathname.match(/dress\/notice-manage\/notice\/update\/(\d+)/)?.[1])

    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 18 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };

    //回显
    const { data: editingNotice, isLoading } = useNotice(noticeId);
    const { data: editingNoticeDetail } = useNoticeDetail(noticeId);
    //表单
    const [form] = useForm();
    const [noticePic, setNoticePic] = useState("");
    const [noticeContent, setNoticeContent] = useState("");
    useEffect(() => {
        form.setFieldsValue({ ...editingNotice?.data });
        //封面
        if (editingNotice?.data?.noticePic) {
            setNoticePic(editingNotice?.data?.noticePic)
        }
        if (editingNoticeDetail?.data?.noticeContent) {
            setNoticeContent(editingNoticeDetail?.data?.noticeContent);
            form.setFieldsValue({
                noticeContent: BraftEditor.createEditorState(
                    editingNoticeDetail?.data?.noticeContent
                ),
            });
        }
    }, [editingNotice, editingNoticeDetail, form]);

    //新建
    const [submitLoading, setSubmitLoading] = useState(false);
    const { mutateAsync } = useEditNotice();
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...editingNotice?.data, ...values, noticePic, noticeContent }).then((data) => {
            if (data.status === 0) {
                message.success(data.msg);
                navigate('/dress/notice-manage/notice/list')
            } else {
                setSubmitLoading(false);
                message.error(data.msg);
            }
        }).catch(err => {
            message.error('提交失败，请重新提交');
            setSubmitLoading(false);
        });
    };

    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/dress/notice-manage/notice/list"}>
                        <Button type="primary">列表</Button>
                    </Link>
                </Col>
            </Row>

            <Divider style={{ marginBottom: 24 }} />
            <Spin spinning={isLoading}>
                <Form
                    form={form}
                    {...layout}
                    onFinish={onFinish}
                >

                    <Form.Item
                        label={"标题"}
                        name={"noticeTitle"}
                        rules={[{ required: true, message: "标题必填" }]}
                    >
                        <Input style={{ width: 380 }} placeholder={"请输入标题"} />
                    </Form.Item>

                    <UploadPic
                        name={'uploadPic'}
                        label={'图片'}
                        pic={noticePic}
                        setPic={setNoticePic}
                        action={'update'}
                        style={{ textAlign: 'left' }}
                    >
                    </UploadPic>

                    <Form.Item
                        label={"排序"}
                        name={"noticeSort"}
                        rules={[{ required: true, message: "排序必填" }]}
                    >
                        <Input style={{ width: 380 }} placeholder={"请输入排序"} />
                    </Form.Item>

                    <Form.Item
                        label={"状态"}
                        name={"noticeShow"}
                        rules={[{ required: true, message: "状态必选" }]}
                        style={{ textAlign: 'left' }}
                    >
                        <Radio.Group>
                            <Radio defaultChecked value={true}>显示</Radio>
                            <Radio value={false}>不显示</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <RichText
                        label={"内容"}
                        name={"noticeContent"}
                        setContent={setNoticeContent}
                    >
                    </RichText>

                    <Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                        <Button loading={submitLoading} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    )
}

