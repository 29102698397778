import { useMutation, useQuery, useQueryClient } from "react-query";
import { WechatMenu } from "../interfaces/wechat-menu";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useWechatMenuList = (param?: Partial<WechatMenu>) => {
    const client = useHttp();
    const result = useQuery<any>(['wechatMenuPage', param], () => client('wechatMenu', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.wechatMenuVOList;
    return {...result, totalElements, data };
}

export const useEditWechatMenu = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<WechatMenu>) => client(`wechatMenu`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('wechatMenuPage')
        }
    )
}

export const useAddWechatMenu = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<WechatMenu>) => client(`wechatMenu`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('wechatMenuPage')
        }
    )
}

export const useDeleteWechatMenu = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ wechatMenuId }: { wechatMenuId: number}) =>
            client(`wechatMenu/${wechatMenuId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('wechatMenuPage')
        }
    );
};

export const useWechatMenu = (wechatMenuId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['wechatMenu', {wechatMenuId}],
        () => client(`wechatMenu/${wechatMenuId}`),
        {
            enabled: Boolean(wechatMenuId)
        }
    )
}

export const useWechatMenuListParent = () => {
    const client = useHttp();
    return useQuery<any>(['wechatMenuListParent', {}], () => client('wechatMenu/list'))
}

export const useSyncWechatMenu = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        () =>
            client(`wechatMenu/sync`, {
            method: "POST",
        }), {
            onSuccess: () => queryClient.invalidateQueries('wechatMenuPage')
        }
    );
};
