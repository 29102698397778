import { Button, Card, Col, Divider,Image, Form, Input, message, Radio, Row, Select, Spin, Table, Space, Modal } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import "./detail.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCourse, useEditCourse, useOffCourseSection, useUpCourseSection } from "../../services/course-service";
import { useDocumentTitle } from "../../utils"
import  UploadPic  from '../../components/upload-pic';
import dayjs from 'dayjs';
import UploadVideo from '../../components/upload-video';
export const CourseDetailPage = () => {
    //标题
    useDocumentTitle('编辑课程', true)
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const courseId = Number(pathname.match(/jigou\/course-manage\/course\/detail\/(\d+)/)?.[1])
    const imgUrl = process.env.REACT_APP_IMG_URL
    const videoUrl = process.env.REACT_APP_VIDEO_URL
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };

    //表单
    const [form] = useForm();
    const [coursePic, setCoursePic] = useState("");
    const [uploading, setUploading] = useState<boolean>(false);

    //准备回显
    const { data: course, isLoading } = useCourse(courseId);
    console.log("回显",course,isLoading);
    // useEffect(() => {
    //     form.setFieldsValue({ ...editingCourse?.data });
    //     //封面
    //     if (editingCourse?.data?.coursePic) {
    //         setCoursePic(editingCourse?.data?.coursePic)
    //     }
    // }, [editingCourse, form]);

//下架课程小节
const { mutateAsync:offSectionAsync } = useOffCourseSection();
const confirmOffCourseSection = (courseSectionId: number) => {
    Modal.confirm({
        title: "确定下架这条数据吗?",
        content: "点击确定下架",
        okText: "确定",
        onOk(): any {
            return new Promise((resolve, reject) => {
                offSectionAsync({ courseSectionId }).then((data) => {
                    if (data.status === 0) {
                        message.info('下架成功');
                        Modal.destroyAll();
                      } else {
                        message.error(data.msg)
                      }
                });
            }).catch(
                () => console.log('Oops errors!')
            );
        },
        cancelText: "取消"
    });
};
//上架课程小节
const { mutateAsync:upSectionAsync } = useUpCourseSection();
const confirmUpCourseSection = (courseSectionId: number) => {
    Modal.confirm({
        title: "确定下架这条数据吗?",
        content: "点击确定下架",
        okText: "确定",
        onOk(): any {
            return new Promise((resolve, reject) => {
                upSectionAsync({ courseSectionId }).then((data) => {
                    if (data.status === 0) {
                        message.info('下架成功');
                        Modal.destroyAll();
                      } else {
                        message.error(data.msg)
                      }
                });
            }).catch(
                () => console.log('Oops errors!')
            );
        },
        cancelText: "取消"
    });
};
    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/jigou/course-manage/course/list"}>
                        <Button type="primary">列表</Button>
                    </Link>
                </Col>
            </Row>
            <Divider style={{ marginBottom: 24 }} />
            <Card title={"课程信息"}>
                <Row gutter={16}>
                    <Col className="gutter-row" span={4}>
                        <div style={{  padding: '8px 0' }}>课程名称:{course?.data?.courseName
                      ? course?.data?.courseName
                      : "-"}</div>
                    </Col>
                    <Col className="gutter-row" span={4}>
                        <div style={{ padding: '8px 0' }}>课程类型:{course?.data?.courseType==1?"线上课程":"线下课程"}</div>
                    </Col>
                    <Col className="gutter-row" span={4}>
                        <div style={{ padding: '8px 0' }}>机构名称:{course?.data?.agencyName?course?.data?.agencyName:''}</div>
                    </Col>
                    <Col className="gutter-row" span={4}>
                        <div style={{ padding: '8px 0' }}>课程分类:{course?.data?.courseClassName?course?.data?.courseClassName:''}</div>
                    </Col>
                    <Col className="gutter-row" span={6}>
                        <div style={{  padding: '8px 0' }}>课程创建时间:
                        {course?.data?.courseAddTime?dayjs(course?.data?.courseAddTime).format("YYYY-MM-DD HH:mm:ss"):""}
                        </div>
                    </Col>
                </Row>
                <Row gutter={16} style={{marginTop:"20px"}}>
                    <Col className="gutter-row" span={4}>        
                        <Card title={"课程预览图"}>
                            {course?.data?.coursePicPre?<>
                                <Image src={imgUrl+course?.data?.coursePicPre}></Image>
                            </>:""}    
                        </Card>
                    </Col>
                    <Col className="gutter-row" span={4}>        
                        <Card title={"课程图片"}>
                            {course?.data?.coursePic?<>
                                <Image src={imgUrl+course?.data?.coursePic}></Image>
                            </>:""}    
                        </Card>
                    </Col>
                </Row>
            </Card>
            <Card title={""}>
                <Row gutter={16}>
                    <Col className="gutter-row" span={3}>
                        <div style={{  padding: '8px 0' }}>
                        课程价格:{course?.data?.coursePrice?"¥"+course?.data?.coursePrice:""}
                        </div>
                    </Col>
                    <Col className="gutter-row" span={3}>
                        <div style={{  padding: '8px 0' }}>
                        购买人数:{course?.data?.courseBuyNum?course?.data?.courseBuyNum:"0"}
                        </div>
                    </Col>
                    <Col className="gutter-row" span={3}>
                        <div style={{  padding: '8px 0' }}>
                        课程评分:{course?.data?.courseEvaSource?course?.data?.courseEvaSource:""}
                        </div>
                    </Col>
                    <Col className="gutter-row" span={3}>
                        <div style={{  padding: '8px 0' }}>
                        好评率:{course?.data?.courseEvaRate?course?.data?.courseEvaRate:""}
                        </div>
                    </Col>
                    <Col className="gutter-row" span={3}>
                        <div style={{  padding: '8px 0' }}>
                            收藏:{course?.data?.courseCollectNum?course?.data?.courseCollectNum:"0"}
                        </div>
                    </Col>
                    <Col className="gutter-row" span={3}>
                        <div style={{  padding: '8px 0' }}>
                        点赞:{course?.data?.courseLikeNum?course?.data?.courseLikeNum:"0"}
                        </div>
                    </Col>
                </Row>
            </Card>
            <Card title={"课程详情"} style={{marginTop:"20px",width:"100%"}} className="course-detail">
                <div style={{width:"100%"}} dangerouslySetInnerHTML={{__html: course?.data?.courseContent}}></div>
            </Card>
            {course?.data?.courseType==1?<>
            <Card title={"课程小节"} style={{marginTop:"20px",width:"100%"}}>
            <Table 
                loading = { isLoading }
                rowKey={ columns => columns.courseSectionId } 
                columns = {[
                {
                    title: 'ID',
                    dataIndex: 'courseSectionId',
                    key: 'courseSectionId',
                    align: 'center',
                    width: 100,
                },


                {
                    title: '课程序号',
                    dataIndex: 'courseSectionIndex',
                    key: 'courseSectionIndex',
                    align: 'center',
                },

                {
                    title: '小节名称',
                    dataIndex: 'courseSectionName',
                    key: 'courseSectionName',
                    align: 'center',
                },

                {
                    title: '是否免费',
                    dataIndex: 'courseSectionFee',
                    key: 'courseSectionFee',
                    align: 'center',
                    render(value, record, index) {
                        return (
                            <>{value?"免费":"付费"}</>
                        )
                    },
                },

                {
                    title: '是否显示',
                    dataIndex: 'courseSectionShow',
                    key: 'courseSectionShow',
                    align: 'center',
                    render: (value) => (
                        <span>{value ? '显示' : '不显示'}</span>
                    ),
                },

				{
                    title: '预览图片',
                    dataIndex: 'courseSectionPic',
                    key: 'courseSectionPic',
                    align: 'center',
                    render: (value) => (
                        <Image width={150} src={imgUrl + value}/>
                    ),
                },

                {
                    title: '课程视频',
                    dataIndex: 'courseSectionVideo',
                    key: 'courseSectionVideo',
                    align: 'center',
                    render: (value) => (
                        <video width={150} src={videoUrl + value}/>
                    ),
                },

                {
                    title: '课程视频预览',
                    dataIndex: 'courseSectionVideoPre',
                    key: 'courseSectionVideoPre',
                    align: 'center',
                    render: (value) => (
                        <video width={150} src={videoUrl + value}/>
                    ),
                },

                {
                    title: '视频观看人数',
                    dataIndex: 'courseSectionViewNum',
                    key: 'courseSectionViewNum',
                    align: 'center',
                },


                {
                    title: '添加时间',
                    dataIndex: 'courseSectionAddTime',
                    key: 'courseSectionAddTime',
                    align: 'center',
                    render: (value) => (
                        <span>
                            {value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss'):'无'}
                        </span>
                    ),
                },

                {
                    title: '操作',
                    key: 'action',
                    align: 'center',
                    render: (value, courseSection) => (
                        <Space size="middle">
                            {/* <Link to={"/part/agency-manage/course-section/update/" + courseSection.courseSectionId}>
                                编辑
                            </Link> */}
                            {courseSection?.courseSectionShow?<>
                            <a onClick={() => confirmOffCourseSection(courseSection.courseSectionId)}>
                                下架
                            </a>
                            </>:<>
                            <a onClick={() => confirmUpCourseSection(courseSection.courseSectionId)}>
                                上架
                            </a>
                            </>}
                            
                        </Space>
                    ),
                    width: 150,
                }
            ]}
            dataSource={ course?.data?.courseSectionList || [] }  />
            </Card></>:""}
            {/* <Spin spinning={isLoading}>
                <Form
                    form={form}
                    {...layout}
                >
               	<Form.Item
                    label={"课程名称"}
                    name={"courseName"}
                    rules={[{ required: true, message: "课程名称必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入课程名称"} />
                </Form.Item>

               	<Form.Item
                    label={"课程评论数"}
                    name={"courseEvaNum"}
                    rules={[{ required: true, message: "课程评论数必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入课程评论数"} />
                </Form.Item>

               	<Form.Item
                    label={"课程评分"}
                    name={"courseEvaSource"}
                    rules={[{ required: true, message: "课程评分必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入课程评分"} />
                </Form.Item>

               	<Form.Item
                    label={"好评率"}
                    name={"courseEvaRate"}
                    rules={[{ required: true, message: "好评率必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入好评率"} />
                </Form.Item>

               	<Form.Item
                    label={"课程分类id"}
                    name={"courseClassId"}
                    rules={[{ required: true, message: "课程分类id必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入课程分类id"} />
                </Form.Item>

               	<Form.Item
                    label={"课程分类名称"}
                    name={"courseClassName"}
                    rules={[{ required: false, message: "课程分类名称必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入课程分类名称"} />
                </Form.Item>

               	<Form.Item
                    label={"课程价格"}
                    name={"coursePrice"}
                    rules={[{ required: true, message: "课程价格必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入课程价格"} />
                </Form.Item>

               	<Form.Item
                    label={"课程点赞人数"}
                    name={"courseLikeNum"}
                    rules={[{ required: true, message: "课程点赞人数必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入课程点赞人数"} />
                </Form.Item>

               	<Form.Item
                    label={"课程收藏人数"}
                    name={"courseCollectNum"}
                    rules={[{ required: true, message: "课程收藏人数必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入课程收藏人数"} />
                </Form.Item>

               	<Form.Item
                    label={"机构id"}
                    name={"agencyId"}
                    rules={[{ required: true, message: "机构id必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入机构id"} />
                </Form.Item>

               	<Form.Item
                    label={"机构名称"}
                    name={"agencyName"}
                    rules={[{ required: false, message: "机构名称必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入机构名称"} />
                </Form.Item>

               	<Form.Item
                    label={"购买人数"}
                    name={"courseBuyNum"}
                    rules={[{ required: true, message: "购买人数必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入购买人数"} />
                </Form.Item>

                <Form.Item
                    label={"是否显示"}
                    name={"courseShow"}
                    rules={[{ required: true, message: "是否显示必选" }]}
                    style={{ textAlign: 'left' }}
                >
                    <Radio.Group>
                        <Radio defaultChecked value={true}>显示</Radio>
                        <Radio value={false}>不显示</Radio>
                    </Radio.Group>
                </Form.Item>
                <Form.Item
                    label={"是否删除"}
                    name={"deleteFlag"}
                    rules={[{ required: true, message: "是否删除必选" }]}
                    style={{ textAlign: 'left' }}
                >
                    <Radio.Group>
                        <Radio defaultChecked value={true}>是</Radio>
                        <Radio value={false}>否</Radio>
                    </Radio.Group>
                </Form.Item>
               	<Form.Item
                    label={"备注"}
                    name={"mark"}
                    rules={[{ required: false, message: "备注必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入备注"} />
                </Form.Item>

               	<Form.Item
                    label={"课程类型(1线上,2线下)"}
                    name={"courseType"}
                    rules={[{ required: true, message: "课程类型(1线上,2线下)必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入课程类型(1线上,2线下)"} />
                </Form.Item>

				<Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                        <Button loading={submitLoading} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Spin> */}
        </div>
    )
}

