import { Button, Col, Divider, Form, Input, message, Radio, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAddArea, useArea } from "../../services/area-service";
import { useDocumentTitle } from "../../utils"
import { useAreaSearchParams } from "./util";

export const AreaAddPage = () => {
    //标题
    useDocumentTitle('新建区域', true)
    const navigate = useNavigate();
    const [param] = useAreaSearchParams();
    const areaType = param?.areaType || 1;
    const areaParentId = param?.areaParentId || 0;

    const { data: parentArea } = useArea(param?.areaParentId || 0)

    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };

    //表单
    const [form] = useForm();

    //新建
    const [submitLoading, setSubmitLoading] = useState(false);
    const { mutateAsync } = useAddArea();
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...values, areaType, areaParentId }).then((data) => {
            if (data.status === 0) {
                message.success(data.msg);
                navigate("/dress/area-manage/area/list?areaType=" + areaType + "&areaParentId=" + areaParentId)
            } else {
                setSubmitLoading(false);
                message.error(data.msg);
            }
        }).catch(err => {
            message.error('提交失败，请重新提交');
            setSubmitLoading(false);
        });
    };

    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/dress/area-manage/area/list?areaType=" + areaType + "&areaParentId=" + areaParentId}>
                        <Button type="primary">列表</Button>
                    </Link>
                </Col>
            </Row>

            <Divider style={{ marginBottom: 24 }} />

            <Form
                form={form}
                {...layout}
                onFinish={onFinish}
                initialValues={{
                    areaSort: 0,
                    areaShow: true,
                    areaHot: false
                }}>

                {parentArea?.data && areaType === 2 ? (
                    <Form.Item
                        label={"省份"}
                        name={"province"}
                    >
                        <Input style={{ width: 380 }} defaultValue={parentArea?.data?.areaName} disabled />
                    </Form.Item>
                ) : parentArea?.data && areaType === 3 ? (
                    <Form.Item
                        label={"城市"}
                        name={"city"}
                    >
                        <Input style={{ width: 380 }} defaultValue={parentArea?.data?.areaName} disabled />
                    </Form.Item>
                ) : ('')}

                <Form.Item
                    label={"名称"}
                    name={"areaName"}
                    rules={[{ required: true, message: "名称必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入名称"} />
                </Form.Item>

                <Form.Item
                    label={"首字母"}
                    name={"areaLetter"}
                    // rules={[{ required: true, message: "首字母必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入首字母"} />
                </Form.Item>

                <Form.Item
                    label={"排序"}
                    name={"areaSort"}
                    rules={[{ required: true, message: "排序必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入排序"} />
                </Form.Item>

                <Form.Item
                    label={"状态"}
                    name={"areaShow"}
                    rules={[{ required: true, message: "状态必选" }]}
                    style={{ textAlign: 'left' }}
                >
                    <Radio.Group>
                        <Radio defaultChecked value={true}>显示</Radio>
                        <Radio value={false}>不显示</Radio>
                    </Radio.Group>
                </Form.Item>

                {param?.areaType === 2 ? (
                    <Form.Item
                        label={"热门"}
                        name={"areaHot"}
                        rules={[{ required: true, message: "是否热门必选" }]}
                        style={{ textAlign: 'left' }}
                    >
                        <Radio.Group>
                            <Radio value={true}>是</Radio>
                            <Radio defaultChecked value={false}>否</Radio>
                        </Radio.Group>
                    </Form.Item>
                ) : ('')}

                <Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                    <Button loading={submitLoading} type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

