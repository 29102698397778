import { useMutation, useQuery, useQueryClient } from "react-query";
import { Company } from "../interfaces/company";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useCompanyList = (param?: Partial<Company>) => {
    const client = useHttp();
    const result = useQuery<any>(['companyPage', param], () => client('company', { data: cleanObject(param) }))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return { ...result, totalElements, data };
}

export const useAddCompany = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Company>) => client(`company`, {
            data: params,
            method: 'POST'
        }), {
        onSuccess: () => queryClient.invalidateQueries('companyPage')
    }
    );
};

export const useEditCompany = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Company>) => client(`company`, {
            data: params,
            method: 'PUT'
        }), {
        onSuccess: () => queryClient.invalidateQueries('companyPage')
    }
    );
};

export const useDeleteCompany = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ companyId }: { companyId: number }) => client(`company/${companyId}`, {
            method: "DELETE",
        }), {
        onSuccess: () => queryClient.invalidateQueries('companyPage')
    }
    );
};

export const useCompany = (companyId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['company', { companyId }],
        () => client(`company/${companyId}`),
        {
            enabled: Boolean(companyId)
        }
    )
}

export const useCompanyListAll = () => {
    const client = useHttp();
    return useQuery<any>(["companyList", {}], () =>
        client(`company/list`)
    );
};
