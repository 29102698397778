import { Button, Col, Divider, Form, Input, message, Radio, Row, Spin, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { usePersonMenu, useEditPersonMenu, usePersonMenuListAll } from "../../services/person-menu-service";
import { useDocumentTitle } from "../../utils"
import UploadPic from '../../components/upload-pic';
import PageUrlSelect from "../../components/page-url-select";
const { Option } = Select;

export const PersonMenuUpdatePage = () => {
    //标题
    useDocumentTitle('编辑工具', true)
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const personMenuId = Number(pathname.match(/dress\/decorate-manage\/person-menu\/update\/(\d+)/)?.[1])

    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };

    const { data: personMenuList } = usePersonMenuListAll();
    const personMenuOptions = personMenuList?.data?.map((item: any) =>
        <Option key={item?.personMenuId} value={item?.personMenuId}>{item?.personMenuName}</Option>
    )

    //表单
    const [form] = useForm();
    const [personMenuPic, setPersonMenuPic] = useState("");
    const [pageUrlId, setPageUrlId] = useState();

    //准备回显
    const { data: editingPersonMenu, isLoading } = usePersonMenu(personMenuId);
    console.log("回显", editingPersonMenu, isLoading);
    useEffect(() => {
        form.setFieldsValue({ ...editingPersonMenu?.data });
        //封面
        if (editingPersonMenu?.data?.personMenuPic) {
            setPersonMenuPic(editingPersonMenu?.data?.personMenuPic)
        }
        let pageUrlId = editingPersonMenu?.data?.pageUrlId;
        setPageUrlId(pageUrlId);
    }, [editingPersonMenu, form]);

    //新建
    const [submitLoading, setSubmitLoading] = useState(false);
    const { mutateAsync } = useEditPersonMenu();
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...editingPersonMenu?.data, ...values, personMenuPic }).then((data) => {
            if (data.status === 0) {
                message.success(data.msg);
                navigate('/dress/decorate-manage/person-menu/list')
            } else {
                setSubmitLoading(false);
                message.error(data.msg);
            }
        }).catch(err => {
            message.error('提交失败，请重新提交');
            setSubmitLoading(false);
        });
    };

    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/dress/decorate-manage/person-menu/list"}>
                        <Button type="primary">列表</Button>
                    </Link>
                </Col>
            </Row>

            <Divider style={{ marginBottom: 24 }} />

            <Spin spinning={isLoading}>
                <Form
                    form={form}
                    {...layout}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label={"上级"}
                        name={"personMenuParentId"}
                        rules={[{ required: true, message: "上级必选" }]}
                    >
                        <Select
                            style={{ width: 380 }}
                            placeholder={"请选择上级"}
                            showSearch
                            allowClear
                            optionFilterProp="children"
                        >
                            <Option value={0}>无</Option>
                            {personMenuOptions}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label={"名称"}
                        name={"personMenuName"}
                        rules={[{ required: true, message: "名称必填" }]}
                    >
                        <Input style={{ width: 380 }} placeholder={"请输入名称"} />
                    </Form.Item>

                    <UploadPic
                        label={'图标'}
                        name={'uploadPic'}
                        pic={personMenuPic}
                        setPic={setPersonMenuPic}
                        action={'update'}
                        style={{ textAlign: 'left' }}
                        required={false}
                    >
                    </UploadPic>


                    <PageUrlSelect
                        pageUrlId={pageUrlId}
                    >
                    </PageUrlSelect>

                    <Form.Item
                        label={"排序"}
                        name={"personMenuSort"}
                        rules={[{ required: true, message: "排序必填" }]}
                    >
                        <Input style={{ width: 380 }} placeholder={"请输入排序"} />
                    </Form.Item>

                    <Form.Item
                        label={"状态"}
                        name={"personMenuShow"}
                        rules={[{ required: true, message: "状态必选" }]}
                        style={{ textAlign: 'left' }}
                    >
                        <Radio.Group>
                            <Radio defaultChecked value={true}>显示</Radio>
                            <Radio value={false}>不显示</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                        <Button loading={submitLoading} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    )
}