import { Button, Col, Form, Input, Row, Tree, message, Divider, Spin } from 'antd';
import { useForm } from 'antd/lib/form/Form';
import { useState, useEffect } from 'react';
import { useDebounce, useDocumentTitle } from '../../utils'
import { useAdminRole, useEditAdminRole } from '../../services/admin-role-service';
import { useAdminAuthorityListAll } from '../../services/admin-authority-service';
import { Link, useLocation, useNavigate } from 'react-router-dom';
import { useAdminAuthoritySearchParams } from './util';

export const AdminRoleUpdatePage = () => {
  useDocumentTitle('编辑角色', true)
  const navigate = useNavigate(); // 路由跳转
  const { pathname } = useLocation();
  const adminRoleId = pathname.match(/setting\/account-manage\/admin-role\/update\/(\d+)/)?.[1];

  //布局
  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 14 },
  };
  const tailLayout = {
    wrapperCol: { offset: 3 },
  };

  //获取数据
  const { data: editingAdminRole, isLoading } = useAdminRole(Number(adminRoleId));
  const [treeData, setTreeData] = useState([]);
  const [param] = useAdminAuthoritySearchParams(Number(adminRoleId));
  const { data: list } = useAdminAuthorityListAll(useDebounce(param, 200))
  useEffect(() => {
    setTreeData(list?.data)
  }, [list])

  // 权限选择
  const [expandedKeys, setExpandedKeys] = useState([]);
  const [checkedKeys, setCheckedKeys] = useState([]);
  const [autoExpandParent, setAutoExpandParent] = useState(true);
  const [adminAuthorityIdList, setAdminAuthorityIdList] = useState([]);

  // 展开节点
  const onExpand = (expandedKeysValue: any) => {
    setExpandedKeys(expandedKeysValue);
    setAutoExpandParent(false);
  };

  // 点击节点
  const onCheck = (checkedKeysValue: any, e: any) => {
    const adminRoleIndex = e.halfCheckedKeys
    const adminAuthorityIdLists = e.checkedNodes.map((item: any) => (item.adminAuthorityId));
    if (adminRoleIndex) {
      adminRoleIndex.forEach((item: any) => {
        adminAuthorityIdLists.push(item);
      });
    }
    setAdminAuthorityIdList(adminAuthorityIdLists);
    setCheckedKeys(checkedKeysValue);
  };

  //表单回显
  const [submitLoading, setSubmitLoading] = useState(false);
  const [form] = useForm();
  useEffect(() => {
    form.setFieldsValue(editingAdminRole?.data);
    const adminAuthorityIdCheck: any = []
    const adminAuthorityIds: any = []
    list?.data?.forEach((item: any) => {
      if (item.check) {
        adminAuthorityIdCheck.push(item.adminAuthorityId);
        if (!item?.adminAuthorityVOList.length) {
          adminAuthorityIds.push(item.adminAuthorityId);
        }
      }
      item?.adminAuthorityVOList?.forEach((itemli: any) => {
        if (itemli.check) {
          adminAuthorityIdCheck.push(itemli.adminAuthorityId);
          if (!itemli?.adminAuthorityVOList.length) {
            adminAuthorityIds.push(itemli.adminAuthorityId);
          }
        }
        itemli?.adminAuthorityVOList?.forEach((itemlii: any) => {
          if (itemlii.check) {
            adminAuthorityIdCheck.push(itemlii.adminAuthorityId);
            adminAuthorityIds.push(itemlii.adminAuthorityId);
          }
        })
      });
    });
    setExpandedKeys(adminAuthorityIds)
    setCheckedKeys(adminAuthorityIds)
    setAdminAuthorityIdList(adminAuthorityIdCheck);
  }, [editingAdminRole, list, form]);
  //提交
  const { mutateAsync } = useEditAdminRole();
  const onFinish = (values: any) => {
    setSubmitLoading(true);
    mutateAsync({ ...editingAdminRole?.data, ...values, adminAuthorityIdList }).then((data) => {
      if (data.status === 0) {
        message.success(data.msg);
        navigate('/setting/account-manage/admin-role/list')
      } else {
        setSubmitLoading(false);
        message.info(data.msg);
      }
    }).catch(err => {
      message.error('提交失败，请重新提交');
      setSubmitLoading(false);
    });
  };
  return (
    <div>
      <Row>
        <Col span={12}>
          <Link to={'/setting/account-manage/admin-role/list'}>
            <Button type='primary'>列表</Button>
          </Link>
        </Col>
      </Row>
      
      <Divider style={{ marginBottom: 24 }} />

      <Spin spinning={isLoading}>
        <Form
          form={form}
          {...layout}
          name='basic'
          onFinish={onFinish}
        >
          <Form.Item
            label={'名称'}
            name={'adminRoleName'}
            rules={[{ required: true, message: '名称必填' }]}
          >
            <Input style={{ width: 380 }} placeholder={'请输入名称'} />
          </Form.Item>

          <Form.Item
            label={'排序'}
            name={'adminRoleSort'}
            rules={[
              { required: true, message: '排序必填' },
              () => ({
                validator(_, value) {
                  const pattern = /^[0-9]*$/;
                  if (!value || pattern.test(value)) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('必须是纯数字'));
                },
              }),
            ]}
          >
            <Input style={{ width: 380 }} placeholder={'请输入排序'} />
          </Form.Item>

          <Form.Item
            label={'权限'}
            rules={[
              () => ({
                validator() {
                  if (adminAuthorityIdList.length) {
                    return Promise.resolve();
                  }
                  return Promise.reject(new Error('权限必选'));
                },
              }),
            ]}
          >
            <Tree
              checkable
              onExpand={onExpand}
              expandedKeys={expandedKeys}
              autoExpandParent={autoExpandParent}
              onCheck={onCheck}
              checkedKeys={checkedKeys}
              treeData={treeData}
              blockNode
              fieldNames={{ title: 'adminAuthorityName', key: 'adminAuthorityId', children: 'adminAuthorityVOList' }}
            />
          </Form.Item>
          
          <Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
            <Button loading={submitLoading} type='primary' htmlType='submit'>
              提交
            </Button>
          </Form.Item>
        </Form>
      </Spin>
    </div>
  );
};
