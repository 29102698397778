import { Button, Col, Divider, Form, Input, message, Radio, Row, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useMenu, useEditMenu } from "../../services/menu-service";
import { useDocumentTitle } from "../../utils"
import UploadPic from '../../components/upload-pic';
import PageUrlSelect from "../../components/page-url-select";

export const MenuUpdatePage = () => {
    //标题
    useDocumentTitle('编辑菜单', true)
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const menuId = Number(pathname.match(/dress\/decorate-manage\/menu\/update\/(\d+)/)?.[1])

    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };

    //表单
    const [form] = useForm();
    const [menuPic, setMenuPic] = useState("");
    const [menuCheckedPic, setMenuCheckedPic] = useState("");
    const [pageUrlId, setPageUrlId] = useState();

    //准备回显
    const { data: editingMenu, isLoading } = useMenu(menuId);
    useEffect(() => {
        form.setFieldsValue({ ...editingMenu?.data });
        //封面
        if (editingMenu?.data?.menuPic) {
            setMenuPic(editingMenu?.data?.menuPic)
        }
        if (editingMenu?.data?.menuCheckedPic) {
            setMenuCheckedPic(editingMenu?.data?.menuCheckedPic)
        }
        let pageUrlId = editingMenu?.data?.pageUrlId;
        setPageUrlId(pageUrlId)
    }, [editingMenu, form]);

    //新建
    const [submitLoading, setSubmitLoading] = useState(false);
    const { mutateAsync } = useEditMenu();
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...editingMenu?.data, ...values, menuPic, menuCheckedPic }).then((data) => {
            if (data.status === 0) {
                message.success(data.msg);
                navigate('/dress/decorate-manage/menu/list')
            } else {
                setSubmitLoading(false);
                message.error(data.msg);
            }
        }).catch(err => {
            message.error('提交失败，请重新提交');
            setSubmitLoading(false);
        });
    };

    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/dress/decorate-manage/menu/list"}>
                        <Button type="primary">列表</Button>
                    </Link>
                </Col>
            </Row>

            <Divider style={{ marginBottom: 24 }} />

            <Spin spinning={isLoading}>
                <Form
                    form={form}
                    {...layout}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label={"名称"}
                        name={"menuName"}
                        rules={[{ required: true, message: "名称必填" }]}
                    >
                        <Input style={{ width: 380 }} placeholder={"请输入名称"} />
                    </Form.Item>

                    <UploadPic
                        name={'uploadPic'}
                        label={'图片'}
                        pic={menuPic}
                        setPic={setMenuPic}
                        action={'update'}
                        style={{ textAlign: 'left' }}
                    >
                    </UploadPic>

                    <UploadPic
                        name={'uploadCheckedPic'}
                        label={'图片(选中)'}
                        pic={menuCheckedPic}
                        setPic={setMenuCheckedPic}
                        action={'update'}
                        style={{ textAlign: 'left' }}
                    >
                    </UploadPic>

                    <PageUrlSelect
                        pageUrlId={pageUrlId}
                    >
                    </PageUrlSelect>

                    <Form.Item
                        label={"排序"}
                        name={"menuSort"}
                        rules={[{ required: true, message: "排序必填" }]}
                    >
                        <Input style={{ width: 380 }} placeholder={"请输入排序"} />
                    </Form.Item>

                    <Form.Item
                        label={"状态"}
                        name={"menuShow"}
                        rules={[{ required: true, message: "状态示必选" }]}
                        style={{ textAlign: 'left' }}
                    >
                        <Radio.Group>
                            <Radio defaultChecked value={true}>显示</Radio>
                            <Radio value={false}>不显示</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                        <Button loading={submitLoading} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    )
}