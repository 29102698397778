import { Button, Col, Divider, Form, Input, message, Radio, Row, Select, Spin, Upload } from "antd";
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import  { useMenu, useEditMenu }  from "../../services/mall-menu-service";
import { usePageUrl, usePageUrlList } from "../../services/page-url-service";
import { useDocumentTitle } from "../../utils"
import { getToken } from "../../utils/auth-provider";
const { Option } = Select;

export const MallMenuUpdatePage = () => {
    //标题
    useDocumentTitle('编辑菜单', true)
    //API地址
    const apiUrl = process.env.REACT_APP_API_URL;
    const imgUrl = process.env.REACT_APP_IMG_URL;
    const token = getToken();
    const { pathname } = useLocation();
    const menuId = pathname.match(/shop\/dress-manage\/mall-menu\/update\/(\d+)/)?.[1]
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    //准备跳转链接
    const [propertyShow, setPropertyShow] = useState(false);
    const [propertyName, setPropertyName] = useState("参数");
    const { data: pageUrlList } = usePageUrlList();
    const options = pageUrlList?.map((item: any, value: any) =>
        <Option value={item.pageUrlId} key={item.pageUrlId}>
            {item.pageUrlName}
        </Option>
    );
    //option选中与非选中
    const { mutateAsync: mutateAsyncPageUrl } = usePageUrl();
    function optionHandleChange(pageUrlId: number) {
        mutateAsyncPageUrl({ pageUrlId }).then((data) => {
            const pageUrlFlag = data.data.pageUrlFlag
            const pageUrlPropertyName = data.data.pageUrlPropertyName
            setPropertyShow(pageUrlFlag)
            setPropertyName(pageUrlPropertyName)
        });
    }
    //表单
    const [form] = useForm();
    //准备回显数据
    const { data: editingMenu, isLoading } = useMenu(
        Number(menuId)
    );  
    const [imageUrl, setImageUrl] = useState("");
    const [imageUrls, setImageUrls] = useState("");
    useEffect(() => {
        form.setFieldsValue(editingMenu?.data)
        const picUrl = imgUrl + editingMenu?.data?.menuPic;
        setImageUrl(picUrl)
        const picUrls = imgUrl + editingMenu?.data?.menuCheckedPic;
        setImageUrls(picUrls)
        const pageUrlPropertyValue = editingMenu?.data?.pageUrlPropertyValue
        if(pageUrlPropertyValue != null) {
            setPropertyShow(true)
        } else {
            setPropertyShow(false)
        }
    }, [editingMenu, form]);
    //图片上传
    const [uploadLoading, setUploadLoading] = useState(false);
    const uploadButton = (
        <div>
          {uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>上传图片</div>
        </div>
    );
    const beforeUpload = (file:any) => {
        setImageUrl("");
        setUploadLoading(true);
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            setUploadLoading(false);
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            setUploadLoading(false);
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }
    const handleChange = (info:any) => {
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            setUploadLoading(false);
            setImageUrl(info?.file?.response?.data?.realPath);
            const relativePath = info?.file?.response?.data?.relativePath
            form.setFieldsValue({
                menuPic: relativePath
            })
        }
    };
    const normFile = (e: any) => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    };
    //图片二
    const [uploadLoadings, setUploadLoadings] = useState(false);
    const uploadButtons = (
        <div>
          {uploadLoadings ? <LoadingOutlined /> : <PlusOutlined />}
          <div style={{ marginTop: 8 }}>上传图片</div>
        </div>
    );
    const beforeUploads = (file:any) => {
        setImageUrls("");
        setUploadLoadings(true);
        const isJpgOrPng = file.type === 'image/jpeg' || file.type === 'image/png';
        if (!isJpgOrPng) {
            setUploadLoadings(false);
            message.error('You can only upload JPG/PNG file!');
        }
        const isLt2M = file.size / 1024 / 1024 < 2;
        if (!isLt2M) {
            setUploadLoadings(false);
            message.error('Image must smaller than 2MB!');
        }
        return isJpgOrPng && isLt2M;
    }
    const handleChanges = (info:any) => {
        if (info.file.status === 'uploading') {
            return;
        }
        if (info.file.status === 'done') {
            setUploadLoadings(false);
            setImageUrls(info?.file?.response?.data?.realPath);
            const relativePath = info?.file?.response?.data?.relativePath
            form.setFieldsValue({
                menuCheckedPic: relativePath
            })
        }
    };
    const normFiles = (e: any) => {
        if (Array.isArray(e)) {
          return e;
        }
        return e && e.fileList;
    };
    //新增
    const useMutateEditMenu = useEditMenu;
    const {mutateAsync, error } =  useMutateEditMenu();
    //提交表单
    const [submitLoading, setSubmitLoading] = useState(false);
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...editingMenu?.data, ...values }).then(() => {
            message.info('编辑成功');
            window.location.href = "/#/shop/dress-manage/mall-menu/list"; 
        });
    };
    return (
        <div>
            <Spin spinning={isLoading}>
                <Row>
                    <Col span={12}>
                        <Link to={"/shop/dress-manage/mall-menu/list"}>
                            <Button type="primary">菜单列表</Button>
                        </Link>
                    </Col>
                </Row>
                <Divider style={{ marginBottom: 24 }} />
                <Form
                    form={form}
                    {...layout}
                    onFinish={onFinish}>
                    <Form.Item
                        label={"菜单名称"}
                        name={"menuName"}
                        rules={[{ required: true, message: "菜单名称必填" }]}
                    >
                        <Input placeholder={"请输入菜单名称"} />
                    </Form.Item>

                    <Form.Item
                        name={"uploadPic"}
                        label={"非选中图标"}
                        valuePropName="fileList"
                        getValueFromEvent={normFile}
                        rules={[{ required: true, message: "非选中图标必传" }]}
                        style={{ textAlign:'left' }}
                        initialValue={[
                            {
                            "uid": "",
                            "lastModified": "",
                            "lastModifiedDate": "",
                            "name": "",
                            "size": "",
                            "type": "",
                            "percent": "",
                            "originFileObj": {
                                "uid": ""
                            },
                            "status": "done",
                            "response": {
                                "status": 0,
                                "data": {
                                "relativePath": "",
                                "realPath": ""
                                }
                            },
                            "xhr": {}
                            }
                        ]}
                    >
                        <Upload
                            name="imageFile"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action={apiUrl + "/upload/img"}
                            beforeUpload={beforeUpload}
                            onChange={handleChange}
                            headers={{ "Authorization": "Bearer " + token }}
                        >
                            {imageUrl ? <img src={imageUrl} alt="avatar" style={{ width: '100%' }} /> : uploadButton}
                        </Upload>
                    </Form.Item>

                    <Form.Item hidden
                        label={"非选中图标"}
                        name={"menuPic"}
                        rules={[{ required: true, message: "非选中图标必传" }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        name={"uploadPics"}
                        label={"选中图标"}
                        valuePropName="fileList"
                        getValueFromEvent={normFiles}
                        rules={[{ required: true, message: "选中图标必传" }]}
                        style={{ textAlign:'left' }}
                        initialValue={[
                            {
                            "uid": "",
                            "lastModified": "",
                            "lastModifiedDate": "",
                            "name": "",
                            "size": "",
                            "type": "",
                            "percent": "",
                            "originFileObj": {
                                "uid": ""
                            },
                            "status": "done",
                            "response": {
                                "status": 0,
                                "data": {
                                "relativePath": "",
                                "realPath": ""
                                }
                            },
                            "xhr": {}
                            }
                        ]}
                    >
                        <Upload
                            name="imageFile"
                            listType="picture-card"
                            className="avatar-uploader"
                            showUploadList={false}
                            action={apiUrl + "/upload/img"}
                            beforeUpload={beforeUploads}
                            onChange={handleChanges}
                            headers={{ "Authorization": "Bearer " + token }}
                        >
                            {imageUrls ? <img src={imageUrls} alt="avatar" style={{ width: '100%' }} /> : uploadButtons}
                        </Upload>
                    </Form.Item>

                    <Form.Item hidden
                        label={"选中图标"}
                        name={"menuCheckedPic"}
                        rules={[{ required: true, message: "选中图标必传" }]}
                    >
                        <Input />
                    </Form.Item>

                    <Form.Item
                        label={"跳转链接"}
                        name={"pageUrlId"}
                        rules={[{ required: true, message: "跳转链接必选" }]}
                    >
                        <Select
                            showSearch
                            placeholder="请选择跳转链接"
                            optionFilterProp="children"
                            style={{textAlign:'left'}}
                            onChange={optionHandleChange}
                        >
                            {options}
                        </Select>
                    </Form.Item>

                    {propertyShow ? <Form.Item
                        label={propertyName}
                        name={"pageUrlPropertyValue"}
                        rules={[{ required: true, message: "链接参数必传" }]}
                    >
                        <Input placeholder={"请输入链接参数"} />
                    </Form.Item> : ''}
                    

                    <Form.Item
                        label={"菜单排序"}
                        name={"menuSort"}
                        rules={[{ required: true, message: "菜单排序必填" }]}
                    >
                        <Input placeholder={"请输入菜单排序"} />
                    </Form.Item>

                    <Form.Item
                        label={"是否显示"}
                        name={"menuShow"}
                        rules={[{ required: true, message: "菜单显示必选" }]}
                        style={{ textAlign:'left' }}
                    >
                        <Radio.Group>
                            <Radio defaultChecked value={true}>显示</Radio>
                            <Radio value={false}>不显示</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item {...tailLayout} style={{ textAlign:'left' }}>
                        <Button loading={ submitLoading } type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>  
                </Form>
            </Spin>
        </div>
    )
}

