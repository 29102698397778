import { useMutation, useQuery, useQueryClient } from "react-query";
import { Goods } from "../interfaces/goods";
import { CouponGoods } from "../interfaces/coupon-goods";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useCouponGoodsList = (param?: Partial<Goods>) => {
    const client = useHttp();
    const result = useQuery<any>(['couponGoodsList', param], () => client('couponGoods/listCouponGoods', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useCouponGoodsListNo = (param?: Partial<Goods>) => {
    const client = useHttp();
    const result = useQuery<any>(['couponGoodsListNo', param], () => client('couponGoods/listNoInCouponGoods', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useAddCouponGoods = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<CouponGoods>) => client(`couponGoods/add`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('couponGoodsListNo')
        }
    )
}

export const useDeleteCouponGoods = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ goodsId, couponId }: { goodsId: number, couponId: number}) =>
            client(`couponGoods/delete?goodsId=${goodsId}&couponId=${couponId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('couponGoodsList')
        }
    );
};