import {
  Button,
  Col,
  Divider,
  Form,
  Input,
  message,
  Radio,
  Row,
  Select,
  Upload,
} from "antd";
import { LoadingOutlined, PlusOutlined } from "@ant-design/icons";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAddShowcase } from "../../services/mall-showcase-service";
import { useDocumentTitle } from "../../utils";
import { getToken } from "../../utils/auth-provider";
import { useAreaListCity } from "../../services/area-service";

export const MallShowcaseAddPage = () => {
  //标题
  useDocumentTitle("新建橱窗", true);
  //API地址
  const apiUrl = process.env.REACT_APP_API_URL;
  const token = getToken();
  //布局
  const layout = {
    labelCol: { span: 3 },
    wrapperCol: { span: 7 },
  };
  const tailLayout = {
    wrapperCol: { offset: 3 },
  };
  //表单
  const [form] = useForm();
  //图片上传
  const [uploadLoading, setUploadLoading] = useState(false);
  const [imageUrl, setImageUrl] = useState("");
  const uploadButton = (
    <div>
      {uploadLoading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>上传图片</div>
    </div>
  );
  const beforeUpload = (file: any) => {
    setImageUrl("");
    setUploadLoading(true);
    const isJpgOrPng = file.type === "image/jpeg" || file.type === "image/png";
    if (!isJpgOrPng) {
      setUploadLoading(false);
      message.error("You can only upload JPG/PNG file!");
    }
    const isLt2M = file.size / 1024 / 1024 < 2;
    if (!isLt2M) {
      setUploadLoading(false);
      message.error("Image must smaller than 2MB!");
    }
    return isJpgOrPng && isLt2M;
  };
  const handleChange = (info: any) => {
    if (info.file.status === "uploading") {
      return;
    }
    if (info.file.status === "done") {
      setUploadLoading(false);
      setImageUrl(info?.file?.response?.data?.realPath);
      const relativePath = info?.file?.response?.data?.relativePath;
      form.setFieldsValue({
        showcaseBanner: relativePath,
      });
    }
  };
  const normFile = (e: any) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  //新建
  const useMutateAddShowcase = useAddShowcase;
  const { mutateAsync, error } = useMutateAddShowcase();
  //提交表单
  const [submitLoading, setSubmitLoading] = useState(false);
  const onFinish = (values: any) => {
    setSubmitLoading(true);
    mutateAsync({ ...values }).then(() => {
      message.info("新建成功");
      window.location.href = "/#/shop/dress-manage/mall-showcase/list";
    });
  };
  //准备区域数据
  const { data: areaList } = useAreaListCity();
  const areaListOptions = areaList?.data?.map((item: any, value: any) => (
    <Select.Option value={item.areaId} key={item.areaId}>
      {item.areaName}
    </Select.Option>
  ));
  return (
    <div>
      <Row>
        <Col span={12}>
          <Link to={"/shop/dress-manage/mall-showcase/list"}>
            <Button type="primary">橱窗列表</Button>
          </Link>
        </Col>
      </Row>
      <Divider style={{ marginBottom: 24 }} />
      <Form
        form={form}
        {...layout}
        onFinish={onFinish}
        initialValues={{
          showcaseSort: 0,
          showcaseShow: true,
          showcaseStyle: 1,
        }}
      >
        <Form.Item
          label={"橱窗名称"}
          name={"showcaseName"}
          rules={[{ required: true, message: "橱窗名称必填" }]}
        >
          <Input placeholder={"请输入橱窗名称"} />
        </Form.Item>

        <Form.Item label={"橱窗描述"} name={"showcaseSubName"}>
          <Input placeholder={"请输入橱窗描述"} />
        </Form.Item>

        <Form.Item
          name={"uploadPic"}
          label={"橱窗Banner"}
          valuePropName="fileList"
          getValueFromEvent={normFile}
          style={{ textAlign: "left" }}
        >
          <Upload
            name="imageFile"
            listType="picture-card"
            className="avatar-uploader"
            showUploadList={false}
            action={apiUrl + "/upload/img"}
            beforeUpload={beforeUpload}
            onChange={handleChange}
            headers={{ Authorization: "Bearer " + token }}
          >
            {imageUrl ? (
              <img src={imageUrl} alt="avatar" style={{ width: "100%" }} />
            ) : (
              uploadButton
            )}
          </Upload>
        </Form.Item>

        <Form.Item hidden label={"橱窗Banner"} name={"showcaseBanner"}>
          <Input />
        </Form.Item>

        <Form.Item
          label={"橱窗样式"}
          name={"showcaseStyle"}
          rules={[{ required: true, message: "橱窗样式必选" }]}
          style={{ textAlign: "left" }}
        >
          <Radio.Group>
            <Radio defaultChecked value={1}>
              一行一个大图
            </Radio>
            <Radio value={2}>一行一个小图</Radio>
            <Radio value={3}>一行两个</Radio>
          </Radio.Group>
        </Form.Item>

        <Form.Item
          label={"橱窗排序"}
          name={"showcaseSort"}
          rules={[{ required: true, message: "橱窗排序必填" }]}
        >
          <Input placeholder={"请输入橱窗排序"} />
        </Form.Item>

        <Form.Item
          label={"是否显示"}
          name={"showcaseShow"}
          rules={[{ required: true, message: "橱窗显示必选" }]}
          style={{ textAlign: "left" }}
        >
          <Radio.Group>
            <Radio defaultChecked value={true}>
              显示
            </Radio>
            <Radio value={false}>不显示</Radio>
          </Radio.Group>
        </Form.Item>
        <Form.Item
          label={"显示区域"}
          name={"areaId"}
          rules={[{ required: true, message: "显示区域必选" }]}
        >
          <Select
            showSearch
            placeholder="请选择显示区域"
            optionFilterProp="children"
            style={{ textAlign: "left", width: 380 }}
          >
            {areaListOptions}
          </Select>
        </Form.Item>
        <Form.Item {...tailLayout} style={{ textAlign: "left" }}>
          <Button loading={submitLoading} type="primary" htmlType="submit">
            提交
          </Button>
        </Form.Item>
      </Form>
    </div>
  );
};
