import { useMutation, useQuery, useQueryClient } from "react-query";
import { CompanyType } from "../interfaces/company-type";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useCompanyTypeList = (param?: Partial<CompanyType>) => {
    const client = useHttp();
    const result = useQuery<any>(['companyTypePage', param], () => client('companyType', { data: cleanObject(param) }))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return { ...result, totalElements, data };
}

export const useEditCompanyType = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<CompanyType>) => client(`companyType`, {
            method: 'PUT',
            data: params
        }), {
        onSuccess: () => queryClient.invalidateQueries('companyTypePage')
    }
    )
}

export const useAddCompanyType = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<CompanyType>) => client(`companyType`, {
            data: params,
            method: 'POST'
        }), {
        onSuccess: () => queryClient.invalidateQueries('companyTypePage')
    }
    )
}

export const useDeleteCompanyType = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ companyTypeId }: { companyTypeId: number }) =>
            client(`companyType/${companyTypeId}`, {
                method: "DELETE",
            }), {
        onSuccess: () => queryClient.invalidateQueries('companyTypePage')
    }
    );
};

export const useCompanyType = (companyTypeId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['companyType', { companyTypeId }],
        () => client(`companyType/${companyTypeId}`),
        {
            enabled: Boolean(companyTypeId)
        }
    )
}

export const useCompanyTypeListAll = (cascadeType: number) => {
    const client = useHttp();
    return useQuery<any>(["companyTypeList", { cascadeType }], () =>
        client(`companyType/list?cascadeType=${cascadeType}`)
    );
};