import { useMemo } from "react";
import { useUrlQueryParam } from "../../utils/url";

export const useStoreAuthSearchParams = () => {
    const [param, setParam] = useUrlQueryParam(["storeAuthId", "storeAuthTimeStart", "storeAuthTimeEnd", "storeName", "storeAuthState", "memberNickname", "pageNum"]);
    return [
        useMemo(() => ({
            ...param, storeAuthId: Number(param.storeAuthId) || undefined, storeAuthState: Number(param.storeAuthState) || undefined,
            pageNum: Number(param.pageNum) || undefined
        }), [param]),
        setParam
    ] as const
}