import { useMemo } from "react";
import { useUrlQueryParam } from "../../utils/url";

export const useCompanyAuthSearchParams = () => {
    const [param, setParam] = useUrlQueryParam(["companyAuthId", "companyAuthTimeStart", "companyAuthTimeEnd", "companyName", "companyAuthState", "memberNickname", "pageNum"]);
    return [
        useMemo(() => ({
            ...param, companyAuthId: Number(param.companyAuthId) || undefined, companyAuthState: Number(param.companyAuthState) || undefined,
            pageNum: Number(param.pageNum) || undefined
        }), [param]),
        setParam
    ] as const
}