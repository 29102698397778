import { useMutation, useQuery, useQueryClient } from "react-query";
import { ReportClass } from "../interfaces/report-class";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useReportClassList = (param?: Partial<ReportClass>) => {
    const client = useHttp();
    const result = useQuery<any>(['reportClassPage', param], () => client('reportClass', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const numberOfElements = result.data?.data?.numberOfElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, numberOfElements, data };
}

export const useEditReportClass = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<ReportClass>) => client(`reportClass`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('reportClassPage')
        }
    )
}

export const useAddReportClass = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<ReportClass>) => client(`reportClass`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('reportClassPage')
        }
    )
}

export const useDeleteReportClass = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ reportClassId }: { reportClassId: number}) =>
            client(`reportClass/${reportClassId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('reportClassPage')
        }
    );
};

export const useReportClass = (reportClassId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['reportClass', {reportClassId}],
        () => client(`reportClass/${reportClassId}`),
        {
            enabled: Boolean(reportClassId)
        }
    )
}

export const useReportClassListAll = () => {
    const client = useHttp();
    return useQuery<any>(["reportClassList", {}], () =>
      client(`reportClass/list`)
    );
  };
