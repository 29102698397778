import { useMutation, useQuery, useQueryClient } from "react-query";
import { Hr } from "../interfaces/hr";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useHrList = (param?: Partial<Hr>) => {
    const client = useHttp();
    const result = useQuery<any>(['hrPage', param], () => client('hr', { data: cleanObject(param) }))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return { ...result, totalElements, data };
}

export const useDeleteHr = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ hrId }: { hrId: number }) =>
            client(`hr/${hrId}`, {
                method: "DELETE",
            }), {
        onSuccess: () => queryClient.invalidateQueries('hrPage')
    }
    );
};