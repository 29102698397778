import { Form, Input } from 'antd';
import { useState } from 'react';
import { SketchPicker } from 'react-color';

const ColorSelect = (props: any) => {

    let { label, name, color, setColor } = props;

    const [displayColor, setDisplayColor] = useState(false)

    const handleColorClick = () => {
        setDisplayColor(true)
    }
    const handleColorClose = () => {
        setDisplayColor(false)
    }

    const onChangeColor = (e: any) => {
        setColor(e?.hex)
        setDisplayColor(false)
    }

    return (
        <div>
            <Form.Item
                label={label}
                name={name}
                rules={[{ required: true, message: label + "必选" }]}
            >
                <Input style={{ width: '380px' }} placeholder={"请选择" + label} value={color} readOnly onClick={handleColorClick}></Input>
            </Form.Item>

            {displayColor ? (
                <div style={{ position: 'absolute', zIndex: 2, marginLeft: 300 }}>
                    <div style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0 }} onClick={handleColorClose} />
                    <SketchPicker width='250px' color={color} onChangeComplete={onChangeColor} />
                </div>
            ) : ''}
        </div>
    )
}

export default ColorSelect;