import { Button, Col, message, Modal, Row, Space, Table, Image } from "antd"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useBankAccountList, useDeleteBankAccount } from "../../services/bank-account-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useBankAccountSearchParams } from "./util"

export const BankAccountListPage = () => {
    useDocumentTitle('银行账号列表', true)
    const imgUrl = process.env.REACT_APP_IMG_URL;
    const [param, setParam] = useBankAccountSearchParams()
    const {isLoading, error, totalElements, data: list} = useBankAccountList(useDebounce(param, 200))

	//分页
	const paginationProps = {
	  showSizeChanger: true,
	  onShowSizeChange: (current: number, pageSize: number) => {
		setParam({
		  ...param,
		  pageNum: current - 1,
		  pageSize,
		});
	  },
	  defaultCurrent: 1,
	  pageSize: param.pageSize,
	  total: totalElements,
	  onChange: (current: number, pageSize: number) =>
		setParam({
		  ...param,
		  pageNum: current - 1,
		  pageSize,
		}),
	};
    //删除事件
    const { mutateAsync } = useDeleteBankAccount();
    const confirmDeleteBankAccount = (bankAccountId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ bankAccountId }).then((data) => {
                        if (data.status === 0) {
                            message.info('删除成功');
                            Modal.destroyAll();
                          } else {
                            message.error(data.msg)
                          }
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    //分页
    return (
        <div>
            <Row style={{marginBottom: 24}}>
                <Col span={12}>
                    <Link to={"/wallet/wallet-manage/agent-bank-account/add"}>
                        <Button type="primary">新建</Button>
                    </Link>
                </Col>
            </Row>
            <Table 
                loading = { isLoading }
                rowKey={ columns => columns.bankAccountId } 
                pagination = { paginationProps }
                scroll={{x:1500}}
            	dataSource={ list || [] }
                columns = {[
                {
                    title: 'ID',
                    dataIndex: 'bankAccountId',
                    key: 'bankAccountId',
                    align: 'center',
                    width: 100,
                },

                {
                    title: '银行',
                    dataIndex: 'bankName',
                    key: 'bankName',
                    width: 150,
                    align: 'center',
                },

                {
                    title: '图标',
                    dataIndex: 'bankLogo',
                    key: 'bankLogo',
                    width: 150,
                    align: 'center',
                    render:(value)=>(<><Image src={imgUrl + value}></Image></>)
                },

                {
                    title: '卡号',
                    dataIndex: 'bankAccountCardNo',
                    key: 'bankAccountCardNo',
                    width: 150,
                    align: 'center',
                },

                {
                    title: '用户名',
                    dataIndex: 'bankAccountTrueName',
                    key: 'bankAccountTrueName',
                    width: 150,
                    align: 'center',
                },

                {
                    title: '是否默认',
                    dataIndex: 'bankAccountDefault',
                    key: 'bankAccountDefault',
                    width: 150,
                    align: 'center',
                    render:(value)=>(<>{value?"是":"否"}</>)
                },

                {
                    title: '操作',
                    key: 'action',
                    align: 'center',
                    width: 200,
                    fixed:"right",
                    render: (value, bankAccount) => (
                        <Space size="middle">
                            <Link to={"/wallet/wallet-manage/agent-bank-account/update/" + bankAccount.bankAccountId}>
                                编辑
                            </Link>
                            <a onClick={() => confirmDeleteBankAccount(bankAccount.bankAccountId)}>
                                删除
                            </a>
                        </Space>
                    )
                }
            ]} />
            <ErrorBox error={ error } />
        </div>
    )
}