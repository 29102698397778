import { Button, Col, Divider, Form, Input, message, Radio, Row, Select, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEditTabBar, useTabBar } from "../../services/tab-bar-service";
import { useDocumentTitle } from "../../utils"
import UploadPic from '../../components/upload-pic';
import { tabBarTypeList } from "./util";
const { Option } = Select;

export const TabBarUpdatePage = () => {
    //标题
    useDocumentTitle('编辑标签栏', true)
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const tabBarId = Number(pathname.match(/dress\/decorate-manage\/tab-bar\/update\/(\d+)/)?.[1])

    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };

    //类型
    const tabBarTypeOptions = tabBarTypeList?.map((item: any) =>
        <Option key={item?.type} value={item?.type}>{item?.name}</Option>
    )

    //表单
    const [form] = useForm();
    const [tabBarPic, setTabBarPic] = useState("");
    //准备回显
    const { data: editingTabBar, isLoading } = useTabBar(tabBarId);
    useEffect(() => {
        form.setFieldsValue({ ...editingTabBar?.data });
        //封面
        if (editingTabBar?.data?.tabBarPic) {
            setTabBarPic(editingTabBar?.data?.tabBarPic)
        }
    }, [editingTabBar, form]);

    //新建
    const [submitLoading, setSubmitLoading] = useState(false);
    const { mutateAsync } = useEditTabBar();
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...editingTabBar?.data, ...values, tabBarPic }).then((data) => {
            if (data.status === 0) {
                message.success(data.msg);
                navigate('/dress/decorate-manage/tab-bar/list')
            } else {
                setSubmitLoading(false);
                message.error(data.msg);
            }
        }).catch(err => {
            message.error('提交失败，请重新提交');
            setSubmitLoading(false);
        });
    };

    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/dress/decorate-manage/tab-bar/list"}>
                        <Button type="primary">列表</Button>
                    </Link>
                </Col>
            </Row>

            <Divider style={{ marginBottom: 24 }} />

            <Spin spinning={isLoading}>
                <Form
                    form={form}
                    {...layout}
                    onFinish={onFinish}
                >

                    <Form.Item
                        label={"标题"}
                        name={"tabBarTitle"}
                        rules={[{ required: true, message: "标题必填" }]}
                    >
                        <Input style={{ width: 380 }} placeholder={"请输入标题"} />
                    </Form.Item>

                    <Form.Item
                        label={"副标题"}
                        name={"tabBarSubTitle"}
                    >
                        <Input style={{ width: 380 }} maxLength={30} placeholder={"请输入副标题"} />
                    </Form.Item>

                    <UploadPic
                        name={'uploadPic'}
                        label={'图标'}
                        pic={tabBarPic}
                        setPic={setTabBarPic}
                        action={'update'}
                        style={{ textAlign: 'left' }}
                    >
                    </UploadPic>

                    <Form.Item
                        label={"类型"}
                        name={"tabBarType"}
                        rules={[{ required: true, message: "类型必选" }]}
                    >
                        <Select
                            showSearch
                            allowClear
                            placeholder="请选择类型"
                            optionFilterProp="children"
                            style={{ textAlign: 'left', width: 380 }}
                        >
                            {tabBarTypeOptions}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label={"排序"}
                        name={"tabBarSort"}
                        rules={[{ required: true, message: "排序必填" }]}
                    >
                        <Input style={{ width: 380 }} placeholder={"请输入排序"} />
                    </Form.Item>

                    <Form.Item
                        label={"状态"}
                        name={"tabBarShow"}
                        rules={[{ required: true, message: "状态必选" }]}
                        style={{ textAlign: 'left' }}
                    >
                        <Radio.Group>
                            <Radio defaultChecked value={true}>显示</Radio>
                            <Radio value={false}>不显示</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item
                        label={"默认"}
                        name={"tabBarDefault"}
                        rules={[{ required: true, message: "是否默认必选" }]}
                        style={{ textAlign: 'left' }}
                    >
                        <Radio.Group>
                            <Radio value={true}>是</Radio>
                            <Radio defaultChecked value={false}>否</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                        <Button loading={submitLoading} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    )
}

