import { Button, Col, Divider, Form, Input, message, Radio, Row, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useRecruitNavigation, useEditRecruitNavigation } from "../../services/recruit-navigation-service";
import { useDocumentTitle } from "../../utils"
import UploadPic from '../../components/upload-pic';
import PageUrlSelect from "../../components/page-url-select";

export const RecruitNavigationUpdatePage = () => {
    //标题
    useDocumentTitle('编辑招聘导航', true)
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const recruitNavigationId = Number(pathname.match(/qiye\/recruit-manage\/recruit-navigation\/update\/(\d+)/)?.[1])

    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };

    //表单
    const [form] = useForm();
    const [recruitNavigationPic, setRecruitNavigationPic] = useState("");
    const [pageUrlId, setPageUrlId] = useState();

    //准备回显
    const { data: editingRecruitNavigation, isLoading } = useRecruitNavigation(recruitNavigationId);
    useEffect(() => {
        form.setFieldsValue({ ...editingRecruitNavigation?.data });
        //封面
        if (editingRecruitNavigation?.data?.recruitNavigationPic) {
            setRecruitNavigationPic(editingRecruitNavigation?.data?.recruitNavigationPic)
        }
        let pageUrlId = editingRecruitNavigation?.data?.pageUrlId;
        setPageUrlId(pageUrlId)
    }, [editingRecruitNavigation, form]);

    //新建
    const [submitLoading, setSubmitLoading] = useState(false);
    const { mutateAsync } = useEditRecruitNavigation();
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...editingRecruitNavigation?.data, ...values, recruitNavigationPic }).then((data) => {
            if (data.status === 0) {
                message.success(data.msg);
                navigate('/qiye/recruit-manage/recruit-navigation/list')
            } else {
                setSubmitLoading(false);
                message.error(data.msg);
            }
        }).catch(err => {
            message.error('提交失败，请重新提交');
            setSubmitLoading(false);
        });
    };

    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/qiye/recruit-manage/recruit-navigation/list"}>
                        <Button type="primary">列表</Button>
                    </Link>
                </Col>
            </Row>

            <Divider style={{ marginBottom: 24 }} />

            <Spin spinning={isLoading}>
                <Form
                    form={form}
                    {...layout}
                    onFinish={onFinish}
                >
                    <Form.Item
                        label={"名称"}
                        name={"recruitNavigationName"}
                        rules={[{ required: true, message: "名称必填" }]}
                    >
                        <Input style={{ width: 380 }} placeholder={"请输入名称"} />
                    </Form.Item>

                    <UploadPic
                        name={'uploadPic'}
                        label={'图片'}
                        pic={recruitNavigationPic}
                        setPic={setRecruitNavigationPic}
                        action={'update'}
                        style={{ textAlign: 'left' }}
                    >
                    </UploadPic>

                    <PageUrlSelect
                        pageUrlId={pageUrlId}
                    >
                    </PageUrlSelect>

                    <Form.Item
                        label={"排序"}
                        name={"recruitNavigationSort"}
                        rules={[{ required: true, message: "排序必填" }]}
                    >
                        <Input style={{ width: 380 }} placeholder={"请输入排序"} />
                    </Form.Item>

                    <Form.Item
                        label={"状态"}
                        name={"recruitNavigationShow"}
                        rules={[{ required: true, message: "状态必选" }]}
                        style={{ textAlign: 'left' }}
                    >
                        <Radio.Group>
                            <Radio value={true}>显示</Radio>
                            <Radio value={false}>不显示</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                        <Button loading={submitLoading} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    )
}

