import { useMutation, useQuery, useQueryClient } from "react-query";
import { Trends } from "../interfaces/trends";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useTrendsList = (param?: Partial<Trends>) => {
    const client = useHttp();
    const result = useQuery<any>(['trendsPage', param], () => client('trends', { data: cleanObject(param) }))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return { ...result, totalElements, data };
}

export const useShowTrends = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ trendsId }: { trendsId: number }) =>
            client(`trends/show?trendsId=${trendsId}`, {
                method: "POST",
            }), {
        onSuccess: () => queryClient.invalidateQueries('trendsPage')
    }
    );
};

export const useHideTrends = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ trendsId }: { trendsId: number }) =>
            client(`trends/hide?trendsId=${trendsId}`, {
                method: "POST",
            }), {
        onSuccess: () => queryClient.invalidateQueries('trendsPage')
    }
    );
};