import { Button, Col, Divider, Form, Input, message, Row } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { Link } from "react-router-dom";
import { useAddMemberTag } from "../../services/member-tag-service";
import { useDocumentTitle } from "../../utils"

export const MemberTagAddPage = () => {
    //标题
    useDocumentTitle('新建用户标签', true)
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    //表单
    const [form] = useForm();
    //新建
    const { mutateAsync, error } = useAddMemberTag();
    //提交表单
    const [submitLoading, setSubmitLoading] = useState(false);
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...values }).then(() => {
            message.info('新建成功');
            window.location.href = "/#/user/user-manage/member-tag/list";
        });
    };
    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/user/user-manage/member-tag/list"}>
                        <Button type="primary">列表</Button>
                    </Link>
                </Col>
            </Row>
            <Divider style={{ marginBottom: 24 }} />
            <Form
                form={form}
                {...layout}
                onFinish={onFinish}>
                <Form.Item
                    label={"名称"}
                    name={"memberTagName"}
                    rules={[{ required: true, message: "名称必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入名称"} />
                </Form.Item>

                <Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                    <Button loading={submitLoading} type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

