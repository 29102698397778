import { useMutation, useQuery, useQueryClient } from "react-query";
import { RecruitAdv } from "../interfaces/recruit-adv";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useRecruitAdvList = (param?: Partial<RecruitAdv>) => {
    const client = useHttp();
    const result = useQuery<any>(['recruitAdvPage', param], () => client('recruitAdv', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditRecruitAdv = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<RecruitAdv>) => client(`recruitAdv`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('recruitAdvPage')
        }
    )
}

export const useAddRecruitAdv = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<RecruitAdv>) => client(`recruitAdv`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('recruitAdvPage')
        }
    )
}

export const useDeleteRecruitAdv = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ recruitAdvId }: { recruitAdvId: number}) =>
            client(`recruitAdv/${recruitAdvId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('recruitAdvPage')
        }
    );
};

export const useRecruitAdv = (recruitAdvId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['recruitAdv', {recruitAdvId}],
        () => client(`recruitAdv/${recruitAdvId}`),
        {
            enabled: Boolean(recruitAdvId)
        }
    )
}
