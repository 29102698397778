import { useMutation, useQuery, useQueryClient } from "react-query";
import { BankAccount } from "../interfaces/bank-account";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useBankAccountList = (param?: Partial<BankAccount>) => {
    const client = useHttp();
    const result = useQuery<any>(['agentBankAccountPage', param], () => client('bankAccount/agent', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useAgentBankAccountListAll = () => {
    const client = useHttp();
    const result = useQuery<any>(['agentBankAccountListAll'], () => client('bankAccount/agent/listAll'))
    const data = result.data?.data;
    return {...result, data };
}

export const useEditBankAccount = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<BankAccount>) => client(`bankAccount/agent`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('agentBankAccountPage')
        }
    )
}

export const useAddBankAccount = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<BankAccount>) => client(`bankAccount/agent`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('agentBankAccountPage')
        }
    )
}

export const useDeleteBankAccount = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ bankAccountId }: { bankAccountId: number}) =>
            client(`bankAccount/agent/${bankAccountId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('agentBankAccountPage')
        }
    );
};

export const useBankAccount = (bankAccountId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['agentBankAccount', {bankAccountId}],
        () => client(`bankAccount/agent/${bankAccountId}`),
        {
            enabled: Boolean(bankAccountId)
        }
    )
}