import {
  Button,
  Col,
  Row,
  Space,
  Steps,
  Table,
  Typography,
  Image,
  Tabs,
  Select,
  message,
  Modal,
  Spin,
  Radio,
  Form,
  Input,
  InputNumber,
} from "antd";
import { useLocation } from "react-router";
import { useInitiativeRefund, useOrders } from "../../services/orders-service";
import { useOrdersAddress } from "../../services/orders-address-service";
import { useOrdersStore } from "../../services/orders-service";
import { useOrdersGoodsList } from "../../services/orders-goods-service";
import { useOrdersParcelList } from "../../services/orders-parcel-service";
import { useExpressList } from "../../services/express-service";
import { useAddOrdersParcelOnGoodsDetail } from "../../services/orders-parcel-service";
import dayjs from "dayjs";
import { useEffect, useState } from "react";
import { OrdersGoods } from "../../interfaces/orders-goods";
const { Text, Title } = Typography;
const { Step } = Steps;
const { TabPane } = Tabs;
const { Option } = Select;

export const OrdersDetailPage = () => {
  const imgUrl = process.env.REACT_APP_IMG_URL;
  //获取数据
  const { pathname } = useLocation();
  const ordersId = Number(pathname.match(/shop\/orders-manage\/orders\/detail\/(\d+)/)?.[1]);
  //订单详情
  const { data: orders, isLoading } = useOrders(Number(ordersId));
  //订单地址
  const { data: ordersAddress } = useOrdersAddress(Number(ordersId));
  //订单自提
  const { data: ordersStore } = useOrdersStore(Number(ordersId));
  //订单商品
  const { data: ordersGoodsList } = useOrdersGoodsList(Number(ordersId));
  //订单包裹
  const { data: ordersParcelList } = useOrdersParcelList(Number(ordersId));
  //进度条
  const [currentStep, setCurrentStep] = useState(Number);
  useEffect(() => {
    if (Number(orders?.data?.ordersState) === 10) {
      setCurrentStep(0);
    }
    if (Number(orders?.data?.ordersState) === 20) {
      setCurrentStep(1);
    }
    if (Number(orders?.data?.ordersState) === 30) {
      setCurrentStep(2);
    }
    if (Number(orders?.data?.ordersState) === 40) {
      setCurrentStep(3);
    }
  }, [orders]);
  //发货
  const [isModalVisible, setIsModalVisible] = useState(false);
  const [submitDeliveryLoading, setSubmitDeliveryLoading] = useState(false);
  //物流
  const { data: expressList } = useExpressList();
  const options = expressList?.map((item: any, value: any) => (
    <Option value={item.expressId} key={item.expressId}>
      {item.expressName}
    </Option>
  ));
  const showModal = () => {
    console.log("发货");
    setIsModalVisible(true);
  };
  const handleCancel = () => {
    setIsModalVisible(false);
  };
  //选择配送方式
  const [ordersParcelType, setOrdersParcelType] = useState(1);
  useEffect(() => {
    if (orders?.data?.ordersDeliveryType === 2) {
      setOrdersParcelType(3);
    }
  }, [orders]);
  const onChangeOrdersParcelType = (e: any) => {
    setOrdersParcelType(e.target.value);
  };
  //全选
  const [ordersGoodsIds, setOrdersGoodsIds] = useState<any>([]);
  const rowSelection = {
    onChange: (selectedRowKeys: React.Key[], selectedRows: OrdersGoods[]) => {
      setOrdersGoodsIds(selectedRowKeys);
    },
    getCheckboxProps: (record: OrdersGoods) => ({
      disabled: record.canDelivery === false,
    }),
  };
  const [selectionType, setSelectionType] = useState<"checkbox" | "radio">(
    "checkbox"
  );
  //物流公司选择
  const [expressId, setExpressId] = useState(0);
  function handleChangeExpress(value: any) {
    setExpressId(value);
  }
  //快递单号选择
  const [trackingNumber, setTrackingNumber] = useState("");
  function handleChangeTrackingNumber(e: any) {
    setTrackingNumber(e.target.value);
  }
  //取货码
  const [ordersPickSn, setOrdersPickSn] = useState("");
  function handleChangeOrdersPickSn(e: any) {
    setOrdersPickSn(e.target.value);
  }
  //发货
  const useMutateAddOrdersParcel = useAddOrdersParcelOnGoodsDetail;
  const { mutateAsync: mutateAsyncAddOrdersParcel } =
    useMutateAddOrdersParcel();
  const handleOk = () => {
    if (ordersParcelType === 1) {
      if (ordersGoodsIds.length === 0) {
        message.error("请至少选择一件商品");
      }
      if (expressId === 0) {
        message.error("请选择快递公司");
      }
      if (trackingNumber.length === 0) {
        message.error("请输入真实有效的物流单号");
      }
      if (
        ordersGoodsIds.length > 0 &&
        expressId > 0 &&
        trackingNumber.length > 0
      ) {
        setSubmitDeliveryLoading(true);
        mutateAsyncAddOrdersParcel({
          ordersId,
          ordersGoodsIds,
          expressId,
          trackingNumber,
          ordersParcelType,
        }).then(() => {
          setIsModalVisible(false);
          setSubmitDeliveryLoading(false);
          window.opener.location.href = window.opener.location.href;
        });
      }
    } else if (ordersParcelType === 3) {
      if (ordersGoodsIds.length === 0) {
        message.error("请至少选择一件商品");
      }
      if (ordersPickSn.length === 0) {
        message.error("请输入取货码");
      }
      if (ordersGoodsIds.length > 0 && ordersPickSn.length > 0) {
        setSubmitDeliveryLoading(true);
        mutateAsyncAddOrdersParcel({
          ordersId,
          ordersGoodsIds,
          expressId,
          ordersPickSn,
          ordersParcelType,
        }).then((res) => {
          if (res.status === 1) {
            message.error(res.msg);
            setSubmitDeliveryLoading(false);
          } else {
            setIsModalVisible(false);
            setSubmitDeliveryLoading(false);
          }
        });
      }
    } else {
      if (ordersGoodsIds.length === 0) {
        message.error("请至少选择一件商品");
      } else {
        //开始提交
        setSubmitDeliveryLoading(true);
        mutateAsyncAddOrdersParcel({
          ordersId,
          ordersGoodsIds,
          ordersParcelType,
        }).then(() => {
          setIsModalVisible(false);
          setSubmitDeliveryLoading(false);
          window.opener.location.href = window.opener.location.href;
        });
      }
    }
  };

  //主动退款
  const onInitiativeRefund = () => {
    Modal.confirm({
      title: "提示",
      content: "是否主动退款该订单",
      okText: "确定",
      onOk(): any {
        setRefundOpen(true);
      },
      cancelText: "取消",
    });
  };
  const [refundOpen, setRefundOpen] = useState(false);
  const [confirmRefundLoading, setConfirmRefundLoading] = useState(false);
  const { mutateAsync: anyncInitiativeRefund } = useInitiativeRefund();
  const onRefundFinish = (param: any) => {
    param.ordersId = orders?.data?.ordersId;
    setConfirmRefundLoading(true);
    return new Promise((resolve, reject) => {
      console.log("主动退款", param);
      anyncInitiativeRefund(param).then((res) => {
        console.log("success", res);
        message.info(res?.msg);
        if (res?.status === 0) {
          Modal.destroyAll();
          setRefundOpen(false);
        }
      }).catch((err) => {
        console.log("error", err);
        message.info(err?.data?.msg);
      }).finally(() => {
        setConfirmRefundLoading(false);
      });
    }).catch(
      () => console.log('Oops errors!')
    ).finally(() => {
      setConfirmRefundLoading(false);
    });
  };

  const handleRefundCancel = () => {
    setRefundOpen(false);
  };
  return (
    <div>
      <Modal
        confirmLoading={submitDeliveryLoading}
        centered
        bodyStyle={{ height: "100", overflowY: "auto" }}
        destroyOnClose={true}
        width={888}
        title="订单发货"
        visible={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
      >
        <Spin spinning={isLoading}>
          <Row style={{ marginBottom: 24 }}>
            <Table
              pagination={false}
              style={{ width: "100%" }}
              rowSelection={{
                type: selectionType,
                ...rowSelection,
              }}
              rowKey={(columns) => columns.ordersGoodsId}
              columns={[
                {
                  title: "商品",
                  dataIndex: "goodsName",
                  key: "goodsName",
                  width: "40%",
                  render: (value, item: any) => (
                    <Space size="middle">
                      <Row>
                        <Col span={6}>
                          <Image
                            width="100%"
                            src={imgUrl + item?.goodsPic}
                          />
                        </Col>
                        <Col span={18} style={{ paddingLeft: 10 }}>
                          <Row>
                            <Col span={24} style={{ marginBottom: 5 }}>
                              {item?.goodsName}
                            </Col>
                            <Col span={24} style={{ marginBottom: 5 }}>
                              规格：{item?.goodsSkuInfo}
                            </Col>
                          </Row>
                        </Col>
                      </Row>
                    </Space>
                  ),
                },
                {
                  title: "数量",
                  dataIndex: "goodsNum",
                  key: "goodsNum",
                  width: "20%",
                },
                {
                  title: "状态",
                  dataIndex: "goodsState",
                  key: "goodsState",
                  width: "20%",
                  render(goodsState) {
                    return (
                      <span>{goodsState === 30 ? "已发货" : "未发货"}</span>
                    );
                  },
                },
                {
                  title: "运单号",
                  dataIndex: "trackingNumber",
                  key: "trackingNumber",
                  width: "20%",
                },
              ]}
              dataSource={ordersGoodsList || []}
            />
          </Row>
          <Row style={{ marginBottom: 24 }}>
            <Col style={{ marginRight: 30 }}>配送信息</Col>
            <Col>
              {orders?.data?.ordersDeliveryType === 1 ? (
                <Space direction="vertical">
                  <Text>配送方式：快递</Text>
                  <Text>
                    收货人：{ordersAddress?.data?.ordersAddressReceiverName}{" "}
                    {ordersAddress?.data?.ordersAddressReceiverTel}
                  </Text>
                  <Text>
                    收货地址：{ordersAddress?.data?.ordersAddressProvince}
                    {ordersAddress?.data?.ordersAddressCity}
                    {ordersAddress?.data?.ordersAddressDistrict}
                    {ordersAddress?.data?.ordersAddressDetail}
                  </Text>
                </Space>
              ) : (
                ""
              )}
              {orders?.data?.ordersDeliveryType === 2 ? (
                <Space direction="vertical">
                  <Text>配送方式：到店</Text>
                  <Text>
                    联系信息：{orders?.data?.ordersReceiverName}{" "}
                    {orders?.data?.ordersReceiverTel}
                  </Text>
                  <Text>门店名称：{ordersStore?.data?.storeName} </Text>
                  <Text>
                    门店地址：{ordersStore?.data?.storeProvinceName}
                    {ordersStore?.data?.storeCityName}
                    {ordersStore?.data?.storeDistrictName}
                    {ordersStore?.data?.storeAddress}
                  </Text>
                </Space>
              ) : (
                ""
              )}
            </Col>
          </Row>
          <Row style={{ marginBottom: 24 }}>
            <Col style={{ marginRight: 30 }}>发货方式</Col>
            <Col>
              <Space direction="vertical">
                {orders?.data?.ordersDeliveryType === 1 ? (
                  <Radio.Group
                    onChange={onChangeOrdersParcelType}
                    value={ordersParcelType}
                  >
                    <Radio value={1}>快递配送</Radio>
                    <Radio value={2}>无需物流</Radio>
                  </Radio.Group>
                ) : (
                  ""
                )}
                {orders?.data?.ordersDeliveryType === 2 ? (
                  <Radio.Group
                    onChange={onChangeOrdersParcelType}
                    value={ordersParcelType}
                  >
                    <Radio value={3}>需要验证自提码</Radio>
                    <Radio value={4}>无需验证自提码</Radio>
                  </Radio.Group>
                ) : (
                  ""
                )}
              </Space>
            </Col>
          </Row>
          {ordersParcelType === 1 ? (
            <Row>
              <Col style={{ marginRight: 30, visibility: "hidden" }}>
                物流信息
              </Col>
              <Col>
                <Row style={{ marginBottom: 0 }}>
                  <Form.Item label={"物流公司"}>
                    <Select
                      onChange={handleChangeExpress}
                      showSearch
                      style={{ width: 200 }}
                      placeholder="请选择物流公司"
                      optionFilterProp="children"
                    >
                      {options}
                    </Select>
                  </Form.Item>
                </Row>
                <Row>
                  <Form.Item label={"物流单号"}>
                    <Input
                      onChange={handleChangeTrackingNumber}
                      style={{ width: 200 }}
                      placeholder={"输入真实有效的物流单号"}
                    />
                  </Form.Item>
                </Row>
              </Col>
            </Row>
          ) : (
            ""
          )}
          {ordersParcelType === 3 ? (
            <Row>
              <Col style={{ marginRight: 30, visibility: "hidden" }}>
                提货信息
              </Col>
              <Col>
                <Row>
                  <Form.Item label={"提货码"}>
                    <Input
                      onChange={handleChangeOrdersPickSn}
                      style={{ width: 200 }}
                      placeholder={"输入提货码"}
                    />
                  </Form.Item>
                </Row>
              </Col>
            </Row>
          ) : (
            ""
          )}
        </Spin>
      </Modal>
      <Row style={{ fontSize: 14, marginBottom: 24 }}>
        <Text style={{ marginRight: 30 }}>
          订单编号： {orders?.data?.ordersSn}
        </Text>
        <Text>
          申请时间：{" "}
          {orders?.data?.ordersCreateTime
            ? dayjs(orders?.data?.ordersCreateTime).format(
              "YYYY-MM-DD HH:mm:ss"
            )
            : "无"}
        </Text>
      </Row>
      <Row style={{ marginBottom: 24 }}>
        <Col
          span={8}
          style={{ width: "100%", padding: 20, border: "1px solid #f0f2f5" }}
        >
          <Title level={4}>
            {orders?.data?.ordersState === 0 ? "订单已关闭" : ""}
            {orders?.data?.ordersState === 10 ? "商品已拍下，等待买家付款" : ""}
            {orders?.data?.ordersState === 20 ? "等待商家发货" : ""}
            {orders?.data?.ordersState === 30 ? "商家已发货，等待交易成功" : ""}
            {orders?.data?.ordersState === 40 ? "交易完成" : ""}
          </Title>
          <Space direction="vertical" style={{ marginBottom: 20 }}>
            {orders?.data?.ordersState === 10 ? (
              <div>
                <Text>
                  如买家未在
                  {orders?.data?.ordersAutoCancelTime
                    ? dayjs(orders?.data?.ordersAutoCancelTime).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                    : "无"}
                  内付款，订单将按照设置逾期自动关闭
                </Text>
              </div>
            ) : (
              ""
            )}
            {orders?.data?.ordersState === 20 ? (
              <div>
                <Text>买家已付款，请尽快发货，否则买家有权申请退款</Text>
              </div>
            ) : (
              ""
            )}
            {orders?.data?.ordersState === 30 ? (
              <div>
                <Text>
                  买家如未在
                  {orders?.data?.ordersAutoConfirmTime
                    ? dayjs(orders?.data?.ordersAutoConfirmTime).format(
                      "YYYY-MM-DD HH:mm:ss"
                    )
                    : "无"}
                  内没有申请退款，交易将自动完成
                </Text>
              </div>
            ) : (
              ""
            )}
            {orders?.data?.ordersState === 40 ? (
              <div>
                <Text>交易完成</Text>
              </div>
            ) : (
              ""
            )}
          </Space>
          {orders?.data?.ordersState === 20 ? (
            <div>
              <Button
                onClick={() => showModal()}
                type="primary"
                style={{ marginRight: 10 }}
              >
                发货
              </Button>
            </div>
          ) : (
            ""
          )}
        </Col>

        <Col
          span={16}
          style={{
            padding: 20,
            borderTop: "1px solid #f0f2f5",
            borderRight: "1px solid #f0f2f5",
            borderBottom: "1px solid #f0f2f5",
          }}
        >
          <Steps current={currentStep}>
            <Step
              title="买家下单"
              description={
                orders?.data?.ordersCreateTime
                  ? dayjs(orders?.data?.ordersCreateTime).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                  : ""
              }
            />
            <Step
              title="买家付款"
              description={
                orders?.data?.ordersPaymentTime
                  ? dayjs(orders?.data?.ordersPaymentTime).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                  : ""
              }
            />
            <Step
              title="商家发货"
              description={
                orders?.data?.ordersShippingTime
                  ? dayjs(orders?.data?.ordersShippingTime).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                  : ""
              }
            />
            <Step
              title="交易成功"
              description={
                orders?.data?.ordersFinishedTime
                  ? dayjs(orders?.data?.ordersFinishedTime).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                  : ""
              }
            />
          </Steps>
        </Col>
      </Row>
      <Row style={{ backgroundColor: "#f0f2f5", marginBottom: 24 }}>
        <Col span={6} style={{ width: "100%", padding: 20 }}>
          {orders?.data?.ordersDeliveryType === 1 ? (
            <Space direction="vertical">
              <Text strong>收货人信息</Text>
              <Text>
                收货人：
                {ordersAddress?.data?.ordersAddressReceiverName
                  ? ordersAddress?.data?.ordersAddressReceiverName
                  : orders?.data?.ordersReceiverName}
              </Text>
              <Text>
                联系电话：
                {ordersAddress?.data?.ordersAddressReceiverTel
                  ? ordersAddress?.data?.ordersAddressReceiverTel
                  : orders?.data?.ordersReceiverTel}
              </Text>
              <Text>
                收货地址：{ordersAddress?.data?.ordersAddressProvince}
                {ordersAddress?.data?.ordersAddressCity}
                {ordersAddress?.data?.ordersAddressDistrict}
                {ordersAddress?.data?.ordersAddressDetail}
              </Text>
            </Space>
          ) : (
            ""
          )}
          {orders?.data?.ordersDeliveryType === 2 ? (
            <Space direction="vertical">
              <Text strong>收货人信息</Text>
              <Text>联系人：{orders?.data?.ordersReceiverName}</Text>
              <Text>联系电话：{orders?.data?.ordersReceiverTel}</Text>
            </Space>
          ) : (
            ""
          )}
        </Col>
        <Col span={6} style={{ width: "100%", padding: 20 }}>
          {orders?.data?.ordersDeliveryType === 1 ? (
            <Space direction="vertical">
              <Text strong>配送信息</Text>
              <Text>配送方式：快递</Text>
              <Text>
                发货时间：
                {orders?.data?.ordersShippingTime
                  ? dayjs(orders?.data?.ordersShippingTime).format(
                    "YYYY-MM-DD HH:mm:ss"
                  )
                  : "无"}
              </Text>
            </Space>
          ) : (
            ""
          )}
          {orders?.data?.ordersDeliveryType === 2 ? (
            <Space direction="vertical">
              <Text strong>配送信息</Text>
              <Text>配送方式：到店</Text>
              <Text>门店名称：{ordersStore?.data?.storeName}</Text>
              <Text>
                门店地址：{ordersStore?.data?.storeProvinceName}
                {ordersStore?.data?.storeCityName}
                {ordersStore?.data?.storeDistrictName}
                {ordersStore?.data?.storeAddress}
              </Text>
            </Space>
          ) : (
            ""
          )}
        </Col>
        <Col span={6} style={{ width: "100%", padding: 20 }}>
          <Space direction="vertical">
            <Text strong>付款信息</Text>
            <Text>实付金额：{orders?.data?.ordersActuallyAmount}</Text>
            <Text>优惠金额：{orders?.data?.ordersDiscount}</Text>
            <Text>
              使用优惠券：{orders?.data?.memberCouponId > 0 ? "是" : "否"}
            </Text>
            <Text>付款方式：{orders?.data?.ordersPaymentName}</Text>
            <Text>
              付款时间：
              {orders?.data?.ordersPaymentTime
                ? dayjs(orders?.data?.ordersPaymentTime).format(
                  "YYYY-MM-DD HH:mm:ss"
                )
                : "无"}
            </Text>
          </Space>
        </Col>
        <Col span={6} style={{ width: "100%", padding: 20 }}>
          <Space direction="vertical">
            <Text strong>买家信息</Text>
            <Text>买家：{orders?.data?.memberNickname}</Text>
            <Text>买家ID：{orders?.data?.memberId}</Text>
            <Text>买家留言：{orders?.data?.ordersMessage}</Text>
          </Space>
        </Col>
      </Row>
      <Row style={{ marginBottom: 24 }}>
        <Tabs type="card" style={{ width: "100%" }}>
          {ordersParcelList?.map((item: any, index: any, value: any) => (
            <TabPane tab={"包裹" + (index + 1)} key={index + 1}>
              <Row
                style={{
                  width: "100%",
                  padding: 20,
                  border: "1px solid #f0f2f5",
                }}
              >
                <Col span={8} style={{ padding: 20 }}>
                  <Col span={24} style={{ marginBottom: 10 }}>
                    <Space direction="vertical">
                      <Text>
                        发货方式：
                        {orders?.data?.ordersDeliveryType === 1 ? "快递" : ""}
                        {orders?.data?.ordersDeliveryType === 2 ? "到店" : ""}
                      </Text>
                      <Text>
                        发货时间：
                        {item?.ordersParcelAddTime
                          ? dayjs(item?.ordersParcelAddTime).format(
                            "YYYY-MM-DD HH:mm:ss"
                          )
                          : "无"}
                      </Text>
                      {item?.verificationStoreName ? (
                        <Text>
                          核销/发货门店：{item?.verificationStoreName}
                        </Text>
                      ) : (
                        ""
                      )}
                      {item?.verificationStoreAccountName ? (
                        <Text>
                          核销/发货账号：{item?.verificationStoreAccountName}
                        </Text>
                      ) : (
                        ""
                      )}
                      {item?.ordersParcelType === 1 ? (
                        <Text>快递公司：{item?.expressName}</Text>
                      ) : (
                        ""
                      )}
                      {item?.ordersParcelType === 1 ? (
                        <Text>快递单号：{item?.trackingNumber}</Text>
                      ) : (
                        ""
                      )}
                    </Space>
                  </Col>
                  <Col span={24}>
                    {item?.ordersGoodsList?.map(
                      (item: any, index: any, value: any) => (
                        <Image
                          style={{ paddingRight: 10 }}
                          width={60}
                          src={imgUrl + item.goodsPic}
                        />
                      )
                    )}
                  </Col>
                </Col>
                {/* confirmLogistics */}
                <Col span={16} style={{ padding: 20 }}>
                  {/* <Space direction="vertical">
                                            <Text>物流状态：<Link onClick={() => confirmLogistics(item?.ordersParcel?.ordersParcelId)}>查看</Link></Text>
                                            {logistics}
                                        </Space> */}
                </Col>
              </Row>
            </TabPane>
          ))}
        </Tabs>
      </Row>
      {orders?.data?.ordersState >= 20 ?

        <Row style={{ marginBottom: 24 }}>
          <Button type="primary" danger onClick={onInitiativeRefund}>
            主动退款
          </Button>
        </Row>
        : ""
      }
      <Row style={{ marginBottom: 24 }}>
        <Table
          pagination={false}
          style={{ width: "100%" }}
          columns={[
            {
              title: "商品",
              dataIndex: "goodsName",
              key: "goodsName",
              width: "30%",
              render: (value, item) => (
                <Space size="middle">
                  <Row>
                    <Col span={6}>
                      <Image
                        width="100%"
                        src={imgUrl + item.goodsPic}
                      />
                    </Col>
                    <Col span={18} style={{ paddingLeft: 10 }}>
                      <Row>
                        <Col span={24} style={{ marginBottom: 5 }}>
                          {item.goodsName}
                        </Col>
                        {item.goodsSkuInfo ? <Col span={24} style={{ marginBottom: 5 }}>规格：{item.goodsSkuInfo}</Col> : ''}
                      </Row>
                    </Col>
                  </Row>
                </Space>
              ),
            },
            {
              title: "单价(元)",
              dataIndex: "goodsPrice",
              key: "goodsPrice",
              width: "11.6%",
            },
            {
              title: "数量",
              dataIndex: "goodsNum",
              key: "goodsNum",
              width: "11.6%",
            },
            {
              title: "小计(元)",
              dataIndex: "goodsTotalPrice",
              key: "goodsTotalPrice",
              width: "11.6%",
            },
            {
              title: "售后状态",
              dataIndex: "ordersGoodsRefundState",
              key: "ordersGoodsRefundState",
              width: "11.6%",
              render(value, ordersGoods) {
                return (
                  <span>
                    {ordersGoods.ordersGoodsRefundState === 0 ? "无售后" : ""}
                    {ordersGoods.ordersGoodsRefundState === 10
                      ? "退款申请待商家处理"
                      : ""}
                    {ordersGoods.ordersGoodsRefundState === 20
                      ? "商家不同意退款申请，待买家处理"
                      : ""}
                    {ordersGoods.ordersGoodsRefundState === 30
                      ? "商家已同意退款申请，待买家发货"
                      : ""}
                    {ordersGoods.ordersGoodsRefundState === 40
                      ? "买家已退货，待商家确认收货"
                      : ""}
                    {ordersGoods.ordersGoodsRefundState === 50
                      ? "商家拒绝收货，待买家处理"
                      : ""}
                    {ordersGoods.ordersGoodsRefundState === 60 ? "退款关闭" : ""}
                    {ordersGoods.ordersGoodsRefundState === 70 ? "退款成功" : ""}
                    <br />
                    {ordersGoods.ordersGoodsRefundState === 70
                      ? "已退款金额：" + ordersGoods.goodsRefundMoney
                      : ""}
                  </span>
                );
              },
            },
            {
              title: "发货状态",
              dataIndex: "goodsState",
              key: "goodsState",
              width: "11.6%",
              render(value, ordersGoods) {
                return (
                  <span>{ordersGoods.goodsState === 30 ? "是" : "否"}</span>
                );
              },
            },
          ]}
          dataSource={ordersGoodsList || []}
        />
      </Row>
      <Row style={{ marginBottom: 5 }}>
        <Col span={22} style={{ textAlign: "right" }}>
          <Text>订单实付：</Text>
        </Col>
        <Col span={2} style={{ textAlign: "right" }}>
          <Text>￥{orders?.data?.ordersActuallyAmount}</Text>
        </Col>
      </Row>
      <Row style={{ marginBottom: 5 }}>
        <Col span={22} style={{ textAlign: "right" }}>
          <Text>订单总价：</Text>
        </Col>
        <Col span={2} style={{ textAlign: "right" }}>
          <Text>￥{orders?.data?.ordersTotalAmount}</Text>
        </Col>
      </Row>
      <Row style={{ marginBottom: 5 }}>
        <Col span={22} style={{ textAlign: "right" }}>
          <Text>商品总价：</Text>
        </Col>
        <Col span={2} style={{ textAlign: "right" }}>
          <Text>￥{orders?.data?.ordersGoodsAmount}</Text>
        </Col>
      </Row>
      <Row style={{ marginBottom: 5 }}>
        <Col span={22} style={{ textAlign: "right" }}>
          <Text>运费：</Text>
        </Col>
        <Col span={2} style={{ textAlign: "right" }}>
          <Text>￥{orders?.data?.ordersShippingFee}</Text>
        </Col>
      </Row>
      <Row style={{ marginBottom: 5 }}>
        <Col span={22} style={{ textAlign: "right" }}>
          <Text>优惠：</Text>
        </Col>
        <Col span={2} style={{ textAlign: "right" }}>
          <Text>￥{orders?.data?.ordersDiscount}</Text>
        </Col>
      </Row>
      {/* <Row style={{ marginBottom: 15 }}>
        <Col span={22} style={{ textAlign: "right" }}>
          <Text>余额：</Text>
        </Col>
        <Col span={2} style={{ textAlign: "right" }}>
          <Text>￥{orders?.data?.ordersMoneyAmount}</Text>
        </Col>
      </Row> */}
      <Row style={{ marginBottom: 5 }}>
        <Col span={22} style={{ textAlign: "right" }}>
          <Text style={{ fontWeight: 700, fontSize: 18 }}>线上实收：</Text>
        </Col>
        <Col span={2} style={{ textAlign: "right" }}>
          <Text style={{ fontWeight: 700, fontSize: 18, color: "#d40000" }}>
            ￥{orders?.data?.ordersReceiptsAmount}
          </Text>
        </Col>
      </Row>
      <Row style={{ marginBottom: 5 }}>
        <Col span={22} style={{ textAlign: "right" }}>
          <Text style={{ fontWeight: 700, fontSize: 18 }}>余额实收：</Text>
        </Col>
        <Col span={2} style={{ textAlign: "right" }}>
          <Text style={{ fontWeight: 700, fontSize: 18, color: "#d40000" }}>
            ￥{orders?.data?.ordersMoneyAmount}
          </Text>
        </Col>
      </Row>
      <Row style={{ marginBottom: 5 }}>
        <Col span={22} style={{ textAlign: "right" }}>
          <Text style={{ fontWeight: 700, fontSize: 18 }}>积分实收：</Text>
        </Col>
        <Col span={2} style={{ textAlign: "right" }}>
          <Text style={{ fontWeight: 700, fontSize: 18, color: "#d40000" }}>
            {orders?.data?.ordersActuallyPoints}积分
          </Text>
        </Col>
      </Row>
      <Modal
        title="主动退款"
        visible={refundOpen}
        onCancel={handleRefundCancel}
        okButtonProps={{ hidden: true }}
      >
        <Form
          name="basic"
          labelCol={{ span: 6 }}
          wrapperCol={{ span: 16 }}
          initialValues={{ remember: true }}
          onFinish={onRefundFinish}
          // onFinishFailed={onFinishFailed}
          autoComplete="off"
        >
          <Form.Item
            label="退款金额"
            name="ordersRefundMoney"
            rules={[{ required: true, message: "退款金额必填" }]}
            initialValue={orders?.data?.ordersActuallyAmount}
          >
            <InputNumber max={orders?.data?.ordersActuallyAmount} />
          </Form.Item>

          <Form.Item wrapperCol={{ offset: 6, span: 16 }}>
            <Button
              type="primary"
              htmlType="submit"
              loading={confirmRefundLoading}
            >
              提交
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
