import { useMutation, useQuery, useQueryClient } from "react-query";
import { PointsTask } from "../interfaces/points-task";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const usePointsTaskList = (param?: Partial<PointsTask>) => {
    const client = useHttp();
    const result = useQuery<any>(['pointsTaskPage', param], () => client('pointsTask', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditPointsTask = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<PointsTask>) => client(`pointsTask`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('pointsTaskPage')
        }
    )
}

export const useAddPointsTask = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<PointsTask>) => client(`pointsTask`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('pointsTaskPage')
        }
    )
}

export const useDeletePointsTask = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ pointsTaskId }: { pointsTaskId: number}) =>
            client(`pointsTask/${pointsTaskId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('pointsTaskPage')
        }
    );
};

export const usePointsTask = (pointsTaskId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['pointsTask', {pointsTaskId}],
        () => client(`pointsTask/${pointsTaskId}`),
        {
            enabled: Boolean(pointsTaskId)
        }
    )
}