import { Layout, Row, Collapse, Image } from "antd";
import {
  UserOutlined,
  SkinOutlined,
  AccountBookOutlined,
  DesktopOutlined,
  HomeOutlined,
  SettingOutlined,
  LineChartOutlined,
  ShoppingOutlined,
  ApartmentOutlined,
  BankOutlined,
  CopyrightOutlined,
  GatewayOutlined,
  ShareAltOutlined,
  SketchOutlined,
  WalletOutlined
} from "@ant-design/icons";
import logo from "../assets/inner-logo.jpeg";
import { Link, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useAdminAuthorityList } from '../services/admin-authority-service';
import "../layouts/sider.css";
const { Panel } = Collapse;
const { Sider } = Layout;

export const SiderLayout = () => {
  const { data: authorityList } = useAdminAuthorityList() // 接口数据
  const { pathname } = useLocation();
  const paths = pathname.split("/");
  const selectedKeys1 = paths[1];
  const selectedKeys2 = paths[2];
  const selectedKeys3 = paths[3];
  //鼠标移入
  const [itemObj, setItemObj] = useState<any>(); //下级对象
  const [adminAuthorityKey, setAdminAuthorityKey] = useState<any>();
  const onMouseOver = (obj: any) => {
    if (obj && obj?.adminAuthorityVOList?.length) {
      setItemObj({ ...obj });
      setAdminAuthorityKey(obj.adminAuthorityKey);
    } else {
      setItemObj("");
      setAdminAuthorityKey("");
    }
  };
  //   鼠标移开
  const onMouseLeave = () => {
    if (selectedKeys1 && selectedKeys2) {
      authorityList?.data?.forEach((item: any) => {
        // adminAuthority?.forEach((item: any) => {
        if (
          item.adminAuthorityKey === selectedKeys1 &&
          item?.adminAuthorityVOList?.length
        ) {
          setItemObj({ ...item });
        }
      });
    } else {
      setItemObj("");
    }
    setAdminAuthorityKey("");
  };
  useEffect(() => {
    if (selectedKeys1) {
      authorityList?.data?.forEach((item: any) => {
        // adminAuthority?.forEach((item: any) => {
        if (
          item.adminAuthorityKey &&
          item.adminAuthorityKey === selectedKeys1 &&
          item?.adminAuthorityVOList?.length
        ) {
          setItemObj({ ...item });
        }
      });
    } else {
      setItemObj("");
    }
  }, [authorityList?.data, selectedKeys1]);
  return (
    <Sider width={itemObj && selectedKeys2 ? 220 : 90}>
      <Row>
        <aside
          id="shared-sidebar"
          onMouseLeave={onMouseLeave}
          className="super-store"
        >
          <nav role="main" id="shared-first-sidebar">
            <div id="shared-team-logo">
              <Image
                preview={false}
                src={logo}
                style={{ width: "40px", height: "40px", borderRadius: "20px" }}
              />
            </div>
            <ul id="shared-first-sidebar-nav">
              <li
                onMouseOver={onMouseOver}
                className={selectedKeys1 === "dashboard" ? "active" : ""}
              >
                <Link to={"/dashboard"}>
                  <DesktopOutlined style={{ marginRight: 5 }} />
                  概况
                </Link>
              </li>
              {authorityList?.data?.map((item: any) => (
                // {adminAuthority?.map((item: any) => (
                <li
                  className={
                    item.adminAuthorityKey === selectedKeys1 ? "active" : ""
                  }
                  id={
                    adminAuthorityKey === item?.adminAuthorityKey
                      ? "authorityKey"
                      : ""
                  }
                  onMouseOver={() => onMouseOver(item)}
                  key={item.adminAuthorityId}
                >
                  <Link
                    to={
                      !item.adminAuthorityVOList.length
                        ? item?.adminAuthorityUrl || ""
                        : !item?.adminAuthorityVOList[0]?.adminAuthorityVOList
                          .length
                          ? item?.adminAuthorityVOList[0].adminAuthorityUrl || ""
                          : item?.adminAuthorityVOList[0]?.adminAuthorityVOList[0]
                            .adminAuthorityUrl || ""
                    }
                  >
                    {item.adminAuthorityKey === "dress" ? (
                      <SkinOutlined style={{ marginRight: 5 }} />
                    ) : item.adminAuthorityKey === "xuexiao" ? (
                      <BankOutlined style={{ marginRight: 5 }} />
                    ) : item.adminAuthorityKey === "qiye" ? (
                      <CopyrightOutlined style={{ marginRight: 5 }} />
                    ) : item.adminAuthorityKey === "jigou" ? (
                      <GatewayOutlined style={{ marginRight: 5 }} />
                    ) : item.adminAuthorityKey === "product" ? (
                      <HomeOutlined style={{ marginRight: 5 }} />
                    ) : item.adminAuthorityKey === "shop" ? (
                      <ShoppingOutlined style={{ marginRight: 5 }} />
                    ) : item.adminAuthorityKey === "daren" ? (
                      <ShareAltOutlined style={{ marginRight: 5 }} />
                    ) : item.adminAuthorityKey === "agent" ? (
                      <ApartmentOutlined style={{ marginRight: 5 }} />
                    ) : item.adminAuthorityKey === "taocan" ? (
                      <SketchOutlined style={{ marginRight: 5 }} />
                    ) : item.adminAuthorityKey === "user" ? (
                      <UserOutlined style={{ marginRight: 5 }} />
                    ) : item.adminAuthorityKey === "money" ? (
                      <AccountBookOutlined style={{ marginRight: 5 }} />
                    ) : item.adminAuthorityKey === "count" ? (
                      <LineChartOutlined style={{ marginRight: 5 }} />
                    ) : item.adminAuthorityKey === "setting" ? (
                      <SettingOutlined style={{ marginRight: 5 }} />
                    ) : item.adminAuthorityKey === "wallet" ? (
                      <WalletOutlined style={{ marginRight: 5 }} />
                    ) : (
                      ""
                    )}
                    {item.adminAuthorityName}
                  </Link>
                </li>
              ))}
            </ul>
          </nav>
          {itemObj ? (
            <nav id="shared-second-sidebar" className="shared-second-sidebar">
              <h2 className="second-sidebar-title">
                {itemObj?.adminAuthorityName}
              </h2>
              <ul
                className="shared-second-sidebar-nav"
                style={{ visibility: "visible" }}
              >
                <Collapse
                  defaultActiveKey={[
                    "0",
                    "1",
                    "2",
                    "3",
                    "4",
                    "5",
                    "6",
                    "7",
                    "8",
                    "9",
                  ]}
                  ghost
                >
                  {itemObj?.adminAuthorityVOList?.map((item: any, index: any) =>
                    item?.adminAuthorityVOList?.length ? (
                      <Panel
                        style={{ padding: 0 }}
                        header={item?.adminAuthorityName}
                        key={index}
                      >
                        {item?.adminAuthorityVOList?.map((itemi: any) => (
                          <li
                            className={
                              selectedKeys3 === itemi.adminAuthorityKey
                                ? "active second-nav-item"
                                : "second-nav-item"
                            }
                            key={itemi.adminAuthorityId}
                          >
                            <Link to={itemi?.adminAuthorityUrl}>
                              {itemi?.adminAuthorityName}
                            </Link>
                          </li>
                        ))}
                      </Panel>
                    ) : (
                      <li
                        className={
                          selectedKeys2 === item.adminAuthorityKey
                            ? "active second-nav-item"
                            : "second-nav-item"
                        }
                        key={item.adminAuthorityId}
                      >
                        <Link to={item?.adminAuthorityUrl}>
                          {item?.adminAuthorityName}
                        </Link>
                      </li>
                    )
                  )}
                </Collapse>
                <div className="divider"></div>
              </ul>
            </nav>
          ) : (
            ""
          )}
          <div id="shared-second-sidebar-toggle"></div>
        </aside>
      </Row>
    </Sider>
  );
};
