import { useMutation, useQuery, useQueryClient } from "react-query";
import { OrdersBatchDelivery } from "../interfaces/orders-batch-delivery";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useOrdersBatchDeliveryList = (param?: Partial<OrdersBatchDelivery>) => {
    const client = useHttp();
    const result = useQuery<any>(['ordersBatchDeliveryList', param], () => client('ordersBatchDelivery', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useAddOrdersBatchDelivery = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<OrdersBatchDelivery>) => client(`ordersBatchDelivery`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('ordersBatchDeliveryList')
        }
    )
}