import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHttp } from "../utils/http";
import { MoneyLog } from "../interfaces/money-log";
import { cleanObject } from "../utils";

//加
export const useChangeMoneyLog = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<MoneyLog>) => client(`moneyLog`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('memberPage')
        }
    )
}

//列表
export const useMoneyLogList = (param?: Partial<MoneyLog>) => {
    const client = useHttp();
    const result = useQuery<any>(['moneyLogPage', param], () => client('moneyLog', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

//列表
export const useAgentMoneyLogList = (param?: Partial<MoneyLog>) => {
    const client = useHttp();
    const result = useQuery<any>(['moneyLogPageAgent', param], () => client('moneyLog/agent', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}
