import {
  Button,
  Card,
  Col,
  Form,
  Row,
  Table,
  DatePicker,
  Select,
  Input,
  Modal,
  message,
} from "antd";
import { ManOutlined, WomanOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { useState } from "react";
import { ErrorBox } from "../../components/lib";
import { useSchoolAppointList } from "../../services/school-appoint-service";
import { useSchoolListAll } from "../../services/school-service";
import { useDebounce, useDocumentTitle } from "../../utils";
import { useSchoolAppointSearchParams } from "../school-appoint/util";
import { useAuth } from "../../context/auth-context";
const { RangePicker } = DatePicker;
const { Option } = Select;

export const SchoolAppointListPage = () => {
  useDocumentTitle("预约探校列表", true);
  const data = useAuth();
  const apiUrl = process.env.REACT_APP_API_URL;
  const [param, setParam] = useSchoolAppointSearchParams();
  const {
    isLoading,
    error,
    totalElements,
    data: list,
  } = useSchoolAppointList(useDebounce(param, 200));

  //分页
  const paginationProps = {
    defaultCurrent: 1,
    pageSize: 10,
    total: totalElements,
    onChange: (current: number) =>
      setParam({
        ...param,
        pageNum: current - 1,
      }),
  };

  //学校
  const { data: schoolList } = useSchoolListAll();
  const schoolOptions = schoolList?.data?.map((item: any) => (
    <Option value={item.schoolId}>{item.schoolName}</Option>
  ));

  //时间
  const [schoolAppointAddTimeStart, setSchoolAppointAddTimeStart] =
    useState("");
  const [schoolAppointAddTimeEnd, setSchoolAppointAddTimeEnd] = useState("");
  const handleSelectTime = (value: any, dateString: any) => {
    setSchoolAppointAddTimeStart(dateString[0]);
    setSchoolAppointAddTimeEnd(dateString[1]);
  };

  const [op, setOp] = useState(1);
  function changeOpSubmit(e: number) {
    setOp(e);
  }

  //提交
  const onFinish = (values: any) => {
    if (op === 1) {
      setParam({
        ...param,
        schoolAppointName: values.schoolAppointName,
        schoolAppointTel: values.schoolAppointTel,
        schoolId: values.schoolId,
        schoolAppointAddTimeStart: schoolAppointAddTimeStart,
        schoolAppointAddTimeEnd: schoolAppointAddTimeEnd,
        pageNum: null,
      });
    } else if (op === 2) {
      let schoolAppointName = values.schoolAppointName;
      let schoolAppointTel = values.schoolAppointTel;
      let schoolId = values.schoolId;
      let token = data?.adminAccount?.token;
      if (schoolAppointName === undefined) {
        schoolAppointName = "";
      }
      if (schoolAppointTel === undefined) {
        schoolAppointTel = "";
      }
      if (schoolId === undefined) {
        schoolId = "";
      }
      if (token === undefined) {
        token = "";
      }
      Modal.confirm({
        title: "确定导出当前列表数据吗?",
        content: "点击确定导出",
        okText: "确定",
        onOk(): any {
          return new Promise((resolve, reject) => {
            window.location.href =
              apiUrl +
              `/schoolAppoint/export?schoolAppointName=${schoolAppointName}&schoolAppointTel=${schoolAppointTel}&schoolId=${schoolId}&schoolAppointAddTimeStart=${schoolAppointAddTimeStart}&schoolAppointAddTimeEnd=${schoolAppointAddTimeEnd}&token=${token}`;
            message.success("导出成功");
            Modal.destroyAll();
          }).catch(() => console.log("Oops errors!"));
        },
        cancelText: "取消",
      });
    }
  };

  //分页
  return (
    <div>
      <Card style={{ backgroundColor: "#f2f3f5", margin: "24px 0 24px 0" }}>
        <Form onFinish={onFinish}>
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item name="schoolAppointAddTimeRange" label="创建时间">
                <RangePicker
                  onChange={handleSelectTime}
                  showTime
                  format="YYYY-MM-DD HH:mm:ss"
                  style={{ width: "100%" }}
                  allowClear
                />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="schoolId" label="预约学校">
                <Select
                  placeholder="请选择预约学校"
                  showSearch
                  allowClear
                  optionFilterProp="children"
                >
                  {schoolOptions}
                </Select>
              </Form.Item>
            </Col>
          </Row>
          <Row gutter={20}>
            <Col span={8}>
              <Form.Item name="schoolAppointName" label="预约人姓名">
                <Input placeholder="请输入预约人姓名" allowClear />
              </Form.Item>
            </Col>
            <Col span={8}>
              <Form.Item name="schoolAppointTel" label="预约人手机">
                <Input placeholder="请输入预约人手机" allowClear />
              </Form.Item>
            </Col>
          </Row>
          <Form.Item style={{ marginBottom: 0 }}>
            <Button
              onClick={() => changeOpSubmit(1)}
              style={{ marginLeft: 70 }}
              type="primary"
              htmlType="submit"
            >
              提交
            </Button>
            <Button
              onClick={() => changeOpSubmit(2)}
              style={{ marginLeft: 20 }}
              type="primary"
              htmlType="submit"
            >
              导出
            </Button>
          </Form.Item>
        </Form>
      </Card>
      <Table
        loading={isLoading}
        rowKey={(columns) => columns.schoolAppointId}
        pagination={paginationProps}
        columns={[
          {
            title: "ID",
            dataIndex: "schoolAppointId",
            key: "schoolAppointId",
            align: "center",
            width: 100,
            fixed: "left",
          },
          {
            title: "姓名",
            dataIndex: "schoolAppointName",
            key: "schoolAppointName",
            align: "center",
            width: 200,
            fixed: "left",
            render: (value, schoolAppoint) => (
              <div>
                <div>
                  {value}
                  {schoolAppoint.schoolAppointSex === 1 ? (
                    <ManOutlined
                      style={{ marginLeft: "5px", color: "#1890ff" }}
                    />
                  ) : schoolAppoint.schoolAppointSex === 2 ? (
                    <WomanOutlined
                      style={{ marginLeft: "5px", color: "#d9251b" }}
                    />
                  ) : (
                    ""
                  )}
                </div>
                <div>{schoolAppoint.schoolAppointTel}</div>
              </div>
            ),
          },
          {
            title: "意向专业",
            dataIndex: "majorName",
            key: "majorName",
            align: "center",
            width: 200,
          },
          {
            title: "所在地区",
            dataIndex: "schoolAppointAddress",
            key: "schoolAppointAddress",
            align: "center",
            width: 200,
            render: (value, schoolAppoint) =>
              schoolAppoint.provinceAreaName ||
              schoolAppoint.cityAreaName ||
              schoolAppoint.districtAreaName ||
              schoolAppoint.schoolAppointAddress ? (
                <div>
                  <span>
                    {schoolAppoint.provinceAreaName}
                    {schoolAppoint.cityAreaName}
                    {schoolAppoint.districtAreaName}
                  </span>
                  <br />
                  <span>{value}</span>
                </div>
              ) : (
                "-"
              ),
          },
          {
            title: "家长姓名",
            dataIndex: "schoolAppointParent",
            key: "schoolAppointParent",
            align: "center",
            width: 200,
          },
          {
            title: "毕业院校",
            dataIndex: "schoolAppointGraduate",
            key: "schoolAppointGraduate",
            align: "center",
            width: 250,
          },
          {
            title: "预约学校",
            dataIndex: "schoolName",
            key: "schoolName",
            align: "center",
            width: 250,
          },
          {
            title: "用户昵称",
            dataIndex: "memberNickname",
            key: "memberNickname",
            align: "center",
            width: 150,
          },
          {
            title: "创建时间",
            dataIndex: "schoolAppointAddTime",
            key: "schoolAppointAddTime",
            align: "center",
            fixed: "right",
            width: 200,
            render: (value) =>
              value ? dayjs(value).format("YYYY-MM-DD HH:mm:ss") : "-",
          },
        ]}
        dataSource={list || []}
        scroll={{ x: 1550 }}
      />
      <ErrorBox error={error} />
    </div>
  );
};
