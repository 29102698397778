import { Button, Form, InputNumber, message, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { useSchoolOrdersSetting, useEditSchoolOrdersSetting } from "../../services/school-orders-setting-service";
import { useDocumentTitle } from "../../utils"

export const SchoolOrdersSettingIndexPage = () => {
    //标题
    useDocumentTitle('学费设置', true)
    
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };

    //表单
    const [form] = useForm();

    //准备回显数据
    const { data: editingSchoolOrdersSetting, isLoading } = useSchoolOrdersSetting();
    useEffect(() => {
        form.setFieldsValue(editingSchoolOrdersSetting?.data);
    }, [editingSchoolOrdersSetting, form]);

    //编辑
    const useMutateEditSchoolOrdersSetting = useEditSchoolOrdersSetting;
    const { mutateAsync } = useMutateEditSchoolOrdersSetting();
    //提交表单
    const [submitLoading, setSubmitLoading] = useState(false);
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...editingSchoolOrdersSetting?.data, ...values }).then(() => {
            message.info('编辑成功');
            setSubmitLoading(false);
        });
    };

    return (
        <div>
            <Spin spinning={isLoading}>
                <Form
                    form={form}
                    {...layout}
                    onFinish={onFinish}
                    style={{ marginTop: 24 }}
                >

                    <Form.Item
                        label={"平台抽成"}
                        name={"schoolOrdersSettingSystemRatio"}
                        rules={[{ required: true, message: "平台抽成必填" }]}
                    >
                        <InputNumber style={{ width: 380 }} min={0} step={0.01} placeholder="请输入平台抽成" addonAfter={'%'} />
                    </Form.Item>

                    <Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                        <Button loading={submitLoading} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    )
}

