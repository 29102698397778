import { Button, Card, Col, Form, message, Modal, Row, Space, Table, DatePicker, Select, Input, Image, Tag } from "antd"
import dayjs from "dayjs"
import { useState } from "react"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useCompanyTagListAll } from "../../services/company-tag-service"
import { useCompanyAttributeListAll } from "../../services/company-attribute-service"
import { useCompanyList, useDeleteCompany } from "../../services/company-service"
import { useCompanyTypeListAll } from "../../services/company-type-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useCompanySearchParams } from "./util"
import picNone from "../../assets/pic-none.png"
import { useAuth } from "../../context/auth-context"
import { useResetIdentityAccount } from "../../services/identity-account-service"
const { RangePicker } = DatePicker
const { Option } = Select;

export const CompanyListPage = () => {
    useDocumentTitle('企业列表', true)
    const data = useAuth();
    const apiUrl = process.env.REACT_APP_API_URL;
    const [param, setParam] = useCompanySearchParams()
    const { isLoading, error, totalElements, data: list } = useCompanyList(useDebounce(param, 200))

    const imgUrl = process.env.REACT_APP_IMG_URL;

    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) =>
            setParam({
                ...param,
                pageNum: (current - 1),
            })
    }

    //企业性质
    const { data: companyAttributeList } = useCompanyAttributeListAll();
    const companyAttributeOptions = companyAttributeList?.data?.map((item: any) =>
        <Option value={item.companyAttributeId}>{item.companyAttributeName}</Option>
    )

    //企业类型
    const { data: companyTypeList } = useCompanyTypeListAll(1);
    const companyTypeOptions = companyTypeList?.data?.map((item: any) =>
        <Option value={item?.value}>{item?.label}</Option>
    )

    //企业标签
    const { data: companyTagList } = useCompanyTagListAll();
    const companyTagOptions = companyTagList?.data?.map((item: any) =>
        <Option value={item.companyTagId}>{item.companyTagName}</Option>
    )

    //时间
    const [companyAddTimeStart, setCompanyAddTimeStart] = useState('');
    const [companyAddTimeEnd, setCompanyAddTimeEnd] = useState('');
    const handleSelectTime = (value: any, dateString: any) => {
        setCompanyAddTimeStart(dateString[0]);
        setCompanyAddTimeEnd(dateString[1]);
    }

    const [op, setOp] = useState(1);
    function changeOpSubmit(e: number) {
        setOp(e);
    }
    //提交
    const onFinish = (values: any) => {
        if (op === 1) {
            setParam({
                ...param,
                companyName: values.companyName,
                companyAttributeId: values.companyAttributeId,
                companyTypeParentId: values.companyTypeParentId,
                companyTagId: values.companyTagId,
                companyAddTimeStart: companyAddTimeStart,
                companyAddTimeEnd: companyAddTimeEnd,
                pageNum: null
            })
        } else if (op === 2) {
            let companyName = values.companyName;
            let companyAttributeId = values.companyAttributeId;
            let companyTypeParentId = values.companyTypeParentId;
            let companyTagId = values.companyTagId;
            let token = data?.adminAccount?.token;
            if (companyName === undefined) {
                companyName = "";
            }
            if (companyAttributeId === undefined) {
                companyAttributeId = "";
            }
            if (companyTypeParentId === undefined) {
                companyTypeParentId = "";
            }
            if (companyTagId === undefined) {
                companyTagId = "";
            }
            if (token === undefined) {
                token = "";
            }
            Modal.confirm({
                title: "确定导出当前列表数据吗?",
                content: "点击确定导出",
                okText: "确定",
                onOk(): any {
                    return new Promise((resolve, reject) => {
                        window.location.href = apiUrl + `/company/export?companyName=${companyName}&companyAttributeId=${companyAttributeId}&companyTypeParentId=${companyTypeParentId}&companyTagId=${companyTagId}&companyAddTimeStart=${companyAddTimeStart}&companyAddTimeEnd=${companyAddTimeEnd}&token=${token}`;
                        message.success("导出成功");
                        Modal.destroyAll();
                    }).catch(() => console.log("Oops errors!"));
                },
                cancelText: "取消",
            });
        }
    }

    //删除事件
    const { mutateAsync: deleteMutateAsync } = useDeleteCompany();
    const confirmDeleteCompany = (companyId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    deleteMutateAsync({ companyId }).then((data) => {
                        if (data.status === 0) {
                            message.info('删除成功');
                            Modal.destroyAll();
                        } else {
                            message.error(data.msg)
                        }
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };

    //重置密码
    const { mutateAsync: resetIdentityAccount } = useResetIdentityAccount();
    const confirmResetIdentityAccount = (identityType: number, identityId: number) => {
        Modal.confirm({
            title: "确定操作这条数据吗?",
            content: "点击确定取消",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    resetIdentityAccount({ identityType, identityId }).then((data) => {
                        if (data.status === 0) {
                            message.info("操作成功");
                            Modal.destroyAll();
                        } else {
                            message.error(data.msg);
                        }
                    });
                }).catch(() => console.log("Oops errors!"));
            },
            cancelText: "取消",
        });
    };

    //分页
    return (
        <div>
            <Card style={{ backgroundColor: "#f2f3f5", margin: '24px 0 24px 0' }}>
                <Form onFinish={onFinish}>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item name="companyAddTimeRange" label="创建时间">
                                <RangePicker onChange={handleSelectTime} showTime format="YYYY-MM-DD HH:mm:ss" style={{ width: '100%' }} allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="companyName" label="企业名称">
                                <Input placeholder='请输入企业名称' allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="companyAttributeId" label="企业性质">
                                <Select
                                    placeholder='请选择企业性质'
                                    showSearch
                                    allowClear
                                    optionFilterProp='children'
                                >
                                    {companyAttributeOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item name="companyTypeParentId" label="企业行业">
                                <Select
                                    placeholder='请选择企业行业'
                                    showSearch
                                    allowClear
                                    optionFilterProp='children'
                                >
                                    {companyTypeOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="companyTagId" label="企业福利">
                                <Select
                                    placeholder='请选择企业福利'
                                    showSearch
                                    allowClear
                                    optionFilterProp='children'
                                >
                                    {companyTagOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item style={{ marginBottom: 0 }}>
                        <Button
                            onClick={() => changeOpSubmit(1)}
                            style={{ marginLeft: 70 }}
                            type="primary"
                            htmlType="submit"
                        >
                            提交
                        </Button>
                        <Button
                            onClick={() => changeOpSubmit(2)}
                            style={{ marginLeft: 20 }}
                            type="primary"
                            htmlType="submit"
                        >
                            导出
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
            <Table
                loading={isLoading}
                rowKey={columns => columns.companyId}
                pagination={paginationProps}
                columns={[
                    {
                        title: 'ID',
                        dataIndex: 'companyId',
                        key: 'companyId',
                        align: 'center',
                        width: 100,
                        fixed: 'left',
                    },
                    {
                        title: 'LOGO',
                        dataIndex: 'companyLogo',
                        key: 'companyLogo',
                        align: 'center',
                        width: 100,
                        fixed: 'left',
                        render: (value) => (
                            value ? <Image src={imgUrl + value} width={60} /> : <Image src={picNone} width={60} />
                        )
                    },
                    {
                        title: '名称',
                        dataIndex: 'companyName',
                        key: 'companyName',
                        align: 'center',
                        width: 200,
                        fixed: 'left',
                    },
                    {
                        title: '地址',
                        dataIndex: 'companyAddress',
                        key: 'companyAddress',
                        align: 'center',
                        width: 300,
                        render: (value, company) => (
                            company.provinceAreaName || company.cityAreaName || company.districtAreaName ? (
                                <div>
                                    <span>{company.provinceAreaName}{company.cityAreaName}{company.districtAreaName}</span><br />
                                    <span>{value}</span>
                                </div>
                            ) : ('-')
                        )
                    },
                    {
                        title: '性质',
                        dataIndex: 'companyAttributeName',
                        key: 'companyAttributeName',
                        align: 'center',
                        width: 120,
                    },
                    {
                        title: '行业',
                        dataIndex: 'companyTypeName',
                        key: 'companyTypeName',
                        align: 'center',
                        width: 250,
                        render: (value, companyAuth) => (
                            companyAuth.companyTypeParentName && value ? companyAuth.companyTypeParentName + '-' + value : '-'
                        )
                    },
                    {
                        title: '规模',
                        dataIndex: 'companyScaleMin',
                        key: 'companyScaleMin',
                        align: 'center',
                        width: 150,
                        render: (value, company) => (
                            value && company?.companyScaleMax ? value + "~" + company?.companyScaleMax + "人"
                                : !value && company?.companyScaleMax ? company?.companyScaleMax + "人以下"
                                    : value && !company?.companyScaleMax ? value + "人以上"
                                        : '-'
                        )
                    },
                    {
                        title: '福利',
                        dataIndex: 'companyTagNameList',
                        key: 'companyTagNameList',
                        align: 'center',
                        width: 200,
                        render: (value) => (
                            value ? value?.map(function (item: string) {
                                return <Tag color="cyan">{item}</Tag>
                            }) : ('-')
                        )
                    },
                    {
                        title: '数据',
                        dataIndex: 'companyBrowseNum',
                        key: 'companyBrowseNum',
                        align: 'center',
                        width: 200,
                        render: (value, company) => (
                            <div>
                                <div>浏览量：{value}</div>
                                <div>点赞量：{company.companyClickNum}</div>
                                <div>关注量：{company.companyCollectNum}</div>
                                <div>岗位量：{company.companyRecruitNum}</div>
                            </div>
                        )
                    },
                    {
                        title: '创建时间',
                        dataIndex: 'companyAddTime',
                        key: 'companyAddTime',
                        align: 'center',
                        width: 200,
                        render: (value) => (
                            value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : '-'
                        )
                    },
                    {
                        title: '排序',
                        dataIndex: 'companySort',
                        key: 'companySort',
                        align: 'center',
                        width: 100,
                    },
                    {
                        title: '状态',
                        dataIndex: 'companyShow',
                        key: 'companyShow',
                        align: 'center',
                        width: 100,
                        render: (value) => (
                            value ? '显示' : '不显示'
                        ),
                    },
                    {
                        title: '操作',
                        key: 'action',
                        align: 'center',
                        render: (value, company) => (
                            <Space size="middle">
                                <Link to={"/qiye/company-manage/company/update/" + company.companyId}>
                                    编辑
                                </Link>
                                <a onClick={() => confirmDeleteCompany(company.companyId)}>
                                    删除
                                </a>
                                <a onClick={() => confirmResetIdentityAccount(5, company.companyId)}>
                                    重置密码
                                </a>
                            </Space>
                        ),
                        width: 200,
                        fixed: 'right',
                    }
                ]}
                dataSource={list || []}
                scroll={{ x: 1500 }}
            />
            <ErrorBox error={error} />
        </div>
    )
}