import { Button, Card, Col, Form, message, Modal, Row, Space, Table, DatePicker, Select, Input, Image } from "antd"
import dayjs from "dayjs"
import { useState } from "react"
import { ErrorBox } from "../../components/lib"
import { useCompanyAuthList, useAgreeCompanyAuth, useRejectCompanyAuth } from "../../services/company-auth-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useCompanyAuthSearchParams } from "../company-auth/util"
const { RangePicker } = DatePicker
const { Option } = Select;

export const CompanyAuthListPage = () => {
    useDocumentTitle('企业认证列表', true)
    const [param, setParam] = useCompanyAuthSearchParams()
    const { isLoading, error, totalElements, data: list } = useCompanyAuthList(useDebounce(param, 200))

    const imgUrl = process.env.REACT_APP_IMG_URL;

    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) =>
            setParam({
                ...param,
                pageNum: (current - 1),
            })
    }

    //时间
    const [companyAuthTimeStart, setCompanyAuthTimeStart] = useState('');
    const [companyAuthTimeEnd, setCompanyAuthTimeEnd] = useState('');
    const handleSelectTime = (value: any, dateString: any) => {
        setCompanyAuthTimeStart(dateString[0]);
        setCompanyAuthTimeEnd(dateString[1]);
    }

    //提交
    const onFinish = (values: any) => {
        setParam({
            ...param,
            companyName: values.companyName,
            memberNickname: values.memberNickname,
            companyAuthState: values.companyAuthState,
            companyAuthTimeStart: companyAuthTimeStart,
            companyAuthTimeEnd: companyAuthTimeEnd,
            pageNum: null
        })
    }

    //通过
    const { mutateAsync: agreeMutateAsync } = useAgreeCompanyAuth();
    const confirmAgreeCompanyAuth = (companyAuthId: number) => {
        Modal.confirm({
            title: "确定操作这条数据吗?",
            content: "点击确定操作",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    agreeMutateAsync({ companyAuthId }).then((data) => {
                        if (data.status === 0) {
                            message.info('操作成功');
                            Modal.destroyAll();
                        } else {
                            message.error(data.msg)
                        }
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };

    //驳回
    const [companyAuthId, setCompanyAuthId] = useState<number>();
    const [submitRejectLoading, setSubmitRejectLoading] = useState(false);
    const [rejectModalVisible, setRejectModalVisible] = useState(false);
    const { mutateAsync: mutateAsyncRejectCompanyAuth } = useRejectCompanyAuth();
    const [companyAuthFailReason, setCompanyAuthFailReason] = useState('');
    const handleFailReason = (e: any) => {
        setCompanyAuthFailReason(e.target.value);
    }
    const handleRejectCancel = () => {
        setRejectModalVisible(false);
    };
    const handleRejectOk = () => {
        if (!companyAuthFailReason) {
            message.error('驳回原因必填');
        } else {
            setSubmitRejectLoading(true)
            mutateAsyncRejectCompanyAuth({ companyAuthId, companyAuthFailReason }).then(() => {
                setRejectModalVisible(false);
                setSubmitRejectLoading(false);
            });
        }
    }
    const showRejectModal = (companyAuthId: number) => {
        setCompanyAuthId(companyAuthId);
        setRejectModalVisible(true);
    }

    //分页
    return (
        <div>
            <Card style={{ backgroundColor: "#f2f3f5", margin: '24px 0 24px 0' }}>
                <Form onFinish={onFinish}>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item name="companyAuthTimeRange" label="认证时间">
                                <RangePicker onChange={handleSelectTime} showTime format="YYYY-MM-DD HH:mm:ss" style={{ width: '100%' }} allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="companyAuthState" label="认证状态">
                                <Select
                                    placeholder='请选择认证状态'
                                    showSearch
                                    allowClear
                                    optionFilterProp='children'
                                >
                                    <Option value={10}>认证中</Option>
                                    <Option value={20}>认证失败</Option>
                                    <Option value={30}>认证成功</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item name="companyName" label="企业名称">
                                <Input placeholder='请输入企业名称' allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="memberNickname" label="用户昵称">
                                <Input placeholder='请输入用户昵称' allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item style={{ marginBottom: 0 }}>
                        <Button style={{ marginLeft: 70 }} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
            <Table
                loading={isLoading}
                rowKey={columns => columns.companyAuthId}
                pagination={paginationProps}
                columns={[
                    {
                        title: 'ID',
                        dataIndex: 'companyAuthId',
                        key: 'companyAuthId',
                        align: 'center',
                        width: 100,
                        fixed: 'left',
                    },
                    {
                        title: '名称',
                        dataIndex: 'companyName',
                        key: 'companyName',
                        align: 'center',
                        width: 200,
                        fixed: 'left',
                    },
                    {
                        title: '性质',
                        dataIndex: 'companyAttributeName',
                        key: 'companyAttributeName',
                        align: 'center',
                        width: 100,
                    },
                    {
                        title: '行业',
                        dataIndex: 'companyTypeName',
                        key: 'companyTypeName',
                        align: 'center',
                        width: 250,
                        render: (value, companyAuth) => (
                            companyAuth.companyTypeParentName && value ? companyAuth.companyTypeParentName + '-' + value : '-'
                        )
                    },
                    {
                        title: '规模',
                        dataIndex: 'companyScaleMin',
                        key: 'companyScaleMin',
                        align: 'center',
                        width: 150,
                        render: (value, companyAuth) => (
                            value && companyAuth?.companyScaleMax ? value + "~" + companyAuth?.companyScaleMax + "人"
                                : !value && companyAuth?.companyScaleMax ? companyAuth?.companyScaleMax + "人以下"
                                    : value && !companyAuth?.companyScaleMax ? value + "人以上"
                                        : '-'
                        )
                    },
                    {
                        title: '创办时间',
                        dataIndex: 'companyBuildTime',
                        key: 'companyBuildTime',
                        align: 'center',
                        width: 200,
                        render: (value) => (
                            value ? dayjs(value).format('YYYY-MM-DD') : '-'
                        )
                    },
                    {
                        title: '营业执照',
                        dataIndex: 'companyCert',
                        key: 'companyCert',
                        align: 'center',
                        width: 100,
                        render: (value) => (
                            value ? <Image src={imgUrl + value} width={60} height={80} /> : '-'
                        )
                    },
                    {
                        title: '申请人',
                        dataIndex: 'companyContactName',
                        key: 'companyContactName',
                        align: 'center',
                        width: 150,
                        render: (value, companyAuth) => (
                            <div>
                                {value}{companyAuth.companyContactType === 1 ? '(法人)' : companyAuth.companyContactType === 2 ? '(代办人)' : ''}<br />
                                {companyAuth?.companyContactTel}
                            </div>
                        )
                    },
                    {
                        title: '授权书',
                        dataIndex: 'companyContactCert',
                        key: 'companyContactCert',
                        align: 'center',
                        width: 100,
                        render: (value) => (
                            value ? <Image src={imgUrl + value} width={60} height={80} /> : '-'
                        )
                    },
                    {
                        title: '身份证',
                        dataIndex: 'companyContactCardFront',
                        key: 'companyContactCardFront',
                        align: 'center',
                        width: 200,
                        render: (value, companyAuth) => (
                            <div>
                                {value ? <Image src={imgUrl + value} width={80} height={60} /> : ''}
                                {companyAuth.companyContactCardBack ? <Image src={imgUrl + companyAuth.companyContactCardBack} width={80} height={60} style={{ marginLeft: 5 }} /> : ''}
                            </div>
                        )
                    },
                    {
                        title: '用户昵称',
                        dataIndex: 'memberNickname',
                        key: 'memberNickname',
                        align: 'center',
                        width: 150,
                    },
                    {
                        title: '认证时间',
                        dataIndex: 'companyAuthTime',
                        key: 'companyAuthTime',
                        align: 'center',
                        width: 200,
                        render: (value) => (
                            value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : '-'
                        )
                    },
                    {
                        title: '认证状态',
                        dataIndex: 'companyAuthState',
                        key: 'companyAuthState',
                        align: 'center',
                        width: 100,
                        render: (value) => (
                            value === 10 ? '认证中' : value === 20 ? '认证失败' : value === 30 ? '认证成功' : '-'
                        )
                    },
                    {
                        title: '操作',
                        key: 'action',
                        align: 'center',
                        render: (value, companyAuth) => (
                            companyAuth.companyAuthState === 10 ? (
                                <Space size="middle">
                                    <a onClick={() => confirmAgreeCompanyAuth(companyAuth.companyAuthId)}>
                                        通过
                                    </a>
                                    <a onClick={() => showRejectModal(companyAuth.companyAuthId)}>
                                        驳回
                                    </a>
                                </Space>
                            ) : ('')
                        ),
                        width: 150,
                        fixed: 'right',
                    }
                ]}
                dataSource={list || []}
                scroll={{ x: 1700 }}
            />
            <ErrorBox error={error} />
            <Modal
                confirmLoading={submitRejectLoading}
                centered
                bodyStyle={{ height: '100', overflowY: 'auto' }}
                destroyOnClose={true}
                width={500}
                title="认证驳回"
                visible={rejectModalVisible}
                onOk={handleRejectOk}
                onCancel={handleRejectCancel}>
                <Col>
                    <Form>
                        <Form.Item
                            label='驳回原因'
                        >
                            <Input onChange={handleFailReason} placeholder={"输入驳回原因"} />
                        </Form.Item>
                    </Form>
                </Col>
            </Modal>
        </div>
    )
}