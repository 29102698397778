import { Button, Col, message, Modal, Row, Space, Table, Image } from "antd"
import Search from "antd/lib/input/Search"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useMenuList, useDeleteMenu } from "../../services/menu-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useMenuSearchParams } from "./util"

export const MenuListPage = () => {
  useDocumentTitle('菜单列表', true)
  const imgUrl = process.env.REACT_APP_IMG_URL;
  const [param, setParam] = useMenuSearchParams()
  const { isLoading, error, totalElements, data: list } = useMenuList(useDebounce(param, 200))

  //分页
  const paginationProps = {
    showSizeChanger: true,
    onShowSizeChange: (current: number, pageSize: number) => {
      setParam({
        ...param,
        pageNum: current - 1,
        pageSize,
      });
    },
    defaultCurrent: 1,
    pageSize: param.pageSize,
    total: totalElements,
    onChange: (current: number, pageSize: number) =>
      setParam({
        ...param,
        pageNum: current - 1,
        pageSize,
      }),
  };

  //删除事件
  const useMutateDeleteMenu = useDeleteMenu;
  const { mutateAsync } = useMutateDeleteMenu();
  const confirmDeleteMenu = (menuId: number) => {
    Modal.confirm({
      title: "确定删除这条数据吗?",
      content: "点击确定删除",
      okText: "确定",
      onOk(): any {
        return new Promise((resolve, reject) => {
          mutateAsync({ menuId }).then((data) => {
            if (data.status === 0) {
              message.info('删除成功');
              Modal.destroyAll();
            } else {
              message.error(data.msg)
            }
          });
        }).catch(
          () => console.log('Oops errors!')
        );
      },
      cancelText: "取消"
    });
  };
  //分页
  return (
    <div>
      <Row style={{ marginBottom: 24 }}>
        <Col span={12}>
          <Link to={"/dress/decorate-manage/menu/add"}>
            <Button type="primary">新建</Button>
          </Link>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Search
            placeholder="可搜索菜单名称"
            style={{ width: 200 }}
            value={param.menuName}
            onChange={(evt) =>
              setParam({
                ...param,
                menuName: evt.target.value,
                pageNum: null
              })}
            onSearch={(value) =>
              setParam({
                ...param,
                menuName: value,
                pageNum: null
              })}
          />
        </Col>
      </Row>

      <Table
        loading={isLoading}
        rowKey={columns => columns.menuId}
        pagination={paginationProps}
        columns={[
          {
            title: 'ID',
            dataIndex: 'menuId',
            key: 'menuId',
            align: 'center',
            width: 100,
          },
          {
            title: '菜单名称',
            dataIndex: 'menuName',
            key: 'menuName',
            align: 'center',
          },
          {
            title: '图片',
            dataIndex: 'menuPic',
            key: 'menuPic',
            align: 'center',
            render: (value) => (
              <Image width={80} src={imgUrl +value} />
            ),
          },
          {
            title: '选中图片',
            dataIndex: 'menuCheckedPic',
            key: 'menuCheckedPic',
            align: 'center',
            render: (value) => (
              <Image width={80} src={imgUrl + value} />
            ),
          },
          {
            title: '排序',
            dataIndex: 'menuSort',
            key: 'menuSort',
            align: 'center',
          },
          {
            title: '状态',
            dataIndex: 'menuShow',
            key: 'menuShow',
            align: 'center',
            render: (value) => (
              <span>{value ? '显示' : '不显示'}</span>
            ),
          },
          {
            title: '操作',
            key: 'action',
            align: 'center',
            render: (value, menu) => (
              <Space size="middle">
                <Link to={"/dress/decorate-manage/menu/update/" + menu.menuId}>
                  编辑
                </Link>
                <a onClick={() => confirmDeleteMenu(menu.menuId)}>
                  删除
                </a>
              </Space>
            ),
            width: 150,
          }
        ]}
        dataSource={list || []} />
      <ErrorBox error={error} />
    </div>
  )
}