import { Button, Col, Divider, Form, Input, message, Radio, Row, Select, Spin, Upload } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useStoreClass, useEditStoreClass, useStoreClassListTree } from "../../services/store-class-service";
import { useDocumentTitle } from "../../utils"
import { getToken } from "../../utils/auth-provider";
const { Option } = Select;

export const StoreClassUpdatePage = () => {
    //标题
    useDocumentTitle('编辑门店分类', true)
    //API地址
    const apiUrl = process.env.REACT_APP_API_URL;
    const imgUrl = process.env.REACT_APP_IMG_URL;
    const token = getToken();
    const { pathname } = useLocation();
    const storeClassId = pathname.match(/store\/class\/update\/(\d+)/)?.[1]
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    //表单
    const [form] = useForm();
    //准备树状结构数据
    const { data: storeClassList } = useStoreClassListTree();
    const storeClassListOptions = storeClassList?.data?.map((item: any, value: any) => 
        <Option value={item.storeClassId} key={item.storeClassId}>{item.storeClassName}</Option>
    );
    //准备回显数据
    const { data: editingStoreClass, isLoading } = useStoreClass(
        Number(storeClassId)
    );  
    const [imageUrl, setImageUrl] = useState("");
    useEffect(() => {
        form.setFieldsValue(editingStoreClass?.data)
    }, [editingStoreClass, form]);
    //编辑
    const useMutateEditStoreClass = useEditStoreClass;
    const {mutateAsync, error } =  useMutateEditStoreClass();
    //提交表单
    const [submitLoading, setSubmitLoading] = useState(false);
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...editingStoreClass?.data, ...values }).then(() => {
            message.info('编辑成功');
            window.location.href = "/#/store/class/list"; 
        });
    };
    return (
        <div>
            <Spin spinning={isLoading}>
                <Row>
                    <Col span={12}>
                        <Link to={"/store/class/list"}>
                            <Button type="primary">分类列表</Button>
                        </Link>
                    </Col>
                </Row>
                <Divider style={{ marginBottom: 24 }} />
                <Form
                    form={form}
                    {...layout}
                    onFinish={onFinish}>
                    <Form.Item
                        label={"上级分类"}
                        name={"storeClassParentId"}
                        rules={[{ required: true, message: "上级分类必选" }]}
                    >
                        <Select
                            showSearch
                            placeholder="请选择上级分类"
                            optionFilterProp="children"
                            style={{textAlign:'left'}}
                        >
                            <option value={0}>无</option>
                            {storeClassListOptions}
                        </Select>
                    </Form.Item>

                    <Form.Item
                        label={"分类名称"}
                        name={"storeClassName"}
                        rules={[{ required: true, message: "分类名称必填" }]}
                    >
                        <Input placeholder={"请输入分类名称"} />
                    </Form.Item>

                    <Form.Item
                        label={"分类排序"}
                        name={"storeClassSort"}
                        rules={[{ required: true, message: "分类排序必填" }]}
                    >
                        <Input placeholder={"请输入分类排序"} />
                    </Form.Item>

                    <Form.Item
                        label={"是否显示"}
                        name={"storeClassShow"}
                        rules={[{ required: true, message: "分类显示必选" }]}
                        style={{ textAlign:'left' }}
                    >
                        <Radio.Group>
                            <Radio value={true}>显示</Radio>
                            <Radio value={false}>不显示</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item {...tailLayout} style={{ textAlign:'left' }}>
                        <Button loading={ submitLoading } type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>  
                </Form>
            </Spin>
        </div>
    )
}

