import { useMutation, useQuery, useQueryClient } from "react-query";
import { useHttp } from "../utils/http";
import { PointsGoods } from "../interfaces/points-goods";

//列表 
export const usePointsGoodsList = (param?: Partial<PointsGoods>) => {
    const client = useHttp();
    const result = useQuery<any>(["pointsGoodsList", param], () =>
        client("pointsGoods", { data: param })
    );
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
};

//新增
export const useAddPointsGoods = () => {
    const client = useHttp();
    const queryClient = useQueryClient();
    return useMutation(
        (params: Partial<PointsGoods>) =>
            client(`pointsGoods`, {
                data: params,
                method: "POST"
            }), {
                onSuccess: () => queryClient.invalidateQueries('pointsGoodsList')
            }
    );
};

//编辑
export const useEditPointsGoods = () => {
    const client = useHttp();
    const queryClient = useQueryClient();
    return useMutation(
        (params: Partial<PointsGoods>) =>
            client(`pointsGoods`, {
                data: params,
                method: "PUT"
            }), {
                onSuccess: () => queryClient.invalidateQueries('pointsGoodsList')
            }
    );
};

//失效
export const useDeletePointsGoods = () => {
    const client = useHttp();
    const queryClient = useQueryClient();
    return useMutation(
        ({ pointsGoodsId }: { pointsGoodsId: number}) =>
            client(`pointsGoods/${pointsGoodsId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('pointsGoodsList')
        }
    );
};

export const usePointsGoods = (pointsGoodsId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['pointsGoods', {pointsGoodsId}],
        () => client(`pointsGoods/${pointsGoodsId}`),
        {
            enabled: Boolean(pointsGoodsId)
        }
    )
}


