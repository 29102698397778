import { useMutation, useQuery, useQueryClient } from "react-query"
import { Benefit } from "../interfaces/benefit"
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http"

export const useBenefitList = (param?: Partial<Benefit>) => {
    const client = useHttp();
    const result = useQuery<any>(['benefitPage', param], () => client('benefit', { data: cleanObject(param) }))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return { ...result, totalElements, data };
}

export const useAddBenefit = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Benefit>) => client(`benefit`, {
            data: params,
            method: 'POST'
        }), {
        onSuccess: () => queryClient.invalidateQueries('benefitPage')
    }
    )
}

export const useEditBenefit = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Benefit>) => client(`benefit`, {
            method: 'PUT',
            data: params
        }), {
        onSuccess: () => queryClient.invalidateQueries('benefit')
    }
    )
}

export const useDeleteBenefit = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ benefitId }: { benefitId: number }) =>
            client(`benefit/${benefitId}`, {
                method: "DELETE",
            }), {
        onSuccess: () => queryClient.invalidateQueries('benefitPage')
    }
    );
};

export const useBenefit = (benefitId: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['benefit', { benefitId }],
        () => client(`benefit/${benefitId}`),
    )
}