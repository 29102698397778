import { Button, Card, Col, Form, Input, Row, Select, Table, Image, Space, Modal, message } from "antd"
import dayjs from "dayjs"
import { ErrorBox } from "../../components/lib"
import { useDeleteHr, useHrList } from "../../services/hr-service"
import { useCompanyListAll } from "../../services/company-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useHrSearchParams } from "./util"
import picNone from "../../assets/pic-none.png"
import { useResetIdentityAccount } from "../../services/identity-account-service"
const { Option } = Select;

export const HrListPage = () => {
    useDocumentTitle('招聘列表', true)
    const [param, setParam] = useHrSearchParams()
    const { isLoading, error, totalElements, data: list } = useHrList(useDebounce(param, 200))

    const imgUrl = process.env.REACT_APP_IMG_URL;

    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) =>
            setParam({
                ...param,
                pageNum: (current - 1),
            })
    }

    //企业
    const { data: companyList } = useCompanyListAll();
    const companyOptions = companyList?.data?.map((item: any) =>
        <Option value={item.companyId}>{item.companyName}</Option>
    )

    //删除
    const { mutateAsync: deleteMutateAsync } = useDeleteHr();
    const confirmDeleteHr = (hrId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    deleteMutateAsync({ hrId }).then((data) => {
                        if (data.status === 0) {
                            message.info('删除成功');
                            Modal.destroyAll();
                        } else {
                            message.error(data.msg)
                        }
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };

    //提交
    const onFinish = (values: any) => {
        setParam({
            ...param,
            hrName: values.hrName,
            hrTel: values.hrTel,
            companyId: values.companyId,
            pageNum: null
        })
    }

    //重置密码
    const { mutateAsync: resetIdentityAccount } = useResetIdentityAccount();
    const confirmResetIdentityAccount = (identityType: number, identityId: number) => {
        Modal.confirm({
            title: "确定操作这条数据吗?",
            content: "点击确定取消",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    resetIdentityAccount({ identityType, identityId }).then((data) => {
                        if (data.status === 0) {
                            message.info("操作成功");
                            Modal.destroyAll();
                        } else {
                            message.error(data.msg);
                        }
                    });
                }).catch(() => console.log("Oops errors!"));
            },
            cancelText: "取消",
        });
    };

    //分页
    return (
        <div>
            <Card style={{ backgroundColor: "#f2f3f5", margin: '24px 0 24px 0' }}>
                <Form onFinish={onFinish}>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item name="hrName" label="HR名称">
                                <Input placeholder='请输入HR名称' allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="hrTel" label="手机号码">
                                <Input placeholder='请输入手机号码' allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="companyId" label="所属企业">
                                <Select
                                    placeholder='请选择所属企业'
                                    showSearch
                                    allowClear
                                    optionFilterProp='children'
                                >
                                    {companyOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item style={{ marginBottom: 0 }}>
                        <Button style={{ marginLeft: 70 }} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Card>

            <Table
                loading={isLoading}
                rowKey={columns => columns.recruitId}
                pagination={paginationProps}
                columns={[
                    {
                        title: '头像',
                        dataIndex: 'hrHeadImg',
                        key: 'hrHeadImg',
                        align: 'center',
                        fixed: 'left',
                        width: 100,
                        render: (value) => (
                            value ? <Image src={imgUrl + value} width={60} /> : <Image src={picNone} width={60} />
                        )
                    },
                    {
                        title: '名称',
                        dataIndex: 'hrName',
                        key: 'hrName',
                        align: 'center',
                        fixed: 'left',
                        width: 150,
                    },
                    {
                        title: '手机',
                        dataIndex: 'hrTel',
                        key: 'hrTel',
                        align: 'center',
                        width: 150,
                    },
                    {
                        title: '企业',
                        dataIndex: 'companyName',
                        key: 'companyName',
                        align: 'center',
                        width: 200,
                    },
                    {
                        title: '用户',
                        dataIndex: 'memberNickname',
                        key: 'memberNickname',
                        align: 'center',
                        width: 150,
                    },
                    {
                        title: '关联时间',
                        dataIndex: 'hrAddTime',
                        key: 'hrAddTime',
                        align: 'center',
                        width: 200,
                        render: (value) => (
                            value ? dayjs(value).format('YYYY-MM-DD hh:mm:ss') : '-'
                        )
                    },
                    {
                        title: '操作',
                        key: 'action',
                        align: 'center',
                        render: (value, hr) => (
                            <Space size="middle">
                                <a onClick={() => confirmDeleteHr(hr.hrId)}>
                                    取消关联
                                </a>
                                <a onClick={() => confirmResetIdentityAccount(13, hr.hrId)}>
                                    重置密码
                                </a>
                            </Space>
                        ),
                        fixed: 'right',
                        width: 150,
                    }
                ]}
                dataSource={list || []}
                scroll={{ x: 1100 }}
            />
            <ErrorBox error={error} />
        </div>
    )
}