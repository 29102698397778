import { useMutation, useQuery, useQueryClient } from "react-query";
import { StoreAttribute } from "../interfaces/store-attribute";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useStoreAttributeList = (param?: Partial<StoreAttribute>) => {
    const client = useHttp();
    const result = useQuery<any>(['storeAttributePage', param], () => client('storeAttribute', { data: cleanObject(param) }))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return { ...result, totalElements, data };
}

export const useEditStoreAttribute = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<StoreAttribute>) => client(`storeAttribute`, {
            method: 'PUT',
            data: params
        }), {
        onSuccess: () => queryClient.invalidateQueries('storeAttributePage')
    }
    )
}

export const useAddStoreAttribute = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<StoreAttribute>) => client(`storeAttribute`, {
            data: params,
            method: 'POST'
        }), {
        onSuccess: () => queryClient.invalidateQueries('storeAttributePage')
    }
    )
}

export const useDeleteStoreAttribute = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ storeAttributeId }: { storeAttributeId: number }) =>
            client(`storeAttribute/${storeAttributeId}`, {
                method: "DELETE",
            }), {
        onSuccess: () => queryClient.invalidateQueries('storeAttributePage')
    }
    );
};

export const useStoreAttribute = (storeAttributeId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['storeAttribute', { storeAttributeId }],
        () => client(`storeAttribute/${storeAttributeId}`),
        {
            enabled: Boolean(storeAttributeId)
        }
    )
}

export const useStoreAttributeListAll = () => {
    const client = useHttp();
    return useQuery<any>(["storeAttributeList", { }], () =>
        client(`storeAttribute/list`)
    );
};