import { useMutation, useQuery, useQueryClient } from "react-query";
import { TabBar } from "../interfaces/tab-bar";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useTabBarList = (param?: Partial<TabBar>) => {
    const client = useHttp();
    const result = useQuery<any>(['tabBarPage', param], () => client('tabBar', { data: cleanObject(param) }))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return { ...result, totalElements, data };
}

export const useEditTabBar = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<TabBar>) => client(`tabBar`, {
            method: 'PUT',
            data: params
        }), {
        onSuccess: () => queryClient.invalidateQueries('tabBarPage')
    }
    )
}

export const useAddTabBar = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<TabBar>) => client(`tabBar`, {
            data: params,
            method: 'POST'
        }), {
        onSuccess: () => queryClient.invalidateQueries('tabBarPage')
    }
    )
}

export const useDeleteTabBar = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ tabBarId }: { tabBarId: number }) =>
            client(`tabBar/${tabBarId}`, {
                method: "DELETE",
            }), {
        onSuccess: () => queryClient.invalidateQueries('tabBarPage')
    }
    );
};

export const useTabBar = (tabBarId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['tabBar', { tabBarId }],
        () => client(`tabBar/${tabBarId}`),
        {
            enabled: Boolean(tabBarId)
        }
    )
}