import { Button, Col, message, Modal, Row, Space, Table, Image, Card, Form, Input, InputNumber } from "antd"
import Search from "antd/lib/input/Search"
import { Link } from "react-router-dom"
import dayjs from "dayjs"
import { ErrorBox } from "../../components/lib"
import { useAgencyHotList, useDeleteAgencyHot, useEditAgencyHot } from "../../services/agency-hot-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useAgencyHotSearchParams } from "./util"

export const AgencyHotListPage = () => {
    useDocumentTitle('热门机构列表', true)
    const imgUrl = process.env.REACT_APP_IMG_URL;
    const [param, setParam] = useAgencyHotSearchParams()
    const {isLoading, error, totalElements, data: list} = useAgencyHotList(useDebounce(param, 200))

	//分页
	const paginationProps = {
	  showSizeChanger: true,
	  onShowSizeChange: (current: number, pageSize: number) => {
		setParam({
		  ...param,
		  pageNum: current - 1,
		  pageSize,
		});
	  },
	  defaultCurrent: 1,
	  pageSize: param.pageSize,
	  total: totalElements,
	  onChange: (current: number, pageSize: number) =>
		setParam({
		  ...param,
		  pageNum: current - 1,
		  pageSize,
		}),
	};
    //删除事件
    const { mutateAsync:deleteAgencyHot } = useDeleteAgencyHot();
    const confirmDeleteAgencyHot = (agencyId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    deleteAgencyHot({ agencyId }).then((data) => {
                        if (data.status === 0) {
                            message.info('删除成功');
                            Modal.destroyAll();
                          } else {
                            message.error(data.msg)
                          }
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    
    const { mutateAsync:updateAgencyHot } = useEditAgencyHot();
    const sortChange = (agencyHotId:any,e:any) =>{
        console.log(agencyHotId,e);
        let agencyHotSort:number = e.target.value;
        updateAgencyHot({ agencyHotId,agencyHotSort }).then((data) => {
            if (data.status === 0) {
                message.info('更新成功');
                Modal.destroyAll();
              } else {
                message.error(data.msg)
              }
        });
    }
    //分页
    return (
        <div>
            <Table 
                loading = { isLoading }
                rowKey={ columns => columns.agencyHotId } 
                pagination = { paginationProps }
                scroll={{x:1500}}
            	dataSource={ list || [] }
                columns = {[
                {
                    title: 'ID',
                    dataIndex: 'agencyId',
                    key: 'agencyId',
                    align: 'center',
                    width: 100,
                },

                {
                    title: '机构',
                    dataIndex: ['agency','agencyName'],
                    key: 'agencyName',
                    width: 150,
                    align: 'center',
                },
                {
                    title: '机构图片',
                    dataIndex: ['agency','agencyPic'],
                    key: 'agencyPic',
                    width: 150,
                    align: 'center',
                    render: (value) => <Image width={60} src={imgUrl + value} />,
                },

                {
                    title: '热门排序',
                    dataIndex: 'agencyHotSort',
                    key: 'agencyHotSort',
                    width: 150,
                    align: 'center',
                    render(value:any, record, index) {
                       return <InputNumber min={0}  value={value} onBlur={(e)=>sortChange(record.agencyHotId,e)} placeholder={"请输入排序"} />
                    },
                },

                {
                    title: '操作',
                    key: 'action',
                    align: 'center',
                    width: 200,
                    fixed:"right",
                    render: (value, agencyHot) => (
                        <Space size="middle">
                            <a onClick={() => confirmDeleteAgencyHot(agencyHot.agencyId)}>
                                删除
                            </a>
                        </Space>
                    )
                }
            ]} />
            <ErrorBox error={ error } />
        </div>
    )
}