import { useQuery } from "react-query";
import { useHttp } from "../utils/http";

export const useStoreAddressListAll = () => {
    const client = useHttp();
    const result = useQuery<any>(["storeAddressListAll", { }], () =>
        client(`storeAddress/listAll`)
    );
    const data = result.data?.data;
    return {...result, data };
};

