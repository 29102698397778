import { useQuery } from "react-query";
import { Report } from "../interfaces/report";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useReportList = (param?: Partial<Report>) => {
  const client = useHttp();
  const result = useQuery<any>(["reportPage", param], () =>
    client("report", { data: cleanObject(param) })
  );
  const totalElements = result.data?.data?.totalElements;
  const numberOfElements = result.data?.data?.numberOfElements;
  const data = result.data?.data?.content;
  return { ...result, totalElements, numberOfElements, data };
};
