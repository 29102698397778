import { useMutation, useQuery, useQueryClient } from "react-query";
import { Salesman } from "../interfaces/salesman";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useSalesmanList = (param?: Partial<Salesman>) => {
    const client = useHttp();
    const result = useQuery<any>(['salesmanPage', param], () => client('salesman', { data: cleanObject(param) }))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return { ...result, totalElements, data };
}

export const useEditSalesman = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Salesman>) => client(`salesman`, {
            data: params,
            method: 'PUT'
        }), {
        onSuccess: () => queryClient.invalidateQueries('salesmanPage')
    }
    );
};

export const useDeleteSalesman = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ salesmanId }: { salesmanId: number }) => client(`salesman/${salesmanId}`, {
            method: "DELETE",
        }), {
        onSuccess: () => queryClient.invalidateQueries('salesmanPage')
    }
    );
};

export const useSalesman = (salesmanId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['salesman', { salesmanId }],
        () => client(`salesman/${salesmanId}`),
        {
            enabled: Boolean(salesmanId)
        }
    )
}

