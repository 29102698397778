import { Button, Card, Form, DatePicker, Table, Row, Col, Modal, message } from "antd"
import { ErrorBox } from "../../components/lib"
import { useBillDetailList } from "../../services/bill-detail-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useBillDetailSearchParams } from "./util"
import dayjs from 'dayjs';
import { useState } from "react";
const { RangePicker } = DatePicker;

export const BillDetailListPage = () => {
    useDocumentTitle('账单明细', true)
    const apiUrl = process.env.REACT_APP_API_URL;
    const [param, setParam] = useBillDetailSearchParams()
    const { isLoading, error, totalElements, data: list } = useBillDetailList(useDebounce(param, 200))
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) =>
            setParam({
                ...param,
                pageNum: (current - 1),
            })
    }
    //时间
    const rangeConfig = {
        rules: [{ type: 'array' as const, message: '请选择时间!' }],
    };
    const [startBillDetailDate, setStartBillDetailDate] = useState('');
    const [endBillDetailDate, setEndBillDetailDate] = useState('');
    const handleSelectTime = (value: any, dateString: any) => {
        setStartBillDetailDate(dateString[0]);
        setEndBillDetailDate(dateString[1]);
    }
    const [op, setOp] = useState(1)
    function changeOp(num: number) {
        setOp(num)
    }
    const onFinish = (values: any) => {
        if (op === 1) {
            setParam({
                ...param,
                startBillDetailDate: startBillDetailDate,
                endBillDetailDate: endBillDetailDate,
                pageNum: null
            })
        } else if (op === 2) {
            let startTime = startBillDetailDate;
            let endTime = endBillDetailDate;
            if (startTime === undefined) {
                startTime = "";
            }
            if (endTime === undefined) {
                endTime = "";
            }
            Modal.confirm({
                title: '确定导出当前列表数据吗?',
                content: '点击确定导出',
                okText: '确定',
                onOk(): any {
                    return new Promise((resolve, reject) => {
                        window.location.href = apiUrl +
                            `/billDetail/export?startBillDetailDate=${startTime}&endBillDetailDate=${endTime}`
                        message.success('导出成功')
                        Modal.destroyAll()
                    }).catch(() => console.log('Oops errors!'))
                },
                cancelText: '取消',
            })
        }
    }
    //分页
    return (
        <div>
            <Card style={{ backgroundColor: "#f2f3f5", marginBottom: 24 }}>
                <Form onFinish={onFinish}>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item name="billDetailDateRange" label="入账时间" {...rangeConfig}>
                                <RangePicker onChange={handleSelectTime} showTime format="YYYY-MM-DD HH:mm:ss" style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Button onClick={() => changeOp(1)} style={{ marginLeft: 70 }} type='primary' htmlType='submit'>
                            提交
                        </Button>
                        <Button onClick={() => changeOp(2)} style={{ marginLeft: 20 }} type='primary' htmlType='submit'>
                            导出
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
            <Table
                loading={isLoading}
                rowKey={columns => columns.billDetailId}
                pagination={paginationProps}
                columns={[
                    {
                        title: '入账时间',
                        dataIndex: 'billDetailDate',
                        key: 'billDetailDate',
                        align: 'center',
                        render(value, billDetail) {
                            return <span>
                                {billDetail.billDetailDate ? dayjs(billDetail.billDetailDate).format('YYYY-MM-DD HH:mm:ss') : '无'}
                            </span>
                        },
                    },
                    {
                        title: '关联编号',
                        dataIndex: 'billDetailOrdersSn',
                        key: 'billDetailOrdersSn',
                        align: 'center',
                        render(value, billDetail) {
                            return <span>
                                {billDetail.billDetailOrdersSn}
                            </span>
                        },
                    },
                    {
                        title: '业务凭证',
                        dataIndex: 'billDetailThirdSn',
                        key: 'billDetailThirdSn',
                        align: 'center',
                        render(value, billDetail) {
                            return <span>
                                {billDetail.billDetailThirdSn ? billDetail.billDetailThirdSn : '无'}
                            </span>
                        },
                    },
                    {
                        title: '收支类型',
                        dataIndex: 'billDetailType',
                        key: 'billDetailType',
                        align: 'center',
                        render(value, billDetail) {
                            return <span>
                                {billDetail.billDetailType === 1 ? '收入' : '支出'}
                            </span>
                        },
                    },
                    {
                        title: '交易场景',
                        dataIndex: 'billDetailScene',
                        key: 'billDetailScene',
                        align: 'center',
                        render(value, billDetail) {
                            return <span>
                                {billDetail.billDetailScene === 1 ? '订单支付' : billDetail.billDetailScene === 2 ? '订单退款'
                                    : billDetail.billDetailScene === 3 ? '资金提现' : '其他'}
                            </span>
                        },
                    },
                    {
                        title: '交易金额',
                        dataIndex: 'billDetailMoney',
                        key: 'billDetailMoney',
                        align: 'center',
                        render(value, billDetail) {
                            return <span>
                                {billDetail.billDetailType === 1 ? <text style={{ color: '#1aad19' }}>+ {billDetail.billDetailMoney}</text>
                                    : <text style={{ color: '#fd5454' }}>- {billDetail.billDetailMoney}</text>}
                            </span>
                        },
                    }
                ]}
                dataSource={list || []} />
            <ErrorBox error={error} />
        </div>
    )
}