import { Button, Col, Divider, Form, Input, message, Radio, Row, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useEditSchoolNotice, useSchoolNotice } from "../../services/school-notice-service";
import { useDocumentTitle } from "../../utils"
import UploadPic from '../../components/upload-pic';
import RichText from "../../components/rich-text";
import { useSchoolNoticeDetail } from "../../services/school-notice-detail-service";
import BraftEditor from "braft-editor";

export const SchoolNoticeUpdatePage = () => {
    //标题
    useDocumentTitle('编辑学校公告', true)
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const schoolNoticeId = Number(pathname.match(/dress\/trends-manage\/trends-tag\/school-notice\/update\/(\d+)/)?.[1])

    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 18 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };

    //回显
    const { data: editingSchoolNotice, isLoading } = useSchoolNotice(schoolNoticeId);
    const { data: editingSchoolNoticeDetail } = useSchoolNoticeDetail(schoolNoticeId);
    //表单
    const [form] = useForm();
    const [schoolNoticePic, setSchoolNoticePic] = useState("");
    const [schoolNoticeContent, setSchoolNoticeContent] = useState("");
    useEffect(() => {
        form.setFieldsValue({ ...editingSchoolNotice?.data });
        //封面
        if (editingSchoolNotice?.data?.schoolNoticePic) {
            setSchoolNoticePic(editingSchoolNotice?.data?.schoolNoticePic)
        }
        if (editingSchoolNoticeDetail?.data?.schoolNoticeContent) {
            setSchoolNoticeContent(editingSchoolNoticeDetail?.data?.schoolNoticeContent);
            form.setFieldsValue({
                schoolNoticeContent: BraftEditor.createEditorState(
                    editingSchoolNoticeDetail?.data?.schoolNoticeContent
                ),
            });
        }
    }, [editingSchoolNotice, editingSchoolNoticeDetail, form]);

    //新建
    const [submitLoading, setSubmitLoading] = useState(false);
    const { mutateAsync } = useEditSchoolNotice();
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...editingSchoolNotice?.data, ...values, schoolNoticePic, schoolNoticeContent }).then((data) => {
            if (data.status === 0) {
                message.success(data.msg);
                navigate('/dress/trends-manage/trends-tag/school-notice/list?trendsTagId=' + editingSchoolNotice?.data?.trendsTagId)
            } else {
                setSubmitLoading(false);
                message.error(data.msg);
            }
        }).catch(err => {
            message.error('提交失败，请重新提交');
            setSubmitLoading(false);
        });
    };

    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/dress/trends-manage/trends-tag/school-notice/list?trendsTagId=" + editingSchoolNotice?.data?.trendsTagId}>
                        <Button type="primary">列表</Button>
                    </Link>
                </Col>
            </Row>

            <Divider style={{ marginBottom: 24 }} />
            <Spin spinning={isLoading}>
                <Form
                    form={form}
                    {...layout}
                    onFinish={onFinish}
                >

                    <Form.Item
                        label={"标题"}
                        name={"schoolNoticeTitle"}
                        rules={[{ required: true, message: "标题必填" }]}
                    >
                        <Input style={{ width: 380 }} placeholder={"请输入标题"} />
                    </Form.Item>

                    <UploadPic
                        name={'uploadPic'}
                        label={'图片'}
                        pic={schoolNoticePic}
                        setPic={setSchoolNoticePic}
                        action={'update'}
                        style={{ textAlign: 'left' }}
                    >
                    </UploadPic>

                    <Form.Item
                        label={"排序"}
                        name={"schoolNoticeSort"}
                        rules={[{ required: true, message: "排序必填" }]}
                    >
                        <Input style={{ width: 380 }} placeholder={"请输入排序"} />
                    </Form.Item>

                    <Form.Item
                        label={"状态"}
                        name={"schoolNoticeShow"}
                        rules={[{ required: true, message: "状态必选" }]}
                        style={{ textAlign: 'left' }}
                    >
                        <Radio.Group>
                            <Radio defaultChecked value={true}>显示</Radio>
                            <Radio value={false}>不显示</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <RichText
                        label={"内容"}
                        name={"schoolNoticeContent"}
                        setContent={setSchoolNoticeContent}
                    >
                    </RichText>

                    <Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                        <Button loading={submitLoading} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    )
}

