import { useMutation, useQuery, useQueryClient } from "react-query";
import { Guide } from "../interfaces/guide";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useGuideList = (param?: Partial<Guide>) => {
    const client = useHttp();
    const result = useQuery<any>(['guidePage', param], () => client('guide', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditGuide = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Guide>) => client(`guide`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('guidePage')
        }
    )
}

export const useAddGuide = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Guide>) => client(`guide`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('guidePage')
        }
    )
}

export const useDeleteGuide = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ guideId }: { guideId: number}) =>
            client(`guide/${guideId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('guidePage')
        }
    );
};

export const useGuide = (guideId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['guide', {guideId}],
        () => client(`guide/${guideId}`),
        {
            enabled: Boolean(guideId)
        }
    )
}