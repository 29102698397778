import { useMutation, useQuery, useQueryClient } from "react-query";
import { MallShowcase } from "../interfaces/mall-showcase";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useShowcaseList = (param?: Partial<MallShowcase>) => {
    const client = useHttp();
    const result = useQuery<any>(['showcaseList', param], () => client('mallShowcase', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditShowcase = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<MallShowcase>) => client(`mallShowcase`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('showcaseList')
        }
    )
}

export const useAddShowcase = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<MallShowcase>) => client(`mallShowcase`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('showcaseList')
        }
    )
}

export const useDeleteShowcase = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ showcaseId }: { showcaseId: number}) =>
            client(`mallShowcase/${showcaseId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('showcaseList')
        }
    );
};

export const useShowcase = (showcaseId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['mallShowcase', {showcaseId}],
        () => client(`mallShowcase/${showcaseId}`),
        {
            enabled: Boolean(showcaseId)
        }
    )
}