import { useMutation, useQuery, useQueryClient } from "react-query";
import { GoodsDetail } from "../interfaces/goods-detail";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useGoodsDetail = (goodsId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['goodsDetail', {goodsId}],
        () => client(`goodsDetail/${goodsId}`),
        {
            enabled: Boolean(goodsId)
        }
    )
}