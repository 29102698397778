import { Button, Col, Divider, Form, Input, message, Radio, Row, Spin } from "antd";
import { SketchPicker } from 'react-color';
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useTemplateMessage, useEditTemplateMessage } from "../../services/template-message-service";
import { useDocumentTitle } from "../../utils"

export const TemplateMessageUpdatePage = () => {
    //标题
    useDocumentTitle('编辑模板', true)
    const { pathname } = useLocation();
    const templateMessageId = pathname.match(/setting\/wechat-manage\/template-message\/update\/(\d+)/)?.[1]
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    // 点击更换颜色
    const [displayColor, setDisplayColor] = useState(false)
    const handleClick = () => {
        setDisplayColor(true)
    }
    const handleClose = () => {
        setDisplayColor(false)
    }
    // 更换颜色
    const [color, setColor] = useState()
    const onChangeColor = (e: any) => {
        setColor(e?.hex)
        form.setFieldsValue({ salesmanFontColor: e?.hex })
        setDisplayColor(false)
    }
    //表单
    const [form] = useForm();
    //准备回显数据
    const { data: editingTemplateMessage, isLoading } = useTemplateMessage(
        Number(templateMessageId)
    );
    //专题
    useEffect(() => {
        form.setFieldsValue(editingTemplateMessage?.data)
        if(editingTemplateMessage?.data?.templateMessageColor){
            setColor(editingTemplateMessage?.data?.templateMessageColor)
        }
    }, [editingTemplateMessage, form]);
    //编辑
    const useMutateEditTemplateMessage = useEditTemplateMessage;
    const { mutateAsync, error } = useMutateEditTemplateMessage();
    //提交表单
    const [submitLoading, setSubmitLoading] = useState(false);
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...editingTemplateMessage?.data, ...values }).then(() => {
            message.info('编辑成功');
            window.location.href = "/#/setting/wechat-manage/template-message/list";
        });
    };
    return (
        <div>
            <Spin spinning={isLoading}>
                <Row>
                    <Col span={12}>
                        <Link to={"/setting/wechat-manage/template-message/list"}>
                            <Button type="primary">列表</Button>
                        </Link>
                    </Col>
                </Row>
                <Divider style={{ marginBottom: 24 }} />
                <Form
                    form={form}
                    {...layout}
                    onFinish={onFinish}
                >

                    <Form.Item
                        label={"标题"}
                    >
                        <span>{editingTemplateMessage?.data?.templateMessageTitle}</span>
                    </Form.Item>

                    <Form.Item
                        label={"编号"}
                    >
                        <span>{editingTemplateMessage?.data?.templateMessageSn}</span>
                    </Form.Item>

                    {/* <Form.Item
                        label={"顶部信息"}
                        name={"templateMessageFirst"}
                        rules={[{ required: true, message: "顶部信息必填" }]}
                    >
                        <Input placeholder={"请输入顶部信息"} />
                    </Form.Item>

                    <Form.Item
                        label={"备注"}
                        name={"templateMessageRemark"}
                        rules={[{ required: true, message: "备注必填" }]}
                    >
                        <Input placeholder={"请输入备注"} />
                    </Form.Item> */}

                    <Form.Item
                        label={"颜色"}
                        name={"templateMessageColor"}
                        style={{ marginTop: 24 }}
                    >
                        <Input placeholder="请输入颜色" value={color} readOnly onClick={handleClick}></Input>
                        {displayColor ? (
                            <div style={{ position: 'absolute', zIndex: 2, marginLeft: 300 }}>
                                <div style={{ position: 'fixed', top: 0, right: 0, bottom: 0, left: 0 }} onClick={handleClose} />
                                <SketchPicker width='250px' color={color} onChangeComplete={onChangeColor} />
                            </div>
                        ) : ''}
                    </Form.Item>

                    <Form.Item
                        label={"状态"}
                        name={"templateMessageStatus"}
                        rules={[{ required: true, message: "状态必选" }]}
                        style={{ textAlign: 'left' }}
                    >
                        <Radio.Group>
                            <Radio value={true}>启用</Radio>
                            <Radio value={false}>停用</Radio>
                        </Radio.Group>
                    </Form.Item>

                    <Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                        <Button loading={submitLoading} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    )
}
