import { useQuery } from "react-query";
import { OrdersRefundLog } from "../interfaces/orders-refund-log";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useOrdersRefundLogList = (ordersRefundId?: number) => {
    const client = useHttp();
    const result = useQuery<any>(['ordersRefundLogList'], () => client('ordersRefundLog?ordersRefundId=' + ordersRefundId, {}))
    const data = result.data?.data;
    return {...result, data };
}