import { useQuery } from "react-query";
import { MemberCoupon } from "../interfaces/member-coupon";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useMemberCouponList = (param?: Partial<MemberCoupon>) => {
    const client = useHttp();
    const result = useQuery<any>(['memberCouponList', param], () => client('memberCoupon', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}