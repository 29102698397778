import { useMutation, useQuery, useQueryClient } from "react-query";
import { CompanyPositionClass } from "../interfaces/company-position-class";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useCompanyPositionClassList = (param?: Partial<CompanyPositionClass>) => {
    const client = useHttp();
    const result = useQuery<any>(['companyPositionClassPage', param], () => client('companyPositionClass', { data: cleanObject(param) }))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return { ...result, totalElements, data };
}

export const useEditCompanyPositionClass = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<CompanyPositionClass>) => client(`companyPositionClass`, {
            method: 'PUT',
            data: params
        }), {
        onSuccess: () => queryClient.invalidateQueries('companyPositionClassPage')
    }
    )
}

export const useAddCompanyPositionClass = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<CompanyPositionClass>) => client(`companyPositionClass`, {
            data: params,
            method: 'POST'
        }), {
        onSuccess: () => queryClient.invalidateQueries('companyPositionClassPage')
    }
    )
}

export const useDeleteCompanyPositionClass = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ companyPositionClassId }: { companyPositionClassId: number }) =>
            client(`companyPositionClass/${companyPositionClassId}`, {
                method: "DELETE",
            }), {
        onSuccess: () => queryClient.invalidateQueries('companyPositionClassPage')
    }
    );
};

export const useCompanyPositionClass = (companyPositionClassId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['companyPositionClass', { companyPositionClassId }],
        () => client(`companyPositionClass/${companyPositionClassId}`),
        {
            enabled: Boolean(companyPositionClassId)
        }
    )
}

export const useCompanyPositionClassListAll = () => {
    const client = useHttp();
    return useQuery<any>(["companyPositionClassList", { }], () =>
        client(`companyPositionClass/list`)
    );
};
