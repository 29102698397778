import { Button, Col, Divider, Form, Input, message, Radio, Row, Select, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCourseNotice, useEditCourseNotice } from "../../services/course-notice-service";
import { useDocumentTitle } from "../../utils"
import  UploadPic  from '../../components/upload-pic';
import BraftEditor from 'braft-editor';
import RichText from '../../components/rich-text';
export const CourseNoticeUpdatePage = () => {
    //标题
    useDocumentTitle('编辑课程公告', true)
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const courseNoticeId = Number(pathname.match(/jigou\/course-manage\/course-notice\/update\/(\d+)/)?.[1])

    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 16 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };

    //表单
    const [form] = useForm();
    const [courseNoticePic, setCourseNoticePic] = useState("");
    const [courseNoticeContent,setCourseNoticeContent] = useState("");
    const [uploading, setUploading] = useState<boolean>(false);

    //准备回显
    const { data: editingCourseNotice, isLoading } = useCourseNotice(courseNoticeId);
    console.log("回显",editingCourseNotice,isLoading);
    useEffect(() => {
        form.setFieldsValue({ ...editingCourseNotice?.data });
        //封面
        if (editingCourseNotice?.data?.courseNoticePic) {
            setCourseNoticePic(editingCourseNotice?.data?.courseNoticePic)
        }        
        if (editingCourseNotice?.data?.courseNoticeContent) {
            setCourseNoticeContent(editingCourseNotice?.data?.courseNoticeContent);
            form.setFieldsValue({
                courseNoticeContent: BraftEditor.createEditorState(
                    editingCourseNotice?.data?.courseNoticeContent
                ),
            });
        }
    }, [editingCourseNotice, form]);

    //新建
    const [submitLoading, setSubmitLoading] = useState(false);
    const { mutateAsync } = useEditCourseNotice();
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...editingCourseNotice?.data, ...values, courseNoticePic,courseNoticeContent }).then((data) => {
            if (data.status === 0) {
                message.success(data.msg);
                navigate('/jigou/course-manage/course-notice/list')
            } else {
                setSubmitLoading(false);
                message.error(data.msg);
            }
        }).catch(err => {
            message.error('提交失败，请重新提交');
            setSubmitLoading(false);
        });
    };

    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/jigou/course-manage/course-notice/list"}>
                        <Button type="primary">列表</Button>
                    </Link>
                </Col>
            </Row>

            <Divider style={{ marginBottom: 24 }} />

            <Spin spinning={isLoading}>
                <Form
                    form={form}
                    {...layout}
                    onFinish={onFinish}
                >
               	<Form.Item
                    label={"标题"}
                    name={"courseNoticeTitle"}
                    rules={[{ required: true, message: "标题必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入标题"} />
                </Form.Item>

               	<UploadPic
                    label={'图片'}
                    name={'uploadCourseNoticePic'}
                    pic={courseNoticePic}
                    setPic={setCourseNoticePic}
                    action={'update'}
                    style={{ textAlign: 'left' }}
                >
                </UploadPic>
               	
                <Form.Item
                    label={"是否显示"}
                    name={"courseNoticeShow"}
                    rules={[{ required: true, message: "是否显示必选" }]}
                    style={{ textAlign: 'left' }}
                >
                    <Radio.Group>
                        <Radio defaultChecked value={true}>显示</Radio>
                        <Radio value={false}>不显示</Radio>
                    </Radio.Group>
                </Form.Item>
                <RichText
                    label={"内容"}
                    name={"courseNoticeContent"}
                    setContent={setCourseNoticeContent}
                >
                </RichText>

				<Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                        <Button loading={submitLoading} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    )
}