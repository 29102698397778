import { useQuery } from "react-query";
import { useHttp } from "../utils/http";

export const useNoticeDetail = (noticeId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['noticeDetail', {noticeId}],
        () => client(`noticeDetail/${noticeId}`),
        {
            enabled: Boolean(noticeId)
        }
    )
}