import { Col, message, Modal, Row, Space, Table, Image, Tabs, Select, DatePicker } from "antd"
import { ErrorBox } from "../../components/lib"
import { useGoodsList, useUnRecommedGoods } from "../../services/goods-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useGoodsSearchParams } from "./util"
import dayjs from 'dayjs'
import { useEffect, useState } from "react"
const { TabPane } = Tabs
const { Option } = Select
const { RangePicker } = DatePicker;

export const GoodsRecommedListPage = () => {
    useDocumentTitle('推荐商品列表', true)
    const imgUrl = process.env.REACT_APP_IMG_URL;
    const [param, setParam] = useGoodsSearchParams();
    useEffect(() => {
        setParam({
            goodsRecommend: true
        })
    }, []);
    //商品列表
    const {isLoading, error, totalElements, data: list} = useGoodsList(useDebounce(param, 200))
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        showSizeChanger: false,
        onChange: (current: number) => 
            setParam({
            ...param,
            pageNum: (current -1),
        })
    }
    //取消推荐事件
    const useMutateUnRecommedGoods = useUnRecommedGoods;
    const { mutateAsync } = useMutateUnRecommedGoods();
    const confirmUnRecommedGoods = (goodsId: number) => {
        Modal.confirm({
            title: "确定取消推荐这条数据吗?",
            content: "点击确定取消",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ goodsId }).then(() => {
                        message.info('取消成功');
                        Modal.destroyAll();
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    //上下架
    const [typeId, setTypeId] = useState();
    const handleTab = (key: any, event: any) => {
        setTypeId(key)
        setParam({
            ...param,
            typeId: key,
            pageNum: null
        })
    }
    return (
        <div>
            <Tabs onTabClick={handleTab} defaultActiveKey="2" type="card" size={"small"}>
                <TabPane tab="全部" key="1">
                </TabPane>
                <TabPane tab="销售中" key="2">
                </TabPane>
                <TabPane tab="已售罄" key="3">
                </TabPane>
                <TabPane tab="仓库中" key="4">
                </TabPane>
            </Tabs>
            <Table 
                loading = { isLoading }
                rowKey={ columns => columns.goodsId } 
                pagination = { paginationProps } 
                columns = {[
                {
                    title: '商品ID',
                    dataIndex: 'goodsId',
                    key: 'goodsId',
                    width: 80,
                    fixed: "left"
                },
                {
                    title: '商品名称',
                    width: 300,
                    dataIndex: 'goodsName',
                    key: 'goodsName',
                    fixed: 'left',
                    render: (value, goods) => (
                        <Space size="middle">
                            <Row>
                                <Col span={6}>
                                    <Image
                                        width="100%"
                                        src={imgUrl  + goods.goodsPic}
                                    />
                                </Col>
                                <Col span={18} style={{paddingLeft:10}}>
                                    {goods.goodsName}
                                </Col>
                            </Row>
                        </Space>
                    ),
                },
                { 
                    title: '价格（元）', 
                    dataIndex: 'goodsPrice', 
                    key: 'goodsPrice' 
                },
                { 
                    title: '访问量', 
                    dataIndex: 'goodsPageView', 
                    key: 'goodsPageView' 
                },
                { 
                    title: '库存', 
                    dataIndex: 'goodsStorage', 
                    key: 'goodsStorage' 
                },
                { 
                    title: '销量', 
                    dataIndex: 'goodsSaleNum', 
                    key: 'goodsSaleNum' 
                },
                { 
                    title: '创建时间', 
                    dataIndex: 'goodsAddTime', 
                    key: 'goodsAddTime',
                    render(value, goods) {
                        return <span>
                            {goods.goodsAddTime ? dayjs(goods.goodsAddTime).format('YYYY-MM-DD HH:mm:ss'):'无'}
                        </span>
                    },
                },
                {
                    title: "门店",
                    dataIndex: "storeName",
                    key: "storeName",
                    width: 250,
                },
                {
                    title: "地区",
                    dataIndex: "cityName",
                    key: "cityName",
                },
                {
                    title: '操作',
                    key: 'action',
                    fixed: "right",
                    render: (value, goods) => (
                        <Space size="middle">
                            <a onClick={() => confirmUnRecommedGoods(goods.goodsId)}>
                                取消推荐
                            </a>
                        </Space>
                    ),
                    width: 100,
                },
            ]}
            dataSource={ list || [] }
            scroll={{ x: 1500 }}  />
            <ErrorBox error={ error } />
        </div>
    )
}