import { useMutation, useQuery, useQueryClient } from "react-query";
import { SchoolOrdersRefund } from "../interfaces/school-orders-refund";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useSchoolOrdersRefundList = (param?: Partial<SchoolOrdersRefund>) => {
    const client = useHttp();
    const result = useQuery<any>(['schoolOrdersRefundPage', param], () => client('schoolOrdersRefund', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useAgreeSchoolOrdersRefund = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ schoolOrdersRefundId }: { schoolOrdersRefundId: number }) =>
            client(`schoolOrdersRefund/agree?schoolOrdersRefundId=${schoolOrdersRefundId}`, {
                method: "POST",
            }), {
        onSuccess: () => queryClient.invalidateQueries('schoolOrdersRefundPage')
    }
    );
};

export const useRejectSchoolOrdersRefund = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<SchoolOrdersRefund>) => client(`schoolOrdersRefund/reject`, {
            method: 'POST',
            data: params
        }), {
        onSuccess: () => queryClient.invalidateQueries('schoolOrdersRefundPage')
    }
    );
}

export const useSchoolOrdersRefund = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ schoolOrdersRefundId }: { schoolOrdersRefundId: number }) =>
            client(`schoolOrdersRefund/${schoolOrdersRefundId}`, {
                method: "GET",
            }), {
        onSuccess: () => queryClient.invalidateQueries('schoolOrdersRefund')
    }
    );
};