import { useMutation, useQuery, useQueryClient } from "react-query";
import { PointsGoodsClass } from "../interfaces/points-goods-class";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const usePointsGoodsClassList = (param?: Partial<PointsGoodsClass>) => {
    const client = useHttp();
    const result = useQuery<any>(['pointsGoodsClassList', param], () => client('pointsGoodsClass', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useAddPointsGoodsClass = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<PointsGoodsClass>) => client(`pointsGoodsClass`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('pointsGoodsClassList')
        }
    )
}

export const useDeletePointsGoodsClass = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ pointsGoodsClassId }: { pointsGoodsClassId: number}) =>
            client(`pointsGoodsClass/${pointsGoodsClassId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('pointsGoodsClassList')
        }
    );
}

export const useEditPointsGoodsClass = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<PointsGoodsClass>) => client(`pointsGoodsClass`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('pointsGoodsClassList')
        }
    )
}

export const usePointsGoodsClass = (pointsGoodsClassId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['pointsGoodsClass', {pointsGoodsClassId}],
        () => client(`pointsGoodsClass/${pointsGoodsClassId}`),
        {
            enabled: Boolean(pointsGoodsClassId)
        }
    )
}


export const usePointsGoodsClassListAll = (param?: Partial<PointsGoodsClass>) => {
    const client = useHttp();
    return useQuery<any>(['pointsGoodsClassListAll', {}], () => client('pointsGoodsClass/listAll'))
};
