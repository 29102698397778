import { Button, Col, message, Modal, Row, Space, Table, Image, Card, Form, Input } from "antd"
import Search from "antd/lib/input/Search"
import { Link } from "react-router-dom"
import dayjs from "dayjs"
import { ErrorBox } from "../../components/lib"
import { useCourseClassList, useDeleteCourseClass } from "../../services/course-class-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useCourseClassSearchParams } from "./util"

export const CourseClassListPage = () => {
    useDocumentTitle('课程分类列表', true)
    const imgUrl = process.env.REACT_APP_IMG_URL;
    const [param, setParam] = useCourseClassSearchParams()
    const {isLoading, error, totalElements, data: list} = useCourseClassList(useDebounce(param, 200))
    console.log("courseClassList:",list);
    

	//分页
	const paginationProps = {
	  showSizeChanger: true,
	  onShowSizeChange: (current: number, pageSize: number) => {
		setParam({
		  ...param,
		  pageNum: current - 1,
		  pageSize,
		});
	  },
	  defaultCurrent: 1,
	  pageSize: param.pageSize,
	  total: totalElements,
	  onChange: (current: number, pageSize: number) =>
		setParam({
		  ...param,
		  pageNum: current - 1,
		  pageSize,
		}),
	};
     //搜索
  const onFinish = (values: any) => {
    setParam({
      ...param,
      courseClassName: values.courseClassName,
      pageNum: null,
    });
  };
    //删除事件
    const { mutateAsync } = useDeleteCourseClass();
    const confirmDeleteCourseClass = (courseClassId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ courseClassId }).then((data) => {
                        if (data.status === 0) {
                            message.info('删除成功');
                            Modal.destroyAll();
                          } else {
                            message.error(data.msg)
                          }
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    //分页
    return (
        <div>
            <Row style={{marginBottom: 24}}>
                <Col span={12}>
                    <Link to={"/jigou/course-manage/course-class/add"}>
                        <Button type="primary">新建</Button>
                    </Link>
                </Col>
				<Col span={12} style={{ textAlign: "right" }}>
				  <Search
					placeholder="可搜索课程分类名称"
					style={{ width: 200 }}
					value={param.courseClassName}
					onChange={(evt) =>
					  setParam({
						...param,
						courseClassName: evt.target.value,
						pageNum: null,
					  })
					}
					onSearch={(value) =>
					  setParam({
						...param,
						courseClassName: value,
						pageNum: null,
					  })
					}
				  />
				</Col>
            </Row>
            <Table 
                loading = { isLoading }
                rowKey={ columns => columns.courseClassId } 
                pagination = { paginationProps } 
                columns = {[
                {
                    title: 'ID',
                    dataIndex: 'courseClassId',
                    key: 'courseClassId',
                    align: 'center',
                    width: 130,
                },

                {
                    title: '课程分类名称',
                    dataIndex: 'courseClassName',
                    key: 'courseClassName',
                    align: 'center',
                },

                {
                    title: '上级分类',
                    dataIndex: 'courseClassParentName',
                    key: 'courseClassParentName',
                    align: 'center',
                },

                {
                    title: '排序',
                    dataIndex: 'courseClassSort',
                    key: 'courseClassSort',
                    align: 'center',
                },

				{
                    title: '图片',
                    dataIndex: 'courseClassPic',
                    key: 'courseClassPic',
                    align: 'center',
                    render: (value) => (
                        <Image width={150} src={imgUrl + value}/>
                    ),
                },

                {
                    title: '是否显示',
                    dataIndex: 'courseClassShow',
                    key: 'courseClassShow',
                    align: 'center',
                    render: (value) => (
                        <span>{value ? '显示' : '不显示'}</span>
                    ),
                },

                {
                    title: '操作',
                    key: 'action',
                    align: 'center',
                    render: (value, courseClass) => (
                        <Space size="middle">
                            <Link to={"/jigou/course-manage/course-class/update/" + courseClass.courseClassId}>
                                编辑
                            </Link>
                            <a onClick={() => confirmDeleteCourseClass(courseClass.courseClassId)}>
                                删除
                            </a>
                        </Space>
                    ),
                    width: 150,
                }
            ]}
            dataSource={ list || [] }  />
            <ErrorBox error={ error } />
        </div>
    )
}