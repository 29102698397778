import { Space, Table, Image, Button, Form, Row, Col, Select, Card, Input, DatePicker, Modal, message } from "antd"
import dayjs from "dayjs"
import { useState } from "react"
import { ErrorBox } from "../../components/lib"
import { useAgencyListAll } from "../../services/agency-service"
import { usePlannerListAll } from "../../services/planner-service"
import { useComfirmPaySchoolOrders, useSchoolOrdersList } from "../../services/school-orders-service"
import { useSchoolListAll } from "../../services/school-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useSchoolOrdersSearchParams } from "./util"
import { useAuth } from '../../context/auth-context';
const { RangePicker } = DatePicker;
const { Option } = Select;

export const SchoolOrdersListPage = () => {
    useDocumentTitle('学费订单列表', true)
    const data = useAuth();
    const apiUrl = process.env.REACT_APP_API_URL;
    const imgUrl = process.env.REACT_APP_IMG_URL;
    const [param, setParam] = useSchoolOrdersSearchParams()
    const { isLoading, error, totalElements, data: list } = useSchoolOrdersList(useDebounce(param, 200))

    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) =>
            setParam({
                ...param,
                pageNum: (current - 1),
            })
    }

    //准备学校
    const { data: schoolList } = useSchoolListAll();
    const schoolOptions = schoolList?.data?.map((item: any, value: any) =>
        <Option value={item.schoolId} key={item.schoolId}>{item.schoolName}</Option>
    );

    //准备机构
    const { data: agencyList } = useAgencyListAll();
    const agencyOptions = agencyList?.data?.map((item: any, value: any) =>
        <Option value={item.agencyId} key={item.agencyId}>{item.agencyName}</Option>
    );

    //准备咨询师
    const { data: plannerList } = usePlannerListAll();
    const plannerOptions = plannerList?.data?.map((item: any, value: any) =>
        <Option value={item.plannerId} key={item.plannerId}>{item.plannerName}</Option>
    );

    //开单时间
    const rangeConfig = {
        rules: [{ type: "array" as const, message: "请选择时间!" }],
    };
    const [schoolOrdersCreateTimeStart, setSchoolOrdersCreateTimeStart] = useState("");
    const [schoolOrdersCreateTimeEnd, setSchoolOrdersCreateTimeEnd] = useState("");
    const handleSelectTime = (value: any, dateString: any) => {
        setSchoolOrdersCreateTimeStart(dateString[0]);
        setSchoolOrdersCreateTimeEnd(dateString[1]);
    };

    const [op, setOp] = useState(1);
    function changeOpSubmit(e: number) {
        setOp(e);
    }

    //完成
    const onFinish = (values: any) => {
        if (op === 1) {
            setParam({
                ...param,
                schoolOrdersSn: values.schoolOrdersSn,
                schoolOrdersCreateTimeStart: schoolOrdersCreateTimeStart,
                schoolOrdersCreateTimeEnd: schoolOrdersCreateTimeEnd,
                schoolId: values.schoolId,
                agencyId: values.agencyId,
                plannerId: values.plannerId,
                schoolOrdersState: values.schoolOrdersState,
                schoolOrdersStudentName: values.schoolOrdersStudentName,
                schoolOrdersStudentTel: values.schoolOrdersStudentTel,
                memberNickname: values.memberNickname,
                pageNum: null,
            });
        } else if (op === 2) {
            let schoolOrdersSn = values.schoolOrdersSn;
            let schoolOrdersStudentName = values.schoolOrdersStudentName;
            let schoolOrdersStudentTel = values.schoolOrdersStudentTel;
            let memberNickname = values.memberNickname;
            let schoolOrdersState = values.schoolOrdersState;
            let schoolId = values.schoolId;
            let agencyId = values.agencyId;
            let plannerId = values.plannerId;
            let token = data?.adminAccount?.token;
            if (schoolOrdersSn === undefined) {
                schoolOrdersSn = "";
            }
            if (schoolOrdersStudentName === undefined) {
                schoolOrdersStudentName = "";
            }
            if (schoolOrdersStudentTel === undefined) {
                schoolOrdersStudentTel = "";
            }
            if (memberNickname === undefined) {
                memberNickname = "";
            }
            if (schoolOrdersState === undefined) {
                schoolOrdersState = "";
            }
            if (schoolId === undefined) {
                schoolId = "";
            }
            if (agencyId === undefined) {
                agencyId = "";
            }
            if (plannerId === undefined) {
                plannerId = "";
            }
            if (token === undefined) {
                token = "";
            }
            Modal.confirm({
                title: "确定导出当前列表数据吗?",
                content: "点击确定导出",
                okText: "确定",
                onOk(): any {
                    return new Promise((resolve, reject) => {
                        window.location.href = apiUrl + `/schoolOrders/export?schoolOrdersSn=${schoolOrdersSn}&schoolOrdersStudentName=${schoolOrdersStudentName}&schoolOrdersStudentTel=${schoolOrdersStudentTel}&schoolOrdersCreateTimeStart=${schoolOrdersCreateTimeStart}&schoolOrdersCreateTimeEnd=${schoolOrdersCreateTimeEnd}&schoolOrdersState=${schoolOrdersState}&memberNickname=${memberNickname}&schoolId=${schoolId}&agencyId=${agencyId}&plannerId=${plannerId}&token=${token}`;
                        message.success("导出成功");
                        Modal.destroyAll();
                    }).catch(() => console.log("Oops errors!"));
                },
                cancelText: "取消",
            });
        }
    };

    const { mutateAsync } = useComfirmPaySchoolOrders();
    const comfirmPaySchoolOrders = (schoolOrdersSn: string) => {
        Modal.confirm({
            title: "确定操作这条数据吗?",
            content: "点击确定操作",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ schoolOrdersSn }).then((data) => {
                        if (data.status === 0) {
                            message.info('操作成功');
                            Modal.destroyAll();
                        } else {
                            message.error(data.msg)
                        }
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };

    //分页
    return (
        <div>
            <Card style={{ backgroundColor: "#f2f3f5", marginBottom: 24 }}>
                <Form onFinish={onFinish}>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item name="schoolOrdersStudentName" label="学员姓名">
                                <Input placeholder="请输入学员姓名" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="schoolOrdersStudentTel" label="学员手机">
                                <Input placeholder="请输入学员手机" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="memberNickname" label="用户昵称">
                                <Input placeholder="请输入用户昵称" />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item name="schoolOrdersCreateTimeRange" label="下单时间" {...rangeConfig}>
                                <RangePicker
                                    onChange={handleSelectTime}
                                    showTime
                                    format="YYYY-MM-DD HH:mm:ss"
                                    style={{ width: '100%' }}
                                />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="schoolOrdersSn" label="订单编号">
                                <Input placeholder="请输入订单编号" />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="schoolOrdersState" label="订单状态">
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder="请选择订单状态"
                                    optionFilterProp="children"
                                >
                                    <Option value={100}>全部</Option>
                                    <Option value={10}>待付款</Option>
                                    <Option value={15}>已上传凭证</Option>
                                    <Option value={20}>已完成</Option>
                                    <Option value={0}>已取消</Option>
                                    <Option value={30}>售后中</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item name="schoolId" label="所属学校">
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder="请选择所属学校"
                                    optionFilterProp="children"
                                >
                                    {schoolOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="agencyId" label="所属机构">
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder="请选择所属机构"
                                    optionFilterProp="children"
                                >
                                    {agencyOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="plannerId" label="所属咨询师">
                                <Select
                                    showSearch
                                    allowClear
                                    placeholder="请选择所属咨询师"
                                    optionFilterProp="children"
                                >
                                    {plannerOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Button
                            onClick={() => changeOpSubmit(1)}
                            style={{ marginLeft: 70 }}
                            type="primary"
                            htmlType="submit"
                        >
                            提交
                        </Button>
                        <Button
                            onClick={() => changeOpSubmit(2)}
                            style={{ marginLeft: 20 }}
                            type="primary"
                            htmlType="submit"
                        >
                            导出
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
            <Table
                loading={isLoading}
                rowKey={columns => columns.schoolOrdersId}
                pagination={paginationProps}
                columns={[
                    {
                        title: '编号',
                        dataIndex: 'schoolOrdersSn',
                        key: 'schoolOrdersSn',
                        align: 'center',
                        width: 200,
                        fixed: 'left',
                    },
                    {
                        title: '专业',
                        dataIndex: 'majorName',
                        key: 'majorName',
                        align: 'center',
                        width: 200,
                    },
                    {
                        title: '实付金额',
                        dataIndex: 'schoolOrdersActuallyAmount',
                        key: 'schoolOrdersActuallyAmount',
                        align: 'center',
                        width: 150,
                    },
                    {
                        title: '学员',
                        dataIndex: 'schoolOrdersStudentName',
                        key: 'schoolOrdersStudentName',
                        align: 'center',
                        width: 200,
                        render: (values, schoolOrders) => (
                            <div>
                                {values ? <div>{values}</div> : ''}
                                {schoolOrders.schoolOrdersStudentTel ? <div>{schoolOrders.schoolOrdersStudentTel}</div> : ''}
                            </div>
                        )
                    },
                    {
                        title: '用户',
                        dataIndex: 'memberNickname',
                        key: 'memberNickname',
                        align: 'center',
                        width: 150,
                    },
                    {
                        title: '学校',
                        dataIndex: 'schoolName',
                        key: 'schoolName',
                        align: 'center',
                        width: 200,
                    },
                    {
                        title: '机构',
                        dataIndex: 'agencyName',
                        key: 'agencyName',
                        align: 'center',
                        width: 200,
                    },
                    {
                        title: '咨询师',
                        dataIndex: 'plannerName',
                        key: 'plannerName',
                        align: 'center',
                        width: 200,
                    },
                    {
                        title: '地区',
                        dataIndex: 'provinceAreaName',
                        key: 'provinceAreaName',
                        align: 'center',
                        width: 200,
                        render: (values, schoolOrders) => (
                            values || schoolOrders.cityAreaName ? values + schoolOrders.cityAreaName : '-'
                        )
                    },
                    {
                        title: '订单状态',
                        dataIndex: 'schoolOrdersStateValue',
                        key: 'schoolOrdersStateValue',
                        align: 'center',
                        width: 150,
                    },
                    {
                        title: '售后状态',
                        dataIndex: 'schoolOrdersRefundState',
                        key: 'schoolOrdersRefundState',
                        align: 'center',
                        width: 150,
                        render: (values) => (
                            values === 0 ? '无售后' : values === 10 ? '售后中' : values === 20 ? '售后驳回' : values === 30 ? '售后同意'
                                : values === 60 ? '售后关闭' : values === 70 ? '售后成功' : '-'
                        )
                    },
                    {
                        title: '下单时间',
                        dataIndex: 'schoolOrdersCreateTime',
                        key: 'schoolOrdersCreateTime',
                        align: 'center',
                        width: 250,
                        render: (values) => (
                            values ? dayjs(values).format('YYYY-MM-DD hh:mm:ss') : '-'
                        )
                    },
                    {
                        title: '支付方式',
                        dataIndex: 'schoolOrdersPaymentName',
                        key: 'schoolOrdersPaymentName',
                        align: 'center',
                        width: 150,
                    },
                    {
                        title: '支付凭证',
                        dataIndex: 'schoolOrdersPaymentCert',
                        key: 'schoolOrdersPaymentCert',
                        align: 'center',
                        width: 100,
                        render: (values) => (
                            values ? <Image src={imgUrl + values} width={60}></Image> : '-'
                        )
                    },
                    {
                        title: '支付时间',
                        dataIndex: 'schoolOrdersPaymentTime',
                        key: 'schoolOrdersPaymentTime',
                        align: 'center',
                        width: 250,
                        render: (values) => (
                            values ? dayjs(values).format('YYYY-MM-DD hh:mm:ss') : '-'
                        )
                    },
                    {
                        title: '操作',
                        key: 'action',
                        align: 'center',
                        render: (value, schoolOrders) => (
                            <Space size="middle">
                                {schoolOrders.schoolOrdersPaymentType === 0 && schoolOrders.schoolOrdersState === 15 ? (
                                    <a onClick={() => comfirmPaySchoolOrders(schoolOrders.schoolOrdersSn)}>
                                        确认支付
                                    </a>
                                ) : ('')}
                            </Space>
                        ),
                        width: 150,
                        fixed: 'right',
                    }
                ]}
                dataSource={list || []}
                scroll={{ x: 2900 }}
            />
            <ErrorBox error={error} />
        </div>
    )
}


