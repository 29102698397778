import { useMemo } from "react";
import { useUrlQueryParam } from "../../utils/url";

export const useSchoolAuthSearchParams = () => {
    const [param, setParam] = useUrlQueryParam(["schoolAuthId", "schoolAuthTimeStart", "schoolAuthTimeEnd", "schoolName", "schoolAuthState", "memberNickname", "pageNum"]);
    return [
        useMemo(() => ({
            ...param, schoolAuthId: Number(param.schoolAuthId) || undefined, schoolAuthState: Number(param.schoolAuthState) || undefined,
            pageNum: Number(param.pageNum) || undefined
        }), [param]),
        setParam
    ] as const
}