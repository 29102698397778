import { useMutation, useQuery, useQueryClient } from "react-query";
import { Transport } from "../interfaces/transport";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useTransportList = (param?: Partial<Transport>) => {
    const client = useHttp();
    const result = useQuery<any>(['transportList', param], () => client('transport', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditTransport = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Transport>) => client(`transport`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('transportList')
        }
    )
}

export const useAddTransport = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Transport>) => client(`transport`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('transportList')
        }
    )
}

export const useDeleteTransport = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ transportId }: { transportId: number}) =>
            client(`transport/${transportId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('transportList')
        }
    );
};

export const useTransport = (transportId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['transport', {transportId}],
        () => client(`transport/${transportId}`),
        {
            enabled: Boolean(transportId)
        }
    )
}

export const useTransportMutation = () => {
    const client = useHttp();
    return useMutation(
        (params: Partial<Transport>) =>
            client(`transport/${params.transportId}`, {
                method: "GET"
            }),
    );
};

export const useGoodsTransport = (goodsId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['goodsTransport', {goodsId}],
        () => client(`transport/${goodsId}/goods`),
        {
            enabled: Boolean(goodsId)
        }
    )
}

/**查询全部 */
export const useTransportListAll = (param?: Partial<Transport>) => {
    const client = useHttp();
    const result = useQuery<any>(["transportListAll", param], () =>
        client("transport/listAll", { data: param })
    );
    const data = result.data?.data;
    return {...result, data };
};