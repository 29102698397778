import { Table, Space, Row, Col, Button, Input, Modal, message } from 'antd';
import { useAdminRoleList, useDeleteAdminRole } from '../../services/admin-role-service';
import { useDebounce, useDocumentTitle } from '../../utils';
import { useAdminRoleSearchParams } from './util';
import { ErrorBox } from '../../components/lib';
import { Link } from 'react-router-dom';
const { Search } = Input;

export const AdminRoleListPage = () => {
  useDocumentTitle('角色列表', true)
  //参数
  const [param, setParam] = useAdminRoleSearchParams();
  const { isLoading, error, totalElements, data: list } = useAdminRoleList(useDebounce(param, 500));
  //分页
  const paginationProps = {
    defaultCurrent: 1,
    showQuickJumper: true,
    pageSize: 10,
    showSizeChanger: false,
    total: totalElements,
    onChange: (current: number) =>
      setParam({
        ...param,
        pageNum: current - 1,
      }),
  };
  //删除事件
  const useMutateDeleteAdminRole = useDeleteAdminRole;
  const { mutateAsync } = useMutateDeleteAdminRole();
  const confirmDeleteAdminRole = (adminRoleId: number) => {
    Modal.confirm({
      title: "确定删除这条数据吗?",
      content: "点击确定删除",
      okText: "确定",
      onOk(): any {
        return new Promise((resolve, reject) => {
          mutateAsync({ adminRoleId }).then((data) => {
            if (data.status === 0) {
              message.info('删除成功');
              Modal.destroyAll();
            } else {
              message.error(data.msg)
            }
          });
        }).catch(
          () => console.log('Oops errors!')
        );
      },
      cancelText: "取消"
    });
  };
  return (
    <div>
      <Row style={{ marginBottom: 24 }}>
        <Col span={12}>
          <Link to={"/setting/account-manage/admin-role/add"}>
            <Button type="primary">新建</Button>
          </Link>
        </Col>
        <Col span={12} style={{ textAlign: 'right' }}>
          <Search
            placeholder='请输入角色名称'
            style={{ width: 200 }}
            onChange={evt =>
              setParam({
                ...param,
                adminRoleName: evt.target.value,
                pageNum: null,
              })
            }
            onSearch={value =>
              setParam({
                ...param,
                adminRoleName: value,
                pageNum: null,
              })
            }
          />
        </Col>
      </Row>
      <Table
        loading={isLoading}
        rowKey={columns => columns.roleId}
        pagination={paginationProps}
        columns={[
          {
            title: 'ID',
            dataIndex: 'adminRoleId',
            key: 'adminRoleId',
            width: 80,
            align: 'center',
          },
          {
            title: '名称',
            dataIndex: 'adminRoleName',
            key: 'adminRoleName',
            align: 'center',
          },
          {
            title: '排序',
            dataIndex: 'adminRoleSort',
            key: 'adminRoleSort',
            align: 'center',
          },
          {
            title: '操作',
            key: 'action',
            width: 150,
            align: 'center',
            render: (value, adminRole) => (
              <Space size='middle'>
                <Link to={'/setting/account-manage/admin-role/update/' + adminRole.adminRoleId}>编辑</Link>
                {adminRole.adminRoleId !== 1 ? (
                  <a onClick={() => confirmDeleteAdminRole(adminRole.adminRoleId)}>删除</a>
                ) : ('')}
              </Space>
            ),
          },
        ]}
        dataSource={list || []}
      />
      <ErrorBox error={error} />
    </div>
  );
};
