import { Button, Card, Col, Divider, Form, Input, message, Row, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAddAgentWithdrawCash } from "../../services/agent-withdraw-cash-service";
import { useDocumentTitle } from "../../utils"
import { useAgentBankAccountListAll } from "../../services/bank-account-service";
import { useSelfAgent } from "../../services/agent-service";
export const AgentWithdrawCashAddPage = () => {
    //标题
    useDocumentTitle('新建代理商提现', true)
    const navigate = useNavigate();

    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };

    //表单
    const [form] = useForm();

    //新建
    const [submitLoading, setSubmitLoading] = useState(false);
    const { mutateAsync } = useAddAgentWithdrawCash();
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...values }).then((data) => {
            if (data.status === 0) {
                message.success(data.msg);
                navigate('/wallet/wallet-manage/agent-withdraw-cash/list')
            } else {
                setSubmitLoading(false);
                message.error(data.msg);
            }
        }).catch(err => {
            message.error('提交失败，请重新提交');
            setSubmitLoading(false);
        });
    };

    const { data: agent } = useSelfAgent();
    const { data: bankAccountList } = useAgentBankAccountListAll();
    const bankAccountListOption = bankAccountList?.map((item: any) => (
        <>
            <Select.Option key={item.bankAccountId} value={item.bankAccountId}>
                {item.bankName} {item.bankAccountCardNo} {item.bankAccountTrueName}
            </Select.Option>
        </>
    ))
    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/wallet/wallet-manage/agent-withdraw-cash/list"}>
                        <Button type="primary">列表</Button>
                    </Link>
                </Col>
            </Row>

            <Divider style={{ marginBottom: 24 }} />
            {agent ? <>
                <Card title="代理商信息">
                    <Card.Grid style={{
                        width: '33%',
                        textAlign: 'center',
                    }}>可提现金额:{agent.agentMoney}</Card.Grid>
                    <Card.Grid style={{
                        width: '33%',
                        textAlign: 'center',
                    }}>总收入:{agent.agentMoneyTotal}</Card.Grid>
                    <Card.Grid style={{
                        width: '33%',
                        textAlign: 'center',
                    }}>已体现金额:{agent.agentMoneyUse}</Card.Grid>
                </Card>
            </> : ""}
            <Divider style={{ marginBottom: 24 }} />
            <Form
                form={form}
                {...layout}
                onFinish={onFinish}
            >

                <Form.Item
                    label={"申请金额"}
                    name={"withdrawAmount"}
                    rules={[{ required: true, message: "金额必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入金额"} />
                </Form.Item>

                {/* <Form.Item
                    label={"手续费"}
                    name={"agentWithdrawCashCharges"}
                    rules={[{ required: true, message: "手续费必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入手续费"} />
                </Form.Item> */}

                <Form.Item
                    label={"收款账号"}
                    name={"bankAccountId"}
                    rules={[{ required: true, message: "收款账号必选" }]}
                >
                    <Select showSearch placeholder={"选择账号"} style={{ width: 380 }} >
                        {bankAccountListOption}
                    </Select>
                </Form.Item>

                <Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                    <Button loading={submitLoading} type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}