import { useQuery } from "react-query";
import { useHttp } from "../utils/http";

export const useSchoolSubjectDetail = (schoolSubjectId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['schoolSubjectDetail', {schoolSubjectId}],
        () => client(`schoolSubjectDetail/${schoolSubjectId}`),
        {
            enabled: Boolean(schoolSubjectId)
        }
    )
}