import { useMutation, useQuery, useQueryClient } from "react-query";
import { RecruitEducation } from "../interfaces/recruit-education";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useRecruitEducationList = (param?: Partial<RecruitEducation>) => {
    const client = useHttp();
    const result = useQuery<any>(['recruitEducationPage', param], () => client('recruitEducation', { data: cleanObject(param) }))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return { ...result, totalElements, data };
}

export const useEditRecruitEducation = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<RecruitEducation>) => client(`recruitEducation`, {
            method: 'PUT',
            data: params
        }), {
        onSuccess: () => queryClient.invalidateQueries('recruitEducationPage')
    }
    )
}

export const useAddRecruitEducation = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<RecruitEducation>) => client(`recruitEducation`, {
            data: params,
            method: 'POST'
        }), {
        onSuccess: () => queryClient.invalidateQueries('recruitEducationPage')
    }
    )
}

export const useDeleteRecruitEducation = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ recruitEducationId }: { recruitEducationId: number }) =>
            client(`recruitEducation/${recruitEducationId}`, {
                method: "DELETE",
            }), {
        onSuccess: () => queryClient.invalidateQueries('recruitEducationPage')
    }
    );
};

export const useRecruitEducation = (recruitEducationId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['recruitEducation', { recruitEducationId }],
        () => client(`recruitEducation/${recruitEducationId}`),
        {
            enabled: Boolean(recruitEducationId)
        }
    )
}

export const useRecruitEducationListAll = () => {
    const client = useHttp();
    return useQuery<any>(["recruitEducationList", {}], () =>
        client(`recruitEducation/list`)
    );
};