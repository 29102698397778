import { useUrlQueryParam } from "../../utils/url";
import { useMemo } from "react";

export const useAdminRoleSearchParams = () => {
  const [param, setParam] = useUrlQueryParam(["adminRoleName", "pageNum", "adminRoleId"]);
  return [
    useMemo(
      () => ({ ...param, adminRoleId: Number(param.adminRoleId) || undefined, pageNum: Number(param.pageNum) || undefined }),
      [param]
    ),
    setParam,
  ] as const;
};

export const useAdminAuthoritySearchParams = ( adminRoleId: number ) => {
  const [param, setParam] = useUrlQueryParam(["adminRoleId"]);
  return [
      useMemo(() => ({...param, adminRoleId: Number(adminRoleId) || undefined}),[param, adminRoleId]),
      setParam
  ] as const
}
