import { useMutation, useQuery, useQueryClient } from "react-query";
import { Agency } from "../interfaces/agency";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useAgencyList = (param?: Partial<Agency>) => {
    const client = useHttp();
    const result = useQuery<any>(['agencyPage', param], () => client('agency', { data: cleanObject(param) }))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return { ...result, totalElements, data };
}
export const useAgencyNoVerList = (param?: Partial<Agency>) => {
    const client = useHttp();
    const result = useQuery<any>(['agencyNoVerPage', param], () => client('agency/noVer', { data: cleanObject(param) }))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return { ...result, totalElements, data };
}
export const useEditAgency = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Agency>) => client(`agency`, {
            method: 'PUT',
            data: params
        }), {
        onSuccess: () => {
            queryClient.invalidateQueries('agencyPage')
            queryClient.invalidateQueries('agencyNoVerPage')
        }
    }
    )
}

export const useAddAgency = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Agency>) => client(`agency`, {
            data: params,
            method: 'POST'
        }), {
        onSuccess: () => {
            queryClient.invalidateQueries('agencyPage')
            queryClient.invalidateQueries('agencyNoVerPage')
        }
    }
    )
}

export const useRejectAgency = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ agencyId }: { agencyId: number }) =>
            client(`agency/${agencyId}`, {
                method: "DELETE",
            }), {
        onSuccess: () => {
            queryClient.invalidateQueries('agencyPage')
            queryClient.invalidateQueries('agencyNoVerPage')
        }
    }
    );
};
export const useAgreeAgency = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ agencyId }: { agencyId: number }) =>
            client(`agency/${agencyId}`, {
                method: "POST",
            }), {
        onSuccess: () => {
            queryClient.invalidateQueries('agencyPage')
            queryClient.invalidateQueries('agencyNoVerPage')
        }
    }
    );
};
export const useAgency = (agencyId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['agency', { agencyId }],
        () => client(`agency/${agencyId}`),
        {
            enabled: Boolean(agencyId)
        }
    )
}

export const useAgencyListAll = () => {
    const client = useHttp();
    return useQuery<any>(["agencyList", {}], () =>
        client(`agency/list`)
    );
};