import { useUrlQueryParam } from "../../utils/url";
import { useMemo } from "react";

export const useSeckillSearchParams = () => {
    const [param, setParam] = useUrlQueryParam(["seckillId", "seckillTitle", "seckillState", "pageNum"]);
    return [
        useMemo(
          () => ({ ...param, seckillId: Number(param.seckillId) || undefined, pageNum: Number(param.pageNum) || undefined }),
          [param]
        ),
        setParam,
    ] as const;
};
