import { useMemo } from "react";
import { useUrlQueryParam } from "../../utils/url";

export const useReportSearchParams = () => {
  const [param, setParam] = useUrlQueryParam([
    "reportId",
    "reportClassId",
    "reportIdentityName",
    "memberNickname",
    "reportAddTimeStart",
    "reportAddTimeEnd",
    "pageNum",
  ]);
  return [
    useMemo(
      () => ({
        ...param,
        reportId: Number(param.reportId) || undefined,
        reportClassId: Number(param.reportClassId) || undefined,
        pageNum: Number(param.pageNum) || undefined,
      }),
      [param]
    ),
    setParam,
  ] as const;
};
