import { useQuery } from "react-query";
import { SchoolAppoint } from "../interfaces/school-appoint";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useSchoolAppointList = (param?: Partial<SchoolAppoint>) => {
    const client = useHttp();
    const result = useQuery<any>(['schoolAppointPage', param], () => client('schoolAppoint', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}