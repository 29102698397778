import { useMutation, useQuery, useQueryClient } from 'react-query';
import { useHttp } from '../utils/http';
import { AdminRole } from '../interfaces/admin-role';
import { cleanObject } from '../utils/index';

//列表-分页
export const useAdminRoleList = (param?: Partial<AdminRole>) => {
  const client = useHttp();
  const result = useQuery<any>(['adminRolePage', param], () => client('adminRole', { data: cleanObject(param) }))
  const totalElements = result.data?.data?.totalElements;
  const data = result.data?.data?.content;
  return { ...result, totalElements, data };
}

//新增
export const useAddAdminRole = () => {
  const client = useHttp();
  const queryClient = useQueryClient();
  return useMutation((params: Partial<AdminRole>) =>
    client(`adminRole`, {
      data: params,
      method: 'POST',
    }), {
    onSuccess: () => queryClient.invalidateQueries('adminRolePage')
  }
  );
};

//编辑
export const useEditAdminRole = () => {
  const client = useHttp();
  const queryClient = useQueryClient();
  return useMutation((params: Partial<AdminRole>) =>
    client(`adminRole`, {
      data: params,
      method: 'PUT',
    }), {
    onSuccess: () => queryClient.invalidateQueries('adminRolePage')
  }
  );
};

//删除
export const useDeleteAdminRole = () => {
  const client = useHttp();
  const queryClient = useQueryClient();
  return useMutation(({ adminRoleId }: { adminRoleId: number }) =>
    client(`adminRole/${adminRoleId}`, {
      method: 'DELETE',
    }), {
    onSuccess: () => queryClient.invalidateQueries('adminRolePage')
  }
  );
};

//详情
export const useAdminRole = (adminRoleId?: number) => {
  const client = useHttp();
  return useQuery<any>(['adminRoleDetail', { adminRoleId }], () => client(`adminRole/${adminRoleId}`), {
    enabled: Boolean(adminRoleId),
  });
};

// 角色列表 list
export const useAdminRoleListAll = () => {
  const client = useHttp();
  return useQuery<any>(["adminRoleList", {}], () =>
    client(`adminRole/list`)
  );
};
