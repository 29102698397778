import { Breadcrumb, Button, Card, Col, Form, Row, Table, DatePicker, Select } from "antd"
import dayjs from "dayjs"
import { useState } from "react"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useMoneyLogList } from "../../services/money-log-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useMoneyLogSearchParams } from "./util"
const { RangePicker } = DatePicker
const { Option } = Select

export const MoneyLogListPage = () => {
    useDocumentTitle('余额明细', true)
    const [param, setParam] = useMoneyLogSearchParams()
    const { isLoading, error, totalElements, data: list } = useMoneyLogList(useDebounce(param, 200))
    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) =>
            setParam({
                ...param,
                pageNum: (current - 1),
            })
    }
    const rangeConfig = {
        rules: [{ type: 'array' as const, message: '请选择时间!' }],
    }
    //注册时间
    const [moneyLogTimeStart, setMoneyLogTimeStart] = useState('')
    const [moneyLogTimeEnd, setMoneyLogTimeEnd] = useState('')
    const handleSelectTime = (value: any, dateString: any) => {
        setMoneyLogTimeStart(dateString[0])
        setMoneyLogTimeEnd(dateString[1])
    }
    const onFinish = (values: any) => {
        setParam({
            ...param,
            moneyLogTimeStart: moneyLogTimeStart,
            moneyLogTimeEnd: moneyLogTimeEnd,
            moneyLogOperation: values.moneyLogOperation,
            pageNum: null,
        })
    }
    //分页
    return (
        <div>
            <Breadcrumb style={{ marginBottom: 20 }}>
                <Breadcrumb.Item>
                    <Link to={'/user/user-manage/member/list'}>客户列表</Link>
                </Breadcrumb.Item>
                <Breadcrumb.Item>余额明细</Breadcrumb.Item>
            </Breadcrumb>
            <Card style={{ backgroundColor: '#f2f3f5', marginBottom: 24 }}>
                <Form onFinish={onFinish}>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item name='moneyLogTimeRange' label='选择时间' {...rangeConfig}>
                                <RangePicker onChange={handleSelectTime} showTime format='YYYY-MM-DD HH:mm:ss' />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name='moneyLogOperation' label='操作方式'>
                                <Select showSearch allowClear placeholder='请选择操作方式' optionFilterProp='children'>
                                    <Option value={''}>全部</Option>
                                    <Option value={'add'}>增加</Option>
                                    <Option value={'sub'}>减少</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item>
                        <Button style={{ marginLeft: 70 }} type='primary' htmlType='submit'>
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
            <Table
                loading={isLoading}
                rowKey={columns => columns.moneyLogId}
                pagination={paginationProps}
                columns={[
                    {
                        title: '明细编号',
                        dataIndex: 'moneyLogSn',
                        key: 'moneyLogSn',
                    },
                    {
                        title: '变化前金额',
                        dataIndex: 'moneyLogStartMoney',
                        key: 'moneyLogStartMoney',
                        render(value) {
                            return <span>￥{value}</span>
                        },
                    },
                    {
                        title: '变化金额',
                        dataIndex: 'moneyLogChangeMoney',
                        key: 'moneyLogChangeMoney',
                        render(value) {
                            return <span>￥{value}</span>
                        },
                    },
                    {
                        title: '变化后金额',
                        dataIndex: 'moneyLogEndMoney',
                        key: 'moneyLogEndMoney',
                        render(value) {
                            return <span>￥{value}</span>
                        },
                    },
                    {
                        title: '操作方式',
                        dataIndex: 'moneyLogOperation',
                        key: 'moneyLogOperation',
                        render(value) {
                            return <span>{value === 'add' ? "增加" : value === 'sub' ? "减少" : '无'}</span>
                        },
                    },
                    {
                        title: '变化原因',
                        dataIndex: 'moneyLogChangeReason',
                        key: 'moneyLogChangeReason',
                    },
                    {
                        title: '变化时间',
                        dataIndex: 'moneyLogTime',
                        key: 'moneyLogTime',
                        render(value) {
                            return <span>{value ? dayjs(value).format('YYYY-MM-DD HH:mm:ss') : '无'}</span>
                        },
                    },
                ]}
                dataSource={list || []} />
            <ErrorBox error={error} />
        </div>
    )
}