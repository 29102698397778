import { useMutation, useQuery, useQueryClient } from "react-query";
import { StoreAuth } from "../interfaces/store-auth";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useStoreAuthList = (param?: Partial<StoreAuth>) => {
    const client = useHttp();
    const result = useQuery<any>(['storeAuthPage', param], () => client('storeAuth', { data: cleanObject(param) }))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return { ...result, totalElements, data };
}

export const useAgreeStoreAuth = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ storeAuthId }: { storeAuthId: number }) => client(`storeAuth/agree?storeAuthId=${storeAuthId}`, {
            method: "POST",
        }), {
        onSuccess: () => queryClient.invalidateQueries('storeAuthPage')
    }
    );
}

export const useRejectStoreAuth = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<StoreAuth>) => client(`storeAuth/reject`, {
            data: params,
            method: 'POST'
        }), {
        onSuccess: () => queryClient.invalidateQueries('storeAuthPage')
    }
    );
}