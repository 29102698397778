import { useMutation, useQuery, useQueryClient } from "react-query";
import { Course } from "../interfaces/course";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useCourseList = (param?: Partial<Course>) => {
    const client = useHttp();
    const result = useQuery<any>(['coursePage', param], () => client('course', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditCourse = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Course>) => client(`course`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('coursePage')
        }
    )
}

export const useAddCourse = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Course>) => client(`course`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('coursePage')
        }
    )
}

export const useDeleteCourse = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ courseId }: { courseId: number}) =>
            client(`course/${courseId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('coursePage')
        }
    );
};

export const useOffCourse = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ courseId }: { courseId: number}) =>
            client(`course/off/${courseId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('coursePage')
        }
    );
};
export const useUpCourse = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ courseId }: { courseId: number}) =>
            client(`course/up/${courseId}`, {
            method: "PUT",
        }), {
            onSuccess: () => queryClient.invalidateQueries('coursePage')
        }
    );
};

export const useCourse = (courseId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['course', {courseId}],
        () => client(`course/${courseId}`),
        {
            enabled: Boolean(courseId)
        }
    )
}

export const useOffCourseSection = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ courseSectionId }: { courseSectionId: number}) =>
            client(`courseSection/off/${courseSectionId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('course')
        }
    );
};
export const useUpCourseSection = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ courseSectionId }: { courseSectionId: number}) =>
            client(`courseSection/up/${courseSectionId}`, {
            method: "PUT",
        }), {
            onSuccess: () => queryClient.invalidateQueries('course')
        }
    );
};