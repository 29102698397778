import { Button, Col, message, Modal, Row, Space, Table } from "antd"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { usePlannerEvaEasilyTagList, useDeletePlannerEvaEasilyTag } from "../../services/planner-eva-easily-tag-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { usePlannerEvaEasilyTagSearchParams } from "./util"

export const PlannerEvaEasilyTagListPage = () => {
    useDocumentTitle('规划师评价快捷标签列表', true)
    const [param, setParam] = usePlannerEvaEasilyTagSearchParams()
    const { isLoading, error, totalElements, data: list } = usePlannerEvaEasilyTagList(useDebounce(param, 200))

    //分页
    const paginationProps = {
        showSizeChanger: true,
        onShowSizeChange: (current: number, pageSize: number) => {
            setParam({
                ...param,
                pageNum: current - 1,
                pageSize,
            });
        },
        defaultCurrent: 1,
        pageSize: param.pageSize,
        total: totalElements,
        onChange: (current: number, pageSize: number) =>
            setParam({
                ...param,
                pageNum: current - 1,
                pageSize,
            }),
    };
    //搜索
    const onFinish = (values: any) => {
        setParam({
            ...param,
            plannerEvaEasilyTagName: values.plannerEvaEasilyTagName,
            pageNum: null,
        });
    };
    //删除事件
    const { mutateAsync } = useDeletePlannerEvaEasilyTag();
    const confirmDeletePlannerEvaEasilyTag = (plannerEvaEasilyTagId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ plannerEvaEasilyTagId }).then((data) => {
                        if (data.status === 0) {
                            message.info('删除成功');
                            Modal.destroyAll();
                        } else {
                            message.error(data.msg)
                        }
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };
    //分页
    return (
        <div>
            <Row style={{ marginBottom: 24 }}>
                <Col span={12}>
                    <Link to={"/jigou/planner-manage/planner-eva-easily-tag/add"}>
                        <Button type="primary">新建</Button>
                    </Link>
                </Col>
            </Row>
            <Table
                loading={isLoading}
                rowKey={columns => columns.plannerEvaEasilyTagId}
                pagination={paginationProps}
                columns={[
                    {
                        title: 'ID',
                        dataIndex: 'plannerEvaEasilyTagId',
                        key: 'plannerEvaEasilyTagId',
                        align: 'center',
                        width: 100,
                    },

                    {
                        title: '名称',
                        dataIndex: 'plannerEvaEasilyTagName',
                        key: 'plannerEvaEasilyTagName',
                        align: 'center',
                    },

                    {
                        title: '排序',
                        dataIndex: 'plannerEvaEasilyTagSort',
                        key: 'plannerEvaEasilyTagSort',
                        align: 'center',
                    },

                    {
                        title: '是否显示',
                        dataIndex: 'plannerEvaEasilyTagShow',
                        key: 'plannerEvaEasilyTagShow',
                        align: 'center',
                        render: (value) => (
                            <span>{value ? '显示' : '不显示'}</span>
                        ),
                    },

                    {
                        title: '操作',
                        key: 'action',
                        align: 'center',
                        render: (value, plannerEvaEasilyTag) => (
                            <Space size="middle">
                                <Link to={"/jigou/planner-manage/planner-eva-easily-tag/update/" + plannerEvaEasilyTag.plannerEvaEasilyTagId}>
                                    编辑
                                </Link>
                                <a onClick={() => confirmDeletePlannerEvaEasilyTag(plannerEvaEasilyTag.plannerEvaEasilyTagId)}>
                                    删除
                                </a>
                            </Space>
                        ),
                        width: 150,
                    }
                ]}
                dataSource={list || []} />
            <ErrorBox error={error} />
        </div>
    )
}