import { useMutation, useQuery, useQueryClient } from "react-query";
import { Notice } from "../interfaces/notice";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useNoticeList = (param?: Partial<Notice>) => {
    const client = useHttp();
    const result = useQuery<any>(['noticePage', param], () => client('notice', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditNotice = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Notice>) => client(`notice`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('noticePage')
        }
    )
}

export const useAddNotice = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Notice>) => client(`notice`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('noticePage')
        }
    )
}

export const useDeleteNotice = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ noticeId }: { noticeId: number}) =>
            client(`notice/${noticeId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('noticePage')
        }
    );
};

export const useNotice = (noticeId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['notice', {noticeId}],
        () => client(`notice/${noticeId}`),
        {
            enabled: Boolean(noticeId)
        }
    )
}