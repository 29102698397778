import { useQuery } from "react-query";
import { MemberBrowse } from "../interfaces/member-browse";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useMemberBrowseList = (param?: Partial<MemberBrowse>) => {
    const client = useHttp();
    const result = useQuery<any>(['memberBrowsePage', param], () => client('memberBrowse', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}