import { useMemo } from "react";
import { useUrlQueryParam } from "../../utils/url";

export const useSalesmanAuthSearchParams = () => {
    const [param, setParam] = useUrlQueryParam(["salesmanAuthId", "salesmanAuthTimeStart", "salesmanAuthTimeEnd", "salesmanName", "salesmanAuthState", "memberNickname", "pageNum"]);
    return [
        useMemo(() => ({
            ...param, salesmanAuthId: Number(param.salesmanAuthId) || undefined, salesmanAuthState: Number(param.salesmanAuthState) || undefined,
            pageNum: Number(param.pageNum) || undefined
        }), [param]),
        setParam
    ] as const
}