import { useQuery } from "react-query";
import { useHttp } from "../utils/http";

export const useSchoolNoticeDetail = (schoolNoticeId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['schoolNoticeDetail', {schoolNoticeId}],
        () => client(`schoolNoticeDetail/${schoolNoticeId}`),
        {
            enabled: Boolean(schoolNoticeId)
        }
    )
}