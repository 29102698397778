import { useMutation, useQuery, useQueryClient } from "react-query";
import { MallAdv } from "../interfaces/mall-adv";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useAdvList = (param?: Partial<MallAdv>) => {
    const client = useHttp();
    const result = useQuery<any>(['advList', param], () => client('mallAdv', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditAdv = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<MallAdv>) => client(`mallAdv`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('advList')
        }
    )
}

export const useAddAdv = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<MallAdv>) => client(`mallAdv`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('advList')
        }
    )
}

export const useDeleteAdv = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ advId }: { advId: number}) =>
            client(`mallAdv/${advId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('advList')
        }
    );
};

export const useAdv = (advId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['adv', {advId}],
        () => client(`mallAdv/${advId}`),
        {
            enabled: Boolean(advId)
        }
    )
}