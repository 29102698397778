import { useQuery } from "react-query";
import { MemberCollect } from "../interfaces/member-collect";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useMemberCollectList = (param?: Partial<MemberCollect>) => {
    const client = useHttp();
    const result = useQuery<any>(['memberCollectPage', param], () => client('memberCollect', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}