import { useMutation, useQuery, useQueryClient } from "react-query";
import { SchoolSubject } from "../interfaces/school-subject";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useSchoolSubjectList = (param?: Partial<SchoolSubject>) => {
    const client = useHttp();
    const result = useQuery<any>(['schoolSubjectPage', param], () => client('schoolSubject', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditSchoolSubject = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<SchoolSubject>) => client(`schoolSubject`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('schoolSubjectPage')
        }
    )
}

export const useAddSchoolSubject = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<SchoolSubject>) => client(`schoolSubject`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('schoolSubjectPage')
        }
    )
}

export const useDeleteSchoolSubject = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ schoolSubjectId }: { schoolSubjectId: number}) =>
            client(`schoolSubject/${schoolSubjectId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('schoolSubjectPage')
        }
    );
};

export const useSchoolSubject = (schoolSubjectId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['schoolSubject', {schoolSubjectId}],
        () => client(`schoolSubject/${schoolSubjectId}`),
        {
            enabled: Boolean(schoolSubjectId)
        }
    )
}