import { Button, Col, Divider, Form, Input, message, Radio, Row, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAddCourseNavigation } from "../../services/course-navigation-service";
import { useDocumentTitle } from "../../utils"
import  UploadPic  from '../../components/upload-pic';
import PageUrlSelect from "../../components/page-url-select";
export const CourseNavigationAddPage = () => {
    //标题
    useDocumentTitle('新建', true)
    const navigate = useNavigate();

    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };

    //表单
    const [form] = useForm();
    const [courseNavigationPic, setCourseNavigationPic] = useState("");
    const [uploading, setUploading] = useState<boolean>(false);

    //新建
    const [submitLoading, setSubmitLoading] = useState(false);
    const { mutateAsync } = useAddCourseNavigation();
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...values, courseNavigationPic }).then((data) => {
            if (data.status === 0) {
                message.success(data.msg);
                navigate('/jigou/course-manage/course-navigation/list')
            } else {
                setSubmitLoading(false);
                message.error(data.msg);
            }
        }).catch(err => {
            message.error('提交失败，请重新提交');
            setSubmitLoading(false);
        });
    };

    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/jigou/course-manage/course-navigation/list"}>
                        <Button type="primary">列表</Button>
                    </Link>
                </Col>
            </Row>

            <Divider style={{ marginBottom: 24 }} />

            <Form
                form={form}
                {...layout}
                onFinish={onFinish}
                initialValues={{
                    courseNavigationSort: 0,
                    courseNavigationShow: true
                }}>
               	<Form.Item
                    label={"课程导航名称"}
                    name={"courseNavigationName"}
                    rules={[{ required: true, message: "课程导航名称必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入课程导航名称"} />
                </Form.Item>

               	<UploadPic
                    label={'图片'}
                    name={'uploadCourseNavigationPic'}
                    pic={courseNavigationPic}
                    setPic={setCourseNavigationPic}
                    action={'add'}
                    style={{ textAlign: 'left' }}
                >
                </UploadPic>
                <Form.Item
                    label={"是否显示"}
                    name={"courseNavigationShow"}
                    rules={[{ required: true, message: "是否显示必选" }]}
                    style={{ textAlign: 'left' }}
                >
                    <Radio.Group>
                        <Radio defaultChecked value={true}>显示</Radio>
                        <Radio value={false}>不显示</Radio>
                    </Radio.Group>
                </Form.Item>
               	<Form.Item
                    label={"排序"}
                    name={"courseNavigationSort"}
                    rules={[{ required: true, message: "排序必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入排序"} />
                </Form.Item>


				<PageUrlSelect></PageUrlSelect>

               <Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                    <Button loading={submitLoading} type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}