import { Button, Col, message, Modal, Row, Space, Table, Image, Card, Form, Input, Avatar, Tag } from "antd";
import { useOffPlanner, useUpPlanner, usePlanner, } from "../../services/planner-service";
import { useDebounce, useDocumentTitle } from "../../utils";
import { usePlannerSearchParams } from "./util";
import { useState } from "react";
import { usePlannerEvaList } from "../../services/planner-eva-service";

export const PlannerDetailPage = () => {
  useDocumentTitle("详情", true);
  const imgUrl = process.env.REACT_APP_IMG_URL;
  const [param, setParam] = usePlannerSearchParams();
  const { data: planner } = usePlanner(param.plannerId);

  //上架事件
  const { mutateAsync: upPlannerFuntion } = useUpPlanner();
  const confirmUpPlanner = (plannerId: number) => {
    Modal.confirm({
      title: "确定通过该规划师吗?",
      content: "点击确定通过",
      okText: "确定",
      onOk(): any {
        return new Promise((resolve, reject) => {
          upPlannerFuntion({ plannerId }).then((data) => {
            if (data.status === 0) {
              message.info("审核成功");
              Modal.destroyAll();
            } else {
              message.error(data.msg);
            }
          });
        }).catch(() => console.log("Oops errors!"));
      },
      cancelText: "取消",
    });
  };
  //下架事件
  const { mutateAsync: offPlannerFuntion } = useOffPlanner();
  const [plannerId, setPlannerId] = useState<number>();
  const [offModalOpen, setOffModalOpen] = useState(false);
  const [offSubmitLoading, setOffSubmitLoading] = useState(false);
  const handleCancel = () => {
    setOffModalOpen(false);
    setPlannerId(undefined);
  };
  const confirmOffPlanner = (plannerId: number) => {
    setPlannerId(plannerId);
    setOffModalOpen(true);
  };
  const onRejectFinish = (values: any) => {
    console.log(values);
    setOffSubmitLoading(true);
    let param = { plannerId, systemRemark: values.systemRemark };
    return new Promise((resolve, reject) => {
      offPlannerFuntion(param)
        .then((data) => {
          if (data.status === 0) {
            message.info("拒绝成功");
            Modal.destroyAll();
          } else {
            message.error(data.msg);
          }
        })
        .finally(() => setOffSubmitLoading(false));
    }).catch(() => console.log("Oops errors!"));
  };
  //多图查看
  const [visible, setVisible] = useState(false);
  const [evaImageList, setEvaImageList] = useState([]);
  const handleImage = (value: []) => {
    setEvaImageList(value);
    setVisible(true);
  };
  const spaceSize = 12;

  const [visibleMore, setVisibleMore] = useState(false);
  const plannerMorePicsImage = planner?.plannerMorePicList?.map((item: any) => {
    return (
      <>
        <Image src={imgUrl + item}></Image>
      </>
    );
  });

  //规划师评论
  const {isLoading,totalElements,data: evaList,} = usePlannerEvaList(useDebounce(param, 200));
  const paginationProps = {
    showSizeChanger: true,
    onShowSizeChange: (current: number, pageSize: number) => {
      setParam({
        ...param,
        pageNum: current - 1,
        pageSize,
      });
    },
    defaultCurrent: 1,
    pageSize: param.pageSize,
    total: totalElements,
    onChange: (current: number, pageSize: number) =>
      setParam({
        ...param,
        pageNum: current - 1,
        pageSize,
      }),
  };
  //分页
  return (
    <div>
      <div style={{ display: "none" }}>
        <Image.PreviewGroup
          preview={{ visible, onVisibleChange: (vis) => setVisible(vis) }}
        >
          {evaImageList
            ? evaImageList?.map(function (item: any) {
              return (
                <Image
                  src={imgUrl + item}
                  style={{
                    width: "80px",
                    height: "80px",
                    marginRight: "5px",
                  }}
                ></Image>
              );
            })
            : ""}
        </Image.PreviewGroup>
      </div>
      <Card title="规划师信息" style={{ minWidth: 1500 }}>
        <Row gutter={24}>
          {planner?.memberNickname ? (
            <>
              <Col
                className="gutter-row"
                style={{ textAlign: "center" }}
                span={2}
              >
                <div>
                  <Avatar size={64} src={planner?.memberHeadImg} />
                </div>
                <div>{planner?.memberNickname}</div>
              </Col>
            </>
          ) : (
            ""
          )}
          <Col className="gutter-row" style={{ textAlign: "center" }} span={2}>
            <div>
              <Avatar
                shape="square"
                size={64}
                src={imgUrl + planner?.plannerHeadImg}
              />
            </div>
            <div>{planner?.plannerName}</div>
          </Col>
          <Col className="gutter-row" style={{ textAlign: "left" }} span={20}>
            <Row gutter={20}>
              <Col className="gutter-row" span={5}>
                <Space size={spaceSize}>
                  <label htmlFor="">归属:</label>
                  <text>{planner?.plannerType === 1 ? "机构" : "学校"}</text>
                </Space>
              </Col>
              <Col className="gutter-row" span={5}>
                <Space size={spaceSize}>
                  <label htmlFor="">电话:</label>
                  <text>{planner?.plannerTel}</text>
                </Space>
              </Col>
              <Col className="gutter-row" span={5}>
                <Space size={spaceSize}>
                  <label htmlFor="">
                    {planner?.plannerType === 1 ? "机构" : "学校"}:
                  </label>
                  <text>
                    {planner?.plannerType === 1
                      ? planner?.agencyName
                      : planner?.schoolName}
                  </text>
                </Space>
              </Col>
              <Col className="gutter-row" span={5}>
                <Space size={spaceSize}>
                  <label htmlFor="">申请状态:</label>
                  <text>{planner?.plannerStateValue}</text>
                </Space>
              </Col>
            </Row>
            <Row gutter={20} style={{ marginTop: 5 }}>
              <Col className="gutter-row" span={5}>
                <Space size={spaceSize}>
                  <label htmlFor="">规划师评分:</label>
                  <text>{planner?.plannerEvaScore}</text>
                </Space>
              </Col>
              <Col className="gutter-row" span={5}>
                <Space size={spaceSize}>
                  <label htmlFor="">好评率:</label>
                  <text>{planner?.plannerEvaRate * 100}%</text>
                </Space>
              </Col>
              <Col className="gutter-row" span={5}>
                <Space size={spaceSize}>
                  <label htmlFor="">收藏数:</label>
                  <text>{planner?.plannerCollectNum}</text>
                </Space>
              </Col>
              <Col className="gutter-row" span={5}>
                <Space size={spaceSize}>
                  <label htmlFor="">点赞数:</label>
                  <text>{planner?.plannerLikeNum}</text>
                </Space>
              </Col>
            </Row>
            <Row gutter={20} style={{ marginTop: 5 }}>
              <Col className="gutter-row" span={20}>
                <Space size={spaceSize}>
                  <label htmlFor="">规划师介绍:</label>
                  <text>{planner?.plannerIntroduce}</text>
                </Space>
              </Col>
            </Row>
          </Col>
        </Row>
      </Card>

      <Card title={"图片展示"} style={{ marginTop: "20px", minWidth: 1500 }}>
        <Row gutter={24}>
          <Col className="gutter-row" span={5} style={{ minWidth: 250 }}>
            <Card
              title={"规划师图片"}
              style={{ textAlign: "center", minWidth: 250 }}
            >
              {planner?.plannerPic ? (
                <>
                  <Image width={200} src={imgUrl + planner?.plannerPic}></Image>
                </>
              ) : (
                ""
              )}
            </Card>
          </Col>
          <Col className="gutter-row" span={5} style={{ minWidth: 250 }}>
            <Card
              title={"多图展示"}
              style={{ textAlign: "center", minWidth: 250 }}
            >
              {planner?.plannerMorePicList ? (
                <>
                  <Image
                    preview={{ visible: false }}
                    width={200}
                    src={imgUrl + planner?.plannerMorePicList[0]}
                    onClick={() => setVisibleMore(true)}
                  />
                  <div style={{ display: "none" }}>
                    <Image.PreviewGroup
                      preview={{
                        visible: visibleMore,
                        onVisibleChange: (vis) => setVisibleMore(vis),
                      }}
                    >
                      {plannerMorePicsImage}
                    </Image.PreviewGroup>
                  </div>
                </>
              ) : (
                ""
              )}
            </Card>
          </Col>
          <Col className="gutter-row" span={5} style={{ minWidth: 250 }}>
            <Card
              title={"身份证正"}
              style={{ textAlign: "center", minWidth: 250 }}
            >
              {planner?.legalPersonIdCardz ? (
                <>
                  <Image width={200} src={imgUrl + planner?.legalPersonIdCardz}></Image>
                </>
              ) : (
                ""
              )}
            </Card>
          </Col>
          <Col className="gutter-row" span={5} style={{ minWidth: 250 }}>
            <Card
              title={"身份证反"}
              style={{ textAlign: "center", minWidth: 250 }}
            >
              {planner?.legalPersonIdCardf ? (
                <>
                  <Image width={200} src={imgUrl + planner?.legalPersonIdCardf}></Image>
                </>
              ) : (
                ""
              )}
            </Card>
          </Col>
        </Row>
      </Card>
      <Card
        title={"规划师详情"}
        style={{ marginTop: "20px", width: "100%", minWidth: 1500 }}
      >
        <div
          style={{ width: "100%" }}
          dangerouslySetInnerHTML={{ __html: planner?.plannerContent }}
        ></div>
      </Card>
      <Card
        title={"规划师评价"}
        style={{ marginTop: "20px", width: "100%", minWidth: 1500 }}
      >
        <Table
          loading={isLoading}
          rowKey={(columns) => columns.plannerEvaId}
          pagination={paginationProps}
          scroll={{ x: 1500 }}
          dataSource={evaList || []}
          columns={[
            {
              title: "ID",
              dataIndex: "plannerEvaId",
              key: "plannerEvaId",
              align: "center",
              width: 100,
            },
            {
              title: "用户昵称",
              dataIndex: "memberNickname",
              key: "memberNickname",
              align: "center",
            },

            {
              title: "用户头像",
              dataIndex: "memberHeadImg",
              key: "memberHeadImg",
              align: "center",
              render: (value) => (<>{value ? <Image src={value} width={70}></Image> : ""}</>)
            },
            {
              title: "评分",
              dataIndex: "plannerEvaScore",
              key: "plannerEvaScore",
              align: "center",
            },
            {
              title: "评论图片",
              dataIndex: "plannerEvaPics",
              key: "plannerEvaPics",
              align: "center",
              render: (value) => (
                <>
                  {value ?
                    <>
                      <Image
                        preview={{ visible: false }}
                        width={80}
                        src={imgUrl + value.split(",")[0]}
                        onClick={() => handleImage(value.split(","))}
                      />
                    </>
                    : ""}</>
              ),
            },
            {
              title: "评价标签",
              dataIndex: "plannerEvaEasilyTagNameList",
              key: "plannerEvaEasilyTagNameList",
              align: "center",
              render(value, record, index) {
                console.log("评价标签", value);
                if (value) {
                  return value.map((item: any) => {
                    return (<><Tag>{item}</Tag></>)
                  })
                }
              },
            },

            {
              title: "学校名称",
              dataIndex: "schoolName",
              key: "schoolName",
              align: "center",
            },
            {
              title: "机构名称",
              dataIndex: "agencyName",
              key: "agencyName",
              align: "center",
            },

            // {
            //   title: "操作",
            //   key: "action",
            //   align: "center",
            //   render: (value, plannerEva) => (
            //     <Space size="middle">
            //       <a
            //         onClick={() =>
            //           confirmDeletePlannerEva(plannerEva.plannerEvaId)
            //         }
            //       >
            //         删除
            //       </a>
            //     </Space>
            //   ),
            //   width: 150,
            // },
          ]}
        />
      </Card>
      <Modal
        title="拒绝通过"
        open={offModalOpen}
        okButtonProps={{ hidden: true }}
        onCancel={handleCancel}
        cancelButtonProps={{ hidden: true }}
      >
        <Form onFinish={onRejectFinish}>
          <Form.Item
            label="拒绝理由"
            name={"systemRemark"}
            rules={[{ required: true, message: "拒绝理由必填" }]}
          >
            <Input />
          </Form.Item>
          <Form.Item style={{ paddingLeft: "80px" }}>
            <Button loading={offSubmitLoading} type="primary" htmlType="submit">
              提交
            </Button>
            <Button
              style={{ marginLeft: "50px" }}
              type="default"
              onClick={handleCancel}
            >
              取消
            </Button>
          </Form.Item>
        </Form>
      </Modal>
    </div>
  );
};
