import { Button, Cascader, Col, Divider, Form, InputNumber, message, Row, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAreaListAll } from "../../services/area-service";
import { useAddBenefit } from "../../services/benefit-service";
import { useDocumentTitle } from "../../utils"
const { Option } = Select;

export const BenefitAddPage = () => {
    //标题
    useDocumentTitle('新建单项', true)
    const navigate = useNavigate();

    //布局
    const layout = {
        labelCol: { span: 4 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };

    //省市区
    const { data: areaList } = useAreaListAll(2)
    const [provinceAreaId, setProvinceAreaId] = useState();
    const [cityAreaId, setCityAreaId] = useState();
    const handleCascader = (e: any) => {
        if (e.length > 0) {
            setProvinceAreaId(e[0]);
            if (e.length > 1) {
                setCityAreaId(e[1]);
            }
        }
    }
    //类型
    const [benefitType, setBenefitType] = useState(2);
    const handleType = (e: any) => {
        setBenefitType(e)
    }

    //表单
    const [form] = useForm();

    //提交
    const { mutateAsync } = useAddBenefit();
    //提交表单
    const [submitLoading, setSubmitLoading] = useState(false);
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...values, benefitType, provinceAreaId, cityAreaId }).then((data) => {
            if (data.status === 0) {
                message.success(data.msg);
                navigate('/taocan/combo-manage/benefit/list')
            } else {
                setSubmitLoading(false);
                message.error(data.msg);
            }
        }).catch(err => {
            message.error('提交失败，请重新提交');
            setSubmitLoading(false);
        });
    };
    
    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/taocan/combo-manage/benefit/list"}>
                        <Button type="primary">列表</Button>
                    </Link>
                </Col>
            </Row>

            <Divider style={{ marginBottom: 24 }} />

            <Form
                form={form}
                {...layout}
                onFinish={onFinish}
                style={{ marginTop: 24 }}
                initialValues={{
                    benefitType: 2
                }}
            >
                <Form.Item
                    label={"选择城市"}
                    name={"cityCascader"}
                    rules={[{ required: true, message: "城市必选" }]}
                >
                    <Cascader style={{ width: 380 }} options={areaList?.data} onChange={handleCascader} placeholder={"请选择城市"} />
                </Form.Item>

                <Form.Item
                    label={"单项类型"}
                    name={"benefitType"}
                    rules={[{ required: true, message: "单项类型必选" }]}
                >
                    <Select
                        placeholder='请选择单项类型'
                        showSearch
                        allowClear
                        optionFilterProp='children'
                        onChange={handleType}
                        style={{ width: 380 }}
                    >
                        <Option value={2}>学校</Option>
                        <Option value={3}>机构</Option>
                        <Option value={5}>企业</Option>
                        <Option value={6}>商家</Option>
                        <Option value={9}>简章</Option>
                        <Option value={10}>动态</Option>
                        <Option value={11}>招聘</Option>
                        <Option value={12}>课程</Option>
                        <Option value={14}>商品</Option>
                    </Select>
                </Form.Item>

                <Form.Item
                    label={"置顶数量"}
                    name={"benefitTopNum"}
                    rules={[{ required: true, message: "置顶数量必填" }]}
                >
                    <InputNumber min={1} style={{ width: 380 }} placeholder={"请输入置顶数量"} addonAfter={'个'} />
                </Form.Item>

                <Form.Item
                    label={"提前天数"}
                    name={"benefitTopAppointDay"}
                    rules={[{ required: true, message: "提前天数必填" }]}
                >
                    <InputNumber min={1} style={{ width: 380 }} placeholder={"请输入提前天数"} addonAfter={'天'} />
                </Form.Item>

                <Form.Item
                    label={"置顶价格"}
                    name={"benefitTopPrice"}
                    rules={[{ required: true, message: "置顶价格必填" }]}
                >
                    <InputNumber step={0.01} min={0} style={{ width: 380 }} placeholder={"请输入置顶价格"} addonAfter={'元/天'} />
                </Form.Item>

                <Form.Item
                    label={"置顶原价"}
                    name={"benefitTopOriginalPrice"}
                    rules={[{ required: true, message: "置顶原价必填" }]}
                >
                    <InputNumber step={0.01} min={0} style={{ width: 380 }} placeholder={"请输入置顶原价"} addonAfter={'元/天'} />
                </Form.Item>

                <Form.Item
                    label={"刷新价格"}
                    name={"benefitRefreshPrice"}
                    rules={[{ required: true, message: "刷新价格必填" }]}
                >
                    <InputNumber step={0.01} min={0} style={{ width: 380 }} placeholder={"请输入刷新价格"} addonAfter={'元/次'} />
                </Form.Item>

                <Form.Item
                    label={"刷新原价"}
                    name={"benefitRefreshOriginalPrice"}
                    rules={[{ required: true, message: "刷新原价必填" }]}
                >
                    <InputNumber step={0.01} min={0} style={{ width: 380 }} placeholder={"请输入刷新原价"} addonAfter={'元/次'} />
                </Form.Item>

                {benefitType === 5 ? (
                    <>
                        <Form.Item
                            label={"招聘价格"}
                            name={"benefitRecruitPrice"}
                            rules={[{ required: true, message: "招聘价格必填" }]}
                        >
                            <InputNumber step={0.01} min={0} style={{ width: 380 }} placeholder={"请输入招聘价格"} addonAfter={'元/次'} />
                        </Form.Item>

                        <Form.Item
                            label={"招聘原价"}
                            name={"benefitRecruitOriginalPrice"}
                            rules={[{ required: true, message: "招聘原价必填" }]}
                        >
                            <InputNumber step={0.01} min={0} style={{ width: 380 }} placeholder={"请输入招聘原价"} addonAfter={'元/次'} />
                        </Form.Item>
                    </>
                ) : ('')}

                <Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                    <Button loading={submitLoading} type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}

