import { Button, Col, message, Modal, Row, Space, Table, Image } from "antd"
import Search from "antd/lib/input/Search"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useBankList, useDeleteBank } from "../../services/bank-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useBankSearchParams } from "./util"

export const BankListPage = () => {
    useDocumentTitle('银行列表', true)
    const imgUrl = process.env.REACT_APP_IMG_URL;
    const [param, setParam] = useBankSearchParams()
    const {isLoading, error, totalElements, data: list} = useBankList(useDebounce(param, 200))

    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) => 
            setParam({
            ...param,
            pageNum: (current -1),
        })
    }

    //删除事件
    const { mutateAsync:deleteMutateAsync } = useDeleteBank();
    const confirmDeleteBank = (bankId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    deleteMutateAsync({ bankId }).then((data) => {
                        if (data.status === 0) {
                            message.info('删除成功');
                            Modal.destroyAll();
                          } else {
                            message.error(data.msg)
                          }
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };

    //分页
    return (
        <div>
            <Row style={{marginBottom: 24}}>
                <Col span={12}>
                    <Link to={"/money/withdraw-manage/bank/add"}>
                        <Button type="primary">新建</Button>
                    </Link>
                </Col>
                <Col span={12} style={{textAlign:'right'}}>
                    <Search 
                        placeholder="可搜索银行名称" 
                        style={{ width: 200 }}
                        value={param.bankName}
                        onChange={(evt) =>
                            setParam({
                            ...param,
                            bankName: evt.target.value,
                            pageNum: null
                        })} 
                        onSearch={(value) =>
                            setParam({
                            ...param,
                            bankName: value,
                            pageNum: null
                        })} 
                    />
                </Col>
            </Row>
            <Table 
                loading = { isLoading }
                rowKey={ columns => columns.bankId } 
                pagination = { paginationProps } 
                columns = {[
                {
                    title: 'ID',
                    dataIndex: 'bankId',
                    key: 'bankId',
                    align: 'center',
                    width: 100,
                },
                {
                    title: '名称',
                    dataIndex: 'bankName',
                    key: 'bankName',
                    align: 'center',
                },
                {
                    title: 'Logo',
                    dataIndex: 'bankLogo',
                    key: 'bankLogo',
                    align: 'center',
                    render: (value, bank) => (
                        <Image width={80} src={imgUrl + bank.bankLogo}/>
                    ),
                },
                {
                    title: '排序',
                    dataIndex: 'bankSort',
                    key: 'bankSort',
                    align: 'center',
                },
                {
                    title: '状态',
                    dataIndex: 'bankShow',
                    key: 'bankShow',
                    align: 'center',
                    render: (value, bank) => (
                        <span>{bank.bankShow ? '显示' : '不显示'}</span>
                    ),
                },
                {
                    title: '操作',
                    key: 'action',
                    align: 'center',
                    render: (value, bank) => (
                        <Space size="middle">
                            <Link to={"/money/withdraw-manage/bank/update/" + bank.bankId}>
                                编辑
                            </Link>
                            <a onClick={() => confirmDeleteBank(bank.bankId)}>
                                删除
                            </a>
                        </Space>
                    ),
                    width: 150,
                }
            ]}
            dataSource={ list || [] }  />
            <ErrorBox error={ error } />
        </div>
    )
}