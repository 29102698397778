import { Button, Col, Divider, Form, Input, message, Radio, Row, Select } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { useAddCourseShowcase } from "../../services/course-showcase-service";
import { useDocumentTitle } from "../../utils"
import  UploadPic  from '../../components/upload-pic';
export const CourseShowcaseAddPage = () => {
    //标题
    useDocumentTitle('新建课程橱窗', true)
    const navigate = useNavigate();

    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };

    //表单
    const [form] = useForm();
    const [courseShowcasePic, setCourseShowcasePic] = useState("");
    const [uploading, setUploading] = useState<boolean>(false);

    //新建
    const [submitLoading, setSubmitLoading] = useState(false);
    const { mutateAsync } = useAddCourseShowcase();
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...values, courseShowcasePic }).then((data) => {
            if (data.status === 0) {
                message.success(data.msg);
                navigate('/jigou/course-manage/course-showcase/list')
            } else {
                setSubmitLoading(false);
                message.error(data.msg);
            }
        }).catch(err => {
            message.error('提交失败，请重新提交');
            setSubmitLoading(false);
        });
    };

    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/jigou/course-manage/course-showcase/list"}>
                        <Button type="primary">列表</Button>
                    </Link>
                </Col>
            </Row>

            <Divider style={{ marginBottom: 24 }} />

            <Form
                form={form}
                {...layout}
                onFinish={onFinish}
                initialValues={{
                    courseShowcaseSort: 0,
                    courseShowcaseShow: true
                }}>
               	<Form.Item
                    label={"橱窗名称"}
                    name={"courseShowcaseName"}
                    rules={[{ required: true, message: "橱窗名称必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入橱窗名称"} />
                </Form.Item>

               	<UploadPic
                    label={'图片'}
                    name={'uploadCourseShowcasePic'}
                    pic={courseShowcasePic}
                    setPic={setCourseShowcasePic}
                    action={'add'}
                    style={{ textAlign: 'left' }}
                >
                </UploadPic>
               	<Form.Item
                    label={"橱窗排序"}
                    name={"courseShowcaseSort"}
                    rules={[{ required: true, message: "橱窗排序必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入橱窗排序"} />
                </Form.Item>

                <Form.Item
                    label={"是否显示"}
                    name={"courseShowcaseShow"}
                    rules={[{ required: true, message: "是否显示必选" }]}
                    style={{ textAlign: 'left' }}
                >
                    <Radio.Group>
                        <Radio defaultChecked value={true}>显示</Radio>
                        <Radio value={false}>不显示</Radio>
                    </Radio.Group>
                </Form.Item>

               <Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                    <Button loading={submitLoading} type="primary" htmlType="submit">
                        提交
                    </Button>
                </Form.Item>
            </Form>
        </div>
    )
}