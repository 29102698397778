import { useMemo } from "react";
import { useUrlQueryParam } from "../../utils/url";

export const useAdminAccountSearchParams = () => {
    const [param, setParam] = useUrlQueryParam(["adminAccountName", "adminAccountNickname", "adminAccountId", "pageNum"]);
    return [
        useMemo(() => ({ ...param, adminAccountId: Number(param.adminAccountId) || undefined, pageNum: Number(param.pageNum) || undefined }), [param]),
        setParam
    ] as const
}
