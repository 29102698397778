import { useMutation, useQuery, useQueryClient } from "react-query";
import { FeedbackClass } from "../interfaces/feedback-class";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useFeedbackClassList = (param?: Partial<FeedbackClass>) => {
    const client = useHttp();
    const result = useQuery<any>(['feedbackClassPage', param], () => client('feedbackClass', { data: cleanObject(param) }))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return { ...result, totalElements, data };
}

export const useEditFeedbackClass = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<FeedbackClass>) => client(`feedbackClass`, {
            method: 'PUT',
            data: params
        }), {
        onSuccess: () => queryClient.invalidateQueries('feedbackClassPage')
    }
    )
}

export const useAddFeedbackClass = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<FeedbackClass>) => client(`feedbackClass`, {
            data: params,
            method: 'POST'
        }), {
        onSuccess: () => queryClient.invalidateQueries('feedbackClassPage')
    }
    )
}

export const useDeleteFeedbackClass = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ feedbackClassId }: { feedbackClassId: number }) =>
            client(`feedbackClass/${feedbackClassId}`, {
                method: "DELETE",
            }), {
        onSuccess: () => queryClient.invalidateQueries('feedbackClassPage')
    }
    );
};

export const useFeedbackClass = (feedbackClassId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['feedbackClass', { feedbackClassId }],
        () => client(`feedbackClass/${feedbackClassId}`),
        {
            enabled: Boolean(feedbackClassId)
        }
    )
}

export const useFeedbackClassListAll = () => {
    const client = useHttp();
    return useQuery<any>(["feedbackClassList", {}], () =>
        client(`feedbackClass/list`)
    );
};