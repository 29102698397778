import { useMutation, useQuery, useQueryClient } from "react-query";
import { GoodsSpec } from "../interfaces/goods-spec";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useGoodsSpecList = (param?: Partial<GoodsSpec>) => {
    const client = useHttp();
    const result = useQuery<any>(['goodsSpecList', param], () => client('goodsSpec', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditGoodsSpec = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<GoodsSpec>) => client(`goodsSpec`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('goodsSpecList')
        }
    )
}

export const useAddGoodsSpec = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<GoodsSpec>) => client(`goodsSpec`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('goodsSpecList')
        }
    )
}

export const useDeleteGoodsSpec = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ goodsSpecId }: { goodsSpecId: number}) =>
            client(`goodsSpec/${goodsSpecId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('goodsSpecList')
        }
    );
};

export const useGoodsSpec = (goodsSpecId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['goodsSpec', {goodsSpecId}],
        () => client(`goodsSpec/${goodsSpecId}`),
        {
            enabled: Boolean(goodsSpecId)
        }
    )
}

export const useGoodsSpecListAll = () => {
    const client = useHttp();
    const result = useQuery<any>(["goodsSpecListAll", { }], () =>
        client(`goodsSpec/listAll`)
    );
    const data = result.data?.data;
    return {...result, data };
};