import { Button, Card, Col, Form, Input, Row, Select, Table, DatePicker } from "antd"
import dayjs from "dayjs"
import { useState } from "react"
import { Link } from "react-router-dom"
import { ErrorBox } from "../../components/lib"
import { useSelfAgent } from "../../services/agent-service"
import { useAreaListAll } from "../../services/area-service"
import { useRecruitList } from "../../services/recruit-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { useRecruitSearchParams } from "./util"
const { Option } = Select;
const { RangePicker } = DatePicker;

export const RecruitListPage = () => {
    useDocumentTitle('招聘列表', true)
    const [param, setParam] = useRecruitSearchParams()
    const { isLoading, error, totalElements, data: list } = useRecruitList(useDebounce(param, 200))

    //分页
    const paginationProps = {
        defaultCurrent: 1,
        pageSize: 10,
        total: totalElements,
        onChange: (current: number) =>
            setParam({
                ...param,
                pageNum: (current - 1),
            })
    }

    //省
    const { data: provinceAreaList } = useAreaListAll(1);
    const provinceAreaOptions = provinceAreaList?.data?.map((item: any) =>
        <Option value={item.value}>{item.label}</Option>
    )

    //市
    const { data: cityAreaList } = useAreaListAll(4);
    const cityAreaOptions = cityAreaList?.data?.map((item: any) =>
        <Option value={item.value}>{item.label}</Option>
    )

    //时间
    const [recruitAddTimeStart, setRecruitAddTimeStart] = useState('');
    const [recruitAddTimeEnd, setRecruitAddTimeEnd] = useState('');
    const handleSelectTime = (value: any, dateString: any) => {
        setRecruitAddTimeStart(dateString[0]);
        setRecruitAddTimeEnd(dateString[1]);
    }

    //提交
    const onFinish = (values: any) => {
        setParam({
            ...param,
            recruitTitle: values.recruitTitle,
            recruitType: values.recruitType,
            provinceAreaId: values.provinceAreaId,
            cityAreaId: values.cityAreaId,
            recruitAddTimeStart: recruitAddTimeStart,
            recruitAddTimeEnd: recruitAddTimeEnd,
            pageNum: null
        })
    }
    //当前登录的代理商
    const { data: agent } = useSelfAgent();

    //分页
    return (
        <div>
            <Card style={{ backgroundColor: "#f2f3f5", margin: '24px 0 24px 0' }}>
                <Form onFinish={onFinish}>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item name="recruitAddTimeRange" label="发布时间">
                                <RangePicker onChange={handleSelectTime} showTime format="YYYY-MM-DD HH:mm:ss" style={{ width: '100%' }} allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="recruitTitle" label="招聘标题">
                                <Input placeholder='请输入招聘标题' allowClear />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="recruitType" label="招聘类型">
                                <Select
                                    placeholder='请选择招聘类型'
                                    showSearch
                                    allowClear
                                    optionFilterProp='children'
                                >
                                    <Option value={0}>全部</Option>
                                    <Option value={1}>全职</Option>
                                    <Option value={2}>兼职</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        {agent?.agentType == 1 ?
                            <>
                                <Col span={8}>
                                    <Form.Item name="cityAreaId" label="工作城市">
                                        <Select
                                            placeholder='请选择工作城市'
                                            showSearch
                                            allowClear
                                            optionFilterProp='children'
                                        >
                                            {cityAreaOptions}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </>
                            : agent?.agentType == 2 ? <>

                            </> : <>
                                <Col span={8}>
                                    <Form.Item name="provinceAreaId" label="工作省份">
                                        <Select
                                            placeholder='请选择工作省份'
                                            showSearch
                                            allowClear
                                            optionFilterProp='children'
                                        >
                                            {provinceAreaOptions}
                                        </Select>
                                    </Form.Item>
                                </Col>
                                <Col span={8}>
                                    <Form.Item name="cityAreaId" label="工作城市">
                                        <Select
                                            placeholder='请选择工作城市'
                                            showSearch
                                            allowClear
                                            optionFilterProp='children'
                                        >
                                            {cityAreaOptions}
                                        </Select>
                                    </Form.Item>
                                </Col>
                            </>}

                    </Row>
                    <Form.Item style={{ marginBottom: 0 }}>
                        <Button style={{ marginLeft: 70 }} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Card>

            <Table
                loading={isLoading}
                rowKey={columns => columns.recruitId}
                pagination={paginationProps}
                columns={[
                    {
                        title: '标题',
                        dataIndex: 'recruitTitle',
                        key: 'recruitTitle',
                        align: 'center',
                        fixed: 'left',
                        width: 200,
                    },
                    {
                        title: '类型',
                        dataIndex: 'recruitType',
                        key: 'recruitType',
                        align: 'center',
                        width: 100,
                        render: (value) => (
                            value === 1 ? '全职' : value === 2 ? '兼职' : ('-')
                        )
                    },
                    {
                        title: '职位',
                        dataIndex: 'companyPositionName',
                        key: 'companyPositionName',
                        align: 'center',
                        width: 300,
                        render: (value, recruit) => (
                            recruit.companyPositionClassName + ' - ' + value
                        )
                    },
                    {
                        title: '薪资',
                        dataIndex: 'recruitSalaryMin',
                        key: 'recruitSalaryMin',
                        align: 'center',
                        width: 200,
                        render: (value, recruit) => (
                            value && recruit.recruitSalaryMax ? value + 'k~' + recruit.recruitSalaryMax + 'k'
                                : !value && recruit.recruitSalaryMax ? recruit.recruitSalaryMax + 'k以下'
                                    : value && !recruit.recruitSalaryMax ? value + 'k以上'
                                        : '-'
                        )
                    },
                    {
                        title: '地区',
                        dataIndex: 'provinceAreaName',
                        key: 'provinceAreaName',
                        align: 'center',
                        width: 200,
                        render: (value, recruit) => (
                            value + ' - ' + recruit.cityAreaName
                        )
                    },
                    {
                        title: '经验',
                        dataIndex: 'recruitExperienceName',
                        key: 'recruitExperienceName',
                        align: 'center',
                        width: 200,
                    },
                    {
                        title: '学历',
                        dataIndex: 'recruitEducationName',
                        key: 'recruitEducationName',
                        align: 'center',
                        width: 200,
                    },
                    {
                        title: '浏览',
                        dataIndex: 'recruitBrowseNum',
                        key: 'recruitBrowseNum',
                        align: 'center',
                        width: 100,
                        render: (value, recruit) => (
                            <Link
                                to={"/qiye/recruit-manage/recruit/member-browse/list?identityType=11&identityId=" + recruit.recruitId}
                            >
                                {value}
                            </Link>
                        ),
                    },
                    {
                        title: '收藏',
                        dataIndex: 'recruitCollectNum',
                        key: 'recruitCollectNum',
                        align: 'center',
                        width: 100,
                        render: (value, recruit) => (
                            <Link
                                to={"/qiye/recruit-manage/recruit/member-collect/list?identityType=11&identityId=" + recruit.recruitId}
                            >
                                {value}
                            </Link>
                        ),
                    },
                    {
                        title: '简历',
                        dataIndex: 'recruitResumeNum',
                        key: 'recruitResumeNum',
                        align: 'center',
                        width: 100,
                        render: (value, recruit) => (
                            <Link
                                to={"/qiye/recruit-manage/recruit/recruit-resume/list?recruitId=" + recruit.recruitId}
                            >
                                {value}
                            </Link>
                        ),
                    },
                    {
                        title: 'HR',
                        dataIndex: 'hrName',
                        key: 'hrName',
                        align: 'center',
                        width: 150,
                    },
                    {
                        title: '企业',
                        dataIndex: 'companyName',
                        key: 'companyName',
                        align: 'center',
                        width: 150,
                    },
                    {
                        title: '状态',
                        dataIndex: 'recruitShow',
                        key: 'recruitShow',
                        align: 'center',
                        width: 100,
                        render: (value) => (
                            value ? '上架' : '下架'
                        ),
                    },
                    {
                        title: '发布时间',
                        dataIndex: 'recruitAddTime',
                        key: 'recruitAddTime',
                        align: 'center',
                        width: 200,
                        fixed: 'right',
                        render: (value) => (
                            value ? dayjs(value).format('YYYY-MM-DD hh:mm:ss') : '-'
                        )
                    }
                ]}
                dataSource={list || []}
                scroll={{ x: 2300 }}
            />
            <ErrorBox error={error} />
        </div>
    )
}