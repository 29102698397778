import { message, Modal, Space, Table, Image, Tag, Rate, Card, Form, Button, Row, Col, Select, Input, DatePicker } from "antd"
import { useState } from "react"
import { ErrorBox } from "../../components/lib"
import { usePlannerEvaEasilyTagListAll} from "../../services/planner-eva-easily-tag-service"
import { usePlannerEvaList, useDeletePlannerEva } from "../../services/planner-eva-service"
import { usePlannerListAll } from "../../services/planner-service"
import { useDebounce, useDocumentTitle } from "../../utils"
import { usePlannerEvaSearchParams } from "./util"
const { RangePicker } = DatePicker;
const { Option } = Select;

export const PlannerEvaListPage = () => {
    useDocumentTitle('规划师评价列表', true)
    const imgUrl = process.env.REACT_APP_IMG_URL;
    const [param, setParam] = usePlannerEvaSearchParams()
    const { isLoading, error, totalElements, data: list } = usePlannerEvaList(useDebounce(param, 200))

    //分页
    const paginationProps = {
        showSizeChanger: true,
        onShowSizeChange: (current: number, pageSize: number) => {
            setParam({
                ...param,
                pageNum: current - 1,
                pageSize,
            });
        },
        defaultCurrent: 1,
        pageSize: param.pageSize,
        total: totalElements,
        onChange: (current: number, pageSize: number) =>
            setParam({
                ...param,
                pageNum: current - 1,
                pageSize,
            }),
    };
    //删除事件
    const { mutateAsync } = useDeletePlannerEva();
    const confirmDeletePlannerEva = (plannerEvaId: number) => {
        Modal.confirm({
            title: "确定删除这条数据吗?",
            content: "点击确定删除",
            okText: "确定",
            onOk(): any {
                return new Promise((resolve, reject) => {
                    mutateAsync({ plannerEvaId }).then((data) => {
                        if (data.status === 0) {
                            message.info('删除成功');
                            Modal.destroyAll();
                        } else {
                            message.error(data.msg)
                        }
                    });
                }).catch(
                    () => console.log('Oops errors!')
                );
            },
            cancelText: "取消"
        });
    };

    //标签
    const { data: plannerEvaEasilyTagList } = usePlannerEvaEasilyTagListAll();
    const evaEasilyTagOptions = plannerEvaEasilyTagList?.data?.map((item: any) =>
        <Option value={item.plannerEvaEasilyTagId}>{item.plannerEvaEasilyTagName}</Option>
    )

    //规划师
    const { data: plannerList } = usePlannerListAll();
    const plannerOptions = plannerList?.data?.map((item: any) =>
        <Option value={item.plannerId}>{item.plannerName}</Option>
    )

    //时间
    const rangeConfig = {
        rules: [{ type: 'array' as const, message: '请选择时间!' }],
    };
    const [plannerEvaTimeStart, setPlannerEvaTimeStart] = useState('');
    const [plannerEvaTimeEnd, setPlannerEvaTimeEnd] = useState('');
    const handleSelectTime = (value: any, dateString: any) => {
        setPlannerEvaTimeStart(dateString[0]);
        setPlannerEvaTimeEnd(dateString[1]);
    }
    const onFinish = (values: any) => {
        setParam({
            ...param,
            plannerId: values.plannerId,
            memberNickname: values.memberNickname,
            plannerEvaStatType: values.plannerEvaStatType,
            plannerEvaEasilyTagId: values.plannerEvaEasilyTagId,
            plannerEvaTimeStart: plannerEvaTimeStart,
            plannerEvaTimeEnd: plannerEvaTimeEnd,
            pageNum: null
        })
    }
    return (
        <div>
            <Card style={{ backgroundColor: "#f2f3f5", margin: '0 0 24px 0' }}>
                <Form onFinish={onFinish}>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item name="plannerEvaTimeRange" label="评价时间" {...rangeConfig}>
                                <RangePicker onChange={handleSelectTime} showTime format="YYYY-MM-DD HH:mm:ss" style={{ width: '100%' }} />
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="plannerEvaStatType" label="评价级别">
                                <Select
                                    placeholder='请选择评价级别'
                                    showSearch
                                    allowClear
                                    optionFilterProp='children'
                                >
                                    <Option value={1}>差评</Option>
                                    <Option value={2}>中评</Option>
                                    <Option value={3}>好评</Option>
                                    <Option value={4}>有图</Option>
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="plannerEvaEasilyTagId" label="评价标签">
                                <Select
                                    placeholder='请选择评价标签'
                                    showSearch
                                    allowClear
                                    optionFilterProp='children'
                                >
                                    {evaEasilyTagOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                    </Row>
                    <Row gutter={20}>
                        <Col span={8}>
                            <Form.Item name="plannerId" label="规划师">
                                <Select
                                    placeholder='请选择规划师'
                                    showSearch
                                    allowClear
                                    optionFilterProp='children'
                                >
                                    {plannerOptions}
                                </Select>
                            </Form.Item>
                        </Col>
                        <Col span={8}>
                            <Form.Item name="memberNickname" label="用户昵称">
                                <Input placeholder='请输入用户昵称' allowClear />
                            </Form.Item>
                        </Col>
                    </Row>
                    <Form.Item style={{ marginBottom: 0 }}>
                        <Button style={{ marginLeft: 70 }} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Card>
            <Table
                loading={isLoading}
                rowKey={columns => columns.plannerEvaId}
                pagination={paginationProps}
                columns={[
                    {
                        title: '头像',
                        dataIndex: 'memberHeadImg',
                        key: 'memberHeadImg',
                        align: 'center',
                        fixed: "left",
                        width: 100,
                        render: (value) => (
                            <Image width={50} height={50} src={value} />
                        ),
                    },
                    {
                        title: '昵称',
                        dataIndex: 'memberNickname',
                        key: 'memberNickname',
                        align: 'center',
                        fixed: "left",
                        width: 150,
                    },
                    {
                        title: '评分',
                        dataIndex: 'plannerEvaScore',
                        key: 'plannerEvaScore',
                        align: 'center',
                        width: 250,
                        render: (value) => (
                            <div>
                                <Rate
                                    allowHalf
                                    defaultValue={value}
                                    disabled
                                    style={{ width: 150, fontSize: 15 }}
                                ></Rate>
                                <span style={{ color: "#1890ff", fontWeight: "bold" }}>
                                    {value}
                                </span>
                            </div>
                        ),
                    },
                    {
                        title: '内容',
                        dataIndex: 'plannerEvaContent',
                        key: 'plannerEvaContent',
                        align: 'center',
                        width: 200,
                    },
                    {
                        title: '图片',
                        dataIndex: 'plannerEvaPics',
                        key: 'plannerEvaPics',
                        align: 'center',
                        width: 250,
                        render(value) {
                            return (
                                <Image.PreviewGroup>
                                    {value ? value?.split(",")?.map((item: any) =>
                                        <Image src={imgUrl + item} style={{ width: "60px", height: "60px", padding: 5 }}></Image>
                                    ) : ''}
                                </Image.PreviewGroup>
                            );
                        }
                    },
                    {
                        title: '标签',
                        dataIndex: 'plannerEvaEasilyTagNameList',
                        key: 'plannerEvaEasilyTagNameList',
                        align: 'center',
                        width: 200,
                        render: (value) => (
                            value?.map(function (item: string) {
                                return <Tag color="#1890ff">{item}</Tag>
                            })
                        )
                    },
                    {
                        title: '规划师',
                        dataIndex: 'plannerName',
                        key: 'plannerName',
                        align: 'center',
                        width: 150,
                    },
                    {
                        title: '机构名称',
                        dataIndex: 'agencyName',
                        key: 'agencyName',
                        align: 'center',
                        width: 150,
                    },
                    {
                        title: '学校',
                        dataIndex: 'schoolName',
                        key: 'schoolName',
                        align: 'center',
                        width: 150,
                    },
                    {
                        title: '操作',
                        key: 'action',
                        align: 'center',
                        fixed: 'right',
                        render: (value, plannerEva) => (
                            <Space size="middle">
                                <a onClick={() => confirmDeletePlannerEva(plannerEva.plannerEvaId)}>
                                    删除
                                </a>
                            </Space>
                        ),
                        width: 150,
                    }
                ]}
                dataSource={list || []}
                scroll={{ x: 1750 }} />
            <ErrorBox error={error} />
        </div>
    )
}