import { useMutation, useQuery, useQueryClient } from "react-query";
import { CourseBoutique } from "../interfaces/course-boutique";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useCourseBoutiqueList = (param?: Partial<CourseBoutique>) => {
    const client = useHttp();
    const result = useQuery<any>(['courseBoutiquePage', param], () => client('courseBoutique', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useNoCourseBoutiqueList = (param?: Partial<CourseBoutique>) => {
    const client = useHttp();
    const result = useQuery<any>(['noCourseBoutiquePage',param], () => client('courseBoutique/course', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}
export const useEditCourseBoutique = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<CourseBoutique>) => client(`courseBoutique`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('courseBoutiquePage')
        }
    )
}

export const useAddCourseBoutique = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<CourseBoutique>) => client(`courseBoutique`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('noCourseBoutiquePage')
        }
    )
}

export const useDeleteCourseBoutique = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ courseBoutiqueIds }: { courseBoutiqueIds: number[]}) =>
            client(`courseBoutique`, {
            method: "DELETE",
            data:{courseBoutiqueIds}
        }), {
            onSuccess: () => queryClient.invalidateQueries('courseBoutiquePage')
        }
    );
};

