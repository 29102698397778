import { Button, Col, Divider, Form, Input, message, Row, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { useMemberTag, useEditMemberTag } from "../../services/member-tag-service";
import { useDocumentTitle } from "../../utils"

export const MemberTagUpdatePage = () => {
    //标题
    useDocumentTitle('编辑用户标签', true)
    const { pathname } = useLocation();
    const memberTagId = pathname.match(/user\/user-manage\/member-tag\/update\/(\d+)/)?.[1]
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    //表单
    const [form] = useForm();
    //准备回显数据
    const { data: editingMemberTag, isLoading } = useMemberTag(
        Number(memberTagId)
    );
    useEffect(() => {
        form.setFieldsValue(editingMemberTag?.data)
    }, [editingMemberTag, form]);
    //编辑
    const { mutateAsync, error } = useEditMemberTag();
    //提交表单
    const [submitLoading, setSubmitLoading] = useState(false);
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...editingMemberTag?.data, ...values }).then(() => {
            message.info('编辑成功');
            window.location.href = "/#/user/user-manage/member-tag/list";
        });
    };
    return (
        <div>
            <Spin spinning={isLoading}>
                <Row>
                    <Col span={12}>
                        <Link to={"/user/user-manage/member-tag/list"}>
                            <Button type="primary">列表</Button>
                        </Link>
                    </Col>
                </Row>
                <Divider style={{ marginBottom: 24 }} />
                <Form
                    form={form}
                    {...layout}
                    onFinish={onFinish}>
                    <Form.Item
                        label={"名称"}
                        name={"memberTagName"}
                        rules={[{ required: true, message: "名称必填" }]}
                    >
                        <Input style={{ width: 380 }} placeholder={"请输入名称"} />
                    </Form.Item>

                    <Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                        <Button loading={submitLoading} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    )
}

