import {
    Button,
    Col,
    message,
    Modal,
    Row,
    Space,
    Table,
    Select,
    Form
  } from "antd";
import { useForm } from "antd/lib/form/Form";
  import Search from "antd/lib/input/Search";
import { useState } from "react";
  import { Link } from "react-router-dom";
  import { ErrorBox } from "../../components/lib";
  import { useBusinessCircleList, useDeleteBusinessCircle, useBusinessCircleBusinessCircleTagList, useAddBusinessCircleBusinessCircleTag } from "../../services/business-circle-service";
  import { useBusinessCircleTagListAll } from '../../services/business-circle-tag-service'
  import { useDebounce, useDocumentTitle } from "../../utils";
  import { useBusinessCircleSearchParams } from "./util";
  const { Option } = Select
  
  export const BusinessCircleListPage = () => {
    useDocumentTitle("商圈列表", true);
    const [param, setParam] = useBusinessCircleSearchParams();
    const {
      isLoading,
      error,
      totalElements,
      data: list,
    } = useBusinessCircleList(useDebounce(param, 200));
    //分页
    const paginationProps = {
      defaultCurrent: 1,
      pageSize: 10,
      total: totalElements,
      onChange: (current: number) =>
        setParam({
          ...param,
          pageNum: current - 1,
        }),
    };
    //标签列表
    const { data: businessCircleTagList } = useBusinessCircleTagListAll()
    const optionsBusinessCircleTag = businessCircleTagList?.map((item: any, value: any) => (
      <Option value={item.businessCircleTagId} key={item.businessCircleTagId}>
        {item.businessCircleTagName}
      </Option>
    ))
    //标签id
    const [businessCircleTagIdList, setBusinessCircleTagIdList] = useState<any>([]);
    const handleChangeSetBusinessCircleTag = (e: any) => {
      const tagIdList: any[] = [];
      if (e && e.length > 0) {
        e.map((item: number) => tagIdList.push(item));
      }
      setBusinessCircleTagIdList(tagIdList);
    }
    //用户
    const [businessCircleId, setBusinessCircleId] = useState<number>();
    //模态框
    const [isBusinessCircleTagModalVisible, setIsBusinessCircleTagModalVisible] = useState(false);//弹窗状态
    const [submitBusinessCircleTagLoading, setSubmitBusinessCircleTagLoading] = useState(false);//按钮状态
    //标签列表
    const useMutateBusinessCircleBusinessCircleTagList = useBusinessCircleBusinessCircleTagList;
    const { mutateAsync: mutateAsyncBusinessCircleBusinessCircleTagList } = useMutateBusinessCircleBusinessCircleTagList();
    //保存
    const useMutateAddBusinessCircleBusinessCircleTag = useAddBusinessCircleBusinessCircleTag
    const { mutateAsync: addBusinessCircleBusinessCircleTag } = useMutateAddBusinessCircleBusinessCircleTag();
    const handleModalCancel = () => {
      setIsBusinessCircleTagModalVisible(false);
    };
    const handleModalOk = () => {
      setSubmitBusinessCircleTagLoading(true)
      addBusinessCircleBusinessCircleTag({
        businessCircleId,
        businessCircleTagIdList
      }).then((res) => {
        message.info(res.msg)
        setIsBusinessCircleTagModalVisible(false);
        setSubmitBusinessCircleTagLoading(false);
      });
    }
    const [form] = useForm();
    const showBusinessCircleTagModal = (businessCircleId: number) => {
      setBusinessCircleId(businessCircleId)
      mutateAsyncBusinessCircleBusinessCircleTagList({ businessCircleId }).then((data: any) => {
        const businessCircleTagData: any = [];
        if (data?.data && data?.data?.length > 0) {
          data?.data?.map((item: any) => businessCircleTagData.push(item.businessCircleTagId));
        }
        form.setFieldsValue({
          "businessCircleBusinessCircleTag": businessCircleTagData
        })
        setBusinessCircleTagIdList(businessCircleTagData);
      });
      setIsBusinessCircleTagModalVisible(true);
    }
    //删除事件
    const useMutateDeleteBusinessCircle = useDeleteBusinessCircle;
    const { mutateAsync } = useMutateDeleteBusinessCircle();
    const confirmDeleteBusinessCircle = (businessCircleId: number) => {
      Modal.confirm({
        title: "确定删除这条数据吗?",
        content: "点击确定删除",
        okText: "确定",
        onOk(): any {
          return new Promise((resolve, reject) => {
            mutateAsync({ businessCircleId }).then(() => {
              message.info("删除成功");
              Modal.destroyAll();
            });
          }).catch(() => console.log("Oops errors!"));
        },
        cancelText: "取消",
      });
    };
    //分页
    return (
      <div>
          <Modal
            confirmLoading={submitBusinessCircleTagLoading}
            centered
            bodyStyle={{ height: '100', overflowY: 'auto' }}
            destroyOnClose={true}
            width={550}
            title="设置标签"
            visible={isBusinessCircleTagModalVisible}
            onOk={handleModalOk}
            onCancel={handleModalCancel}
          >
            <Form form={form}>
              <Form.Item
                label={"商圈标签"}
                name={'businessCircleBusinessCircleTag'}
              >
                <Select
                  showSearch
                  mode="multiple"
                  onChange={handleChangeSetBusinessCircleTag}
                  placeholder='请选择商圈标签'
                  optionFilterProp='children'>
                    {optionsBusinessCircleTag}
                </Select>
              </Form.Item>
            </Form>
        </Modal>
        <Row style={{ marginBottom: 24 }}>
          <Col span={12}>
            <Link to={"/shop/store-manage/businessCircle/add"}>
              <Button type="primary">新建商圈</Button>
            </Link>
          </Col>
          <Col span={12} style={{ textAlign: "right" }}>
            <Search
              placeholder="可搜索商圈名称"
              style={{ width: 200 }}
              value={param.businessCircleName}
              onChange={(evt) =>
                setParam({
                  ...param,
                  businessCircleName: evt.target.value,
                  pageNum: null,
                })
              }
              onSearch={(value) =>
                setParam({
                  ...param,
                  businessCircleName: value,
                  pageNum: null,
                })
              }
            />
          </Col>
        </Row>
        <Table
          loading={isLoading}
          rowKey={(columns) => columns.businessCircleId}
          pagination={paginationProps}
          columns={[
            {
              title: "商圈ID",
              dataIndex: "businessCircleId",
              key: "businessCircleId",
              width: 100,
            },
            {
              title: "商圈名称",
              dataIndex: "businessCircleName",
              key: "businessCircleName",
            },
            {
              title: '所在区域',
              dataIndex: 'businessCircleProvinceName',
              key: 'businessCircleProvinceName',
              render: (value, businessCircle) => (
                  <span>{businessCircle.businessCircleProvinceName }{businessCircle.businessCircleCityName }{businessCircle.businessCircleDistrictName }</span>
              ),
            },
            {
              title: '操作',
              key: 'action',
              render: (value, businessCircle) => (
                <Space size="middle">
                  <a onClick={() => showBusinessCircleTagModal(businessCircle.businessCircleId)}>
                    标签
                  </a>
                  <Link to={"/shop/store-manage/businessCircle/store/list/" + businessCircle.businessCircleId}>
                    门店
                  </Link>
                  <Link to={"/shop/store-manage/businessCircle/update/" + businessCircle.businessCircleId}>
                    编辑
                  </Link>
                  <a onClick={() => confirmDeleteBusinessCircle(businessCircle.businessCircleId)}>
                    删除
                  </a>
                </Space>
              ),
              width: 250,
            }
          ]}
          dataSource={list || []}
        />
        <ErrorBox error={error} />
      </div>
    );
  };
  