import { useMutation, useQuery, useQueryClient } from "react-query";
import { CourseNotice } from "../interfaces/course-notice";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useCourseNoticeList = (param?: Partial<CourseNotice>) => {
    const client = useHttp();
    const result = useQuery<any>(['courseNoticePage', param], () => client('courseNotice', {data: cleanObject(param)}))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return {...result, totalElements, data };
}

export const useEditCourseNotice = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<CourseNotice>) => client(`courseNotice`, {
            method: 'PUT',
            data: params
        }), {
            onSuccess: () => queryClient.invalidateQueries('courseNoticePage')
        }
    )
}

export const useAddCourseNotice = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<CourseNotice>) => client(`courseNotice`, {
            data: params,
            method: 'POST'
        }), {
            onSuccess: () => queryClient.invalidateQueries('courseNoticePage')
        }
    )
}

export const useDeleteCourseNotice = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ courseNoticeId }: { courseNoticeId: number}) =>
            client(`courseNotice/${courseNoticeId}`, {
            method: "DELETE",
        }), {
            onSuccess: () => queryClient.invalidateQueries('courseNoticePage')
        }
    );
};

export const useCourseNotice = (courseNoticeId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['courseNotice', {courseNoticeId}],
        () => client(`courseNotice/${courseNoticeId}`),
        {
            enabled: Boolean(courseNoticeId)
        }
    )
}