import { Button, Col, Divider, Form, Input, message, Radio, Row, Select, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useBankAccount, useEditBankAccount } from "../../services/bank-account-service";
import { useDocumentTitle } from "../../utils"
import { useBankListAll } from "../../services/bank-service";
export const BankAccountUpdatePage = () => {
    //标题
    useDocumentTitle('编辑银行账号', true)
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const bankAccountId = Number(pathname.match(/wallet\/wallet-manage\/agent-bank-account\/update\/(\d+)/)?.[1])

    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };

    //表单
    const [form] = useForm();

    //准备回显
    const { data: editingBankAccount, isLoading } = useBankAccount(bankAccountId);
    console.log("回显",editingBankAccount,isLoading);
    useEffect(() => {
        form.setFieldsValue({ ...editingBankAccount?.data });
    }, [editingBankAccount, form]);

    //新建
    const [submitLoading, setSubmitLoading] = useState(false);
    const { mutateAsync } = useEditBankAccount();
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...editingBankAccount?.data, ...values }).then((data) => {
            if (data.status === 0) {
                message.success(data.msg);
                navigate('/wallet/wallet-manage/agent-bank-account/list')
            } else {
                setSubmitLoading(false);
                message.error(data.msg);
            }
        }).catch(err => {
            message.error('提交失败，请重新提交');
            setSubmitLoading(false);
        });
    };
    const {data:bankList} = useBankListAll();
    const bankListOption = bankList?.map((item:any)=>(
        <>
            <Select.Option key={item.bankId} value={item.bankId}>{item.bankName}</Select.Option>
        </>
    ))
    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/wallet/wallet-manage/agent-bank-account/list"}>
                        <Button type="primary">列表</Button>
                    </Link>
                </Col>
            </Row>

            <Divider style={{ marginBottom: 24 }} />

            <Spin spinning={isLoading}>
                <Form
                    form={form}
                    {...layout}
                    onFinish={onFinish}
                >

                <Form.Item
                    label={"银行"}
                    name={"bankId"}
                    rules={[{ required: false, message: "银行id必填" }]}
                >
                    <Select showSearch placeholder="银行必填"
                    optionFilterProp="children"
                    style={{ textAlign: 'left', width: 380 }}>
                        {bankListOption}
                    </Select>
                </Form.Item>

               	<Form.Item
                    label={"卡号"}
                    name={"bankAccountCardNo"}
                    rules={[{ required: true, message: "卡号必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入卡号"} />
                </Form.Item>

               	<Form.Item
                    label={"用户名"}
                    name={"bankAccountTrueName"}
                    rules={[{ required: true, message: "用户名必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入用户名"} />
                </Form.Item>

                <Form.Item
                    label={"是否默认"}
                    name={"bankAccountDefault"}
                    rules={[{ required: true, message: "是否默认必选" }]}
                    style={{ textAlign: 'left' }}
                >
                    <Radio.Group>
                        <Radio value={true}>是</Radio>
                        <Radio value={false}>否</Radio>
                    </Radio.Group>
                </Form.Item>

				<Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                        <Button loading={submitLoading} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    )
}