import { Button, Col, Divider, Form, Input, message, Radio, Row, Select, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useAgencyTag, useEditAgencyTag } from "../../services/agency-tag-service";
import { useDocumentTitle } from "../../utils"
import  UploadPic  from '../../components/upload-pic';
export const AgencyTagUpdatePage = () => {
    //标题
    useDocumentTitle('编辑机构标签', true)
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const agencyTagId = Number(pathname.match(/jigou\/agency-manage\/agency-tag\/update\/(\d+)/)?.[1])

    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };

    //表单
    const [form] = useForm();
    const [agencyTagPic, setAgencyTagPic] = useState("");
    const [uploading, setUploading] = useState<boolean>(false);

    //准备回显
    const { data: editingAgencyTag, isLoading } = useAgencyTag(agencyTagId);
    console.log("回显",editingAgencyTag,isLoading);
    useEffect(() => {
        form.setFieldsValue({ ...editingAgencyTag?.data });
        //封面
        if (editingAgencyTag?.data?.agencyTagPic) {
            setAgencyTagPic(editingAgencyTag?.data?.agencyTagPic)
        }
    }, [editingAgencyTag, form]);

    //新建
    const [submitLoading, setSubmitLoading] = useState(false);
    const { mutateAsync } = useEditAgencyTag();
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...editingAgencyTag?.data, ...values, agencyTagPic }).then((data) => {
            if (data.status === 0) {
                message.success(data.msg);
                navigate('/jigou/agency-manage/agency-tag/list')
            } else {
                setSubmitLoading(false);
                message.error(data.msg);
            }
        }).catch(err => {
            message.error('提交失败，请重新提交');
            setSubmitLoading(false);
        });
    };

    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/jigou/agency-manage/agency-tag/list"}>
                        <Button type="primary">列表</Button>
                    </Link>
                </Col>
            </Row>

            <Divider style={{ marginBottom: 24 }} />

            <Spin spinning={isLoading}>
                <Form
                    form={form}
                    {...layout}
                    onFinish={onFinish}
                >
               	<Form.Item
                    label={"标签名"}
                    name={"agencyTagName"}
                    rules={[{ required: false, message: "标签名必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入标签名"} />
                </Form.Item>

                <Form.Item
                    label={"是否显示"}
                    name={"agencyTagShow"}
                    rules={[{ required: true, message: "是否显示必选" }]}
                    style={{ textAlign: 'left' }}
                >
                    <Radio.Group>
                        <Radio defaultChecked value={true}>显示</Radio>
                        <Radio value={false}>不显示</Radio>
                    </Radio.Group>
                </Form.Item>
				<Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                        <Button loading={submitLoading} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    )
}