import { Button, Card, Col, Row, Space, Select, Typography, Image, Divider, List, Modal, Form, message, Descriptions, Input } from "antd";
import { useLocation } from "react-router";
import { useOrdersRefund, useAgree, useReject, useAgreeReturn, useRejectReceive, useAgreeReceive } from '../../services/orders-refund-service';
import { useOrdersRefundLogList } from '../../services/orders-refund-log-service';
import { useStoreAddressListAll } from '../../services/store-address-service';
import dayjs from 'dayjs';
import { useEffect, useState } from "react";
import { useDocumentTitle } from "../../utils";
const { Text, Link, Title } = Typography;
const { Option } = Select;

export const OrdersRefundDetailPage = () => {
    useDocumentTitle('售后详情', true)
    const imgUrl = process.env.REACT_APP_IMG_URL;
    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 21 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    //获取数据
    const { pathname } = useLocation();
    const ordersRefundId = pathname.match(/shop\/orders-manage\/orders-refund\/detail\/(\d+)/)?.[1]
    //详情
    const [ordersRefundStateVal, setOrdersRefundStateVal] = useState("");
    const { data: ordersRefund, isLoading } = useOrdersRefund(
        Number(ordersRefundId)
    );
    useEffect(() => {
        if(ordersRefund?.data?.ordersRefundState == 10) {
            setOrdersRefundStateVal("退款申请待商家处理");
        }
        if(ordersRefund?.data?.ordersRefundState == 20) {
            setOrdersRefundStateVal("商家不同意退款申请，待买家处理");
        }
        if(ordersRefund?.data?.ordersRefundState == 30) {
            setOrdersRefundStateVal("商家已同意退款申请，待买家发货");
        }
        if(ordersRefund?.data?.ordersRefundState == 40) {
            setOrdersRefundStateVal("买家已退货，待商家确认收货");
        }
        if(ordersRefund?.data?.ordersRefundState == 50) {
            setOrdersRefundStateVal("商家拒绝收货，待买家处理");
        }
        if(ordersRefund?.data?.ordersRefundState == 60) {
            setOrdersRefundStateVal("退款关闭");
        }
        if(ordersRefund?.data?.ordersRefundState == 70) {
            setOrdersRefundStateVal("退款成功");
        }
    }, [ordersRefund]);
    //退款地址
    const { data: storeAddressList } = useStoreAddressListAll()
    const options = storeAddressList?.map((item: any, value: any) =>
        <Option value={item.storeAddressId} key={item.storeAddressId}>{item.storeAddressProvince}{item.storeAddressCity}{item.storeAddressDistrict}{item.storeAddressDetail}</Option>
    );
    //协商记录
    const { data: ordersRefundLogList} = useOrdersRefundLogList(Number(ordersRefundId))
    //同意退款弹框
    const [submitAgreeLoading, setSubmitAgreeLoading] = useState(false);
    const useMutateAgree = useAgree;
    const { mutateAsync: mutateAsyncAgree } = useMutateAgree();
    const [isAgreeModalVisible, setIsAgreeModalVisible] = useState(false);
    const showAgreeModal = () => {
        setIsAgreeModalVisible(true);
    };
    const handleAgreeOk = (ordersRefundId: number) => {
        setSubmitAgreeLoading(true)
        return new Promise((resolve, reject) => {
            mutateAsyncAgree({ ordersRefundId }).then(() => {
                message.info('退款成功');
                window.location.href = "/#/shop/orders-manage/orders-refund/list";
            });
        }).catch(
            () => console.log('Oops errors!')
        );
        setIsAgreeModalVisible(false);
    };
    const handleAgreeCancel = () => {
        setIsAgreeModalVisible(false);
    };
    //拒绝原因
    const [ordersRefundRejectReason, setOrdersRefundRejectReason] = useState("");
    function handleChangeOrdersRefundRejectReason(e: any) {
        setOrdersRefundRejectReason(e.target.value)
    }
    //拒绝退款弹框
    const [submitRejectLoading, setSubmitRejectLoading] = useState(false);
    const useMutateReject = useReject;
    const { mutateAsync: mutateAsyncReject } = useMutateReject();
    const [isRejectModalVisible, setIsRejectModalVisible] = useState(false);
    const showRejectModal = () => {
        setIsRejectModalVisible(true);
    };
    const handleRejectOk = (ordersRefundId: number) => {
        if(ordersRefundRejectReason.length > 0) {
            setSubmitRejectLoading(true)
            return new Promise((resolve, reject) => {
                mutateAsyncReject({ ordersRefundRejectReason, ordersRefundId}).then(() => {
                    message.info('拒绝退款');
                    window.location.href = "/#/shop/orders-manage/orders-refund/list";
                });
            }).catch(
                () => console.log('Oops errors!')
            );
        } else {
            message.info('请输入拒绝说明');
        }
    };
    const handleRejectCancel = () => {
        setIsRejectModalVisible(false);
    };
    //同意并发送收货地址
    const [storeAddressId, setStoreAddressId] = useState();
    const storeAddressChange = (e: any) =>{
        setStoreAddressId(e);
    }
    const [submitAgreeReturnLoading, setSubmitAgreeReturnLoading] = useState(false);
    const useMutateAgreeReturn = useAgreeReturn;
    const { mutateAsync: mutateAsyncAgreeReturn } = useMutateAgreeReturn();
    const [isAgreeReturnModalVisible, setIsAgreeReturnModalVisible] = useState(false);
    const showAgreeReturnModal = () => {
        setIsAgreeReturnModalVisible(true);
    };
    const handleAgreeReturnOk = (ordersRefundId: number, storeAddressId: number) => {
        if(storeAddressId != null && storeAddressId > 0) {
            setSubmitAgreeReturnLoading(true)
            return new Promise((resolve, reject) => {
                mutateAsyncAgreeReturn({ ordersRefundId, storeAddressId }).then(() => {
                    message.info('退款成功');
                    window.location.href = "/#/shop/orders-manage/orders-refund/list";
                });
            }).catch(
                () => console.log('Oops errors!')
            );
        } else {
            message.info('请选择退货地址');
        }
    };
    const handleAgreeReturnCancel = () => {
        setIsAgreeReturnModalVisible(false);
    };
    //卖家拒绝收货
    const [submitRejectReceiveLoading, setSubmitRejectReceiveLoading] = useState(false);
    const useMutateRejectReceive = useRejectReceive;
    const { mutateAsync: mutateAsyncRejectReceive } = useMutateRejectReceive();
    const [isRejectReceiveModalVisible, setIsRejectReceiveModalVisible] = useState(false);
    const showRejectReceiveModal = () => {
        setIsRejectReceiveModalVisible(true);
    };
    const handleRejectReceiveOk = (ordersRefundId: number) => {
        if(ordersRefundRejectReason.length > 0) {
            setSubmitRejectReceiveLoading(true)
            return new Promise((resolve, reject) => {
                mutateAsyncRejectReceive({ ordersRefundRejectReason, ordersRefundId }).then(() => {
                    message.info('卖家拒绝收货');
                    window.location.href = "/#/shop/orders-manage/orders-refund/list";
                });
            }).catch(
                () => console.log('Oops errors!')
            );
        } else {
            message.info('请输入拒绝说明');
        }
    };
    const handleRejectReceiveCancel = () => {
        setIsRejectReceiveModalVisible(false);
    };
    //确认收货并退款
    const [submitAgreeReceiveLoading, setSubmitAgreeReceiveLoading] = useState(false);
    const useMutateAgreeReceive = useAgreeReceive;
    const { mutateAsync: mutateAsyncAgreeReceive } = useMutateAgreeReceive();
    const [isAgreeReceiveModalVisible, setIsAgreeReceiveModalVisible] = useState(false);
    const showAgreeReceiveModal = () => {
        setIsAgreeReceiveModalVisible(true);
    };
    const handleAgreeReceiveOk = (ordersRefundId: number) => {
        setSubmitAgreeReceiveLoading(true)
        return new Promise((resolve, reject) => {
            mutateAsyncAgreeReceive({ ordersRefundId }).then(() => {
                message.info('退款成功');
                window.location.href = "/#/shop/orders-manage/orders-refund/list";
            });
        }).catch(
            () => console.log('Oops errors!')
        );
    };
    const handleAgreeReceiveCancel = () => {
        setIsAgreeReceiveModalVisible(false);
    };
    return (
        <div>
            <Modal confirmLoading={submitAgreeLoading} title="同意买家退款" visible={isAgreeModalVisible} onOk={() => handleAgreeOk(ordersRefund?.data?.ordersRefundId)} onCancel={handleAgreeCancel}>
                <p>退款方式：{ordersRefund?.data?.ordersRefundMethod == 1? '仅退款' : '退货退款'}</p>
                <p>退款金额：￥{ordersRefund?.data?.ordersRefundMoney}</p>
                <p>{ordersRefund?.data?.ordersRefundMethod == 2? <p>退款数量：{ordersRefund?.data?.ordersRefundNum}</p> : ''}</p>
            </Modal>
            <Modal confirmLoading={submitRejectLoading} title="拒绝买家退款" visible={isRejectModalVisible} onOk={() => handleRejectOk(ordersRefund?.data?.ordersRefundId)} onCancel={handleRejectCancel}>
                <p>退款方式：仅退款</p>
                <p>退款金额：￥{ordersRefund?.data?.ordersRefundMoney}</p>
                <p>拒绝说明：<Input.TextArea onChange={handleChangeOrdersRefundRejectReason} style={{width: 300}} placeholder={"请输入拒绝说明"} /></p>
            </Modal>
            <Modal confirmLoading={submitAgreeReturnLoading} title="同意并发送退货地址" visible={isAgreeReturnModalVisible} onOk={() => handleAgreeReturnOk(ordersRefund?.data?.ordersRefundId, Number(storeAddressId))} onCancel={handleAgreeReturnCancel}>
                <p>退款方式：退货退款</p>
                <p>退款金额：￥{ordersRefund?.data?.ordersRefundMoney}</p>
                <p>退款数量：{ordersRefund?.data?.ordersRefundNum}</p>
                <p>退货地址：
                    <Select
                        onChange={storeAddressChange}
                        showSearch
                        placeholder="请选择退货地址"
                        optionFilterProp="children"
                        style={{textAlign:'left', width: '70%'}}
                        >
                        {options}
                    </Select>
                </p>
            </Modal>
            <Modal confirmLoading={submitRejectReceiveLoading} title="拒绝收货" visible={isRejectReceiveModalVisible} onOk={() => handleRejectReceiveOk(ordersRefund?.data?.ordersRefundId)} onCancel={handleRejectReceiveCancel}>
                <p>退款方式：退货退款</p>
                <p>退款金额：￥{ordersRefund?.data?.ordersRefundMoney}</p>
                <p>退款数量：{ordersRefund?.data?.ordersRefundNum}</p>
                <p>拒绝说明：<Input.TextArea onChange={handleChangeOrdersRefundRejectReason} style={{width: 300}} placeholder={"请输入拒绝说明"} /></p>
            </Modal>
            <Modal confirmLoading={submitAgreeReceiveLoading} title="确认收货并退款" visible={isAgreeReceiveModalVisible} onOk={() => handleAgreeReceiveOk(ordersRefund?.data?.ordersRefundId)} onCancel={handleAgreeReceiveCancel}>
                <p>退款方式：{ordersRefund?.data?.ordersRefundMethod == 1? '仅退款' : '退货退款'}</p>
                <p>退款金额：￥{ordersRefund?.data?.ordersRefundMoney}</p>
                <p>{ordersRefund?.data?.ordersRefundMethod == 2? <p>退款数量：{ordersRefund?.data?.ordersRefundNum}</p> : ''}</p>
            </Modal>
            <Card style={{ width: "100%", marginBottom: 24 }}>
                <Row>
                    <Descriptions title={ordersRefundStateVal}>
                        <Descriptions.Item label="售后编号">{ordersRefund?.data?.ordersRefundSn}</Descriptions.Item>
                        <Descriptions.Item label="退款方式">{ordersRefund?.data?.ordersRefundMethod == 1? '仅退款' : '退货退款'} </Descriptions.Item>
                        <Descriptions.Item label="买家">{ordersRefund?.data?.memberNickname}  |  申请时间：{ordersRefund?.data?.ordersRefundAddTime ? dayjs(ordersRefund?.data?.ordersRefundAddTime).format('YYYY-MM-DD HH:mm:ss'):'无'}</Descriptions.Item>
                        {ordersRefund?.data?.ordersRefundState == 10? <Descriptions.Item label="">请在<span style={{ color: "#faab0c", fontSize: 20, paddingLeft: 5, paddingRight: 5 }}>{ordersRefund?.data?.ordersRefundNextStepTime ? dayjs(ordersRefund?.data?.ordersRefundNextStepTime).format('YYYY-MM-DD HH:mm:ss'):'无'}</span>内处理，逾期未处理，将自动退款给买家</Descriptions.Item> : ""}
                        {ordersRefund?.data?.ordersRefundState == 20? <Descriptions.Item label="">买家修改申请后，商家需要重新处理</Descriptions.Item> : ""}
                        {ordersRefund?.data?.ordersRefundState == 20? <Descriptions.Item label=""> </Descriptions.Item> : ""}
                        {ordersRefund?.data?.ordersRefundState == 20? <Descriptions.Item label=""> </Descriptions.Item> : ""}
                        {ordersRefund?.data?.ordersRefundState == 20? <Descriptions.Item label="">买家需在<span style={{ color: "#faab0c", fontSize: 20, paddingLeft: 5, paddingRight: 5 }}>{ordersRefund?.data?.ordersRefundNextStepTime ? dayjs(ordersRefund?.data?.ordersRefundNextStepTime).format('YYYY-MM-DD HH:mm:ss'):'无'}</span>内处理，逾期未处理，退款申请将自动撤销</Descriptions.Item> : ""}
                        {ordersRefund?.data?.ordersRefundState == 30? <Descriptions.Item label="">买家需在<span style={{ color: "#faab0c", fontSize: 20, paddingLeft: 5, paddingRight: 5 }}>{ordersRefund?.data?.ordersRefundNextStepTime ? dayjs(ordersRefund?.data?.ordersRefundNextStepTime).format('YYYY-MM-DD HH:mm:ss'):'无'}</span>内退货，逾期未处理，退款申请将自动撤销</Descriptions.Item> : ""}
                        {ordersRefund?.data?.ordersRefundState == 40? <Descriptions.Item label="">请在<span style={{ color: "#faab0c", fontSize: 20, paddingLeft: 5, paddingRight: 5 }}>{ordersRefund?.data?.ordersRefundNextStepTime ? dayjs(ordersRefund?.data?.ordersRefundNextStepTime).format('YYYY-MM-DD HH:mm:ss'):'无'}</span>内确认收货，逾期未处理，将自动同意收货并退款给买家</Descriptions.Item> : ""}
                        {ordersRefund?.data?.ordersRefundState == 50? <Descriptions.Item label="">买家修改申请后，商家需要重新处理</Descriptions.Item> : ""}
                        {ordersRefund?.data?.ordersRefundState == 50? <Descriptions.Item label=""> </Descriptions.Item> : ""}
                        {ordersRefund?.data?.ordersRefundState == 50? <Descriptions.Item label=""> </Descriptions.Item> : ""}
                        {ordersRefund?.data?.ordersRefundState == 50? <Descriptions.Item label="">买家需在<span style={{ color: "#faab0c", fontSize: 20, paddingLeft: 5, paddingRight: 5 }}>{ordersRefund?.data?.ordersRefundNextStepTime ? dayjs(ordersRefund?.data?.ordersRefundNextStepTime).format('YYYY-MM-DD HH:mm:ss'):'无'}</span>内处理，逾期未处理，退款申请将自动撤销</Descriptions.Item> : ""}
                        {ordersRefund?.data?.ordersRefundState == 60? <Descriptions.Item label="">买家撤销了本次退款申请</Descriptions.Item> : ""}
                        {ordersRefund?.data?.ordersRefundState == 70? <Descriptions.Item label="退款金额" labelStyle={{alignItems: "center"}} contentStyle={{color: "#faab0c", fontSize: 20}}>￥{ordersRefund?.data?.ordersRefundMoney} </Descriptions.Item> : ""}
                    </Descriptions>
                    {(ordersRefund?.data?.ordersRefundState == 10 && ordersRefund?.data?.ordersRefundMethod == 1) ? <div>
                        <Button onClick={showAgreeModal} type="primary" style={{marginRight: 10}}>同意退货</Button>
                        <Button onClick={showRejectModal}>拒绝买家退款</Button>
                    </div>:''}
                    {(ordersRefund?.data?.ordersRefundState == 10 && ordersRefund?.data?.ordersRefundMethod == 2) ? <div>
                        <Button onClick={showAgreeReturnModal} type="primary" style={{marginRight: 10}}>同意退货</Button>
                        <Button onClick={showRejectModal}>拒绝买家退款</Button>
                    </div>:''}
                    {ordersRefund?.data?.ordersRefundState == 40 ? <div>
                        <Button onClick={showAgreeReceiveModal} type="primary" style={{marginRight: 10}}>确认收货并退款</Button>
                        <Button onClick={showRejectReceiveModal}>拒绝收货</Button>
                    </div>:''}
                </Row>
            </Card>
            <Card style={{ backgroundColor: '#f0f2f5', width: "100%", marginBottom: 24 }}>
                <Row>
                    <Col span={8} style={{  width: "100%", padding:20 }}>
                        <Space direction="vertical">
                            <Text strong>售后商品</Text>
                            <Row>
                                <Col span={6}>
                                    <Image
                                        width="100%"
                                        src={imgUrl  + ordersRefund?.data?.goodsPic}
                                    />
                                </Col>
                                <Col span={18} style={{paddingLeft:10}}>
                                    {ordersRefund?.data?.goodsName}<br/>
                                    规格：{ordersRefund?.data?.goodsSkuInfo}
                                </Col>
                            </Row>
                        </Space>
                    </Col>
                    <Col span={8} style={{  width: "100%", padding:20 }}>
                        <Space direction="vertical">
                            <Text strong>退款申请信息</Text>
                            <Text>退款方式：{ordersRefund?.data?.ordersRefundMethod == 1? '仅退款' : '退货退款'}</Text>
                            <Text>退款金额：￥{ordersRefund?.data?.ordersRefundMoney}</Text>
                            <Text>货物状态：{ordersRefund?.data?.ordersRefundGoodsStatus ? '已发货':'未发货'}</Text>
                            <Text>联系方式：{ordersRefund?.data?.ordersRefundContactTel} / {ordersRefund?.data?.ordersRefundContactName}</Text>
                            <Text>退款原因：{ordersRefund?.data?.ordersRefundReason}</Text>
                            {ordersRefund?.data?.ordersRefundMessage? <Text>退款说明：{ordersRefund?.data?.ordersRefundMessage}</Text> : ""}
                        </Space>
                    </Col>
                    <Col span={8} style={{ width: "100%", padding:20 }}>
                        <Space direction="vertical">
                            <Text strong>订单信息</Text>
                            <Text>买家昵称：{ordersRefund?.data?.memberNickname}</Text>
                            <Text>订单编号：{ordersRefund?.data?.ordersSn}</Text>
                            <Text>付款时间：{ordersRefund?.data?.ordersRefundAddTime ? dayjs(ordersRefund?.data?.ordersPayTime).format('YYYY-MM-DD HH:mm:ss'):'无'}</Text>
                            <Text>实付金额：￥{ordersRefund?.data?.ordersActuallyAmount}</Text>
                            <Text>订单金额：￥{ordersRefund?.data?.ordersTotalAmount}</Text>
                        </Space>
                    </Col>
                </Row>
            </Card>
            <Card style={{ width: "100%" }}>
                <Row style={{marginBottom: 24}}>
                    <Text strong>协商记录</Text>
                </Row>
                <List
                    bordered = {false}
                    split = {false}
                    dataSource={ordersRefundLogList || []}
                    renderItem={(item:any) => (
                        <List.Item>
                            <Row style={{marginBottom: 30}}>
                                <Space style={{width: "100%"}}>
                                    <Text strong style={{ paddingRight: 30 }}>
                                        {item.ordersRefundLogType == 1 ? "买家" : "卖家"}
                                    </Text>
                                    <Text>
                                        {item.ordersRefundLogCreateTime ? dayjs(item.ordersRefundLogCreateTime).format('YYYY-MM-DD HH:mm:ss'):'无'}
                                    </Text>
                                </Space>
                                <Divider dashed style={{ margin: "14px 0"}} />
                                {item.ordersRefundState == 10 ? <Space direction="vertical">
                                    <Text strong>{item.ordersRefundLogTitle}</Text>
                                    <Text>退款方式：{item.ordersRefundMethod == 1? '仅退款' : '退货退款'}</Text>
                                    <Text>退款原因：{item.ordersRefundReason}</Text>
                                    <Text>退款金额：￥{item.ordersRefundMoney}</Text>
                                    {item.ordersRefundMessage ? <Text>退款说明：{item.ordersRefundMessage}</Text> : ""}
                                    {item.ordersRefundMethod == 2? <Text>退货数量：{item.ordersRefundNum}</Text> : ''}
                                    <Text>联系方式：{item.ordersRefundContactTel} {item.ordersRefundContactName? "/ " + item.ordersRefundContactName : ""}</Text>
                                    <Text>
                                        {item?.ordersRefundLogPics? "上传凭证：" : "" }
                                        {
                                            item?.ordersRefundLogPics?.split(",")?.map((item: any, value: any) => 
                                                <span  style={{marginRight:10}}>
                                                    <Image
                                                        width={60}
                                                        src={imgUrl  + item} />
                                                </span>
                                            )
                                        }
                                    </Text>
                                </Space> : ""}
                                {item.ordersRefundState == 20 ? <Space direction="vertical">
                                    <Text strong>{item.ordersRefundLogTitle}</Text>
                                    <Text>拒绝理由：{item.ordersRefundRejectReason}</Text>
                                </Space> : ""}
                                {item.ordersRefundState == 30 ? <Space direction="vertical">
                                    <Text strong>{item.ordersRefundLogTitle}</Text>
                                    <Text>退货地址：{item.ordersRefundAddressProvince}{item.ordersRefundAddressCity}{item.ordersRefundAddressDistrict}{item.ordersRefundAddressDetail}, {item.ordersRefundAddressReceiverName}, {item.ordersRefundAddressReceiverTel}</Text>
                                </Space> : ""}
                                {item.ordersRefundState == 40 ? <Space direction="vertical">
                                    <Text strong>{item.ordersRefundLogTitle}</Text>
                                    <Text>物流名称：{item.ordersRefundExpressCompany}</Text>
                                    <Text>物流编号：{item.ordersRefundTrackingNumber}</Text>
                                    <Text>
                                        {item?.ordersRefundLogPics? "上传凭证：" : "" }
                                        {
                                            item?.ordersRefundLogPics?.split(",")?.map((item: any, value: any) => 
                                                <span  style={{marginRight:10}}>
                                                    <Image
                                                        width={60}
                                                        src={imgUrl  + item} />
                                                </span>
                                            )
                                        }
                                    </Text>
                                </Space> : ""}
                                {item.ordersRefundState == 50 ? <Space direction="vertical">
                                    <Text strong>{item.ordersRefundLogTitle}</Text>
                                    <Text>拒绝理由：{item.ordersRefundRejectReason}</Text>
                                </Space> : ""}
                                {item.ordersRefundState == 60 ? <Space direction="vertical">
                                    <Text strong>{item.ordersRefundLogTitle}</Text>
                                </Space> : ""}
                                {item.ordersRefundState == 70 ? <Space direction="vertical">
                                    <Text strong>{item.ordersRefundLogTitle}</Text>
                                    <Text>退款金额：{item.ordersRefundMoney}</Text>
                                </Space> : ""}
                            </Row>
                        </List.Item>
                    )}
                />
            </Card>
        </div>
    )
}