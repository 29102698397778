import { useMutation, useQuery, useQueryClient } from "react-query";
import { ResumeEducation } from "../interfaces/resume-education";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useResumeEducationList = (param?: Partial<ResumeEducation>) => {
    const client = useHttp();
    const result = useQuery<any>(['resumeEducationPage', param], () => client('resumeEducation', { data: cleanObject(param) }))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return { ...result, totalElements, data };
}

export const useEditResumeEducation = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<ResumeEducation>) => client(`resumeEducation`, {
            method: 'PUT',
            data: params
        }), {
        onSuccess: () => queryClient.invalidateQueries('resumeEducationPage')
    }
    )
}

export const useAddResumeEducation = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<ResumeEducation>) => client(`resumeEducation`, {
            data: params,
            method: 'POST'
        }), {
        onSuccess: () => queryClient.invalidateQueries('resumeEducationPage')
    }
    )
}

export const useDeleteResumeEducation = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ resumeEducationId }: { resumeEducationId: number }) =>
            client(`resumeEducation/${resumeEducationId}`, {
                method: "DELETE",
            }), {
        onSuccess: () => queryClient.invalidateQueries('resumeEducationPage')
    }
    );
};

export const useResumeEducation = (resumeEducationId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['resumeEducation', { resumeEducationId }],
        () => client(`resumeEducation/${resumeEducationId}`),
        {
            enabled: Boolean(resumeEducationId)
        }
    )
}

export const useResumeEducationListAll = () => {
    const client = useHttp();
    return useQuery<any>(["resumeEducationList", {}], () =>
        client(`resumeEducation/list`)
    );
};