import { Button, Col, Divider, Form, Input, message, Radio, Row, Select, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useCourseClass, useCourseClassList, useEditCourseClass, useGoodsClassListTree } from "../../services/course-class-service";
import { useDocumentTitle } from "../../utils"
import  UploadPic  from '../../components/upload-pic';
export const CourseClassUpdatePage = () => {
    //标题
    useDocumentTitle('编辑课程分类', true)
    const navigate = useNavigate();
    const { pathname } = useLocation();
    const courseClassId = Number(pathname.match(/jigou\/course-manage\/course-class\/update\/(\d+)/)?.[1])

    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
  //准备树状结构数据
  const { data: courseClassList } = useGoodsClassListTree();
  const courseClassListOptions = courseClassList?.data?.map(
    (item: any, value: any) => (
      <Select.Option value={item.courseClassId} key={item.courseClassId}>
        {item.courseClassName}
      </Select.Option>
    )
  );
    //表单
    const [form] = useForm();
    const [courseClassPic, setCourseClassPic] = useState("");
    const [uploading, setUploading] = useState<boolean>(false);

    //准备回显
    const { data: editingCourseClass, isLoading } = useCourseClass(courseClassId);
    console.log("回显",editingCourseClass,isLoading);
    useEffect(() => {
        form.setFieldsValue({ ...editingCourseClass?.data });
        //封面
        if (editingCourseClass?.data?.courseClassPic) {
            setCourseClassPic(editingCourseClass?.data?.courseClassPic)
        }
    }, [editingCourseClass, form]);

    //新建
    const [submitLoading, setSubmitLoading] = useState(false);
    const { mutateAsync } = useEditCourseClass();
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...editingCourseClass?.data, ...values, courseClassPic }).then((data) => {
            if (data.status === 0) {
                message.success(data.msg);
                navigate('/jigou/course-manage/course-class/list')
            } else {
                setSubmitLoading(false);
                message.error(data.msg);
            }
        }).catch(err => {
            message.error('提交失败，请重新提交');
            setSubmitLoading(false);
        });
    };

    return (
        <div>
            <Row>
                <Col span={12}>
                    <Link to={"/jigou/course-manage/course-class/list"}>
                        <Button type="primary">列表</Button>
                    </Link>
                </Col>
            </Row>

            <Divider style={{ marginBottom: 24 }} />

            <Spin spinning={isLoading}>
                <Form
                    form={form}
                    {...layout}
                    onFinish={onFinish}
                >
               	<Form.Item
                    label={"课程分类名称"}
                    name={"courseClassName"}
                    rules={[{ required: false, message: "课程分类名称必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入课程分类名称"} />
                </Form.Item>

                <Form.Item
                    label={"上级分类"}
                    name={"courseClassParentId"}
                    rules={[{ required: true, message: "上级分类必选" }]}
                >
                    <Select
                    showSearch
                    placeholder="请选择上级分类"
                    optionFilterProp="children"
                    style={{ textAlign: "left" ,width:"380px"}}
                    >
                    <option value={0}>无</option>
                    {courseClassListOptions}
                    </Select>
                </Form.Item>
               	<Form.Item
                    label={"排序"}
                    name={"courseClassSort"}
                    rules={[{ required: true, message: "排序必填" }]}
                >
                    <Input style={{ width: 380 }} placeholder={"请输入排序"} />
                </Form.Item>

               	<UploadPic
                    label={'图片'}
                    name={'uploadCourseClassPic'}
                    pic={courseClassPic}
                    setPic={setCourseClassPic}
                    action={'update'}
                    style={{ textAlign: 'left' }}
                >
                </UploadPic>
                <Form.Item
                    label={"是否显示"}
                    name={"courseClassShow"}
                    rules={[{ required: true, message: "是否显示必选" }]}
                    style={{ textAlign: 'left' }}
                >
                    <Radio.Group>
                        <Radio defaultChecked value={true}>显示</Radio>
                        <Radio value={false}>不显示</Radio>
                    </Radio.Group>
                </Form.Item>
				<Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                        <Button loading={submitLoading} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    )
}