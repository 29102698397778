import { useMutation, useQuery, useQueryClient } from "react-query";
import { Area } from "../interfaces/area";
import { cleanObject } from "../utils";
import { useHttp } from "../utils/http";

export const useAreaList = (param?: Partial<Area>) => {
    const client = useHttp();
    const result = useQuery<any>(['areaPage', param], () => client('area', { data: cleanObject(param) }))
    const totalElements = result.data?.data?.totalElements;
    const data = result.data?.data?.content;
    return { ...result, totalElements, data };
}

export const useEditArea = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Area>) => client(`area`, {
            method: 'PUT',
            data: params
        }), {
        onSuccess: () => queryClient.invalidateQueries('areaPage')
    }
    )
}

export const useAddArea = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        (params: Partial<Area>) => client(`area`, {
            data: params,
            method: 'POST'
        }), {
        onSuccess: () => queryClient.invalidateQueries('areaPage')
    }
    )
}

export const useDeleteArea = () => {
    const client = useHttp()
    const queryClient = useQueryClient()
    return useMutation(
        ({ areaId }: { areaId: number }) =>
            client(`area/${areaId}`, {
                method: "DELETE",
            }), {
        onSuccess: () => queryClient.invalidateQueries('areaPage')
    }
    );
};

export const useMutationArea = () => {
    const client = useHttp()
    return useMutation(
        ({ areaId }: { areaId: number }) =>
            client(`area/${areaId}`, {
                method: "GET",
            })
    );
};

export const useArea = (areaId?: number) => {
    const client = useHttp()
    return useQuery<any>(
        ['area', { areaId }],
        () => client(`area/${areaId}`),
        {
            enabled: Boolean(areaId)
        }
    )
}

export const useAreaListAll = (cascadeType: number) => {
    const client = useHttp();
    return useQuery<any>(["areaList", { cascadeType }], () =>
        client(`area/list?cascadeType=${cascadeType}`)
    );
};

export const useAreaListTree = () => {
    const client = useHttp();
    return useQuery<any>(['areaListTree', {}], () => client('area/listTree'))
}

export const useAreaListChildren = () => {
    const client = useHttp();
    return useQuery<any>(['areaListChildren', {}], () => client('area/listChildren'))
}

export const useAreaListTop = () => {
    const client = useHttp();
    return useQuery<any>(['areaListTop', {}], () => client('area/listTop'))
}

export const useAreaListCity = () => {
    const client = useHttp();
    return useQuery<any>(['areaListCity', {}], () => client('area/listCity'))
}