import { Button, Form, Input, message, Radio, Spin } from "antd";
import { useForm } from "antd/lib/form/Form";
import { useEffect, useState } from "react";
import { useSystemSetting, useEditSystemSetting } from "../../services/system-setting-service";
import { useDocumentTitle } from "../../utils"
import UploadPic from "../../components/upload-pic";

export const SystemSettingIndexPage = () => {
    //标题
    useDocumentTitle('通用设置', true)

    //布局
    const layout = {
        labelCol: { span: 3 },
        wrapperCol: { span: 7 },
    };
    const tailLayout = {
        wrapperCol: { offset: 3 },
    };
    //表单
    const [form] = useForm();
    const [systemSettingDefaultTechnicalSupport, setSystemSettingDefaultTechnicalSupport] = useState(true);
    //准备回显数据
    const { data: editingSystemSetting, isLoading } = useSystemSetting();
    const [systemSettingSharePic, setSystemSettingSharePic] = useState("");
    const [systemSettingTechnicalSupportPic, setSystemSettingTechnicalSupportPic] = useState("");
    const [systemSettingSellOutPic, setSystemSettingSellOutPic] = useState("");
    useEffect(() => {
        form.setFieldsValue(editingSystemSetting?.data)
        if (editingSystemSetting?.data) {
            setSystemSettingDefaultTechnicalSupport(editingSystemSetting?.data?.systemSettingDefaultTechnicalSupport)
        }
        if (editingSystemSetting?.data?.systemSettingSharePic) {
            setSystemSettingSharePic(editingSystemSetting?.data?.systemSettingSharePic)
        }
        if (editingSystemSetting?.data?.systemSettingTechnicalSupportPic) {
            setSystemSettingTechnicalSupportPic(editingSystemSetting?.data?.systemSettingTechnicalSupportPic)
        }
        if (editingSystemSetting?.data?.systemSettingSellOutPic) {
            setSystemSettingSellOutPic(editingSystemSetting?.data?.systemSettingSellOutPic)
        }
    }, [editingSystemSetting, form]);

    function changeSystemSettingDefaultTechnicalSupport(e: any) {
        setSystemSettingDefaultTechnicalSupport(e.target.value)
    }

    //新增
    const useMutateEditSystemSetting = useEditSystemSetting;
    const { mutateAsync, error } = useMutateEditSystemSetting();
    //提交表单
    const [submitLoading, setSubmitLoading] = useState(false);
    const onFinish = (values: any) => {
        setSubmitLoading(true);
        mutateAsync({ ...editingSystemSetting?.data, ...values, systemSettingSharePic, systemSettingTechnicalSupportPic, systemSettingSellOutPic }).then(() => {
            message.info('编辑成功');
            setSubmitLoading(false);
        });
    };
    return (
        <div>
            <Spin spinning={isLoading}>
                <Form
                    form={form}
                    {...layout}
                    onFinish={onFinish}
                    style={{ marginTop: 24 }}
                >
                    <div style={{ padding: 10, backgroundColor: '#f8f8f8', fontSize: 14, fontWeight: 700 }}>
                        页面设置
                    </div>

                    <Form.Item
                        label={"平台名称"}
                        name={"systemSettingName"}
                        rules={[{ required: true, message: "平台名称必填" }]}
                    >
                        <Input style={{ width: 380 }} placeholder={"请输入平台名称"} />
                    </Form.Item>

                    <Form.Item
                        label={"技术支持"}
                        name={"systemSettingDefaultTechnicalSupport"}
                        rules={[{ required: true, message: "技术支持必选" }]}
                    >
                        <Radio.Group onChange={changeSystemSettingDefaultTechnicalSupport}>
                            <Radio value={true}>默认</Radio>
                            <Radio value={false}>自定义</Radio>
                        </Radio.Group>
                    </Form.Item>

                    {systemSettingDefaultTechnicalSupport ? ('') : (
                        <UploadPic
                            name='uploadSupportPic'
                            label='自定义LOGO'
                            pic={systemSettingTechnicalSupportPic}
                            setPic={setSystemSettingTechnicalSupportPic}
                            action={'update'}
                            style={{ textAlign: 'left' }}
                            required={false}
                        ></UploadPic>
                    )}

                    <UploadPic
                        name='uploadOutPic'
                        label='售罄标识'
                        pic={systemSettingSellOutPic}
                        setPic={setSystemSettingSellOutPic}
                        action={'update'}
                        style={{ textAlign: 'left' }}
                        required={false}
                    ></UploadPic>

                    <div style={{ padding: 10, backgroundColor: '#f8f8f8', fontSize: 14, fontWeight: 700 }}>
                        分享设置
                    </div>

                    <Form.Item
                        label={"分享标题"}
                        name={"systemSettingShareTitle"}
                    >
                        <Input style={{ width: 380 }} placeholder={"请输入分享标题"} />
                    </Form.Item>

                    <Form.Item
                        label={"分享描述"}
                        name={"systemSettingShareDescription"}
                    >
                        <Input style={{ width: 380 }} placeholder={"请输入分享描述"} />
                    </Form.Item>

                    <UploadPic
                        name='uploadSharePic'
                        label='分享图'
                        pic={systemSettingSharePic}
                        setPic={setSystemSettingSharePic}
                        action={'update'}
                        style={{ textAlign: 'left' }}
                        required={false}
                    ></UploadPic>

                    <Form.Item {...tailLayout} style={{ textAlign: 'left' }}>
                        <Button loading={submitLoading} type="primary" htmlType="submit">
                            提交
                        </Button>
                    </Form.Item>
                </Form>
            </Spin>
        </div>
    )
}

